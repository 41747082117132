export const generateDynamicFields = (
  fields,
  submitMethodName = 'submitPayload',
  dataFields = 'value'
) => {
  return fields.reduce((acc, current) => {
    const isString = typeof current === 'string';
    let name = null;
    let field = null;

    if (!isString) {
      ({ field, name } = current);
    }

    let currentField = field;

    if (!field) {
      currentField = name;
    }

    if (isString) {
      currentField = current;
      name = current;
    }

    acc[name] = {
      get() {
        return this[dataFields][currentField];
      },

      set(value) {
        this[submitMethodName](currentField, value);
      },
    };

    return acc;
  }, {});
};

export const fontItems = [
  {
    text: 'Arial',
    value: 'Arial, sans-serif',
  },

  {
    text: 'Times new roman',
    value: "'Times new roman', serif",
  },

  {
    text: 'Helvetica',
    value: 'Helvetica, sans-serif',
  },

  {
    text: 'Verdana',
    value: 'Verdana, sans-serif',
  },

  {
    text: 'Roboto',
    value: 'Roboto, sans-serif',
  },
];

export const hintPositionOptions = [
  {
    text: 'element',
    value: 'element',
  },

  {
    text: 'topRight',
    value: 'topRight',
  },
  {
    text: 'bottomRight',
    value: 'bottomRight',
  },
  {
    text: 'topLeft',
    value: 'topLeft',
  },
  {
    text: 'bottomLeft',
    value: 'bottomLeft',
  },
];

export const THEME_TYPE_BASIC = 'basic';
export const THEME_TYPE_CLASSIC = 'classic';

export const MIX_VALUE = 'mix';
