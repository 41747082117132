/* eslint-disable no-shadow,no-param-reassign,no-unused-vars */
import Vue from 'vue';
import api from 'src/api';
import {
  SCENARIOS_LIST,
  SCENARIOS_ERROR,
  SCENARIOS_COUNT,
  SCENARIOS_UPDATE_ITEM,
} from 'src/store/mutation-types';
import { deepcopy } from 'src/utils';

const state = {
  scenariosList: [],
  scenariosCount: 0,
  error: '',
};

const actions = {
  async list({ commit }) {
    const params = {};
    const { response } = await api.scenarios.list({ params });

    if (response) {
      commit(SCENARIOS_LIST, response);
    }
  },

  async getList({ commit }, { page = 1, limit, pattern } = {}) {
    const payload = { page, limit };

    if (pattern) {
      payload.pattern = pattern;
    }

    const { response } = await api.scenarios.listWithParams({ params: payload });

    return response;
  },

  async getItem(context, id) {
    const { response } = await api.scenarios.getOne({ url_params: { scenarioId: id } });

    return response;
  },

  async listWithParams(
    { commit },
    {
      page,
      limit,
      filterBy,
      filterDirection,
      pattern,
      organizationId,
      groupId,
      checklistId,
      trashedItems,
      creatorsEmails,
      tags,
      publishStatus,
      creationDatesRange,
    } = {}
  ) {
    const params = deepcopy({
      page,
      limit,
      filter_by: filterBy,
      filter_direction: filterDirection,
      organizationId,
      group_id: groupId,
      checklist_id: checklistId,
      trashedItems,
      ...(creatorsEmails ? { creatorsEmails } : {}),
      ...(tags ? { tags } : {}),
      ...(typeof publishStatus === 'boolean' ? { publishStatus } : {}),
      ...(creationDatesRange ? { ...creationDatesRange } : {}),
    });

    if (pattern) {
      params.pattern = pattern;
    }

    const { response } = await api.scenarios.listWithParams({ params });
    commit(SCENARIOS_LIST, response.payload);
    commit(SCENARIOS_COUNT, response.count);
  },

  clearList({ commit }) {
    commit(SCENARIOS_LIST, []);
    commit(SCENARIOS_COUNT, 0);
  },

  async removeScenario({ state, commit }, id) {
    await api.scenarios.remove({ url_params: { scenarioId: id } });
  },

  async restoreScenario({ state, commit }, id) {
    await api.scenarios.restore({ url_params: { scenarioId: id } });
  },

  async getListByIds(context, { ids, isWithTriggers }) {
    const { response } = await api.scenarios.getListByIds({ data: { list: ids, isWithTriggers } });

    return response;
  },

  // TODO: must be replaced by schema migration tool
  // hotfix for converting step.withModal to Number
  prepareMultipleScenarios(context, rawData) {
    const data = deepcopy(rawData);
    data.items = data.items.map(scenario => {
      scenario.steps = scenario.steps.map(step => {
        if (
          step.widthModal &&
          typeof step.widthModal === 'string' &&
          !Number.isNaN(Number(step.widthModal))
        ) {
          step.widthModal = Number(step.widthModal);
        }

        return step;
      });

      return scenario;
    });

    return data;
  },

  async createManyScenarios({ dispatch }, rawData) {
    const preparedData = await dispatch('prepareMultipleScenarios', rawData);
    const { response } = await api.scenarios.createMany({ data: preparedData });

    return response;
  },
};

const mutations = {
  [SCENARIOS_LIST](state, payload) {
    state.scenariosList = payload;
  },

  [SCENARIOS_UPDATE_ITEM](state, { id, payload }) {
    const index = state.scenariosList.findIndex(scenario => scenario.id === id);

    if (index === -1) {
      return;
    }

    Vue.set(state.scenariosList, index, {
      ...payload,
      isEditable: state.scenariosList[index].isEditable,
    });
  },

  [SCENARIOS_ERROR](state, payload) {
    state.error = payload;
  },

  [SCENARIOS_COUNT](state, payload) {
    state.scenariosCount = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
