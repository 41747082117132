<script>
  import { mapActions, mapState } from 'vuex';
  import urlParse from 'url-parse';

  import api from 'src/api';

  import BaseAddingForm from './BaseAddingForm.vue';

  export default {
    name: 'ChecklistScenariosAddingForm',

    extends: BaseAddingForm,

    computed: {
      ...mapState('scenarios', ['scenariosList', 'scenariosCount']),

      title() {
        return this.$t('pages.groups.addingModal.title.checklists', {
          title: this.entity.title,
        });
      },

      allListTitle() {
        return this.$t('pages.groups.addingModal.all.checklists');
      },

      addedListTitle() {
        return this.$t('pages.groups.addingModal.inGroup.checklists');
      },

      allItems() {
        return this.scenariosList;
      },

      allItemsCount() {
        return this.scenariosCount;
      },
    },

    methods: {
      ...mapActions('scenarios', { getAllScenarios: 'listWithParams' }),

      itemInEntity(item) {
        return item.isInChecklist;
      },

      mapItem(item) {
        const url = item.origin && urlParse(item.origin);

        return {
          ...item,
          title: (item.title && item.title.trim()) || '---',
          subtitle: url?.hostname || '---',
        };
      },

      async getAddedListMethod() {
        const params = this.currentAddedListParams;

        const queryParams = {
          limit: params.limit,
          page: params.page,
          ...(params.pattern ? { pattern: params.pattern } : {}),
        };

        const {
          response: { payload, count },
        } = await api.checklists.getScenarios({
          url_params: { id: this.entity.id },
          params: queryParams,
        });

        this.addedList = payload;
        this.addedCount = count;
      },

      async getAllListMethod() {
        const { limit, page, pattern } = this.currentAllListParams;
        const checklistId = this.entity.id;

        await this.getAllScenarios({ limit, page, pattern, checklistId });
      },

      removeFromEntityMethod(scenario) {
        return api.checklists.removeScenario({
          url_params: { id: this.entity.id },
          data: { scenarioId: scenario.id },
        });
      },

      addToEntityMethod(scenario) {
        return api.checklists.addScenario({
          url_params: { id: this.entity.id },
          data: { scenarioId: scenario.id },
        });
      },
    },
  };
</script>
