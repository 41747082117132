<template>
  <v-slider track-color="#fddee8" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <template v-for="(_, slotName) in $slots" #[slotName]>
      <slot :name="slotName" />
    </template>
  </v-slider>
</template>

<script>
  export default {
    name: 'HSlider',
  };
</script>
