<template>
  <base-item :item="propsItem" />
</template>

<script>
  import BaseItem from 'src/components/commonComponents/BaseItem.vue';

  export default {
    name: 'BaseItemProps',
    components: {
      BaseItem,
    },

    props: {
      href: { type: String, default: '' },
      icon: { type: String, default: '' },
      title: { type: String, default: '' },
      to: { type: String, default: '' },
      iconType: { type: String, default: 'feather' },
    },

    computed: {
      propsItem() {
        const { href, icon, title, to, iconType } = this;

        return {
          href,
          icon,
          title,
          to,
          iconType,
        };
      },
    },
  };
</script>
