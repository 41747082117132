var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.groupsList,"options":_vm.options,"server-items-length":_vm.groupsCount,"loading":_vm.isBusy,"page":_vm.page},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('travolta-not-found')]},proxy:true},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-8",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":((_vm.$t('pages.groups.search')) + "..."),"append-icon":"mdi-magnify","clearable":"","hide-details":""},model:{value:(_vm.patternModel),callback:function ($$v) {_vm.patternModel=$$v},expression:"patternModel"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openCreatingModal()}}},[_vm._v(" "+_vm._s(_vm.$t('pages.groups.create'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-plus ")])],1)],1)],1)],1)],1)]},proxy:true},{key:"item.num",fn:function(ref){
var index = ref.index;
return [_c('TableRowNumber',{attrs:{"server-page":_vm.serverPage,"server-limit":_vm.serverLimit,"index":index}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.updatedAt))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 info--text",attrs:{"small":""},on:{"click":function($event){return _vm.openEditModal(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.edit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openManageModalStep(2, item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-multiple ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.editGroupUsers")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openManageModalStep(3, item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-playlist-check ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.editGroupScenarios")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openManageModalStep(4, item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-chat-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.editGroupTooltips")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openManageModalStep(5, item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-link-variant ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.editGroupLinks")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"error--text",attrs:{"small":""},on:{"click":function($event){return _vm.openRemoveDialog(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.delete")))])])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"1039px"},model:{value:(_vm.manageModalOpened),callback:function ($$v) {_vm.manageModalOpened=$$v},expression:"manageModalOpened"}},[(_vm.manageModalOpened)?_c('group-manage-modal',{attrs:{"group-id":_vm.editingGroupId,"initial-step":_vm.manageModalStep},on:{"close":function($event){return _vm.closeModal()},"created":function($event){return _vm.onCreatedGroup()},"updated":function($event){return _vm.onUpdatedGroup()}}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.removeDialogOpened),callback:function ($$v) {_vm.removeDialogOpened=$$v},expression:"removeDialogOpened"}},[(_vm.removeDialogOpened)?_c('remove-item-modal',{attrs:{"title":_vm.$t('pages.groups.removeModalTitle'),"text":_vm.$t('pages.groups.removeModalText'),"remove-callback":_vm.removeCallback},on:{"close":function($event){_vm.removeDialogOpened = false},"removed":function($event){return _vm.onRemoved()}}}):_vm._e()],1),_c('v-snackbar',{attrs:{"color":_vm.manageToastType,"timeout":"3000"},model:{value:(_vm.showedManageToast),callback:function ($$v) {_vm.showedManageToast=$$v},expression:"showedManageToast"}},[_vm._v(" "+_vm._s(_vm.$t(("pages.groups." + (_vm.manageToastType === 'success' ? 'createdToast' : 'updatedToast'))))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }