<template>
  <hinted-modal class="fake-modal hinted-modal hinted-widget__custom-styles">
    <div class="hinted-feedback-modal style-classic" data-feedback-modal="">
      <div class="hinted-feedback-modal__wrapper-title">
        <div class="hinted-feedback-modal__title" data-title-feedback-modal="">
          {{ $t('pages.styles.settings.rateHeader') }}
        </div>
        <button
          hinted-name="close"
          hinted-click="remove"
          class="hinted-hint-bubble-close hinted-hint-close hinted-feedback-modal__close"
          title="Закрыть"
        ></button>
      </div>

      <div class="hinted-feedback-modal__content" hinted-name="content-modal">
        <div class="hinted-feedback-modal__wrapper-stars" hinted-name="stars">
          <div class="hinted-feedback-modal__star">
            <button
              type="button"
              hinted-name="star-icon"
              data-grade="1"
              class="hinted-feedback-modal__icon hinted-feedback-modal__icon--active"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="40"
                viewBox="0 0 42 40"
                fill="none"
              >
                <path
                  d="M21.7765 30.864L21 30.3942L20.2235 30.864L10.2893 36.8749L12.927 25.54L13.1318 24.6599L12.4496 24.0675L3.66234 16.436L15.2261 15.4525L16.1281 15.3758L16.4806 14.542L21 3.85207L25.5194 14.542L25.8719 15.3758L26.7739 15.4525L38.3377 16.436L29.5504 24.0675L28.8682 24.6599L29.073 25.54L31.7107 36.8749L21.7765 30.864Z"
                  fill="translate"
                  stroke="currentColor"
                  stroke-width="3"
                ></path>
              </svg>
            </button>
            <div hinted-name="star-title" class="hinted-feedback-modal__title-star">
              {{ $t('pages.styles.settings.rateBadText') }}
            </div>
          </div>
          <div class="hinted-feedback-modal__star">
            <button
              type="button"
              hinted-name="star-icon"
              data-grade="2"
              class="hinted-feedback-modal__icon hinted-feedback-modal__icon--active"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="40"
                viewBox="0 0 42 40"
                fill="none"
              >
                <path
                  d="M21.7765 30.864L21 30.3942L20.2235 30.864L10.2893 36.8749L12.927 25.54L13.1318 24.6599L12.4496 24.0675L3.66234 16.436L15.2261 15.4525L16.1281 15.3758L16.4806 14.542L21 3.85207L25.5194 14.542L25.8719 15.3758L26.7739 15.4525L38.3377 16.436L29.5504 24.0675L28.8682 24.6599L29.073 25.54L31.7107 36.8749L21.7765 30.864Z"
                  fill="translate"
                  stroke="currentColor"
                  stroke-width="3"
                ></path>
              </svg>
            </button>
          </div>
          <div class="hinted-feedback-modal__star">
            <button
              type="button"
              hinted-name="star-icon"
              data-grade="3"
              class="hinted-feedback-modal__icon hinted-feedback-modal__icon--active"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="40"
                viewBox="0 0 42 40"
                fill="none"
              >
                <path
                  d="M21.7765 30.864L21 30.3942L20.2235 30.864L10.2893 36.8749L12.927 25.54L13.1318 24.6599L12.4496 24.0675L3.66234 16.436L15.2261 15.4525L16.1281 15.3758L16.4806 14.542L21 3.85207L25.5194 14.542L25.8719 15.3758L26.7739 15.4525L38.3377 16.436L29.5504 24.0675L28.8682 24.6599L29.073 25.54L31.7107 36.8749L21.7765 30.864Z"
                  fill="translate"
                  stroke="currentColor"
                  stroke-width="3"
                ></path>
              </svg>
            </button>
          </div>
          <div class="hinted-feedback-modal__star">
            <button
              type="button"
              hinted-name="star-icon"
              data-grade="4"
              class="hinted-feedback-modal__icon"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="40"
                viewBox="0 0 42 40"
                fill="none"
              >
                <path
                  d="M21.7765 30.864L21 30.3942L20.2235 30.864L10.2893 36.8749L12.927 25.54L13.1318 24.6599L12.4496 24.0675L3.66234 16.436L15.2261 15.4525L16.1281 15.3758L16.4806 14.542L21 3.85207L25.5194 14.542L25.8719 15.3758L26.7739 15.4525L38.3377 16.436L29.5504 24.0675L28.8682 24.6599L29.073 25.54L31.7107 36.8749L21.7765 30.864Z"
                  fill="translate"
                  stroke="currentColor"
                  stroke-width="3"
                ></path>
              </svg>
            </button>
          </div>
          <div class="hinted-feedback-modal__star">
            <button
              type="button"
              hinted-name="star-icon"
              data-grade="5"
              class="hinted-feedback-modal__icon"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="40"
                viewBox="0 0 42 40"
                fill="none"
              >
                <path
                  d="M21.7765 30.864L21 30.3942L20.2235 30.864L10.2893 36.8749L12.927 25.54L13.1318 24.6599L12.4496 24.0675L3.66234 16.436L15.2261 15.4525L16.1281 15.3758L16.4806 14.542L21 3.85207L25.5194 14.542L25.8719 15.3758L26.7739 15.4525L38.3377 16.436L29.5504 24.0675L28.8682 24.6599L29.073 25.54L31.7107 36.8749L21.7765 30.864Z"
                  fill="translate"
                  stroke="currentColor"
                  stroke-width="3"
                ></path>
              </svg>
            </button>
            <div hinted-name="star-title" class="hinted-feedback-modal__title-star">
              {{ $t('pages.styles.settings.rateGoodText') }}
            </div>
          </div>
        </div>
        <div class="hinted-feedback-modal__comment" data-comment="">
          <div class="hinted-feedback-modal__wrapper-comment" data-comment-title="">
            {{ $t('pages.styles.settings.rateCommentText') }}
          </div>
          <div class="hinted-feedback-modal__wrapper-field">
            <textarea class="hinted-feedback-modal__textarea" data-textarea=""></textarea>
          </div>
          <div class="hinted-feedback-modal__wrapper-footer">
            <div class="hinted-hint-bottom-link">
              <img v-if="customLogo" :src="customLogo" class="custom-logo-icon" />

              <a
                v-else
                :href="`https://${$options.COMPANY_DOMAIN}/`"
                target="_blank"
                class="hinted-hint-bottom-link hinted-feedback-modal__wrapper-logo"
              >
                <span class="logo-powered">Powered&nbsp;by&nbsp;</span>

                <copyright-logo />
              </a>
            </div>
            <div class="hinted-feedback-modal__wrapper-button">
              <button class="hinted-feedback-modal__button-send" data-send-button="">
                {{ $t('pages.styles.settings.rateSendText') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </hinted-modal>
</template>

<script>
  import { buildSpecConfig } from 'src/config';

  import CopyrightLogo from '../CopyrightLogo.vue';

  export default {
    name: 'FakeRating',

    components: {
      CopyrightLogo,
    },

    inject: ['orgSettings'],

    COMPANY_DOMAIN: buildSpecConfig.companyDomain,

    computed: {
      customLogo() {
        return this.orgSettings.logoUrl;
      },
    },
  };
</script>
