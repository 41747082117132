<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <scenario-stats-chart />
      </v-col>

      <template v-if="scenarioId">
        <v-col cols="12">
          <interrupted-steps-chart />
        </v-col>

        <v-col cols="12">
          <scenario-step-errors-table />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';
  import ScenarioStepErrorsTable from 'src/components/Statistics/ScenarioStepErrorsTable.vue';
  import InterruptedStepsChart from 'src/components/Statistics/InterruptedStepsChart.vue';
  import ScenarioStatsChart from 'src/components/Statistics/ScenarioStatsChart.vue';

  export default {
    name: 'Charts',

    components: {
      InterruptedStepsChart,
      ScenarioStepErrorsTable,
      ScenarioStatsChart,
    },

    data() {
      return {
        tabs: [
          'Time to go through walkthrough steps',
          'Average walkthrough playing time',
          'Interrupted and total steps',
          'Walkthrough statistics',
        ],
        tab: null,
      };
    },

    computed: {
      ...mapState('stats', ['scenarioId']),
    },
  };
</script>
