<template>
  <div class="questions-block">
    <div class="questions-block__title">
      {{ $t('pages.billing.supportHeader') }}
    </div>

    <div class="questions-block__text">
      {{ $t('pages.billing.supportText') }} <a href="mailto:sales@hinted.me">sales@hinted.me</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'QuestionsBlock',
  };
</script>

<style lang="scss">
  .questions-block {
    padding: 50px 150px;
    background-color: #ffffff;
    color: #343349;
    text-align: center;

    &__title {
      margin-bottom: 10px;
      font-size: 24px;
      line-height: 32px;
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.005em;

      a {
        color: inherit !important;
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }
</style>
