import { THEME_TYPE_BASIC } from 'src/pages/Styles/utils';

export default {
  model: {
    prop: 'value',
    event: 'update:value',
  },

  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    settings: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    themeType: {
      type: String,
      required: false,
      default: THEME_TYPE_BASIC,
    },
  },

  methods: {
    submitPayload(fieldKey, value) {
      this.$emit('update:value', { ...this.value, [fieldKey]: value });
    },

    updateSettings(fieldKey, value) {
      this.$emit('update:settings', { ...this.settings, [fieldKey]: value });
    },
  },
};
