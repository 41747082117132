<template>
  <div class="origin-list-edit">
    <div
      v-for="(originItem, index) in showedFullOriginList ? localValue : [localValue[0]]"
      :key="index"
      class="d-flex mb-4"
    >
      <v-text-field
        :value="originItem.value"
        :label="$t('components.originListEdit.originLabel')"
        :hint="
          originItem.type === 'regex'
            ? $t('components.originListEdit.originRegexHint') +
              compileStringToRegex(originItem.value)
            : null
        "
        hide-details="auto"
        outlined
        @input="updateOriginValue(index, $event)"
      >
        <template #append>
          <v-icon
            :color="originItem.type === 'regex' ? 'grey darken-4' : 'grey lighten-2'"
            @click.stop="updateOriginType(index)"
          >
            mdi-regex
          </v-icon>
        </template>
      </v-text-field>

      <v-btn :disabled="localValue.length < 2" icon class="ml-2 mt-2" @click="removeOrigin(index)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>

    <div v-if="localValue.length > 1" class="mb-4">
      <span
        v-if="showedFullOriginList"
        class="primary--text subtitle-2 text-decoration-underline origin-list-edit__show-full-text-button"
        @click="showedFullOriginList = false"
      >
        {{ $t('components.originListEdit.hideFullOriginList') }}
      </span>

      <span
        v-else
        class="primary--text subtitle-2 text-decoration-underline origin-list-edit__show-full-text-button"
        @click="showedFullOriginList = true"
      >
        {{ $t('components.originListEdit.showFullOriginList') }} (+{{ localValue.length - 1 }})
      </span>
    </div>

    <div class="d-flex">
      <v-text-field
        v-model="tempOrigin.value"
        :label="$t('components.originListEdit.addOriginLabel')"
        :error-messages="tempOriginError"
        :hint="
          tempOrigin.value && tempOrigin.type === 'regex'
            ? $t('components.originListEdit.originRegexHint') +
              compileStringToRegex(tempOrigin.value)
            : null
        "
        hide-details="auto"
        outlined
        @keydown.prevent.enter="addOrigin()"
      >
        <template #append>
          <v-icon
            :color="tempOrigin.type === 'regex' ? 'grey darken-4' : 'grey lighten-2'"
            @click.stop="tempOrigin.type = tempOrigin.type === 'text' ? 'regex' : 'text'"
          >
            mdi-regex
          </v-icon>
        </template>
      </v-text-field>

      <v-btn color="primary" width="63" height="56" class="ml-2" @click="addOrigin()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { deepcopy } from 'src/utils';

  const defaultOrigin = {
    type: 'text',
    value: null,
  };

  export default {
    name: 'OriginListEdit',

    model: {
      prop: 'modelValue',
      event: 'update:modelValue',
    },

    props: {
      modelValue: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        localValue: deepcopy(this.modelValue || [{ ...defaultOrigin }]),
        tempOrigin: {
          ...defaultOrigin,
        },
        tempOriginError: null,
        showedFullOriginList: false,
      };
    },

    watch: {
      modelValue: {
        handler(value) {
          if (!value || !value.length) {
            this.localValue = [{ ...defaultOrigin }];
          } else {
            this.localValue = deepcopy(value);
          }
        },
        deep: true,
      },
    },

    methods: {
      compileStringToRegex(str) {
        try {
          return new RegExp(str);
        } catch (e) {
          return this.$t('components.originListEdit.originRegexError');
        }
      },

      updateOriginValue(index, value) {
        this.localValue[index].value = value;

        this.$emit('update:modelValue', this.localValue);
      },

      updateOriginType(index) {
        this.localValue[index].type = this.localValue[index].type === 'text' ? 'regex' : 'text';

        this.$emit('update:modelValue', this.localValue);
      },

      removeOrigin(index) {
        if (this.localValue.length < 2) {
          return;
        }

        this.localValue.splice(index, 1);

        this.$emit('update:modelValue', this.localValue);
      },

      addOrigin() {
        this.tempOriginError = null;

        if (!this.tempOrigin.value) {
          return;
        }

        if (
          this.tempOrigin.type === 'text' &&
          !/https?:\/\/(www\.)?[-a-zA-Z\d@:%._+~#=]{1,256}\.[a-zA-Z\d()]{1,6}\b([-a-zA-Z\d()@:%_+.~#?&/=]*)/.test(
            this.tempOrigin.value
          )
        ) {
          this.tempOriginError = this.$t('components.originListEdit.newOriginError');
          return;
        }

        this.localValue.push({ ...this.tempOrigin });

        this.tempOrigin = {
          ...defaultOrigin,
        };

        this.showedFullOriginList = true;

        this.$emit('update:modelValue', this.localValue);
      },
    },
  };
</script>

<style lang="scss">
  .origin-list-edit {
    &__show-full-text-button {
      cursor: pointer;
    }
  }
</style>
