<template>
  <div class="styles-controls">
    <v-card v-if="tabs.length === 1" flat>
      <v-card-text>
        <component
          :is="tabs[0].component"
          :value="value"
          :settings="settings"
          :theme-type="themeType"
          @update:value="$emit('change', $event)"
          @update:settings="$emit('update:settings', $event)"
        />
      </v-card-text>
    </v-card>

    <template v-else>
      <v-tabs
        v-model="tab"
        active-class="white"
        background-color="transparent"
        color="gray"
        hide-slider
        grow
      >
        <v-tab v-for="item in tabs" :key="item.title">
          {{ item.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in tabs" :key="item.title">
          <v-card flat>
            <v-card-text>
              <component
                :is="item.component"
                :value="value"
                :settings="settings"
                :theme-type="themeType"
                @update:value="$emit('change', $event)"
                @update:settings="$emit('update:settings', $event)"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </div>
</template>

<script>
  import InlineColorPicker from 'src/components/InlineColorPicker.vue';
  import { THEME_TYPE_BASIC } from 'src/pages/Styles/utils';
  import HintGeneralStyles from './settings/HintGeneralStyles.vue';
  import HintAdvancedStyles from './settings/HintAdvancedStyles.vue';
  import HighlightGeneralStyles from './settings/HighlightGeneralStyles.vue';
  import TooltipGeneralStyles from './settings/TooltipGeneralStyles.vue';
  import TooltipAdvancedStyles from './settings/TooltipAdvancedStyles.vue';
  import SupportCenterGeneralStyles from './settings/SupportCenterGeneralStyles.vue';
  import RatingGeneralStyles from './settings/RatingGeneralStyles.vue';
  import RatingAdvancedStyles from './settings/RatingAdvancedStyles.vue';
  import ModalGeneralStyles from './settings/ModalGeneralStyles.vue';
  import ModalAdvancedStyles from './settings/ModalAdvancedStyles.vue';
  import SimulationGeneralStyles from './settings/SimulationGeneralStyles.vue';

  const TYPE_HINT = 'hint';
  const TYPE_HIGHLIGHT = 'highlight';
  const TYPE_TOOLTIP = 'tooltip';
  const TYPE_SUPPORT_CENTER = 'supportCenter';
  const TYPE_RATING = 'rating';
  const TYPE_MODAL = 'modal';
  const TYPE_SIMULATION = 'simulation';

  export default {
    name: 'StyleControls',

    components: {
      InlineColorPicker,
    },

    model: {
      prop: 'value',
      event: 'change',
    },

    props: {
      value: {
        type: Object,
        default: () => ({}),
      },

      settings: {
        type: Object,
        default: () => ({}),
      },

      type: {
        type: String,
        required: false,
        default: TYPE_HINT,
        validator: value =>
          [
            TYPE_HINT,
            TYPE_HIGHLIGHT,
            TYPE_TOOLTIP,
            TYPE_SUPPORT_CENTER,
            TYPE_RATING,
            TYPE_MODAL,
            TYPE_SIMULATION,
          ].includes(value),
      },

      themeType: {
        type: String,
        required: false,
        default: THEME_TYPE_BASIC,
      },
    },

    data() {
      return {
        tab: 0,
      };
    },

    computed: {
      tabs() {
        switch (this.type) {
          case TYPE_HINT:
            return [
              {
                title: this.$t('pages.styles.settings.types.general'),
                component: HintGeneralStyles,
              },
              {
                title: this.$t('pages.styles.settings.types.advanced'),
                component: HintAdvancedStyles,
              },
            ];
          case TYPE_MODAL:
            return [
              {
                title: this.$t('pages.styles.settings.types.general'),
                component: ModalGeneralStyles,
              },
              {
                title: this.$t('pages.styles.settings.types.advanced'),
                component: ModalAdvancedStyles,
              },
            ];
          case TYPE_HIGHLIGHT:
            return [
              {
                title: this.$t('pages.styles.settings.types.general'),
                component: HighlightGeneralStyles,
              },
            ];
          case TYPE_TOOLTIP:
            return [
              {
                title: this.$t('pages.styles.settings.types.general'),
                component: TooltipGeneralStyles,
              },
              {
                title: this.$t('pages.styles.settings.types.advanced'),
                component: TooltipAdvancedStyles,
              },
            ];
          case TYPE_SUPPORT_CENTER:
            return [
              {
                title: this.$t('pages.styles.settings.types.general'),
                component: SupportCenterGeneralStyles,
              },
            ];
          case TYPE_RATING:
            return [
              {
                title: this.$t('pages.styles.settings.types.general'),
                component: RatingGeneralStyles,
              },
              {
                title: this.$t('pages.styles.settings.types.advanced'),
                component: RatingAdvancedStyles,
              },
            ];
          case TYPE_SIMULATION:
            return [
              {
                title: this.$t('pages.styles.settings.types.general'),
                component: SimulationGeneralStyles,
              },
            ];
          default:
            throw new Error('Unknown entity type');
        }
      },
    },
  };
</script>

<style lang="scss">
  .styles-controls {
    .input-control {
      max-width: 340px;
    }

    .slider-input {
      width: 30px;

      .v-input__slot::before {
        display: none;
      }
    }

    .block-header {
      font-weight: bold;
      font-size: 1.1rem;
      margin: 40px 0 10px;

      &--first {
        margin-top: 0;
      }
    }
  }
</style>
