var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticClass:"share-popup",attrs:{"close-on-content-click":false,"nudge-width":"476","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-share-variant-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.simulations.sharePopup.tooltip')))])])]}}]),model:{value:(_vm.menuOpened),callback:function ($$v) {_vm.menuOpened=$$v},expression:"menuOpened"}},[_c('v-card',[_c('v-tabs',{staticClass:"mx-4",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('pages.simulations.sharePopup.invite'))+" ")]),(_vm.embedCodeFeatureAvailable)?_c('v-tab',[_vm._v(" Embed ")]):_c('plan-upgrade-menu',{attrs:{"top":"","offset-y":"","nudge-top":"-10","nudge-right":"-150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"share-popup__tab-wrapper"},'div',attrs,false),on),[_c('v-tab',{staticClass:"share-popup__disabled-tab",attrs:{"disabled":""}},[_vm._v(" Embed "),_c('plan-upgrade-chip',{staticClass:"ml-3"})],1)],1)]}}])})],1),(_vm.tab === 0)?_c('v-form',{staticClass:"pa-6",on:{"submit":function($event){$event.preventDefault();return _vm.invite()}}},[_c('v-row',{staticClass:"ma-0"},[_c('v-text-field',{ref:"input",attrs:{"error-messages":_vm.emailError,"label":_vm.$t('pages.simulations.sharePopup.inputLabel'),"outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}}),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","height":"40","type":"submit","loading":_vm.isBusy,"disabled":_vm.isBusy}},[_vm._v(" "+_vm._s(_vm.$t('pages.simulations.sharePopup.invite'))+" ")])],1)],1):_c('div',{staticClass:"pa-6"},[_c('v-row',{staticClass:"ma-0"},[_c('v-textarea',{attrs:{"hide-details":"","outlined":"","readonly":"","no-resize":"","label":_vm.$t('pages.simulations.sharePopup.embedCodeLabel'),"value":_vm.embedCode,"rows":"3"}})],1),_c('v-row',{staticClass:"ma-0 mt-3",attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"disabled":_vm.embedCopied,"color":"primary"},on:{"click":function($event){return _vm.copyEmbedCode()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-code-tags ")]),(!_vm.embedCopied)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('pages.simulations.sharePopup.embedCodeCopyButton'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('pages.simulations.sharePopup.embedCodeCopied'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }