<template>
  <v-app id="admin-panel">
    <template v-if="isUserLoaded && loadedWorkspaces">
      <vertical-header />

      <notifications-panel />

      <template v-if="showedContent">
        <v-main>
          <v-container fluid class="page-wrapper">
            <transition
              name="router-anim"
              enter-active-class="fade-enter-active fade-enter"
              leave-active-class="fade-leave-active fade-enter"
            >
              <router-view />
            </transition>
          </v-container>
        </v-main>

        <VerticalSidebar />
      </template>
    </template>

    <template v-else>
      <v-app-bar app clipped-left clipped-right color="white">
        <v-skeleton-loader width="50px" height="50px" loading type="avatar" />

        <v-spacer />

        <v-skeleton-loader width="50px" height="50px" loading type="avatar" />
      </v-app-bar>

      <v-main>
        <v-navigation-drawer
          mobile-breakpoint="960"
          clipped
          dark
          :right="$vuetify.rtl"
          mini-variant-width="70"
          app
        >
          <v-skeleton-loader
            width="100%"
            loading
            elevation="0"
            type="list-item-avatar,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item"
          />

          <template #append>
            <div class="pa-2">
              <v-skeleton-loader width="100%" loading elevation="0" type="list-item" />
            </div>
          </template>
        </v-navigation-drawer>

        <v-container fluid>
          <v-skeleton-loader
            width="100%"
            height="100%"
            loading
            type="table-heading, table-thead, table-row-divider@12, table-tfoot"
          />
        </v-container>
      </v-main>
    </template>
  </v-app>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';

  import { isAuth } from 'src/services/session';
  import { currentLocale } from 'src/plugins/i18n';

  import eventsService from 'src/services/events';

  import VerticalHeader from 'src/components/VerticalHeader/VerticalHeader.vue';
  import VerticalSidebar from 'src/components/VerticalSidebar/VerticalSidebar.vue';
  import NotificationsPanel from 'src/components/NotificationsPanel/NotificationsPanel.vue';

  export default {
    name: 'Main',

    components: {
      VerticalHeader,
      VerticalSidebar,
      NotificationsPanel,
    },

    data() {
      return {
        showedContent: true,
        loadedWorkspaces: false,
      };
    },

    computed: {
      ...mapState('users', ['currentUser']),
      ...mapGetters('users', ['isUserLoaded']),
      ...mapGetters('workspaces', ['hasAccessToWorkspaces', 'selectedWorkspace']),

      workspaceId() {
        return this.currentUser.workspaceId || null;
      },
    },

    watch: {
      async workspaceId(value, oldValue) {
        if (!oldValue) {
          return;
        }

        if (!this.$route.meta.workspaceAccess && !this.selectedWorkspace.isRoot) {
          this.$router.push({ name: 'Dashboard' });
        }

        this.showedContent = false;
        await this.$nextTick();
        this.showedContent = true;
      },
    },

    async mounted() {
      if (!isAuth()) {
        return;
      }

      await this.setAuthState();

      this.getCurrentUser()
        .then(async () => {
          this.setLocale(currentLocale);

          if (this.hasAccessToWorkspaces) {
            await this.getWorkspacesList();
          }

          this.loadedWorkspaces = true;

          eventsService.connect();
        })
        .catch(err => {
          if (err.code !== 'PAYMENT_REQUIRED') {
            this.reset();
          }
        });
    },

    methods: {
      ...mapActions('auth', ['setAuthState', 'clearData']),
      ...mapActions('users', ['getCurrentUser', 'setLocale']),
      ...mapActions('workspaces', ['getWorkspacesList']),

      async reset() {
        await this.clearData();
        await this.setAuthState();

        if (this.$route.name !== 'login') {
          this.$router.push({ name: 'login' });
        }
      },
    },
  };
</script>
