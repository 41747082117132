<template>
  <v-card>
    <v-card-title class="pb-0">
      <span class="headline">
        <template v-if="isEdit">
          {{ $t('ui.groupManage.editTitle', { groupTitle: group.title }) }}
        </template>

        <template v-else>
          {{ $t('ui.groupManage.createTitle') }}
        </template>
      </span>

      <v-spacer />

      <v-btn icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="px-0">
      <v-stepper v-model="stepperState" elevation="0" vertical non-linear>
        <v-stepper-step step="1" :editable="isEdit">
          {{ $t('ui.groupManage.settings') }}

          <small class="mt-1">{{ $t('ui.groupManage.settingsCaption') }}</small>
        </v-stepper-step>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="form" class="checklist-edit-modal__form" @submit.prevent="submitGroup()">
              <v-container class="mb-6" fluid>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      ref="titleInput"
                      v-model="group.title"
                      :label="$t('ui.groupManage.titleLabel')"
                      :rules="[getRequiredRule]"
                      required
                      :error-messages="error ? [error] : null"
                      hide-details="auto"
                      @input="error = null"
                    />
                  </v-col>
                </v-row>
              </v-container>

              <v-btn color="primary" :loading="isBusySubmit" type="submit">
                {{ $t(`ui.common.${isEdit ? 'save' : 'create'}`) }}
              </v-btn>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>

        <v-stepper-step step="2" :editable="isEdit">
          {{ $t('ui.groupManage.users') }}

          <small class="mt-1">
            {{ $t('ui.groupManage.usersCaption') }}
          </small>
        </v-stepper-step>

        <v-stepper-items>
          <v-stepper-content step="2">
            <group-users-adding-form v-if="group.id" :entity="group" :per-page="3" embedded />
          </v-stepper-content>
        </v-stepper-items>

        <v-stepper-step step="3" :editable="isEdit">
          {{ $t('ui.groupManage.scenarios') }}

          <small class="mt-1">
            {{ $t('ui.groupManage.scenariosCaption') }}
          </small>
        </v-stepper-step>

        <v-stepper-items>
          <v-stepper-content step="3">
            <v-tabs v-model="selectedMode" class="mb-4" height="54">
              <v-tab class="text-none">
                <v-icon class="mr-3">mdi-google-chrome</v-icon>

                {{ $t('pages.scenarios.extensionTitle', { companyName: $options.COMPANY_NAME }) }}
              </v-tab>

              <v-tab class="text-none">
                <v-icon class="mr-3">mdi-monitor</v-icon>

                {{ $options.DESKTOP_NAME }}
              </v-tab>
            </v-tabs>

            <group-scenarios-adding-form
              v-if="showedModeComponent && group.id"
              :mode="mappedSelectedMode"
              :entity="group"
              :per-page="3"
              embedded
            />
          </v-stepper-content>
        </v-stepper-items>

        <v-stepper-step step="4" :editable="isEdit">
          {{ $t('ui.groupManage.tooltips') }}

          <small class="mt-1">
            {{ $t('ui.groupManage.tooltipsCaption') }}
          </small>
        </v-stepper-step>

        <v-stepper-items>
          <v-stepper-content step="4">
            <v-tabs v-model="selectedMode" class="mb-4" height="54">
              <v-tab class="text-none">
                <v-icon class="mr-3">mdi-google-chrome</v-icon>

                {{ $t('pages.scenarios.extensionTitle', { companyName: $options.COMPANY_NAME }) }}
              </v-tab>

              <v-tab class="text-none">
                <v-icon class="mr-3">mdi-monitor</v-icon>

                {{ $options.DESKTOP_NAME }}
              </v-tab>
            </v-tabs>

            <group-tooltips-adding-form
              v-if="showedModeComponent && group.id"
              :mode="mappedSelectedMode"
              :entity="group"
              :per-page="3"
              embedded
            />
          </v-stepper-content>
        </v-stepper-items>

        <v-stepper-step step="5" :editable="isEdit">
          {{ $t('ui.groupManage.links') }}

          <small class="mt-1">
            {{ $t('ui.groupManage.linksCaption') }}
          </small>
        </v-stepper-step>

        <v-stepper-items>
          <v-stepper-content step="5">
            <group-links-adding-form
              v-if="showedModeComponent && group.id"
              :entity="group"
              :per-page="3"
              embedded
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>

    <v-overlay :value="isBusyGetGroup" absolute>
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-card>
</template>

<script>
  import pick from 'lodash.pick';

  import { busyFlow } from 'src/utils';
  import api from 'src/api';
  import { buildSpecConfig } from 'src/config';

  import GroupUsersAddingForm from 'src/components/AddingForms/GroupUsersAddingForm.vue';
  import GroupScenariosAddingForm from 'src/components/AddingForms/GroupScenariosAddingForm.vue';
  import GroupTooltipsAddingForm from 'src/components/AddingForms/GroupTooltipsAddingForm.vue';
  import GroupLinksAddingForm from 'src/components/AddingForms/GroupLinksAddingForm.vue';

  const editableFields = ['title'];

  export default {
    name: 'GroupManageModal',

    components: {
      GroupUsersAddingForm,
      GroupScenariosAddingForm,
      GroupTooltipsAddingForm,
      GroupLinksAddingForm,
    },

    props: {
      groupId: {
        type: String,
        required: false,
        default: null,
      },

      initialStep: {
        type: Number,
        required: false,
        default: 1,
      },
    },

    data() {
      return {
        id: this.groupId,
        isEdit: Boolean(this.groupId),
        stepperState: this.initialStep,

        isBusyGetGroup: false,
        isBusySubmit: false,

        group: {
          title: null,
        },

        selectedMode: 0,
        showedModeComponent: true,

        error: null,

        getRequiredRule: v => !!v || this.$t('validation.notNull'),
      };
    },

    COMPANY_NAME: buildSpecConfig.companyName,
    DESKTOP_NAME: buildSpecConfig.desktopName,

    computed: {
      mappedSelectedMode() {
        switch (this.selectedMode) {
          case 1:
            return 'DESKTOP';
          case 0:
          default:
            return 'WEB';
        }
      },
    },

    watch: {
      mappedSelectedMode() {
        this.refreshSelectedModeComponent();
      },
    },

    async mounted() {
      if (this.isEdit) {
        await this.getGroup();
      }

      await this.$nextTick();
      this.$refs.titleInput.focus();
    },

    methods: {
      getGroup() {
        return busyFlow.call(
          this,
          async () => {
            const { response } = await api.groups.getOne({ url_params: { id: this.id } });

            this.group = response;
          },
          false,
          'isBusyGetGroup'
        );
      },

      submitGroup() {
        if (!this.$refs.form.validate()) {
          return;
        }

        busyFlow.call(
          this,
          async () => {
            try {
              if (this.isEdit) {
                await api.groups.update({
                  url_params: { id: this.id },
                  data: pick(this.group, editableFields),
                });

                this.$emit('updated');
              } else {
                const { response } = await api.groups.create({
                  data: pick(this.group, editableFields),
                });

                this.id = response.id;
                this.group = response;
                this.isEdit = true;
                this.stepperState = 2;

                this.$emit('created');
              }
            } catch (error) {
              this.error = this.$te(`serverCode.${error.code}`)
                ? this.$t(`serverCode.${error.code}`)
                : this.$t('serverCode.serverError');
            }
          },
          false,
          'isBusySubmit'
        );
      },

      close() {
        this.$emit('close');
      },

      async refreshSelectedModeComponent() {
        this.showedModeComponent = false;
        await this.$nextTick();
        this.showedModeComponent = true;
      },
    },
  };
</script>

<style></style>
