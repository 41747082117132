const scenarioCards = {
  name: 'scenarioCards',
  method: 'get',
  url: 'cards',
};

const getScenarioStatistics = {
  name: 'getScenarioStatistics',
  method: 'get',
  url: 'scenario-stats',
};

const byScenario = {
  name: 'byScenario',
  method: 'get',
  url: 'scenario/:id',
};

const scenarioStepsErrors = {
  name: 'scenarioStepsErrors',
  method: 'get',
  url: 'scenario-errors/:id',
};

const getDurationToAmountChart = {
  name: 'getDurationToAmountChart',
  method: 'get',
  url: 'charts/duration-to-amount/:scenarioId',
};

const getStepDurationsChart = {
  name: 'getStepDurationsChart',
  method: 'get',
  url: 'charts/step-durations/:scenarioId',
};

const getInterruptedToTotalChart = {
  name: 'getInterruptedToTotalChart',
  method: 'get',
  url: 'charts/interrupted-total/:scenarioId',
};

const getScenarioStatsChart = {
  name: 'getScenarioStatsChart',
  method: 'get',
  url: 'charts/scenario-stats',
};

const getScenarioStepsStatsChart = {
  name: 'getScenarioStepsStatsChart',
  method: 'get',
  url: 'charts/scenario-steps-stats/:scenarioId',
};

const getSimulationStatsChart = {
  name: 'getSimulationStatsChart',
  method: 'get',
  url: 'charts/simulation-stats',
};

const getSimulationTestingStats = {
  name: 'getSimulationTestingStats',
  method: 'get',
  url: 'simulation-testing',
};

const getTooltipStatsChart = {
  name: 'getTooltipStatsChart',
  method: 'get',
  url: 'charts/tooltip-stats/:tooltipId',
};

const downloadStepStatistics = {
  name: 'downloadStepStatistics',
  method: 'get',
  url: 'download-steps-stats',
  options: {
    responseType: 'blob',
  },
};

const downloadSimulationsStatistics = {
  name: 'downloadSimulationsStatistics',
  method: 'get',
  url: 'download-simulations-stats',
  options: {
    responseType: 'blob',
  },
};

const downloadTooltipStatistics = {
  name: 'downloadTooltipStatistics',
  method: 'get',
  url: 'download-tooltips-stats/:tooltipId',
  options: {
    responseType: 'blob',
  },
};

const downloadTestingResults = {
  name: 'downloadTestingResults',
  method: 'get',
  url: 'simulation-testing/download',
  options: {
    responseType: 'blob',
  },
};

const downloadHistoricalStepStats = {
  name: 'downloadHistoricalStepStats',
  method: 'get',
  url: 'download-historic-step-stats',
  options: {
    responseType: 'blob',
  },
};

export default {
  name: 'stats',
  url: '/api/statistics/',
  children: [
    scenarioCards,
    getScenarioStatistics,
    byScenario,
    scenarioStepsErrors,
    getDurationToAmountChart,
    getStepDurationsChart,
    getInterruptedToTotalChart,
    getScenarioStatsChart,
    getScenarioStepsStatsChart,
    getSimulationStatsChart,
    getSimulationTestingStats,
    downloadStepStatistics,
    downloadSimulationsStatistics,
    downloadTooltipStatistics,
    getTooltipStatsChart,
    downloadTestingResults,
    downloadHistoricalStepStats,
  ],
};
