<template>
  <v-dialog persistent :value="true" width="509" class="billing-feedback-success-modal">
    <v-card class="billing-feedback-success-modal__content">
      <img src="~src/assets/feedbackSent.svg" class="billing-feedback-success-modal__image" />

      <div class="billing-feedback-success-modal__title">
        {{ $t('pages.billing.feedbackModal.successHeader') }}
      </div>

      <div class="billing-feedback-success-modal__text">
        {{ $t('pages.billing.feedbackModal.successText') }}
      </div>

      <billing-button right large theme="green" @click="$emit('close')">
        {{ $t('pages.billing.feedbackModal.return') }}
      </billing-button>
    </v-card>
  </v-dialog>
</template>

<script>
  import BillingButton from '../BillingButton/BillingButton.vue';

  export default {
    name: 'BillingFeedbackSuccessModal',

    components: {
      BillingButton,
    },
  };
</script>

<style lang="scss">
  .billing-feedback-success-modal {
    font-family: 'Roboto', sans-serif;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 0;
      text-align: center;
    }

    &__image {
      width: 73px;
      margin-bottom: 20px;
    }

    &__title {
      font-weight: 400;
      font-size: 32px;
      line-height: 38px;
      color: #343349;
      margin-bottom: 20px;
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.005em;
      margin-bottom: 20px;
    }
  }
</style>
