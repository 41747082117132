import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from 'vuetify/es5/locale/en';
import ru from 'vuetify/es5/locale/ru';
import pt from 'vuetify/es5/locale/pt';
import es from 'vuetify/es5/locale/es';

import 'src/scss/vuetify/overrides.scss';

import { currentLocale } from 'src/plugins/i18n';
import localStorage from 'src/services/localStorage';
import MailIcon from 'src/components/Icons/MailIcon.vue';

Vue.use(Vuetify);

const theme = {
  primary: '#D71B5A',
  secondary: '#02AC5E',
  info: '#1e88e5',
  success: '#06d79c',
  accent: '#ef5350',
  default: '#563dea',
};

const getVuetifyLocale = () => {
  const sessionLocale = localStorage.get('interfaceLocale');
  if (sessionLocale) {
    return sessionLocale;
  }

  return currentLocale;
};

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
    dark: false, // If you want to set dark theme then dark:true else set to false
  },

  icons: {
    values: {
      mail: {
        component: MailIcon,
      },
    },
  },

  lang: {
    locales: { en, ru, pt, es },
    current: getVuetifyLocale(),
  },

  rtl: false, // If you want to set rtl theme then rtl:true else set to false
});
