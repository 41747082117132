<template>
  <div
    id="hinted__container-floating"
    class="hinted-hint-widget bottom-right fake-support-center"
    style="z-index: 1000;"
  >
    <div data-he-name="discovery">
      <div
        class="first-time-discovery"
        :class="{ show: showedFirstTimeDiscovery }"
        data-he-name="discovery"
      >
        <div data-he-name="discoveryTitle" class="hello-message">
          {{ firstTimeHeader }}
        </div>
        <div data-he-name="discoveryDescription" class="hello-description">
          {{ firstTimeText }}
        </div>
      </div>
    </div>

    <div
      class="floating-wrapper"
      data-he-name="floatingWrapper"
      data-floating-wrapper=""
      :class="{
        'style-classic': classicThemeType,
        'floating-wrapper--show': openedScenariosList || openedEmptyList,
        opened: openedScenariosList || openedEmptyList,
      }"
    >
      <div
        class="floating-wrapper__container "
        :class="{ 'floating-wrapper__container--show': openedScenariosList || openedEmptyList }"
        data-he-name="floatingContainer"
      >
        <template v-if="!openedEmptyList">
          <div class="hinted__scenarios-search-container">
            <input
              id="hinted__scenarios-search"
              data-he-name="search"
              click=""
              :placeholder="$t('pages.styles.supportCenter.search')"
            />
            <i class="hinted__scenarios-search-icon"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M12.5302 11.1918H11.7624L11.4902 10.9294C12.4428 9.82133 13.0162 8.38279 13.0162 6.8179C13.0162 3.32847 10.1878 0.5 6.69833 0.5C3.20891 0.5 0.380432 3.32847 0.380432 6.8179C0.380432 10.3073 3.20891 13.1358 6.69833 13.1358C8.26322 13.1358 9.70176 12.5623 10.8098 11.6098L11.0723 11.8819V12.6498L15.9322 17.5L17.3804 16.0517L12.5302 11.1918ZM6.69833 11.1918C4.27809 11.1918 2.3244 9.23814 2.3244 6.8179C2.3244 4.39766 4.27809 2.44397 6.69833 2.44397C9.11857 2.44397 11.0723 4.39766 11.0723 6.8179C11.0723 9.23814 9.11857 11.1918 6.69833 11.1918Z"
                  fill="#C0C3CB"
                ></path></svg
            ></i>
            <button class="hinted__scenarios-search-close-icon" data-he-name="close-search">
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L9 9M9 1L1 9"
                  stroke="#BBBBC6"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
              </svg>
            </button>
          </div>
        </template>
        <div class="floating-wrapper__content">
          <template v-if="!openedEmptyList">
            <details class="hinted__controller" data-he-name="controller-details" open="">
              <summary class="hinted__controller-title" data-title-controller=""
                ><span class="hinted__controller-title-icon"></span>
                <span>{{ $t('pages.styles.supportCenter.checklistsTitle') }}</span>
                <i class="hinted__arrow-icon"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                  >
                    <path
                      d="M13 7L7 0.999999L1 7"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path></svg></i
              ></summary>
              <div
                data-he-name="controller"
                class="hinted__controller-wrapper"
                data-control-list=""
              >
                <ul class="hinted__controller-list">
                  <li
                    data-control-item="f2e5faee-9e53-496e-b504-19fdd116d42b"
                    class="hinted__controller-list-item hinted-play-btn"
                  >
                    <span class="hinted__controller-list-item-icon"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                      >
                        <path
                          d="M12.4 3.39999H13.8C14.1713 3.39999 14.5274 3.54749 14.7899 3.81004C15.0525 4.0726 15.2 4.42869 15.2 4.79999V14.6C15.2 14.9713 15.0525 15.3274 14.7899 15.5899C14.5274 15.8525 14.1713 16 13.8 16H5.4C5.0287 16 4.6726 15.8525 4.41005 15.5899C4.1475 15.3274 4 14.9713 4 14.6V4.79999C4 4.42869 4.1475 4.0726 4.41005 3.81004C4.6726 3.54749 5.0287 3.39999 5.4 3.39999H6.8"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11.7 2H7.49999C7.11339 2 6.79999 2.3134 6.79999 2.7V4.1C6.79999 4.4866 7.11339 4.8 7.49999 4.8H11.7C12.0866 4.8 12.4 4.4866 12.4 4.1V2.7C12.4 2.3134 12.0866 2 11.7 2Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M12.4 10.4H6.79999"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M12.4 7.60001H6.79999"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M12.4 13.2H6.79999"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path></svg
                    ></span>
                    <span>{{ $t('pages.styles.supportCenter.checklistExampleOne') }}</span>
                  </li>
                  <li
                    data-control-item="fc9c523b-1f0d-4ce5-872f-7e9a589b704a"
                    class="hinted__controller-list-item hinted-play-btn"
                  >
                    <span class="hinted__controller-list-item-icon"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                      >
                        <path
                          d="M12.4 3.39999H13.8C14.1713 3.39999 14.5274 3.54749 14.7899 3.81004C15.0525 4.0726 15.2 4.42869 15.2 4.79999V14.6C15.2 14.9713 15.0525 15.3274 14.7899 15.5899C14.5274 15.8525 14.1713 16 13.8 16H5.4C5.0287 16 4.6726 15.8525 4.41005 15.5899C4.1475 15.3274 4 14.9713 4 14.6V4.79999C4 4.42869 4.1475 4.0726 4.41005 3.81004C4.6726 3.54749 5.0287 3.39999 5.4 3.39999H6.8"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11.7 2H7.49999C7.11339 2 6.79999 2.3134 6.79999 2.7V4.1C6.79999 4.4866 7.11339 4.8 7.49999 4.8H11.7C12.0866 4.8 12.4 4.4866 12.4 4.1V2.7C12.4 2.3134 12.0866 2 11.7 2Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M12.4 10.4H6.79999"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M12.4 7.60001H6.79999"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M12.4 13.2H6.79999"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path></svg
                    ></span>
                    <span>{{ $t('pages.styles.supportCenter.checklistExampleTwo') }}</span>
                  </li>
                  <li
                    data-control-item="d7cf0df8-9069-4624-b32f-83802c3fd428"
                    class="hinted__controller-list-item hinted-play-btn"
                  >
                    <span class="hinted__controller-list-item-icon"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                      >
                        <path
                          d="M12.4 3.39999H13.8C14.1713 3.39999 14.5274 3.54749 14.7899 3.81004C15.0525 4.0726 15.2 4.42869 15.2 4.79999V14.6C15.2 14.9713 15.0525 15.3274 14.7899 15.5899C14.5274 15.8525 14.1713 16 13.8 16H5.4C5.0287 16 4.6726 15.8525 4.41005 15.5899C4.1475 15.3274 4 14.9713 4 14.6V4.79999C4 4.42869 4.1475 4.0726 4.41005 3.81004C4.6726 3.54749 5.0287 3.39999 5.4 3.39999H6.8"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11.7 2H7.49999C7.11339 2 6.79999 2.3134 6.79999 2.7V4.1C6.79999 4.4866 7.11339 4.8 7.49999 4.8H11.7C12.0866 4.8 12.4 4.4866 12.4 4.1V2.7C12.4 2.3134 12.0866 2 11.7 2Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M12.4 10.4H6.79999"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M12.4 7.60001H6.79999"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M12.4 13.2H6.79999"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path></svg
                    ></span>
                    <span>{{ $t('pages.styles.supportCenter.checklistExampleThree') }}</span>
                  </li>
                </ul>
              </div>
            </details>
          </template>

          <details
            v-if="!openedEmptyList"
            class="hinted__scenarios"
            open=""
            data-he-name="scenario-details"
          >
            <summary class="hinted__scenarios-title" data-title-scenario=""
              ><span class="hinted__scenarios-title-icon"></span>
              <span>{{ $t('pages.styles.supportCenter.scenariosTitle') }}</span>
              <i class="hinted__arrow-icon"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                >
                  <path
                    d="M13 7L7 0.999999L1 7"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path></svg></i
            ></summary>
            <div data-he-name="scenarios">
              <div id="hinted__scenarios">
                <div id="hinted__scenarios-body" data-he-name="list">
                  <button class="hinted-play-btn" type="button">
                    <span class="hinted__scenarios-body-icon"
                      ><svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.309 8.7262L8.40194 6.613C8.29525 6.53566 8.15362 6.52401 8.03669 6.58411C7.91882 6.64374 7.84521 6.76487 7.84521 6.89578V11.1208C7.84521 11.2531 7.91882 11.3738 8.03669 11.4334C8.08654 11.4586 8.14105 11.4711 8.19602 11.4711C8.26776 11.4711 8.34044 11.4483 8.40194 11.4031L11.309 9.29177C11.4008 9.22422 11.4543 9.11987 11.4543 9.00899C11.4548 8.89625 11.3998 8.79236 11.309 8.7262Z"
                          fill="#92929E"
                        ></path>
                        <path
                          d="M9.00023 2.00098C5.13347 2.00098 2 5.13445 2 9.00121C2 12.8666 5.13347 15.9991 9.00023 15.9991C12.8661 15.9991 16 12.8661 16 9.00121C16.0005 5.13445 12.8661 2.00098 9.00023 2.00098ZM9.00023 14.8312C5.78011 14.8312 3.16935 12.2218 3.16935 9.00121C3.16935 5.78202 5.78011 3.16939 9.00023 3.16939C12.2199 3.16939 14.8302 5.78155 14.8302 9.00121C14.8307 12.2218 12.2199 14.8312 9.00023 14.8312Z"
                          fill="#92929E"
                        ></path></svg
                    ></span>
                    {{ $t('pages.styles.supportCenter.scenarioExampleOne') }}
                  </button>

                  <button class="hinted-play-btn" type="button">
                    <span class="hinted__scenarios-body-icon"
                      ><svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.309 8.7262L8.40194 6.613C8.29525 6.53566 8.15362 6.52401 8.03669 6.58411C7.91882 6.64374 7.84521 6.76487 7.84521 6.89578V11.1208C7.84521 11.2531 7.91882 11.3738 8.03669 11.4334C8.08654 11.4586 8.14105 11.4711 8.19602 11.4711C8.26776 11.4711 8.34044 11.4483 8.40194 11.4031L11.309 9.29177C11.4008 9.22422 11.4543 9.11987 11.4543 9.00899C11.4548 8.89625 11.3998 8.79236 11.309 8.7262Z"
                          fill="#92929E"
                        ></path>
                        <path
                          d="M9.00023 2.00098C5.13347 2.00098 2 5.13445 2 9.00121C2 12.8666 5.13347 15.9991 9.00023 15.9991C12.8661 15.9991 16 12.8661 16 9.00121C16.0005 5.13445 12.8661 2.00098 9.00023 2.00098ZM9.00023 14.8312C5.78011 14.8312 3.16935 12.2218 3.16935 9.00121C3.16935 5.78202 5.78011 3.16939 9.00023 3.16939C12.2199 3.16939 14.8302 5.78155 14.8302 9.00121C14.8307 12.2218 12.2199 14.8312 9.00023 14.8312Z"
                          fill="#92929E"
                        ></path></svg
                    ></span>
                    {{ $t('pages.styles.supportCenter.scenarioExampleTwo') }}
                  </button>
                </div>
              </div>
            </div>
          </details>
          <div v-else id="hinted__scenarios">
            <div id="hinted__scenarios-body" data-he-name="list">
              <div class="empty">
                {{ listEmptyText }}
              </div>
            </div>
          </div>
        </div>

        <div id="hinted__scenarios-title" data-he-name="title">
          <div class="floating-title">{{ listHeader }}</div>
        </div>
        <div class="hinted__logo">
          <div v-if="customLogo" class="hinted-hint-bottom-link">
            <img :src="customLogo" class="custom-logo-icon" />
          </div>

          <a
            v-else
            :href="`https://${$options.COMPANY_DOMAIN}/`"
            target="_blank"
            class="hinted-hint-bottom-link"
          >
            <span class="logo-powered">Powered&nbsp;by&nbsp;</span>

            <copyright-logo />
          </a>
        </div>
      </div>
      <div
        v-if="(!openedScenariosList && !openedEmptyList && classicThemeType) || !classicThemeType"
        id="hinted__floating-button"
        data-toggle="tooltip"
        data-he-name="btn"
        data-placement="left"
        data-original-title="Create"
      >
        <p class="hinted__plus">
          <svg
            class="hinted__plus-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="27"
            viewBox="0 0 96 442"
          >
            <path
              data-he-name="hint"
              fill="#d71b5a"
              d="M85 108v334H9V108h76zM38 1a55 55 0 0136 6 46 46 0 0113 11 40 40 0 017 15 36 36 0 01-7 32c-2 2 11 16 9 18-3 2-19-9-22-7a50 50 0 01-17 6 55 55 0 01-36-6A47 47 0 018 65a40 40 0 01-7-15 36 36 0 017-32A43 43 0 0121 7a50 50 0 0117-6z"
            ></path>
          </svg>
          <svg
            class="hinted__tooltip-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="59"
            height="59"
            viewBox="0 0 59 59"
            fill="none"
          >
            <path
              data-he-name="hint"
              d="M58.2349 29.5C58.6574 29.5 59.001 29.8426 58.9901 30.2651C58.8963 33.8783 58.1393 37.4458 56.7544 40.7892C55.2719 44.3683 53.099 47.6203 50.3596 50.3596C47.6203 53.099 44.3683 55.2719 40.7892 56.7544C37.4458 58.1393 33.8783 58.8963 30.265 58.9901C29.8426 59.001 29.5 58.6574 29.5 58.2349C29.5 57.8123 29.8426 57.4708 30.265 57.4593C33.6773 57.3659 37.0459 56.6486 40.2036 55.3407C43.597 53.9351 46.6804 51.8748 49.2776 49.2776C51.8748 46.6804 53.9351 43.597 55.3407 40.2036C56.6486 37.0459 57.3659 33.6773 57.4593 30.265C57.4708 29.8426 57.8123 29.5 58.2349 29.5Z"
              fill="white"
            ></path>
            <path
              data-he-name="hint"
              d="M0.765121 29.5C0.342556 29.5 -0.0010376 29.1574 0.00992203 28.7349C0.103653 25.1217 0.860703 21.5542 2.24555 18.2108C3.72807 14.6317 5.90102 11.3797 8.64035 8.64035C11.3797 5.90102 14.6317 3.72807 18.2108 2.24555C21.5542 0.860703 25.1217 0.103653 28.735 0.00992203C29.1574 -0.0010376 29.5 0.342556 29.5 0.765121C29.5 1.18769 29.1574 1.52915 28.735 1.54071C25.3227 1.63408 21.9541 2.35137 18.7964 3.65932C15.403 5.06493 12.3196 7.12516 9.7224 9.7224C7.12516 12.3196 5.06493 15.403 3.65932 18.7964C2.35137 21.9541 1.63408 25.3227 1.54071 28.735C1.52915 29.1574 1.18769 29.5 0.765121 29.5Z"
              fill="white"
            ></path>
            <path
              data-he-name="hint"
              d="M34.0011 40.9082C33.4252 43.2666 31.523 43.9351 29.3643 43.9351C27.9434 43.9351 26.8382 43.5864 26.05 42.8939C25.2618 42.1985 24.8676 41.3175 24.8676 40.2486C24.8676 39.8349 24.8962 39.4093 24.9556 38.9756C25.0156 38.5415 25.111 38.0526 25.2415 37.5061L26.7083 32.3056C26.8388 31.8076 26.9497 31.3358 27.0385 30.8896C27.1286 30.4462 27.172 30.0382 27.172 29.6709C27.172 29.0068 27.0347 28.5423 26.7615 28.2811C25.8375 27.4006 24.9952 28.0365 24 28.4053C24.5704 26.0738 26.4887 25.3769 28.6441 25.3769C30.0552 25.3769 31.144 25.7185 31.9087 26.4018C32.6733 27.0855 33.0555 27.9719 33.0555 29.0641C33.0555 29.2901 33.0304 29.6883 32.9764 30.2574C32.9237 30.8278 32.8256 31.3504 32.6826 31.8257L31.2221 37.0054C31.1024 37.4214 30.9947 37.897 30.9011 38.4322C30.8045 38.9638 30.7583 39.3701 30.7583 39.6425C30.7583 40.3303 30.9114 40.7999 31.2183 41.0495C32.1859 41.8308 33.0077 41.3756 34.0011 40.9082ZM35.3715 19.1626C35.3715 20.0651 35.032 20.8358 34.3496 21.4695C33.6691 22.1055 32.849 22.4238 31.8896 22.4238C30.9272 22.4238 30.1051 22.1055 29.4167 21.4695C28.7296 20.8356 28.3853 20.0651 28.3853 19.1626C28.3853 18.262 28.7296 17.49 29.4167 16.8482C30.1038 16.2075 30.9275 15.8872 31.8896 15.8872C32.8487 15.8872 33.6691 16.2082 34.3496 16.8482C35.0325 17.49 35.3715 18.2622 35.3715 19.1626Z"
              fill="white"
            ></path>
          </svg>
        </p>
        <p class="hinted__edit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="35px"
            version="1.1"
            height="27px"
            viewBox="0 0 64 64"
            enable-background="new 0 0 64 64"
          >
            <g>
              <path
                id="move"
                fill="#FFFFFF"
                d="m63.875,31.203c-0.102-0.246-0.248-0.467-0.435-0.652l-6.837-6.838c-0.783-0.783-2.051-0.783-2.834,0-0.781,0.781-0.781,2.05 0,2.832l3.42,3.42-23.16-.001 .002-23.155 3.568,3.57c0.393,0.392 0.904,0.588 1.418,0.588 0.512,0 1.025-0.196 1.416-0.588 0.783-0.781 0.783-2.051 0-2.834l-6.988-6.99c-0.186-0.186-0.406-0.332-0.652-0.434-0.49-0.203-1.041-0.203-1.531,0-0.244,0.101-0.463,0.246-0.646,0.429 0,0-0.002,0.002-0.004,0.003l-6.844,6.84c-0.781,0.783-0.781,2.051 0,2.834 0.393,0.391 0.904,0.587 1.418,0.587 0.512,0 1.025-0.196 1.416-0.587l3.422-3.42-.002,23.157-23.15-.001 3.417-3.418c0.781-0.782 0.781-2.051 0-2.832-0.783-0.783-2.051-0.783-2.834,0l-6.838,6.84c-0.393,0.391-0.588,0.903-0.588,1.416s0.195,1.025 0.588,1.417l6.988,6.989c0.392,0.393 0.904,0.588 1.417,0.588s1.025-0.195 1.417-0.588c0.782-0.783 0.782-2.051 0-2.833l-3.571-3.571 23.153,.001-.001,23.153-3.418-3.417c-0.783-0.78-2.051-0.782-2.834,0.001-0.781,0.783-0.781,2.052 0,2.834l6.844,6.839c0.391,0.392 0.904,0.587 1.416,0.587 0.513,0 1.025-0.195 1.416-0.587l6.99-6.991c0.783-0.783 0.783-2.053 0-2.834-0.783-0.783-2.051-0.783-2.834,0l-3.572,3.574 .002-23.159 23.16,.001-3.57,3.569c-0.781,0.782-0.781,2.05 0,2.833 0.393,0.393 0.904,0.588 1.418,0.588 0.512,0 1.025-0.195 1.416-0.588l6.989-6.989c0.004-0.005 0.006-0.012 0.012-0.017 0.177-0.182 0.321-0.396 0.421-0.633 0.102-0.246 0.154-0.506 0.154-0.768-0.001-0.259-0.053-0.52-0.155-0.765z"
              ></path>
            </g>
          </svg>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { buildSpecConfig } from 'src/config';

  import CopyrightLogo from '../CopyrightLogo.vue';

  export default {
    name: 'FakeSupportCenter',

    components: {
      CopyrightLogo,
    },

    inject: ['orgSettings'],

    props: {
      showedFirstTimeDiscovery: {
        type: Boolean,
        required: false,
        default: false,
      },

      openedScenariosList: {
        type: Boolean,
        required: false,
        default: false,
      },

      openedEmptyList: {
        type: Boolean,
        required: false,
        default: false,
      },

      firstTimeHeader: {
        type: String,
        required: true,
      },

      firstTimeText: {
        type: String,
        required: true,
      },

      listHeader: {
        type: String,
        required: true,
      },

      listEmptyText: {
        type: String,
        required: true,
      },

      classicThemeType: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    COMPANY_DOMAIN: buildSpecConfig.companyDomain,

    computed: {
      customLogo() {
        return this.orgSettings.logoUrl;
      },
    },
  };
</script>

<style lang="scss">
  .fake-support-center {
    position: absolute !important;
    right: 0;
    bottom: 0;

    // Reset default vuetify styles
    p {
      margin: auto;
    }

    button {
      box-sizing: border-box;
    }

    .empty {
      margin-top: 30px;
    }

    .floating-wrapper {
      &.show {
        &.style-classic {
          #hinted__floating-button {
            opacity: 0;
          }
        }
      }

      &.opened {
        pointer-events: none !important;

        .floating-wrapper__container {
          opacity: 1 !important;
        }

        .hinted__edit {
          animation: edit-in 0.2s;
          animation-delay: 0.1s;
          animation-fill-mode: forwards;

          svg {
            vertical-align: baseline;
          }
        }

        .hinted__plus {
          animation: plus-in 0.15s linear;
          animation-fill-mode: forwards;
        }

        #hinted__floating-button {
          background-color: #343349;
          box-shadow: none;
        }
      }
    }
  }
</style>
