<template>
  <div class="system-card">
    <div class="system-card__preview-wrapper">
      <div
        v-if="!system.previewUrl"
        class="system-card__preview"
        :style="{ backgroundColor: system.color }"
      >
        <div class="system-card__preview-circle" :style="{ color: system.color }">
          {{ system.url[0] }}
        </div>
      </div>

      <img v-else :src="system.previewUrl" class="system-card__preview-image" />

      <div v-if="!system.blocked" class="system-card__workplaces">
        <v-icon dark size="17" left>
          mdi-account-multiple-outline
        </v-icon>

        <span>{{ system.workplacesCount }}</span>
      </div>

      <div v-else class="system-card__block-overlay">
        <v-icon size="40" dark class="mb-3">
          mdi-lock-outline
        </v-icon>

        {{ $t('ui.systemCard.systemBlocked') }}
      </div>
    </div>

    <div class="system-card__footer">
      <div class="system-card__info">
        <a :href="`//${system.url}`" target="_blank" class="system-card__url">{{ system.url }}</a>

        <div class="system-card__datetime">
          {{ $t('ui.systemCard.createdAt') }}: {{ formatDateTime(system.createdAt) }}
        </div>
      </div>

      <v-menu offset-y left top>
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item v-if="!system.previewUrl" @click="openUploadPreviewForm()">
            <v-list-item-icon class="mr-0">
              <v-icon small>mdi-cloud-download-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t('ui.systemCard.uploadPreview') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-else @click="removePreview()">
            <v-list-item-icon class="mr-0">
              <v-icon small>mdi-close</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t('ui.systemCard.removePreview') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="!system.blocked" @click="openBlockForm('BlockSystemModal')">
            <v-list-item-icon class="mr-0">
              <v-icon small>mdi-lock-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t('ui.systemCard.block') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-else @click="openBlockForm('UnblockSystemModal')">
            <v-list-item-icon class="mr-0">
              <v-icon small>mdi-lock-open-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t('ui.systemCard.unblock') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="remove()">
            <v-list-item-icon class="mr-0">
              <v-icon small>mdi-delete</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t('ui.systemCard.remove') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <input
      ref="previewInput"
      type="file"
      accept="image/*"
      class="system-card__preview-input"
      @change="applyPreview($event)"
    />

    <v-dialog v-model="isBlockModalOpened" max-width="648px">
      <component
        :is="blockClientComponent"
        v-if="isBlockModalOpened"
        :system-id="system.id"
        @updated="emitUpdate()"
        @close="isBlockModalOpened = false"
      />
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  import api from 'src/api';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  import BlockSystemModal from '../BlockSystemModal/BlockSystemModal.vue';
  import UnblockSystemModal from '../UnblockSystemModal/UnblockSystemModal.vue';

  export default {
    name: 'SystemCard',

    components: {
      BlockSystemModal,
      UnblockSystemModal,
    },

    mixins: [formatTimestampMixin],

    props: {
      system: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        isBlockModalOpened: false,
        blockClientComponent: null,
      };
    },

    methods: {
      ...mapActions('upload', ['uploadFile']),

      async remove() {
        await api.integrators.removeClientSystem({ url_params: { id: this.system.id } });

        this.emitUpdate();
      },

      openUploadPreviewForm() {
        this.$refs.previewInput.click();
      },

      async applyPreview($event) {
        const [file] = $event.target.files;

        if (!file) {
          return;
        }

        const previewUrl = await this.uploadFile(file);

        this.$refs.previewInput.value = '';

        await api.integrators.updateClientSystem({
          url_params: { id: this.system.id },
          data: {
            previewUrl,
          },
        });

        this.emitUpdate();
      },

      async removePreview() {
        await api.integrators.updateClientSystem({
          url_params: { id: this.system.id },
          data: {
            previewUrl: null,
          },
        });

        this.emitUpdate();
      },

      openBlockForm(blockComponent) {
        this.blockClientComponent = blockComponent;
        this.isBlockModalOpened = true;
      },

      emitUpdate() {
        this.$emit('updated');
      },
    },
  };
</script>

<style lang="scss">
  .system-card {
    height: 249px;
    background-color: #ffffff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;

    &__preview-wrapper {
      position: relative;
      width: 100%;
      height: 178px;
    }

    &__preview {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__preview-circle {
      width: 115px;
      height: 115px;
      background-color: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 70px;
      line-height: 82px;
      text-transform: uppercase;
      user-select: none;
    }

    &__preview-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__workplaces {
      position: absolute;
      bottom: 10px;
      left: 10px;
      background-color: rgba(52, 51, 73, 0.75);
      color: #ffffff;
      border-radius: 10px;
      padding: 10px;
      font-size: 14px;
      line-height: 16px;
      user-select: none;
    }

    &__block-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(52, 51, 73, 0.85);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
    }

    &__info {
      overflow: hidden;
    }

    &__url {
      display: block;
      font-size: 16px;
      line-height: 17px;
      text-decoration: underline;
      color: #343349 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__datetime {
      margin-top: 10px;
      font-size: 12px;
      line-height: 14px;
      color: #343349;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__preview-input {
      display: none;
    }
  }
</style>
