const getUnreadCount = {
  name: 'getUnreadCount',
  method: 'get',
  url: 'unreadCount',
};

const getList = {
  name: 'getList',
  method: 'get',
  url: 'list',
};

const readEvent = {
  name: 'readEvent',
  method: 'patch',
  url: ':id/read',
};

const removeEvent = {
  name: 'removeEvent',
  method: 'delete',
  url: ':id',
};

export default {
  name: 'events',
  url: '/api/events',
  children: [getUnreadCount, getList, readEvent, removeEvent],
};
