import { THEME_TYPE_BASIC, THEME_TYPE_CLASSIC } from 'src/pages/Styles/utils/index';

export const hintDefaultsSchema = {
  [THEME_TYPE_BASIC]: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Arial, sans-serif',
    color: '#000000FF',
    borderColor: '#00000080',
    isBorderEnabled: true,
    borderLineWidth: 3,
    isShadowEnabled: false,
    shadowXOffset: 0,
    shadowYOffset: 0,
    shadowColor: '#000000FF',
    shadowBlurRadius: 10,
    shadowSpreadRadius: 0,
    buttonFontFamily: 'Arial, sans-serif',
    buttonBorderRadius: 0,
    buttonBackgroundColor: '#A7448FFF',
    buttonColor: '#FFFFFFFF',
    paddingTop: 25,
    paddingRight: 15,
    paddingBottom: 15,
    paddingLeft: 15,
    type: 'default',
    frameColor: '#02AC5EFF',
    frameSize: 3,
    zIndex: 0,
  },
  [THEME_TYPE_CLASSIC]: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto, sans-serif',
    color: '#343347FF',
    borderColor: '#00000000',
    isBorderEnabled: false,
    borderLineWidth: 0,
    borderRadius: 10,
    isShadowEnabled: true,
    shadowXOffset: 0,
    shadowYOffset: 10,
    shadowColor: '#00000033',
    shadowBlurRadius: 25,
    shadowSpreadRadius: 0,
    buttonFontFamily: 'Roboto, sans-serif',
    buttonBorderRadius: 10,
    buttonBackgroundColor: '#C62F5AFF',
    buttonColor: '#FFFFFFFF',
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    type: 'default',
    frameColor: '#02AC5EFF',
    frameSize: 3,
    zIndex: 0,
  },
};

export const highlightDefaultsSchema = {
  [THEME_TYPE_BASIC]: {
    borderColor: '#02AC5EFF',
    borderLineWidth: 3,
  },

  [THEME_TYPE_CLASSIC]: {
    borderColor: '#02AC5EFF',
    borderLineWidth: 3,
  },
};

export const tooltipDefaultsSchema = {
  [THEME_TYPE_BASIC]: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Arial, sans-serif',
    color: '#000000FF',
    borderColor: '#00000080',
    borderLineWidth: 0,
    borderRadius: 0,
    isBorderEnabled: false,
    isShadowEnabled: true,
    shadowXOffset: 0,
    shadowYOffset: 0,
    highlightType: 'marker',
    shadowBlurRadius: 10,
    shadowSpreadRadius: 0,
    shadowColor: '#00000066',
    hotspotBackgroundColor: '#02ac5d',
    hotspotOpacity: 0.7,
    hotspotBorderRadius: 50,
    hotspotColor: '#ffffff',
    hotspotSize: 24,
    underlineSize: 3,
    underlineBackgroundColor: '#02ac5d',
    underlineOpacity: 1,
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    showMarkerDelay: 0,
    showAction: 'hover',
    showDelay: 0,
  },
  [THEME_TYPE_CLASSIC]: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto, sans-serif',
    color: '#343347FF',
    borderColor: '#00000000',
    borderLineWidth: 0,
    borderRadius: 10,
    isBorderEnabled: false,
    isShadowEnabled: true,
    shadowXOffset: 0,
    shadowYOffset: 10,
    shadowColor: '#00000033',
    highlightType: 'marker',
    shadowBlurRadius: 25,
    shadowSpreadRadius: 0,
    hotspotBackgroundColor: '#02ac5d',
    hotspotOpacity: 0.7,
    hotspotBorderRadius: 50,
    hotspotColor: '#ffffff',
    hotspotSize: 24,
    underlineSize: 3,
    underlineBackgroundColor: '#02ac5d',
    underlineOpacity: 1,
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    showMarkerDelay: 0,
    showAction: 'hover',
    showDelay: 0,
  },
};

export const supportCenterDefaultSchema = {
  [THEME_TYPE_BASIC]: {
    fontFamily: 'Arial, sans-serif',
    firstTimeBackgroundColor: '#343349',
    firstTimeColor: '#FFFFFF',
    buttonBackgroundColor: '#02ac5d',
    buttonHoverBackgroundColor: '#343349',
    buttonColor: '#d71b5a',
    backgroundColor: '#f9f9f9',
    isButtonShadowEnabled: true,
    buttonShadowXOffset: 0,
    buttonShadowYOffset: 10,
    buttonShadowColor: '#00000033',
    buttonShadowBlurRadius: 25,
    buttonShadowSpreadRadius: 0,
    itemColor: '#000000',
    itemHoverBackgroundColor: '#a74482f2',
    itemHoverColor: '#FFFFFF',
    headerColor: '#02ac5e',
    headerBackgroundColor: '#343349',
  },

  [THEME_TYPE_CLASSIC]: {
    fontFamily: 'Roboto, sans-serif',
    firstTimeBackgroundColor: '#343349FF',
    firstTimeColor: '#FFFFFFFF',
    buttonBackgroundColor: '#C62F5AFF',
    buttonHoverBackgroundColor: '#C62F5AFF',
    buttonColor: '#FFFFFFFF',
    isButtonShadowEnabled: true,
    buttonShadowXOffset: 0,
    buttonShadowYOffset: 10,
    buttonShadowColor: '#00000033',
    buttonShadowBlurRadius: 25,
    buttonShadowSpreadRadius: 0,
    backgroundColor: '#FFFFFFFF',
    itemColor: '#343347FF',
    itemHoverBackgroundColor: '#FFFFFFFF',
    itemHoverColor: '#C62F5AFF',
    headerColor: '#02ac5e',
    headerBackgroundColor: '#343349',
  },
};

export const ratingDefaultSchema = {
  [THEME_TYPE_BASIC]: {
    activeColor: '#d81159',
    backgroundColor: '#FFFFFF',
    borderColor: '#00000080',
    borderLineWidth: 0,
    borderRadius: 2,
    color: '#000000FF',
    fontFamily: 'Arial, sans-serif',
    isBorderEnabled: false,
    isShadowEnabled: false,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    shadowBlurRadius: 10,
    shadowColor: '#000000FF',
    shadowSpreadRadius: 0,
    shadowXOffset: 0,
    shadowYOffset: 0,
  },

  [THEME_TYPE_CLASSIC]: {
    activeColor: '#C62F5AFF',
    backgroundColor: '#FFFFFF',
    borderColor: '#00000080',
    borderLineWidth: 0,
    borderRadius: 10,
    color: '#343347FF',
    fontFamily: 'Roboto, sans-serif',
    isBorderEnabled: false,
    paddingTop: 25,
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    isShadowEnabled: true,
    shadowXOffset: 0,
    shadowYOffset: 10,
    shadowColor: '#00000033',
    shadowBlurRadius: 25,
    shadowSpreadRadius: 0,
  },
};

export const modalDefaultSchema = {
  [THEME_TYPE_BASIC]: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Arial, sans-serif',
    color: '#000000FF',
    borderColor: '#00000080',
    isBorderEnabled: true,
    borderLineWidth: 3,
    isShadowEnabled: false,
    shadowXOffset: 0,
    shadowYOffset: 0,
    shadowColor: '#000000FF',
    shadowBlurRadius: 10,
    shadowSpreadRadius: 0,
    buttonFontFamily: 'Arial, sans-serif',
    buttonBorderRadius: 0,
    buttonBackgroundColor: '#A7448FFF',
    buttonColor: '#FFFFFFFF',
    paddingTop: 25,
    paddingRight: 15,
    paddingBottom: 15,
    paddingLeft: 15,
  },

  [THEME_TYPE_CLASSIC]: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto, sans-serif',
    color: '#343347FF',
    borderColor: '#00000000',
    isBorderEnabled: false,
    borderLineWidth: 0,
    isShadowEnabled: true,
    shadowXOffset: 0,
    shadowYOffset: 10,
    shadowColor: '#00000033',
    shadowBlurRadius: 25,
    shadowSpreadRadius: 0,
    buttonFontFamily: 'Roboto, sans-serif',
    buttonBorderRadius: 10,
    buttonBackgroundColor: '#C62F5AFF',
    buttonColor: '#FFFFFFFF',
    paddingTop: 25,
    paddingRight: 15,
    paddingBottom: 30,
    paddingLeft: 15,
  },
};

export const simulationDefaultSchema = {
  [THEME_TYPE_BASIC]: {
    systemSlidesShowed: true,
    systemSlideBackgroundColor: '#F4F6F9',
    systemSlideColor: '#353248',
    systemSlideFontFamily: 'Roboto, sans-serif',
    systemSlidePaddingLeft: 50,
    systemSlidePaddingRight: 50,
    systemSlidePaddingTop: 50,
    systemSlidePaddingBottom: 50,
    systemSlideLogoShowed: true,
    systemSlideLogoUrl: null,

    popupFontFamily: 'Roboto, sans-serif',
    popupBackgroundColor: '#D81159',
    popupColor: '#FFFFFF',
    popupButtonBackgroundColor: '#FFFFFF',
    popupButtonColor: '#D81159',
    popupButtonFontFamily: 'Roboto, sans-serif',
    popupPaddingLeft: 15,
    popupPaddingRight: 15,
    popupPaddingTop: 15,
    popupPaddingBottom: 15,
    highlightColor: '#D81159',

    paginationFontFamily: 'Roboto, sans-serif',
    paginationBackgroundColor: '#FFFFFF',
    paginationColor: '#343349',
    paginationButtonColor: '#FFFFFF',
    paginationSecondaryButtonColor: '#D81159',
    paginationButtonBackgroundColor: '#D81159',
    paginationCompletedStepColor: '#D81159',
    paginationUncompletedStepColor: '#C0C3CB',

    copyrightHidden: false,
  },

  [THEME_TYPE_CLASSIC]: {
    systemSlidesShowed: true,
    systemSlideBackgroundColor: '#F4F6F9',
    systemSlideColor: '#353248',
    systemSlideFontFamily: 'Roboto, sans-serif',
    systemSlidePaddingLeft: 50,
    systemSlidePaddingRight: 50,
    systemSlidePaddingTop: 50,
    systemSlidePaddingBottom: 50,
    systemSlideLogoShowed: true,
    systemSlideLogoUrl: null,

    popupFontFamily: 'Roboto, sans-serif',
    popupBackgroundColor: '#D81159',
    popupColor: '#FFFFFF',
    popupButtonBackgroundColor: '#FFFFFF',
    popupButtonColor: '#D81159',
    popupButtonFontFamily: 'Roboto, sans-serif',
    popupPaddingLeft: 15,
    popupPaddingRight: 15,
    popupPaddingTop: 15,
    popupPaddingBottom: 15,
    highlightColor: '#D81159',

    paginationFontFamily: 'Roboto, sans-serif',
    paginationBackgroundColor: '#FFFFFF',
    paginationColor: '#343349',
    paginationButtonColor: '#FFFFFF',
    paginationSecondaryButtonColor: '#D81159',
    paginationButtonBackgroundColor: '#D81159',
    paginationCompletedStepColor: '#D81159',
    paginationUncompletedStepColor: '#C0C3CB',

    copyrightHidden: false,
  },
};

export const defaultHintSettings = {
  position: 'element',
  copyrightHidden: false,
  isStepCountEnabled: false,
};

export function getDefaultSupportCenterSettings() {
  return {
    firstTimeHeader: this.$t('pages.styles.settings.supportCenterFirstTimeDefaultHeader'),
    firstTimeText: this.$t('pages.styles.settings.supportCenterFirstTimeDefaultText'),
    listHeader: this.$t('pages.styles.settings.supportCenterFirstTimeDefaultHeader'),
    listEmptyText: this.$t('pages.styles.settings.supportCenterFirstTimeDefaultEmptyText'),
    isWelcomeMessageEnabled: false,
  };
}

export const defaultTooltipSettings = {
  copyrightHidden: false,
};
