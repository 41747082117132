<template>
  <v-card :elevation="embedded ? 0 : undefined">
    <v-card-title v-if="!embedded">{{ title }}</v-card-title>

    <v-card-text :class="[embedded && 'px-0 pt-0']">
      <v-progress-linear :active="isBusy" indeterminate />

      <v-row class="justify-center d-flex">
        <v-col lg="6" cols="12">
          <h4 class="title blue-grey--text text--darken-2 mb-3" :class="[!embedded && 'mt-4']">
            {{ allListTitle }}
          </h4>

          <v-text-field
            v-model="currentAllListSearch"
            :label="$t('pages.groups.addingModal.search')"
            clearable
          />

          <draggable
            :value="mappedAllItems"
            :group="{ ...draggableParams, pull: 'clone' }"
            :sort="false"
            class="cursor-move addable-items todo-cards"
          >
            <adding-form-item
              v-for="(item, index) in mappedAllItems"
              :id="item.id"
              :key="index"
              :is-in-group="itemInEntity(item)"
              :icon="getAddableItemIcon(item)"
              :title="item.title"
              :subtitle="item.subtitle"
              :is-button-disabled="isBusy"
              @click="changeItemGroupState(item)"
            />
          </draggable>

          <v-pagination
            v-if="currentAllListParams.pages > 1"
            v-model="allListPage"
            :length="currentAllListParams.pages"
            :disabled="isBusy"
            :total-visible="12 - String(currentAllListParams.pages).length"
          />
        </v-col>

        <v-col lg="6" cols="12">
          <h4 class="title blue-grey--text text--darken-2 mb-3" :class="[!embedded && 'mt-4']">
            {{ addedListTitle }}
          </h4>

          <v-text-field
            v-model="currentAddedListSearch"
            :label="$t('pages.groups.addingModal.search')"
            clearable
          />

          <draggable
            :value="mappedAddedItems"
            :group="draggableParams"
            class="cursor-move group-items progress-cards"
            @add="onAdd($event)"
            @remove="onRemove($event)"
          >
            <adding-form-item
              v-for="(item, index) in mappedAddedItems"
              :id="item.id"
              :key="index"
              icon="trash-2"
              :title="item.title"
              :subtitle="item.subtitle"
              :is-button-disabled="isBusy"
              @click="removeFromGroup(item)"
            />
          </draggable>

          <v-pagination
            v-if="currentAddedListParams.pages > 1"
            v-model="addedListPage"
            :length="currentAddedListParams.pages"
            :disabled="isBusy"
            :total-visible="12 - String(currentAddedListParams.pages).length"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import draggable from 'vuedraggable';
  import debounce from 'lodash.debounce';

  import { busyFlow, getAttributes } from 'src/utils';

  import AddingFormItem from './AddingFormItem.vue';

  export default {
    name: 'BaseAddingForm',

    components: {
      draggable,
      AddingFormItem,
    },

    props: {
      entity: {
        type: Object,
        required: false,
        default: () => ({}),
      },

      perPage: {
        type: [Number, String],
        required: false,
        default: 6,
      },

      embedded: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        isBusy: false,

        allListSearch: null,
        allList: [],
        allCount: 0,
        allListParams: { page: 1 },

        addedListSearch: null,
        addedList: [],
        addedCount: 0,
        addedListParams: { page: 1 },

        draggableParams: { name: 'custom', put: true, pull: true },
      };
    },

    /* eslint-disable vue/return-in-computed-property */
    computed: {
      title() {
        throw new Error('"title" property not implemented');
      },

      allListTitle() {
        throw new Error('"allListTitle" property not implemented');
      },

      addedListTitle() {
        throw new Error('"addedListTitle" property not implemented');
      },

      allItems() {
        return this.allList;
      },

      mappedAllItems() {
        return this.allItems.map(this.mapItem);
      },

      addedItems() {
        return this.addedList;
      },

      mappedAddedItems() {
        return this.addedItems.map(this.mapItem);
      },

      allItemsCount() {
        return this.allCount;
      },

      addedItemsCount() {
        return this.addedCount;
      },

      currentAllListParams() {
        return {
          ...this.allListParams,
          limit: this.perPage,
          count: this.allItemsCount,
          pattern: this.allListSearch,
          pages: Math.ceil(this.allItemsCount / this.perPage),
        };
      },

      currentAddedListParams() {
        return {
          ...this.addedListParams,
          limit: this.perPage,
          count: this.addedItemsCount,
          pattern: this.addedListSearch,
          pages: Math.ceil(this.addedItemsCount / this.perPage),
        };
      },

      currentAllListSearch: {
        get() {
          return this.currentAllListParams.pattern;
        },

        set(value) {
          this.allListSearch = value;
          this.allListParams.page = 1;
          this.getDebouncedAllList();
        },
      },

      currentAddedListSearch: {
        get() {
          return this.currentAddedListParams.pattern;
        },

        set(value) {
          this.addedListSearch = value;
          this.addedListParams.page = 1;
          this.getDebouncedAddedList();
        },
      },

      allListPage: {
        get() {
          return this.currentAllListParams.page;
        },

        set(value) {
          this.allListParams.page = value;
          this.getDebouncedAllList();
        },
      },

      addedListPage: {
        get() {
          return this.currentAddedListParams.page;
        },

        set(value) {
          this.addedListParams.page = value;
          this.getDebouncedAddedList();
        },
      },
    },

    mounted() {
      this.loadLists();
    },

    methods: {
      mapItem() {
        throw new Error('"mapItem" not implemented');
      },

      getAddedListMethod() {
        throw new Error('"getAddedListMethod" not implemented');
      },

      getAllListMethod() {
        throw new Error('"getAllListMethod" not implemented');
      },

      itemInEntity() {
        throw new Error('"itemInEntity" not implemented');
      },

      addToEntityMethod() {
        throw new Error('"addToEntityMethod" method not implemented');
      },

      removeFromEntityMethod() {
        throw new Error('"removeFromEntityMethod" method not implemented');
      },

      clearListsMethod() {
        return null;
      },

      getAddedList(isForce = false) {
        return busyFlow.call(this, this.getAddedListMethod, isForce);
      },

      getAllList(isForce = false) {
        return busyFlow.call(this, this.getAllListMethod, isForce);
      },

      getDebouncedAddedList: debounce(function request() {
        this.getAddedList();
      }, 300),

      getDebouncedAllList: debounce(function request() {
        this.getAllList();
      }, 300),

      async clearLists() {
        await this.clearListsMethod();

        this.allList = [];
        this.allCount = [];
        this.addedList = [];
        this.addedCount = [];
        this.allListParams.page = 1;
        this.addedListParams.page = 1;
      },

      getAddableItemIcon(item) {
        return this.itemInEntity(item) ? 'check-square' : 'plus-square';
      },

      async addToGroup(item) {
        if (this.itemInEntity(item)) {
          return;
        }

        await this.addToEntityMethod(item);

        if (this.addedItems.length >= this.perPage) {
          this.addedListParams.page += 1;
        }

        if (this.allItems.length <= 1 && this.allListPage > 1) {
          this.allListParams.page -= 1;
        }

        await this.loadLists();
      },

      async removeFromGroup(item) {
        await this.removeFromEntityMethod(item);

        if (this.addedItems.length <= 1 && this.addedListPage > 1) {
          this.addedListParams.page -= 1;
        }

        await this.loadLists();
      },

      async changeItemGroupState(item) {
        return this.itemInEntity(item) ? this.removeFromGroup(item) : this.addToGroup(item);
      },

      async loadLists() {
        await busyFlow.call(
          this,
          () => {
            return Promise.all([this.getAllList(true), this.getAddedList(true)]);
          },
          true
        );
      },

      onAdd(event) {
        const { id, 'is-in-group': isInGroupString } = getAttributes(event.item);

        if (isInGroupString === 'true') {
          return;
        }
        const item = this.allItems.find(listItem => listItem.id === id);

        this.addToGroup(item);
      },

      onRemove(event) {
        const { id } = getAttributes(event.item);

        const item = this.addedItems.find(listItem => listItem.id === id);

        this.removeFromGroup(item);
      },
    },
  };
</script>
