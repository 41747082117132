/* eslint-disable no-shadow,no-param-reassign,no-unused-vars */
import api from 'src/api';
import { currentLocale } from 'src/plugins/i18n';

import localStorage from 'src/services/localStorage';

import { deepcopy, getLocationForQueries } from 'src/utils';
import {
  USERS_LIST,
  USERS_ERROR,
  USERS_CLEAR_ERROR,
  USER_DATA,
  USERS_ROLES,
  SET_USER_WORKSPACE,
  USERS_CREATE_MANY,
  USERS_SET_EMAIL_RETRY_PERIOD,
} from 'src/store/mutation-types';

import {
  ORGANIZATION_PLAN_ENTERPRISE,
  ORGANIZATION_PLAN_FREE,
  ORGANIZATION_PLAN_SIMULATION,
  ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,
  ORGANIZATION_PLAN_SMART_MANUALS_PRO,
  ORGANIZATION_PLAN_SMART_MANUALS_START,
  ORGANIZATION_PLAN_STANDARD,
  ORGANIZATION_PLAN_STARTUP,
  LOAD_IDLE,
  LOAD_FAILED,
  LOAD_PENDING,
  LOAD_SUCCESS,
  ANALYST_ROLE,
} from 'src/constants';

const state = {
  usersList: [],
  usersCount: 0,
  error: null,
  currentUser: {},
  usersRoles: [],
  createManyUsersStatus: LOAD_IDLE,
  retryLoginLinkEmailPeriod: null,
};

const getters = {
  isUserLoaded(state) {
    return typeof state.currentUser.role === 'string';
  },

  userRole(state, getters, rootState, rootGetters) {
    const { role } = state.currentUser;
    if (!role) {
      return null;
    }

    if (['ADMIN', 'SUPERADMIN', 'SERVICE'].includes(role)) {
      return role;
    }

    return rootGetters['workspaces/selectedWorkspace']?.isAdmin ? 'ADMIN' : role;
  },

  isSuperAdmin(state, getters) {
    return getters.userRole && ['SUPERADMIN', 'SERVICE'].includes(state.currentUser.role);
  },

  isAdmin(state, getters) {
    return getters.userRole === 'ADMIN';
  },

  isAuditor(state, getters) {
    return getters.userRole === 'AUDITOR' || getters.isSuperAdmin;
  },

  isAnalyst(state, getters) {
    return getters.userRole === ANALYST_ROLE;
  },

  isAdminOrHigher: (state, getters) =>
    ['ADMIN', 'SUPERADMIN', 'SERVICE'].includes(getters.userRole),

  isRealAdminOrHigher: state => ['ADMIN', 'SUPERADMIN', 'SERVICE'].includes(state.currentUser.role),

  isDesignerOrHigher: (state, getters) =>
    ['DESIGNER', 'MODERATOR', 'ADMIN', 'SUPERADMIN', 'SERVICE'].includes(getters.userRole),

  isModeratorOrHigher: (state, getters) =>
    ['MODERATOR', 'SERVICE', 'SUPERADMIN', 'ADMIN'].includes(getters.userRole),

  isHintedPlansActive: state =>
    [
      ORGANIZATION_PLAN_FREE,
      ORGANIZATION_PLAN_STANDARD,
      ORGANIZATION_PLAN_ENTERPRISE,
      ORGANIZATION_PLAN_STARTUP,
      ORGANIZATION_PLAN_SIMULATION,
    ].includes(state.currentUser?.organizationPlan || null),

  isSmartManualsPlansActive: state =>
    [
      ORGANIZATION_PLAN_SMART_MANUALS_START,
      ORGANIZATION_PLAN_SMART_MANUALS_PRO,
      ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,
    ].includes(state.currentUser?.organizationPlan || null),

  isSimulationAccessPlansActive: state =>
    [
      ORGANIZATION_PLAN_SIMULATION,
      ORGANIZATION_PLAN_STANDARD,
      ORGANIZATION_PLAN_ENTERPRISE,
    ].includes(state.currentUser?.organizationPlan || null),

  isEnterprisePlanActive: state =>
    state.currentUser?.organizationPlan === ORGANIZATION_PLAN_ENTERPRISE,

  isSmartManualsStartPlanActive: state =>
    state.currentUser?.organizationPlan === ORGANIZATION_PLAN_SMART_MANUALS_START,

  isSmartManualsProPlanActive: state =>
    state.currentUser?.organizationPlan === ORGANIZATION_PLAN_SMART_MANUALS_PRO,

  isSmartManualsEnterprisePlanActive: state =>
    state.currentUser?.organizationPlan === ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,

  isStartupPlanActive: state => state.currentUser?.organizationPlan === ORGANIZATION_PLAN_STARTUP,
  isSimulationPlanActive: state =>
    state.currentUser?.organizationPlan === ORGANIZATION_PLAN_SIMULATION,

  loginLinkEmailRetryPeriod: state => state.retryLoginLinkEmailPeriod,
};

const actions = {
  async list(
    { commit },
    { page, limit, filterBy, filterDirection, pattern, organizationId, groupId, workspaceId } = {}
  ) {
    const params = deepcopy({
      page,
      limit,
      sort: filterBy,
      dir: filterDirection,
      organization_id: organizationId,
      group_id: groupId,
      workspace_id: workspaceId,
    });

    if (pattern) {
      params.pattern = pattern;
    }

    const { response } = await api.users.list({ params });

    commit(USERS_LIST, response);
  },

  async checkAccess() {
    await api.users.list({ params: { limit: 1 } });
  },

  async getCurrentUser({ state, commit, getters, dispatch }) {
    const { response } = await api.users.me();

    commit(USER_DATA, response);

    localStorage.set('organizationPlan', response.organizationPlan);

    if (state.currentUser.organizationId && getters.isSuperAdmin) {
      await dispatch('getUsersRoles');
    }
  },

  async add({ commit }, data) {
    try {
      const { response } = await api.users.add({
        data,
      });

      if (response && response.id) {
        return response.id;
      }

      return false;
    } catch (e) {
      commit(USERS_ERROR, e.error);
      throw e;
    }
  },

  async sendLoginLinkEmail({ commit }) {
    try {
      await api.users.sendLoginLink({
        data: { app: 'ADMIN_PANEL_MOBILE', locale: getLocationForQueries() },
      });
    } catch (e) {
      console.log(e);
    }
  },

  async getLoginLinkEmailRetryPeriod({ commit }) {
    try {
      const { response } = await api.users.getLoginLinkEmailRetryPeriod();
      commit(USERS_SET_EMAIL_RETRY_PERIOD, response.period);
    } catch (e) {
      console.log(e);
    }
  },

  async createManyUsers({ commit }, data) {
    try {
      commit(USERS_CREATE_MANY, LOAD_PENDING);
      const { response } = await api.users.createMany({
        data,
      });
      commit(USERS_CREATE_MANY, LOAD_SUCCESS);
      return response;
    } catch (error) {
      commit(USERS_CREATE_MANY, LOAD_FAILED);
      return commit(USERS_ERROR, error.error);
    }
  },

  async update({ commit }, { userId, data }) {
    await api.users.update({ url_params: { userId }, data });
  },

  async remove(context, userId) {
    await api.users.remove({ url_params: { id: userId } });
  },

  clearError({ commit }) {
    commit(USERS_CLEAR_ERROR);
  },

  async getUsersRoles({ commit }) {
    const { response } = await api.users.getRoles();
    const filteredRoles = response.filter(role => role !== 'AUDITOR');

    commit(USERS_ROLES, filteredRoles);
  },

  async sendRecoveryCode(context, { email, backUrl }) {
    const { response } = await api.users.sendRecoveryCode({
      data: { email, backUrl, browserLocale: currentLocale },
    });
    return response;
  },

  async recoverPassword(context, { code, password, passwordRepeat }) {
    const { response } = await api.users.recoverPassword({
      data: { code, password, passwordRepeat },
    });

    return response;
  },

  async approveUsers(context, idList) {
    const { response } = await api.users.approveUsers({ data: { list: idList } });
    return response;
  },

  async setLocale(context, locale) {
    const { response } = await api.users.setLocale({ data: { browserLocale: locale } });
    return response;
  },
};

const mutations = {
  [USERS_LIST](state, payload) {
    state.usersList = payload.users;
    state.usersCount = payload.count;
  },

  [USERS_ERROR](state, payload) {
    state.error = payload;
  },

  [USERS_CREATE_MANY](state, payload) {
    state.createManyUsersStatus = payload;
  },

  [USERS_CLEAR_ERROR](state) {
    state.error = null;
  },

  [USER_DATA](state, payload) {
    state.currentUser = payload;
  },

  [USERS_ROLES](state, payload) {
    state.usersRoles = payload;
  },

  [SET_USER_WORKSPACE](state, workspaceId) {
    state.currentUser.workspaceId = workspaceId;
  },

  [USERS_SET_EMAIL_RETRY_PERIOD](state, period) {
    state.retryLoginLinkEmailPeriod = period;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
