<template>
  <v-card class="integrator-balance-modal">
    <v-toolbar tile dark color="primary">
      <v-toolbar-title>
        {{ $t('ui.integratorBalanceModal.title') }} ( {{ $t('ui.integratorBalanceModal.balance') }}:
        {{ formatCost(integrator.balance || 0) }})
      </v-toolbar-title>

      <v-spacer />

      <v-btn icon class="mr-0" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <div class="d-flex align-start mt-5 mb-2">
        <v-text-field
          v-model="formattedAmount"
          :label="$t('ui.integratorBalanceModal.credits')"
          outlined
          class="integrator-balance-modal__amount-input"
          :error-messages="amountError"
          @input="amountError = null"
        />

        <v-btn
          depressed
          height="56"
          :disabled="isBusyCreditBalance || isBusyDebitBalance"
          :loading="isBusyCreditBalance"
          dark
          color="green accent-4"
          class="ml-4"
          @click="changeBalance(true)"
        >
          {{ $t('ui.integratorBalanceModal.credit') }}
        </v-btn>

        <v-btn
          :disabled="isBusyCreditBalance || isBusyDebitBalance"
          :loading="isBusyDebitBalance"
          depressed
          height="56"
          color="primary"
          class="ml-2"
          @click="changeBalance(false)"
        >
          {{ $t('ui.integratorBalanceModal.debit') }}
        </v-btn>
      </div>

      <div class="pl-5 mb-8">
        <div class="position-relative d-flex align-center text-h6">
          <div class="lstick lstick--info" />

          {{ $t('ui.integratorBalanceModal.filter') }}

          <v-autocomplete
            :value="historyParams.clientId"
            :items="clientsOptions"
            :label="$t('ui.integratorBalanceModal.clients')"
            item-text="name"
            item-value="id"
            prepend-inner-icon="mdi-magnify"
            light
            outlined
            dense
            hide-details
            clearable
            class="ml-6 mr-2"
            @input="updateClientId($event)"
          />

          <v-autocomplete
            v-model="historyParams.userId"
            :items="usersOptions"
            :label="$t('ui.integratorBalanceModal.users')"
            item-text="email"
            item-value="id"
            prepend-inner-icon="mdi-magnify"
            light
            outlined
            dense
            hide-details
            clearable
            class="mx-2"
          />

          <v-menu
            ref="menu"
            v-model="openedDateMenu"
            :close-on-content-click="false"
            :return-value.sync="filterDates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formattedDatesFilter"
                :label="$t('ui.integratorBalanceModal.period')"
                prepend-inner-icon="mdi-calendar"
                readonly
                dense
                outlined
                hide-details
                v-bind="attrs"
                class="ml-2"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="datePickerModel"
              range
              no-title
              scrollable
              first-day-of-week="1"
              :max="maxDate"
            >
              <v-spacer />

              <v-btn text color="primary" @click="openedDateMenu = false">
                {{ $t('ui.common.cancel') }}
              </v-btn>

              <v-btn text color="primary" @click="$refs.menu.save(datePickerModel)">
                {{ $t('ui.common.ok') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </div>

      <div class="px-5 mb-3">
        <div class="position-relative d-flex align-center text-h6">
          <div class="lstick lstick--info" />

          {{ $t('ui.integratorBalanceModal.balanceHistory') }}
        </div>
      </div>

      <v-data-table
        :headers="headers"
        :items="history"
        :options.sync="historyParams"
        :server-items-length="historyServerCount"
        :loading="isBusyHistory"
      >
        <template #item.createdAt="{value}">
          {{ formatDateTime(value) }}
        </template>

        <template #item.oldBalance="{value}">
          {{ formatCost(value) }}
        </template>

        <template #item.action="{value, item}">
          <template v-if="['CREDIT_FROM_SUPERADMIN', 'DEBIT_FROM_SUPERADMIN'].includes(value)">
            {{ $t(`ui.integratorBalanceModal.actions.${value}`) }}
          </template>

          <template v-if="value === 'CREATE_LICENSE'">
            <div class="d-flex align-center">
              <span class="mr-2">{{ $t(`ui.integratorBalanceModal.actions.${value}`) }}:</span>
              {{ item.data.usersCount }}
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" right v-on="on">mdi-account-multiple-outline</v-icon>
                </template>

                <span> {{ $tc('ui.plurals.users', item.data.usersCount) }}</span>
              </v-tooltip>
              <span class="mx-2">{{ $t('ui.integratorBalanceModal.for') }}</span>
              {{ item.data.monthsCount }}
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" right v-on="on">mdi-calendar-blank-outline</v-icon>
                </template>

                <span> {{ $tc('ui.plurals.months', item.data.monthsCount) }}</span>
              </v-tooltip>
            </div>
          </template>

          <template v-if="value === 'ADDING_USERS_TO_LICENSE'">
            <div class="d-flex align-center">
              <span class="mr-2">{{ $t(`ui.integratorBalanceModal.actions.${value}`) }}:</span> +
              {{ item.data.usersCount }}
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" right v-on="on">mdi-account-multiple-outline</v-icon>
                </template>

                <span> {{ $tc('ui.plurals.users', item.data.usersCount) }}</span>
              </v-tooltip>

              <span class="mx-2">{{ $t('ui.integratorBalanceModal.for') }}</span>
              {{ item.data.monthsCount }}
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" right v-on="on">mdi-calendar-blank-outline</v-icon>
                </template>

                <span> {{ $tc('ui.plurals.months', item.data.monthsCount) }}</span>
              </v-tooltip>
            </div>
          </template>

          <template v-if="value === 'EXTENSION_LICENSE_PERIOD'">
            <div class="d-flex align-center">
              <span class="mr-2">{{ $t(`ui.integratorBalanceModal.actions.${value}`) }}</span> +
              {{ item.data.monthsCount }}
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" right v-on="on">mdi-calendar-blank-outline</v-icon>
                </template>

                <span> {{ $tc('ui.plurals.months', item.data.monthsCount) }}</span>
              </v-tooltip>

              <span class="mx-2">{{ $t('ui.integratorBalanceModal.for') }}</span>
              {{ item.data.usersCount }}

              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" right v-on="on">mdi-account-multiple-outline</v-icon>
                </template>

                <span> {{ $tc('ui.plurals.users', item.data.usersCount) }}</span>
              </v-tooltip>
            </div>
          </template>
        </template>

        <template #item.value="{value}">
          <span :class="value < 0 ? 'red--text  darken-1' : 'green--text accent-3'">
            {{ value >= 0 ? '+' : '-' }} {{ formatCost(Math.abs(value)) }}
          </span>
        </template>

        <template #item.newBalance="{item}">
          {{ formatCost(item.oldBalance + item.value) }}
        </template>

        <template #item.clientName="{value}">
          {{ value || '—' }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import moment from 'moment';

  import api from 'src/api';
  import { busyFlow } from 'src/utils';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';
  import formatCostMixin from 'src/mixins/formatCostMixin';

  const defaultHistoryParams = {
    itemsPerPage: 10,
    page: 1,
    sortBy: ['createdAt'],
    sortDesc: [true],
    userId: null,
    clientId: null,
    from: moment()
      .subtract(1, 'years')
      .startOf('day')
      .valueOf(),
    to: moment()
      .endOf('day')
      .valueOf(),
  };

  export default {
    name: 'IntegratorBalanceModal',

    mixins: [formatTimestampMixin, formatCostMixin],

    props: {
      integratorId: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        integrator: {},

        isBusyCreditBalance: false,
        isBusyDebitBalance: false,

        amount: 100,
        amountError: null,

        history: [],
        historyServerCount: 0,

        isBusyHistory: false,
        historyParams: { ...defaultHistoryParams },
        headers: [
          { text: this.$t('ui.integratorBalanceModal.tableHeaders.createdAt'), value: 'createdAt' },
          { text: this.$t('ui.integratorBalanceModal.tableHeaders.action'), value: 'action' },
          {
            text: this.$t('ui.integratorBalanceModal.tableHeaders.oldBalance'),
            value: 'oldBalance',
            width: '130px',
          },
          {
            text: this.$t('ui.integratorBalanceModal.tableHeaders.value'),
            value: 'value',
            width: '130px',
          },
          {
            text: this.$t('ui.integratorBalanceModal.tableHeaders.newBalance'),
            value: 'newBalance',
            width: '130px',
            sortable: false,
          },
          {
            text: this.$t('ui.integratorBalanceModal.tableHeaders.clientName'),
            value: 'clientName',
          },
          { text: this.$t('ui.integratorBalanceModal.tableHeaders.userEmail'), value: 'userEmail' },
        ],

        filterDates: [
          moment()
            .subtract(1, 'years')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        openedDateMenu: false,
        maxDate: new Date().toISOString().slice(0, 10),
        datePickerModel: [
          moment()
            .subtract(1, 'years')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],

        clients: [],
        users: [],
      };
    },

    computed: {
      formattedAmount: {
        get() {
          return this.formatCost(this.amount);
        },
        set(value) {
          const amount = value.replace(/\D/g, '');
          this.amount = amount === '' ? '' : Number(amount);
        },
      },

      mappedParams() {
        const {
          itemsPerPage,
          page = 1,
          sortBy: [filterBy],
          sortDesc: [isDesc],
          userId,
          clientId,
          from,
          to,
        } = this.historyParams;

        const limit = itemsPerPage === -1 ? 'all' : itemsPerPage;
        const filterDirection = isDesc ? 'desc' : 'asc';

        return {
          page,
          limit,
          from,
          to,
          ...(userId ? { userId } : {}),
          ...(clientId ? { clientId } : {}),
          ...(filterBy ? { filter_by: filterBy, filter_direction: filterDirection } : {}),
        };
      },

      formattedDatesFilter() {
        const dates = [...this.filterDates];

        if (dates.length === 1) {
          dates.push(dates[0]);
        }

        if (Date.parse(dates[1]) < Date.parse(dates[0])) {
          const temp = dates[1];
          [dates[1]] = dates;
          dates[0] = temp;
        }

        return `${this.formatDate(dates[0])} ~ ${this.formatDate(dates[1])}`;
      },

      clientsOptions() {
        return [
          {
            id: null,
            name: this.$t('ui.integratorBalanceModal.allClients'),
          },
          ...this.clients,
        ];
      },

      usersOptions() {
        return [
          {
            id: null,
            email: this.$t('ui.integratorBalanceModal.allUsers'),
          },
          ...this.users,
        ];
      },
    },

    watch: {
      mappedParams() {
        this.getHistory();
      },

      filterDates(value) {
        const dates = [...value];

        if (dates.length === 1) {
          dates.push(dates[0]);
        }

        if (Date.parse(dates[1]) < Date.parse(dates[0])) {
          const temp = dates[1];
          [dates[1]] = dates;
          dates[0] = temp;
        }

        this.historyParams = {
          ...defaultHistoryParams,
          sortBy: this.historyParams.sortBy,
          itemsPerPage: this.historyParams.itemsPerPage,
          sortDesc: this.historyParams.sortDesc,
          userId: this.historyParams.userId,
          clientId: this.historyParams.clientId,
          from: moment(dates[0]).valueOf(),
          to: moment(dates[1]).valueOf(),
        };
      },
    },

    mounted() {
      this.getIntegrator();
      this.getHistory();
      this.getClients();
      this.getUsers();
    },

    methods: {
      async getIntegrator() {
        const { response } = await api.integrators.getById({
          url_params: { id: this.integratorId },
        });

        this.integrator = response;
      },

      getHistory() {
        busyFlow.call(
          this,
          async () => {
            const { response } = await api.integrators.getBalanceHistory({
              url_params: { id: this.integratorId },
              params: { ...this.mappedParams },
            });

            this.history = response.payload;
            this.historyServerCount = response.count;
          },
          false,
          'isBusyHistory'
        );
      },

      changeBalance(isCredit = false) {
        const value = isCredit ? Number(this.amount) : -this.amount;

        if (!value) {
          return;
        }

        const data = {
          value,
        };

        if (data)
          busyFlow.call(
            this,
            async () => {
              try {
                const { response } = await api.integrators.changeBalance({
                  url_params: { id: this.integratorId },
                  data,
                });

                this.integrator.balance = response.balance;
                this.historyParams = {
                  ...defaultHistoryParams,
                };
              } catch (error) {
                if (error.code === 'INSUFFICIENT_FUNDS') {
                  this.amountError = this.$t('ui.integratorBalanceModal.notEnoughFundsError');

                  this.integrator.balance = error.details.balance;
                }
              }
            },
            false,
            isCredit ? 'isBusyCreditBalance' : 'isBusyDebitBalance'
          );
      },

      async getClients() {
        const { response } = await api.integrators.getClientsById({
          url_params: { id: this.integratorId },
          params: {
            limit: 'all',
          },
        });

        this.clients = Object.freeze(response.payload);
      },

      async getUsers() {
        const { response } = await api.integrators.getBalanceHistoryUsers({
          url_params: { id: this.integratorId },
          params: {
            ...(this.historyParams.clientId ? { clientId: this.historyParams.clientId } : {}),
          },
        });

        this.users = Object.freeze(response);
      },

      updateClientId(clientId) {
        this.historyParams = {
          ...defaultHistoryParams,
          sortBy: this.historyParams.sortBy,
          itemsPerPage: this.historyParams.itemsPerPage,
          sortDesc: this.historyParams.sortDesc,
          clientId,
          userId: null,
        };

        this.getUsers();
      },
    },
  };
</script>

<style lang="scss">
  .integrator-balance-modal {
    &__amount-input {
      max-width: 300px;
    }
  }
</style>
