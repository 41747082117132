const create = {
  name: 'create',
  method: 'post',
  url: '/api/legal',
};

const get = {
  name: 'get',
  method: 'get',
};

const update = {
  name: 'update',
  method: 'patch',
  url: '/api/legal/',
};

const getInfo = {
  name: 'getInfo',
  method: 'get',
  url: 'info',
};

const getInvoice = {
  name: 'getInvoice',
  method: 'post',
  url: 'invoice',
};

const getDocuments = {
  name: 'getDocuments',
  method: 'get',
  url: 'documents',
};

export default {
  name: 'legal',
  url: '/api/legal',
  children: [create, get, update, getInfo, getInvoice, getDocuments],
};
