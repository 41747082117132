<template>
  <v-form ref="form" @submit.prevent="create()">
    <v-card>
      <v-card-title>
        <span class="headline text-truncate">
          {{ $t(`ui.workspaces.editDialog.title.${editMode ? 'edit' : 'create'}`) }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container class="mt-4">
          <v-row>
            <v-text-field
              v-model="workspace.title"
              :label="$t('ui.workspaces.editDialog.fields.title')"
              :rules="[rules.getRequiredRule('title')]"
              outlined
              required
            />
          </v-row>

          <v-row>
            <v-text-field
              v-model="workspace.shortname"
              :label="$t('ui.workspaces.editDialog.fields.shortname')"
              :rules="[rules.getShortNameRule]"
              :error-messages="shortnameExistsError"
              outlined
              required
              @input="shortnameExistsError = null"
            />
          </v-row>

          <v-row>
            <v-combobox
              v-if="!hasLDAP"
              v-model="workspace.adminsList"
              :items="users"
              :label="$t('ui.workspaces.editDialog.fields.admins')"
              :loading="isBusyUsers"
              item-text="email"
              multiple
              outlined
              small-chips
              deletable-chips
            />
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn v-if="editMode" color="primary" text @click="removeDialogOpened = true">
          {{ $t('ui.common.remove') }}
        </v-btn>

        <v-spacer />

        <v-btn color="primary" text @click="close()">
          {{ $t('ui.common.cancel') }}
        </v-btn>

        <v-btn :loading="isBusy" color="primary" text type="submit">
          {{ $t(`ui.common.${editMode ? 'save' : 'create'}`) }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="removeDialogOpened" max-width="852">
      <workspace-remove-confirm
        v-if="removeDialogOpened"
        :workspace="editingWorkspace"
        @close="removeDialogOpened = false"
        @deleted="close()"
      />
    </v-dialog>
  </v-form>
</template>

<script>
  import { mapActions, mapState } from 'vuex';

  import api from 'src/api';

  import { busyFlow } from 'src/utils';

  import WorkspaceRemoveConfirm from 'src/components/WorkspaceRemoveConfirm/WorkspaceRemoveConfirm.vue';

  export default {
    name: 'WorkspaceEditModal',

    components: {
      WorkspaceRemoveConfirm,
    },

    props: {
      editingWorkspace: {
        type: Object,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        isBusy: false,
        isBusyUsers: false,
        removeDialogOpened: false,

        rules: {
          getRequiredRule: name => v =>
            !!v ||
            this.$t('validation.notNullField', {
              field: this.$t(`ui.workspaces.editDialog.fields.${name}`),
            }),
          getShortNameRule: v =>
            !v ||
            /^[a-z0-9]+$/i.test(v) ||
            this.$t('ui.workspaces.editDialog.validation.shortname'),
        },

        workspace: {
          title: null,
          adminsList: [],
        },

        users: [],

        shortnameExistsError: null,
      };
    },

    computed: {
      ...mapState('settings', ['hasLDAP']),
      ...mapState('users', ['currentUser']),

      editMode() {
        return Boolean(this.editingWorkspace);
      },
    },

    mounted() {
      if (this.editMode) {
        this.workspace.title = this.editingWorkspace.title;
        this.workspace.shortname = this.editingWorkspace.shortname;
      }

      this.loadUsers();
    },

    methods: {
      ...mapActions('workspaces', ['createWorkspace', 'updateWorkspace']),

      loadUsers() {
        busyFlow.call(
          this,
          async () => {
            const {
              response: { users },
            } = await api.users.list({
              params: {
                page: 1,
                limit: 'all',
                filter_by: 'email',
                filter_direction: 'ASC',
                organizationId: this.currentUser.organizationId,
              },
            });

            this.users = Object.freeze(users);

            if (this.editMode) {
              const { response } = await api.workspaces.adminsList({
                url_params: { id: this.editingWorkspace.id },
              });
              this.workspace.adminsList = response;
            }
          },
          false,
          'isBusyUsers'
        );
      },

      close() {
        this.removeDialogOpened = false;
        this.$emit('close');
      },

      openRemoveDialog() {
        this.removeDialogOpened = true;
      },

      create() {
        const { form } = this.$refs;

        if (!form.validate()) {
          return;
        }

        busyFlow.call(this, async () => {
          let payload = {
            title: this.workspace.title,
            adminsList: this.workspace.adminsList.map(user => user.id),
            ...(this.workspace.shortname ? { shortname: this.workspace.shortname } : {}),
          };
          if (this.editMode) {
            payload = {
              id: this.editingWorkspace.id,
              data: payload,
            };
          }

          const method = this.editMode ? this.updateWorkspace : this.createWorkspace;

          try {
            await method(payload);
            this.close();
          } catch (e) {
            if (e.code === 'SHORTNAME_ALREADY_TAKEN') {
              this.shortnameExistsError = this.$t(
                'ui.workspaces.editDialog.validation.shortnameExists'
              );
            }
          }
        });
      },
    },
  };
</script>

<style lang="scss"></style>
