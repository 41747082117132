const create = {
  name: 'create',
  method: 'post',
  url: '/api/simulations',
};

const update = {
  name: 'update',
  method: 'patch',
  url: ':id',
};

const updateDoc = {
  name: 'updateDoc',
  method: 'patch',
  url: 'docs/:simulationId',
};

const updateDocV2 = {
  name: 'updateDocV2',
  method: 'patch',
  url: 'docs/:simulationId/v2',
};

const updateTesting = {
  name: 'updateTesting',
  method: 'patch',
  url: 'testing/:simulationId',
};

const remove = {
  name: 'remove',
  method: 'delete',
  url: ':id',
};

const restore = {
  name: 'restore',
  method: 'post',
  url: ':id/restore',
};

const get = {
  name: 'get',
  method: 'get',
  url: ':id',
};

const getDoc = {
  name: 'getDoc',
  method: 'get',
  url: 'docs/:id',
};

const getTesting = {
  name: 'getTesting',
  method: 'get',
  url: 'testing/:id',
};

const list = {
  name: 'list',
  method: 'get',
  url: 'list',
};

const downloadScorm = {
  name: 'downloadScorm',
  method: 'get',
  url: ':id/scorm',
  options: {
    responseType: 'blob',
  },
};

const downloadTestingScorm = {
  name: 'downloadTestingScorm',
  method: 'get',
  url: ':id/scorm/testing',
  options: {
    responseType: 'blob',
  },
};

const downloadDocx = {
  name: 'downloadDocx',
  method: 'get',
  url: ':id/docx',
  options: {
    responseType: 'blob',
  },
};

const downloadPDF = {
  name: 'downloadPDF',
  method: 'get',
  url: ':id/pdf',
  options: {
    responseType: 'blob',
  },
};

const changeWorkspace = {
  name: 'changeWorkspace',
  method: 'post',
  url: 'change-workspace',
};

const sendInvite = {
  name: 'sendInvite',
  method: 'post',
  url: ':id/invite',
};

const combineVersions = {
  name: 'combineVersions',
  method: 'patch',
  url: 'version/:id/combine',
};

const getVersionsList = {
  name: 'getVersionsList',
  method: 'get',
  url: 'version/:id/versions-list',
};

const getVersion = {
  name: 'getVersion',
  method: 'get',
  url: 'version/:versionId',
};

export default {
  name: 'simulations',
  url: '/api/simulations',
  children: [
    create,
    update,
    updateDoc,
    updateDocV2,
    updateTesting,
    remove,
    restore,
    get,
    getDoc,
    getTesting,
    list,
    downloadScorm,
    downloadTestingScorm,
    downloadDocx,
    downloadPDF,
    changeWorkspace,
    sendInvite,
    combineVersions,
    getVersionsList,
    getVersion,
  ],
};
