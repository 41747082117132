<template>
  <div class="notifications-list">
    <div class="notifications-list__header-wrapper">
      <v-list-item class="notifications-list__header">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ $t('ui.notificationsList.notifications') }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon @click="setShowedSettings(true)">
            <v-icon color="grey darken-4">mdi-cog-outline</v-icon>
          </v-btn>
        </v-list-item-action>

        <v-list-item-action>
          <v-btn icon>
            <v-icon color="grey darken-4" @click="setShowedPanel(false)">mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-divider />
    </div>

    <v-container class="notifications-list__content">
      <div class="d-flex mb-4">
        <v-btn
          v-for="listType in listTypes"
          :key="listType.type"
          :color="selectedListType === listType.type ? 'primary' : 'grey lighten-1'"
          text
          @click="setListType(listType.type)"
        >
          {{ listType.text }}
        </v-btn>
      </div>

      <div v-if="list.length">
        <component
          :is="$options.notificationTypesComponentMapping[notification.type]"
          v-for="notification in list"
          :key="notification.id"
          :notification="notification"
        />
      </div>

      <div v-else class="notifications-list__empty-placeholder">
        {{ $t('ui.notificationsList.listEmptyPlaceholder') }}
      </div>
    </v-container>

    <v-container v-if="list.length" class="notifications-list__pagination">
      <span>{{ $t('ui.notificationsList.itemPerPage') }}</span>

      <v-select
        v-model="perPage"
        :items="perPageOptions"
        dense
        hide-details
        class="notifications-list__per-page-select mt-0 mx-4"
      />

      <span class="mr-4">
        {{ (page - 1) * perPage + 1 }}-{{ Math.min((page - 1) * perPage + perPage, serverCount) }}
        {{ $t('common.of') }} {{ serverCount }}
      </span>

      <v-btn icon small :disabled="page === 1" @click="page -= 1">
        <v-icon color="grey darken-1">mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn
        icon
        small
        :disabled="(page - 1) * perPage + perPage >= serverCount"
        class="ml-3"
        @click="page += 1"
      >
        <v-icon color="grey darken-1">mdi-chevron-right</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex';

  import eventsService from 'src/services/events';
  import { deepcopy } from 'src/utils';

  import {
    SET_NOTIFICATIONS_LIST,
    SET_NOTIFICATIONS_LIST_TYPE,
    SET_SHOWED_NOTIFICATIONS_PANEL,
    SET_SHOWED_NOTIFICATIONS_SETTINGS,
  } from 'src/store/mutation-types';

  import {
    NOTIFICATIONS_LIST_TYPE_ALL,
    NOTIFICATIONS_LIST_TYPE_UNREAD,
    NOTIFICATION_TYPE_ENTITY_MODERATION,
    EVENT_TYPE_CONTENT_MODERATION,
    EVENT_TYPE_CHANGE_DRAFT_STATUS,
  } from 'src/constants';

  import EntityModerateNotificationItem from './EntityModerateNotificationItem.vue';

  export default {
    name: 'NotificationList',

    data() {
      return {
        listTypes: Object.freeze([
          {
            type: NOTIFICATIONS_LIST_TYPE_ALL,
            text: this.$t('ui.notificationsList.notificationsTypeAll'),
          },
          {
            type: NOTIFICATIONS_LIST_TYPE_UNREAD,
            text: this.$t('ui.notificationsList.notificationsTypeUnread'),
          },
        ]),

        page: 1,
        perPage: 10,
        perPageOptions: Object.freeze([
          {
            text: '10',
            value: 10,
          },
          {
            text: '25',
            value: 25,
          },
          {
            text: '50',
            value: 50,
          },
          {
            text: this.$t('common.all'),
            value: 'all',
          },
        ]),
      };
    },

    computed: {
      ...mapState('notifications', {
        selectedListType: 'listType',
        list: 'list',
        unreadCount: 'unreadCount',
        serverCount: 'serverCount',
      }),
    },

    notificationTypesComponentMapping: {
      [NOTIFICATION_TYPE_ENTITY_MODERATION]: EntityModerateNotificationItem,
    },

    watch: {
      selectedListType() {
        this.page = 1;
        this.getList();
      },

      perPage() {
        this.page = 1;
        this.getList();
      },
    },

    mounted() {
      this.getList();

      eventsService.on(EVENT_TYPE_CONTENT_MODERATION, this.addNewNotification);
      eventsService.on(EVENT_TYPE_CHANGE_DRAFT_STATUS, this.changeDraftNotificationStatus);
    },

    beforeDestroy() {
      eventsService.off(EVENT_TYPE_CONTENT_MODERATION, this.addNewNotification);
      eventsService.off(EVENT_TYPE_CHANGE_DRAFT_STATUS, this.changeDraftNotificationStatus);
    },

    methods: {
      ...mapActions('notifications', ['getNotificationsList', 'addNotification']),
      ...mapMutations('notifications', {
        setShowedPanel: SET_SHOWED_NOTIFICATIONS_PANEL,
        setShowedSettings: SET_SHOWED_NOTIFICATIONS_SETTINGS,
        setListType: SET_NOTIFICATIONS_LIST_TYPE,
        setList: SET_NOTIFICATIONS_LIST,
      }),

      getList() {
        this.getNotificationsList({ page: this.page, perPage: this.perPage });
      },

      addNewNotification(notification) {
        this.addNotification(notification);
      },

      changeDraftNotificationStatus({ entityId: eventId, data }) {
        const index = this.list.findIndex(event => event.id === eventId);
        if (index !== -1) {
          const list = deepcopy(this.list);
          list[index].data.draftStatus = data.status;

          this.setList(list);
        }
      },
    },
  };
</script>

<style lang="scss">
  .notifications-list {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    &__header-wrapper {
      position: sticky;
      top: 0;
      background-color: #ffffff;
      z-index: 1;
    }

    &__header {
      flex-basis: auto;
      flex-grow: 0;
    }

    &__content {
      position: relative;
      flex: 1;
      overflow: auto;
      align-content: flex-start;
    }

    &__empty-placeholder {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      text-align: center;
      transform: translateY(-50%);
      color: #bbbbc6;
      font-size: 16px;
      line-height: 24px;
    }

    &__pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 20px;
    }

    &__per-page-select {
      flex: 0;
    }
  }
</style>
