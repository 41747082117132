import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';

Vue.use(Vuex);

const plugins = [];

export default new Vuex.Store({
  modules,
  plugins,
  strict: false,
});
