<template>
  <div class="billing-skeleton-loader">
    <v-card class="billing-skeleton-loader__info">
      <div class="d-flex justify-space-between ">
        <v-skeleton-loader type="heading" width="1000" class="mt-1" />

        <v-skeleton-loader type="button" />
      </div>

      <div class="mt-15 d-flex justify-space-between">
        <v-skeleton-loader
          v-for="index in 3"
          :key="`plan-info-${index}`"
          type="text"
          width="100"
          class="mt-1"
        />
      </div>
    </v-card>

    <div class="mt-15 d-flex justify-center">
      <v-skeleton-loader type="heading" width="300" class="d-flex justify-center" />
    </div>

    <div class="mt-15 d-flex justify-center align-center">
      <v-skeleton-loader type="text" width="100" />

      <v-skeleton-loader type="button" class="ml-4" />
      <v-skeleton-loader type="button" class="ml-2" />
    </div>

    <div class="billing-skeleton-loader__plans-wrapper">
      <v-card
        v-for="index in 3"
        :key="`plan-card-${index}`"
        class="billing-skeleton-loader__card pa-10"
        elevation="0"
      >
        <v-skeleton-loader type="heading" />

        <v-skeleton-loader type="heading" class="mt-5 mb-10" />

        <v-skeleton-loader
          v-for="feature in 10"
          :key="`plan-info-${index}-${feature}`"
          type="text"
          class="mt-8"
        />
      </v-card>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BillingSkeletonLoader',
  };
</script>

<style lang="scss">
  .billing-skeleton-loader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__info {
      width: 100%;
      max-width: 1213px;
      padding: 30px;
    }

    &__plans-wrapper {
      width: 100%;
      margin-top: 50px;
      display: grid;
      grid-gap: 40px;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: minmax(0, auto);
    }

    &__card {
      width: 100%;
      height: 700px;
    }
  }
</style>
