<template>
  <v-menu
    :ref="fieldTitle"
    v-model="showDateSelectMenu"
    :close-on-content-click="false"
    :return-value.sync="dateValue"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="dateValue"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>

    <v-date-picker v-model="dateValue" :min="minDate" :max="maxDate" no-title scrollable>
      <v-spacer />

      <v-btn text color="primary" @click="closeSelectDateMenu">
        {{ $t('ui.common.cancel') }}
      </v-btn>

      <v-btn text color="primary" @click="saveDateValue">
        {{ $t('ui.common.ok') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
  export default {
    name: 'BaseDateSelectMenu',

    props: {
      defaultDateValue: {
        type: String,
        required: false,
        default: null,
      },
      minDate: {
        type: String,
        required: false,
        default: null,
      },
      maxDate: {
        type: String,
        required: false,
        default: null,
      },
      label: {
        type: String,
        required: true,
      },
      fieldTitle: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        dateValue: null,
        showDateSelectMenu: false,
      };
    },

    mounted() {
      this.dateValue = this.defaultDateValue;
    },

    methods: {
      closeSelectDateMenu() {
        this.showDateSelectMenu = false;
      },

      saveDateValue() {
        this.$refs[this.fieldTitle].save(this.dateValue);
        this.$emit('change', this.dateValue);
      },
    },
  };
</script>
