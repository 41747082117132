const create = {
  name: 'create',
  method: 'post',
  url: '/api/element-style',
};

const getByOrganization = {
  name: 'getByOrganization',
  method: 'get',
  url: 'organization',
};

const update = {
  name: 'update',
  method: 'patch',
  url: ':id',
};

const enableTheme = {
  name: 'enableTheme',
  method: 'post',
  url: 'enable-theme/:id',
};

const remove = {
  name: 'remove',
  method: 'delete',
  url: ':id',
};

const enableElementTheme = {
  name: 'enableElementTheme',
  method: 'post',
  url: 'enable-element-theme',
};

const disableElementTheme = {
  name: 'disableElementTheme',
  method: 'post',
  url: 'disable-element-theme',
};

const getTooltipTheme = {
  name: 'getTooltipTheme',
  method: 'get',
  url: 'tooltip/:tooltipId',
};

const getScenarioTheme = {
  name: 'getScenarioTheme',
  method: 'get',
  url: 'scenario/:scenarioId',
};

export default {
  name: 'elementStyle',
  url: '/api/element-style',
  children: [
    create,
    update,
    getByOrganization,
    enableTheme,
    remove,
    enableElementTheme,
    disableElementTheme,
    getTooltipTheme,
    getScenarioTheme,
  ],
};
