<template>
  <v-card>
    <v-card-title class="primary mb-6">
      <span class="title white--text">
        {{ $t('ui.workspaceEntityMigrateModal.title') }}
      </span>

      <v-spacer />

      <v-btn icon dark @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-select
        v-model="workspaceId"
        :items="mappedWorkspaceList"
        :label="$t('ui.workspaceEntityMigrateModal.label')"
        outlined
        hide-details
        dense
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer />

      <v-btn color="primary" text @click="close()">
        {{ $t('ui.common.cancel') }}
      </v-btn>

      <v-btn :loading="isBusy" color="primary" @click="changeWorkspace()">
        {{ $t('ui.common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex';

  import api from 'src/api';
  import { busyFlow } from 'src/utils';

  export default {
    name: 'WorkspaceEntityMigrateModal',

    props: {
      type: {
        type: String,
        required: true,
      },

      list: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        isBusy: false,
        workspaceId: null,
      };
    },

    computed: {
      ...mapState('users', ['currentUser']),
      ...mapState('workspaces', ['workspacesList']),

      mappedWorkspaceList() {
        return this.workspacesList.map(workspace => ({
          value: workspace.id,
          text: workspace.isRoot ? this.$t('ui.workspaces.commonWorkspace') : workspace.title,
        }));
      },
    },

    mounted() {
      this.workspaceId = this.currentUser.workspaceId || null;
    },

    methods: {
      close() {
        this.$emit('close');
      },

      changeWorkspace() {
        if (this.workspaceId === this.currentUser.workspaceId) {
          this.close();

          return;
        }

        busyFlow.call(this, async () => {
          await api[this.type].changeWorkspace({
            data: {
              workspaceId: this.workspaceId,
              list: this.list,
            },
          });

          this.$emit('changed');
          this.close();
        });
      },
    },
  };
</script>
