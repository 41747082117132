<template>
  <v-menu
    v-model="popupOpened"
    :close-on-content-click="false"
    max-width="256"
    bottom
    offset-y
    content-class="menu-offset-class"
    rounded="0"
  >
    <template #activator="{ on, attrs }">
      <v-list-item class="profile-bg" v-bind="attrs" v-on="on">
        <v-list-item-content class="py-4">
          <v-list-item-title>{{ getWorkspaceName(selectedWorkspace) }}</v-list-item-title>

          <v-list-item-subtitle v-if="isFieldAllowed('email')">
            {{ currentUser.email }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-icon>{{ menuIcon }}</v-icon>
      </v-list-item>
    </template>

    <v-card tile>
      <v-list nav>
        <v-list-item-group v-model="selectedWorkspaceId">
          <v-list-item
            v-for="workspace in workspacesList"
            :key="workspace.id"
            :value="workspace.id"
          >
            <v-list-item-content>
              <v-list-item-title>{{ getWorkspaceName(workspace) }}</v-list-item-title>

              <v-list-item-subtitle v-if="workspace.shortname">
                {{ workspace.shortname }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action
              v-if="showWorkspaceActions(workspace)"
              class="flex-row align-center ml-2"
            >
              <v-btn v-if="isRealAdminOrHigher" icon small @click.stop="openEditDialog(workspace)">
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </v-btn>

              <v-btn v-if="workspace.isAdmin" icon small @click.stop="openUsersDialog(workspace)">
                <v-icon small>
                  mdi-account-multiple
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-card-actions v-if="!hasLDAP">
        <v-btn
          v-if="isRealAdminOrHigher"
          block
          color="primary"
          class="text-none"
          @click="openEditDialog()"
        >
          {{ $t('ui.workspaces.create') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="editingDialogOpened" max-width="852">
      <workspace-edit-modal
        v-if="editingDialogOpened"
        :editing-workspace="editingWorkspace"
        @close="editingDialogOpened = false"
      />
    </v-dialog>

    <v-dialog v-model="usersDialogOpened" max-width="900px">
      <workspace-users-adding-form v-if="usersDialogOpened" :entity="editingWorkspace" />
    </v-dialog>
  </v-menu>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';

  import WorkspaceUsersAddingForm from 'src/components/AddingForms/WorkspaceUsersAddingForm.vue';
  import WorkspaceEditModal from 'src/components/WorkspaceEditModal/WorkspaceEditModal.vue';

  export default {
    name: 'WorkspacesMenu',

    components: {
      WorkspaceUsersAddingForm,
      WorkspaceEditModal,
    },

    data() {
      return {
        popupOpened: false,
        editingWorkspace: null,
        editingDialogOpened: false,
        usersDialogOpened: false,
      };
    },

    computed: {
      ...mapState('users', ['currentUser']),
      ...mapState('workspaces', ['workspacesList']),
      ...mapState('settings', ['hasLDAP', 'allowedUserInfoFields']),
      ...mapGetters('workspaces', ['selectedWorkspace']),
      ...mapGetters('users', ['isRealAdminOrHigher', 'isSuperAdmin']),

      menuIcon() {
        return `mdi-menu-down ${this.popupOpened ? 'mdi-rotate-180' : ''}`;
      },

      selectedWorkspaceId: {
        get() {
          return this.selectedWorkspace?.id || null;
        },

        set(value) {
          if (value !== this.selectedWorkspaceId) {
            this.popupOpened = false;
            this.selectWorkspace(value);
          }
        },
      },
    },

    watch: {
      editingDialogOpened(value) {
        if (!value) {
          this.editingWorkspace = null;
        }
      },

      usersDialogOpened(value) {
        if (!value) {
          this.editingWorkspace = null;
        }
      },
    },

    methods: {
      ...mapActions('workspaces', ['selectWorkspace']),

      getWorkspaceName(workspace) {
        if (!workspace) {
          return null;
        }

        if (workspace.isRoot) {
          return this.$t('ui.workspaces.commonWorkspace');
        }

        return workspace.title;
      },

      openEditDialog(workspace = null) {
        this.editingWorkspace = workspace;
        this.editingDialogOpened = true;
      },

      openUsersDialog(workspace = null) {
        this.editingWorkspace = workspace;
        this.usersDialogOpened = true;
      },

      showWorkspaceActions(workspace) {
        if (this.hasLDAP) {
          return false;
        }

        return (workspace.isAdmin || this.isRealAdminOrHigher) && !workspace.isRoot;
      },

      isFieldAllowed(fieldName) {
        if (this.allowedUserInfoFields === 'all' || this.isSuperAdmin) {
          return true;
        }

        return this.allowedUserInfoFields.includes(fieldName);
      },
    },
  };
</script>

<style lang="scss">
  .menu-offset-class {
    margin: 0 0 0 -12px;
  }
</style>
