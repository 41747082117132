<template>
  <v-form ref="form" class="license-term-extension-modal" @submit.prevent="submit()">
    <v-card>
      <v-card-title>
        <span class="headline text-truncate">
          {{ $t('ui.licenseTermExtensionModal.title') }}
        </span>

        <v-spacer />

        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="monthsCount"
              :items="monthsItems"
              :label="$t('ui.licenseTermExtensionModal.fields.monthsCount')"
              hide-details="auto"
              outlined
            />
          </v-col>
        </v-row>

        <div
          class="mt-4 pa-4 d-flex align-center license-term-extension-modal__calculate"
          :class="{ 'license-term-extension-modal__calculate--error': price > balance }"
        >
          <v-icon :color="price > balance ? '#EB5757' : '#32A1F6'" large class="mr-4">
            mdi-license
          </v-icon>

          <div>
            <div>
              {{ $t('ui.licenseTermExtensionModal.costCalculation') }}:

              <span class="text-decoration-underline font-weight-bold ml-1">
                {{ $tc('ui.plurals.users', usersCount) }}
              </span>

              <span class="mx-1">*</span>

              <span class="text-decoration-underline font-weight-bold">
                {{ $tc('ui.plurals.months', monthsCount) }}
              </span>

              <span class="mx-1">=</span>

              <span class="font-weight-bold">
                {{ $tc('ui.plurals.credits', price) }}
              </span>
            </div>

            <div class="license-term-extension-modal font-weight-medium mt-2">
              <template v-if="price > balance">
                {{ $t('ui.licenseTermExtensionModal.notEnoughCredits') }}
              </template>

              <template v-else>
                {{ $t('ui.licenseTermExtensionModal.debited') }}:
                {{ $tc('ui.plurals.credits', price) }}
              </template>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text color="primary" @click="close()">
          {{ $t('ui.common.cancel') }}
        </v-btn>

        <v-btn
          :loading="isBusySubmit"
          :disabled="price > balance"
          text
          color="primary"
          type="submit"
        >
          {{ $t('ui.licenseTermExtensionModal.extendButton') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="isBusy" absolute>
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-form>
</template>

<script>
  import api from 'src/api';

  import { busyFlow } from 'src/utils';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  export default {
    name: 'LicenseTermExtensionModal',

    mixins: [formatTimestampMixin],

    props: {
      clientId: {
        type: String,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        isBusy: false,
        balance: Number.MAX_VALUE,
        isBusySubmit: false,

        usersCount: 0,
        monthsCount: 6,

        monthsItems: Array(12)
          .fill(null)
          .map((value, index) => ({
            text: this.$tc('ui.plurals.months', index + 1),
            value: index + 1,
          })),
      };
    },

    computed: {
      price() {
        return this.monthsCount * this.usersCount;
      },
    },

    watch: {
      balance(value) {
        this.$emit('updateBalance', value);
      },
    },

    mounted() {
      this.getData();
    },

    methods: {
      submit() {
        const { form } = this.$refs;
        if (!form.validate()) {
          return;
        }

        busyFlow.call(
          this,
          async () => {
            try {
              const { response } = await api.integrators.clientLicenseAddMonths({
                url_params: {
                  id: this.clientId,
                },
                data: {
                  monthsCount: this.monthsCount,
                },
              });

              this.$emit('updateBalance', response.balance);
              this.$emit('updated');
              this.close();
            } catch (error) {
              if (error.code === 'INSUFFICIENT_FUNDS') {
                this.balance = error.details.balance;
                this.$emit('updateBalance', error.details.balance);
              }
            }
          },
          false,
          'isBusySubmit'
        );
      },

      getData() {
        busyFlow.call(this, async () => {
          const { response: integratorData } = await api.integrators.getCurrent();
          const { response: licenseData } = await api.integrators.getClientLicense({
            url_params: { id: this.clientId },
          });

          this.balance = integratorData.balance;
          this.usersCount = licenseData.usersCount;
        });
      },

      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss">
  .license-term-extension-modal {
    &__calculate {
      background-color: rgba(44, 130, 226, 0.15);
      border-radius: 4px;
      color: #32a1f6;
      font-size: 14px;
      line-height: 20px;

      &--error {
        color: #eb5757;
        background-color: rgba(235, 87, 87, 0.15);
      }
    }

    &__price {
      font-size: 20px;
      line-height: 23px;
    }
  }
</style>
