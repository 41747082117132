<template>
  <v-container>
    <v-alert :value="!isAvailable" color="info" text>
      <template #prepend>
        <v-icon color="info" x-large class="mr-5">mdi-information</v-icon>
      </template>

      <template #default>
        <h4 class="mb-3">{{ $t('pages.logs.disabledTitle') }}</h4>

        <!-- eslint-disable vue/no-v-html -->
        <div v-html="$t('pages.logs.enableDescription')" />
        <!-- eslint-enable vue/no-v-html -->

        <div>
          {{ $t('pages.logs.disableEmailMask') }}
          <b> LOGS_TRACING_LOGS_EMAIL_MASK=false</b>
        </div>
      </template>
    </v-alert>

    <v-card>
      <v-card-text>
        <v-data-table
          v-model="selectedItems"
          :loading="isBusy"
          :headers="headers"
          :items="logs"
          item-key="filename"
          show-select
          hide-default-footer
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title class="flex-shrink-0">{{ $t('pages.logs.title') }}</v-toolbar-title>

              <v-divider class="mx-4" inset vertical />

              <v-progress-linear
                v-if="isBusyDownload"
                :value="downloadPercent"
                buffer-value="100"
                color="primary"
                rounded
              />

              <v-spacer />

              <v-btn
                v-if="selectedItems.length"
                :loading="isBusyDownload && downloadedFileIndex === null"
                color="primary"
                dark
                class="ml-3 text-none"
                @click="confirmDownload(selectedItems)"
              >
                <v-icon left>
                  mdi-download
                </v-icon>

                {{ $t('ui.common.download') }}
              </v-btn>
            </v-toolbar>
          </template>

          <template #item.period="{item}">
            {{ formatDateTime(item.startDate) }} &mdash; {{ formatDateTime(item.endDate) }}
          </template>

          <template #item.action="{item, index}">
            <v-btn
              :loading="isBusyDownload && downloadedFileIndex === index"
              icon
              @click="confirmDownload([item], index)"
            >
              <v-icon color="primary">mdi-download</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="confirmDialogOpened" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('pages.logs.sizeLimit') }}
        </v-card-title>

        <v-card-text v-if="confirmedDownloadingItems.length">
          {{ $t('pages.logs.filesLimit') }}:
          {{ confirmedDownloadingItems.map(item => item.filename).join(', ') }}
        </v-card-text>

        <v-card-text>
          {{ $t('pages.logs.filesIsLarge') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            v-if="confirmedDownloadingItems.length"
            color="primary"
            text
            @click="confirmDialogOpened = false"
          >
            {{ $t('ui.common.cancel') }}
          </v-btn>

          <v-btn color="primary" text @click="downloadArchive()">
            {{ $t('ui.common.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { saveAs } from 'file-saver';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  const DOWNLOAD_SIZE_LIMIT = 2 * 1024 * 1024 * 1024;

  export default {
    name: 'Logs',

    mixins: [formatTimestampMixin],

    data() {
      return {
        isAvailable: true,
        logs: [],
        selectedItems: [],
        isBusy: false,
        isBusyDownload: false,
        confirmDialogOpened: false,
        downloadedFileIndex: null,
        downloadedItems: [],
        downloadPercent: 0,

        headers: Object.freeze([
          { text: this.$t('pages.logs.tableHeaders.filename'), value: 'filename', sortable: false },
          { text: this.$t('pages.logs.tableHeaders.period'), value: 'period', sortable: false },
          {
            text: this.$t('ui.common.actions'),
            value: 'action',
            align: 'center',
            width: 135,
            sortable: false,
          },
        ]),
      };
    },

    computed: {
      ...mapGetters('users', ['isAuditor']),

      confirmedDownloadingItems() {
        if (!this.downloadedItems.length) {
          return [];
        }

        let size = 0;
        return this.downloadedItems.reduce((acc, item) => {
          if (size + item.size <= DOWNLOAD_SIZE_LIMIT) {
            acc.push(item);
            size += item.size;
          }

          return acc;
        }, []);
      },
    },

    async mounted() {
      if (!this.isAuditor) {
        this.$router.push({ name: 'Dashboard' });

        return;
      }

      const result = await this.checkAvailability();

      this.isAvailable = result;

      if (result) {
        this.isBusy = true;
        this.logs = await this.getList();
        this.isBusy = false;
      }
    },

    methods: {
      ...mapActions('trace', ['checkAvailability', 'getList', 'download']),

      async confirmDownload(items, fileIndex = null) {
        if (this.isBusyDownload) {
          return;
        }

        this.downloadedItems = items;
        this.downloadedFileIndex = fileIndex;

        await this.$nextTick();

        if (items.length !== this.confirmedDownloadingItems.length) {
          this.confirmDialogOpened = true;

          return;
        }

        this.downloadArchive();
      },

      async downloadArchive() {
        if (this.isBusyDownload) {
          return;
        }

        this.confirmDialogOpened = false;

        if (!this.confirmedDownloadingItems.length) {
          return;
        }

        this.isBusyDownload = true;
        this.downloadPercent = 0;

        const response = await this.download({
          filenames: this.confirmedDownloadingItems.map(item => item.filename),
          onDownloadProgress: progressEvent => {
            this.downloadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        });

        this.isBusyDownload = false;
        this.downloadPercent = 0;
        this.downloadedFileIndex = null;

        saveAs(response, 'logs.zip');
      },
    },
  };
</script>

<style lang="scss"></style>
