<template>
  <v-card class="checklist-edit-modal">
    <v-card-title class="pb-0">
      <span class="headline">
        <template v-if="isEdit">
          {{ $t('pages.checklists.editModal.title.edit') }}
        </template>

        <template v-else>
          {{ $t('pages.checklists.editModal.title.create') }}
        </template>
      </span>

      <v-spacer />

      <v-btn icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="px-0">
      <v-stepper v-model="stepperState" elevation="0" vertical non-linear>
        <v-stepper-step step="1" :editable="isEdit">
          {{ $t('pages.checklists.editModal.settingsTitle') }}
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-form ref="form" class="checklist-edit-modal__form" @submit.prevent="onSubmit()">
            <v-container class="mb-10" fluid>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    ref="titleInput"
                    v-model="checklist.title"
                    :label="$t('pages.checklists.editModal.fields.title')"
                    :required="isEdit"
                    :rules="isEdit ? [rules.getRequiredRule('title')] : []"
                  />
                </v-col>

                <v-col v-if="isModeratorOrHigher" cols="12" class="py-0">
                  <v-combobox
                    v-model="checkListGroups"
                    :items="allGroups"
                    :loading="isBusyGroups"
                    :label="$t('pages.checklists.editModal.fields.groups')"
                    :hint="$t('pages.users.groupsModal.inputHint')"
                    item-text="title"
                    multiple
                    small-chips
                    deletable-chips
                  />
                </v-col>

                <v-col cols="12" class="py-0 my-4">
                  <origin-list-edit v-model="checklist.originList" />
                </v-col>

                <v-col cols="12" class="py-0">
                  <v-switch
                    v-model="checklist.published"
                    :label="$t('pages.checklists.editModal.fields.published')"
                    hide-details
                    class="mt-0"
                  />
                </v-col>

                <v-col cols="12" class="py-0">
                  <v-switch
                    v-model="checklist.hasCompletedScenarios"
                    :label="$t('pages.checklists.editModal.fields.hasCompletedScenarios')"
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>

            <v-btn color="primary" :loading="isBusy" type="submit">
              {{ $t(`ui.common.${isEdit ? 'save' : 'create'}`) }}
            </v-btn>

            <v-btn color="primary" text class="ml-2" @click="close()">
              {{ $t('ui.common.cancel') }}
            </v-btn>
          </v-form>
        </v-stepper-content>

        <v-stepper-step step="2" :editable="isEdit">
          {{ $t('pages.checklists.editModal.scenariosTitle') }}
        </v-stepper-step>

        <v-stepper-content step="2">
          <checklist-scenarios-adding-form
            v-if="Number(stepperState) === 2 && checklist.id"
            :entity="checklist"
            :per-page="3"
            embedded
          />

          <v-btn color="primary" class="mt-6" @click="close()">
            {{ $t('ui.common.close') }}
          </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
  import urlParse from 'url-parse';
  import pick from 'lodash.pick';
  import { mapGetters, mapState } from 'vuex';

  import api from 'src/api';
  import { busyFlow, deepcopy } from 'src/utils';

  import ChecklistScenariosAddingForm from 'src/components/AddingForms/ChecklistScenariosAddingForm.vue';
  import OriginListEdit from 'src/components/OriginListEdit/OriginListEdit.vue';

  const checklistEditableParams = [
    'title',
    'published',
    'hasCompletedScenarios',
    'origin',
    'originList',
  ];

  export default {
    name: 'ChecklistEditModal',

    components: {
      ChecklistScenariosAddingForm,
      OriginListEdit,
    },

    props: {
      editingChecklist: {
        type: Object,
        required: false,
        default: null,
      },

      scenariosManaging: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        stepperState: this.scenariosManaging ? 2 : 1,
        isBusy: false,
        isBusyGroups: false,
        isEdit: false,
        checklist: {
          title: '',
          published: false,
          hasCompletedScenarios: false,
          origin: null,
          originList: [
            {
              type: 'text',
              value: '',
            },
          ],
        },
        checkListGroups: [],
        originalChecklistGroups: [],
        allGroups: [],
        rules: {
          getRequiredRule: name => v =>
            !!v ||
            this.$t('validation.notNullField', {
              field: this.$t(`pages.checklists.editModal.fields.${name}`),
            }),
        },
      };
    },

    computed: {
      ...mapState('users', ['currentUser']),
      ...mapGetters('users', ['isModeratorOrHigher']),
    },

    async created() {
      this.isBusyGroups = true;

      if (this.isModeratorOrHigher) {
        await this.loadAllGroups();
      }

      if (this.editingChecklist) {
        this.isEdit = true;
        this.checklist = deepcopy(this.editingChecklist);

        if (this.isModeratorOrHigher) {
          await this.loadChecklistGroups();
        }
      }

      this.isBusyGroups = false;
      await this.$nextTick();
      this.$refs.titleInput.focus();
    },

    methods: {
      getValidUrl(url = '') {
        let newUrl = window.decodeURIComponent(url);
        newUrl = newUrl.trim().replace(/\s/g, '');

        if (/^(:\/\/)/.test(newUrl)) {
          return `http${newUrl}`;
        }
        if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
          return `http://${newUrl}`;
        }

        return newUrl;
      },

      parseUrl(url) {
        if (!url) {
          return null;
        }

        try {
          return urlParse(url);
        } catch (err) {
          return null;
        }
      },

      async loadChecklistGroups() {
        const { response: groups } = await api.checklists.getGroups({
          url_params: { id: this.editingChecklist.id },
        });

        this.checkListGroups = groups;
        this.originalChecklistGroups = Object.freeze(deepcopy(groups));
      },

      async loadAllGroups() {
        const {
          response: { payload: groups },
        } = await api.groups.list({
          params: { organizationId: this.currentUser.organizationId, limit: 'all', page: 1 },
        });

        this.allGroups = Object.freeze(groups);
      },

      async onSubmit() {
        if (!this.$refs.form.validate()) {
          return;
        }

        busyFlow.call(this, async () => {
          let checklistId = null;

          const data = pick(this.checklist, checklistEditableParams);

          if (this.isEdit) {
            checklistId = this.editingChecklist.id;

            await api.checklists.update({
              url_params: { id: checklistId },
              data,
            });
          } else {
            const { response } = await api.checklists.create({
              data: {
                ...data,
                title: data.title || this.$t('pages.checklists.editModal.defaultChecklistTitle'),
              },
            });

            checklistId = response.id;

            this.checklist = response;
          }

          if (this.isModeratorOrHigher) {
            await this.applyGroups(checklistId);
          }

          if (this.isEdit) {
            this.$emit('updated');
            this.close();
          } else {
            this.$emit('created');
            this.isEdit = true;
            this.stepperState = 2;
          }
        });
      },

      async applyGroups(checklistId) {
        const promises = [];

        const checklistGroupsIds = this.checkListGroups.map(group => group.id);
        const originalChecklistGroupsIds = this.originalChecklistGroups.map(group => group.id);
        const addingGroups = checklistGroupsIds.filter(
          id => !originalChecklistGroupsIds.includes(id)
        );
        const removingGroups = originalChecklistGroupsIds.filter(
          id => !checklistGroupsIds.includes(id)
        );

        if (addingGroups.length) {
          addingGroups.forEach(id => {
            promises.push(
              api.groups.addChecklists({
                url_params: { id },
                data: {
                  checklists: [checklistId],
                },
              })
            );
          });
        }

        if (this.isEdit && removingGroups.length) {
          removingGroups.forEach(id => {
            promises.push(
              api.groups.removeChecklists({
                url_params: { id },
                data: {
                  checklists: [checklistId],
                },
              })
            );
          });
        }

        await Promise.all(promises);
      },

      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss">
  .checklist-edit-modal {
    &__form {
      max-width: 550px;
    }
  }
</style>
