<template>
  <v-container class="styles">
    <v-progress-linear v-if="!activeTheme" indeterminate color="primary" />

    <template v-else>
      <v-row class="white ma-0 pa-4 mb-4">
        <v-col class=" d-flex pa-0">
          <v-select
            v-if="!openedTitleEditForm"
            v-model="activeTheme"
            :items="elementStyles"
            item-text="title"
            item-value="id"
            hide-details
            dense
            outlined
            :label="$t('pages.styles.selectTheme')"
            class="mr-3"
          />

          <v-text-field
            v-else
            ref="titleInput"
            v-model="tempThemeTitle"
            :label="$t('pages.styles.themeTitle')"
            hide-details
            dense
            outlined
            class="mr-3"
            @keydown.enter="saveTitle()"
            @keydown.esc="closeEditTitleForm()"
          >
          </v-text-field>

          <template v-if="!openedTitleEditForm">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" @click="openEditTitleForm(true)" v-on="on">
                  <v-icon size="18">mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t(`common.actions.addTheme`) }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="isSuperAdmin"
                  :color="activeTheme.isPublic ? 'info' : undefined"
                  icon
                  v-bind="attrs"
                  @click="publishTheme()"
                  v-on="on"
                >
                  <v-icon size="18">mdi-cloud-upload</v-icon>
                </v-btn>
              </template>
              <span>{{ $t(`common.actions.publishTheme`) }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" @click="copyTheme()" v-on="on">
                  <v-icon size="18">mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>{{ $t(`common.actions.createThemeCopy`) }}</span>
            </v-tooltip>

            <template
              v-if="
                !activeTheme.isRoot &&
                  ((activeTheme.isPublic && isSuperAdmin) ||
                    (!activeTheme.isPublic && isDesignerOrHigher))
              "
            >
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="openEditTitleForm()" v-on="on">
                    <v-icon size="18">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`common.actions.edit`) }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-if="!activeTheme.isPublic"
                    :loading="isBusyDelete"
                    icon
                    v-bind="attrs"
                    @click="deleteTheme()"
                    v-on="on"
                  >
                    <v-icon size="18">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`common.actions.delete`) }}</span>
              </v-tooltip>
            </template>
          </template>

          <template v-else>
            <v-btn icon @click="saveTitle()">
              <v-icon size="18">mdi-check</v-icon>
            </v-btn>

            <v-btn icon @click="closeEditTitleForm()">
              <v-icon size="18">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-col>

        <v-col v-if="!openedTitleEditForm && !isSmartManualsPlansActive" cols="3" class="pa-0 ml-2">
          <v-select
            v-model="type"
            :items="themeTypeOptions"
            hide-details
            dense
            outlined
            :label="$t('pages.styles.themeType')"
            class="mr-3"
          />
        </v-col>

        <v-col class="pa-0 text-right align-center justify-end d-flex">
          <v-btn :loading="isBusyActiveTheme" color="primary" small @click="applyTheme()">
            {{ $t('pages.styles.applyTheme') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-row class="white ma-0 px-4">
            <v-col class="d-flex align-center pa-0">
              <v-tabs v-model="tab" class="styles__types-tabs mr-4">
                <v-tab v-for="{ title } in tabs" :key="title">
                  {{ title }}
                </v-tab>
              </v-tabs>

              <v-spacer />

              <v-btn color="grey lighten-4" small class="mr-3" @click="applyCurrentStyles()">
                {{ $t('pages.styles.resetTheme') }}
              </v-btn>

              <v-btn :loading="isBusy" color="primary" small @click="save()">
                {{ $t('pages.styles.saveTheme') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="styles__widget-column pb-0" cols="6">
          <v-row justify="space-between">
            <v-col> </v-col>

            <v-spacer />

            <v-col cols="auto"> </v-col>
          </v-row>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="{ title, component } in tabs" :key="title" :transition="false">
              <component
                :is="component"
                v-if="component === currentKey"
                :schema="schema[component]"
                :settings="settings[getWidgetName(component)]"
                :type="type"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>

        <v-col cols="6">
          <styles-controls
            v-model="currentSchema"
            :type="currentElement"
            :settings="currentSettings"
            :theme-type="type"
            @update:settings="currentSettings = $event"
          />
        </v-col>
      </v-row>

      <v-snackbar v-model="showedActiveThemeToast" color="info" timeout="3000">
        {{ $t('pages.styles.themeSelected') }}

        <template #action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="showedActiveThemeToast = false">
            {{ $t('ui.common.close') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar v-model="showThemeSavedToast" color="success" timeout="3000">
        {{ $t('pages.styles.themeUpdated') }}

        <template #action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="showThemeSavedToast = false">
            {{ $t('ui.common.close') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-model="confirmOpened" max-width="380" style="z-index:100000000;">
        <v-card>
          <v-card-title class="text-h5">
            {{ $t('pages.styles.confirmModal.title') }}
          </v-card-title>

          <v-card-text>
            {{ $t('pages.styles.confirmModal.text') }}
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn color="grey" text @click="$options.cancelCallback()">
              {{ $t('ui.common.dontSave') }}
            </v-btn>

            <v-btn color="primary" text @click="$options.confirmCallback()">
              {{ $t('ui.common.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
  import isEqual from 'lodash.isequal';

  import { busyFlow, deepcopy } from 'src/utils';

  import { SET_ACTIVE_ELEMENT_STYLE_ID } from 'src/store/mutation-types';

  import { THEME_TYPE_BASIC, THEME_TYPE_CLASSIC } from 'src/pages/Styles/utils';
  import {
    hintDefaultsSchema,
    highlightDefaultsSchema,
    ratingDefaultSchema,
    supportCenterDefaultSchema,
    tooltipDefaultsSchema,
    modalDefaultSchema,
    simulationDefaultSchema,
    defaultHintSettings,
    getDefaultSupportCenterSettings,
    defaultTooltipSettings,
  } from './utils/defaults';

  import StylesControls from './components/StylesControls.vue';
  import HintPreview from './components/preview/HintPreview.vue';
  import HighlightPreview from './components/preview/HighlightPreview.vue';
  import TooltipPreview from './components/preview/TooltipPreview.vue';
  import SupportCenterPreview from './components/preview/SupportCenterPreview.vue';
  import RatingPreview from './components/preview/RatingPreview.vue';
  import ModalPreview from './components/preview/ModalPreview.vue';
  import SimulationPreview from './components/preview/SimulationPreview.vue';

  export default {
    name: 'Styles',

    components: {
      StylesControls,
      HintPreview,
      HighlightPreview,
      TooltipPreview,
      SupportCenterPreview,
      RatingPreview,
      ModalPreview,
      SimulationPreview,
    },

    provide() {
      return {
        orgSettings: this.orgSettings,
      };
    },

    data() {
      return {
        isBusy: false,
        isBusyActiveTheme: false,
        currentStyleId: null,
        showThemeSavedToast: false,
        showedActiveThemeToast: false,
        tempThemeTitle: null,
        openedTitleEditForm: false,
        confirmOpened: false,
        creatingNewItem: false,
        isBusyDelete: false,

        compareSchema: null,
        compareSettings: null,
        compareType: null,

        orgSettings: {
          logoUrl: null,
        },

        tab: 0,

        defaultsSchemas: Object.freeze({
          HintPreview: { ...hintDefaultsSchema },
          HighlightPreview: { ...highlightDefaultsSchema },
          TooltipPreview: { ...tooltipDefaultsSchema },
          SupportCenterPreview: { ...supportCenterDefaultSchema },
          RatingPreview: { ...ratingDefaultSchema },
          ModalPreview: { ...modalDefaultSchema },
          SimulationPreview: { ...simulationDefaultSchema },
        }),

        defaultSettings: Object.freeze({
          hint: { ...defaultHintSettings },
          highlight: {},
          tooltip: { ...defaultTooltipSettings },
          supportCenter: { ...getDefaultSupportCenterSettings.call(this) },
          simulation: {},
        }),

        schema: {
          HintPreview: { ...hintDefaultsSchema[THEME_TYPE_BASIC] },
          HighlightPreview: { ...highlightDefaultsSchema[THEME_TYPE_BASIC] },
          TooltipPreview: { ...tooltipDefaultsSchema[THEME_TYPE_BASIC] },
          SupportCenterPreview: { ...supportCenterDefaultSchema[THEME_TYPE_BASIC] },
          RatingPreview: { ...ratingDefaultSchema[THEME_TYPE_BASIC] },
          ModalPreview: { ...modalDefaultSchema[THEME_TYPE_BASIC] },
          SimulationPreview: { ...simulationDefaultSchema[THEME_TYPE_BASIC] },
        },

        settings: {
          hint: { ...defaultHintSettings },
          highlight: {},
          tooltip: { ...defaultTooltipSettings },
          supportCenter: { ...getDefaultSupportCenterSettings.call(this) },
          rating: {},
          modal: {},
          simulation: {},
        },

        styles: {
          hint: {},
          highlight: {},
          tooltip: {},
          supportCenter: {},
          rating: {},
          modal: {},
          simulation: {},
        },

        type: THEME_TYPE_BASIC,
      };
    },

    confirmCallback: null,
    cancelCallback: null,

    computed: {
      ...mapState('elementStyle', ['elementStyles', 'activeElementStyleId']),
      ...mapGetters('elementStyle', ['activeElementStyle']),
      ...mapGetters('users', [
        'isSuperAdmin',
        'isDesignerOrHigher',
        'isEnterprisePlanActive',
        'isSmartManualsPlansActive',
      ]),
      ...mapState('users', ['currentUser']),

      themeTypeOptions() {
        return [
          {
            value: THEME_TYPE_BASIC,
            text: this.$t('pages.styles.basic'),
          },
          {
            value: THEME_TYPE_CLASSIC,
            text: this.$t('pages.styles.classic'),
          },
        ];
      },

      tabs() {
        if (this.isSmartManualsPlansActive) {
          return [
            {
              title: this.$t('pages.styles.entities.simulation'),
              component: 'SimulationPreview',
              type: 'simulation',
            },
          ];
        }

        return [
          {
            title: this.$t('pages.styles.entities.hint'),
            component: 'HintPreview',
            type: 'hint',
          },

          {
            title: this.$t('pages.styles.entities.modal'),
            component: 'ModalPreview',
            type: 'modal',
          },

          {
            title: this.$t('pages.styles.entities.highlight'),
            component: 'HighlightPreview',
            type: 'highlight',
          },

          {
            title: this.$t('pages.styles.entities.tooltip'),
            component: 'TooltipPreview',
            type: 'tooltip',
          },
          {
            title: this.$t('pages.styles.entities.supportCenter'),
            component: 'SupportCenterPreview',
            type: 'supportCenter',
          },

          ...(this.isEnterprisePlanActive
            ? [
                {
                  title: this.$t('pages.styles.entities.rating'),
                  component: 'RatingPreview',
                  type: 'rating',
                },
              ]
            : []),

          {
            title: this.$t('pages.styles.entities.simulation'),
            component: 'SimulationPreview',
            type: 'simulation',
          },
        ];
      },

      currentKey() {
        return this.tabs[this.tab].component;
      },

      currentSchema: {
        get() {
          return this.schema[this.currentKey];
        },

        set(value) {
          this.schema[this.currentKey] = value;

          window.HintedWidget.setSchema(this.schema, this.type);
        },
      },

      currentSettings: {
        get() {
          return this.settings[this.getWidgetName(this.currentKey)];
        },

        set(value) {
          this.settings[this.getWidgetName(this.currentKey)] = deepcopy(value);
        },
      },

      currentElement() {
        return this.tabs[this.tab].type;
      },

      activeTheme: {
        get() {
          return this.activeElementStyle;
        },

        set(value) {
          if (this.hasUnsavedChanges()) {
            this.confirmOpened = true;

            this.$options.confirmCallback = async () => {
              this.confirmOpened = false;
              await this.save();
              this[SET_ACTIVE_ELEMENT_STYLE_ID](value);
            };

            this.$options.cancelCallback = () => {
              this.confirmOpened = false;
              this[SET_ACTIVE_ELEMENT_STYLE_ID](value);
            };

            return;
          }

          this[SET_ACTIVE_ELEMENT_STYLE_ID](value);
        },
      },
    },

    watch: {
      activeTheme() {
        this.applyCurrentStyles();
      },

      type(value) {
        window.HintedWidget.setSchema(this.schema, value);
      },
    },

    mounted() {
      busyFlow.call(this, async () => {
        await this.$loadScript('/hinted-ondemand-widget.min.js');
        await this.$loadScript('/hinted-simulation-player.js');

        await this.getByOrganization();

        this[SET_ACTIVE_ELEMENT_STYLE_ID](
          this.elementStyles.find(elementStyle => elementStyle.isActive).id
        );

        await this.applyCurrentStyles();

        const { logoUrl } = await this.getOrgSettings({ id: this.currentUser.organizationId });
        this.orgSettings.logoUrl = logoUrl;
      });
    },

    methods: {
      ...mapActions('elementStyle', [
        'create',
        'getByOrganization',
        'update',
        'enableTheme',
        'remove',
      ]),
      ...mapActions('organizations', ['getOrgSettings']),
      ...mapMutations('elementStyle', [SET_ACTIVE_ELEMENT_STYLE_ID]),

      async applyCurrentStyles() {
        await this.$nextTick();

        this.type = this.activeElementStyle.type;

        Object.keys(this.schema).forEach(componentName => {
          this.schema[componentName] = {
            ...this.defaultsSchemas[componentName][this.type],
            ...this.activeElementStyle.schema[componentName],
          };
        });

        Object.keys(this.settings).forEach(componentName => {
          this.settings[componentName] = {
            ...this.defaultSettings[componentName],
            ...this.activeElementStyle.settings[componentName],
          };
        });

        window.HintedWidget.setSchema(this.schema, this.type);

        this.compareSchema = Object.freeze(deepcopy(this.schema));
        this.compareSettings = Object.freeze(deepcopy(this.settings));
        this.compareType = this.type;
      },

      hasUnsavedChanges() {
        return (
          !isEqual(this.compareSchema, this.schema) ||
          !isEqual(this.compareSettings, this.settings) ||
          this.type !== this.compareType
        );
      },

      async submit() {
        const payload = {
          schema: { ...this.schema },
          settings: { ...this.settings },
          styles: this.activeElementStyle.styles,
          type: this.type,
        };

        let id = this.activeElementStyleId;
        const themeIsActive = this.activeTheme.isActive;
        if ((this.activeTheme.isPublic && !this.isSuperAdmin) || this.activeTheme.isRoot) {
          id = await this.copyTheme();

          if (themeIsActive) {
            await this.enableTheme(id);
          }
        }

        await this.update({ id, payload });

        await this.getByOrganization();

        this.showThemeSavedToast = true;
      },

      save() {
        busyFlow.call(this, async () => {
          await this.submit();
        });
      },

      getWidgetName(name) {
        switch (name) {
          case 'HighlightPreview':
            return 'highlight';
          case 'TooltipPreview':
            return 'tooltip';
          case 'SupportCenterPreview':
            return 'supportCenter';
          case 'RatingPreview':
            return 'rating';
          case 'ModalPreview':
            return 'modal';
          case 'SimulationPreview':
            return 'simulation';
          case 'HintPreview':
          default:
            return 'hint';
        }
      },

      applyTheme() {
        busyFlow.call(
          this,
          async () => {
            await this.enableTheme(this.activeElementStyleId);

            this.showedActiveThemeToast = true;
          },
          false,
          'isBusyActiveTheme'
        );
      },

      async openEditTitleForm(creatingNewItem = false) {
        this.creatingNewItem = creatingNewItem;

        if (!creatingNewItem) {
          this.tempThemeTitle = this.activeTheme.title;
        }

        this.openedTitleEditForm = true;

        await this.$nextTick();

        this.$refs.titleInput.focus();
      },

      async saveTitle() {
        const { creatingNewItem } = this;

        if (!this.tempThemeTitle) {
          this.closeEditTitleForm();

          return;
        }

        let schema = {};
        let settings = {};

        if (!creatingNewItem) {
          schema = this.activeTheme.schema;
          settings = this.activeTheme.settings;
        }

        const payload = {
          schema,
          settings,
          styles: {},
          type: THEME_TYPE_CLASSIC,
          title: this.tempThemeTitle,
        };

        let theme = null;
        if (creatingNewItem) {
          theme = await this.create(payload);
        } else {
          await this.update({ id: this.activeElementStyleId, payload });
        }

        this.closeEditTitleForm();

        await this.getByOrganization();

        if (creatingNewItem) {
          this[SET_ACTIVE_ELEMENT_STYLE_ID](theme.id);
        }
      },

      async publishTheme() {
        if (this.activeTheme.isPublic) {
          return;
        }

        const { schema, settings } = this.activeTheme;
        const payload = {
          schema,
          settings,
          styles: this.activeElementStyle.styles,
          isPublic: true,
        };

        await this.update({ id: this.activeElementStyleId, payload });
        this.getByOrganization();
      },

      closeEditTitleForm() {
        this.openedTitleEditForm = false;
        this.tempThemeTitle = null;
        this.creatingNewItem = false;
      },

      deleteTheme() {
        busyFlow.call(
          this,
          async () => {
            const { id } = this.activeTheme;

            if (this.activeTheme.isActive) {
              const rootTheme = this.elementStyles.find(elementStyles => elementStyles.isRoot);

              await this.enableTheme(rootTheme.id);
              this[SET_ACTIVE_ELEMENT_STYLE_ID](rootTheme.id);
            } else {
              const currentEnabledTheme = this.elementStyles.find(
                elementStyles => elementStyles.isActive
              );
              this[SET_ACTIVE_ELEMENT_STYLE_ID](currentEnabledTheme.id);
            }

            await this.remove(id);
            await this.getByOrganization();
          },
          false,
          'isBusyDelete'
        );
      },

      async copyTheme() {
        const { schema, settings, title, type, styles } = this.activeTheme;
        const payload = { schema, settings, styles, type, title: `${title} (copy)` };

        const theme = await this.create(payload);

        await this.getByOrganization();

        this[SET_ACTIVE_ELEMENT_STYLE_ID](theme.id);

        return theme.id;
      },
    },
  };
</script>

<style lang="scss">
  .styles {
    &__types-tabs {
      min-width: 0;
    }

    &__widget-column {
      position: sticky;
      top: 80px;
      height: 100%;
    }
  }
</style>
