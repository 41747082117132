<template>
  <hinted-modal class="fake-modal hinted-modal hinted-widget__custom-styles">
    <div
      hinted-name="background"
      class="hinted-modal-background"
      style="background-color: rgba(0, 0, 0, 0.8);"
    ></div>
    <div hinted-name="hinted-hint-bubble" class="hinted-hint-bubble" style="top: 10%;">
      <div class="hinted-hint-left-border">
        <div hinted-name="container" class="hinted-hint-bubble-container" style="width: 400px;">
          <div class="hinted-hint-bubble-content">
            <h3 hinted-name="title" class="hinted-hint-title"></h3>
            <div
              hinted-name="content"
              class="hinted-hint-content ql-editor"
              style="max-height: 621.6px;"
            >
              <h3>{{ $t('pages.styles.loremTitle') }}</h3>
              <p>
                {{ $t('pages.styles.loremParagraph') }}
              </p>
            </div>
          </div>
          <div class="hinted-hint-modal-footer">
            <div class="hinted-hint-actions">
              <button
                hinted-name="cancel"
                hinted-click="onClick"
                style="display:none;"
                class="hinted-hint-nav-button next hinted-hint-cta"
              >
                Отмена
              </button>
              <button
                hinted-name="start"
                hinted-click="onClick"
                style="display:none;"
                class="hinted-hint-nav-button next hinted-hint-start"
              >
                Начать
              </button>
              <button
                hinted-name="prev"
                hinted-click="onClick"
                class="hinted-hint-nav-button prev hinted-hint-prev"
              >
                {{ $t('pages.styles.settings.modalBackText') }}
              </button>
              <button
                hinted-name="next-button-modal"
                hinted-click="onClick"
                class="hinted-hint-nav-button next hinted-hint-next"
              >
                {{ $t('pages.styles.settings.modalCompleteText') }}
              </button>
            </div>

            <div hinted-name="signature">
              <div v-if="customLogo" class="hinted-hint-bottom-link">
                <img :src="customLogo" class="custom-logo-icon" />
              </div>

              <a
                v-else
                :href="`https://${$options.COMPANY_DOMAIN}/`"
                target="_blank"
                class="hinted-hint-bottom-link"
              >
                <span class="logo-powered">Powered&nbsp;by&nbsp;</span>

                <copyright-logo />
              </a>
            </div>
            <div class="hinted-hint-bubble-number" data-step-counter-modal=""></div>
          </div>
          <button
            hinted-name="close"
            hinted-click="onClick"
            class="hinted-hint-bubble-close hinted-hint-close"
            title="Закрыть"
          ></button>
        </div>
      </div>
    </div>
  </hinted-modal>
</template>

<script>
  import { buildSpecConfig } from 'src/config';

  import CopyrightLogo from '../CopyrightLogo.vue';

  export default {
    name: 'FakeModal',

    components: {
      CopyrightLogo,
    },

    inject: ['orgSettings'],

    COMPANY_DOMAIN: buildSpecConfig.companyDomain,

    computed: {
      customLogo() {
        return this.orgSettings.logoUrl;
      },
    },
  };
</script>

<style lang="scss">
  .fake-modal {
    position: unset !important;
    top: unset !important;
    left: unset !important;
    width: 100% !important;
    height: 100% !important;
    z-index: unset !important;
  }
</style>
