<template>
  <v-card>
    <v-card-text>
      <v-data-table
        :headers="tableHeaders"
        :items="items"
        :options.sync="params"
        :server-items-length="serverItemsCount"
        :loading="isBusy"
      >
        <template #top>
          <v-toolbar flat class="mb-8" height="60">
            <v-container fluid class="px-0">
              <v-row align="start" justify="space-between">
                <v-col cols="4" lg="3">
                  <v-text-field
                    v-model="debouncedPattern"
                    :label="`${$t(`${localeRootPath}.search`)}...`"
                    append-icon="mdi-magnify"
                    clearable
                    hide-details
                    class="mt-2"
                  />
                </v-col>

                <v-col cols="4" class="d-flex justify-end">
                  <v-checkbox
                    v-if="isModeratorOrHigher"
                    v-model="trashedItems"
                    :label="$t('common.showTrashed')"
                    class="mt-2 mr-2"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-toolbar>
        </template>

        <template #item.num="{ index }">
          <TableRowNumber :server-limit="serverLimit" :server-page="serverPage" :index="index" />
        </template>

        <template #item.steps="{item}">
          {{
            item[tooltipsMode ? 'items' : 'steps']
              ? item[tooltipsMode ? 'items' : 'steps'].length
              : 0
          }}
        </template>

        <template #item.createdAt="{value}">
          {{ formatDateTime(value) }}
        </template>

        <template #item.actions="{item}">
          <v-tooltip v-if="trashedItems" bottom>
            <template #activator="{ on, attrs }">
              <v-btn icon small v-bind="attrs" @click="restoreItem(item.id)" v-on="on">
                <v-icon small>
                  mdi-delete-restore
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t(`common.actions.restore`) }}</span>
          </v-tooltip>

          <template v-else-if="item.isEditable">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="info"
                  icon
                  small
                  v-bind="attrs"
                  @click="
                    () => {
                      openEditDialog(item);
                      sendMetrics('desktop_walkthroughs_edit_button', 'click');
                    }
                  "
                  v-on="on"
                >
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t(`common.actions.edit`) }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="error"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    () => {
                      openRemoveDialog(item.id);
                      sendMetrics('desktop_walkthroughs_delete_button', 'click');
                    }
                  "
                >
                  <v-icon small>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t(`common.actions.delete`) }}</span>
            </v-tooltip>

            <v-menu v-if="isAdmin && isEnterprisePlanActive" offset-y left>
              <template #activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon small>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item @click="openWorkspaceMigrationDialog([item])">
                  <v-list-item-icon class="mr-0">
                    <v-icon small>mdi-folder-swap-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('ui.workspaceEntityMigrateModal.buttonText') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="removeDialogOpened" max-width="500">
      <remove-item-modal
        v-if="removeDialogOpened"
        :title="$t(`${localeRootPath}.removeModalTitle`)"
        :text="$t(`${localeRootPath}.removeModalText`)"
        :remove-callback="removeCallback"
        @close="removeDialogOpened = false"
        @removed="onRemoved()"
      />
    </v-dialog>

    <desktop-entity-edit-form
      :editing-entity="editingEntity"
      :tooltips-mode="tooltipsMode"
      @close="clearEditingItem()"
      @updated="onUpdated()"
    />

    <v-dialog v-model="workspaceMigratingDialogOpened" max-width="450px">
      <workspace-entity-migrate-modal
        v-if="workspaceMigratingDialogOpened"
        :type="tooltipsMode ? 'desktopTooltips' : 'desktopScenarios'"
        :list="workspaceMigratingIds"
        @changed="getList()"
        @close="closeWorkspaceModal()"
      />
    </v-dialog>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex';
  import debounce from 'lodash.debounce';

  import api from 'src/api';

  import { busyFlow, deepcopy } from 'src/utils';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  import WorkspaceEntityMigrateModal from 'src/components/WorkspaceEntityMigrateModal/WorkspaceEntityMigrateModal.vue';
  import RemoveItemModal from 'src/components/RemoveItemModal/RemoveItemModal.vue';

  import TableRowNumber from 'src/components/commonComponents/TableRowNumber.vue';
  import DesktopEntityEditForm from './DesktopEntityEditForm.vue';

  const defaultParams = {
    itemsPerPage: 10,
    page: 1,
    pattern: null,
    sortBy: ['createdAt'],
    sortDesc: [true],
  };

  export default {
    name: 'DesktopEntities',

    components: {
      WorkspaceEntityMigrateModal,
      RemoveItemModal,
      DesktopEntityEditForm,
      TableRowNumber,
    },

    mixins: [formatTimestampMixin],

    inject: ['sendMetrics'],

    props: {
      tooltipsMode: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        isBusy: false,
        items: [],
        serverItemsCount: 0,
        params: { ...defaultParams },

        editingEntity: null,

        trashedItems: false,

        workspaceMigratingDialogOpened: false,
        workspaceMigratingIds: [],

        serverPage: 1,
        serverLimit: 1,

        removeDialogOpened: false,
        removeCallback: () => {},
      };
    },

    mappedCollectionIcons: {
      scenarios: 'mdi-message-outline',
    },

    computed: {
      ...mapGetters('versions', ['hasAccessToVersionControl']),
      ...mapGetters('users', ['isModeratorOrHigher', 'isAdmin', 'isEnterprisePlanActive']),

      localeRootPath() {
        return this.tooltipsMode ? 'ui.desktopTooltips' : 'ui.desktopScenarios';
      },

      tableHeaders() {
        return [
          {
            value: 'num',
            text: '#',
            sortable: false,
            align: 'center',
            width: '40px',
          },
          { text: this.$t('pages.ratings.tableHeaders.title'), value: 'title' },
          {
            text: this.$t('ui.desktopScenarios.stepsCount'),
            value: 'steps',
            align: 'center',
            sortable: false,
          },
          {
            text: this.$t('common.fields.created'),
            value: 'createdAt',
            width: '150px',
          },

          {
            text: this.$t('ui.common.actions'),
            value: 'actions',
            width: '120px',
            align: 'center',
            sortable: false,
          },
        ];
      },

      debouncedPattern: {
        get() {
          return this.params.pattern;
        },

        set(value) {
          this.debouncedSetPattern(value);
        },
      },

      mappedParams() {
        const {
          itemsPerPage,
          page = 1,
          pattern,
          sortBy: [filterBy],
          sortDesc: [isDesc],
        } = this.params;

        const limit = itemsPerPage === -1 ? 'all' : itemsPerPage;
        const filterDirection = isDesc ? 'desc' : 'asc';

        return {
          page,
          limit,
          trashedItems: this.trashedItems,
          ...(pattern ? { pattern } : {}),
          ...(filterBy ? { filter_by: filterBy, filter_direction: filterDirection } : {}),
        };
      },
    },

    watch: {
      mappedParams: {
        handler() {
          this.getList();
        },
        immediate: true,
      },
    },

    methods: {
      debouncedSetPattern: debounce(function setPattern(pattern) {
        this.params = {
          ...defaultParams,
          sortBy: this.params.sortBy,
          itemsPerPage: this.params.itemsPerPage,
          sortDesc: this.params.sortDesc,
          pattern,
        };
      }, 300),

      getList() {
        busyFlow.call(this, async () => {
          const { response } = await api[
            this.tooltipsMode ? 'desktopTooltips' : 'desktopScenarios'
          ].list({
            params: { ...this.mappedParams },
          });

          this.serverItemsCount = response.count;
          this.serverLimit = this.mappedParams.limit;
          this.serverPage = this.mappedParams.page;
          this.items = response.payload;
        });
      },

      openEditDialog(item) {
        this.editingEntity = deepcopy(item);
      },

      clearEditingItem() {
        this.editingEntity = null;
      },

      onUpdated() {
        this.clearEditingItem();
        this.getList();
      },

      openRemoveDialog(id) {
        this.removeCallback = () =>
          api[this.tooltipsMode ? 'desktopTooltips' : 'desktopScenarios'].remove({
            url_params: { id },
          });

        this.removeDialogOpened = true;
      },

      onRemoved() {
        if (this.items.length === 1 && this.params.page !== 1) {
          this.params.page -= 1;
        } else {
          this.getList();
        }
      },

      async restoreItem(id) {
        await api[this.tooltipsMode ? 'desktopTooltips' : 'desktopScenarios'].restore({
          url_params: { id },
        });

        if (this.items.length === 1 && this.params.page !== 1) {
          this.params.page -= 1;
        } else {
          this.getList();
        }
      },

      openWorkspaceMigrationDialog(entities) {
        this.workspaceMigratingIds = entities.map(entity => entity.id);
        this.workspaceMigratingDialogOpened = true;
      },

      closeWorkspaceModal() {
        this.workspaceMigratingDialogOpened = false;
        this.workspaceMigratingIds = [];
      },
    },
  };
</script>
