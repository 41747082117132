<template>
  <v-card>
    <v-card-title class="pa-4 primary">
      <span class="ma-1 title white--text">
        {{ $t('pages.scenarios.downloadModal.title') }}
      </span>

      <v-spacer />

      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pt-6">
      <span class="black--text subtitle-1">
        {{ $t('pages.scenarios.downloadModal.text') }}
      </span>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" text data-test-download-triggers-no-button @click="withoutTriggers">
        {{ $t('pages.scenarios.downloadModal.no') }}
      </v-btn>

      <v-btn color="primary" data-test-download-triggers-yes-button @click="withTriggers">
        {{ $t('pages.scenarios.downloadModal.yes') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'TriggersDialog',

    methods: {
      withTriggers() {
        return this.$emit('downloadWithTriggers');
      },

      withoutTriggers() {
        return this.$emit('downloadWithoutTriggers');
      },
    },
  };
</script>
