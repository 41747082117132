<template>
  <div :class="chipClasses">
    {{ theme }}
  </div>
</template>

<script>
  export default {
    name: 'PlanUpgradeChip',

    props: {
      theme: {
        type: String,
        required: false,
        default: 'pro',
      },

      left: {
        type: Boolean,
        required: false,
        default: false,
      },

      right: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    computed: {
      chipClasses() {
        return [
          'plan-upgrade-chip',
          `plan-upgrade-chip--theme-${this.theme}`,
          this.left && 'plan-upgrade-chip--left',
          this.right && 'plan-upgrade-chip--right',
        ];
      },
    },
  };
</script>

<style lang="scss">
  .plan-upgrade-chip {
    display: inline;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-transform: none;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 5px;

    &::first-letter {
      text-transform: uppercase;
    }

    &--theme-pro {
      background: linear-gradient(86.41deg, #7474bf 2.91%, #348ac7 99.13%);
    }

    &--theme-enterprise {
      background: linear-gradient(86.41deg, #43c6ac 2.91%, rgba(22, 73, 84, 0.66) 99.13%);
    }

    &--left {
      margin-right: 10px;
    }

    &--right {
      margin-left: 10px;
    }
  }
</style>
