const list = {
  name: 'list',
  method: 'get',
  url: 'list',
};

const create = {
  name: 'create',
  method: 'post',
  url: '/api/links',
};

const update = {
  name: 'update',
  method: 'patch',
  url: ':id',
};

const remove = {
  name: 'remove',
  method: 'delete',
  url: ':id',
};

const restore = {
  name: 'restore',
  method: 'post',
  url: ':id/restore',
};

const getGroups = {
  name: 'getGroups',
  method: 'get',
  url: ':id/groups',
};

const changeWorkspace = {
  name: 'changeWorkspace',
  method: 'post',
  url: 'change-workspace',
};

export default {
  name: 'links',
  url: '/api/links/',
  children: [list, create, update, remove, restore, getGroups, changeWorkspace],
};
