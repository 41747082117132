<template>
  <v-card class="link-edit-modal">
    <v-form ref="form" @submit.prevent="submit()">
      <v-card-title class="pb-0">
        <span class="headline">
          {{ $t('pages.accounts.accountBlockModal.title', { email: editingAccount.email }) }}
        </span>

        <v-spacer />

        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="blockReason"
                :rules="[validation.getRequiredRule]"
                :label="$t('pages.accounts.accountBlockModal.reason')"
                hide-details="auto"
                outlined
              />
            </v-col>
          </v-row>

          <v-alert v-if="serverError" color="red" type="error" class="mt-5 mb-0">
            {{ serverError }}
          </v-alert>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="primary" text @click="close()">
          {{ $t('ui.common.cancel') }}
        </v-btn>

        <v-btn :disabled="isBusy" :loading="isBusy" color="primary" type="submit">
          {{ $t('pages.accounts.accountBlockModal.blockButton') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import api from 'src/api';

  export default {
    name: 'AccountChangePasswordModal',

    props: {
      editingAccount: {
        type: Object,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        blockReason: '',

        isBusy: false,

        serverError: null,
        validation: {
          getRequiredRule: v => (!!v && !!v.trim()) || this.$t('validation.notNull'),
        },
      };
    },

    methods: {
      async submit() {
        if (this.isBusy || !this.$refs.form.validate()) {
          return;
        }

        this.serverError = null;

        const payload = {
          reason: this.blockReason,
          isPermanent: true,
          isHack: false,
        };

        try {
          await api.users.block({
            url_params: { id: this.editingAccount.id },
            data: payload,
          });

          this.$emit('blocked');
          this.close();
        } catch (error) {
          this.serverError = this.$te(`serverCode.${error.code}`)
            ? this.$t(`serverCode.${error.code}`)
            : this.$t('serverCode.serverError');
        } finally {
          this.isBusy = false;
        }
      },

      close() {
        this.$emit('close');
      },
    },
  };
</script>
