import api from 'src/api';
import { apiUrl } from 'src/config';

const actions = {
  async uploadUpdate(context, { file, onProgress = () => {} }) {
    const form = new FormData();
    form.append('file', file);

    const payload = {
      options: {
        // there is hack to correctly send formdata with file via axios.
        // Cause: if Content-Type header was not set it provide application/json.
        //        If Content-Type was set it not provide boundary length.
        transformRequest: [
          (data, headers) => {
            // eslint-disable-next-line no-param-reassign
            delete headers.common;
            return form;
          },
        ],
        maxBodyLength: 500000,
        onUploadProgress: onProgress,
      },
    };

    const { response } = await api.upload.update(payload);

    if (response.status !== 'updating') {
      throw new Error('Can not upload');
    }

    return response;
  },

  async uploadFile(context, file) {
    const form = new FormData();
    form.append('file', file);

    const payload = {
      options: {
        // there is hack to correctly send formdata with file via axios.
        // Cause: if Content-Type header was not set it provide application/json.
        //        If Content-Type was set it not provide boundary length.
        transformRequest: [
          (data, headers) => {
            // eslint-disable-next-line no-param-reassign
            delete headers.common;
            return form;
          },
        ],
        maxBodyLength: 15000,
      },
    };

    const { response } = await api.upload.uploadFile(payload);

    if (!response) {
      throw new Error('Can not upload');
    }

    const { relativeUrl } = response;

    const url = new URL(relativeUrl, apiUrl);
    return url.href;
  },

  async uploadByUrl(context, url) {
    const data = { url };

    const { response } = await api.upload.uploadByUrl({ data });

    if (!response) {
      throw new Error('Can not upload');
    }

    const { relativeUrl } = response;

    const urlInstance = new URL(relativeUrl, apiUrl);
    return urlInstance.href;
  },
};

export default {
  namespaced: true,
  actions,
};
