<template>
  <div>
    <div>
      <font-select v-model="fontFamily" />
    </div>

    <div>
      <div class="block-header">
        {{ $t('pages.styles.settings.firstTimeWindow') }}
      </div>

      <v-switch
        v-model="isWelcomeMessageEnabled"
        :label="$t('pages.styles.settings.showWelcomeMessage')"
        hide-details
      />

      <template v-if="isWelcomeMessageEnabled">
        <v-alert text type="info" max-width="500" class="my-4">
          <template #prepend>
            <v-icon color="info" class="mr-4">
              mdi-alert-circle-outline
            </v-icon>
          </template>

          {{ $t('pages.styles.settings.welcomeMessageNotice') }}
        </v-alert>

        <div class="d-flex align-center mb-3">
          <InlineColorPicker :color.sync="firstTimeBackgroundColor" />
          <div class="ml-4">{{ $t('pages.styles.settings.backgroundColor') }}</div>
        </div>

        <div class="d-flex align-center mb-3">
          <InlineColorPicker :color.sync="firstTimeColor" />
          <div class="ml-4">{{ $t('pages.styles.settings.fontColor') }}</div>
        </div>

        <div class="d-flex align-center mb-4">
          <v-text-field
            v-model="firstTimeHeader"
            outlined
            hide-details
            dense
            :placeholder="$t('pages.styles.settings.firstTimeHeaderPlaceholder')"
            :label="$t('pages.styles.settings.firstTimeHeader')"
            maxlength="25"
          />
        </div>

        <div class="d-flex align-center mb-4">
          <v-textarea
            v-model="firstTimeText"
            outlined
            hide-details
            dense
            :placeholder="$t('pages.styles.settings.firstTimeText')"
            :label="$t('pages.styles.settings.firstTimeText')"
            rows="3"
          />
        </div>
      </template>
    </div>

    <div>
      <div class="block-header">
        {{ $t('pages.styles.settings.supportCenterButtonHeader') }}
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="buttonBackgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.backgroundColor') }}</div>
      </div>

      <div v-if="themeType !== $options.THEME_TYPE_CLASSIC" class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="buttonHoverBackgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.hoverBackgroundColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="buttonColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.fontColor') }}</div>
      </div>

      <div class="mb-2">
        <v-switch
          v-model="isButtonShadowEnabled"
          :label="$t('pages.styles.settings.shadow')"
          hide-details
        />

        <template v-if="isButtonShadowEnabled">
          <div class="d-flex align-center mb-3">
            <InlineColorPicker :color.sync="buttonShadowColor" />
            <div class="ml-4">{{ $t('pages.styles.settings.shadowColor') }}</div>
          </div>

          <div class="input-control mb-3">
            <div>{{ $t('pages.styles.settings.shadowXOffset') }}</div>

            <h-slider v-model="buttonShadowXOffset" max="50" min="-50" hide-details>
              <template #append>
                <v-text-field
                  v-model="buttonShadowXOffset"
                  hide-details
                  dense
                  disabled
                  class="mt-0 pt-0 slider-input"
                />
              </template>
            </h-slider>
          </div>

          <div class="input-control mb-3">
            <div>{{ $t('pages.styles.settings.shadowYOffset') }}</div>

            <h-slider v-model="buttonShadowYOffset" max="50" min="-50" hide-details>
              <template #append>
                <v-text-field
                  v-model="buttonShadowYOffset"
                  hide-details
                  dense
                  disabled
                  class="mt-0 pt-0 slider-input"
                />
              </template>
            </h-slider>
          </div>

          <div class="input-control mb-3">
            <div>{{ $t('pages.styles.settings.shadowBlur') }}</div>

            <h-slider v-model="buttonShadowBlurRadius" max="50" min="0" hide-details>
              <template #append>
                <v-text-field
                  v-model="buttonShadowBlurRadius"
                  hide-details
                  dense
                  disabled
                  class="mt-0 pt-0 slider-input"
                />
              </template>
            </h-slider>
          </div>

          <div class="input-control mb-3">
            <div>{{ $t('pages.styles.settings.shadowSpreadRadius') }}</div>

            <h-slider v-model="buttonShadowSpreadRadius" max="50" min="0" hide-details>
              <template #append>
                <v-text-field
                  v-model="buttonShadowSpreadRadius"
                  hide-details
                  dense
                  disabled
                  class="mt-0 pt-0 slider-input"
                />
              </template>
            </h-slider>
          </div>
        </template>
      </div>
    </div>

    <div>
      <div class="block-header">
        {{ $t('pages.styles.settings.supportCenter') }}
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="backgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.backgroundColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="itemColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.fontColor') }}</div>
      </div>

      <div v-if="themeType !== $options.THEME_TYPE_CLASSIC" class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="itemHoverBackgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.itemHoverBackgroundColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="itemHoverColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.itemHoverColor') }}</div>
      </div>

      <template v-if="themeType !== $options.THEME_TYPE_CLASSIC">
        <div class="d-flex align-center mb-3">
          <InlineColorPicker :color.sync="headerBackgroundColor" />
          <div class="ml-4">{{ $t('pages.styles.settings.supportCenterBackgroundColor') }}</div>
        </div>

        <div class="d-flex align-center mb-3">
          <InlineColorPicker :color.sync="headerColor" />
          <div class="ml-4">{{ $t('pages.styles.settings.supportCenterColor') }}</div>
        </div>
      </template>

      <div v-if="themeType !== $options.THEME_TYPE_CLASSIC" class="d-flex align-center mb-4">
        <v-text-field
          v-model="listHeader"
          outlined
          hide-details
          dense
          :placeholder="$t('pages.styles.settings.supportCenterHeaderPlaceholder')"
          :label="$t('pages.styles.settings.supportCenterHeaderLabel')"
          maxlength="64"
        />
      </div>

      <div class="d-flex align-center mb-4">
        <v-textarea
          v-model="listEmptyText"
          outlined
          hide-details
          dense
          :placeholder="$t('pages.styles.settings.supportCenterEmptyPlaceholder')"
          :label="$t('pages.styles.settings.supportCenterEmptyLabel')"
          rows="3"
        />
      </div>

      <div v-if="isEnterprisePlanActive" class="mb-2">
        <v-switch
          v-model="copyrightHidden"
          :label="$t('pages.styles.settings.copyrightHidden')"
          hide-details
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import InlineColorPicker from 'src/components/InlineColorPicker.vue';
  import HSlider from 'src/HSlider/HSlider.vue';

  import stylesMixin from '../../mixins/stylesMixin';

  import { generateDynamicFields, THEME_TYPE_CLASSIC } from '../../utils';

  import FontSelect from '../FontSelect.vue';

  export default {
    name: 'SupportCenterAdvancedStyles',

    components: {
      InlineColorPicker,
      HSlider,
      FontSelect,
    },

    mixins: [stylesMixin],

    THEME_TYPE_CLASSIC,

    computed: {
      ...mapGetters('users', ['isEnterprisePlanActive']),

      ...generateDynamicFields([
        'fontFamily',
        'firstTimeBackgroundColor',
        'firstTimeColor',
        'buttonBackgroundColor',
        'buttonHoverBackgroundColor',
        'buttonColor',
        'isButtonShadowEnabled',
        'buttonShadowXOffset',
        'buttonShadowYOffset',
        'buttonShadowColor',
        'buttonShadowBlurRadius',
        'buttonShadowSpreadRadius',
        'backgroundColor',
        'itemHoverColor',
        'itemColor',
        'itemHoverBackgroundColor',
        'headerColor',
        'headerBackgroundColor',
      ]),

      ...generateDynamicFields(
        [
          'firstTimeHeader',
          'firstTimeText',
          'listHeader',
          'listEmptyText',
          'copyrightHidden',
          'isWelcomeMessageEnabled',
        ],
        'updateSettings',
        'settings'
      ),

      padding: {
        get() {
          const { paddingLeft, paddingRight, paddingTop, paddingBottom } = this.value;

          if (
            paddingLeft !== paddingRight ||
            paddingLeft !== paddingTop ||
            paddingLeft !== paddingBottom
          ) {
            return 0;
          }

          return paddingLeft;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingLeft: value,
            paddingRight: value,
            paddingTop: value,
            paddingBottom: value,
          });
        },
      },

      horizontalPadding: {
        get() {
          const { paddingLeft, paddingRight } = this.value;

          if (paddingLeft !== paddingRight) {
            return 0;
          }

          return paddingLeft;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingLeft: value,
            paddingRight: value,
          });
        },
      },

      verticalPadding: {
        get() {
          const { paddingTop, paddingBottom } = this.value;

          if (paddingTop !== paddingBottom) {
            return 0;
          }

          return paddingTop;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingTop: value,
            paddingBottom: value,
          });
        },
      },
    },

    watch: {
      type(value) {
        if (value === 'default') {
          this.position = undefined;
        } else {
          this.position = 'element';
        }
      },
    },
  };
</script>

<style lang="scss"></style>
