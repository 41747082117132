/* eslint-disable no-shadow,no-param-reassign,no-unused-vars */
import api from 'src/api';
import pick from 'lodash.pick';
import { deepcopy } from 'src/utils';

import {
  ORGANIZATIONS_LIST,
  ORGANIZATIONS_QUERY_LIST,
  ORGANIZATIONS_QUERY_COUNT,
} from 'src/store/mutation-types';

const state = {
  organizationsList: [],
  organizationsQueryList: [],
  organizationsQueryCount: 0,
};

const actions = {
  async getOrganization(context, id) {
    const { response } = await api.organizations.getOne({ url_params: { id } });

    return response;
  },

  async getOrgSettings(context, { id }) {
    const { response } = await api.organizations.getSettings({ url_params: { id } });

    return response;
  },

  async list({ commit }) {
    const params = {};
    const { response } = await api.organizations.list({ params });

    if (response) {
      commit(ORGANIZATIONS_LIST, response);
    }
  },

  async getListWithParams(
    { commit },
    { page, limit, filterBy, filterDirection, pattern, trashedItems } = {}
  ) {
    const params = deepcopy({
      page,
      limit,
      filter_by: filterBy,
      filter_direction: filterDirection,
      pattern,
      trashedItems,
    });

    const { response } = await api.organizations.listWithParams({ params });

    commit(ORGANIZATIONS_QUERY_LIST, response.payload);
    commit(ORGANIZATIONS_QUERY_COUNT, response.count);
  },

  async create(context, data) {
    const { response } = await api.organizations.create({ data });

    return response;
  },

  async update(context, payload) {
    const data = pick(payload, ['site', 'name', 'shortname', 'isIntegrator', 'integratorId']);

    const { response } = await api.organizations.update({
      url_params: { id: payload.id },
      data,
    });

    return response;
  },

  async updateInfo(context, { id, payload }) {
    const { response } = await api.organizations.updateInfo({
      url_params: { id },
      data: payload,
    });

    return response;
  },

  async updateOrgOwner(context, { id, payload }) {
    const { response } = await api.organizations.updateOwner({
      url_params: { id },
      data: payload,
    });

    return response;
  },

  async updateOrgSettings(context, { payload }) {
    await api.organizations.updateSettings({
      data: payload,
    });
  },

  checkDirty({ dispatch, state }) {
    // stupid diff
    if (state.actualSavingState === 'dirty') {
      return;
    }

    dispatch('setSavingState', 'dirty');
  },
};

const mutations = {
  [ORGANIZATIONS_LIST](state, payload) {
    state.organizationsList = payload;
  },

  [ORGANIZATIONS_QUERY_LIST](state, payload) {
    state.organizationsQueryList = payload;
  },

  [ORGANIZATIONS_QUERY_COUNT](state, payload) {
    state.organizationsQueryCount = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
