<template>
  <div>
    <v-dialog v-model="dialogState" max-width="700px">
      <v-card>
        <v-card-title>
          {{ $t('pages.statistics.downloadStatistics') }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <v-progress-linear indeterminate :active="isBusy" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto">
              <base-date-select-menu
                field-title="startDate"
                :default-date-value="startDate"
                :max-date="endDate"
                :label="$t('pages.statistics.startDate')"
                @change="changeStartDate"
              />
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <base-date-select-menu
                field-title="endDate"
                :default-date-value="endDate"
                :max-date="endDate"
                :label="$t('pages.statistics.endDate')"
                @change="changeEndDate"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-spacer />

            <v-col cols="auto">
              <v-btn :disabled="isBusy" dark color="primary" small @click="downloadStatisticsFile">
                {{ $t('pages.statistics.downloadBySteps') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import moment from 'moment';
  import BaseDateSelectMenu from 'src/components/commonComponents/BaseDateSelectMenu.vue';

  export default {
    name: 'DownloadStatistics',

    components: {
      BaseDateSelectMenu,
    },

    inject: ['sendMetrics'],

    model: {
      prop: 'isDialogOpened',
      event: 'change',
    },

    props: {
      load: {
        type: Function,
        required: true,
        default: async () => {},
      },

      filename: {
        type: String,
        required: false,
        default: 'stats.xlsx',
      },

      datesPeriod: {
        type: Object,
        required: false,
        default: () => ({
          unit: 'w',
          value: 2,
        }),
      },

      isDialogOpened: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        downloadDialogOpened: false,
        isBusy: false,
        startDate: moment()
          .subtract(this.datesPeriod.value, this.datesPeriod.unit)
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      };
    },

    computed: {
      dialogState: {
        get() {
          return this.isDialogOpened;
        },

        set(value) {
          if (value) {
            this.sendMetrics('statistics_download_button_clicked', 'click');
          }

          this.$emit('change', value);
        },
      },
    },

    methods: {
      changeEndDate(value) {
        this.endDate = value;
      },

      changeStartDate(value) {
        this.startDate = value;
      },

      async downloadStatisticsFile() {
        if (this.isBusy) {
          return;
        }

        this.isBusy = true;

        try {
          const from = moment(this.startDate)
            .startOf('day')
            .valueOf();

          const to = moment(this.endDate)
            .endOf('day')
            .valueOf();

          const response = await this.load({
            from,
            to,
          });

          const fileURL = window.URL.createObjectURL(new Blob([response]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', this.filename);
          document.body.appendChild(fileLink);

          fileLink.click();

          this.downloadDialogOpened = false;
        } catch (err) {
          console.error('download error >>>', err);
        } finally {
          this.isBusy = false;
        }
      },
    },
  };
</script>
