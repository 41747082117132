const auth = {
  name: 'auth',
  method: 'post',
  url: 'oauth/authorize',
};

const authTransition = {
  name: 'authTransition',
  method: 'post',
  url: 'oauth/transition/authorize',
};

const integrated = {
  name: 'integrated',
  method: 'post',
  url: 'oauth/integrated',
};

const refresh = {
  name: 'refresh',
  method: 'post',
  url: 'oauth/refresh',
};

const logout = {
  name: 'logout',
  method: 'delete',
  url: 'oauth/logout',
};

const register = {
  name: 'register',
  method: 'post',
  url: 'users/register',
};

const signTransitionToken = {
  name: 'signTransitionToken',
  method: 'post',
  url: 'oauth/transition',
};

export default {
  name: 'auth',
  url: '/api/',
  children: [auth, integrated, refresh, logout, register, authTransition, signTransitionToken],
};
