<template>
  <v-container fluid class-name="down-top-padding">
    <v-row class-name="pb-0">
      <v-col cols="12" class="pt-0">
        <v-container>
          <v-card flat>
            <v-card-text class="d-flex pa-5">
              <v-row align="center">
                <v-col cols="auto">
                  <h3 class="title font-weight-regular">
                    <span class="lstick" />
                    {{ $t('pages.statistics.statistics') }}:
                  </h3>
                </v-col>

                <v-col cols="3" md="2" xl="3">
                  <v-menu
                    ref="startDate"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        :label="$t('pages.statistics.startDate')"
                        prepend-icon="mdi-calendar"
                        hide-details
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="startDate"
                      :min="minDate"
                      :max="maxDate"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn text color="primary" @click="startDateMenu = false">
                        {{ $t('ui.common.cancel') }}
                      </v-btn>

                      <v-btn text color="primary" @click="$refs.startDate.save(startDate)">
                        {{ $t('ui.common.ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="3" md="2" xl="3">
                  <v-menu
                    ref="endDate"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="endDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        :label="$t('pages.statistics.endDate')"
                        prepend-icon="mdi-calendar"
                        readonly
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="endDate"
                      :min="startDate || minDate"
                      :max="maxDate"
                      no-title
                      scrollable
                    >
                      <v-spacer />

                      <v-btn text color="primary" @click="endDateMenu = false">
                        {{ $t('ui.common.cancel') }}
                      </v-btn>

                      <v-btn text color="primary" @click="$refs.endDate.save(endDate)">
                        {{ $t('ui.common.ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-spacer />

                <v-col cols="auto">
                  <v-menu>
                    <template #activator="{ props, on }">
                      <v-btn dark color="primary" small v-bind="props" v-on="on">
                        {{ $t('pages.statistics.downloadStatistics') }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="item in downloadItems"
                        :key="item.value"
                        :value="item.value"
                        @click="downloadFile(item.value)"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>

      <v-col cols="12">
        <stat-cards :cards="cards" :stats="cardsData" />
      </v-col>

      <v-col cols="12">
        <v-container>
          <help-center-chart :chart-data="helpCenterChartData" />
        </v-container>
      </v-col>

      <v-col cols="12">
        <v-container>
          <help-center-hidden-scenarios-chart :chart-data="helpCenterScenarioVisibilityChartData" />
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import moment from 'moment';
  import { saveAs } from 'file-saver';

  import HelpCenterChart from 'src/components/Statistics/HelpCenterChart.vue';
  import HelpCenterHiddenScenariosChart from '@/components/Statistics/HelpCenterHiddenScenariosChart.vue';
  import StatCards from 'src/components/Statistics/StatCards.vue';

  import externalLinkIcon from 'src/assets/external-link.svg';
  import moreExternalLinkIcon from 'src/assets/more-external-link.svg';
  import api from 'src/api';
  import { getLocationForQueries, prepareCsv } from 'src/utils';

  const defaultParams = {
    itemsPerPage: 10,
    page: 1,
  };

  export default {
    name: 'HelpCenterStats',
    components: { HelpCenterChart, StatCards, HelpCenterHiddenScenariosChart },
    data() {
      return {
        maxDate: null,
        minDate: '2018-01-01',
        startDate: null,
        startDateMenu: false,
        endDate: null,
        endDateMenu: false,
        filter: { ...defaultParams },
        datesFilter: {},

        isBusy: false,
        downloadItems: [
          {
            title: this.$t('pages.statistics.helpCenterStats'),
            value: 'helpCenterFile',
          },

          {
            title: this.$t('pages.statistics.helpCenterHiddenScenariosStats'),
            value: 'scenariosHiddenFile',
          },
        ],
      };
    },

    computed: {
      ...mapState('stats', [
        'helpCenterChartData',
        'helpCenterCardsData',
        'helpCenterScenarioVisibilityChartData',
        'helpCenterScenarioVisibilityCardData',
      ]),
      ...mapState('users', ['currentUser']),

      cards() {
        return {
          total: {
            title: this.$t('pages.statistics.cards.totalOpened'),
            svg: moreExternalLinkIcon,
            color: 'primary',
          },

          uniq: {
            title: this.$t('pages.statistics.cards.uniqOpened'),
            svg: externalLinkIcon,
            color: 'info',
          },

          scenariosHidden: {
            title: this.$t('pages.statistics.cards.scenariosHidden'),
            feather: 'eye-off',
            color: 'grey',
          },
        };
      },

      cardsData() {
        return {
          ...this.helpCenterCardsData,
          scenariosHidden: this.helpCenterScenarioVisibilityCardData,
        };
      },

      currentOrganizationId() {
        return this.currentUser.clientId || this.currentUser.organizationId;
      },
    },

    watch: {
      startDate(value, prevValue) {
        if (!prevValue) {
          return;
        }

        const from = moment(value)
          .startOf('day')
          .valueOf();
        const to = moment(this.endDate)
          .endOf('day')
          .valueOf();

        this.datesFilter = { from, to };
        this.getChartData();
      },

      endDate(value, prevValue) {
        if (!prevValue) {
          return;
        }

        const from = moment(this.startDate)
          .startOf('day')
          .valueOf();
        const to = moment(value)
          .endOf('day')
          .valueOf();

        this.datesFilter = { from, to };
        this.getChartData();
      },
    },

    mounted() {
      this.endDate = moment().format('YYYY-MM-DD');
      this.maxDate = moment().format('YYYY-MM-DD');
      this.startDate = moment()
        .subtract(2, 'weeks')
        .format('YYYY-MM-DD');

      const from = moment(this.startDate)
        .startOf('day')
        .valueOf();
      const to = moment()
        .endOf('day')
        .valueOf();

      this.datesFilter = { from, to };

      this.getChartData();
    },

    methods: {
      ...mapActions('stats', ['getHelpCenterStatsChart', 'getHelpCenterScenarioVisibilityChart']),

      async getChartData() {
        this.isBusy = true;
        const chartParams = {
          organizationId: this.currentOrganizationId,
          ...this.datesFilter,
        };

        try {
          await Promise.all([
            this.getHelpCenterScenarioVisibilityChart(chartParams),
            this.getHelpCenterStatsChart(chartParams),
          ]);
        } catch (err) {
          console.error(err);
        } finally {
          this.isBusy = false;
        }
      },

      async downloadFile(type) {
        if (this.isBusy) {
          return;
        }

        this.isBusy = true;

        try {
          const from = moment(this.startDate)
            .startOf('day')
            .valueOf();
          const to = moment(this.endDate)
            .endOf('day')
            .valueOf();

          if (type === 'helpCenterFile') {
            await this.saveHelpCenterFile(from, to);
          } else {
            await this.saveHiddenStatsFile(from, to);
          }
        } finally {
          this.isBusy = false;
        }
      },

      async saveHelpCenterFile(from, to) {
        const { response } = await api.v2statistics.getOrganizationHelpCenterFile({
          url_params: { organizationId: this.currentOrganizationId },
          params: { from, to, lang: getLocationForQueries() },
        });

        const blob = await prepareCsv(response);
        saveAs(blob, `helpCenterStats.csv`);
      },

      async saveHiddenStatsFile(from, to) {
        const { response } = await api.v2statistics.getOrganizationScenarioVisibilityFile({
          url_params: { organizationId: this.currentOrganizationId },
          params: { from, to, lang: getLocationForQueries() },
        });

        const blob = await prepareCsv(response);
        saveAs(blob, `scenariosHiddenStats.csv`);
      },
    },
  };
</script>
