<script>
  import { mapActions, mapState } from 'vuex';

  import api from 'src/api';

  import BaseAddingForm from './BaseAddingForm.vue';

  export default {
    name: 'GroupLinksAddingForm',

    extends: BaseAddingForm,

    computed: {
      ...mapState('groups', ['groupLinks', 'groupLinksCount']),

      title() {
        return this.$t('pages.groups.addingModal.title.links');
      },

      allListTitle() {
        return this.$t('pages.groups.addingModal.all.links');
      },

      addedListTitle() {
        return this.$t('pages.groups.addingModal.inGroup.links');
      },

      allItems() {
        return this.allList;
      },

      allItemsCount() {
        return this.allCount;
      },

      addedItems() {
        return this.groupLinks;
      },

      addedItemsCount() {
        return this.groupLinksCount;
      },
    },

    methods: {
      ...mapActions('groups', ['getLinks', 'clearLinks', 'addLinks', 'removeLinks']),

      itemInEntity(item) {
        return item.isInGroup;
      },

      mapItem(item) {
        return {
          ...item,
          title: item.title?.trim() || '---',
          subtitle: item.url || '',
        };
      },

      getAddedListMethod() {
        const params = this.currentAddedListParams;

        const queryParams = {
          type: this.mode,
          limit: params.limit,
          page: params.page,
          groupId: this.entity.id,
          ...(params.pattern ? { pattern: params.pattern } : {}),
        };

        return this.getLinks(queryParams);
      },

      async getAllListMethod() {
        const { limit, page, pattern } = this.currentAllListParams;
        const groupId = this.entity.id;

        const { response } = await api.links.list({
          params: { limit, page, group_id: groupId, ...(pattern ? { pattern } : {}) },
        });

        this.allList = response.payload;
        this.allCount = response.count;
      },

      removeFromEntityMethod(link) {
        return this.removeLinks({
          groupId: this.entity.id,
          links: [link.id],
        });
      },

      addToEntityMethod(link) {
        return this.addLinks({
          groupId: this.entity.id,
          links: [link.id],
        });
      },

      clearListsMethod() {
        return this.clearLinks();
      },
    },
  };
</script>
