<template>
  <vue-apex-charts :height="height" :series="chartData" :options="chartOptions" />
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';

  export default {
    name: 'BaseHorizontalChartBar',

    components: {
      VueApexCharts,
    },

    props: {
      barHeight: { type: Number, default: 30 },
      barGap: { type: Number, default: 15 },
      chartData: { type: Array, default: () => [] },
      categories: { type: Array, default: () => [] },
      colors: { type: Array, default: () => ['#abceff', '#398bf7', '#7460ee', '#9c94ee'] },
      overrideChartOptions: { type: Object, default: () => ({}) },
      tooltipFormatter: { type: Function, default: val => val },
      barTotalLabelFormatter: {
        type: Function,
        default: (series = [], idx) =>
          series.reduce((totalRes, { data }) => totalRes + Number(data[idx]), 0),
      },
    },

    data() {
      return {
        chartOptions: Object.freeze({
          chart: {
            type: 'bar',
            stacked: true,
            fontFamily: 'Montserrat,sans-serif',
            toolbar: {
              show: false,
            },
          },

          xaxis: {
            categories: this.categories,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },

          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },

          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },

          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '50%',
              dataLabels: {
                position: 'top',
              },
            },
          },

          dataLabels: {
            enabled: true,
            enabledOnSeries: [3],
            offsetX: 40,
            formatter: (
              _,
              {
                dataPointIndex: idx,
                w: {
                  config: { series },
                },
              }
            ) => this.barTotalLabelFormatter(series, idx),
            style: {
              colors: ['#343349'],
              fontWeight: 400,
            },
          },

          colors: this.colors,

          fill: {
            opacity: 1,
          },

          legend: {
            show: false,
          },

          tooltip: {
            theme: 'dark',
            shared: true,
            intersect: false,
            fillColors: this.colors,
            y: {
              formatter: (
                val,
                {
                  dataPointIndex: idx,
                  w: {
                    config: { series },
                  },
                }
              ) => this.tooltipFormatter(val, series, idx),
            },
            x: {},
          },

          ...this.overrideChartOptions,
        }),
      };
    },

    computed: {
      height() {
        return Math.max(100, this.categories.length * (this.barHeight / 0.8 + this.barGap * 2));
      },
    },
  };
</script>
