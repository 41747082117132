<template>
  <v-container>
    <accounts-filter
      :organization-id="organizationId"
      :pattern="pattern"
      @update:pattern="onUpdatePattern($event)"
      @update:organizationId="onUpdateOrganizationId($event)"
    />

    <v-card class="mt-8">
      <v-card-text>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="params"
          :server-items-length="serverItemsCount"
          :loading="isBusy"
        >
          <template #no-data>
            <travolta-not-found />
          </template>

          <template #top>
            <v-toolbar flat>
              <v-toolbar-title class="font-weight-medium">
                {{ $t('pages.accounts.title') }}
              </v-toolbar-title>

              <v-divider inset vertical class="mx-4" />

              <v-spacer />

              <v-btn color="primary" dark class="mb-2" @click="openManageModal()">
                {{ $t('pages.accounts.createButton') }}

                <v-icon right>mdi-plus</v-icon>
              </v-btn>
            </v-toolbar>
          </template>

          <template #item.name="{item}">
            {{ getFullName(item) }}
          </template>

          <template #item.status="{ item }">
            <v-row justify="center">
              <v-tooltip v-if="item.verified" bottom>
                <template #activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-btn>
                </template>

                <span>{{ $t('pages.accounts.emailApproved') }}</span>
              </v-tooltip>

              <v-tooltip v-else bottom>
                <template #activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on">
                    <v-icon color="gray">mdi-close</v-icon>
                  </v-btn>
                </template>

                <span>{{ $t('pages.accounts.emailNotApproved') }}</span>
              </v-tooltip>

              <v-tooltip v-if="item.blocked" bottom>
                <template #activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on">
                    <v-icon color="error">mdi-lock</v-icon>
                  </v-btn>
                </template>

                <span>
                  {{ $t('pages.accounts.blockedStatus', { reason: getBlockReason(item) }) }}
                </span>
              </v-tooltip>
            </v-row>
          </template>

          <template #item.action="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="info"
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openManageModal(item)"
                >
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>

              <span>{{ $t(`common.actions.edit`) }}</span>
            </v-tooltip>

            <v-menu offset-y left>
              <template #activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon small>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item @click="openChangePasswordModal(item)">
                  <v-list-item-icon class="mr-0">
                    <v-icon small>mdi-form-textbox-password</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('pages.accounts.changePassword') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="!item.blocked" @click="openBlockAccountModal(item)">
                  <v-list-item-icon class="mr-0">
                    <v-icon small>mdi-account-lock</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('pages.accounts.blockButton') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-else @click="unblockAccount(item)">
                  <v-list-item-icon class="mr-0">
                    <v-icon small>mdi-account-lock-open</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('pages.accounts.unblockButton') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="openRemoveAccountModal(item)">
                  <v-list-item-icon class="mr-0">
                    <v-icon small color="error">mdi-delete</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title> {{ $t(`common.actions.delete`) }} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="manageModalOpened" max-width="764">
      <account-manage-modal
        v-if="manageModalOpened"
        :editing-account="editingItem"
        @create="findAccounts()"
        @update="findAccounts()"
        @close="closeManageModalHandler()"
      />
    </v-dialog>

    <v-dialog v-model="changePasswordModalOpened" max-width="764">
      <account-change-password-modal
        v-if="changePasswordModalOpened"
        :editing-account="editingItem"
        @update="findAccounts()"
        @close="closeChangePasswordModalHandler()"
      />
    </v-dialog>

    <v-dialog v-model="removeAccountModalOpened" max-width="764">
      <account-remove-modal
        v-if="removeAccountModalOpened"
        :editing-account="editingItem"
        @removed="findAccounts()"
        @close="closeRemoveAccountModalHandler()"
      />
    </v-dialog>

    <v-dialog v-model="blockAccountModalOpened" max-width="764">
      <account-block-modal
        v-if="blockAccountModalOpened"
        :editing-account="editingItem"
        @blocked="findAccounts()"
        @close="closeBlockAccountModalHandler()"
      />
    </v-dialog>
  </v-container>
</template>

<script>
  import TravoltaNotFound from 'src/components/TravoltaNotFound.vue';

  import api from 'src/api';

  import { busyFlow } from 'src/utils';

  import AccountsFilter from './components/AccountsFilter.vue';
  import AccountManageModal from './components/AccountManageModal.vue';
  import AccountChangePasswordModal from './components/AccountChangePasswordModal.vue';
  import AccountRemoveModal from './components/AccountRemoveModal.vue';
  import AccountBlockModal from './components/AccountBlockModal.vue';

  const defaultParams = {
    itemsPerPage: 10,
    page: 1,
    pattern: null,
    organizationId: null,
    sortBy: ['createdAt'],
    sortDesc: [true],
  };

  export default {
    name: 'Accounts',

    components: {
      AccountsFilter,
      AccountManageModal,
      AccountChangePasswordModal,
      AccountRemoveModal,
      AccountBlockModal,

      TravoltaNotFound,
    },

    data() {
      return {
        pattern: null,
        organizationId: null,

        isBusy: false,

        items: [],
        serverItemsCount: 0,
        params: { ...defaultParams },

        tableHeaders: Object.freeze([
          { text: 'Email', value: 'email' },
          { text: this.$t('pages.accounts.tableHeaders.name'), value: 'name', sortable: false },
          {
            text: this.$t('pages.accounts.tableHeaders.status'),
            value: 'status',
            sortable: false,
            align: 'center',
            width: 100,
          },
          {
            text: this.$t('ui.common.actions'),
            value: 'action',
            sortable: false,
            align: 'end',
            width: 100,
          },
        ]),

        manageModalOpened: false,
        changePasswordModalOpened: false,
        removeAccountModalOpened: false,
        blockAccountModalOpened: false,
        editingItem: null,
      };
    },

    computed: {
      mappedParams() {
        const {
          itemsPerPage,
          page = 1,
          sortBy: [filterBy],
          sortDesc: [isDesc],
          pattern,
          organizationId,
        } = this.params;

        const limit = itemsPerPage === -1 ? 'all' : itemsPerPage;
        const filterDirection = isDesc ? 'desc' : 'asc';

        return {
          page,
          limit,
          ...(organizationId ? { organizationId } : {}),
          ...(pattern ? { pattern } : {}),
          ...(filterBy ? { filter_by: filterBy, filter_direction: filterDirection } : {}),
        };
      },
    },

    watch: {
      mappedParams: {
        handler() {
          this.findAccounts();
        },
        immediate: true,
      },
    },

    methods: {
      getFullName(item) {
        const name = `${item.firstname || ''} ${item.lastname || ''}`.trim();

        return name || '—';
      },

      getBlockReason(item) {
        if (!item.blocked) {
          return null;
        }

        if (item.isHack) {
          return this.$t('pages.users.editModal.hackInfo');
        }

        return item.blockReason;
      },

      findAccounts() {
        busyFlow.call(this, async () => {
          const { response } = await api.accounts.list({
            params: this.mappedParams,
          });

          this.items = Object.freeze(response.payload);
          this.serverItemsCount = response.count;
        });
      },

      async unblockAccount(item) {
        await api.users.unblock({ url_params: { id: item.id } });

        this.findAccounts();
      },

      onUpdatePattern(pattern) {
        this.params = {
          ...this.params,
          page: 1,
          pattern,
        };
      },

      onUpdateOrganizationId(organizationId) {
        this.params = {
          ...this.params,
          page: 1,
          organizationId,
        };
      },

      openManageModal(editingItem = null) {
        this.editingItem = editingItem;
        this.manageModalOpened = true;
      },

      openChangePasswordModal(editingItem) {
        this.editingItem = editingItem;
        this.changePasswordModalOpened = true;
      },

      openRemoveAccountModal(editingItem) {
        this.editingItem = editingItem;
        this.removeAccountModalOpened = true;
      },

      openBlockAccountModal(editingItem) {
        this.editingItem = editingItem;
        this.blockAccountModalOpened = true;
      },

      closeManageModalHandler() {
        this.manageModalOpened = false;
        this.editingItem = null;
      },

      closeChangePasswordModalHandler() {
        this.changePasswordModalOpened = false;
        this.editingItem = null;
      },

      closeRemoveAccountModalHandler() {
        this.removeAccountModalOpened = false;
        this.editingItem = null;
      },

      closeBlockAccountModalHandler() {
        this.blockAccountModalOpened = false;
        this.editingItem = null;
      },
    },
  };
</script>
