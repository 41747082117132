<template>
  <v-card-text class="pt-6">
    <v-combobox
      v-model="bindTagsRaw"
      :items="filteredTags"
      :filter="filterTags"
      :type="isRealAdminOrHigher ? 'text' : 'button'"
      :label="$t('ui.manageTagsModal.userTags')"
      :hint="$t('ui.manageTagsModal.addTagHint')"
      item-text="title"
      item-value="id"
      item-disabled="isSystemTag"
      small-chips
      hide-selected
      persistent-hint
      outlined
      multiple
    >
      <template #selection="{ attrs, item, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :color="item.color"
          :input-value="selected"
          small
        >
          <span>
            {{ item.title }}
          </span>

          <v-icon small class="ml-2" @click="bindTag = null">
            $delete
          </v-icon>
        </v-chip>
      </template>

      <template #item="{ item }">
        <span v-if="item.id === deletingTagId" class="mr-1">
          {{ $t('pages.scenarios.editModal.tagsDeleteConfirm') }}
        </span>

        <v-chip :color="item.color" small>
          {{ item.title }}
        </v-chip>

        <span v-if="item.id === deletingTagId" class="ml-1">
          ?
        </span>

        <v-spacer />

        <v-list-item-action
          v-if="isRealAdminOrHigher"
          class="flex align-center flex-row  flex-grow-0 flex-nowrap"
          @click.stop
        >
          <template v-if="deletingTagId === item.id">
            <v-btn icon @click="removeTag()">
              <v-icon small color="gray">
                mdi-check
              </v-icon>
            </v-btn>

            <v-btn icon @click="deletingTagId = null">
              <v-icon small color="gray">
                mdi-close
              </v-icon>
            </v-btn>
          </template>

          <v-btn v-else icon color="red" @click="deletingTagId = item.id">
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </template>

      <template #no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="$t('pages.scenarios.editModal.tagsEnterCaption')" />
              <!-- eslint-enable vue/no-v-html -->
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </v-card-text>
</template>

<script>
  import { mapGetters } from 'vuex';
  import api from 'src/api';
  import { deepcopy, versionTagColors } from 'src/utils';

  export default {
    name: 'AddTagsTab',

    data() {
      return {
        isBusy: false,

        tags: [],

        bindTagsRaw: [],
        bindTags: [],

        deletingTagId: null,
      };
    },

    computed: {
      ...mapGetters('users', ['isRealAdminOrHigher']),

      entitiesIdList() {
        return this.entities.map(entity => entity.id);
      },

      filteredTags() {
        return this.tags.filter(tag => !tag.isSystemTag);
      },
    },

    watch: {
      async bindTagsRaw(tags) {
        let tempTags = deepcopy(tags);

        const newTag = this.findNewTag(tempTags);

        if (!this.isRealAdminOrHigher || !newTag) {
          tempTags = tempTags.filter(tag => typeof tag === 'object');
          this.bindTags = tempTags.map(tag => tag.id);

          this.$emit('updated', this.bindTags);
          return;
        }

        try {
          const newTagIndex = tempTags.findIndex(tag => tag === newTag);

          const existsTag = this.tags.find(
            tag => tag.title.toLowerCase().trim() === newTag.toLowerCase().trim()
          );

          if (!existsTag) {
            tempTags[newTagIndex] = await this.createNewTag(newTag);
          } else if (existsTag.isSystemTag) {
            tempTags.splice(newTagIndex, 1);
          } else {
            tempTags[newTagIndex] = { ...existsTag };
          }
        } catch {
          tempTags = tempTags.filter(tag => typeof tag === 'object');
        } finally {
          this.bindTags = tempTags.map(tag => tag.id);

          this.$emit('updated', this.bindTags);
        }
      },
    },

    mounted() {
      this.getTags();
    },

    methods: {
      async getTags() {
        const { response } = await api.tags.list();

        this.tags = response;
      },

      filterTags(item, queryText, itemText) {
        if (item.header) {
          return false;
        }

        const hasValue = val => (val != null ? val : '');

        const text = hasValue(itemText);
        const query = hasValue(queryText);

        return (
          text
            .toString()
            .toLowerCase()
            .trim()
            .indexOf(
              query
                .toString()
                .toLowerCase()
                .trim()
            ) > -1
        );
      },

      async removeTag() {
        await api.tags.remove({
          url_params: {
            id: this.deletingTagId,
          },
        });

        this.tags = this.tags.filter(tag => tag.id !== this.deletingTagId);

        if (this.bindTag?.id === this.deletingTagId) {
          this.bindTag = null;
        }

        this.deletingTagId = null;
      },

      async createNewTag(title) {
        const color = versionTagColors[Math.floor(Math.random() * versionTagColors.length)];
        const { response } = await api.tags.create({
          data: {
            title,
            color,
          },
        });

        this.tags.push(response);

        this.bindTag = response;
      },

      findNewTag(tags) {
        const newTag = tags.find(tag => typeof tag === 'string');

        if (!newTag) {
          return null;
        }

        const alreadySelectedTag = tags.find(
          tag =>
            typeof tag === 'object' &&
            tag.title.toLowerCase().trim() === newTag.toLowerCase().trim()
        );

        if (alreadySelectedTag) {
          return null;
        }

        return newTag;
      },
    },
  };
</script>
