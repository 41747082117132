import api from 'src/api';

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },

  methods: {
    read() {
      if (this.notification.isRead) {
        return;
      }

      api.events.readEvent({ url_params: { id: this.notification.id } });
    },
  },
};
