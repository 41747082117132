<template>
  <v-container class="install">
    <v-tabs v-model="tabIndex" height="54" class="mb-4">
      <template v-for="tab in tabs">
        <v-tab v-if="!tab.upgradeSmartManualsType" :key="tab.value">
          <v-icon left>
            {{ tab.icon }}
          </v-icon>

          {{ tab.text }}
        </v-tab>

        <plan-upgrade-menu v-else :key="tab.value" top offset-y nudge-top="-10" nudge-right="-100">
          <template #activator="{on, attrs}">
            <div v-bind="attrs" class="install__tab-wrapper" v-on="on">
              <v-tab disabled class="install__disabled-tab">
                <v-icon left>
                  {{ tab.icon }}
                </v-icon>

                {{ tab.text }}

                <plan-upgrade-chip class="ml-3" :theme="tab.upgradeSmartManualsType" />
              </v-tab>
            </div>
          </template>
        </plan-upgrade-menu>
      </template>
    </v-tabs>

    <component :is="showedContentComponent" />
  </v-container>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';

  import localStorage from 'src/services/localStorage';
  import { ORGANIZATION_PLAN_FREE } from 'src/constants';

  import PlanUpgradeMenu from 'src/components/PlanUpgradeMenu/PlanUpgradeMenu.vue';
  import PlanUpgradeChip from 'src/components/PlanUpgradeChip/PlanUpgradeChip.vue';

  import BrowserExtension from './components/BrowserExtension.vue';
  import SiteCode from './components/SiteCode.vue';
  import DesktopInstall from './components/DesktopInstall.vue';

  const TAB_TYPE_CHROME_EXTENSION = 'chrome';
  const TAB_TYPE_SITE_CODE = 'siteCode';
  const TAB_TYPE_DESKTOP = 'desktop';

  export default {
    name: 'Install',

    components: {
      PlanUpgradeChip,
      PlanUpgradeMenu,

      BrowserExtension,
      SiteCode,
      DesktopInstall,
    },

    data() {
      return {
        activeTabIndex: 0,
        showedTabValue: localStorage.get('installPageMode') || TAB_TYPE_CHROME_EXTENSION,
      };
    },

    computed: {
      ...mapState('users', ['currentUser']),
      ...mapGetters('users', [
        'isAdminOrHigher',
        'isSmartManualsStartPlanActive',
        'isSimulationPlanActive',
        'isSmartManualsPlansActive',
      ]),

      tabs() {
        const showedSiteTab =
          this.isAdminOrHigher && !this.isSimulationPlanActive && !this.isSmartManualsPlansActive;

        const showedDesktopTab = this.currentUser.organizationPlan !== ORGANIZATION_PLAN_FREE;

        return [
          {
            value: TAB_TYPE_CHROME_EXTENSION,
            text: this.$t('pages.install.browserExtension.title'),
            icon: 'mdi-google-chrome',
          },
          ...(showedSiteTab
            ? [
                {
                  value: TAB_TYPE_SITE_CODE,
                  text: this.$t('pages.install.siteCode.title'),
                  icon: 'mdi-language-javascript',
                },
              ]
            : []),
          ...(showedDesktopTab
            ? [
                {
                  value: TAB_TYPE_DESKTOP,
                  text: this.$t('pages.install.desktop.title'),
                  icon: 'mdi-monitor',
                  upgradeSmartManualsType: this.isSmartManualsStartPlanActive ? 'pro' : null,
                },
              ]
            : []),
        ];
      },

      tabIndex: {
        get() {
          return this.activeTabIndex;
        },

        set(value) {
          this.showedTabValue = this.tabs[value].value;
          this.activeTabIndex = value;

          localStorage.set('installPageMode', this.tabs[value].value);
        },
      },

      showedContentComponent() {
        switch (this.showedTabValue) {
          case TAB_TYPE_CHROME_EXTENSION:
            return 'BrowserExtension';
          case TAB_TYPE_SITE_CODE:
            return 'SiteCode';
          case TAB_TYPE_DESKTOP:
            return 'DesktopInstall';
          default:
            throw new Error(`Unknown tab ${this.showedTabValue}`);
        }
      },
    },

    mounted() {
      const existTabIndex = this.tabs.findIndex(tab => tab.value === this.showedTabValue);

      if (existTabIndex === -1 || this.tabs[existTabIndex].upgradeSmartManualsType) {
        this.activeTabIndex = 0;
        this.showedTabValue = this.tabs[0].value;
      } else {
        this.activeTabIndex = existTabIndex;
      }
    },
  };
</script>

<style lang="scss">
  .install {
    &__tab-wrapper {
      display: flex;
      align-items: center;
    }

    &__disabled-tab {
      opacity: 1 !important;
      color: rgba(0, 0, 0, 0.2) !important;
    }
  }
</style>
