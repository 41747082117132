import { io } from 'socket.io-client';

import { getTokenForAxios, headerAuthorization } from 'src/services/session';
import { apiUrl } from 'src/config';

class EventsService {
  constructor() {
    this.socket = null;
  }

  connect() {
    if (this.socket) {
      this.socket.disconnect();
    }

    this.socket = io(apiUrl, {
      transports: ['polling', 'websocket'],
      path: '/api/socket',
      extraHeaders: {
        [headerAuthorization]: getTokenForAxios(),
      },
    });
  }

  on(event, callback) {
    if (!this.socket) {
      return;
    }

    this.socket.on(event, callback);
  }

  off(event, callback) {
    if (!this.socket) {
      return;
    }

    this.socket.off(event, callback);
  }
}
export default new EventsService();
