<template>
  <v-container>
    <v-row>
      <template v-for="(value, name) in stats">
        <v-col v-if="currentCards[name]" :key="name" cols="auto" class="py-0 flex-grow-1">
          <v-card>
            <v-card-text class="pa-5">
              <div class="d-flex align-center">
                <span class="lstick" :class="`lstick--${currentCards[name].color}`" />
                <v-icon
                  v-if="currentCards[name].icon"
                  large
                  :color="currentCards[name].color"
                  class="img-fluid"
                >
                  {{ currentCards[name].icon }}
                </v-icon>

                <v-img
                  v-if="currentCards[name].svg"
                  :src="currentCards[name].svg"
                  class="img-fluid"
                  :max-height="currentCards[name].height || 24"
                  :max-width="currentCards[name].width || 24"
                />

                <feather
                  v-if="currentCards[name].feather"
                  class="img-fluid"
                  :type="currentCards[name].feather"
                />

                <div class="ml-4 mr-1">
                  <h5 class="subtitle-1 grey--text">
                    {{ currentCards[name].title }}
                  </h5>

                  <h2 class="font-weight-regular " :class="`${currentCards[name].color}--text`">
                    {{ value }}
                  </h2>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'StatCards',

    props: {
      stats: {
        type: Object,
        required: false,
        default: () => ({}),
      },

      cards: {
        type: Object,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        cardsDefault: {
          complete: {
            title: this.$t('pages.statistics.cards.completed'),
            icon: 'mdi-check',
            color: 'info',
          },

          interrupt: {
            title: this.$t('pages.statistics.cards.interrupted'),
            icon: 'mdi-cancel',
            color: 'grey',
          },

          played: {
            title: this.$t('pages.statistics.cards.played'),
            icon: 'mdi-play',
            color: 'green',
          },

          stop: {
            title: this.$t('pages.statistics.cards.stopped'),
            icon: 'mdi-stop',
            color: 'red',
          },
        },
      };
    },

    computed: {
      currentCards() {
        return this.cards || this.cardsDefault;
      },
    },
  };
</script>
