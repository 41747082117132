<template>
  <v-card class="link-edit-modal">
    <v-form ref="form" @submit.prevent="submit()">
      <v-card-title class="pb-0">
        <span class="headline">
          <template v-if="editMode">
            {{
              $t('pages.accounts.accountManageModal.title.edit', { email: editingAccount.email })
            }}
          </template>

          <template v-else>
            {{ $t('pages.accounts.accountManageModal.title.create') }}
          </template>
        </span>

        <v-spacer />

        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-6">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model.trim="accountData.username"
                :rules="[validation.getRequiredRule]"
                :error-messages="usernameServerError"
                :label="$t('pages.accounts.accountManageModal.username')"
                hide-details="auto"
                outlined
                @input="usernameServerError = null"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model.trim="accountData.email"
                :rules="[validation.getRequiredRule, validation.getEmailRule]"
                :error-messages="emailServerError"
                :label="$t('pages.accounts.accountManageModal.email')"
                hide-details="auto"
                outlined
                @input="emailServerError = null"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="accountData.firstname"
                :label="$t('pages.accounts.accountManageModal.firstname')"
                hide-details="auto"
                outlined
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="accountData.lastname"
                :label="$t('pages.accounts.accountManageModal.lastname')"
                hide-details="auto"
                outlined
              />
            </v-col>

            <template v-if="!editMode">
              <v-col cols="6">
                <v-text-field
                  v-model="accountData.password"
                  :rules="[validation.getRequiredRule, validation.getPasswordLengthRule]"
                  :append-icon="showedPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showedPassword ? 'text' : 'password'"
                  :label="$t('pages.accounts.accountManageModal.password')"
                  hide-details="auto"
                  outlined
                  @click:append="showedPassword = !showedPassword"
                />
              </v-col>

              <v-col cols="6">
                <v-switch
                  v-model="accountData.isPasswordChangeNeeded"
                  :label="$t('pages.accounts.accountManageModal.firstLoginChangePassword')"
                  hide-details
                  class="mt-3 mb-0 pt-0"
                />
              </v-col>
            </template>

            <v-col v-if="editMode" cols="6">
              <v-switch
                v-model="accountData.verified"
                :disabled="editMode && editingAccount.verified"
                :label="$t('pages.accounts.accountManageModal.emailVerified')"
                hide-details
                class="mt-0 mb-3"
              />
            </v-col>
          </v-row>

          <account-organizations v-model="accountOrganizations" class="mt-3" />

          <v-alert v-if="commonServerError" color="red" type="error" class="mt-5 mb-0">
            {{ commonServerError }}
          </v-alert>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="primary" text @click="close()">
          {{ $t('ui.common.cancel') }}
        </v-btn>

        <v-btn :disabled="isBusy" :loading="isBusy" color="primary" type="submit">
          <template v-if="editMode">
            {{ $t('ui.common.save') }}
          </template>

          <template v-else>
            {{ $t('ui.common.create') }}
          </template>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import pick from 'lodash.pick';
  import omit from 'lodash.omit';

  import api from 'src/api';
  import { emailRegex } from 'src/utils';

  import AccountOrganizations from './AccountOrganizations.vue';

  const USERNAME_ALREADY_EXISTS_CODE = 'USERNAME_ALREADY_EXISTS';
  const EMAIL_ALREADY_EXISTS_CODE = 'EMAIL_ALREADY_EXISTS';

  export default {
    name: 'AccountManageModal',

    components: {
      AccountOrganizations,
    },

    props: {
      editingAccount: {
        type: Object,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        isBusy: false,

        accountData: {
          username: '',
          email: '',
          firstname: '',
          lastname: '',
          verified: true,
          password: '',
          isPasswordChangeNeeded: true,
        },
        accountOrganizations: [],

        showedPassword: false,

        usernameServerError: null,
        emailServerError: null,
        commonServerError: null,
        validation: {
          getRequiredRule: v => (!!v && !!v.trim()) || this.$t('validation.notNull'),
          getEmailRule: v => emailRegex.test(v.trim()) || this.$t('validation.emailInvalid'),
          getPasswordLengthRule: v => v.length >= 6 || this.$t('validation.passwordLength'),
        },
      };
    },

    computed: {
      editMode() {
        return Boolean(this.editingAccount);
      },
    },

    mounted() {
      if (this.editingAccount) {
        this.accountData = {
          ...this.accountData,
          ...pick(this.editingAccount, [
            'username',
            'email',
            'firstname',
            'lastname',
            'verified',
            'isPasswordChangeNeeded',
          ]),
        };

        this.getUserOrganizations();
      }
    },

    methods: {
      async getUserOrganizations() {
        const { response } = await api.accounts.getOrganizations({
          url_params: { id: this.editingAccount.id },
        });

        this.accountOrganizations = response;
      },

      async submit() {
        if (this.isBusy || !this.$refs.form.validate()) {
          return;
        }

        this.usernameServerError = null;
        this.emailServerError = null;
        this.commonServerError = null;

        try {
          const payload = {
            ...this.accountData,
            firstname: this.accountData.firstname || null,
            lastname: this.accountData.lastname || null,
          };

          let response = null;

          if (this.editMode) {
            ({ response } = await this.update(payload));
            this.$emit('update');
          } else {
            ({ response } = await this.create(payload));
            this.$emit('create');
          }

          this.updateOrganizations(response.id);

          this.close();
        } catch (error) {
          switch (error.code) {
            case USERNAME_ALREADY_EXISTS_CODE:
              this.usernameServerError = this.$t('serverCode.USERNAME_ALREADY_EXISTS');
              break;
            case EMAIL_ALREADY_EXISTS_CODE:
              this.emailServerError = this.$t('serverCode.EMAIL_ALREADY_EXISTS');
              break;
            default:
              this.commonServerError = this.$te(`serverCode.${error.code}`)
                ? this.$t(`serverCode.${error.code}`)
                : this.$t('serverCode.serverError');
              break;
          }
        } finally {
          this.isBusy = false;
        }
      },

      async create(payload) {
        return api.accounts.create({ data: omit(payload, ['verified']) });
      },

      update(payload) {
        return api.accounts.update({
          url_params: { id: this.editingAccount.id },
          data: omit(payload, ['isPasswordChangeNeeded', 'password']),
        });
      },

      updateOrganizations(id) {
        return api.accounts.updateOrganizations({
          url_params: { id },
          data: { list: this.accountOrganizations },
        });
      },

      close() {
        this.$emit('close');
      },
    },
  };
</script>
