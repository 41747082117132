<template>
  <div>
    <v-row v-for="(accountOrganization, index) in localValue" :key="accountOrganization.id">
      <v-col cols="4">
        <v-autocomplete
          :value="accountOrganization.id"
          :items="organizationsList"
          :label="$t('pages.accounts.accountOrganizations.team')"
          item-value="id"
          item-text="name"
          disabled
          hide-details
          outlined
          @input="changeItemField(index, 'id', $event)"
        />
      </v-col>

      <v-col cols="4">
        <v-select
          :value="accountOrganization.role"
          :items="rolesItems"
          :label="$t('pages.accounts.accountOrganizations.role')"
          hide-details
          outlined
          @input="changeItemField(index, 'role', $event)"
        />
      </v-col>

      <v-col cols="4" class="d-flex align-center justify-space-between">
        <v-switch
          :input-value="accountOrganization.isApproved"
          :label="$t('pages.accounts.accountOrganizations.approved')"
          hide-details
          class="mt-0"
          @change="changeItemField(index, 'isApproved', $event)"
        />

        <v-btn icon class="ml-2" @click="removeItem(index)">
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="newItem">
      <v-col cols="4">
        <v-autocomplete
          :value="newItem.id"
          :items="notSelectedOrganizations"
          :label="$t('pages.accounts.accountOrganizations.team')"
          hide-details
          outlined
          item-value="id"
          item-text="name"
          @input="applyNewItem($event)"
        />
      </v-col>

      <v-col cols="4">
        <v-select v-model="newItem.role" :items="rolesItems" label="Роль" hide-details outlined />
      </v-col>

      <v-col cols="4" class="d-flex align-center justify-space-between">
        <v-switch
          v-model="newItem.isApproved"
          :label="$t('pages.accounts.accountOrganizations.approved')"
          hide-details
          class="mt-0"
        />

        <v-btn icon class="ml-2" @click="newItem = null">
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center mt-4">
      <v-btn :disabled="Boolean(newItem)" color="primary" text @click="addItem()">
        <v-icon left>mdi-plus</v-icon>

        <span>{{ $t('pages.accounts.accountOrganizations.addButton') }}</span>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import api from 'src/api';
  import { deepcopy } from 'src/utils';

  export default {
    name: 'AccountOrganizations',

    model: {
      prop: 'modelValue',
      event: 'update:modelValue',
    },

    props: {
      modelValue: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        organizationsList: [],

        localValue: [],

        newItem: null,
      };
    },

    computed: {
      ...mapState('users', ['usersRoles']),

      rolesItems() {
        return this.usersRoles.map(role => ({
          value: role,
          text: this.$t(`common.roles.${role}`),
        }));
      },

      notSelectedOrganizations() {
        const selectedOrganizationsIds = this.localValue.map(({ id }) => id);

        return this.organizationsList.filter(item => !selectedOrganizationsIds.includes(item.id));
      },
    },

    watch: {
      modelValue(value) {
        this.localValue = value;
      },
    },

    mounted() {
      this.getAllOrganizations();
    },

    methods: {
      async getAllOrganizations() {
        const { response } = await api.organizations.list();

        this.organizationsList = Object.freeze(
          response.sort((a, b) => a.name.localeCompare(b.name))
        );
      },

      changeItemField(index, field, value) {
        const newItems = deepcopy(this.localValue);

        newItems[index][field] = value;

        this.update(newItems);
      },

      addItem() {
        this.newItem = { id: null, isApproved: true, role: 'EDITOR' };
      },

      applyNewItem(organizationId) {
        const newItems = deepcopy(this.localValue);

        newItems.push({ ...this.newItem, id: organizationId });

        this.newItem = null;

        this.update(newItems);
      },

      removeItem(index) {
        const newItems = deepcopy(this.localValue);

        newItems.splice(index, 1);

        this.update(newItems);
      },

      update(value) {
        this.$emit('update:modelValue', value);
      },
    },
  };
</script>
