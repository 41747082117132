import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';

import api from 'src/api';
import { deepcopy, syncSimulationWithScenario, syncedSimulationOmittingFields } from 'src/utils';

import {
  CLEAR_VERSIONS_DATA,
  SET_BUSY_SELECTED_VERSION,
  SET_BUSY_UPDATE_VERSION,
  SET_BUSY_VERSIONS_LIST,
  SET_ENABLED_CREATING_MODE,
  SET_SELECTED_VERSION_ID,
  SET_TAGS_LIST,
  SET_VERSION_DATA,
  SET_VERSION_TAGS,
  SET_VERSIONS_ENTITY,
  SET_VERSIONS_ENTITY_TYPE,
  SET_VERSIONS_LIST,
  UPDATE_VERSION_DATA,
  SET_SYNCED_SIMULATION_ID,
  SET_SYNCED_SIMULATION_VERSIONS_LIST,
} from '../mutation-types';

const excludedEntitiesFields = {
  scenarios: [
    'id',
    'api',
    'creatorId',
    'organizationId',
    'createdAt',
    'updatedAt',
    'deletedAt',
    'isSubVersion',
    'versionNumber',
    'workspaceId',
    'onModerating',
    'isDraft',
  ],
  tooltips: [
    'id',
    'api',
    'creatorId',
    'organizationId',
    'createdAt',
    'updatedAt',
    'deletedAt',
    'isSubVersion',
    'versionNumber',
    'workspaceId',
    'onModerating',
    'isDraft',
  ],
};

const defaultState = {
  compareData: null,
  creatingModeEnabled: false,
  entity: null,
  entityType: null,
  isBusyUpdatingVersion: false,
  isBusySelectedVersion: false,
  isBusyVersionsList: false,
  selectedVersionId: null,
  selectedVersionData: null,
  versionsList: [],

  tags: [],
  selectedVersionTags: [],

  syncedSimulationId: null,
  syncedSimulationVersionsList: [],
};

export default {
  namespaced: true,

  state: {
    ...defaultState,
  },

  getters: {
    entityId: state => state.entity?.id || null,
    entityExcludedFields: state => excludedEntitiesFields[state.entityType],

    hasAccessToVersionControl: (state, getters, rootState, rootGetters) =>
      rootGetters['users/isEnterprisePlanActive'],

    selectedVersion: (state, getters) => {
      if (!getters.hasAccessToVersionControl) {
        return state.entity;
      }

      const { selectedVersionId } = state;
      if (!selectedVersionId) {
        return {};
      }

      const selectedVersion = state.versionsList.find(version => version.id === selectedVersionId);

      return selectedVersion || {};
    },

    isSelectedActualVersion: (state, getters) => {
      if (!getters.hasAccessToVersionControl || !state.versionsList.length) {
        return true;
      }

      return getters.selectedVersion.isActual;
    },

    versionHasUnsavedChanges: (state, getters) => {
      if (getters.isSelectedActualVersion) {
        return false;
      }

      return !isEqual(state.compareData, state.selectedVersionData);
    },

    tagsList: state => state.tags.filter(tag => !tag.isSystemTag),

    isSyncedScenarioWithSimulation: state =>
      state.entityType === 'scenarios' && state.syncedSimulationId,
  },

  actions: {
    async getVersions({ state, getters, commit }) {
      if (state.entity.onModerating || state.entity.isDraft) {
        const { response } = await api[state.entityType].getDraft({
          url_params: { id: getters.entityId },
        });

        commit(SET_VERSION_DATA, response);

        return;
      }

      if (!getters.hasAccessToVersionControl) {
        const versionsData = { ...state.entity };

        if (getters.isSyncedScenarioWithSimulation) {
          const { response } = await api.simulations.get({
            url_params: { id: state.syncedSimulationId },
          });

          versionsData.simulation = omit(response, syncedSimulationOmittingFields);
        }

        commit(SET_VERSION_DATA, state.entity);

        return;
      }

      commit(SET_BUSY_VERSIONS_LIST, true);

      const { response } = await api[state.entityType].getVersions({
        url_params: { id: getters.entityId },
      });

      let syncedSimulationVersionsList = [];

      if (getters.isSyncedScenarioWithSimulation) {
        ({ response: syncedSimulationVersionsList } = await api.simulations.getVersionsList({
          url_params: { id: state.syncedSimulationId },
        }));
      }

      const actualVersionId = response.find(version => version.isActual).id;

      commit(SET_SELECTED_VERSION_ID, actualVersionId);
      commit(SET_VERSIONS_LIST, response.reverse());
      commit(SET_SYNCED_SIMULATION_VERSIONS_LIST, syncedSimulationVersionsList);
      commit(SET_BUSY_VERSIONS_LIST, false);
    },

    async getVersionData({ state, getters, commit }) {
      commit(SET_BUSY_SELECTED_VERSION, true);

      const { response } = await api[state.entityType].getVersionData({
        url_params: { versionId: state.selectedVersionId },
      });

      if (getters.isSyncedScenarioWithSimulation) {
        const syncSimulationVersion = state.syncedSimulationVersionsList.find(
          version => version.meta.scenarioVersionId === state.selectedVersionId
        );

        const { response: syncSymulationVersion } = await api.simulations.getVersion({
          url_params: { versionId: syncSimulationVersion.id },
        });

        response.simulation = omit(syncSymulationVersion, syncedSimulationOmittingFields);
      }

      commit(SET_VERSION_DATA, response);
      commit(SET_BUSY_SELECTED_VERSION, false);
    },

    async getAllTags({ commit, getters }) {
      if (!getters.hasAccessToVersionControl) {
        return;
      }

      const { response } = await api.tags.list();

      commit(SET_TAGS_LIST, response);
    },

    async getVersionTags({ state, commit, getters }) {
      if (!getters.hasAccessToVersionControl) {
        return;
      }

      const { response } = await api[state.entityType].getVersionTags({
        url_params: { versionId: state.selectedVersionId },
      });

      commit(SET_VERSION_TAGS, response);
    },

    async updateVersionTags({ state, getters }, versionId = null) {
      if (!getters.hasAccessToVersionControl) {
        return null;
      }

      const updatingVersionId = versionId || state.selectedVersionId;

      const { response } = await api[state.entityType].updateVersionTags({
        url_params: {
          versionId: updatingVersionId,
        },
        data: {
          tags: state.selectedVersionTags.filter(tag => !tag.isSystemTag).map(tag => tag.id),
        },
      });

      return response;
    },

    async createTag({ dispatch }, data) {
      const { response } = await api.tags.create({
        data,
      });

      dispatch('getAllTags');

      return response;
    },

    async deleteTag({ dispatch }, id) {
      const { response } = await api.tags.remove({
        url_params: {
          id,
        },
      });

      await dispatch('getAllTags');

      return response;
    },

    async update({ state, getters, dispatch, commit, rootGetters }, createNewVersion = false) {
      commit(SET_BUSY_UPDATE_VERSION, true);

      const data = omit(state.selectedVersionData, getters.entityExcludedFields);

      if (state.selectedVersionData.onModerating) {
        data.isNewVersion = createNewVersion;
      }

      if (getters.isSyncedScenarioWithSimulation) {
        data.simulation = syncSimulationWithScenario(data, data.simulation);
      }

      if (state.selectedVersionData.onModerating || state.selectedVersionData.isDraft) {
        await api[state.entityType].updateDraft({
          url_params: { id: getters.entityId },
          data,
        });

        commit(SET_BUSY_UPDATE_VERSION, false);

        return;
      }

      if (
        !state.creatingModeEnabled &&
        getters.hasAccessToVersionControl &&
        (createNewVersion || !getters.isSelectedActualVersion)
      ) {
        const {
          response: { versionId },
        } = await api[state.entityType].createVersion({
          url_params: { id: getters.entityId },
          data,
        });

        if (rootGetters['users/isModeratorOrHigher']) {
          await dispatch('updateVersionTags', versionId);
        }
      } else {
        const method = state.creatingModeEnabled ? 'create' : 'update';
        let params = state.creatingModeEnabled
          ? { data }
          : { url_params: { id: getters.entityId }, data };
        // Hack here: tooltip update method accept id from body, not from url
        if (state.entityType === 'tooltips' && !state.creatingModeEnabled) {
          params = { data: { ...data, id: getters.entityId } };
        }

        await api[state.entityType][method](params);
      }

      if (getters.hasAccessToVersionControl && !state.creatingModeEnabled) {
        await dispatch('getVersions');
      }

      commit(SET_BUSY_UPDATE_VERSION, false);
    },

    async createDraft({ state, getters, dispatch, commit }) {
      commit(SET_BUSY_UPDATE_VERSION, true);

      const data = omit(state.selectedVersionData, getters.entityExcludedFields);
      data.isNewVersion = true;

      await api[state.entityType].createDraft({
        url_params: { id: getters.entityId },
        data,
      });

      await dispatch('getVersions');

      commit(SET_BUSY_UPDATE_VERSION, false);
    },

    async approveDraft({ state, getters, dispatch, commit }, createNewVersion = false) {
      commit(SET_BUSY_UPDATE_VERSION, true);

      await dispatch('update', createNewVersion);

      await api[state.entityType].approveDraft({
        url_params: { id: getters.entityId },
      });

      commit(SET_BUSY_UPDATE_VERSION, false);
    },

    async rejectDraft({ state, getters }) {
      await api[state.entityType].rejectDraft({
        url_params: { id: getters.entityId },
      });
    },
  },

  mutations: {
    [SET_ENABLED_CREATING_MODE](state, status) {
      state.creatingModeEnabled = status;
    },

    [SET_VERSIONS_LIST](state, versionsList) {
      state.versionsList = versionsList;
    },

    [SET_SELECTED_VERSION_ID](state, id) {
      state.selectedVersionId = id;
    },

    [SET_VERSION_DATA](state, versionData) {
      state.selectedVersionData = deepcopy(versionData);
      state.compareData = Object.freeze(deepcopy(versionData));
    },

    [SET_VERSION_TAGS](state, tags) {
      state.selectedVersionTags = tags;
    },

    [SET_TAGS_LIST](state, tags) {
      state.tags = tags;
    },

    [UPDATE_VERSION_DATA](state, versionData) {
      state.selectedVersionData = deepcopy(versionData);
    },

    [SET_VERSIONS_ENTITY_TYPE](state, type) {
      state.entityType = type;
    },

    [SET_VERSIONS_ENTITY](state, entity) {
      state.entity = Object.freeze(deepcopy(entity));
    },

    [SET_BUSY_SELECTED_VERSION](state, status) {
      state.isBusySelectedVersion = status;
    },

    [SET_BUSY_VERSIONS_LIST](state, status) {
      state.isBusyVersionsList = status;
    },

    [SET_BUSY_UPDATE_VERSION](state, status) {
      state.isBusyUpdatingVersion = status;
    },

    [SET_SYNCED_SIMULATION_ID](state, simulationId) {
      state.syncedSimulationId = simulationId;
    },

    [SET_SYNCED_SIMULATION_VERSIONS_LIST](state, versionsList) {
      state.syncedSimulationVersionsList = versionsList;
    },

    [CLEAR_VERSIONS_DATA](state) {
      const { entityType, tags } = state;

      Object.keys(state).forEach(field => {
        state[field] = defaultState[field];
      });

      state.entityType = entityType;
      state.tags = tags;
    },
  },
};
