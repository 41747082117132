<template>
  <v-card class="link-edit-modal">
    <v-card-title class="pb-0">
      <span class="headline">
        <template v-if="isEdit">
          {{ $t('pages.links.editModal.title.edit') }}
        </template>

        <template v-else>
          {{ $t('pages.links.editModal.title.create') }}
        </template>
      </span>

      <v-spacer />

      <v-btn icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pt-6">
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                ref="titleInput"
                v-model="link.title"
                :label="$t('pages.links.editModal.fields.title')"
                :rules="[rules.getRequiredRule('title')]"
                hide-details="auto"
                maxlength="200"
                outlined
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                ref="titleInput"
                v-model="link.url"
                :label="$t('pages.links.editModal.fields.url')"
                :rules="[rules.getRequiredRule('url')]"
                hide-details="auto"
                outlined
              />
            </v-col>

            <v-col cols="12">
              <origin-list-edit v-model="originList" />
            </v-col>

            <v-col v-if="isModeratorOrHigher" cols="12" class="py-0">
              <v-switch
                v-model="link.published"
                :label="$t(`pages.links.editModal.fields.published`)"
                hide-details
              />
            </v-col>

            <v-col cols="12" class="py-0">
              <v-switch
                v-model="link.openInNewPage"
                :label="$t(`pages.links.editModal.fields.openInNewPage`)"
                hide-details
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" text @click="close()">
        {{ $t('ui.common.cancel') }}
      </v-btn>

      <v-btn v-if="isEdit" color="primary" type="submit" @click="submit()">
        {{ $t('ui.common.save') }}
      </v-btn>

      <v-btn v-else color="primary" type="submit" @click="submit()">
        {{ $t('ui.common.create') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import pick from 'lodash.pick';
  import { mapGetters } from 'vuex';

  import api from 'src/api';
  import { busyFlow, deepcopy } from 'src/utils';

  import OriginListEdit from 'src/components/OriginListEdit/OriginListEdit.vue';

  const linkEditableParams = ['title', 'published', 'openInNewPage', 'url', 'originList'];

  export default {
    name: 'LinkEditModal',

    components: {
      OriginListEdit,
    },

    props: {
      editingLink: {
        type: Object,
        required: false,
        default: null,
      },

      scenariosManaging: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        isBusy: false,
        isBusyGroups: false,
        isEdit: false,
        link: {
          title: this.$t(`pages.links.editModal.defaultLinkTitle`),
          published: true,
          openInNewPage: true,
          url: null,
          originList: [
            {
              type: 'regex',
              value: '*',
            },
          ],
        },
        rules: {
          getRequiredRule: name => v =>
            !!v ||
            this.$t('validation.notNullField', {
              field: this.$t(`pages.links.editModal.fields.${name}`),
            }),
        },
      };
    },

    computed: {
      ...mapGetters('users', ['isModeratorOrHigher']),

      originList: {
        get() {
          if (!this.editingLink) {
            return this.link.originList;
          }

          if (!this.editingLink.originList?.length) {
            return [{ type: 'text', value: this.editingLink.origin }];
          }

          return this.editingLink.originList;
        },
        set(value) {
          if (this.isEdit) {
            this.$set(this.editingLink, 'originList', value);
          } else {
            this.$set(this.link, 'originList', value);
          }
        },
      },
    },

    async created() {
      this.isBusyGroups = true;

      if (this.editingLink) {
        this.isEdit = true;
        this.link = deepcopy(this.editingLink);
      }

      this.isBusyGroups = false;
      await this.$nextTick();
      this.$refs.titleInput.focus();
    },

    methods: {
      async submit() {
        if (!this.$refs.form.validate()) {
          return;
        }

        busyFlow.call(this, async () => {
          let linkId = null;

          const data = pick(this.link, linkEditableParams);

          if (this.isEdit) {
            linkId = this.editingLink.id;

            await api.links.update({
              url_params: { id: linkId },
              data: { ...data, originList: this.originList },
            });
          } else {
            const { response } = await api.links.create({
              data: {
                ...data,
                title: data.title || this.$t('pages.links.editModal.defaultLinkTitle'),
                originList: this.originList,
              },
            });

            this.link = response;
          }

          if (this.isEdit) {
            this.$emit('updated');
          } else {
            this.$emit('created');
          }

          this.close();
        });
      },

      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss">
  .link-edit-modal {
    &__form {
      max-width: 550px;
    }
  }
</style>
