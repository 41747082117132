<template>
  <div>
    <v-container>
      <v-row justify="space-between">
        <span>{{ value }}</span>
        <span v-if="showPercent">{{ `(${percent})%` }}</span>
      </v-row>
    </v-container>

    <v-progress-linear
      :value="percent"
      color="#EB3349"
      background-color="#DAE4E8"
      :height="5"
      rounded
    ></v-progress-linear>
  </div>
</template>

<script>
  export default {
    name: 'BaseConversionBar',

    props: {
      value: { type: Number, default: 0 },
      total: { type: Number, default: 1 },
      percent: { type: Number, default: 0 },
      showPercent: { type: Boolean, default: false },
    },
  };
</script>
