import { render, staticRenderFns } from "./OrganizationsMenu.vue?vue&type=template&id=0ff59f08&"
import script from "./OrganizationsMenu.vue?vue&type=script&lang=js&"
export * from "./OrganizationsMenu.vue?vue&type=script&lang=js&"
import style0 from "./OrganizationsMenu.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VDialog,VIcon,VList,VListItem,VListItemContent,VListItemGroup,VListItemSubtitle,VListItemTitle,VMenu,VTextField})
