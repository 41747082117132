<template>
  <div>
    <div>
      <div class="block-header block-header--first">
        {{ $t('pages.styles.settings.themeColors') }}
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="hotspotBackgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.primaryColor') }}</div>
      </div>

      <div v-if="themeType !== $options.THEME_TYPE_CLASSIC" class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="borderColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.secondaryColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="backgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.backgroundColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="color" />
        <div class="ml-4">{{ $t('pages.styles.settings.fontColor') }}</div>
      </div>
    </div>

    <div>
      <div class="block-header">
        {{ $t('pages.styles.settings.highlightType') }}
      </div>

      <v-btn-toggle v-model="highlightType" mandatory class="">
        <v-btn value="marker">
          <v-icon>
            mdi-information
          </v-icon>
        </v-btn>
        <v-btn value="underline">
          <div style="width: 55px; height: 2px; background-color: rgba(0, 0, 0, 0.87); " />
        </v-btn>
        <v-btn value="none">
          {{ $t('pages.styles.settings.nonHighlight') }}
        </v-btn>
      </v-btn-toggle>
    </div>

    <div>
      <div class="block-header">
        {{ $t('pages.styles.settings.generalSettings') }}
      </div>

      <font-select v-model="fontFamily" />

      <div class="input-control">
        <div>{{ $t('pages.styles.settings.allSidePadding') }}</div>

        <h-slider v-model="padding" max="30" min="0" hide-details>
          <template #append>
            <v-text-field
              v-model="padding"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div v-if="highlightType === 'marker'" class="input-control">
        <div>{{ $t('pages.styles.settings.iconSize') }}</div>

        <h-slider v-model="hotspotSize" max="50" min="15" hide-details>
          <template #append>
            <v-text-field
              v-model="hotspotSize"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>
    </div>

    <div v-if="isEnterprisePlanActive" class="mb-2">
      <v-switch
        v-model="copyrightHidden"
        :label="$t('pages.styles.settings.copyrightHidden')"
        hide-details
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import InlineColorPicker from 'src/components/InlineColorPicker.vue';
  import HSlider from 'src/HSlider/HSlider.vue';

  import stylesMixin from '../../mixins/stylesMixin';

  import { generateDynamicFields, THEME_TYPE_CLASSIC } from '../../utils';

  import FontSelect from '../FontSelect.vue';

  export default {
    name: 'TooltipGeneralStyles',

    components: {
      InlineColorPicker,
      HSlider,
      FontSelect,
    },

    mixins: [stylesMixin],

    THEME_TYPE_CLASSIC,

    computed: {
      ...mapGetters('users', ['isEnterprisePlanActive']),

      ...generateDynamicFields([
        'backgroundColor',
        'borderColor',
        'buttonBackgroundColor',
        'buttonBorderRadius',
        'buttonColor',
        'color',
        'fontFamily',
        'highlightType',
        'hotspotBackgroundColor',
        'hotspotSize',
        'underlineBackgroundColor',
      ]),

      ...generateDynamicFields(['copyrightHidden'], 'updateSettings', 'settings'),

      padding: {
        get() {
          const { paddingLeft, paddingRight, paddingTop, paddingBottom } = this.value;

          if (
            paddingLeft !== paddingRight &&
            paddingLeft !== paddingTop &&
            paddingLeft !== paddingBottom
          ) {
            return 0;
          }

          return paddingLeft;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingLeft: value,
            paddingRight: value,
            paddingTop: value,
            paddingBottom: value,
          });
        },
      },
    },

    watch: {
      hotspotBackgroundColor(value) {
        this.underlineBackgroundColor = value;
      },

      highlightType() {
        this.underlineBackgroundColor = this.hotspotBackgroundColor;
      },
    },
  };
</script>

<style lang="scss"></style>
