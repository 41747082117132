import get from 'lodash.get';
import localStorage from './localStorage';

const accessTokenName = 'access-token';
const refreshTokenName = 'refresh-token';
export const headerAuthorization = 'auth';

let accessToken = '';
let refreshToken = '';
let authing;
let store;

const tokenFromStorage = localStorage.get(accessTokenName);
if (tokenFromStorage) {
  accessToken = tokenFromStorage;
}
const refreshTokenFromStorage = localStorage.get(refreshTokenName);
if (refreshTokenFromStorage) {
  refreshToken = refreshTokenFromStorage;
}

/**
 * return Boolean is auth process running
 * @return {boolean}
 */
export const isAuthing = () => Boolean(authing);

let startJobs;

export const waitInitialization = new Promise(resolve => {
  startJobs = resolve;
});

/**
 * return client "is authenticated" status
 * @return {boolean}
 */
export const isAuth = () => Boolean(accessToken);

export const initAuthPlugin = async storeFromVuex => {
  if (!store) {
    store = storeFromVuex;
  }

  try {
    if (accessToken) {
      await store.dispatch('auth/checkAuth');
    }
  } finally {
    authing = false;
    startJobs();
  }
};

/**
 * Return current session access token
 * @return {string} - session token
 */
export const getTokenForAxios = () => accessToken;
export const getRefreshToken = () => refreshToken;

export const setAccessToken = data => {
  accessToken = `Bearer ${data}`;
  localStorage.set(accessTokenName, `Bearer ${data}`);
  return true;
};

export const setRefreshToken = data => {
  refreshToken = data;
  localStorage.set(refreshTokenName, data);
  return true;
};

/**
 * Drop session token.
 */
export const dropToken = () => {
  accessToken = null;
  localStorage.remove(accessTokenName);
  refreshToken = null;
  localStorage.remove(refreshTokenName);
};

export const handleResponseError = error => {
  if (error && error.response && error.response.status && error.response.status === 401) {
    dropToken();
  }
  const exception = get(error, 'response.data.error.name');

  if (exception === 'AuthError') {
    store.dispatch('auth/clearData');
  }

  return Promise.reject(error);
};
