import { customAlphabet } from 'nanoid';
import localStorage from 'src/services/localStorage';
import qs from 'query-string';
import * as windows1251 from 'windows-1251';

export const deepcopy = val => JSON.parse(JSON.stringify(val));

export const timeout = delay => {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
};

export function retry(decisionCallback = () => {}, delay = 300) {
  const exec = async () => {
    const isFailed = await decisionCallback.call(this);

    if (isFailed) {
      // eslint-disable-next-line no-use-before-define
      return attempt();
    }

    return true;
  };

  const attempt = async () => {
    await timeout(delay);
    return exec();
  };

  return exec();
}

export async function busyFlow(func, isForce, busyProperty = 'isBusy') {
  if (this[busyProperty] && !isForce) {
    return;
  }

  this[busyProperty] = true;

  try {
    await func();
  } catch (err) {
    console.error(err);
  } finally {
    this[busyProperty] = false;
  }
}

export const strToHtml = string => {
  try {
    const parser = new DOMParser();
    const el = parser.parseFromString(string, 'text/html');
    return el.body.firstChild || el.body;
  } catch (error) {
    return null;
  }
};

export const randomString = (length = 10) => {
  return customAlphabet('1234567890abcdef', length)();
};

export const getAttributes = node => {
  return Array.from(node.attributes).reduce((acc, current) => {
    acc[current.nodeName] = current.nodeValue;
    return acc;
  }, {});
};

export const getUUIDv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    /* eslint-disable no-bitwise */
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line eqeqeq
    const v = c == 'x' ? r : (r & 0x3) | 0x8;
    /* eslint-enable no-bitwise */

    return v.toString(16);
  });
};

export const jsonStylesToCss = (jsonStyle, parentClass) => {
  let css = '';
  Object.keys(jsonStyle).forEach(key => {
    const parent = parentClass ? `.${parentClass} ` : '';
    if (typeof jsonStyle[key] === 'string') {
      css += `${parent}.${key} {${jsonStyle[key]}}`;
    } else if (typeof jsonStyle[key] === 'object') {
      css += jsonStylesToCss(jsonStyle[key], key);
    }
  });
  return css;
};

export const isWindows = () => {
  const regexp = /(win32|win64|windows|wince)/i;
  return regexp.test(window.navigator.userAgent);
};

export const prepareCsv = async value => {
  const isWindowsPlatform = isWindows();

  if (isWindowsPlatform) {
    const plainText = await value.text();
    const unit16Array = windows1251.encode(plainText, { mode: 'replacement' });

    return new Blob(
      [new Uint8Array(unit16Array.buffer, unit16Array.byteOffset, unit16Array.byteLength)],
      { type: 'text/plain;charset=windows-1251' }
    );
  }

  return new Blob([value], { type: 'text/plain;charset=utf-8' });
};

export const versionTagColors = [
  'rgb(186, 186, 186)',
  'rgb(178, 228, 136)',
  'rgb(142, 225, 214)',
  'rgb(255, 199, 232)',
  'rgb(253, 219, 87)',
  'rgb(223, 197, 124)',
  'rgb(223, 130, 124)',
  'rgb(124, 175, 223)',
  'rgb(179, 124, 223)',
];

export const copyText = text => {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  }

  const input = document.createElement('input');
  input.setAttribute('value', text);
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);

  return Promise.resolve();
};

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getPercent = (value = 0, total = 1, decimalPlaces = 0) =>
  value >= 0 && total > 0 ? ((value / total) * 100).toFixed(decimalPlaces) : 0;

export const getLocationForQueries = () => {
  const localStorageRuLangSelected =
    localStorage.local && localStorage.local.interfaceLocale === 'ru';
  const navigatorRuLangSelected =
    localStorage.local && !localStorage.local.interfaceLocale && /^ru\b/.test(navigator.language);
  return localStorageRuLangSelected || navigatorRuLangSelected ? 'ru' : 'en';
};

export const encodeUrl = str =>
  encodeURIComponent(str).replace(/[!'()*]/g, char => `%${char.charCodeAt(0).toString(16)}`);

export const paramsSerializer = params => {
  const obj = Object.entries(params).reduce((acc, [key, value]) => {
    if (!Array.isArray(value)) {
      acc[key] = encodeUrl(value);
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});

  return qs.stringify(obj, { encode: false, arrayFormat: 'index' });
};

export const getIsMobilePlatform = () => {
  const clientPlatformString = navigator.userAgent || window.opera;
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      clientPlatformString
    ) ||
    // eslint-disable-next-line no-useless-escape
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      clientPlatformString.substring(0, 4)
    )
  );
};

export const moveArrayElement = (array, moveIndex, toIndex) => {
  const item = array[moveIndex];
  const { length } = array;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  }

  if (diff < 0) {
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }

  return array;
};

export const syncedSimulationOmittingFields = [
  'id',
  'creatorId',
  'organizationId',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'workspaceId',
  'versionNumber',
  'scenarioId',
  'doc',
  'testing',
];

export const generateSimulationStepAction = scenarioStep => {
  const conditions =
    scenarioStep.closeConditionList?.length && Array.isArray(scenarioStep.closeConditionList)
      ? scenarioStep.closeConditionList
      : [scenarioStep.closeConditionList];

  const isEntry = conditions.find(event => event === 'value_enter_or_timeout');

  if (isEntry) {
    return 'textInput';
  }

  const isHover = conditions.find(event => event === 'hover');

  if (isHover) {
    return 'mouseMove';
  }

  const isNext = conditions.find(event => event === 'next');

  if (isNext) {
    return 'nextButton';
  }

  return 'leftClick';
};

export const syncSimulationWithScenario = (scenario, simulation) => {
  const preparedSimulation = deepcopy(simulation);

  preparedSimulation.steps = preparedSimulation.steps.map((step, index) => ({
    ...step,
    htmlContent: scenario.steps[index].content || '',
    description: scenario.steps[index].description,
    position: scenario.steps[index].contentPosition.split('-')[0],
    contentJson: scenario.steps[index].contentJson || null,
    action: generateSimulationStepAction(scenario.steps[index]),
  }));

  return preparedSimulation;
};
