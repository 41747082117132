<template>
  <v-container class="profile">
    <v-row>
      <v-col cols="6">
        <v-card flat height="100%">
          <v-card-text>
            <template v-if="!isSmartManualsPlansActive">
              <div class="subtitle-1 grey--text text--darken-4 mb-5">
                {{ $t('pages.profile.organizationData') }}
              </div>

              <v-text-field
                :value="currentUser.organizationName"
                :label="$t('pages.profile.organizationName')"
                outlined
                disabled
                hide-details
                dense
                class="mb-3"
              />

              <v-text-field
                :value="currentUser.organizationSite"
                :label="$t('pages.profile.site')"
                outlined
                disabled
                hide-details
                dense
                class="mb-3"
              />

              <v-text-field
                :value="tariffName"
                :label="$t('pages.profile.plan')"
                outlined
                disabled
                hide-details
                dense
                class="mb-3"
              />

              <v-text-field
                :value="activeUntil"
                :label="$t('pages.profile.activeUntil')"
                outlined
                disabled
                hide-details
                dense
                class="mb-3"
              />

              <v-switch
                v-if="isRealAdminOrHigher"
                v-model="debouncedHideHints"
                hide-details="auto"
                persistent-hint
              >
                <template #label>
                  <div>
                    <div>{{ $t('pages.profile.hideHintsLabel') }}</div>

                    <div class="mt-1 body-2">{{ $t('pages.profile.hideHintsDescription') }}</div>
                  </div>
                </template>
              </v-switch>

              <v-switch
                v-if="isRealAdminOrHigher && selectedOrganization.integratorId"
                v-model="debouncedIsDisabledIntegratorAccess"
                :label="$t('pages.profile.isDisabledIntegratorAccess')"
                hide-details
              />
            </template>

            <template
              v-if="
                (isEnterprisePlanActive ||
                  isSmartManualsProPlanActive ||
                  isSmartManualsEnterprisePlanActive) &&
                  isRealAdminOrHigher
              "
            >
              <v-switch
                v-if="isEnterprisePlanActive"
                v-model="debouncedModerateContent"
                :label="$t('pages.profile.contentModerating')"
                hide-details
              />

              <div class="subtitle-1 grey--text text--darken-4 mt-4 mb-2">
                {{ $t('pages.profile.logo') }}
              </div>

              <div class="d-flex align-center">
                <img :src="logoUrl || $options.DEFAULT_LOGO" class="profile__logo" />

                <input
                  ref="logoInput"
                  type="file"
                  accept="image/*"
                  class="profile__logo-input"
                  @change="uploadLogo($event)"
                />

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      :disabled="logoUrl === null"
                      v-bind="attrs"
                      @click="logoUrl = null"
                      v-on="on"
                    >
                      <v-icon>
                        mdi-refresh
                      </v-icon>
                    </v-btn>
                  </template>
                  <span> {{ $t('pages.profile.restoreLogo') }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :loading="isBusyUploadLogo"
                      icon
                      v-bind="attrs"
                      @click="$refs.logoInput.click()"
                      v-on="on"
                    >
                      <v-icon>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span> {{ $t('pages.profile.uploadLogo') }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :loading="isBusySaveLogo"
                      :disabled="initialLogoUrl === logoUrl"
                      icon
                      v-bind="attrs"
                      @click="saveLogo()"
                      v-on="on"
                    >
                      <v-icon>
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span> {{ $t('pages.profile.saveLogo') }}</span>
                </v-tooltip>
              </div>

              <span class="body-1 black--text">
                {{ $t('pages.profile.logoHint') }}
              </span>
            </template>

            <template v-if="isAdminOrHigher && !isSmartManualsPlansActive">
              <div class="subtitle-1 grey--text text--darken-4 mt-4 mb-2">
                {{ $t('pages.profile.triggersHistory') }}
              </div>

              <v-btn
                color="primary"
                :loading="isBusySessionRemoving"
                :disabled="isBusySessionRemoving"
                @click="removeClientSessions"
              >
                {{ $t('pages.profile.clearClientSession') }}
              </v-btn>
            </template>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="isPersonalDataCardEnabled" cols="6">
        <v-card flat height="100%">
          <v-card-text>
            <div class="subtitle-1 grey--text text--darken-4 mb-5">
              {{ $t('pages.profile.userData') }}
            </div>

            <v-form v-if="isNamesCardEnabled" @submit.prevent="updateProfile()">
              <v-text-field
                v-if="isFieldAllowed('firstname')"
                v-model="firstname"
                :label="$t('pages.profile.firstname')"
                outlined
                hide-details
                dense
                class="mb-3"
              />

              <v-text-field
                v-if="isFieldAllowed('lastname')"
                v-model="lastname"
                :label="$t('pages.profile.lastname')"
                outlined
                hide-details
                dense
                class="mb-3"
              />

              <v-select
                v-if="isFieldAllowed('locale')"
                v-model="locale"
                :items="localesOptions"
                :label="$t('pages.profile.locale')"
                outlined
                hide-details
                dense
                class="mb-3"
              />

              <div class="text-right">
                <v-btn
                  :loading="isBusyProfile"
                  :disabled="isBusyProfile"
                  color="primary"
                  type="submit"
                >
                  {{ $t('ui.common.save') }}
                </v-btn>
              </div>
            </v-form>

            <v-form
              v-if="!hasLDAP && isFieldAllowed('password')"
              ref="changePasswordForm"
              class="mt-8"
              @submit.prevent="changePassword()"
            >
              <v-text-field
                v-model="oldPassword"
                :rules="[rules.getRequiredRule(), rules.passwordLength]"
                :error-messages="passwordError ? [passwordError] : []"
                :label="$t('pages.profile.oldPassword')"
                outlined
                dense
                hide-details="auto"
                type="password"
                class="mb-3"
                @input="passwordError = null"
              />

              <v-text-field
                v-model="newPassword"
                :rules="[rules.getRequiredRule(), rules.passwordLength]"
                :error-messages="newPasswordError ? [newPasswordError] : []"
                :label="$t('pages.profile.newPassword')"
                outlined
                dense
                hide-details="auto"
                type="password"
                class="mb-3"
                @input="newPasswordError = null"
              />

              <v-text-field
                v-model="repeatPassword"
                :rules="[rules.getRequiredRule(), rules.passwordLength, rules.passwordRepeat]"
                :label="$t('pages.profile.repeatPassword')"
                outlined
                dense
                hide-details="auto"
                type="password"
                class="mb-3"
              />

              <div class="text-right">
                <v-btn
                  :loading="isBusyPassword"
                  :disabled="isBusyPassword"
                  color="primary"
                  type="submit"
                >
                  {{ $t('ui.common.save') }}
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="showedPasswordSnackbar" color="success" timeout="3000">
      {{ $t('pages.profile.passwordChanged') }}

      <template #action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showedPasswordSnackbar = false">
          {{ $t('ui.common.close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="showedUserSnackbar" color="success" timeout="3000">
      {{ $t('pages.profile.settingsSaved') }}

      <template #action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showedUserSnackbar = false">
          {{ $t('ui.common.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import debounce from 'lodash.debounce';

  import api from 'src/api';
  import { i18n, currentLocale } from 'src/plugins/i18n';
  import { busyFlow } from 'src/utils';
  import localStorage from 'src/services/localStorage';

  import { buildSpecConfig } from 'src/config';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  const DEFAULT_LOCALE = 'default';

  export default {
    name: 'Profile',

    mixins: [formatTimestampMixin],

    DEFAULT_LOGO: buildSpecConfig.logoUrl,

    data() {
      return {
        selectedOrganization: {},

        isBusyProfile: false,
        isBusyPassword: false,

        firstname: null,
        lastname: null,
        showedUserSnackbar: false,
        locale: localStorage.get('interfaceLocale') || DEFAULT_LOCALE,

        oldPassword: null,
        newPassword: null,
        repeatPassword: null,
        passwordError: null,
        newPasswordError: null,
        showedPasswordSnackbar: false,

        hideHints: false,
        moderateContent: false,
        isDisabledIntegratorAccess: false,
        initialLogoUrl: null,
        logoUrl: null,
        isBusyUploadLogo: false,
        isBusySaveLogo: false,
        isBusySessionRemoving: false,

        rules: {
          getRequiredRule: name => v =>
            !!v ||
            (name
              ? this.$t('validation.notNullField', {
                  field: name,
                })
              : this.$t('validation.notNull')),
          passwordLength: v => (v && v.length >= 6) || this.$t('validation.passwordLength'),
          passwordRepeat: v => v === this.newPassword || this.$t('validation.passwordsDiff'),
        },
      };
    },

    computed: {
      ...mapGetters('users', [
        'isSuperAdmin',
        'isRealAdminOrHigher',
        'isAdminOrHigher',
        'isEnterprisePlanActive',
        'isSmartManualsPlansActive',
        'isSmartManualsProPlanActive',
        'isSmartManualsEnterprisePlanActive',
      ]),
      ...mapState('users', ['currentUser']),
      ...mapState('settings', ['hasLDAP', 'allowedUserInfoFields']),

      tariffName() {
        return (
          this.currentUser.organizationPlan.charAt(0).toUpperCase() +
          this.currentUser.organizationPlan.slice(1)
        );
      },

      activeUntil() {
        return this.formatDate(this.currentUser.organizationActiveUntil);
      },

      localesOptions() {
        return [
          {
            value: 'default',
            text: this.$t('common.locales.default'),
          },
          {
            value: 'ru',
            text: 'Русский',
          },
          {
            value: 'en',
            text: 'English',
          },
          {
            value: 'es',
            text: 'Español',
          },
          {
            value: 'pt',
            text: 'Português',
          },
        ];
      },

      isNamesCardEnabled() {
        const fields = ['firstname', 'lastname'];
        return (
          this.allowedUserInfoFields === 'all' ||
          this.isSuperAdmin ||
          this.allowedUserInfoFields.some(r => fields.includes(r))
        );
      },

      isPersonalDataCardEnabled() {
        const fields = ['firstname', 'lastname', 'password', 'email'];
        return (
          this.allowedUserInfoFields === 'all' ||
          this.isSuperAdmin ||
          this.allowedUserInfoFields.some(r => fields.includes(r))
        );
      },

      debouncedModerateContent: {
        get() {
          return this.moderateContent;
        },

        set(value) {
          this.debouncedUpdateModerateContent(value);
        },
      },

      debouncedHideHints: {
        get() {
          return this.hideHints;
        },

        set(value) {
          this.debouncedUpdateHideHints(value);
        },
      },

      debouncedIsDisabledIntegratorAccess: {
        get() {
          return this.isDisabledIntegratorAccess;
        },

        set(value) {
          this.debouncedUpdateDisabledIntegratorAccess(value);
        },
      },
    },

    async mounted() {
      this.firstname = this.currentUser.firstname;
      this.lastname = this.currentUser.lastname;

      await Promise.all([this.getSettings(), this.getSelectedOrganization()]);
    },

    methods: {
      ...mapActions('users', ['getCurrentUser']),
      ...mapActions('upload', ['uploadFile']),

      async getSelectedOrganization() {
        const { response } = await api.organizations.me();

        this.selectedOrganization = Object.freeze(response);
      },

      async getSettings() {
        const { response } = await api.organizations.getSettings({
          url_params: { id: this.currentUser.organizationId },
        });
        this.hideHints = response.hideHints;
        this.moderateContent = response.moderateContent;
        this.isDisabledIntegratorAccess = response.isDisabledIntegratorAccess;
        this.logoUrl = response.logoUrl;
        this.initialLogoUrl = response.logoUrl;
      },

      updateLocale() {
        const { locale } = this;

        if (locale === DEFAULT_LOCALE) {
          localStorage.remove('interfaceLocale');
          i18n.locale = currentLocale;
        } else {
          localStorage.set('interfaceLocale', locale);
          i18n.locale = locale;
        }

        this.$vuetify.lang.current = i18n.locale;
      },

      updateProfile() {
        busyFlow.call(
          this,
          async () => {
            await api.users.updateCurrent({
              data: {
                firstname: this.firstname || null,
                lastname: this.lastname || null,
              },
            });

            this.updateLocale();

            this.showedUserSnackbar = true;

            this.getCurrentUser();
          },
          false,
          'isBusyProfile'
        );
      },

      changePassword() {
        this.passwordError = null;

        if (!this.$refs.changePasswordForm.validate()) {
          return;
        }

        busyFlow.call(
          this,
          async () => {
            try {
              await api.users.changePassword({
                data: {
                  oldPassword: this.oldPassword,
                  newPassword: this.newPassword,
                },
              });

              this.oldPassword = null;
              this.newPassword = null;
              this.repeatPassword = null;

              this.$refs.changePasswordForm.resetValidation();

              this.showedPasswordSnackbar = true;
            } catch (error) {
              if (error.code === 'PASSWORD_USED_BEFORE') {
                this.newPasswordError = this.$t(`serverCode.PASSWORD_USED_BEFORE`);
                return;
              }
              this.passwordError = this.$t(`serverCode.${error.code}`);
            }
          },
          false,
          'isBusyPassword'
        );
      },

      isFieldAllowed(fieldName) {
        if (this.allowedUserInfoFields === 'all' || this.isSuperAdmin) {
          return true;
        }

        return this.allowedUserInfoFields.includes(fieldName);
      },

      debouncedUpdateModerateContent: debounce(async function updateModerateContent(value) {
        this.moderateContent = value;
        await api.organizations.updateSettings({
          data: { moderateContent: value },
        });
      }, 300),

      debouncedUpdateHideHints: debounce(async function updateHideHints(value) {
        this.hideHints = value;
        await api.organizations.updateSettings({
          data: { hideHints: value },
        });
      }, 300),

      debouncedUpdateDisabledIntegratorAccess: debounce(
        async function updateDisabledIntegratorAccess(value) {
          this.isDisabledIntegratorAccess = value;
          await api.organizations.updateSettings({
            data: { isDisabledIntegratorAccess: value },
          });
        },
        300
      ),

      uploadLogo($event) {
        const [file] = $event.target.files;

        if (!file) {
          return;
        }

        busyFlow.call(
          this,
          async () => {
            this.logoUrl = await this.uploadFile(file);

            this.$refs.logoInput.value = '';
          },
          false,
          'isBusyUploadLogo'
        );
      },

      saveLogo() {
        busyFlow.call(
          this,
          async () => {
            await api.organizations.updateSettings({
              data: { logoUrl: this.logoUrl },
            });

            this.initialLogoUrl = this.logoUrl;
          },
          false,
          'isBusySaveLogo'
        );
      },

      removeClientSessions() {
        busyFlow.call(
          this,
          () => api.clientSessions.removeClientSessions(),
          false,
          'isBusySessionRemoving'
        );
      },
    },
  };
</script>

<style lang="scss">
  .profile {
    &__logo-input {
      display: none;
    }

    &__logo {
      max-width: 100px;
      max-height: 28px;
      object-fit: contain;
    }
  }
</style>
