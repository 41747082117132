<template>
  <div class="inline-color-picker">
    <v-text-field
      v-model="currentColor"
      :disabled="isDisabled"
      hide-details
      dense
      solo
      class="ma-0 pa-0"
    >
      <template #append>
        <v-menu
          v-model="menu"
          :disabled="isDisabled"
          top
          nudge-bottom="105"
          nudge-left="16"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <div :style="swatchStyle" v-on="on" />
          </template>

          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker v-model="currentColor" :disabled="isDisabled" hide-inputs flat />
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>

<script>
  export default {
    name: 'InlineColorPicker',

    props: {
      color: { type: String, default: '#000000FF' },
      isDisabled: Boolean,
    },

    data() {
      return {
        menu: false,
      };
    },

    computed: {
      currentColor: {
        get() {
          return this.color;
        },

        set(value) {
          this.$emit('update:color', value);
        },
      },

      swatchStyle() {
        const { currentColor, menu } = this;
        return {
          backgroundColor: currentColor,
          cursor: 'pointer',
          height: '23px',
          width: '23px',
          borderRadius: menu ? '50%' : '4px',
          transition: 'border-radius 200ms ease-in-out',
          border: '1px solid rgba(0, 0, 0, 0.07)',
        };
      },
    },
  };
</script>

<style scoped>
  .inline-color-picker {
    margin: 10px 0;
    color: #535353;
    max-width: 180px;
  }
</style>
