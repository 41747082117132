import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import {
  CLEAR_VERSIONS_DATA,
  SET_ENABLED_CREATING_MODE,
  SET_SELECTED_VERSION_ID,
  SET_VERSION_TAGS,
  SET_VERSIONS_ENTITY,
  SET_VERSIONS_ENTITY_TYPE,
  UPDATE_VERSION_DATA,
} from 'src/store/mutation-types';

import { deepcopy, versionTagColors } from 'src/utils';

export default {
  computed: {
    ...mapState('versions', [
      'creatingModeEnabled',
      'entity',
      'entityType',
      'isBusyUpdatingVersion',
      'isBusySelectedVersion',
      'isBusyVersionsList',
      'selectedVersionData',
      'versionsList',
      'selectedVersionTags',
      'tags',
    ]),

    ...mapGetters('versions', [
      'hasAccessToVersionControl',
      'selectedVersion',
      'isSelectedActualVersion',
      'versionHasUnsavedChanges',
      'tagsList',
    ]),

    ...mapGetters('users', ['isRealAdminOrHigher']),

    selectedVersionId: {
      get() {
        return this.$store.state.versions.selectedVersionId;
      },
      set(value) {
        this.setSelectedVersionId(value);
      },
    },

    versionTags: {
      get() {
        return this.selectedVersionTags;
      },
      set(value) {
        this.applyTags(value);
      },
    },
  },

  watch: {
    entityType() {
      this.clearVersionsData();
      this.getAllTags();
    },

    selectedVersionId(value) {
      if (value) {
        this.getVersionData();
        this.getVersionTags();
      }
    },

    entity(value) {
      if (value) {
        this.getVersions();
      }
    },
  },

  methods: {
    ...mapActions('versions', [
      'getVersions',
      'getVersionData',
      'getAllTags',
      'getVersionTags',
      'updateVersionTags',
      'update',
      'createTag',
      'deleteTag',
      'rejectDraft',
      'approveDraft',
      'createDraft',
    ]),

    ...mapMutations('versions', {
      setCreatingModeEnabled: SET_ENABLED_CREATING_MODE,
      clearVersionsData: CLEAR_VERSIONS_DATA,
      setVersionEntityType: SET_VERSIONS_ENTITY_TYPE,
      setVersionEntity: SET_VERSIONS_ENTITY,
      updateVersionData: UPDATE_VERSION_DATA,
      setSelectedVersionId: SET_SELECTED_VERSION_ID,
      setVersionTags: SET_VERSION_TAGS,
    }),

    updateTags(tags) {
      this.setVersionTags(tags);
    },

    async createNewTag(title) {
      const color = versionTagColors[Math.floor(Math.random() * versionTagColors.length)];
      const createdTag = await this.createTag({
        title,
        color,
      });

      return createdTag;
    },

    findNewTag(tags) {
      const newTag = tags.find(tag => typeof tag === 'string');

      if (!newTag) {
        return null;
      }

      const alreadySelectedTag = tags.find(
        tag =>
          typeof tag === 'object' && tag.title.toLowerCase().trim() === newTag.toLowerCase().trim()
      );

      if (alreadySelectedTag) {
        return null;
      }

      return newTag;
    },

    async applyTags(tags) {
      let tempTags = deepcopy(tags);

      const newTag = this.findNewTag(tempTags);

      if (!this.isRealAdminOrHigher || !newTag) {
        tempTags = tempTags.filter(tag => typeof tag === 'object');
        this.updateTags(tempTags);

        return;
      }

      try {
        const newTagIndex = tempTags.findIndex(tag => tag === newTag);

        const existsTag = this.tags.find(
          tag => tag.title.toLowerCase().trim() === newTag.toLowerCase().trim()
        );

        if (!existsTag) {
          tempTags[newTagIndex] = await this.createNewTag(newTag);
        } else if (existsTag.isSystemTag) {
          tempTags.splice(newTagIndex, 1);
        } else {
          tempTags[newTagIndex] = { ...existsTag };
        }
      } catch {
        tempTags = tempTags.filter(tag => typeof tag === 'object');
      } finally {
        this.updateTags(tempTags);
      }
    },
  },
};
