const removeClientSessions = {
  method: 'delete',
  name: 'removeClientSessions',
  url: 'client-sessions',
};

export default {
  name: 'clientSessions',
  url: '/api/',
  children: [removeClientSessions],
};
