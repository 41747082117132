const list = {
  name: 'list',
  method: 'get',
  url: '/api/tags/',
};

const create = {
  name: 'create',
  method: 'post',
  url: '/api/tags',
};

const update = {
  name: 'update',
  method: 'patch',
  url: ':id',
};

const remove = {
  name: 'remove',
  method: 'delete',
  url: ':id',
};

export default {
  name: 'tags',
  url: '/api/tags/',
  children: [list, create, update, remove],
};
