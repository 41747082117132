const removeOrganizationStatistics = {
  name: 'removeOrganizationStatistics',
  method: 'delete',
  url: ':id',
};

export default {
  name: 'statistics',
  url: '/api/statistics',
  children: [removeOrganizationStatistics],
};
