<template>
  <v-chip :color="color" label>{{ $t(`common.roles.${normalizedRole}`) }}</v-chip>
</template>

<script>
  export default {
    name: 'UserRoleChip',

    props: {
      role: { type: String, default: '' },
    },

    data() {
      return {
        roleColors: {
          USER: 'cyan lighten-3',
          EDITOR: 'light-blue lighten-3',
          DESIGNER: 'teal lighten-3',
          MODERATOR: 'deep-purple lighten-2',
          ADMIN: 'blue lighten-2',
          SUPERADMIN: 'red lighten-3',
          SERVICE: 'red lighten-3',
        },
      };
    },

    computed: {
      normalizedRole() {
        return this.role.toUpperCase();
      },

      color() {
        return this.roleColors[this.normalizedRole];
      },
    },
  };
</script>
