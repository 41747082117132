<template>
  <v-app id="admin-panel">
    <v-app-bar app clipped-left clipped-right color="white" class="py-3" elevation="0">
      <v-toolbar-title class="header">
        <div class="header-logo">
          <img :src="$options.LOGO_URL" :alt="$options.COMPANY_NAME" class="mt-2 logo" />
        </div>

        <v-icon @click="exit">mdi-exit-to-app</v-icon>
      </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <transition
          name="router-anim"
          enter-active-class="fade-enter-active fade-enter"
          leave-active-class="fade-leave-active fade-enter"
        >
          <router-view />
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
  import { buildSpecConfig } from 'src/config';
  import { mapActions } from 'vuex';

  export default {
    name: 'MobileLayout',

    LOGO_URL: buildSpecConfig.logoUrl,
    COMPANY_NAME: buildSpecConfig.companyName,

    methods: {
      ...mapActions('auth', ['logout']),

      async exit() {
        await this.logout();

        this.$router.push({ name: 'login' });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .logo {
    width: 100px;
  }

  .header {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
  }

  .header-logo {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
</style>
