<template>
  <div>
    <div>
      <div class="block-header block-header--first">
        {{ $t('pages.styles.settings.tooltipStyles') }}
      </div>

      <font-select v-model="fontFamily" />

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="backgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.backgroundColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="color" />
        <div class="ml-4">{{ $t('pages.styles.settings.fontColor') }}</div>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.allSidePadding') }}</div>

        <h-slider v-model="padding" max="30" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="padding"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.horizontalPadding') }}</div>

        <h-slider v-model="horizontalPadding" max="30" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="horizontalPadding"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.verticalPadding') }}</div>

        <h-slider v-model="verticalPadding" max="30" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="verticalPadding"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <template v-if="themeType !== $options.THEME_TYPE_CLASSIC">
        <div class="mb-2">
          <v-switch
            v-model="isBorderEnabled"
            :label="$t('pages.styles.settings.border')"
            hide-details
          />
        </div>

        <template v-if="isBorderEnabled">
          <div class="d-flex align-center mb-3">
            <InlineColorPicker :color.sync="borderColor" />
            <div class="ml-4">{{ $t('pages.styles.settings.borderColor') }}</div>
          </div>

          <div class="input-control mb-3">
            <div>{{ $t('pages.styles.settings.borderWidth') }}</div>

            <h-slider v-model="borderLineWidth" max="10" min="2" hide-details>
              <template #append>
                <v-text-field
                  v-model="borderLineWidth"
                  hide-details
                  dense
                  disabled
                  class="mt-0 pt-0 slider-input"
                />
              </template>
            </h-slider>
          </div>

          <div class="input-control mb-3">
            <div>{{ $t('pages.styles.settings.borderRadius') }}</div>

            <h-slider v-model="borderRadius" max="10" min="2" hide-details>
              <template #append>
                <v-text-field
                  v-model="borderRadius"
                  hide-details
                  dense
                  disabled
                  class="mt-0 pt-0 slider-input"
                />
              </template>
            </h-slider>
          </div>
        </template>
      </template>

      <div class="mb-2">
        <v-switch
          v-model="isShadowEnabled"
          :label="$t('pages.styles.settings.shadow')"
          hide-details
        />
      </div>

      <template v-if="isShadowEnabled">
        <div class="d-flex align-center mb-3">
          <InlineColorPicker :color.sync="shadowColor" />
          <div class="ml-4">{{ $t('pages.styles.settings.shadowColor') }}</div>
        </div>

        <div class="input-control mb-3">
          <div>{{ $t('pages.styles.settings.shadowXOffset') }}</div>

          <h-slider v-model="shadowXOffset" max="50" min="-50" hide-details>
            <template #append>
              <v-text-field
                v-model="shadowXOffset"
                hide-details
                dense
                disabled
                class="mt-0 pt-0 slider-input"
              />
            </template>
          </h-slider>
        </div>

        <div class="input-control mb-3">
          <div>{{ $t('pages.styles.settings.shadowYOffset') }}</div>

          <h-slider v-model="shadowYOffset" max="50" min="-50" hide-details>
            <template #append>
              <v-text-field
                v-model="shadowYOffset"
                hide-details
                dense
                disabled
                class="mt-0 pt-0 slider-input"
              />
            </template>
          </h-slider>
        </div>

        <div class="input-control mb-3">
          <div>{{ $t('pages.styles.settings.shadowBlur') }}</div>

          <h-slider v-model="shadowBlurRadius" max="50" min="0" hide-details>
            <template #append>
              <v-text-field
                v-model="shadowBlurRadius"
                hide-details
                dense
                disabled
                class="mt-0 pt-0 slider-input"
              />
            </template>
          </h-slider>
        </div>

        <div class="input-control mb-3">
          <div>{{ $t('pages.styles.settings.shadowSpreadRadius') }}</div>

          <h-slider v-model="shadowSpreadRadius" max="50" min="0" hide-details>
            <template #append>
              <v-text-field
                v-model="shadowSpreadRadius"
                hide-details
                dense
                disabled
                class="mt-0 pt-0 slider-input"
              />
            </template>
          </h-slider>
        </div>
      </template>

      <div v-if="isEnterprisePlanActive" class="mb-2">
        <v-switch
          v-model="copyrightHidden"
          :label="$t('pages.styles.settings.copyrightHidden')"
          hide-details
        />
      </div>
    </div>

    <div>
      <div class="block-header">
        {{ $t('pages.styles.settings.highlightType') }}
      </div>

      <v-btn-toggle v-model="highlightType" mandatory class="">
        <v-btn value="marker">
          <v-icon>
            mdi-information
          </v-icon>
        </v-btn>
        <v-btn value="underline">
          <div style="width: 55px; height: 2px; background-color: rgba(0, 0, 0, 0.87); " />
        </v-btn>
        <v-btn value="none" class="text-none">
          {{ $t('pages.styles.settings.nonHighlight') }}
        </v-btn>
      </v-btn-toggle>

      <div class="input-control mt-6">
        <h-text-field
          v-if="highlightType === 'marker'"
          v-model.number="showMarkerDelay"
          :label="$t('pages.styles.settings.tooltipShowMarkerDelay')"
          :default-title="$t('common.defaultTitles.notSet')"
          maxlength="3"
          :default-value="0"
          outlined
          hide-details
          persistent-placeholder
          number-input
          positive-number
          class="mt-4"
        />

        <template v-if="highlightType === 'marker'">
          <div class="mt-4">
            {{ $t('pages.styles.settings.tooltipShowAction') }}
          </div>

          <v-btn-toggle v-model="showAction" mandatory class="mt-2">
            <v-btn value="hover" class="text-none">
              <v-icon left>
                mdi-cursor-default-outline
              </v-icon>

              {{ $t('pages.styles.settings.tooltipByHover') }}
            </v-btn>

            <v-btn value="click" class="text-none">
              <v-icon left>
                mdi-cursor-default-click-outline
              </v-icon>

              {{ $t('pages.styles.settings.tooltipByClick') }}
            </v-btn>
          </v-btn-toggle>
        </template>

        <h-text-field
          v-if="
            (highlightType === 'marker' && showAction !== 'click') || highlightType !== 'marker'
          "
          v-model.number="showDelay"
          :label="$t('pages.styles.settings.tooltipShowDelay')"
          :default-title="$t('common.defaultTitles.notSet')"
          maxlength="3"
          :default-value="0"
          outlined
          hide-details
          persistent-placeholder
          number-input
          positive-number
          class="mt-6"
        />
      </div>
    </div>

    <div>
      <template v-if="highlightType === 'marker'">
        <div class="block-header">
          {{ $t('pages.styles.settings.iconStyles') }}
        </div>

        <div class="d-flex align-center mb-3">
          <InlineColorPicker :color.sync="hotspotBackgroundColor" />
          <div class="ml-4">{{ $t('pages.styles.settings.iconBackgroundColor') }}</div>
        </div>

        <div class="d-flex align-center mb-3">
          <InlineColorPicker :color.sync="hotspotColor" />
          <div class="ml-4">{{ $t('pages.styles.settings.iconColor') }}</div>
        </div>

        <div class="input-control mb-3">
          <div>{{ $t('pages.styles.settings.opacity') }}</div>

          <h-slider v-model="hotspotOpacity" max="1" min="0" step="0.1" hide-details>
            <template #append>
              <v-text-field
                :value="hotspotOpacity"
                hide-details
                dense
                disabled
                class="mt-0 pt-0 slider-input"
              />
            </template>
          </h-slider>
        </div>

        <div class="input-control mb-3">
          <div>{{ $t('pages.styles.settings.iconSize') }}</div>

          <h-slider v-model="hotspotSize" max="50" min="15" hide-details>
            <template #append>
              <v-text-field
                :value="hotspotSize"
                hide-details
                dense
                disabled
                class="mt-0 pt-0 slider-input"
              />
            </template>
          </h-slider>
        </div>
      </template>

      <template v-if="highlightType === 'underline'">
        <div class="block-header">
          {{ $t('pages.styles.settings.underlineStyles') }}
        </div>

        <div class="d-flex align-center mb-3">
          <InlineColorPicker :color.sync="underlineBackgroundColor" />
          <div class="ml-4">{{ $t('pages.styles.settings.underlineColor') }}</div>
        </div>

        <div class="input-control mb-3">
          <div>{{ $t('pages.styles.settings.opacity') }}</div>

          <h-slider v-model="underlineOpacity" max="1" min="0" step="0.1" hide-details>
            <template #append>
              <v-text-field
                :value="underlineOpacity"
                hide-details
                dense
                disabled
                class="mt-0 pt-0 slider-input"
              />
            </template>
          </h-slider>
        </div>

        <div class="input-control mb-3">
          <div>{{ $t('pages.styles.settings.underlineSize') }}</div>

          <h-slider v-model="underlineSize" max="30" min="1" hide-details>
            <template #append>
              <v-text-field
                :value="underlineSize"
                hide-details
                dense
                disabled
                class="mt-0 pt-0 slider-input"
              />
            </template>
          </h-slider>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import InlineColorPicker from 'src/components/InlineColorPicker.vue';
  import HSlider from 'src/HSlider/HSlider.vue';
  import HTextField from 'src/components/HTextField/HTextField.vue';

  import stylesMixin from '../../mixins/stylesMixin';

  import { generateDynamicFields, THEME_TYPE_CLASSIC } from '../../utils';

  import FontSelect from '../FontSelect.vue';

  export default {
    name: 'TooltipAdvancedStyles',

    components: {
      InlineColorPicker,
      HSlider,
      HTextField,
      FontSelect,
    },

    mixins: [stylesMixin],

    THEME_TYPE_CLASSIC,

    computed: {
      ...mapGetters('users', ['isEnterprisePlanActive']),

      ...generateDynamicFields([
        'backgroundColor',
        'borderColor',
        'borderLineWidth',
        'borderRadius',
        'color',
        'fontFamily',
        'highlightType',
        'hotspotBackgroundColor',
        'hotspotBorderRadius',
        'hotspotColor',
        'hotspotOpacity',
        'hotspotSize',
        'isBorderEnabled',
        'isShadowEnabled',
        'shadowBlurRadius',
        'shadowColor',
        'shadowSpreadRadius',
        'shadowXOffset',
        'shadowYOffset',
        'underlineBackgroundColor',
        'underlineOpacity',
        'underlineSize',
        'showAction',
      ]),

      ...generateDynamicFields(['copyrightHidden'], 'updateSettings', 'settings'),

      padding: {
        get() {
          const { paddingLeft, paddingRight, paddingTop, paddingBottom } = this.value;

          if (
            paddingLeft !== paddingRight ||
            paddingLeft !== paddingTop ||
            paddingLeft !== paddingBottom
          ) {
            return 0;
          }

          return paddingLeft;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingLeft: value,
            paddingRight: value,
            paddingTop: value,
            paddingBottom: value,
          });
        },
      },

      horizontalPadding: {
        get() {
          const { paddingLeft, paddingRight } = this.value;

          if (paddingLeft !== paddingRight) {
            return 0;
          }

          return paddingLeft;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingLeft: value,
            paddingRight: value,
          });
        },
      },

      verticalPadding: {
        get() {
          const { paddingTop, paddingBottom } = this.value;

          if (paddingTop !== paddingBottom) {
            return 0;
          }

          return paddingTop;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingTop: value,
            paddingBottom: value,
          });
        },
      },

      showMarkerDelay: {
        get() {
          return this.value.showMarkerDelay / 1000;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            showMarkerDelay: (value || 0) * 1000,
          });
        },
      },

      showDelay: {
        get() {
          return this.value.showDelay / 1000;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            showDelay: (value || 0) * 1000,
          });
        },
      },
    },

    watch: {
      highlightType(value) {
        if (value !== 'marker' && this.showAction !== 'hover') {
          this.showAction = 'hover';
        }
      },
    },
  };
</script>
