<template>
  <v-card class="integrator-balance-modal">
    <v-card-title class="pa-4 primary">
      <span class="ma-1 title white--text">{{ $t('ui.manageTagsModal.title') }}</span>

      <v-spacer />

      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <div>
      <v-tabs v-model="selectedMode" fixed-tabs height="54" style="padding: 15px 24px 0 24px">
        <v-tab class="text-none">
          {{ $t('ui.manageTagsModal.replaceTagsTab') }}
        </v-tab>

        <v-tab class="text-none">
          {{ $t('ui.manageTagsModal.addTagsTab') }}
        </v-tab>

        <v-tab class="text-none">
          {{ $t('ui.manageTagsModal.removeTagsTab') }}
        </v-tab>
      </v-tabs>
    </div>

    <replace-tags-tab
      v-if="selectedMode === $options.TAB_DEFAULT"
      @updated="updateReplaceTagsVars"
    />

    <add-tag-tab v-if="selectedMode === $options.TAB_ADD" @updated="updateAddTagsVars" />

    <remove-tag-tab v-if="selectedMode === $options.TAB_REMOVE" @updated="updateRemoveTagsVars" />

    <v-card-actions>
      <v-spacer />

      <v-btn color="grey" text @click="$emit('close')">
        {{ $t('ui.common.cancel') }}
      </v-btn>

      <v-btn :loading="isBusy" color="primary" @click="update()">
        {{ $t('ui.common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex';

  import api from 'src/api';

  import ReplaceTagsTab from 'src/components/ManageTagsModal/components/ReplaceTagsTab.vue';
  import RemoveTagTab from 'src/components/ManageTagsModal/components/RemoveTagTab.vue';
  import AddTagTab from 'src/components/ManageTagsModal/components/AddTagTab.vue';

  import { busyFlow } from 'src/utils';

  export default {
    name: 'ManageTagsModal',

    components: {
      ReplaceTagsTab,
      RemoveTagTab,
      AddTagTab,
    },

    props: {
      entityType: {
        type: String,
        required: false,
        default: 'scenarios',
      },

      entities: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        isBusy: false,

        tags: [],

        unbindTag: null,
        bindTag: null,

        bindTagsArr: [],
        unbindTagsArr: [],

        deletingTagId: null,

        selectedMode: 0,
      };
    },

    TAB_DEFAULT: 0,
    TAB_ADD: 1,
    TAB_REMOVE: 2,

    computed: {
      ...mapGetters('users', ['isRealAdminOrHigher']),

      entitiesIdList() {
        return this.entities.map(entity => entity.id);
      },

      filteredTags() {
        return this.tags.filter(tag => !tag.isSystemTag);
      },
    },

    watch: {
      selectedMode: {
        immediate: true,
        async handler() {
          this.unbindTag = null;
          this.bindTag = null;
        },
      },
    },

    mounted() {
      this.getTags();
    },

    methods: {
      async getTags() {
        const { response } = await api.tags.list();

        this.tags = response;
      },

      update() {
        if (this.selectedMode === this.$options.TAB_ADD) {
          this.bind();

          return;
        }

        if (this.selectedMode === this.$options.TAB_REMOVE) {
          this.unbind();

          return;
        }

        if ((!this.bindTag && !this.unbindTag) || this.bindTag?.id === this.unbindTag?.id) {
          this.$emit('close');

          return;
        }

        this.replace();
      },

      unbind() {
        busyFlow.call(this, async () => {
          await api[this.entityType].unbindTag({
            data: {
              tagsIds: this.unbindTagsArr,
              idList: this.entitiesIdList,
            },
          });

          this.$emit('close');
        });
      },

      bind() {
        busyFlow.call(this, async () => {
          await api[this.entityType].bindTag({
            data: {
              tagsIds: this.bindTagsArr,
              idList: this.entitiesIdList,
            },
          });

          this.$emit('close');
        });
      },

      replace() {
        busyFlow.call(this, async () => {
          await api[this.entityType].replaceTag({
            data: {
              oldTagId: this.unbindTag.id,
              newTagId: this.bindTag.id,
              idList: this.entitiesIdList,
            },
          });

          this.$emit('close');
        });
      },

      updateReplaceTagsVars({ oldTag = null, newTag = null }) {
        this.unbindTag = oldTag;
        this.bindTag = newTag;
      },

      updateAddTagsVars(newTags = []) {
        this.bindTagsArr = newTags;
      },

      updateRemoveTagsVars(oldTags = []) {
        this.unbindTagsArr = oldTags;
      },
    },
  };
</script>
