<template>
  <v-menu
    v-model="menuOpened"
    :close-on-content-click="false"
    nudge-width="476"
    offset-y
    class="share-popup"
  >
    <template #activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn icon small v-bind="attrs" v-on="{ ...tooltip, ...menu }">
            <v-icon small>
              mdi-share-variant-outline
            </v-icon>
          </v-btn>
        </template>

        <span>{{ $t('pages.simulations.sharePopup.tooltip') }}</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-tabs v-model="tab" class="mx-4">
        <v-tab>
          {{ $t('pages.simulations.sharePopup.invite') }}
        </v-tab>

        <v-tab v-if="embedCodeFeatureAvailable">
          Embed
        </v-tab>

        <plan-upgrade-menu v-else top offset-y nudge-top="-10" nudge-right="-150">
          <template #activator="{on, attrs}">
            <div v-bind="attrs" class="share-popup__tab-wrapper" v-on="on">
              <v-tab disabled class="share-popup__disabled-tab">
                Embed

                <plan-upgrade-chip class="ml-3" />
              </v-tab>
            </div>
          </template>
        </plan-upgrade-menu>
      </v-tabs>

      <v-form v-if="tab === 0" class="pa-6" @submit.prevent="invite()">
        <v-row class="ma-0">
          <v-text-field
            ref="input"
            v-model.trim="email"
            :error-messages="emailError"
            :label="$t('pages.simulations.sharePopup.inputLabel')"
            outlined
            dense
            hide-details="auto"
          />

          <v-btn
            color="primary"
            height="40"
            type="submit"
            :loading="isBusy"
            :disabled="isBusy"
            class="ml-3"
          >
            {{ $t('pages.simulations.sharePopup.invite') }}
          </v-btn>
        </v-row>
      </v-form>

      <div v-else class="pa-6">
        <v-row class="ma-0">
          <v-textarea
            hide-details
            outlined
            readonly
            no-resize
            :label="$t('pages.simulations.sharePopup.embedCodeLabel')"
            :value="embedCode"
            rows="3"
          />
        </v-row>

        <v-row class="ma-0 mt-3" justify="end">
          <v-btn :disabled="embedCopied" color="primary" @click="copyEmbedCode()">
            <v-icon left>
              mdi-code-tags
            </v-icon>

            <span v-if="!embedCopied">
              {{ $t('pages.simulations.sharePopup.embedCodeCopyButton') }}
            </span>

            <span v-else>
              {{ $t('pages.simulations.sharePopup.embedCodeCopied') }}
            </span>
          </v-btn>
        </v-row>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
  import { mapGetters } from 'vuex';

  import { copyText, emailRegex } from 'src/utils';

  import api from 'src/api';

  import PlanUpgradeMenu from 'src/components/PlanUpgradeMenu/PlanUpgradeMenu.vue';
  import PlanUpgradeChip from 'src/components/PlanUpgradeChip/PlanUpgradeChip.vue';

  export default {
    name: 'SharePopup',

    components: {
      PlanUpgradeMenu,
      PlanUpgradeChip,
    },

    props: {
      id: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        tab: 0,

        menuOpened: false,

        email: '',
        emailError: null,

        isBusy: false,

        embedCopied: false,
      };
    },

    computed: {
      ...mapGetters('users', [
        'isHintedPlansActive',
        'isSmartManualsProPlanActive',
        'isSmartManualsEnterprisePlanActive',
      ]),

      embedCode() {
        const { href } = this.$router.resolve({ name: 'SimulationView', params: { id: this.id } });
        const { origin } = window.location;
        const link = origin + href;

        return `<iframe width="720" height="640" src="${link}" ></iframe>`;
      },

      embedCodeFeatureAvailable() {
        return (
          this.isHintedPlansActive ||
          this.isSmartManualsProPlanActive ||
          this.isSmartManualsEnterprisePlanActive
        );
      },
    },

    watch: {
      menuOpened(value) {
        if (!value) {
          this.email = '';
          this.emailError = null;
        }
      },
    },

    methods: {
      async invite() {
        this.emailError = null;

        if (!this.email) {
          return;
        }

        if (!this.email.match(emailRegex)) {
          this.emailError = this.$t('validation.emailInvalid');

          return;
        }

        this.isBusy = true;

        try {
          await api.simulations.sendInvite({
            url_params: { id: this.id },
            data: { email: this.email },
          });

          this.email = '';
          this.menuOpened = false;

          this.$emit('invited');
        } catch (error) {
          this.emailError = this.$te(`serverCode.${error.code}`)
            ? this.$t(`serverCode.${error.code}`)
            : this.$t('serverCode.serverError');
        } finally {
          this.isBusy = false;
        }
      },

      copyEmbedCode() {
        if (this.embedCopied) {
          return;
        }

        copyText(this.embedCode);

        this.embedCopied = true;
        setTimeout(() => {
          this.embedCopied = false;
        }, 3000);
      },
    },
  };
</script>

<style lang="scss">
  .share-popup {
    &__tab-wrapper {
      display: flex;
      align-items: center;
    }

    &__disabled-tab {
      opacity: 1 !important;
      color: rgba(0, 0, 0, 0.2) !important;
    }
  }
</style>
