<template>
  <v-card class="mb-3">
    <v-card-text class="pa-2">
      <div class="d-flex align-center">
        <h5 class="body-1 blue-grey--text text--darken-2">{{ title }}</h5>

        <div class="ml-auto">
          <v-btn icon dark color="error" :disabled="isButtonDisabled" @click="onButtonClick()">
            <feather :type="icon" />
          </v-btn>
        </div>
      </div>

      <p class="subtitle-2 font-weight-light mb-0">{{ subtitle }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'AddingFormItem',

    props: {
      title: { type: String, default: '' },
      subtitle: { type: String, default: '' },
      icon: { type: String, default: '' },
      isButtonDisabled: Boolean,
    },

    methods: {
      onButtonClick(event) {
        this.$emit('click', event);
      },
    },
  };
</script>
