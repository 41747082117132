var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-5"},[_c('v-card-text',{staticClass:"pa-5"},[_c('div',{staticClass:"d-sm-flex align-center"},[_c('div',[_c('h3',{staticClass:"title font-weight-regular"},[_c('span',{staticClass:"lstick"}),_vm._v(" "+_vm._s(_vm.$t('pages.statistics.stepsErrors.errorsHistory'))+": ")])])]),_c('div',{staticClass:"mt-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.scenarioErrorsHistory,"loading":_vm.isBusy,"server-items-length":_vm.count,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('travolta-not-found')]},proxy:true},{key:"item.stepIndex",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"info"}},'v-icon',attrs,false),on),[_vm._v("mdi-link-variant")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.origin))])]),_vm._v(" "+_vm._s(_vm.$t('pages.statistics.stepsErrors.step'))+" "+_vm._s(item.stepIndex + 1)+": "+_vm._s(item.scenarioTitle)+" ")]}},{key:"item.error",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.getErrorText(item.code)))])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }