<template>
  <v-card class="desktop-install">
    <v-container>
      <v-card-text class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <div class="desktop-install__icon-wrapper">
            <img width="43" height="43" :src="$options.DESKTOP_LOGO" :alt="$options.DESKTOP_NAME" />
          </div>

          <div class="ml-5">
            <div class="desktop-install__title">{{ $options.DESKTOP_NAME }}</div>

            <div class="desktop-install__subtitle">
              {{ $t('pages.install.desktop.description') }}
            </div>
          </div>
        </div>

        <v-btn :href="downloadLink" target="_blank" color="primary" large class="ml-3">
          <v-icon left>mdi-download</v-icon>

          {{ $t('pages.install.desktop.downloadWindowsButton') }}
        </v-btn>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
  import { apiUrl, buildSpecConfig } from 'src/config';

  export default {
    name: 'DesktopInstall',

    DESKTOP_NAME: buildSpecConfig.desktopName,
    DESKTOP_LOGO: buildSpecConfig.desktopLogoUrl,

    computed: {
      downloadLink() {
        const urlInstance = new URL('/plugins/desktop/hinted.exe', apiUrl);
        return urlInstance.href;
      },
    },
  };
</script>

<style lang="scss">
  .desktop-install {
    color: #343349;

    &__icon-wrapper {
      width: 87px;
      height: 87px;
      background: rgba(216, 17, 89, 0.1);
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__title {
      font-size: 24px;
      line-height: 32px;
    }

    &__subtitle {
      font-size: 16px;
      line-height: 24px;
      max-width: 520px;
    }
  }
</style>
