<template>
  <v-app class="payment-required">
    <VerticalHeader read-only />

    <v-main>
      <v-container class="fill-height justify-center">
        <v-card width="630">
          <v-form ref="form" @submit.prevent="send()">
            <v-card-text class="grey--text text--darken-3 pa-10">
              <h4 class="font-weight-bold grey--text text--darken-4">
                {{ $t('pages.paymentRequired.title') }}
              </h4>

              <div v-if="isSent" class="body-1 mt-6 payment-required__text">
                {{ $t('pages.paymentRequired.textAfterSent') }}
              </div>

              <template v-else>
                <div class="body-1 mt-6 payment-required__text">
                  {{ $t('pages.paymentRequired.text') }}
                </div>

                <v-text-field
                  v-model="phone"
                  :label="$t('pages.paymentRequired.phone')"
                  outlined
                  hide-details
                  dense
                  class="mt-8"
                />

                <v-text-field
                  v-model="email"
                  label="Email"
                  outlined
                  :rules="[rules.getRequiredRule('Email')]"
                  dense
                  class="mt-4"
                />

                <div class="d-flex align-center mt-4 ">
                  <v-btn color="primary" type="submit" dark class="text-none">
                    {{ $t('pages.paymentRequired.buttonText') }}
                  </v-btn>

                  <span class="mx-3"> {{ $t('pages.paymentRequired.orText') }}</span>

                  <a
                    :href="`mailto:${salesEmail}`"
                    target="_blank"
                    class="text-decoration-underline"
                  >
                    {{ $t('pages.paymentRequired.sendMail') }}
                  </a>
                </div>
              </template>

              <div class="caption mt-8">
                {{ $t('pages.paymentRequired.supportText') }}
                <a
                  :href="`mailto:${organizationSupportEmail}`"
                  target="_blank"
                  class="text-decoration-underline grey--text text--darken-4"
                >
                  {{ organizationSupportEmail }}
                </a>
              </div>
            </v-card-text>
          </v-form>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import { mapState } from 'vuex';

  import api from 'src/api';

  import localStorage from 'src/services/localStorage';

  import VerticalHeader from 'src/components/VerticalHeader/VerticalHeader.vue';

  export default {
    name: 'PaymentRequired',

    components: {
      VerticalHeader,
    },

    data() {
      return {
        isSent: false,
        phone: localStorage.get('loginPhone'),
        email: localStorage.get('loginEmail'),
        organizationId: localStorage.get('loginOrganizationId'),
        rules: {
          getRequiredRule: name => v =>
            !!v ||
            this.$t('validation.notNullField', {
              field: name,
            }),
        },
      };
    },

    computed: {
      ...mapState('settings', ['supportEmail']),

      salesEmail() {
        return localStorage.get('supportEmail') || 'sales@hinted.me';
      },

      organizationSupportEmail() {
        return localStorage.get('supportEmail') || this.supportEmail;
      },

      isDemo() {
        return this.$route.query.isDemo === 'true';
      },
    },

    created() {
      if (!this.$route.query.redirected) {
        this.$router.push({ name: 'Dashboard' });
      } else {
        this.$router.push({ name: 'PaymentRequired', query: { isDemo: this.$route.query.isDemo } });
      }
    },

    methods: {
      async send() {
        if (!this.$refs.form.validate()) {
          return;
        }

        try {
          await api.organizations.sendFeedback({
            data: {
              locale: this.$i18n.locale,
              ...(this.organizationId ? { organizationId: this.organizationId } : {}),
              fields: [
                {
                  title: this.$t('pages.paymentRequired.emailSubjectTitle'),
                  value: this.isDemo
                    ? this.$t('pages.paymentRequired.emailDemoSubject')
                    : this.$t('pages.paymentRequired.emailSubject'),
                },
                ...(this.phone
                  ? { title: this.$t('pages.paymentRequired.phone'), value: this.phone }
                  : []),
                ...(this.email ? { title: this.$t('common.fields.email'), value: this.email } : []),
              ],
            },
          });
        } finally {
          this.isSent = true;
        }
      },
    },
  };
</script>

<style lang="scss">
  .payment-required {
    &__text {
      max-width: 520px;
    }
  }
</style>
