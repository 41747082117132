<template>
  <v-list flat dense class="mt-0 pa-0 pt-0">
    <v-radio-group :value="status" @change="$emit('updated', $event)">
      <div class="px-4 py-3 firstItem">
        <button class="font-select__button" @click="$emit('back')">
          {{ $t('pages.scenarios.filters.back') }}
        </button>

        <button class="font-select__button" @click="resetFilters()">
          {{ $t('pages.scenarios.filters.reset') }}
        </button>
      </div>

      <v-list-item>
        <v-list-item-action>
          <v-radio :value="'all'" color="primary" />
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{{ $t('pages.scenarios.filters.all') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-action>
          <v-radio :value="true" color="primary" />
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{{ $t('pages.scenarios.filters.published') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-action>
          <v-radio :value="false" color="primary" />
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{{ $t('pages.scenarios.filters.unpublished') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-radio-group>
  </v-list>
</template>

<script>
  export default {
    name: 'PublishStatusFilter',

    props: {
      currentFilter: {
        type: [Boolean, String],
        default: 'all',
      },
    },

    data() {
      return {
        status: 'all',
      };
    },

    watch: {
      currentFilter: {
        handler(value) {
          if (typeof value === 'boolean') {
            this.status = this.currentFilter;
          } else {
            this.status = 'all';
          }
        },
        immediate: true,
      },
    },

    methods: {
      resetFilters() {
        this.$emit('updated', 'all');
      },
    },
  };
</script>
