<template>
  <v-form ref="form" class="create-license-modal" @submit.prevent="submit()">
    <v-card>
      <v-card-title>
        <span class="headline text-truncate">
          {{ $t('ui.createLicenseModal.title') }}
        </span>

        <v-spacer />

        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              ref="nameInput"
              v-model.number="license.usersCount"
              :label="$t('ui.createLicenseModal.fields.usersCount')"
              :rules="[rules.getRequiredRule, rules.getNumberRule, rules.getPositiveNumberRule]"
              type="number"
              hide-details="auto"
              outlined
            />
          </v-col>

          <v-col>
            <v-select
              v-model="license.monthsCount"
              :items="monthsItems"
              :label="$t('ui.createLicenseModal.fields.period')"
              hide-details="auto"
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-menu
              ref="activeUntil"
              v-model="startDateMenu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              min-width="290px"
              offset-y
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  ref="activeUntilInput"
                  :value="formatDate(startDate)"
                  :label="$t('ui.createLicenseModal.fields.startDate')"
                  append-icon="mdi-calendar"
                  hide-details
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click:append="on.click($event)"
                >
                </v-text-field>
              </template>

              <v-date-picker v-model="startDate" :min="minStartDate" no-title scrollable>
                <v-spacer />

                <v-btn color="primary" text @click="startDateMenu = false">
                  {{ $t('ui.common.cancel') }}
                </v-btn>

                <v-btn color="primary" text @click="$refs.activeUntil.save(startDate)">
                  {{ $t('ui.common.ok') }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col>
            <v-text-field
              :value="endDate"
              :label="$t('ui.createLicenseModal.fields.endDate')"
              append-icon="mdi-calendar"
              disabled
              hide-details
              outlined
            />
          </v-col>
        </v-row>

        <div
          class="mt-4 pa-4 d-flex align-center create-license-modal__calculate"
          :class="{ 'create-license-modal__calculate--error': price > balance }"
        >
          <v-icon :color="price > balance ? '#EB5757' : '#32A1F6'" large class="mr-4">
            mdi-license
          </v-icon>

          <div>
            <div>
              {{ $t('ui.createLicenseModal.costCalculation') }}:

              <span class="text-decoration-underline font-weight-bold ml-1">
                {{ $tc('ui.plurals.users', license.usersCount) }}
              </span>

              <span class="mx-1">*</span>

              <span class="text-decoration-underline font-weight-bold">
                {{ $tc('ui.plurals.months', license.monthsCount) }}
              </span>

              <span class="mx-1">=</span>

              <span class="font-weight-bold">
                {{ $tc('ui.plurals.credits', price) }}
              </span>
            </div>

            <div class="create-license-modal__price font-weight-medium mt-2">
              <template v-if="price > balance">
                {{ $t('ui.createLicenseModal.notEnoughCredits') }}
              </template>

              <template v-else>
                {{ $t('ui.createLicenseModal.debited') }}: {{ $tc('ui.plurals.credits', price) }}
              </template>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="primary" text @click="close()">
          {{ $t('ui.common.cancel') }}
        </v-btn>

        <v-btn
          :loading="isBusySubmit"
          :disabled="price > balance"
          color="primary"
          type="submit"
          text
        >
          {{ $t('ui.createLicenseModal.grantAccess') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="isBusy" absolute>
      <v-progress-circular size="64" indeterminate />
    </v-overlay>
  </v-form>
</template>

<script>
  import moment from 'moment';

  import api from 'src/api';

  import { busyFlow } from 'src/utils';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  export default {
    name: 'CreateLicenseModal',

    mixins: [formatTimestampMixin],

    props: {
      clientId: {
        type: String,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        isBusy: false,
        balance: Number.MAX_VALUE,
        isBusySubmit: false,

        license: {
          usersCount: 50,
          monthsCount: 12,
          startDate: moment()
            .startOf('day')
            .valueOf(),
        },

        rules: {
          getRequiredRule: v => !!v || this.$t('validation.notNull'),
          getNumberRule: v => !Number.isNaN(v) || this.$t('validation.isNumber'),
          getPositiveNumberRule: v => v > 0 || this.$t('validation.notPositive'),
        },

        monthsItems: Array(10)
          .fill(null)
          .map((value, index) => ({
            text: this.$tc('ui.plurals.months', index + 3),
            value: index + 3,
          })),

        startDateMenu: false,
        minStartDate: moment().format('YYYY-MM-DD'),
      };
    },

    computed: {
      startDate: {
        get() {
          return moment(this.license.startDate).format('YYYY-MM-DD');
        },

        set(value) {
          this.license.startDate = moment
            .utc(value)
            .startOf('day')
            .valueOf();
        },
      },

      endDate() {
        return this.formatDate(
          moment(this.license.startDate)
            .add(this.license.monthsCount, 'month')
            .format('YYYY-MM-DD')
        );
      },

      price() {
        return this.license.monthsCount * this.license.usersCount;
      },
    },

    watch: {
      balance(value) {
        this.$emit('updateBalance', value);
      },
    },

    mounted() {
      this.getBalance();
    },

    methods: {
      submit() {
        const { form } = this.$refs;
        if (!form.validate()) {
          return;
        }

        busyFlow.call(
          this,
          async () => {
            try {
              const { response } = await api.integrators.createClientLicense({
                url_params: {
                  id: this.clientId,
                },
                data: this.license,
              });

              this.$emit('updateBalance', response.balance);
              this.$emit('created');
              this.close();
            } catch (error) {
              if (error.code === 'INSUFFICIENT_FUNDS') {
                this.balance = error.details.balance;
                this.$emit('updateBalance', error.details.balance);
              }
            }
          },
          false,
          'isBusySubmit'
        );
      },

      getBalance() {
        busyFlow.call(this, async () => {
          const { response } = await api.integrators.getCurrent();

          this.balance = response.balance;
        });
      },

      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss">
  .create-license-modal {
    &__calculate {
      background-color: rgba(44, 130, 226, 0.15);
      border-radius: 4px;
      color: #32a1f6;
      font-size: 14px;
      line-height: 20px;

      &--error {
        color: #eb5757;
        background-color: rgba(235, 87, 87, 0.15);
      }
    }

    &__price {
      font-size: 20px;
      line-height: 23px;
    }
  }
</style>
