<template>
  <div class="support-center-preview">
    <div class="support-center-preview__modes">
      <template v-if="isWelcomeMessageEnabled">
        <button
          class="support-center-preview__mode-button"
          :class="{ 'support-center-preview__mode-button--active': showedFirstTimeDiscovery }"
          @click="showedFirstTimeDiscovery = !showedFirstTimeDiscovery"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.6078 0.5C8.77873 0.5 0 9.27873 0 20.1078C0 20.3244 0.175575 20.5 0.392157 20.5H20V0.892157C20 0.675575 19.8244 0.5 19.6078 0.5ZM17.5758 19.2879C18.2452 19.2879 18.7879 18.7452 18.7879 18.0757C18.7879 17.4063 18.2452 16.8636 17.5758 16.8636C16.9063 16.8636 16.3636 17.4063 16.3636 18.0757C16.3636 18.7452 16.9063 19.2879 17.5758 19.2879Z"
              fill="currentColor"
            />
          </svg>
        </button>

        <div class="support-center-preview__modes-dash" />
      </template>

      <button
        class="support-center-preview__mode-button"
        :class="{ 'support-center-preview__mode-button--active': openedScenariosList }"
        @click="toggleOpenedScenariosList()"
      >
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.7778 2.72222V18.2778H2.22222V2.72222H17.7778ZM19 0.5H1C0.444445 0.5 0 0.944445 0 1.5V19.5C0 19.9444 0.444445 20.5 1 20.5H19C19.4444 20.5 20 19.9444 20 19.5V1.5C20 0.944445 19.4444 0.5 19 0.5ZM8.88889 4.94444H15.5556V7.16667H8.88889V4.94444ZM8.88889 9.38889H15.5556V11.6111H8.88889V9.38889ZM8.88889 13.8333H15.5556V16.0556H8.88889V13.8333ZM4.44444 4.94444H6.66667V7.16667H4.44444V4.94444ZM4.44444 9.38889H6.66667V11.6111H4.44444V9.38889ZM4.44444 13.8333H6.66667V16.0556H4.44444V13.8333Z"
            fill="currentColor"
          />
        </svg>
      </button>

      <div class="support-center-preview__modes-dash" />

      <button
        class="support-center-preview__mode-button"
        :class="{ 'support-center-preview__mode-button--active': openedEmptyList }"
        @click="toggleEmptyList()"
      >
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.7778 2.72222V18.2778H2.22222V2.72222H17.7778ZM19 0.5H1C0.444445 0.5 0 0.944445 0 1.5V19.5C0 19.9444 0.444445 20.5 1 20.5H19C19.4444 20.5 20 19.9444 20 19.5V1.5C20 0.944445 19.4444 0.5 19 0.5Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </div>

    <FakeSupportCenter
      v-if="showed"
      :showed-first-time-discovery="showedFirstTimeDiscovery && isWelcomeMessageEnabled"
      :opened-scenarios-list="openedScenariosList"
      :opened-empty-list="openedEmptyList"
      :first-time-header="firstTimeHeader"
      :first-time-text="firstTimeText"
      :list-header="listHeader"
      :list-empty-text="listEmptyText"
      :classic-theme-type="type === $options.THEME_TYPE_CLASSIC"
    />
  </div>
</template>

<script>
  import hexToRgba from 'hex-to-rgba';

  import { jsonStylesToCss } from 'src/utils';

  import basePreview from '../../mixins/basePreview';
  import { THEME_TYPE_CLASSIC } from '../../utils';

  import FakeSupportCenter from './FakeSupportCenter.vue';

  export default {
    name: 'SupportCenterPreview',

    components: {
      FakeSupportCenter,
    },

    extends: basePreview,

    data() {
      return {
        showedFirstTimeDiscovery: true,
        openedScenariosList: false,
        openedEmptyList: false,
      };
    },

    THEME_TYPE_CLASSIC,

    computed: {
      firstTimeHeader() {
        return this.settings.firstTimeHeader;
      },

      isWelcomeMessageEnabled() {
        return this.settings.isWelcomeMessageEnabled || false;
      },

      firstTimeText() {
        return this.settings.firstTimeText;
      },

      listHeader() {
        return this.settings.listHeader;
      },

      listEmptyText() {
        return this.settings.listEmptyText;
      },

      compiledCss() {
        const styles = {
          ...this.buttonStyles,
        };

        const { copyrightHidden } = this.settings;

        styles['fake-support-center .floating-wrapper.opened #hinted__floating-button'] =
          styles['floating-wrapper:hover #hinted__floating-button'];

        if (copyrightHidden) {
          styles['hinted-hint-bottom-link'] = 'display:none;';
        }

        return jsonStylesToCss(styles).replace(/;|(([^;])(}))/g, '$2 !important; $3');
      },

      buttonStyles() {
        const { buttonHoverBackgroundColor } = this.schema;

        const hoverBackgroundColorStyle = `background-color: ${hexToRgba(
          buttonHoverBackgroundColor
        )};`;

        return {
          'floating-wrapper:hover #hinted__floating-button': hoverBackgroundColorStyle,
        };
      },
    },

    watch: {
      firstTimeHeader() {
        this.showedFirstTimeDiscovery = true;
      },

      firstTimeText() {
        this.showedFirstTimeDiscovery = true;
      },

      listHeader() {
        if (!this.openedScenariosList && !this.openedEmptyList) {
          this.openedScenariosList = true;
        }
      },

      listEmptyText() {
        this.openedScenariosList = false;
        this.openedEmptyList = true;
      },
    },

    methods: {
      toggleOpenedScenariosList() {
        if (!this.openedScenariosList && this.openedEmptyList) {
          this.openedEmptyList = false;
        }

        this.openedScenariosList = !this.openedScenariosList;
      },

      toggleEmptyList() {
        if (!this.openedEmptyList && this.openedScenariosList) {
          this.openedScenariosList = false;
        }

        this.openedEmptyList = !this.openedEmptyList;
      },
    },
  };
</script>

<style lang="scss">
  .support-center-preview {
    height: 68vh;
    position: relative;

    &__modes {
      background-color: #ffffff;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      width: 40px;
      position: absolute;
      top: 10px;
      left: 10px;
    }

    &__mode-button {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #343349;
      opacity: 0.2;
      transition: opacity 0.3s ease-out;

      &--active {
        opacity: 1;
      }
    }

    &__modes-dash {
      width: 18px;
      height: 1px;
      background-color: #343349;
      opacity: 0.2;
      margin: 0 auto;
    }
  }
</style>
