<template>
  <div class="main">
    <div class="travoltaContainer">
      <v-img src="~src/assets/travoltaBlob.svg" max-width="180" max-height="180" />

      <v-img
        class="travolta"
        :class="{ sided: isSided }"
        src="~src/assets/travolta.svg"
        max-width="120"
        max-height="260"
        @click="changeSide"
      />
    </div>

    <div class="text">
      <span>{{ $t('ui.common.nothingFound') }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TravoltaNotFound',

    data() {
      return {
        isSided: false,
      };
    },

    methods: {
      changeSide() {
        this.isSided = !this.isSided;
      },
    },
  };
</script>

<style>
  .main {
    padding-top: 80px;
  }

  .travoltaContainer {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    position: relative;
  }

  .travolta {
    position: absolute;
  }

  .sided {
    transform: scale(-1, 1);
  }

  .text {
    margin-top: 80px;
    margin-bottom: 10px;
  }
</style>
