<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-container fluid class="pb-0">
        <v-card flat>
          <v-card-text class="px-5 py-4">
            <v-row align="center">
              <v-col cols="auto">
                <h3 class="title font-weight-regular">
                  <span class="lstick" />
                  {{ $t('pages.accounts.accountsFilter.title') }}:
                </h3>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  v-model.trim="debouncedPattern"
                  prepend-inner-icon="mdi-magnify"
                  :label="$t('pages.accounts.accountsFilter.search')"
                  light
                  outlined
                  dense
                  hide-details
                  clearable
                />
              </v-col>

              <v-col cols="3">
                <v-autocomplete
                  :value="localOrganizationId"
                  :loading="isLoadingOrganizationsList"
                  :items="organizationsList"
                  :search-input.sync="debouncedOrganizationsSearchPattern"
                  :label="$t('pages.accounts.accountsFilter.team')"
                  :placeholder="$t('pages.accounts.accountsFilter.teamPlaceholder')"
                  persistent-placeholder
                  item-text="name"
                  item-value="id"
                  prepend-inner-icon="mdi-magnify"
                  light
                  outlined
                  dense
                  cache-items
                  hide-details
                  clearable
                  @input="$emit('update:organizationId', $event)"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
  import debounce from 'lodash.debounce';

  import api from 'src/api';

  export default {
    name: 'AccountsFilter',

    props: {
      pattern: {
        type: String,
        required: false,
        default: null,
      },

      organizationId: {
        type: String,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        localPattern: '',
        localOrganizationId: null,

        isLoadingOrganizationsList: false,
        organizationsList: [],
        organizationsSearchPattern: '',
      };
    },

    computed: {
      debouncedPattern: {
        get() {
          return this.localPattern;
        },

        set(value) {
          this.debouncedSetPattern(value);
        },
      },

      debouncedOrganizationsSearchPattern: {
        get() {
          return this.organizationsSearchPattern;
        },

        set(value) {
          this.debouncedSetOrganizationSearchPattern(value);
        },
      },
    },

    watch: {
      pattern: {
        handler(value) {
          this.localPattern = value;
        },
        immediate: true,
      },

      organizationId: {
        handler(value) {
          this.localOrganizationId = value;
        },
        immediate: true,
      },

      organizationsSearchPattern: {
        handler() {
          this.findOrganizations();
        },
        immediate: true,
      },
    },

    methods: {
      debouncedSetPattern: debounce(function setPattern(pattern) {
        this.$emit('update:pattern', pattern);
      }, 300),

      debouncedSetOrganizationSearchPattern: debounce(function setPattern(pattern) {
        this.organizationsSearchPattern = pattern;
      }, 300),

      async findOrganizations() {
        this.isLoadingOrganizationsList = true;

        const { response } = await api.organizations.listWithParams({
          params: {
            page: 1,
            limit: 10,
            filter_by: 'name',
            filter_direction: 'ASC',
            ...(this.organizationsSearchPattern
              ? { pattern: this.organizationsSearchPattern }
              : {}),
          },
        });

        this.organizationsList = Object.freeze(response.payload);

        this.isLoadingOrganizationsList = false;
      },
    },
  };
</script>
