<template>
  <div class="d-flex flex-column mt-2">
    <template v-if="isWelcomePage && isActive">
      <router-link class="text-link text-link--primary text-center" to="login-link">
        {{ $t('pages.mobileWelcome.sendLinkText') }}
      </router-link>
    </template>

    <template v-else>
      <button v-if="!isActive" v-ripple class="text-link text-link--retry text-center mt-2">
        {{
          $t('pages.mobileSendLink.resendLinkTimeoutMessage', { period: retryEmailTimerCountDown })
        }}
      </button>

      <button
        v-if="isActive"
        v-ripple
        class="text-link text-link--retry text-center mt-2"
        @click="retrySendLoginLinkEmail"
      >
        {{ $t('pages.mobileSendLink.resendLinkMessage') }}
      </button>
    </template>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'SendLoginLink',

    data() {
      return {
        retryEmailTimerCountDown: 0,
      };
    },

    computed: {
      ...mapGetters('users', ['loginLinkEmailRetryPeriod']),

      isWelcomePage() {
        return this.$route.name === 'MobileWelcome';
      },

      isActive() {
        return this.retryEmailTimerCountDown === 0;
      },
    },

    watch: {
      loginLinkEmailRetryPeriod(value) {
        if (value > 0) {
          this.retryEmailTimerCountDown = value;
        }
      },

      retryEmailTimerCountDown(value) {
        if (value > 0) {
          setTimeout(() => {
            this.retryEmailTimerCountDown -= 1;
          }, 60000);
        }
      },
    },

    mounted() {
      this.getLoginLinkEmailRetryPeriod();
    },

    methods: {
      ...mapActions('users', ['getLoginLinkEmailRetryPeriod', 'sendLoginLinkEmail']),

      async retrySendLoginLinkEmail() {
        await this.sendLoginLinkEmail();
        await this.getLoginLinkEmailRetryPeriod();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .text-link {
    text-transform: none;
    text-decoration: underline;

    &--primary {
      color: #009efb;
    }

    &--retry {
      color: rgba(52, 51, 73, 0.43);
    }
  }

  .v-btn__content {
    width: 80vw;
    white-space: normal;
  }
</style>
