<template>
  <div>
    <div class="px-4 py-5 firstItem">
      <button class="font-select__button" @click="$emit('back')">
        {{ $t('pages.scenarios.filters.back') }}
      </button>

      <button class="font-select__button" @click="resetFilters()">
        {{ $t('pages.scenarios.filters.reset') }}
      </button>
    </div>

    <v-date-picker
      v-model="localDates"
      range
      no-title
      scrollable
      :max="maxDate"
      first-day-of-week="1"
      width="340"
      @change="update($event)"
    />
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'CreationDatesFilter',

    props: {
      currentFilter: {
        type: Object,
        default: null,
      },
    },

    data() {
      return {
        filterDates: [],
        localDates: [],
        maxDate: new Date().toISOString().slice(0, 10),
      };
    },

    watch: {
      currentFilter: {
        handler(value) {
          if (!value) {
            this.filterDates = [];
          } else {
            const { from, to } = value;
            this.filterDates = [
              moment
                .utc(from)
                .local()
                .format('YYYY-MM-DD'),
              moment
                .utc(to)
                .local()
                .format('YYYY-MM-DD'),
            ];
          }

          this.localDates = this.filterDates;
        },
        immediate: true,
      },
    },

    methods: {
      update(data) {
        const dates = [...data];

        if (dates.length === 1) {
          dates.push(dates[0]);
        }

        if (Date.parse(dates[1]) < Date.parse(dates[0])) {
          const temp = dates[1];
          [dates[1]] = dates;
          dates[0] = temp;
        }

        this.$emit('updated', dates);
      },

      resetFilters() {
        this.$emit('updated', []);
      },
    },
  };
</script>
