<template>
  <v-list flat dense class="tags-filter">
    <div class="px-4 py-3 firstItem">
      <button class="font-select__button" @click="$emit('back')">
        {{ $t('pages.scenarios.filters.back') }}
      </button>

      <button class="font-select__button" @click="resetFilters()">
        {{ $t('pages.scenarios.filters.reset') }}
      </button>
    </div>

    <v-list-item-group
      :value="selectedTags"
      multiple
      class="tags-filter__list"
      @change="$emit('updated', $event)"
    >
      <v-list-item v-for="tag in tags" :key="tag.id" :value="tag.id" :disabled="tag.isSystemTag">
        <template #default="{ active }">
          <v-list-item-action>
            <v-checkbox
              :input-value="tag.isSystemTag ? true : active"
              :disabled="tag.isSystemTag === true"
              color="primary"
            />
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ tag.title }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
  import api from 'src/api';

  export default {
    name: 'TagsFilter',

    props: {
      currentFilter: {
        type: Array,
        default: null,
      },
    },

    data() {
      return {
        tags: null,
        selectedTags: [],
      };
    },

    watch: {
      currentFilter: {
        handler(value) {
          this.selectedTags = value || [];
        },
        immediate: true,
      },
    },

    mounted() {
      this.getTags();
    },

    methods: {
      async getTags() {
        const { response } = await api.tags.list();

        this.tags = response;
      },

      resetFilters() {
        this.$emit('update', []);
      },
    },
  };
</script>

<style lang="scss">
  .tags-filter {
    &__list {
      max-height: 290px;
      overflow: auto;
    }
  }
</style>
