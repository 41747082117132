<template>
  <v-alert color="primary" :dismissible="contactsSent" dark class="py-6" width="100%">
    <template #prepend>
      <v-icon large class="mr-4">
        mdi-alert
      </v-icon>
    </template>

    <v-row align="center">
      <v-col class="grow">
        <h4>
          {{ title }}
        </h4>

        <div class="mt-2">
          {{ text }}
        </div>
      </v-col>

      <v-col v-if="!contactsSent" class="shrink d-flex">
        <v-btn color="white" light class="text-none" :loading="isBusy" @click="sendContactForm()">
          {{ $t('ui.expirePlanAlert.button') }}
        </v-btn>

        <v-btn color="white" light class="ml-2" icon @click="close()">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
  import { mapState } from 'vuex';
  import moment from 'moment';

  import api from 'src/api';

  import { busyFlow } from 'src/utils';
  import localStorage from 'src/services/localStorage';

  export default {
    name: 'ExpirePlanAlert',

    data() {
      return {
        isBusy: false,
        contactsSent: false,
      };
    },

    computed: {
      ...mapState('users', ['currentUser']),
      ...mapState('settings', ['isDemo']),

      plan() {
        return this.currentUser.organizationPlan;
      },

      daysLeft() {
        return Math.ceil(
          moment.utc(this.currentUser.organizationActiveUntil).diff(moment.utc(), 'days', true)
        );
      },

      title() {
        if (this.contactsSent) {
          return this.$t('ui.expirePlanAlert.sentTitle');
        }
        return (
          this.$t('ui.expirePlanAlert.title', {
            plan: this.plan.charAt(0).toUpperCase() + this.plan.slice(1),
          }) + this.$tc('ui.expirePlanAlert.daysLeft', this.daysLeft <= 1 ? 0 : this.daysLeft)
        );
      },

      text() {
        if (this.contactsSent) {
          return this.$t('ui.expirePlanAlert.sentText');
        }

        return this.$t('ui.expirePlanAlert.text');
      },
    },

    methods: {
      sendContactForm() {
        busyFlow.call(this, async () => {
          await api.organizations.sendFeedback({
            data: {
              organizationId: this.currentUser.organizationId,
              locale: this.$i18n.locale,
              fields: [
                {
                  title: this.$t('pages.paymentRequired.emailSubjectTitle'),
                  value: this.isDemo
                    ? this.$t('pages.paymentRequired.emailDemoSubject')
                    : this.$t('pages.paymentRequired.emailSubject'),
                },
                {
                  title: this.$t('common.fields.email'),
                  value: this.currentUser.email,
                },
                ...(this.currentUser.phone
                  ? [
                      {
                        title: this.$t('pages.paymentRequired.phone'),
                        value: this.currentUser.phone,
                      },
                    ]
                  : []),
              ],
            },
          });

          this.contactsSent = true;
          this.setClosedStatus();
        });
      },

      setClosedStatus() {
        const expireAlertClosingStatuses = localStorage.get('expireAlertClosingStatuses') || {};

        const { daysLeft } = this;

        if (daysLeft <= 30 && daysLeft > 20) {
          expireAlertClosingStatuses[30] = true;
        } else if (daysLeft <= 20 && daysLeft > 10) {
          expireAlertClosingStatuses[20] = true;
        } else if (daysLeft <= 10 && daysLeft > 7) {
          expireAlertClosingStatuses[10] = true;
        } else {
          expireAlertClosingStatuses[daysLeft] = true;
        }

        localStorage.set('expireAlertClosingStatuses', expireAlertClosingStatuses);
      },

      close() {
        this.setClosedStatus();
        this.$emit('closed');
      },
    },
  };
</script>
