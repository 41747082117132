<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    :active-class="``"
  >
    <v-list-item-icon v-if="item.icon" class="sicon">
      <feather v-if="isFeatherIcons" :type="item.icon" />

      <v-icon v-else>{{ item.icon }}</v-icon>
    </v-list-item-icon>

    <slot name="adornmentStart"></slot>

    <v-list-item-content v-if="item.title">
      <v-list-item-title v-text="item.title" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import Themeable from 'vuetify/lib/mixins/themeable';

  export default {
    name: 'BaseItem',

    mixins: [Themeable],

    props: {
      item: {
        type: Object,
        default: () => ({
          href: undefined,
          icon: undefined,
          title: undefined,
          to: undefined,
          iconType: 'mdi',
        }),
      },
      text: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      isFeatherIcons() {
        return this.item.iconType === 'feather';
      },

      href() {
        return this.item.href || (!this.item.to ? '#' : undefined);
      },
    },
  };
</script>
