<template>
  <div>
    <v-container v-if="!isBusy && !items.length && !isSearchActive" fluid>
      <v-row class="pb-0" justify="center">
        <v-col cols="auto">
          <v-img src="~src/assets/emptySimulationTable.svg" max-width="350" />
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-0">
        <v-col cols="auto" class="text-h5 font-weight-medium">{{
          $t('pages.simulations.noSimulationsTitle')
        }}</v-col>
      </v-row>

      <v-row class="mt-0" justify="center">
        <v-col cols="auto">{{ $t('pages.simulations.noSimulationsMessage') }}</v-col>
      </v-row>

      <v-row class="mt-0" justify="center">
        <v-col cols="auto">
          <create-simulation-button
            has-icon
            :is-player-loaded="playerLoaded"
            :create-from-scratch="openCreateForm"
            :create-from-extension="createFromExtension"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="tableHeaders"
            :items="items"
            :options.sync="params"
            :server-items-length="serverItemsCount"
            :loading="isBusy"
          >
            <template #no-data>
              <travolta-not-found />
            </template>

            <template #top>
              <v-toolbar flat class="mb-8">
                <v-container fluid class="px-0">
                  <v-row align="center" justify="space-between">
                    <v-col cols="4">
                      <v-text-field
                        v-model="debouncedPattern"
                        :label="`${$t('pages.simulations.search')}...`"
                        append-icon="mdi-magnify"
                        clearable
                        hide-details
                      />
                    </v-col>

                    <v-col cols="auto" class="d-flex justify-end">
                      <v-checkbox
                        v-if="isModeratorOrHigher"
                        v-model="trashedItems"
                        :label="$t('common.showTrashed')"
                        class="mt-2 mr-2"
                      />

                      <create-simulation-button
                        :is-player-loaded="playerLoaded"
                        :create-from-scratch="openCreateForm"
                        :create-from-extension="createFromExtension"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-toolbar>
            </template>

            <template #item.num="{ index }">
              <TableRowNumber
                :server-page="serverPage"
                :server-limit="serverLimit"
                :index="index"
              />
            </template>

            <template #item.steps="{value}">
              {{ value.length }}
            </template>

            <template #item.createdAt="{value}">
              {{ formatDateTime(value) }}
            </template>

            <template #item.action="{item }">
              <v-tooltip v-if="trashedItems" bottom>
                <template #activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" @click="restore(item.id)" v-on="on">
                    mdi-delete-restore
                  </v-icon>
                </template>
                <span>{{ $t(`common.actions.restore`) }}</span>
              </v-tooltip>

              <template v-else>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="openPlayingSimulation(item.id)"
                    >
                      <v-icon small>
                        mdi-television-play
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('pages.simulations.play') }}</span>
                </v-tooltip>

                <v-tooltip v-if="playerLoaded" bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :loading="editingId === item.id && isBusySavingSimulation"
                      :to="{ name: 'Simulations', query: { id: item.id } }"
                      color="info"
                      icon
                      small
                      exact
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t(`common.actions.edit`) }}</span>
                </v-tooltip>

                <share-popup :id="item.id" @invited="showInvitedToast = true" />

                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on" @click="copyLink(item.id)">
                      <v-icon small>mdi-link-variant</v-icon>
                    </v-btn>
                  </template>
                  <span> {{ $t('pages.simulations.copyLinkTooltip') }}</span>
                </v-tooltip>

                <v-menu offset-y left>
                  <template #activator="{ on, attrs }">
                    <v-btn small icon v-bind="attrs" v-on="on">
                      <v-icon small>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-item v-if="item.testingEnabled" @click="openSimulationTesting(item.id)">
                      <v-list-item-icon class="mr-0">
                        <v-icon small>mdi-clipboard-text-play-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('pages.simulations.startTesting') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <template v-if="showedDownloadButtons">
                      <v-list-item @click="showDownloadDocxDialog(item)">
                        <v-list-item-icon class="mr-0">
                          <v-icon small>mdi-file-document</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t('pages.simulations.downloadDocx') }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item @click="showDownloadPdfDialog(item)">
                        <v-list-item-icon class="mr-0">
                          <v-icon small>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t('pages.simulations.downloadPDF') }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <template v-if="showedScormButton">
                        <v-list-item @click="downloadScorm(item)">
                          <v-list-item-icon class="mr-0">
                            <v-icon small>mdi-folder-zip-outline</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                item.testingEnabled
                                  ? $t('pages.simulations.downloadScormPlaying')
                                  : $t('pages.simulations.downloadScorm')
                              }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="item.testingEnabled" @click="downloadScorm(item, true)">
                          <v-list-item-icon class="mr-0">
                            <v-icon small>mdi-folder-zip-outline</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t('pages.simulations.downloadScormTesting') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </template>

                    <v-list-item @click="copy(item)">
                      <v-list-item-icon class="mr-0">
                        <v-icon small>mdi-content-copy</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('pages.simulations.copyAction') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="isAdmin && isEnterprisePlanActive"
                      @click="openWorkspaceMigrationDialog([item])"
                    >
                      <v-list-item-icon class="mr-0">
                        <v-icon small>mdi-folder-swap-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('ui.workspaceEntityMigrateModal.buttonText') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="openRemoveDialog(item.id)">
                      <v-list-item-icon class="mr-0">
                        <v-icon small color="error">mdi-delete</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title> {{ $t(`common.actions.delete`) }} </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-snackbar v-model="showSavingToast" color="success" timeout="3000">
        {{ $t('pages.simulations.saveToastText') }}

        <template #action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="showSavingToast = false">
            {{ $t('ui.common.close') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar v-model="showCopyLinkToast" color="info" timeout="2000">
        {{ $t('pages.simulations.copyToastText') }}

        <template #action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="showedActiveThemeToast = false">
            {{ $t('ui.common.close') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar v-model="showInvitedToast" color="info" timeout="2000">
        {{ $t('pages.simulations.invitedToastText') }}

        <template #action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="showInvitedToast = false">
            {{ $t('ui.common.close') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar v-model="showCreatingToast" color="success" timeout="2000">
        {{ $t('pages.simulations.createdToastText') }}

        <template #action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="showCreatingToast = false">
            {{ $t('ui.common.close') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar v-model="showErrorToast" color="error" timeout="3000">
        {{ errorToastText }}

        <template #action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="showErrorToast = false">
            {{ $t('ui.common.close') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-model="workspaceMigratingDialogOpened" max-width="450px">
        <workspace-entity-migrate-modal
          v-if="workspaceMigratingDialogOpened"
          :list="workspaceMigratingIds"
          type="simulations"
          @changed="getList()"
          @close="closeWorkspaceModal()"
        />
      </v-dialog>

      <v-dialog v-model="removeDialogOpened" max-width="500">
        <remove-item-modal
          v-if="removeDialogOpened"
          :title="$t('pages.simulations.removeModalTitle')"
          :text="$t('pages.simulations.removeModalText')"
          :remove-callback="removeCallback"
          @close="removeDialogOpened = false"
          @removed="onRemoved()"
        />
      </v-dialog>

      <v-dialog v-model="isBusyDownload" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            {{ $t('pages.simulations.downloadingStatus') }}
            <v-progress-linear indeterminate color="white" class="mb-0" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <template v-if="id && title">
        <download-docx
          :id="id"
          :show="openDownloadDocxDialog"
          :title="title"
          @close="hideDownloadDocxDialog"
        />

        <download-pdf
          :id="id"
          :show="openDownloadPdfDialog"
          :title="title"
          @close="hideDownloadPdfDialog"
        />
      </template>
    </v-container>

    <v-dialog v-model="hasExtensionSimulationCreation" width="600">
      <create-simulation-from-extension-modal
        :extension-url="createFromExtensionUrl"
        :extension-title="extensionTitle"
        @close="toggleCreateFromExtensionModal"
      />
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import debounce from 'lodash.debounce';
  import pick from 'lodash.pick';
  import { saveAs } from 'file-saver';
  import * as StackBlur from 'stackblur-canvas';

  import localStorage from 'src/services/localStorage';
  import { getTokenForAxios } from 'src/services/session';

  import { currentLocale } from 'src/plugins/i18n';

  import api from 'src/api';
  import { apiUrl, buildSpecConfig } from 'src/config';
  import { busyFlow, copyText, deepcopy, getUUIDv4 } from 'src/utils';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  import WorkspaceEntityMigrateModal from 'src/components/WorkspaceEntityMigrateModal/WorkspaceEntityMigrateModal.vue';
  import RemoveItemModal from 'src/components/RemoveItemModal/RemoveItemModal.vue';
  import DownloadDocx from 'src/pages/Simulations/components/DownloadDocx/DownloadDocx.vue';
  import DownloadPdf from 'src/pages/Simulations/components/DownloadPdf/DownloadPdf.vue';
  import TableRowNumber from 'src/components/commonComponents/TableRowNumber.vue';
  import TravoltaNotFound from 'src/components/TravoltaNotFound.vue';
  import SharePopup from 'src/pages/Simulations/components/SharePopup/SharePopup.vue';
  import CreateSimulationButton from '@/pages/Simulations/components/CreateSimulationButton.vue';
  import CreateSimulationFromExtensionModal from '@/pages/Simulations/components/CreateSimulationFromExtensionModal.vue';

  const defaultParams = {
    itemsPerPage: 10,
    page: 1,
    pattern: null,
    sortBy: ['createdAt'],
    sortDesc: [true],
  };

  const getInitialSimulationData = (title, organizationId, organizationPlan) => {
    return {
      isNewSimulation: true,
      title,
      description: '',
      published: false,
      organizationId,
      organizationPlan,
      createdBy: 'web',
      steps: [
        {
          id: getUUIDv4(),
          _isEmpty: true,
          newStep: true,
          action: 'nextButton',
          contentJson: {},
          description: '',
          height: 30,
          imgUrl: null,
          modal: false,
          position: 'bottom',
          title: '',
          waitedText: '',
          waitedTextCount: 3,
          waitedTextExactMatch: false,
          width: 100,
          x: 20,
          y: 20,
          blurAreas: [],
        },
      ],
    };
  };

  const getInitialSimulationTestingData = () => {
    return {
      enabled: false,
      expectedResult: 75,
      retryCount: -1,
      timeToPass: 0,
      allowedErrorsCount: 0,
    };
  };

  export default {
    name: 'Simulations',

    HINTED_EDITOR_EXTENSION_URL: buildSpecConfig.editorExtensionUrl,
    SMART_MANUAL_EXTENSION_URL: buildSpecConfig.smartManualExtensionUrl,

    components: {
      WorkspaceEntityMigrateModal,
      RemoveItemModal,
      DownloadDocx,
      DownloadPdf,
      TableRowNumber,
      TravoltaNotFound,

      SharePopup,
      CreateSimulationButton,
      CreateSimulationFromExtensionModal,
    },

    mixins: [formatTimestampMixin],

    data() {
      return {
        openDownloadDocxDialog: false,
        openDownloadPdfDialog: false,
        isBusy: false,
        tableHeaders: Object.freeze([
          {
            value: 'num',
            text: '#',
            sortable: false,
            align: 'center',
            width: '40px',
          },
          { text: this.$t('pages.simulations.tableHeaders.title'), value: 'title' },
          { text: this.$t('pages.simulations.tableHeaders.creatorEmail'), value: 'creatorEmail' },
          { text: this.$t('common.fields.created'), value: 'createdAt' },
          {
            text: this.$t('pages.simulations.tableHeaders.stepsCount'),
            value: 'steps',
            align: 'center',
            sortable: false,
          },
          {
            text: this.$t('ui.common.actions'),
            value: 'action',
            align: 'end',
            width: 180,
            sortable: false,
          },
        ]),
        items: [],
        id: null,
        title: null,
        serverItemsCount: 0,
        serverPage: 1,
        serverLimit: 1,

        params: { ...defaultParams },

        playerLoaded: false,
        editingId: null,
        showSavingToast: false,
        showCreatingToast: false,
        showCopyLinkToast: false,
        showInvitedToast: false,
        showErrorToast: false,
        errorToastText: null,
        isBusySavingSimulation: false,
        isBusyCreatingSimulation: false,
        isBusyDownload: false,
        hasExtensionSimulationCreation: false,

        trashedItems: false,

        workspaceMigratingDialogOpened: false,
        workspaceMigratingIds: [],

        removeDialogOpened: false,
        removeCallback: () => {},
        instantCreateType: null,
      };
    },

    computed: {
      ...mapGetters('users', [
        'isModeratorOrHigher',
        'isHintedPlansActive',
        'isSimulationPlanActive',
        'isSmartManualsProPlanActive',
        'isSmartManualsEnterprisePlanActive',
        'isSmartManualsPlansActive',
        'isSimulationAccessPlansActive',
        'isAdmin',
        'isEnterprisePlanActive',
        'isSuperAdmin',
      ]),
      ...mapState('users', ['currentUser']),

      debouncedPattern: {
        get() {
          return this.params.pattern;
        },

        set(value) {
          this.debouncedSetPattern(value);
        },
      },

      mappedParams() {
        const {
          itemsPerPage,
          page = 1,
          pattern,
          sortBy: [filterBy],
          sortDesc: [isDesc],
        } = this.params;

        const limit = itemsPerPage === -1 ? 'all' : itemsPerPage;
        const filterDirection = isDesc ? 'desc' : 'asc';

        return {
          page,
          limit,
          trashedItems: this.trashedItems,
          ...(pattern ? { pattern } : {}),
          ...(filterBy ? { filter_by: filterBy, filter_direction: filterDirection } : {}),
        };
      },

      showedDownloadButtons() {
        return (
          this.isHintedPlansActive ||
          this.isSmartManualsProPlanActive ||
          this.isSmartManualsEnterprisePlanActive
        );
      },

      showedScormButton() {
        return (
          (this.isHintedPlansActive && !this.isSimulationPlanActive) ||
          this.isSmartManualsEnterprisePlanActive
        );
      },

      createFromExtensionUrl() {
        if (this.isSmartManualsPlansActive) {
          return this.$options.SMART_MANUAL_EXTENSION_URL;
        }

        if (this.isSimulationAccessPlansActive) {
          return this.$options.HINTED_EDITOR_EXTENSION_URL;
        }

        return null;
      },

      extensionTitle() {
        return this.isSmartManualsPlansActive ? 'HintEd Smart Manuals Editor' : 'HintEd Editor';
      },

      isSearchActive() {
        return (this.debouncedPattern && this.debouncedPattern.length) || this.trashedItems;
      },
    },

    watch: {
      mappedParams: {
        handler() {
          this.getList();
        },
        immediate: true,
      },

      $route: {
        handler(to) {
          if (to.query.id) {
            this.openEditSimulation(to.query.id);
          } else {
            this.closePlayer();
          }
        },
        deep: true,
        immediate: true,
      },

      playerLoaded(value) {
        if (!value) {
          return;
        }

        this.execInstantCreate();
      },
    },

    async mounted() {
      await this.loadPlayer();
      this.instantCreateType = this.$route.query.createFrom;
      this.execInstantCreate();
    },

    methods: {
      ...mapActions('upload', ['uploadFile']),

      async loadPlayer() {
        await this.$loadScript('/hinted-simulation-player.js');
        this.playerLoaded = true;
      },

      debouncedSetPattern: debounce(function setPattern(pattern) {
        this.params = {
          ...defaultParams,
          sortBy: this.params.sortBy,
          itemsPerPage: this.params.itemsPerPage,
          sortDesc: this.params.sortDesc,
          pattern,
        };
      }, 300),

      getList() {
        busyFlow.call(this, async () => {
          const { response } = await api.simulations.list({
            params: { ...this.mappedParams },
          });

          this.serverItemsCount = response.count;
          this.serverLimit = this.mappedParams.limit;
          this.serverPage = this.mappedParams.page;
          this.items = response.payload;
        });
      },

      openRemoveDialog(id) {
        this.removeCallback = () => api.simulations.remove({ url_params: { id } });
        this.removeDialogOpened = true;
      },

      onRemoved() {
        if (this.items.length === 1 && this.params.page !== 1) {
          this.params.page -= 1;
        } else {
          this.getList();
        }
      },

      hideDownloadDocxDialog() {
        this.openDownloadDocxDialog = false;
      },

      showDownloadDocxDialog({ id, title }) {
        this.openDownloadDocxDialog = true;
        this.id = id;
        this.title = title;
      },

      hideDownloadPdfDialog() {
        this.openDownloadPdfDialog = false;
      },

      showDownloadPdfDialog({ id, title }) {
        this.openDownloadPdfDialog = true;
        this.id = id;
        this.title = title;
      },

      async restore(id) {
        await api.simulations.restore({ url_params: { id } });

        if (this.items.length === 1 && this.params.page !== 1) {
          this.params.page -= 1;
        } else {
          this.getList();
        }
      },

      initPlayer(isEditMode = false) {
        const interfaceLocale = localStorage.get('interfaceLocale');

        window.hintedSimulationPlayer.init(apiUrl, getTokenForAxios());
        window.hintedSimulationPlayer.setScormStatus(!this.isSimulationPlanActive);
        window.hintedSimulationPlayer.setLocale(interfaceLocale || currentLocale);
        window.hintedSimulationPlayer.onClose(() => {
          if (isEditMode) {
            this.$router.push({ name: 'Simulations' });
          }
        });
        window.hintedSimulationPlayer.onSaveAsDocument(({ format, id, title }) => {
          if (format === 'pdf') {
            this.showDownloadPdfDialog({ id, title });
            return;
          }
          if (format === 'docx') {
            this.showDownloadDocxDialog({ id, title });
          }
        });
        window.hintedSimulationPlayer.setEditMode(true);
        window.hintedSimulationPlayer.onSave(
          async (simulation, simulationDoc = null, simulationTesting = null) => {
            if (simulation.isNewSimulation) {
              await this.createSimulation(simulation, simulationTesting);
            } else {
              await this.updateSimulation(simulation, simulationDoc, simulationTesting);
            }
          }
        );
      },

      async openEditSimulation(id) {
        if (!this.playerLoaded) {
          await this.loadPlayer();
        }
        this.initPlayer(true);

        try {
          let simulation = this.items.find(item => item.id === id);

          if (!simulation) {
            const { response } = await api.simulations.get({ url_params: { id } });

            simulation = response;
          }

          if (simulation.organizationId !== this.currentUser.organizationId && !this.isSuperAdmin) {
            this.errorToastText = this.$t('pages.simulations.anotherOrganizationError');
            this.showErrorToast = true;

            return;
          }

          window.hintedSimulationPlayer.playSimulation(id);
        } catch (error) {
          if (error.code === 'NOT_FOUND') {
            this.errorToastText = this.$t('pages.simulations.notFoundError');
            this.showErrorToast = true;
          }
        }
      },

      closePlayer() {
        if (!this.playerLoaded) {
          return;
        }

        window.hintedSimulationPlayer.close();
      },

      async downloadScorm(simulation, isTesting = false) {
        this.isBusyDownload = true;

        const downloadMethod = isTesting
          ? api.simulations.downloadTestingScorm
          : api.simulations.downloadScorm;
        try {
          const { response } = await downloadMethod({
            url_params: { id: simulation.id },
          });

          saveAs(response, `${simulation.title} (SCORM).zip`);
        } finally {
          this.isBusyDownload = false;
        }
      },

      async openCreateForm() {
        if (!this.playerLoaded) {
          return;
        }

        const simulation = getInitialSimulationData(
          this.$t('pages.simulations.newSimulationTitle'),
          this.currentUser.organizationId,
          this.currentUser.organizationPlan
        );
        const testingData = getInitialSimulationTestingData();

        this.initPlayer();
        window.hintedSimulationPlayer.openCreateForm(simulation, testingData);
      },

      async createSimulation(simulation, simulationTesting, simulationDoc = null) {
        if (!simulation.steps.length) {
          return;
        }

        const preparedSimulation = await this.prepareSimulation(simulation);

        await busyFlow.call(
          this,
          async () => {
            let docResponse = null;

            const { response } = await api.simulations.create({
              data: pick(preparedSimulation, [
                'steps',
                'published',
                'title',
                'description',
                'createdBy',
                'settings',
              ]),
            });

            const { response: versionsList } = await api.simulations.getVersionsList({
              url_params: { id: response.id },
            });

            const firstVersionId = versionsList[0].id;

            const { response: testingResponse } = await api.simulations.updateTesting({
              url_params: { simulationId: response.id },
              data: pick(simulationTesting, [
                'enabled',
                'timeToPass',
                'expectedResult',
                'retryCount',
                'description',
                'allowedErrorsCount',
              ]),
            });

            if (simulationDoc) {
              if (simulationDoc.version === 2) {
                ({ response: docResponse } = await api.simulations.updateDocV2({
                  url_params: { simulationId: response.id },
                  data: pick(simulationDoc, ['contentJson']),
                }));
              } else {
                ({ response: docResponse } = await api.simulations.updateDoc({
                  url_params: { simulationId: response.id },
                  data: pick(simulationDoc, ['contentJson']),
                }));
              }
            }

            await api.simulations.combineVersions({
              url_params: { id: response.id },
              data: {
                simulationVersionId: firstVersionId,
                docVersionId: docResponse?.versionId || firstVersionId,
                testingVersionId: testingResponse.versionId,
              },
            });

            this.showCreatingToast = true;

            this.getList();
          },
          false,
          'isBusyCreatingSimulation'
        );
      },

      async updateSimulation(simulation, simulationDoc, simulationTesting) {
        const preparedSimulation = await this.prepareSimulation(simulation);

        await busyFlow.call(
          this,
          async () => {
            this.editingId = simulation.id;
            let docResponse = null;
            let testingResponse = null;
            let simulationResponse = null;

            ({ response: simulationResponse } = await api.simulations.update({
              url_params: { id: this.editingId },
              data: pick(preparedSimulation, [
                'steps',
                'published',
                'title',
                'description',
                'settings',
              ]),
            }));

            if (simulationDoc) {
              const updateDocMethod =
                simulationDoc.version === 1
                  ? api.simulations.updateDoc
                  : api.simulations.updateDocV2;

              ({ response: docResponse } = await updateDocMethod({
                url_params: { simulationId: this.editingId },
                data: pick(simulationDoc, ['contentJson']),
              }));
            }

            if (simulationTesting) {
              ({ response: testingResponse } = await api.simulations.updateTesting({
                url_params: { simulationId: this.editingId },
                data: pick(simulationTesting, [
                  'enabled',
                  'timeToPass',
                  'expectedResult',
                  'retryCount',
                  'description',
                  'allowedErrorsCount',
                ]),
              }));
            }

            await api.simulations.combineVersions({
              url_params: { id: this.editingId },
              data: {
                simulationVersionId: simulationResponse.versionId,
                docVersionId: docResponse?.versionId || simulationResponse.versionId,
                testingVersionId: testingResponse?.versionId || simulationResponse.versionId,
              },
            });

            this.editingId = null;
            this.showSavingToast = true;

            this.getList();
          },
          false,
          'isBusySavingSimulation'
        );
      },

      async prepareSimulation(simulation) {
        if (!simulation.steps.length) {
          return simulation;
        }

        const preparedSimulation = deepcopy(simulation);

        const loadImage = image => {
          return new Promise(resolve => {
            const url = new URL(image);

            if (image.origin !== window.location.origin) {
              url.host = window.location.host;
              url.protocol = window.location.protocol;
            }

            const img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');
            img.src = url.toString();
            img.addEventListener('load', () => resolve(img));
          });
        };

        const imgToCanvas = img => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          return canvas;
        };

        const DEFAULT_IMAGE_SIZE = 1630 * 780;
        const DEFAULT_BLUR = 28;
        const MAX_BLUR_RADIUS = 100;

        const addBlurAreas = (canvas, blurAreas) => {
          const canvasSize = canvas.width * canvas.height;

          const size = DEFAULT_IMAGE_SIZE > canvasSize ? canvasSize : DEFAULT_IMAGE_SIZE;

          const blurRadius = Math.min((canvasSize / size) * DEFAULT_BLUR, MAX_BLUR_RADIUS);

          return new Promise(resolve => {
            blurAreas.forEach(blurArea => {
              StackBlur.canvasRGB(
                canvas,
                Math.round(blurArea.x.toFixed(0)),
                Math.round(blurArea.y.toFixed(0)),
                Math.round(blurArea.width.toFixed(0)),
                Math.round(blurArea.height.toFixed(0)),
                Math.round(blurRadius)
              );
            });

            canvas.toBlob(blob => resolve(blob), 'image/jpeg');
          });
        };

        const promises = preparedSimulation.steps.map(async step => {
          const img = await loadImage(step.imgUrl);
          const canvas = imgToCanvas(img);

          const blob = await addBlurAreas(canvas, step.blurAreas || []);

          const formattedImgUrl = await this.uploadFile(blob);

          return { ...step, formattedImgUrl };
        });

        preparedSimulation.steps = await Promise.all(promises);

        return preparedSimulation;
      },

      openWorkspaceMigrationDialog(simulations) {
        this.workspaceMigratingIds = simulations.map(simulation => simulation.id);
        this.workspaceMigratingDialogOpened = true;
      },

      closeWorkspaceModal() {
        this.workspaceMigratingDialogOpened = false;
        this.workspaceMigratingIds = [];
      },

      openPlayingSimulation(id) {
        const { href } = this.$router.resolve({ name: 'SimulationView', params: { id } });
        const { origin } = window.location;
        const link = origin + href;

        window.open(link, '_blank');
      },

      copyLink(id) {
        const { href } = this.$router.resolve({ name: 'SimulationView', params: { id } });
        const { origin } = window.location;
        const link = origin + href;

        copyText(link);

        this.showCopyLinkToast = true;
      },

      openSimulationTesting(id) {
        const { href } = this.$router.resolve({ name: 'SimulationTesting', params: { id } });
        const { origin } = window.location;
        const link = origin + href;

        window.open(link, '_blank');
      },

      async copy(simulation) {
        const [{ response: doc }, { response: testing }] = await Promise.all([
          api.simulations.getDoc({
            url_params: { id: simulation.id },
          }),
          api.simulations.getTesting({
            url_params: { id: simulation.id },
          }),
        ]);

        const mappedStepsId = simulation.steps.reduce((acc, step) => {
          acc[step.id] = getUUIDv4();

          return acc;
        }, {});

        const copiedSimulation = {
          ...simulation,
          title: `${simulation.title} (${this.$t('pages.simulations.copyTitleText')})`,
          steps: simulation.steps.map(step => ({ ...step, id: mappedStepsId[step.id] })),
        };

        const processBlock = block => {
          return {
            ...block,
            ...(block.tunes?.stepId && mappedStepsId[block.tunes.stepId]
              ? { tunes: { ...block.tunes, stepId: mappedStepsId[block.tunes.stepId] } }
              : {}),
          };
        };

        const copyDoc = currentDoc => {
          const docV1 = deepcopy(currentDoc);

          return {
            ...docV1,
            contentJson: {
              ...docV1.contentJson,
              blocks: docV1.contentJson.blocks.map(processBlock),
            },
          };
        };

        const copyDocV2 = currentDoc => {
          const docV2 = deepcopy(currentDoc);

          return {
            ...docV2,
            contentJson: docV2.contentJson.map(step => {
              const { before, after } = docV2.editableContentJson[step.stepId];
              return {
                ...step,
                blocks: step.blocks.map(processBlock),
                before,
                after,
              };
            }),
          };
        };

        const copiedDoc = doc.version === 2 ? copyDocV2(doc) : copyDoc(doc);

        await this.createSimulation(copiedSimulation, testing, copiedDoc);
      },

      execInstantCreate() {
        if (!this.instantCreateType) {
          return false;
        }

        switch (this.instantCreateType) {
          case 'fromScratch':
            return this.openCreateForm();
          case 'browser':
            return this.createFromExtension();
          default:
            return false;
        }
      },

      createFromExtension() {
        if (!window.hintedExtension && this.createFromExtensionUrl) {
          return window.open(this.createFromExtensionUrl, '_blank').focus();
        }

        this.toggleCreateFromExtensionModal();
        return false;
      },

      toggleCreateFromExtensionModal() {
        this.hasExtensionSimulationCreation = !this.hasExtensionSimulationCreation;
      },
    },
  };
</script>
