<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="groupsList"
          :options.sync="options"
          :server-items-length="groupsCount"
          :loading="isBusy"
          :page.sync="page"
        >
          <template #no-data>
            <travolta-not-found />
          </template>

          <template #top>
            <v-toolbar flat class="mb-8">
              <v-container fluid class="px-0">
                <v-row align="center">
                  <v-col cols="4">
                    <v-text-field
                      v-model="patternModel"
                      :label="`${$t('pages.groups.search')}...`"
                      append-icon="mdi-magnify"
                      clearable
                      hide-details
                    />
                  </v-col>

                  <v-spacer />

                  <v-col cols="auto">
                    <v-btn color="primary" dark class="text-none" @click="openCreatingModal()">
                      {{ $t('pages.groups.create') }}

                      <v-icon right dark>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-toolbar>
          </template>

          <template #item.num="{ index }">
            <TableRowNumber :server-page="serverPage" :server-limit="serverLimit" :index="index" />
          </template>

          <template #item.createdAt="{ item }">
            {{ formatDateTime(item.createdAt) }}
          </template>

          <template #item.updatedAt="{ item }">
            {{ formatDateTime(item.updatedAt) }}
          </template>

          <template #item.action="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2 info--text"
                  v-bind="attrs"
                  @click="openEditModal(item.id)"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t(`common.actions.edit`) }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  @click="openManageModalStep(2, item.id)"
                  v-on="on"
                >
                  mdi-account-multiple
                </v-icon>
              </template>
              <span>{{ $t(`common.actions.editGroupUsers`) }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  @click="openManageModalStep(3, item.id)"
                  v-on="on"
                >
                  mdi-playlist-check
                </v-icon>
              </template>
              <span>{{ $t(`common.actions.editGroupScenarios`) }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  @click="openManageModalStep(4, item.id)"
                  v-on="on"
                >
                  mdi-chat-outline
                </v-icon>
              </template>
              <span>{{ $t(`common.actions.editGroupTooltips`) }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  @click="openManageModalStep(5, item.id)"
                  v-on="on"
                >
                  mdi-link-variant
                </v-icon>
              </template>
              <span>{{ $t(`common.actions.editGroupLinks`) }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="error--text"
                  v-bind="attrs"
                  v-on="on"
                  @click="openRemoveDialog(item.id)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t(`common.actions.delete`) }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="manageModalOpened" max-width="1039px">
      <group-manage-modal
        v-if="manageModalOpened"
        :group-id="editingGroupId"
        :initial-step="manageModalStep"
        @close="closeModal()"
        @created="onCreatedGroup()"
        @updated="onUpdatedGroup()"
      />
    </v-dialog>

    <v-dialog v-model="removeDialogOpened" max-width="500">
      <remove-item-modal
        v-if="removeDialogOpened"
        :title="$t('pages.groups.removeModalTitle')"
        :text="$t('pages.groups.removeModalText')"
        :remove-callback="removeCallback"
        @close="removeDialogOpened = false"
        @removed="onRemoved()"
      />
    </v-dialog>

    <v-snackbar v-model="showedManageToast" :color="manageToastType" timeout="3000">
      {{ $t(`pages.groups.${manageToastType === 'success' ? 'createdToast' : 'updatedToast'}`) }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import debounce from 'lodash.debounce';
  import { mapState, mapActions } from 'vuex';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  import RemoveItemModal from 'src/components/RemoveItemModal/RemoveItemModal.vue';

  import TableRowNumber from 'src/components/commonComponents/TableRowNumber.vue';
  import TravoltaNotFound from 'src/components/TravoltaNotFound.vue';
  import GroupManageModal from './components/GroupManageModal.vue';

  export default {
    name: 'Groups',

    components: {
      RemoveItemModal,
      GroupManageModal,
      TableRowNumber,
      TravoltaNotFound,
    },

    mixins: [formatTimestampMixin],

    data() {
      return {
        options: {},
        isBusy: false,
        limit: 10,
        page: 1,
        serverPage: 1,
        serverLimit: 1,
        pattern: undefined,
        filterBy: undefined,
        filterDirection: undefined,
        headers: [
          {
            value: 'num',
            text: '#',
            sortable: false,
            align: 'center',
            width: '40px',
          },
          { text: this.$t('pages.groups.tableHeaders.title'), value: 'title' },
          { text: this.$t('pages.groups.tableHeaders.organization'), value: 'organizationName' },
          { text: this.$t('common.fields.created'), value: 'createdAt' },
          { text: this.$t('common.fields.updated'), value: 'updatedAt' },
          { text: this.$t('pages.groups.tableHeaders.creator'), value: 'creatorEmail' },
          {
            text: this.$t('ui.common.actions'),
            value: 'action',
            align: 'center',
            width: 170,
            sortable: false,
          },
        ],

        manageModalStep: 1,
        manageModalOpened: false,
        editingGroupId: null,

        showedManageToast: false,
        manageToastType: 'success',

        removeDialogOpened: false,
        removeCallback: () => {},
      };
    },

    computed: {
      ...mapState('groups', ['groupsCount', 'groupsList']),

      patternModel: {
        get() {
          return this.pattern;
        },

        set(value) {
          this.debouncedSearch(value);
        },
      },
    },

    watch: {
      options: {
        handler(value) {
          this.setValues(value);
        },

        deep: true,
      },
    },

    methods: {
      ...mapActions('groups', ['getList', 'create', 'updateGroup', 'remove']),

      debouncedSearch: debounce(function search(pattern) {
        this.pattern = pattern || undefined;
        this.resetValues();
        this.update();
      }, 300),

      openCreatingModal() {
        this.manageModalStep = 1;
        this.editingGroupId = null;
        this.openModal();
      },

      openEditModal(id) {
        this.manageModalStep = 1;
        this.editingGroupId = id;
        this.openModal();
      },

      openManageModalStep(step, id) {
        this.manageModalStep = step;
        this.editingGroupId = id;
        this.openModal();
      },

      openModal() {
        this.manageModalOpened = true;
      },

      closeModal() {
        this.manageModalOpened = false;
      },

      onCreatedGroup() {
        this.manageToastType = 'success';
        this.showedManageToast = true;

        this.update();
      },

      onUpdatedGroup() {
        this.manageToastType = 'default';
        this.showedManageToast = true;

        this.update();
      },

      async update() {
        if (this.isBusy) {
          return;
        }

        this.isBusy = true;

        try {
          await this.getList({
            page: this.page,
            limit: this.limit,
            filterBy: this.filterBy,
            filterDirection: this.filterDirection,
            pattern: this.pattern,
          });
          this.serverLimit = this.limit;
          this.serverPage = this.page;
        } catch (err) {
          console.error(err);
        } finally {
          this.isBusy = false;
        }
      },

      setValues(obj) {
        const {
          itemsPerPage,
          page,
          sortBy: [filterBy],
          sortDesc: [isDesc],
        } = obj;

        this.limit = itemsPerPage === -1 ? 'all' : itemsPerPage;
        this.page = page;
        this.filterBy = filterBy;
        this.filterDirection = (isDesc && 'desc') || 'asc';

        this.update();
      },

      resetValues() {
        this.limit = 10;
        this.page = 1;
        this.filterBy = undefined;
        this.filterDirection = undefined;
      },

      openRemoveDialog(id) {
        this.removeCallback = () => this.remove(id);
        this.removeDialogOpened = true;
      },

      onRemoved() {
        if (this.groupsList.length === 1 && this.options.page !== 1) {
          this.options.page -= 1;
        } else {
          this.update();
        }
      },

      async removeItem(item) {
        const { id } = item;

        if (this.isBusy) {
          return;
        }

        this.isBusy = true;

        try {
          await this.remove(id);
        } catch (err) {
          console.error(err);
        } finally {
          this.isBusy = false;
        }

        this.update();
      },
    },
  };
</script>
