<template>
  <v-card>
    <v-card-title>
      <span class="headline">
        <template v-if="isRemoving">
          {{ $t('pages.users.groupsModal.title.remove') }}
        </template>

        <template v-else>
          {{ $t('pages.users.groupsModal.title.add') }}
        </template>
      </span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-combobox
          v-model="selectedGroups"
          :items="groupsList"
          :label="$t('pages.users.groupsModal.label')"
          :hint="$t('pages.users.groupsModal.inputHint')"
          item-text="title"
          multiple
          outlined
          dense
          small-chips
          deletable-chips
        />
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="blue darken-1" text @click="close">
        {{ $t('ui.common.cancel') }}
      </v-btn>

      <v-btn color="blue darken-1" text :loading="isBusy" @click="onSubmit">
        <template v-if="isRemoving">
          {{ $t('ui.common.remove') }}
        </template>

        <template v-else>
          {{ $t('ui.common.add') }}
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from 'vuex';

  export default {
    name: 'GroupDialog',

    props: {
      isRemoving: { type: Boolean, default: false },
    },

    data() {
      return {
        selectedGroups: [],
        isBusy: false,
      };
    },

    computed: {
      ...mapState('groups', ['groupsList']),
      ...mapState('users', ['currentUser']),
    },

    async mounted() {
      this.isBusy = true;

      try {
        await this.getList({ organizationId: this.currentUser.organizationId, limit: 'all' });
      } catch (err) {
        console.error(err);
      } finally {
        this.isBusy = false;
      }
    },

    methods: {
      ...mapActions('groups', ['getList']),

      onSubmit() {
        const payload =
          (this.selectedGroups.length && this.selectedGroups.map(group => group.id)) || [];

        if (this.isRemoving) {
          return this.$emit('remove', payload);
        }

        return this.$emit('add', payload);
      },

      close() {
        this.$emit('close');
      },
    },
  };
</script>
