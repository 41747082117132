<template>
  <div class="plan-card" :class="[`plan-card--theme-${theme}`]">
    <div class="plan-card__header">
      <div>
        <div>Smart Manuals</div>

        <div>{{ name }}</div>
      </div>
    </div>

    <div class="plan-card__price">
      <div class="plan-card__price-shadow">
        <slot name="priceShadow" />
      </div>

      <span>
        <slot name="price" />
      </span>

      <div v-if="discount" class="plan-feature__chip plan-feature__chip--large">
        {{ discount }}
      </div>
    </div>

    <div class="plan-card__delimiter" />

    <div v-for="(feature, key) in features" :key="key" class="plan-card__feature plan-feature">
      <v-icon left color="white" size="20">
        mdi-checkbox-marked-circle-outline
      </v-icon>

      <span class="plan-feature__text" :class="{ 'plan-feature__text--bold': feature.bold }">
        {{ feature.text }}
      </span>

      <div v-if="feature.soon" class="plan-feature__chip">
        {{ $t('pages.billing.soon') }}
      </div>
    </div>

    <div class="plan-card__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PlanCard',

    props: {
      theme: {
        type: String,
        required: false,
        default: 'orange',
      },

      name: {
        type: String,
        required: true,
      },

      discount: {
        type: [String, Number],
        required: false,
        default: null,
      },

      features: {
        type: Array,
        required: false,
        default: () => [],
      },
    },
  };
</script>

<style lang="scss">
  .plan-card {
    padding: 30px 30px 102px 30px;
    color: #ffffff;
    border-radius: 10px;
    position: relative;

    &--theme-orange {
      background: linear-gradient(45deg, #ec6f66 5.69%, #f3a183 99.86%);
    }

    &--theme-blue {
      background: linear-gradient(86.41deg, #7474bf 2.91%, #348ac7 99.13%);
    }

    &--theme-green {
      background: linear-gradient(86.41deg, #43c6ac 2.91%, rgba(22, 73, 84, 0.66) 99.13%);
    }

    &__header {
      font-weight: 700;
      font-size: 34px;
      line-height: 40px;
      // letter-spacing: 0.0025em;
    }

    &__price {
      margin-top: 20px;
      position: relative;
      height: 96px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 48px;
      line-height: 40px;
      letter-spacing: 0.0025em;
    }

    &__price-shadow {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.05;
      letter-spacing: -0.015em;
      font-weight: 900;
      font-size: 85px;
      line-height: 96px;
      user-select: none;

      @media (max-width: 1600px) {
        display: none;
      }
    }

    &__delimiter {
      width: 100%;
      height: 2px;
      background-color: #ffffff;
      opacity: 0.3;
      margin: 20px 0;
    }

    &__feature {
      display: flex;
      align-items: flex-start;

      & + & {
        margin-top: 12px;
      }
    }

    .plan-feature {
      &__text {
        color: #ffffff;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.005em;

        &--bold {
          font-weight: 900;
        }
      }

      &__chip {
        margin-left: 10px;
        padding: 5px 10px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.3);
        color: #ffffff;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        white-space: nowrap;
        letter-spacing: 0.00892857em;

        &--large {
          padding: 10px 15px;
        }
      }
    }

    &__footer {
      position: absolute;
      bottom: 30px;
      left: 0;
      width: 100%;
      padding: 0 30px;
    }
  }
</style>
