const list = {
  name: 'list',
  method: 'get',
  url: 'list',
};

const update = {
  name: 'update',
  method: 'patch',
  url: ':id',
};

const remove = {
  name: 'remove',
  method: 'delete',
  url: ':id',
};

const restore = {
  name: 'restore',
  method: 'post',
  url: ':id/restore',
};

const changeWorkspace = {
  name: 'changeWorkspace',
  method: 'post',
  url: 'change-workspace',
};

export default {
  name: 'desktopTooltips',
  url: '/api/desktop/tooltips',
  children: [list, update, remove, restore, changeWorkspace],
};
