<template>
  <div
    class="base-notification-item"
    :class="{ 'base-notification-item--read': notification.isRead }"
  >
    <div class="base-notification-item__header">
      <div class="base-notification-item__header-content">
        <slot name="header" />
      </div>

      <div class="base-notification-item__info">
        <span class="mr-2 base-notification-item__date">
          {{ formatDateTime(notification.createdAt) }}
        </span>

        <v-btn v-if="!notification.isRead" icon small color="grey lighten-2" @click="read()">
          <v-icon small>mdi-check-bold</v-icon>
        </v-btn>

        <v-btn icon small color="primary" @click="remove()">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="base-notification-item__content">
      <slot name="content" />
    </div>

    <div v-if="$scopedSlots.actions" class="base-notification-item__actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';
  import notificationItemMixin from './notificationItemMixin';

  export default {
    name: 'BaseNotificationItem',

    mixins: [formatTimestampMixin, notificationItemMixin],

    methods: {
      ...mapActions('notifications', ['removeNotification']),

      remove() {
        this.removeNotification(this.notification.id);
      },
    },
  };
</script>

<style lang="scss">
  .base-notification-item {
    width: 100%;
    min-height: 110px;
    padding: 15px 10px 20px;
    color: #1b1b3a;
    font-size: 14px;
    line-height: 16px;

    &--read {
      .base-notification-item__header-content,
      .base-notification-item__content,
      .base-notification-item__date,
      .base-notification-item__actions {
        opacity: 0.5;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #dae4e8;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__header-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
    }

    &__info {
      display: flex;
      align-items: center;
    }

    &__content {
      margin: 5px 0;
    }

    &__actions {
      display: flex;
      align-items: center;
    }
  }
</style>
