<template>
  <v-card class="integrator-balance-modal">
    <v-card-title class="pa-4 primary">
      <span class="ma-1 title white--text"> {{ title }} </span>

      <v-spacer />

      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pt-6">
      <span v-if="text" class="black--text subtitle-1"> {{ text }}? </span>
      <slot />
    </v-card-text>

    <v-card-actions>
      <v-spacer />

      <v-btn color="grey" text @click="$emit('close')">
        {{ $t('ui.common.cancel') }}
      </v-btn>

      <v-btn :disabled="disabled" :loading="isBusy" color="primary" @click="remove()">
        {{ $t('ui.common.remove') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { busyFlow } from 'src/utils';

  export default {
    name: 'RemoveItemModal',

    props: {
      removeCallback: {
        type: [Function, Promise],
        required: true,
      },

      title: {
        type: String,
        required: true,
      },

      text: {
        type: String,
        default: null,
        required: false,
      },

      disabled: {
        type: Boolean,
        required: false,
      },
    },

    data() {
      return {
        isBusy: false,
      };
    },

    methods: {
      remove() {
        busyFlow.call(this, async () => {
          await this.removeCallback();

          this.$emit('removed');
          this.$emit('close');
        });
      },
    },
  };
</script>
