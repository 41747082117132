<template>
  <v-app class="organization-select">
    <v-main>
      <v-container fluid class="organization-select__wrapper">
        <div class="organization-select__logout-wrapper">
          <v-btn text large @click="exit()">
            <v-icon left>mdi-logout</v-icon>

            {{ $t('pages.organizationSelect.logout') }}
          </v-btn>
        </div>

        <div class="organization-select__header">
          <h2>{{ $t('pages.organizationSelect.header') }}</h2>
        </div>

        <div class="organization-select__list">
          <template v-for="organization in organizationsList">
            <div
              v-if="organization.isApproved"
              :key="organization.id"
              class="organization-select__item organization-item"
              @click="selectOrganization(organization.id)"
            >
              <span>{{ organization.name }}</span>

              <v-icon>mdi-login</v-icon>
            </div>

            <v-tooltip v-else :key="organization.id" top open-delay="300">
              <template #activator="{ on, attrs }">
                <div
                  class="organization-select__item organization-item organization-item--disabled"
                  v-bind="attrs"
                  v-on="on"
                  @click="selectOrganization(organization.id)"
                >
                  <span>{{ organization.name }}</span>

                  <v-icon>mdi-clock-time-four-outline</v-icon>
                </div>
              </template>
              <span> {{ $t('pages.organizationSelect.notApprovedTooltip') }}</span>
            </v-tooltip>
          </template>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import { mapActions } from 'vuex';

  import api from 'src/api';

  export default {
    name: 'OrganizationSelect',

    data() {
      return {
        organizationsList: [],
      };
    },

    mounted() {
      this.loadList();
    },

    methods: {
      ...mapActions('auth', ['logout']),
      ...mapActions('users', ['getCurrentUser']),

      async exit() {
        await this.logout();

        this.$router.push({ name: 'login' });
      },

      async loadList() {
        const { response } = await api.organizations.getAvailableList({
          params: { limit: 'all' },
        });

        this.organizationsList = Object.freeze(response.payload);
      },

      async selectOrganization(id) {
        await api.organizations.select({ url_params: { id } });
        await this.getCurrentUser();

        this.$router.push(
          this.$route.query.next
            ? { path: decodeURIComponent(String(this.$route.query.next)) }
            : { name: 'Dashboard' }
        );
      },
    },
  };
</script>

<style lang="scss">
  .organization-select {
    &__wrapper {
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px 32px 64px;
    }

    &__logout-wrapper {
      align-self: flex-end;
    }

    &__header {
      margin-top: 32px;
      text-align: center;
    }

    &__create-link {
      text-decoration: underline;
    }

    &__list {
      width: 464px;
      padding: 0 14px;
      overflow: auto;
      flex: 1 1 auto;
      margin-top: 35px;
    }

    &__item {
      width: 100%;
      height: 86px;

      background: #ffffff;
      box-shadow: 0 4px 4px rgba(134, 134, 134, 0.25);
      border-radius: 5px;

      margin-bottom: 20px;
    }

    .organization-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      cursor: pointer;
      color: #343349;
      transition-property: background-color, color, box-shadow;
      transition-duration: 0.1s;
      transition-timing-function: ease-in;

      &--disabled {
        opacity: 0.5;
        cursor: default;
      }

      .v-icon {
        color: currentColor;
      }

      &:hover:not(&--disabled) {
        background-color: rgba(216, 17, 89, 0.1);
        color: #d81159;
        box-shadow: 0 4px 4px rgba(215, 27, 90, 0.22);
      }
    }
  }
</style>
