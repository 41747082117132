<template>
  <v-navigation-drawer
    id="main-sidebar"
    :value="true"
    :dark="true"
    color="#1d2228"
    mobile-breakpoint="960"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    app
  >
    <organizations-menu />

    <workspaces-menu v-if="hasAccessToWorkspaces" />

    <v-list-item v-else class="profile-bg">
      <v-list-item-avatar>
        <v-icon large>
          mdi-account-circle
        </v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-if="isFieldAllowed('email')">
          {{ currentUser.email }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list expand nav class="mt-1">
      <template v-for="item in sidebarWorkspacesNavigationItems">
        <base-item-props
          v-if="visibleItems[item.key]"
          :key="item.key"
          v-bind="item"
          :title="$t(`ui.sidebar.${item.key}`)"
          @click.native="sendSidebarMetrics(item.key)"
        />
      </template>

      <plan-upgrade-menu v-if="isSmartManualsStartPlanActive" offset-x right nudge-right="20">
        <template #activator="{on, attrs}">
          <v-list-item v-bind="attrs" class="disabled-item" v-on="on">
            <v-list-item-icon class="disabled-item__icon">
              <feather type="droplet" />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="disabled-item__title">
                {{ $t('ui.sidebar.element_styles') }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon>
              <plan-upgrade-chip />
            </v-list-item-icon>
          </v-list-item>
        </template>
      </plan-upgrade-menu>
    </v-list>

    <template #append>
      <download-app-links v-if="isSmartManualsPlansActive" />
      <div class="pa-2">
        <v-btn
          :href="`mailto:${currentUser.organizationSupportEmail}`"
          target="_blank"
          plain
          block
          class="text-none"
        >
          {{ $t('ui.sidebar.requestSupport') }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';

  import api from 'src/api';

  import BaseItemProps from 'src/components/commonComponents/BaseItemProps.vue';
  import WorkspacesMenu from 'src/components/WorkspacesMenu/WorkspacesMenu.vue';
  import OrganizationsMenu from 'src/components/OrganizationsMenu/OrganizationsMenu.vue';
  import PlanUpgradeChip from 'src/components/PlanUpgradeChip/PlanUpgradeChip.vue';
  import PlanUpgradeMenu from 'src/components/PlanUpgradeMenu/PlanUpgradeMenu.vue';
  import DownloadAppLinks from 'src/components/VerticalSidebar/DownloadAppLinks.vue';
  import { SUPERADMIN_ROLE } from 'src/constants';

  export default {
    name: 'VerticalSidebar',

    components: {
      BaseItemProps,
      WorkspacesMenu,
      OrganizationsMenu,
      PlanUpgradeChip,
      PlanUpgradeMenu,
      DownloadAppLinks,
    },

    inject: ['sendMetrics'],

    data() {
      return {
        items: Object.freeze([
          {
            key: 'dashboard',
            icon: 'bar-chart-2',
            to: '/dashboard',
          },
          {
            key: 'integratorClientSystems',
            icon: 'table',
            to: '/systems',
          },
          {
            key: 'users',
            icon: 'users',
            to: '/users',
          },
          {
            key: 'scenarios',
            icon: 'message-square',
            to: '/walkthroughs',
          },
          {
            key: 'tooltips',
            icon: 'message-circle',
            to: '/tooltips',
          },
          {
            key: 'simulations',
            icon: 'package',
            to: '/simulations',
          },
          {
            key: 'checklists',
            icon: 'clipboard',
            to: '/checklists',
          },
          {
            key: 'rating',
            icon: 'star',
            to: '/ratings',
          },
          {
            key: 'links',
            icon: 'link',
            to: '/links',
          },
          {
            key: 'element_styles',
            icon: 'droplet',
            to: '/styles',
          },
          {
            key: 'teams',
            icon: 'briefcase',
            to: '/teams',
          },
          {
            key: 'accounts',
            icon: 'globe',
            to: '/accounts',
          },
          {
            key: 'integratorClients',
            icon: 'award',
            to: '/integrator-clients',
          },
          {
            key: 'groups',
            icon: 'folder',
            to: '/groups',
          },
        ]),
        visibleItems: {
          dashboard: true,
          users: false,
          scenarios: false,
          tooltips: false,
          simulations: false,
          checklists: false,
          rating: false,
          teams: false,
          accounts: false,
          element_styles: false,
          groups: false,
          links: false,
          integratorClients: false,
          integratorClientSystems: false,
        },
        workspacesItems: [
          'dashboard',
          'scenarios',
          'tooltips',
          'rating',
          'checklists',
          'links',
          'simulations',
        ],
      };
    },

    computed: {
      ...mapState('settings', ['supportEmail', 'allowedUserInfoFields']),
      ...mapState('users', ['currentUser']),
      ...mapGetters('users', [
        'isSuperAdmin',
        'isEnterprisePlanActive',
        'isSimulationPlanActive',
        'isAdminOrHigher',
        'isSmartManualsStartPlanActive',
        'isSmartManualsPlansActive',
      ]),
      ...mapGetters('workspaces', ['hasAccessToWorkspaces', 'selectedWorkspaceIsRoot']),

      showedOrganizationsMenu() {
        if (this.hasLDAP || !this.currentUser.id || !this.userOrganization.id) {
          return false;
        }

        return (
          this.currentUser.selfRole === SUPERADMIN_ROLE ||
          (this.userOrganization.isIntegrator && this.isAdminOrHigher)
        );
      },

      sidebarWorkspacesNavigationItems() {
        if (!this.selectedWorkspaceIsRoot && this.hasAccessToWorkspaces) {
          return this.items.filter(({ key }) =>
            this.workspacesItems.some(workspacesItem => workspacesItem === key)
          );
        }
        return this.items;
      },
    },

    watch: {
      isEnterprisePlanActive() {
        this.updateVisibility();
      },

      isSimulationPlanActive() {
        this.updateVisibility();
      },
    },

    mounted() {
      this.updateVisibility();
    },

    methods: {
      ...mapActions('users', { checkUsersAccess: 'checkAccess' }),
      ...mapActions('scenarios', { getScenariosList: 'getList' }),
      ...mapActions('tooltips', { checkTooltipAccess: 'checkAccess' }),
      ...mapActions('organizations', {
        getOrganizationsList: 'list',
      }),
      ...mapActions('elementStyle', { getElementStylesByOrganization: 'getByOrganization' }),
      ...mapActions('groups', { getGroupsList: 'getList' }),

      sendSidebarMetrics(key) {
        this.sendMetrics(key, 'menu_open', window.location.href);
      },

      async updateVisibility() {
        const { response } = await api.adminPanel.getPermissions();
        this.visibleItems = response;
      },

      isFieldAllowed(fieldName) {
        if (this.allowedUserInfoFields === 'all' || this.isSuperAdmin) {
          return true;
        }

        return this.allowedUserInfoFields.includes(fieldName);
      },
    },
  };
</script>

<style lang="scss">
  #main-sidebar {
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
    .v-navigation-drawer__border {
      display: none;
    }
    .v-list {
      padding: 8px 15px;
    }
    .v-list-item {
      min-height: 35px;
      &__icon--text,
      &__icon:first-child {
        justify-content: center;
        text-align: center;
        width: 20px;
      }

      &__title {
        font-size: 13px;
      }
    }
    .v-list-item__icon i {
      width: 20px;
    }
    .icon-size .v-list-group__items i {
      width: 16px;
      font-size: 16px;
    }
    .profile-bg {
      &.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        opacity: 1;
      }
      .v-avatar {
        padding: 15px 0;
      }
    }
    .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }

    .disabled-item {
      opacity: 1 !important;
      &:hover {
        opacity: 1 !important;
      }

      &__icon,
      &__title {
        opacity: 0.3 !important;
      }
    }
  }
</style>
