<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-dialog v-model="hasGroupDialog" max-width="500px">
          <group-dialog
            :is-removing="isGroupRemovingMode"
            @close="closeGroupDialog()"
            @remove="removeUsersFromGroups()"
            @add="addUsersToGroups()"
          />
        </v-dialog>

        <v-dialog v-model="deleteOwnerDialog" max-width="640">
          <owner-deletion-dialog
            v-if="deleteOwnerDialog"
            @close="closeOwnerDeletionDialog()"
            @changeOwner="changeOrganizationOwnerDialog()"
          />
        </v-dialog>

        <v-dialog v-model="changeOrgOwnerDialog" max-width="500">
          <change-organization-owner-dialog
            v-if="changeOrgOwnerDialog"
            :owner="ownerItem"
            @close="closeChangeOrganizationOwnerDialog()"
            @updated="deleteItem(ownerItem)"
          />
        </v-dialog>

        <v-data-table
          v-model="selectedRows"
          :headers="headers"
          :items="items"
          :server-items-length="itemsCount"
          :options.sync="options"
          :loading="isBusy"
          show-select
        >
          <template #no-data>
            <travolta-not-found />
          </template>

          <template #top>
            <v-toolbar :height="selectedRows.length ? 120 : 60" flat class="mb-8">
              <v-container fluid class="px-0">
                <v-row align="center">
                  <v-col cols="4">
                    <v-text-field
                      v-model="currentSearch"
                      :label="`${$t('pages.users.search')}...`"
                      append-icon="mdi-magnify"
                      clearable
                      hide-details
                    />
                  </v-col>

                  <v-spacer />

                  <v-col cols="auto">
                    <v-dialog
                      v-if="showInviteUserButton && !hasLDAP"
                      v-model="inviteDialog"
                      max-width="500px"
                    >
                      <template #activator="{ on }">
                        <v-btn color="primary" dark class="mr-2 text-none" v-on="on">
                          {{ $t('pages.users.invite') }}
                        </v-btn>
                      </template>

                      <EmailInvite :organization-id="$route.query.orgId" @close="onInviteClose()" />
                    </v-dialog>

                    <v-dialog v-model="dialog" max-width="500px">
                      <template #activator="{ on }">
                        <v-btn
                          v-if="!hasLDAP"
                          color="primary"
                          dark
                          class="text-none"
                          v-on="on"
                          @click="resetItem()"
                        >
                          {{ $t('pages.users.create') }}
                        </v-btn>
                      </template>

                      <v-form ref="form" @submit.prevent="save()">
                        <v-card>
                          <v-card-title>
                            <span class="headline text-truncate"
                              >{{ formTitle }} {{ editedItem.email }}</span
                            >
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="editedItem.username"
                                    :rules="getRequiredRule('username')"
                                    :label="$t('common.fields.username')"
                                    :disabled="editedIndex !== -1"
                                    required
                                    @input="clearError()"
                                  />
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="editedItem.firstname"
                                    :label="$t('common.fields.firstname')"
                                    :disabled="editedIndex !== -1"
                                    required
                                    @input="clearError()"
                                  />
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="editedItem.lastname"
                                    :label="$t('common.fields.lastname')"
                                    :disabled="editedIndex !== -1"
                                    required
                                    @input="clearError()"
                                  />
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="editedItem.email"
                                    :rules="emailRules"
                                    :label="$t('common.fields.email')"
                                    :disabled="editedIndex !== -1"
                                    required
                                    @input="clearError()"
                                  />
                                </v-col>

                                <template v-if="editedIndex !== -1">
                                  <v-col cols="12" sm="7" md="7">
                                    <v-switch
                                      v-model="editedItem.verified"
                                      :label="$t('pages.users.editModal.verified')"
                                      :disabled="editedIndex !== -1"
                                      hide-details
                                      class="mt-0"
                                    />
                                  </v-col>

                                  <v-col cols="12" sm="5" md="5">
                                    <v-switch
                                      v-model="editedItem.isApproved"
                                      :label="$t('pages.users.editModal.approved')"
                                      hide-details
                                      class="mt-0"
                                    />
                                  </v-col>
                                </template>

                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    v-model="editedItem.role"
                                    :rules="getRequiredRule('role')"
                                    :label="$t('common.fields.role')"
                                    :items="rolesItems"
                                    :disabled="isStartupPlanActive"
                                    @input="clearError()"
                                  />
                                </v-col>

                                <v-col v-if="editedIndex === -1" cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="editedItem.password"
                                    :rules="passwordRules"
                                    :label="$t('common.fields.password')"
                                    required
                                    type="password"
                                    @input="clearError()"
                                  />
                                </v-col>

                                <template v-if="editedIndex === -1">
                                  <v-col cols="12" sm="12" md="12">
                                    <v-switch
                                      v-model="editedItem.isPasswordChangeNeeded"
                                      :label="$t('pages.users.editModal.askForPasswordChange')"
                                    />
                                  </v-col>
                                </template>
                              </v-row>

                              <v-alert v-if="showedUserExistsError" color="red" type="error">
                                {{ $t('pages.users.editModal.existsError') }}
                              </v-alert>

                              <v-alert v-if="showPasswordUsedBeforeError" color="red" type="error">
                                {{ $t('pages.users.editModal.passwordUsedError') }}
                              </v-alert>

                              <v-alert
                                v-if="editedItemBlocked && editedItem.isHack"
                                color="primary"
                                type="info"
                              >
                                {{ $t('pages.users.editModal.hackInfo') }}
                              </v-alert>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <create-many-users @update="updateList()" />

                            <v-spacer />

                            <v-btn color="blue darken-1" text @click="close()">
                              {{ $t('ui.common.cancel') }}
                            </v-btn>

                            <v-btn color="blue darken-1" text type="submit">
                              {{ $t(`ui.common.${editedIndex === -1 ? 'create' : 'save'}`) }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-form>
                    </v-dialog>
                  </v-col>
                </v-row>

                <v-row v-if="selectedRows.length" justify="end">
                  <v-col cols="auto">
                    <v-btn class="mr-2 text-none " color="primary" dark @click="approveUsersList()">
                      {{ $t('pages.users.tableButtons.approve') }}
                    </v-btn>

                    <template v-if="!isSmartManualsPlansActive">
                      <v-btn class="mr-2 text-none" color="primary" dark @click="addToGroups()">
                        {{ $t('pages.users.tableButtons.addToGroups') }}
                      </v-btn>

                      <v-btn color="primary" dark class="text-none" @click="removeFromGroups()">
                        {{ $t('pages.users.tableButtons.removeFromGroups') }}
                      </v-btn>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </v-toolbar>
          </template>

          <template #item.email="{item}">
            {{ item.email }}

            <v-chip v-if="item.founderOfOrganization" color="primary" small class="ml-2">
              {{ $t('pages.users.founder') }}
            </v-chip>
          </template>

          <template #item.verified="{ item }">
            <v-row align="center">
              <v-col v-if="item.verified" cols="auto">
                <v-icon color="primary">mdi-check</v-icon>
              </v-col>

              <v-col cols="auto">
                <v-chip v-if="!item.isApproved" color="red" text-color="white">
                  {{ $t('pages.users.notApproved') }}
                </v-chip>
              </v-col>
            </v-row>
          </template>

          <template #item.firstname="{ item }">
            {{ getNameString(item.firstname) }}
          </template>

          <template #item.lastname="{ item }">
            {{ getNameString(item.lastname) }}
          </template>

          <template #item.role="{ item }">
            <user-role-chip :role="item.role" />
          </template>

          <template #item.action="{ item }">
            <template v-if="!hasLdap">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2 info--text"
                    v-bind="attrs"
                    @click="editItem(item)"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t(`common.actions.edit`) }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    small
                    class="error--text"
                    v-bind="attrs"
                    @click="deleteIfNotOwner(item)"
                    v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>{{ $t(`common.actions.delete`) }}</span>
              </v-tooltip>
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import debounce from 'lodash.debounce';

  import EmailInvite from 'src/components/EmailInvite.vue';
  import GroupDialog from 'src/components/GroupDialog.vue';
  import Toast from 'src/components/Toast/Toast.vue';
  import UserRoleChip from 'src/components/UserRoleChip.vue';
  import CreateManyUsers from 'src/pages/Users/components/CreateManyUsers/CreateManyUsers.vue';
  import OwnerDeletionDialog from 'src/pages/Users/components/OwnerDeletionDialog.vue';
  import ChangeOrganizationOwnerDialog from 'src/pages/Users/components/ChangeOrganizationOwnerDialog.vue';
  import TravoltaNotFound from 'src/components/TravoltaNotFound.vue';
  import { busyFlow } from 'src/utils';

  const defaultOptions = {
    itemsPerPage: 10,
    page: 1,
    pattern: undefined,
    sortBy: [],
    sortDesc: [],
  };

  const components = {
    EmailInvite,
    GroupDialog,
    Toast,
    UserRoleChip,
    CreateManyUsers,
    OwnerDeletionDialog,
    ChangeOrganizationOwnerDialog,
    TravoltaNotFound,
  };

  function data() {
    return {
      selectedRows: [],
      dialog: false,
      deleteOwnerDialog: false,
      changeOrgOwnerDialog: false,
      showedCreatedUsersToast: false,
      inviteDialog: false,
      hasGroupDialog: false,
      isGroupRemovingMode: false,
      editedIndex: -1,
      options: { ...defaultOptions },
      isBusy: false,
      showedUserExistsError: false,
      showPasswordUsedBeforeError: false,
      editedItem: {
        id: null,
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        verified: false,
        isPasswordChangeNeeded: false,
      },
      defaultItem: {
        id: null,
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        verified: false,
        role: 'USER',
        isPasswordChangeNeeded: false,
      },
      headers: [
        { text: 'Email', value: 'email' },
        { text: this.$t('pages.users.tableHeaders.firstname'), value: 'firstname' },
        { text: this.$t('pages.users.tableHeaders.lastname'), value: 'lastname' },
        { text: this.$t('pages.users.tableHeaders.role'), value: 'role' },
        { text: this.$t('pages.users.tableHeaders.verified'), value: 'verified' },
        { text: this.$t('ui.common.actions'), value: 'action', sortable: false },
      ],
      ownerItem: {},
      editedItemBlocked: false,
      editedItemBlockReason: null,
    };
  }

  const computed = {
    ...mapState('settings', ['showInviteUserButton', 'hasLDAP', 'allowedUserInfoFields']),
    ...mapState('users', ['usersList', 'usersCount', 'error', 'usersRoles', 'currentUser']),
    ...mapState('groups', ['groupUsers', 'groupUsersCount']),
    ...mapGetters('users', ['isSuperAdmin', 'isStartupPlanActive', 'isSmartManualsPlansActive']),

    rolesItems() {
      return this.usersRoles.map(role => ({ value: role, text: this.$t(`common.roles.${role}`) }));
    },

    emailRules() {
      return [
        v => !!v || this.$t('validation.notNullField', { field: this.$t('common.fields.email') }),
        v =>
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((([^<>()[\].,;:\s@"]{2,})+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
            v
          ) || this.$t('validation.emailInvalid'),
      ];
    },

    passwordRules() {
      if (this.editedIndex === -1 || this.editedItem.password?.length > 0) {
        return [
          v =>
            !!v || this.$t('validation.notNullField', { field: this.$t('common.fields.password') }),
          v => (v && v.length >= 6) || this.$t('validation.passwordLength'),
        ];
      }

      return [];
    },

    formTitle() {
      return this.$t(`pages.users.editModal.title.${this.editedIndex === -1 ? 'create' : 'edit'}`);
    },

    currentSearch: {
      get() {
        return this.options.pattern;
      },

      set(value) {
        this.debouncedSearch(value);
      },
    },

    isGroupMode() {
      return Boolean(this.$route.query.groupId);
    },

    items() {
      const { usersList, groupUsers } = this;
      const userList = this.isGroupMode ? groupUsers : usersList;
      const currentUserIndex = userList.findIndex(x => x.id === this.currentUser.id);
      if (currentUserIndex !== -1) {
        Object.keys(userList[currentUserIndex]).forEach(key => {
          if (!this.isFieldAllowedForListing(key)) {
            delete userList[currentUserIndex][key];
          }
        });
      }

      if (!this.isFieldAllowedForListing('role')) {
        userList[currentUserIndex].role = 'notShown';
      }

      if (!this.isFieldAllowedForListing('isApproved')) {
        userList[currentUserIndex].isApproved = 'notShown';
      }

      return userList;
    },

    itemsCount() {
      return this.isGroupMode ? this.groupUsersCount : this.usersCount;
    },

    mappedParams() {
      const {
        itemsPerPage,
        page = 1,
        pattern,
        sortBy: [filterBy],
        sortDesc: [isDesc],
      } = this.options;

      const limit = itemsPerPage === -1 ? 'all' : itemsPerPage;
      const filterDirection = (isDesc && 'desc') || 'asc';

      return {
        page,
        limit,
        pattern: pattern || undefined,
        filterBy,
        filterDirection,
        ...(this.isGroupMode
          ? { groupId: this.$route.query.groupId }
          : { organizationId: this.$route.query.orgId }),
      };
    },
  };

  const watch = {
    mappedParams() {
      this.updateList();
    },

    async dialog(value) {
      if (value) {
        this.showPasswordUsedBeforeError = false;
        this.showedUserExistsError = false;

        await this.$nextTick();
        this.$refs.form.resetValidation();
      } else {
        this.close();
      }
    },
  };

  async function mounted() {
    this.getUsersRoles();
  }

  const methods = {
    ...mapActions('users', ['list', 'remove', 'getUsersRoles', 'approveUsers']),
    ...mapActions('groups', ['getUsers', 'addUsers', 'removeUsers']),
    ...mapActions('organizations', ['getOrganization', 'getListWithParams']),

    getRequiredRule(name) {
      return [
        v => !!v || this.$t('validation.notNullField', { field: this.$t(`common.fields.${name}`) }),
      ];
    },

    getNameString(val) {
      if (val) {
        return val;
      }

      return '---';
    },

    getUsersList() {
      return this.list(this.mappedParams);
    },

    getGroupUsers() {
      return this.getUsers(this.mappedParams);
    },

    updateUsersList() {
      if (this.isGroupMode) {
        return this.getGroupUsers();
      }

      return this.getUsersList();
    },

    async updateList() {
      if (this.isBusy) {
        return;
      }

      this.isBusy = true;

      try {
        await this.updateUsersList();
      } catch (err) {
        console.error(err);
      } finally {
        this.isBusy = false;
      }
    },

    debouncedSearch: debounce(function search(pattern) {
      this.options = {
        ...defaultOptions,
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        pattern,
      };
    }, 300),

    editItem(item) {
      this.editedIndex = this.usersList.indexOf(item);
      this.editedItem = { ...item };
      this.editedItemBlocked = item.blocked;
      this.editedItemBlockReason = item.blockReason;
      this.dialog = true;
    },

    async deleteIfNotOwner(item) {
      if (item.founderOfOrganization) {
        this.ownerItem = item;
        this.deleteOwnerDialog = true;
        return;
      }

      this.deleteItem(item);
    },

    async deleteItem(item) {
      if (this.isBusy) {
        return;
      }

      this.isBusy = true;

      try {
        await this.remove(item.id);
      } catch (err) {
        console.error(err);
      } finally {
        this.isBusy = false;
        this.updateList();
      }
    },

    close() {
      this.dialog = false;

      setTimeout(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      }, 300);
    },

    resetItem() {
      this.editedIndex = -1;
      this.editedItem = { ...this.defaultItem };
    },

    async save() {
      this.showedUserExistsError = false;
      this.showPasswordUsedBeforeError = false;

      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.isBusy) {
        return;
      }

      this.isBusy = true;

      try {
        let payload = {
          isApproved: this.editedItem.isApproved,
          ...(this.isStartupPlanActive ? { role: 'USER' } : { role: this.editedItem.role }),
        };

        if (this.editedIndex === -1) {
          payload = {
            username: this.editedItem.username,
            email: this.editedItem.email,
            firstname: this.editedItem.firstname || null,
            lastname: this.editedItem.lastname || null,
            ...(this.isStartupPlanActive ? { role: 'USER' } : { role: this.editedItem.role }),
            ...(this.editedIndex !== -1
              ? { isApproved: this.editedItem.isApproved, verified: this.editedItem.verified }
              : {}),
            ...(this.editedIndex === -1
              ? { isPasswordChangeNeeded: this.editedItem.isPasswordChangeNeeded }
              : {}),
          };
        }

        if (this.editedItem.password && this.editedItem.password.length) {
          payload.password = this.editedItem.password;
        }

        if (this.editedIndex > -1) {
          if (this.editedItem.id === this.currentUser.id) {
            Object.keys(payload).forEach(key => {
              if (!this.isFieldAllowed(key)) {
                payload[key] = this.currentUser[key];
              }
            });
          }

          if (Object.keys(payload).length) {
            const userId = this.editedItem.id;
            await this.$store.dispatch('users/update', { userId, data: payload });
          }
        } else {
          await this.$store.dispatch('users/add', payload);
        }

        this.dialog = false;
      } catch (err) {
        if (err?.code === 'USER_EXISTS') {
          this.showedUserExistsError = true;
        }

        if (err?.code === 'PASSWORD_USED_BEFORE') {
          this.showPasswordUsedBeforeError = true;
        }

        console.error(err);
      } finally {
        this.isBusy = false;
      }

      this.updateList();
    },

    clearError() {
      this.$store.dispatch('users/clearError');
    },

    onInviteClose() {
      this.inviteDialog = !this.inviteDialog;
    },

    openGroupDialog() {
      this.hasGroupDialog = true;
    },

    closeGroupDialog() {
      this.hasGroupDialog = false;
    },

    closeOwnerDeletionDialog() {
      this.deleteOwnerDialog = false;
    },

    closeChangeOrganizationOwnerDialog() {
      this.changeOrgOwnerDialog = false;
    },

    changeOrganizationOwnerDialog() {
      this.deleteOwnerDialog = false;
      this.changeOrgOwnerDialog = true;
    },

    async approveUsersList() {
      if (this.isBusy) {
        return;
      }

      this.isBusy = true;

      const list = this.selectedRows.map(current => current.id);

      try {
        await this.approveUsers(list);
      } catch (err) {
        console.error('>>>>', err);
      } finally {
        this.isBusy = false;
      }

      this.updateList();
    },

    addToGroups() {
      this.isGroupRemovingMode = false;
      this.openGroupDialog();
    },

    removeFromGroups() {
      this.isGroupRemovingMode = true;
      this.openGroupDialog();
    },

    async removeUsersFromGroups(groups) {
      this.closeGroupDialog();

      if (!groups.length || this.isBusy) {
        return;
      }

      this.isBusy = true;

      const users = this.selectedRows.map(user => user.id);

      try {
        const promises = groups.map(groupId => this.removeUsers({ groupId, users }));
        await Promise.all(promises);
      } catch (err) {
        console.error(err);
      } finally {
        this.isBusy = false;
      }

      this.updateList();
    },

    async addUsersToGroups(groups) {
      this.closeGroupDialog();

      if (!groups.length || this.isBusy) {
        return;
      }

      this.isBusy = true;

      const users = this.selectedRows.map(user => user.id);

      try {
        const promises = groups.map(groupId => this.addUsers({ groupId, users }));
        await Promise.all(promises);
      } catch (err) {
        console.error(err);
      } finally {
        this.isBusy = false;
      }

      this.updateList();
    },

    debouncedSearchOrganization: debounce(async function search() {
      await busyFlow.call(
        this,
        () => this.findOrganizations(this.dialogOrganizationPattern),
        false,
        'isBusyOrganizations'
      );
    }, 300),

    async findOrganizations(pattern, limit = 10) {
      if (
        !this.isSuperAdmin ||
        !this.dialog ||
        (this.editedUserOrganization && this.editedUserOrganization.name === pattern)
      ) {
        return;
      }

      await this.getListWithParams({ pattern: pattern || undefined, limit, page: 1 });
    },

    async getEditedUserOrganization() {
      if (this.isSuperAdmin && this.editedItem.clientId && this.editedIndex !== -1) {
        this.editedUserOrganization = await this.getOrganization(this.editedItem.clientId);
      }

      this.findOrganizations();
    },

    openCreateManyDialog() {
      this.dialog = false;
      this.createManyDialog = true;
    },

    onCreatedUsers() {
      this.createManyDialog = false;
      this.showedCreatedUsersToast = true;

      this.updateList();
    },

    isFieldAllowed(fieldName) {
      if (
        this.allowedUserInfoFields === 'all' ||
        this.isSuperAdmin ||
        (this.editedItem.id !== this.currentUser.id && this.editedIndex > -1) ||
        this.editedIndex === -1
      ) {
        return true;
      }

      return this.allowedUserInfoFields.includes(fieldName);
    },

    isFieldAllowedForListing(fieldName) {
      if (this.allowedUserInfoFields === 'all' || this.isSuperAdmin) {
        return true;
      }

      return this.allowedUserInfoFields.includes(fieldName);
    },
  };

  export default {
    name: 'Users',
    components,
    data,
    computed,
    watch,
    mounted,
    methods,
  };
</script>
