<template>
  <v-card v-if="chartDataLoaded" class="mb-5 mx-2">
    <v-card-text class="pa-5">
      <base-table-title>
        {{ $t('pages.statistics.scenarioStepStatisticsChart.sectionTitle') }}:
      </base-table-title>

      <base-horizontal-chart-bar
        :chart-data="chartData"
        :colors="colors"
        :categories="categories"
        :bar-total-label-formatter="barTotalLabelFormatter"
        :tooltip-formatter="tooltipFormatter"
      />
    </v-card-text>
  </v-card>
</template>
<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import { busyFlow, getPercent } from 'src/utils';

  import BaseTableTitle from 'src/components/BaseTableTitle/BaseTableTitle.vue';
  import BaseHorizontalChartBar from 'src/components/BaseHorizontalChartBar/BaseHorizontalChartBar.vue';

  export default {
    name: 'ScenarioStepsStatisticsChart',

    components: {
      BaseTableTitle,
      BaseHorizontalChartBar,
    },

    data() {
      return {
        isBusy: false,
        colors: ['#71C589', '#E2C25D', '#ED6F69', '#4BA6DF'],
        barTotalLabelFormatter: (series, idx) =>
          `${getPercent(series[0].data[idx], series[3].data[idx])} %`,
        tooltipFormatter: (val, series, idx) =>
          `${val} (${getPercent(val, series[3].data[idx])} %)`,
      };
    },

    computed: {
      ...mapState('stats', {
        scenarioId: state => state.scenarioId,
        from: state => state.filter.from,
        to: state => state.filter.to,
      }),

      ...mapGetters('stats', {
        chartData: ['scenarioStepsStatisticsBarChartData'],
        categories: ['scenarioStepsStatisticsBarChartCategories'],
      }),

      chartDataLoaded() {
        return (
          this.scenarioId && !this.isBusy && this.chartData.length > 0 && this.categories.length > 0
        );
      },
    },

    watch: {
      scenarioId(value) {
        if (value) {
          this.loadScenarioStepsStatistics();
        }
      },

      from() {
        this.loadScenarioStepsStatistics();
      },

      to() {
        this.loadScenarioStepsStatistics();
      },
    },

    methods: {
      ...mapActions('stats', ['getScenarioStepsStatsChart']),

      loadScenarioStepsStatistics() {
        busyFlow.call(
          this,
          async () => {
            await this.getScenarioStepsStatsChart();
          },
          true
        );
      },
    },
  };
</script>
