<template>
  <v-form ref="form" @submit.prevent="onSubmit()">
    <v-card>
      <v-card-title>
        <span class="headline">
          <template v-if="isEdit">
            {{ $t('pages.organizations.editModal.workspaces.editTitle') }}
          </template>

          <template v-else>
            {{ $t('pages.organizations.editModal.workspaces.createTitle') }}
          </template>
        </span>
      </v-card-title>

      <v-card-text>
        <v-col>
          <v-text-field
            v-model="tempWorkspace.title"
            :label="$t('pages.organizations.editModal.workspaces.workspaceTitle')"
            :rules="[rules.getRequiredRule('title')]"
            :disabled="!workspaceEditable"
            outlined
            required
          />
        </v-col>

        <v-col>
          <v-text-field
            v-model="tempWorkspace.shortname"
            :label="$t('pages.organizations.editModal.workspaces.workspaceShortname')"
            :rules="[rules.getShortNameRule]"
            :disabled="!workspaceEditable"
            outlined
            required
          />
        </v-col>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="isEdit"
          color="blue darken-1"
          :disabled="!workspaceEditable"
          text
          @click="$emit('remove')"
        >
          {{ $t('ui.common.remove') }}
        </v-btn>
        <v-spacer />

        <v-btn color="blue darken-1" text @click="$emit('close')">
          {{ $t('ui.common.cancel') }}
        </v-btn>

        <v-btn :disabled="!workspaceEditable" color="blue darken-1" text type="submit">
          {{ $t('ui.common.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import { mapActions } from 'vuex';

  import api from 'src/api';
  import { deepcopy } from 'src/utils';

  export default {
    name: 'EditWorkspace',

    props: {
      workspace: { type: Object, required: true },
      isEdit: { type: Boolean, required: true },
    },

    data() {
      return {
        workspaceEditable: null,
        tempWorkspace: {},
        rules: {
          getRequiredRule: name => v =>
            !!v ||
            this.$t('validation.notNullField', {
              field: this.$t(`ui.workspaces.editDialog.fields.${name}`),
            }),
          getShortNameRule: v =>
            !v ||
            /^[a-z0-9]+$/i.test(v) ||
            this.$t('ui.workspaces.editDialog.validation.shortname'),
        },
      };
    },

    watch: {
      workspace: {
        deep: true,
        immediate: true,
        async handler() {
          this.tempWorkspace = deepcopy(this.workspace);
          this.workspaceEditable = await this.isWorkspaceEditable(this.workspace.id);
        },
      },
    },

    methods: {
      ...mapActions('workspaces', ['updateWorkspace']),

      async onSubmit() {
        if (this.isEdit) {
          await this.updateWorkspace({
            id: this.tempWorkspace.id,
            data: {
              title: this.tempWorkspace.title,
              shortname: this.tempWorkspace.shortname,
            },
          });

          return this.$emit('updated');
        }

        await api.workspaces.createByOrgId({
          url_params: { orgId: this.tempWorkspace.organizationId },
          data: { title: this.tempWorkspace.title, shortname: this.tempWorkspace.shortname },
        });

        return this.$emit('updated');
      },

      async isWorkspaceEditable(workspaceId) {
        if (this.isEdit) {
          const params = { id: workspaceId };
          const workspaceUsers = await api.workspaces.usersList({ url_params: params });
          return workspaceUsers.response.count === 0;
        }
        return true;
      },
    },
  };
</script>
