<template>
  <div>
    <div>
      <div class="block-header block-header--first">
        {{ $t('pages.styles.settings.simulationSystemSlide.title') }}
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="systemSlideBackgroundColor" />
        <div class="ml-4">
          {{ $t('pages.styles.settings.simulationSystemSlide.backgroundColor') }}
        </div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="systemSlideColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.simulationSystemSlide.textColor') }}</div>
      </div>

      <font-select
        v-model="systemSlideFontFamily"
        :label="$t('pages.styles.settings.simulationSystemSlide.textFont')"
        class="mt-5"
      />

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.simulationSystemSlide.allSidePadding') }}</div>

        <h-slider v-model="systemSlidePadding" max="100" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="systemSlidePaddingLabel"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.simulationSystemSlide.horizontalPadding') }}</div>

        <h-slider v-model="systemSlideHorizontalPadding" max="100" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="systemSlideHorizontalPadding"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.simulationSystemSlide.verticalPadding') }}</div>

        <h-slider v-model="systemSlideVerticalPadding" max="100" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="systemSlideVerticalPadding"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="mb-2">
        <v-switch
          v-model="systemSlideLogoShowed"
          :label="$t('pages.styles.settings.simulationSystemSlide.simulationSystemSlideLogoShowed')"
          hide-details
        />
      </div>

      <div v-if="systemSlideLogoShowed" class="d-flex align-center">
        <img :src="systemSlideLogoUrl || $options.DEFAULT_LOGO_URL" class="profile__logo" />

        <input
          ref="logoInput"
          type="file"
          accept="image/*"
          class="profile__logo-input"
          @change="uploadLogo($event)"
        />

        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              :disabled="systemSlideLogoUrl === null"
              v-bind="attrs"
              @click="systemSlideLogoUrl = null"
              v-on="on"
            >
              <v-icon>
                mdi-refresh
              </v-icon>
            </v-btn>
          </template>
          <span> {{ $t('pages.styles.settings.simulationSystemSlide.restoreLogo') }}</span>
        </v-tooltip>

        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              :loading="isBusyUploadLogo"
              icon
              v-bind="attrs"
              @click="$refs.logoInput.click()"
              v-on="on"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span> {{ $t('pages.styles.settings.simulationSystemSlide.uploadLogo') }}</span>
        </v-tooltip>
      </div>
    </div>

    <div>
      <div class="block-header">
        {{ $t('pages.styles.settings.simulationStyles') }}
      </div>

      <font-select
        v-model="popupFontFamily"
        :label="$t('pages.styles.settings.simulationPopupFont')"
      />

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="popupBackgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.simulationPopupBackground') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="popupColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.simulationPopupColor') }}</div>
      </div>

      <font-select
        v-model="popupButtonFontFamily"
        :label="$t('pages.styles.settings.simulationPopupButtonFont')"
        class="mt-5"
      />

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="popupButtonColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.simulationPopupButtonColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="popupButtonBackgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.simulationPopupButtonBackground') }}</div>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.simulationPopupPadding') }}</div>

        <h-slider v-model="popupPadding" max="50" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="popupPaddingLabel"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.simulationPopupHorizontalPadding') }}</div>

        <h-slider v-model="popupHorizontalPadding" max="50" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="popupHorizontalPadding"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.simulationPopupVerticalPadding') }}</div>

        <h-slider v-model="popupVerticalPadding" max="50" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="popupVerticalPadding"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="highlightColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.simulationHighlightColor') }}</div>
      </div>
    </div>

    <div>
      <div class="block-header">
        {{ $t('pages.styles.settings.simulationNavigation') }}
      </div>

      <font-select
        v-model="paginationFontFamily"
        :label="$t('pages.styles.settings.simulationNavigationFont')"
        class="mt-5"
      />

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="paginationBackgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.simulationNavigationBackground') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="paginationColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.simulationNavigationColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="paginationButtonBackgroundColor" />
        <div class="ml-4">
          {{ $t('pages.styles.settings.simulationNavigationButtonBackground') }}
        </div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="paginationButtonColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.simulationNavigationButtonColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="paginationSecondaryButtonColor" />
        <div class="ml-4">
          {{ $t('pages.styles.settings.simulationNavigationSecondaryButtonColor') }}
        </div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="paginationCompletedStepColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.simulationNavigationStepComplete') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="paginationUncompletedStepColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.simulationNavigationStep') }}</div>
      </div>
    </div>

    <div v-if="isEnterprisePlanActive || isSmartManualsPlansActive" class="mb-2">
      <v-switch
        v-model="copyrightHidden"
        :label="$t('pages.styles.settings.copyrightHidden')"
        hide-details
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import { buildSpecConfig } from 'src/config';

  import InlineColorPicker from 'src/components/InlineColorPicker.vue';
  import HSlider from 'src/HSlider/HSlider.vue';

  import stylesMixin from '../../mixins/stylesMixin';

  import { generateDynamicFields, MIX_VALUE, THEME_TYPE_CLASSIC } from '../../utils';

  import FontSelect from '../FontSelect.vue';
  import { busyFlow } from '../../../../utils';

  export default {
    name: 'SupportCenterAdvancedStyles',

    components: {
      InlineColorPicker,
      HSlider,
      FontSelect,
    },

    mixins: [stylesMixin],

    data() {
      return {
        initialLogoUrl: null,
        isBusyUploadLogo: false,
      };
    },

    THEME_TYPE_CLASSIC,
    DEFAULT_LOGO_URL: buildSpecConfig.logoUrl,

    computed: {
      ...mapGetters('users', ['isEnterprisePlanActive', 'isSmartManualsPlansActive']),

      ...generateDynamicFields([
        'systemSlidesShowed',
        'systemSlideBackgroundColor',
        'systemSlideColor',
        'systemSlideFontFamily',
        'systemSlidePaddingLeft',
        'systemSlidePaddingRight',
        'systemSlidePaddingTop',
        'systemSlidePaddingBottom',
        'systemSlideLogoShowed',
        'systemSlideLogoUrl',
        'popupFontFamily',
        'popupBackgroundColor',
        'popupColor',
        'popupButtonBackgroundColor',
        'popupButtonColor',
        'popupButtonFontFamily',
        'popupPaddingLeft',
        'popupPaddingRight',
        'popupPaddingTop',
        'popupPaddingBottom',
        'highlightColor',
        'paginationFontFamily',
        'paginationBackgroundColor',
        'paginationColor',
        'paginationButtonColor',
        'paginationSecondaryButtonColor',
        'paginationButtonBackgroundColor',
        'paginationCompletedStepColor',
        'paginationUncompletedStepColor',
        'copyrightHidden',
      ]),

      systemSlidePaddingLabel() {
        const {
          systemSlidePaddingLeft,
          systemSlidePaddingRight,
          systemSlidePaddingTop,
          systemSlidePaddingBottom,
        } = this.value;

        if (
          systemSlidePaddingLeft !== systemSlidePaddingRight ||
          systemSlidePaddingLeft !== systemSlidePaddingTop ||
          systemSlidePaddingLeft !== systemSlidePaddingBottom
        ) {
          return MIX_VALUE;
        }

        return systemSlidePaddingLeft;
      },

      systemSlidePadding: {
        get() {
          const {
            systemSlidePaddingLeft,
            systemSlidePaddingRight,
            systemSlidePaddingTop,
            systemSlidePaddingBottom,
          } = this.value;

          if (
            systemSlidePaddingLeft !== systemSlidePaddingRight ||
            systemSlidePaddingLeft !== systemSlidePaddingTop ||
            systemSlidePaddingLeft !== systemSlidePaddingBottom
          ) {
            return 0;
          }

          return systemSlidePaddingLeft;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            systemSlidePaddingLeft: value,
            systemSlidePaddingRight: value,
            systemSlidePaddingTop: value,
            systemSlidePaddingBottom: value,
          });
        },
      },

      systemSlideHorizontalPadding: {
        get() {
          const { systemSlidePaddingLeft, systemSlidePaddingRight } = this.value;

          if (systemSlidePaddingLeft !== systemSlidePaddingRight) {
            return 0;
          }

          return systemSlidePaddingLeft;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            systemSlidePaddingLeft: value,
            systemSlidePaddingRight: value,
          });
        },
      },

      systemSlideVerticalPadding: {
        get() {
          const { systemSlidePaddingTop, systemSlidePaddingBottom } = this.value;

          if (systemSlidePaddingTop !== systemSlidePaddingBottom) {
            return 0;
          }

          return systemSlidePaddingTop;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            systemSlidePaddingTop: value,
            systemSlidePaddingBottom: value,
          });
        },
      },

      popupPaddingLabel() {
        const {
          popupPaddingLeft,
          popupPaddingRight,
          popupPaddingTop,
          popupPaddingBottom,
        } = this.value;

        if (
          popupPaddingLeft !== popupPaddingRight ||
          popupPaddingLeft !== popupPaddingTop ||
          popupPaddingLeft !== popupPaddingBottom
        ) {
          return MIX_VALUE;
        }

        return popupPaddingLeft;
      },

      popupPadding: {
        get() {
          const {
            popupPaddingLeft,
            popupPaddingRight,
            popupPaddingTop,
            popupPaddingBottom,
          } = this.value;

          if (
            popupPaddingLeft !== popupPaddingRight ||
            popupPaddingLeft !== popupPaddingTop ||
            popupPaddingLeft !== popupPaddingBottom
          ) {
            return 0;
          }

          return popupPaddingLeft;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            popupPaddingLeft: value,
            popupPaddingRight: value,
            popupPaddingTop: value,
            popupPaddingBottom: value,
          });
        },
      },

      popupHorizontalPadding: {
        get() {
          const { popupPaddingLeft, popupPaddingRight } = this.value;

          if (popupPaddingLeft !== popupPaddingRight) {
            return 0;
          }

          return popupPaddingLeft;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            popupPaddingLeft: value,
            popupPaddingRight: value,
          });
        },
      },

      popupVerticalPadding: {
        get() {
          const { popupPaddingTop, popupPaddingBottom } = this.value;

          if (popupPaddingTop !== popupPaddingBottom) {
            return 0;
          }

          return popupPaddingTop;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            popupPaddingTop: value,
            popupPaddingBottom: value,
          });
        },
      },
    },

    methods: {
      ...mapActions('upload', ['uploadFile']),

      uploadLogo($event) {
        const [file] = $event.target.files;

        if (!file) {
          return;
        }

        busyFlow.call(
          this,
          async () => {
            this.systemSlideLogoUrl = await this.uploadFile(file);

            this.$refs.logoInput.value = '';
          },
          false,
          'isBusyUploadLogo'
        );
      },
    },
  };
</script>
