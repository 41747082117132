const list = {
  name: 'list',
  method: 'get',
  url: 'list',
};

const getListByIds = {
  name: 'getListByIds',
  method: 'post',
  url: 'list-by-ids',
};

const create = {
  name: 'create',
  method: 'post',
  url: '/api/tooltips',
};

const createMany = {
  name: 'createMany',
  method: 'post',
  url: 'create-many',
};

const update = {
  name: 'update',
  method: 'patch',
  url: '/api/tooltips',
};

const remove = {
  name: 'remove',
  method: 'delete',
  url: ':id',
};

const restore = {
  name: 'restore',
  method: 'post',
  url: ':id/restore',
};

const getVersions = {
  name: 'getVersions',
  method: 'get',
  url: 'version/:id/versions-list',
};

const getVersionData = {
  name: 'getVersionData',
  method: 'get',
  url: 'version/:versionId/',
};

const getVersionTags = {
  name: 'getVersionTags',
  method: 'get',
  url: 'version/:versionId/tags',
};

const updateVersionTags = {
  name: 'updateVersionTags',
  method: 'post',
  url: 'version/:versionId/tags',
};

const createVersion = {
  name: 'createVersion',
  method: 'post',
  url: 'version/:id/',
};

const createDraft = {
  name: 'createDraft',
  method: 'post',
  url: 'draft/:id',
};

const getDraft = {
  name: 'getDraft',
  method: 'get',
  url: 'draft/:id',
};

const rejectDraft = {
  name: 'rejectDraft',
  method: 'post',
  url: 'draft/:id/reject',
};

const approveDraft = {
  name: 'approveDraft',
  method: 'post',
  url: 'draft/:id/approve',
};

const updateDraft = {
  name: 'updateDraft',
  method: 'patch',
  url: 'draft/:id',
};

const changeWorkspace = {
  name: 'changeWorkspace',
  method: 'post',
  url: 'change-workspace',
};

const unbindTag = {
  name: 'unbindTag',
  method: 'post',
  url: 'unbind-tag',
};

const bindTag = {
  name: 'bindTag',
  method: 'post',
  url: 'bind-tag',
};

const replaceTag = {
  name: 'replaceTag',
  method: 'post',
  url: 'replace-tag',
};

export default {
  name: 'tooltips',
  url: '/api/tooltips',
  children: [
    list,
    getListByIds,
    create,
    createMany,
    update,
    remove,
    restore,
    getVersions,
    getVersionData,
    getVersionTags,
    updateVersionTags,
    createVersion,
    createDraft,
    getDraft,
    rejectDraft,
    approveDraft,
    updateDraft,
    changeWorkspace,
    unbindTag,
    bindTag,
    replaceTag,
  ],
};
