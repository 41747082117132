<template>
  <v-container>
    <v-tabs v-model="selectedMode" class="mb-4" height="54">
      <v-tab
        class="text-none"
        @click="sendMetrics('walkthroughs_extension_list_selected', 'select')"
      >
        <v-icon class="mr-3">mdi-google-chrome</v-icon>

        {{ $t('pages.scenarios.extensionTitle', { companyName: $options.COMPANY_NAME }) }}
      </v-tab>

      <v-tab class="text-none" @click="sendMetrics('walkthroughs_desktop_list_selected', 'select')">
        <v-icon class="mr-3">mdi-monitor</v-icon>

        {{ $options.DESKTOP_NAME }}
      </v-tab>
    </v-tabs>

    <widget-entities v-if="mode === 0" />

    <desktop-entities v-else />
  </v-container>
</template>

<script>
  import localStorage from 'src/services/localStorage';
  import { buildSpecConfig } from 'src/config';

  import WidgetEntities from 'src/components/WidgetEntities/WidgetEntities.vue';
  import DesktopEntities from 'src/components/DesktopEntities/DesktopEntities.vue';

  export default {
    name: 'Scenarios',

    components: {
      WidgetEntities,
      DesktopEntities,
    },

    inject: ['sendMetrics'],

    data() {
      return {
        mode: localStorage.get('scenariosMode') || 0,
      };
    },

    COMPANY_NAME: buildSpecConfig.companyName,
    DESKTOP_NAME: buildSpecConfig.desktopName,

    computed: {
      selectedMode: {
        get() {
          return this.mode;
        },

        set(value) {
          localStorage.set('scenariosMode', value);
          this.mode = value;
        },
      },
    },
  };
</script>

<style lang="scss"></style>
