var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","class-name":"down-top-padding"}},[_c('v-row',{attrs:{"class-name":"pb-0"}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex pa-5"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h3',{staticClass:"title font-weight-regular"},[_c('span',{staticClass:"lstick"}),_vm._v(" "+_vm._s(_vm.$t('pages.statistics.statistics'))+": ")])]),_c('v-col',{attrs:{"cols":"3","md":"2","xl":"3"}},[_c('v-menu',{ref:"startDate",attrs:{"close-on-content-click":false,"return-value":_vm.startDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.startDate=$event},"update:return-value":function($event){_vm.startDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('pages.statistics.startDate'),"prepend-icon":"mdi-calendar","hide-details":"","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.minDate,"max":_vm.maxDate,"no-title":"","scrollable":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.startDateMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t('ui.common.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.startDate.save(_vm.startDate)}}},[_vm._v(" "+_vm._s(_vm.$t('ui.common.ok'))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"3","md":"2","xl":"3"}},[_c('v-menu',{ref:"endDate",attrs:{"close-on-content-click":false,"return-value":_vm.endDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.endDate=$event},"update:return-value":function($event){_vm.endDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('pages.statistics.endDate'),"prepend-icon":"mdi-calendar","readonly":"","hide-details":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.startDate || _vm.minDate,"max":_vm.maxDate,"no-title":"","scrollable":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.endDateMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t('ui.common.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.endDate.save(_vm.endDate)}}},[_vm._v(" "+_vm._s(_vm.$t('ui.common.ok'))+" ")])],1)],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"primary","small":""}},'v-btn',props,false),on),[_vm._v(" "+_vm._s(_vm.$t('pages.statistics.downloadStatistics'))+" ")])]}}])},[_c('v-list',_vm._l((_vm.downloadItems),function(item){return _c('v-list-item',{key:item.value,attrs:{"value":item.value},on:{"click":function($event){return _vm.downloadFile(item.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('stat-cards',{attrs:{"cards":_vm.cards,"stats":_vm.cardsData}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',[_c('help-center-chart',{attrs:{"chart-data":_vm.helpCenterChartData}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',[_c('help-center-hidden-scenarios-chart',{attrs:{"chart-data":_vm.helpCenterScenarioVisibilityChartData}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }