<template>
  <v-card class="mb-5">
    <v-card-text class="pa-5">
      <div class="d-sm-flex align-center">
        <div>
          <h3 class="title font-weight-regular">
            <span class="lstick" />
            {{ $t('pages.statistics.stepsInterruptedStatistics') }}:
          </h3>
        </div>
      </div>

      <div class="mt-2">
        <vue-apex-charts
          class="chart"
          height="350"
          :series="interruptedChartData"
          :options="chartOptions"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex';
  import VueApexCharts from 'vue-apexcharts';

  export default {
    name: 'InterruptedStepsChart',

    components: {
      VueApexCharts,
    },

    data() {
      return {
        chartOptions: Object.freeze({
          chart: {
            height: 370,
            type: 'area',
            stacked: false,
            fontFamily: 'Montserrat,sans-serif',
            toolbar: {
              show: false,
            },
          },

          dataLabels: {
            enabled: false,
          },

          markers: {
            size: 3,
            strokeColors: 'transparent',
          },

          stroke: {
            curve: 'smooth',
            width: '2',
          },

          colors: ['#abceff', '#398bf7', '#7460ee', '#9c94ee'],

          legend: {
            show: false,
          },

          grid: {
            show: true,
            strokeDashArray: 0,
            borderColor: 'rgba(0,0,0,0.1)',
            yaxis: {
              show: false,
            },
          },

          yaxis: {
            labels: {
              formatter: value => {
                return value.toFixed(0);
              },

              style: {
                colors: '#a1aab2',
              },
            },
          },

          tooltip: {
            theme: 'dark',
            fillColors: ['#abceff', '#398bf7', '#7460ee', '#9c94ee'],
            y: {
              title: {
                formatter: () => `${this.$t('pages.statistics.charts.interrupted')}:`,
              },
            },
          },
        }),
      };
    },

    computed: {
      ...mapState('stats', ['interruptedAndTotalChartData']),

      interruptedChartData() {
        const data = this.interruptedAndTotalChartData;

        if (!data.labels?.length) {
          return [];
        }

        const dataset =
          data.datasets.find(datasetItem => datasetItem.label === 'interrupted')?.data || [];

        return [
          {
            data: dataset.map(item => ({
              ...item,
              x: `${this.$t('pages.statistics.charts.step')} ${item.x.split(' ').pop()}`,
            })),
            name: 'interrupted',
          },
        ];
      },
    },
  };
</script>

<style lang="scss"></style>
