<template>
  <div>
    <div>
      <div class="block-header block-header--first">
        {{ $t('pages.styles.settings.themeColors') }}
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="buttonBackgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.primaryColor') }}</div>
      </div>

      <div v-if="themeType !== $options.THEME_TYPE_CLASSIC" class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="borderColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.secondaryColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="backgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.backgroundColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="color" />
        <div class="ml-4">{{ $t('pages.styles.settings.fontColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="buttonColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.secondaryFontColor') }}</div>
      </div>
    </div>

    <template v-if="type === 'border'">
      <div class="d-flex align-center mb-3 mt-3">
        <v-select
          v-model="position"
          :items="$options.hintPositionOptions"
          outlined
          hide-details
          dense
          class="input-control"
        >
          <template #item="{item}">
            {{ $t(`pages.styles.settings.hintPositionOptions.${item.text}`) }}
          </template>

          <template #selection="{item}">
            {{ $t(`pages.styles.settings.hintPositionOptions.${item.text}`) }}
          </template>
        </v-select>

        <div class="ml-4">{{ $t('pages.styles.settings.position') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="frameColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.borderColor') }}</div>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.borderWidth') }}</div>

        <h-slider v-model="frameSize" max="30" min="2" hide-details>
          <template #append>
            <v-text-field
              v-model="frameSize"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>
    </template>

    <div>
      <div class="block-header">
        {{ $t('pages.styles.settings.generalSettings') }}
      </div>

      <font-select v-model="fontFamily" />

      <div class="input-control mb-4">
        <div>{{ $t('pages.styles.settings.allSidePaddingModal') }}</div>

        <h-slider v-model="padding" max="30" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="paddingLabel"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="input-control">
        <div>{{ $t('pages.styles.settings.buttonBorderRadius') }}</div>

        <h-slider v-model="buttonBorderRadius" max="10" min="0" hide-details>
          <template #append>
            <v-text-field
              v-model="buttonBorderRadius"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div v-if="isEnterprisePlanActive" class="mb-2">
        <v-switch
          v-model="copyrightHidden"
          :label="$t('pages.styles.settings.copyrightHidden')"
          hide-details
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import InlineColorPicker from 'src/components/InlineColorPicker.vue';
  import HSlider from 'src/HSlider/HSlider.vue';

  import stylesMixin from '../../mixins/stylesMixin';
  import {
    generateDynamicFields,
    hintPositionOptions,
    MIX_VALUE,
    THEME_TYPE_CLASSIC,
  } from '../../utils';

  import FontSelect from '../FontSelect.vue';

  export default {
    name: 'ModalGeneralStyles',

    components: {
      InlineColorPicker,
      HSlider,
      FontSelect,
    },

    mixins: [stylesMixin],

    hintPositionOptions,

    THEME_TYPE_CLASSIC,

    computed: {
      ...mapGetters('users', ['isEnterprisePlanActive']),

      ...generateDynamicFields([
        'backgroundColor',
        'borderColor',
        'buttonBackgroundColor',
        'buttonBorderRadius',
        'buttonColor',
        'color',
        'fontFamily',
        'type',
        'frameColor',
        'frameSize',
      ]),

      ...generateDynamicFields(['position', 'copyrightHidden'], 'updateSettings', 'settings'),

      paddingLabel() {
        const { paddingLeft, paddingRight, paddingTop, paddingBottom } = this.value;

        if (
          paddingLeft !== paddingRight ||
          paddingLeft !== paddingTop ||
          paddingLeft !== paddingBottom
        ) {
          return MIX_VALUE;
        }

        return paddingLeft;
      },

      padding: {
        get() {
          return this.paddingLabel === MIX_VALUE ? 0 : this.paddingLabel;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingLeft: value,
            paddingRight: value,
            paddingTop: value,
            paddingBottom: value,
          });
        },
      },
    },

    watch: {
      type(value) {
        if (value === 'default') {
          this.position = undefined;
        } else {
          this.position = 'element';
        }
      },
    },
  };
</script>
