<template>
  <div>
    <div>
      <div class="block-header block-header--first">
        {{ $t('pages.styles.settings.hintStyles') }}
      </div>

      <font-select v-model="fontFamily" />

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="backgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.backgroundColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="color" />
        <div class="ml-4">{{ $t('pages.styles.settings.fontColor') }}</div>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.allSidePadding') }}</div>

        <h-slider v-model="padding" max="30" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="paddingLabel"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.horizontalPadding') }}</div>

        <h-slider v-model="horizontalPadding" max="30" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="horizontalPaddingLabel"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.verticalPadding') }}</div>

        <h-slider v-model="verticalPadding" max="30" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="verticalPaddingLabel"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>
    </div>

    <template v-if="themeType !== $options.THEME_TYPE_CLASSIC">
      <div class="mb-2">
        <v-switch
          v-model="isBorderEnabled"
          :label="$t('pages.styles.settings.border')"
          hide-details
        />
      </div>

      <template v-if="isBorderEnabled">
        <div class="d-flex align-center mb-3">
          <InlineColorPicker :color.sync="borderColor" />
          <div class="ml-4">{{ $t('pages.styles.settings.borderColor') }}</div>
        </div>

        <div class="input-control mb-3">
          <div>{{ $t('pages.styles.settings.borderWidth') }}</div>

          <h-slider v-model="borderLineWidth" max="10" min="2" hide-details>
            <template #append>
              <v-text-field
                v-model="borderLineWidth"
                hide-details
                dense
                disabled
                class="mt-0 pt-0 slider-input"
              />
            </template>
          </h-slider>
        </div>
      </template>
    </template>

    <div class="mb-2">
      <v-switch
        v-model="isShadowEnabled"
        :label="$t('pages.styles.settings.shadow')"
        hide-details
      />
    </div>

    <template v-if="isShadowEnabled">
      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="shadowColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.shadowColor') }}</div>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.shadowXOffset') }}</div>

        <h-slider v-model="shadowXOffset" max="50" min="-50" hide-details>
          <template #append>
            <v-text-field
              v-model="shadowXOffset"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.shadowYOffset') }}</div>

        <h-slider v-model="shadowYOffset" max="50" min="-50" hide-details>
          <template #append>
            <v-text-field
              v-model="shadowYOffset"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.shadowBlur') }}</div>

        <h-slider v-model="shadowBlurRadius" max="50" min="0" hide-details>
          <template #append>
            <v-text-field
              v-model="shadowBlurRadius"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.shadowSpreadRadius') }}</div>

        <h-slider v-model="shadowSpreadRadius" max="50" min="0" hide-details>
          <template #append>
            <v-text-field
              v-model="shadowSpreadRadius"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>
    </template>

    <div class="mt-2 mb-4">
      <v-switch
        v-model="isStepCountEnabled"
        :label="$t('pages.styles.settings.isStepCountEnabled')"
        hide-details
      />
    </div>

    <div class="input-control mt-6 mb-4">
      <h-text-field
        v-model.number="zIndex"
        :label="$t('pages.styles.settings.zIndex')"
        :default-title="$t('common.defaultTitles.default')"
        :default-value="0"
        outlined
        hide-details
        persistent-placeholder
        number-input
        positive-number
        dense
      />
    </div>

    <div v-if="isEnterprisePlanActive" class="mb-2">
      <v-switch
        v-model="copyrightHidden"
        :label="$t('pages.styles.settings.copyrightHidden')"
        hide-details
      />
    </div>

    <div>
      <div class="block-header">
        {{ $t('pages.styles.settings.buttonStyles') }}
      </div>

      <font-select
        v-model="buttonFontFamily"
        :label="$t('pages.styles.settings.buttonFontFamily')"
      />

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="buttonBackgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.buttonBackgroundColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="buttonColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.buttonFontColor') }}</div>
      </div>

      <div class="input-control">
        <div>{{ $t('pages.styles.settings.buttonBorderRadius') }}</div>

        <h-slider v-model="buttonBorderRadius" max="10" min="0" hide-details>
          <template #append>
            <v-text-field
              v-model="buttonBorderRadius"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>
    </div>

    <div>
      <div class="block-header">
        {{ $t('pages.styles.settings.highlightType') }}
      </div>

      <v-btn-toggle v-model="type" mandatory class="">
        <v-btn value="default">
          <v-icon size="17">
            mdi-triangle mdi-rotate-180
          </v-icon>
        </v-btn>
        <v-btn value="border">
          <v-icon size="25">
            mdi-rectangle-outline
          </v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>

    <template v-if="type === 'border'">
      <div class="d-flex align-center mb-3 mt-3">
        <v-select
          v-model="position"
          :items="$options.hintPositionOptions"
          outlined
          hide-details
          dense
          class="input-control"
        >
          <template #item="{item}">
            {{ $t(`pages.styles.settings.hintPositionOptions.${item.text}`) }}
          </template>

          <template #selection="{item}">
            {{ $t(`pages.styles.settings.hintPositionOptions.${item.text}`) }}
          </template>
        </v-select>

        <div class="ml-4">{{ $t('pages.styles.settings.position') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="frameColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.borderColor') }}</div>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.borderWidth') }}</div>

        <h-slider v-model="frameSize" max="30" min="2" hide-details>
          <template #append>
            <v-text-field
              v-model="frameSize"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import InlineColorPicker from 'src/components/InlineColorPicker.vue';
  import HSlider from 'src/HSlider/HSlider.vue';
  import HTextField from 'src/components/HTextField/HTextField.vue';

  import {
    generateDynamicFields,
    hintPositionOptions,
    MIX_VALUE,
    THEME_TYPE_CLASSIC,
  } from '../../utils';
  import stylesMixin from '../../mixins/stylesMixin';

  import FontSelect from '../FontSelect.vue';

  export default {
    name: 'HintAdvancedStyles',

    components: {
      InlineColorPicker,
      HSlider,
      HTextField,
      FontSelect,
    },

    mixins: [stylesMixin],
    hintPositionOptions,

    THEME_TYPE_CLASSIC,

    computed: {
      ...mapGetters('users', ['isEnterprisePlanActive']),

      ...generateDynamicFields([
        'backgroundColor',
        'borderColor',
        'borderLineWidth',
        'buttonBackgroundColor',
        'buttonBorderRadius',
        'buttonColor',
        'buttonFontFamily',
        'color',
        'fontFamily',
        'isBorderEnabled',
        'isShadowEnabled',
        'shadowBlurRadius',
        'shadowColor',
        'shadowSpreadRadius',
        'shadowXOffset',
        'shadowYOffset',
        'type',
        'frameColor',
        'frameSize',
        'zIndex',
      ]),

      ...generateDynamicFields(
        ['position', 'copyrightHidden', 'isStepCountEnabled'],
        'updateSettings',
        'settings'
      ),

      paddingLabel() {
        const { paddingLeft, paddingRight, paddingTop, paddingBottom } = this.value;

        if (
          paddingLeft !== paddingRight ||
          paddingLeft !== paddingTop ||
          paddingLeft !== paddingBottom
        ) {
          return MIX_VALUE;
        }

        return paddingLeft;
      },

      padding: {
        get() {
          return this.paddingLabel === MIX_VALUE ? 0 : this.paddingLabel;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingLeft: value,
            paddingRight: value,
            paddingTop: value,
            paddingBottom: value,
          });
        },
      },

      horizontalPaddingLabel() {
        const { paddingLeft, paddingRight } = this.value;

        if (paddingLeft !== paddingRight) {
          return MIX_VALUE;
        }

        return paddingLeft;
      },

      horizontalPadding: {
        get() {
          return this.horizontalPaddingLabel === MIX_VALUE ? 0 : this.horizontalPaddingLabel;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingLeft: value,
            paddingRight: value,
          });
        },
      },

      verticalPaddingLabel() {
        const { paddingTop, paddingBottom } = this.value;

        if (paddingTop !== paddingBottom) {
          return MIX_VALUE;
        }

        return paddingTop;
      },

      verticalPadding: {
        get() {
          return this.verticalPaddingLabel === MIX_VALUE ? 0 : this.verticalPaddingLabel;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingTop: value,
            paddingBottom: value,
          });
        },
      },
    },

    watch: {
      type(value) {
        if (value === 'default') {
          this.position = undefined;
        } else {
          this.position = 'element';
        }
      },
    },
  };
</script>

<style lang="scss"></style>
