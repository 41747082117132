<template>
  <svg
    v-if="$options.BUILD_SPEC === $options.APP_BUILD_SPEC_RU"
    width="28"
    height="8"
    viewBox="0 0 175 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="logo-icon"
  >
    <g clip-path="url(#clip0_517_11445)">
      <path
        d="M0.076355 49.02L7.29667 23.8659L0.557709 -0.0429688H8.17915L12.9927 17.9717L17.9667 -0.0429688H25.9893L18.9294 23.9489L25.8288 49.02H18.1271L13.0729 31.0054L8.17915 49.02H0.076355ZM31.6742 49.02V13.8679H38.5368V34.4621L46.3603 13.8679H53.223V49.02H46.3603V26.5085C44.942 30.2959 43.5466 34.0597 42.1741 37.7998C40.8015 41.4925 39.4062 45.2326 37.9878 49.02H31.6742ZM59.7361 49.02V13.8679H67.5596V27.7157H73.736V13.8679H81.4222V49.02H73.736V33.8229H67.5596V49.02H59.7361ZM92.8716 49.02V19.6911H86.4207V13.8679H107.352V19.6911H100.832V49.02H92.8716Z"
        fill="#353349"
        class="hint-logo"
      />
      <path
        d="M143.053 49.0001V42.1967H145.524C146.026 42.102 146.598 41.8416 147.239 41.4155C147.879 40.9421 148.451 40.232 148.955 39.2851C149.504 38.2909 149.869 36.9889 150.053 35.3793L152.524 11.8025H172.082V42.1967H174.95V45.8094V49.0101L171.467 49.0001H170.984H149.573H143.053ZM155.68 42.1967H163.778V17.6257H159.661L157.739 34.9532C157.556 36.3262 157.327 37.7465 157.053 39.2141C156.778 40.6817 156.32 41.6759 155.68 42.1967Z"
        fill="#D81159"
        class="ed-logo"
      />
      <path
        d="M138.761 49.03H112.123V0H137.66V6.91141H120.029V20.7842H136.287V27.6556H120.029V42.1586H138.761V49.03Z"
        fill="#D81159"
        class="ed-logo"
      />
    </g>
    <defs>
      <clipPath id="clip0_517_11445">
        <rect width="174.95" height="49.01" fill="white" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="8"
    viewBox="0 0 114 32"
    fill="none"
    class="logo-icon"
  >
    <g clip-path="url(#clip0_217_7970)">
      <path
        d="M20.3109 32.0065H15.2674V18.1253H5.19339V32.0065H-0.0195312V0H5.19339V13.4568H15.2674V0H20.3109V31.9935V32.0065Z"
        fill="#34344A"
        class="hint-logo"
      ></path>
      <path
        d="M29.1608 31.8525H24V9H29.1673L29.1608 31.8525Z"
        fill="#343349"
        class="hint-logo"
      ></path>
      <path
        d="M54.5662 32.0063H49.3989V19.1306C49.3989 14.8692 47.8915 12.7384 44.8767 12.7384C44.1341 12.7194 43.3968 12.8686 42.7199 13.175C42.0429 13.4814 41.4437 13.937 40.967 14.5079C39.9185 15.7585 39.3705 17.3546 39.4292 18.987V32.0455H34.2163V9.19303H39.4292V12.9865H39.5205C40.2286 11.6465 41.2962 10.5312 42.6029 9.76637C43.9096 9.00154 45.4035 8.61754 46.9163 8.65763C49.3924 8.65763 51.2843 9.46291 52.5918 11.0735C53.8994 12.684 54.551 15.0172 54.5467 18.0729L54.5662 32.0063Z"
        fill="#34344A"
        class="hint-logo"
      ></path>
      <path
        d="M69.5077 31.2428C68.2572 31.8018 66.8945 32.0632 65.5264 32.0067C61.0954 32.0067 58.8821 29.8782 58.8864 25.6211V12.6996H55.081V8.63841H58.8864V4.50537H64.0537V8.63841H69.5077V12.6996H64.0537V24.1259C64.0537 25.4839 64.3013 26.4503 64.7901 27.0314C65.1099 27.3473 65.4952 27.5888 65.9186 27.7389C66.342 27.889 66.7932 27.9439 67.2401 27.8998C68.0605 27.9164 68.8614 27.6489 69.5077 27.1424V31.2428Z"
        fill="#34344A"
        class="hint-logo"
      ></path>
      <path
        d="M89.6166 32.0065H71.6581V0H88.8737V4.51173H76.9883V13.5678H87.9485V18.0535H76.9883V27.5209H89.6166V32.0065Z"
        fill="#D61C5A"
        class="ed-logo"
      ></path>
      <path
        d="M114.013 32.0065H108.8V28.1216H108.715C107.047 31.0663 103.75 32.0065 101.033 32.0065C98.3156 32.0065 95.9763 31.5168 94.2952 29.4732C92.614 27.4295 91.7669 24.6415 91.7669 21.1157C91.7669 17.3374 92.6987 14.31 94.5623 12.0335C95.4522 10.922 96.5892 10.0343 97.8823 9.44142C99.1755 8.84851 100.589 8.56676 102.01 8.61865C105.086 8.61865 107.314 9.85268 108.715 12.3208H108.8V0H114.013V32.0065ZM108.917 21.5597V18.5693C108.952 17.0386 108.383 15.5559 107.334 14.4428C106.822 13.8876 106.197 13.4489 105.501 13.1561C104.805 12.8633 104.055 12.7231 103.3 12.7452C102.422 12.7098 101.548 12.8885 100.753 13.2661C99.9589 13.6437 99.2677 14.2088 98.7392 14.9129C97.6271 16.358 97.071 18.3582 97.071 20.9133C97.071 23.229 97.601 25.0616 98.661 26.4109C99.1641 27.06 99.8142 27.5797 100.558 27.9271C101.301 28.2746 102.116 28.4397 102.936 28.4089C103.754 28.4321 104.567 28.2677 105.312 27.9282C106.057 27.5888 106.716 27.0832 107.236 26.4501C108.359 25.0733 108.949 23.337 108.898 21.5597H108.917Z"
        fill="#D61C5A"
        class="ed-logo"
      ></path>
      <path
        d="M30.6063 4.60324C30.6076 4.40979 30.5857 4.21689 30.5412 4.02866C30.4978 3.84594 30.4344 3.66858 30.3522 3.49979C30.2687 3.32909 30.1683 3.16722 30.0525 3.01662C29.9337 2.86484 29.8006 2.7249 29.655 2.59875C29.5051 2.46895 29.3437 2.35312 29.1728 2.2527C28.9959 2.15008 28.8106 2.06271 28.6189 1.99153C28.4176 1.91964 28.2105 1.86504 27.9999 1.82829C27.7848 1.7894 27.5668 1.76974 27.3483 1.76953C27.1297 1.76946 26.9117 1.78913 26.6967 1.82829C26.4905 1.86575 26.2878 1.92035 26.0907 1.99153C25.899 2.06271 25.7137 2.15008 25.5368 2.2527C25.3659 2.35312 25.2045 2.46895 25.0546 2.59875C24.909 2.7249 24.7759 2.86484 24.6571 3.01662C24.5413 3.16722 24.4409 3.32909 24.3574 3.49979C24.2752 3.66858 24.2118 3.84594 24.1684 4.02866C24.1239 4.21689 24.102 4.40979 24.1032 4.60324C24.1016 4.79455 24.1235 4.98533 24.1684 5.17129C24.2107 5.35641 24.2741 5.53605 24.3574 5.70669C24.441 5.8753 24.5414 6.03499 24.6571 6.18332C24.774 6.33865 24.9073 6.48093 25.0546 6.60773C25.206 6.73556 25.3673 6.85126 25.5368 6.95378C25.7137 7.05639 25.899 7.14376 26.0907 7.21495C26.2878 7.28613 26.4905 7.34073 26.6967 7.37818C26.9117 7.41735 27.1297 7.43702 27.3483 7.43695C27.5668 7.43674 27.7848 7.41707 27.9999 7.37818C28.2061 7.34073 28.4088 7.28613 28.6059 7.21495C28.7976 7.14376 28.9828 7.05639 29.1598 6.95378C29.3357 6.84931 30.463 7.561 30.6129 7.43695C30.7627 7.31289 29.9547 6.34003 30.0525 6.20944C30.1682 6.06111 30.2686 5.90142 30.3522 5.7328C30.4354 5.56217 30.4989 5.38253 30.5412 5.1974C30.5883 5.00297 30.6102 4.80327 30.6063 4.60324Z"
        fill="#D61C5A"
        class="ed-logo"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_217_7970">
        <rect width="114" height="32" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  import { buildSpec } from 'src/config';

  import { APP_BUILD_SPEC_RU } from 'src/constants';

  export default {
    name: 'CopyrightLogo',

    BUILD_SPEC: buildSpec,

    APP_BUILD_SPEC_RU,
  };
</script>
