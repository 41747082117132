<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-col lg="11" sm="8" xl="7">
        <v-card class="elevation-4">
          <v-row>
            <v-col lg="7" class="info d-none d-md-flex align-center justify-center">
              <div class="d-none d-sm-block">
                <div class="d-flex align-center pa-10">
                  <div>
                    <h2 class="display-1 white--text font-weight-medium">
                      {{ $t('pages.login.aside.title', { companyName: $options.COMPANY_NAME }) }}
                    </h2>

                    <h6 class="subtitle-1 mt-4 white--text op-5 font-weight-regular">
                      {{ $t('pages.login.aside.message') }}
                    </h6>
                  </div>
                </div>
              </div>
            </v-col>

            <v-col lg="5">
              <div class="pa-7 pa-sm-12">
                <v-alert v-if="isRegistered" dense type="info">
                  {{ $t('pages.login.message.registered') }}
                </v-alert>

                <v-alert v-if="isInvited" dense type="info">
                  {{ $t('pages.login.message.invited') }}
                </v-alert>

                <v-alert v-if="isRecovered" dense type="info">
                  {{ $t('pages.login.message.recovered') }}
                </v-alert>

                <v-alert v-if="isVerified" dense type="success">
                  {{ $t('pages.login.message.verified') }}
                </v-alert>

                <v-alert v-if="error" dense type="error">
                  {{ error }}
                </v-alert>

                <img :src="$options.LOGO_URL" :alt="$options.COMPANY_NAME" />

                <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
                  {{ $t('pages.login.title') }}
                </h2>

                <h6 v-if="!hasLDAP" class="subtitle-1">
                  <i18n path="pages.login.dontHaveAccountMessage">
                    <router-link to="/signup">{{ $t('pages.login.signUp') }}</router-link>
                  </i18n>
                </h6>

                <v-form ref="form" @submit.prevent="onSubmit()">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    :error="!!error"
                    :label="$t('pages.login.form.email')"
                    class="mt-4"
                    required
                    outlined
                  />

                  <v-text-field
                    ref="passwordField"
                    v-model="password"
                    :rules="passwordRules"
                    :error="!!error"
                    :label="$t('pages.login.form.password')"
                    required
                    outlined
                    :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    @click:append="togglePasswordVisibility"
                    @focus="$event.target.select()"
                  />

                  <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                    <div class="ml-auto">
                      <router-link to="/recover" class="link">
                        {{ $t('pages.login.recover') }}
                      </router-link>
                    </div>
                  </div>

                  <v-btn color="info" block class="mr-4" type="submit">
                    {{ $t('pages.login.action') }}
                  </v-btn>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import moment from 'moment';

  import localStorage from 'src/services/localStorage';
  import { isAuth } from 'src/services/session';
  import { buildSpecConfig } from 'src/config';

  export default {
    name: 'Login',

    data() {
      return {
        isBusy: false,
        error: null,
        isValid: true,
        isPasswordVisible: false,
        password: '',
        email: '',
      };
    },

    COMPANY_NAME: buildSpecConfig.companyName,
    LOGO_URL: buildSpecConfig.logoUrl,

    computed: {
      ...mapState('settings', ['hasLDAP', 'kerberosEnabled']),
      ...mapGetters('users', ['isUserLoaded']),
      ...mapState('users', ['currentUser']),

      passwordRules() {
        return [v => !!v || this.$t('validation.passwordEmpty')];
      },

      emailRules() {
        return [v => !!v || this.$t('validation.emailOrLoginEmpty')];
      },

      isVerified() {
        return this.$route.query.status === 'verified';
      },

      isRegistered() {
        return this.$route.query.status === 'registered';
      },

      isRecovered() {
        return this.$route.query.status === 'recovered';
      },

      isInvited() {
        return this.$route.query.status === 'invited';
      },
    },

    async mounted() {
      await this.onLoad();

      if (!this.isUserLoaded && this.kerberosEnabled) {
        this.integratedAuth();
      }
    },

    methods: {
      ...mapActions('auth', ['auth', 'integrated']),
      ...mapActions('users', ['getCurrentUser', 'setLocale']),

      validate() {
        this.isValid = this.$refs.form.validate();
      },

      togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible;
      },

      async onSubmit() {
        this.error = null;

        this.validate();

        if (!this.isValid) {
          return;
        }

        if (this.isBusy) {
          return;
        }

        this.isBusy = true;

        try {
          const response = await this.auth({
            username: this.email,
            password: this.password,
          });

          localStorage.set('loginPhone', response.phone);
          localStorage.set('loginEmail', response.email);
          localStorage.set('loginOrganizationId', response.organizationId);

          if (this.currentUser.organizationId) {
            this.$router.push(
              this.$route.query.next
                ? { path: decodeURIComponent(String(this.$route.query.next)) }
                : { name: 'Dashboard' }
            );
          } else {
            this.$router.push({
              name: 'OrganizationSelect',
              ...(this.$route.query.next ? { query: { next: this.$route.query.next } } : {}),
            });
          }
        } catch (err) {
          const excludedCodes = [
            'PAYMENT_REQUIRED',
            'BLOCKED_USER',
            'WRONG_CREDENTIALS',
            'WRONG_PASSWORD',
          ];
          if (!excludedCodes.includes(err.code)) {
            this.error = this.$te(`serverCode.${err.code}`)
              ? this.$t(`serverCode.${err.code}`)
              : this.$t('serverCode.serverError');
          }

          if (err.code === 'BLOCKED_USER') {
            const { isHack, blockEndsAt } = err.details;

            if (isHack) {
              const endsAt = moment.unix(blockEndsAt);
              const dateNow = moment();

              this.error = blockEndsAt
                ? `${this.$t(`serverCode.TEMP_BLOCKED_USER_HACK`, {
                    mins: endsAt.diff(dateNow, 'minutes'),
                  })} ${this.$tc('ui.plurals.minutes', endsAt.diff(dateNow, 'minutes'))}`
                : this.$t('serverCode.PERM_BLOCKED_USER_HACK');
            } else {
              this.error = this.$te(`serverCode.${err.code}`)
                ? this.$t(`serverCode.${err.code}`)
                : this.$t('serverCode.serverError');
            }
          }

          if (err.code === 'WRONG_CREDENTIALS' || err.code === 'WRONG_PASSWORD') {
            this.error = this.$t(`serverCode.${err.code}`);
            this.$nextTick();
            this.$refs.passwordField.focus();
          }
        } finally {
          this.isBusy = false;
        }
      },

      async integratedAuth() {
        this.isBusy = true;

        try {
          await this.integrated();

          this.$router.push({ name: 'Dashboard' });
        } catch (error) {
          this.isBusy = false;
        }
      },

      async onLoad() {
        if (!isAuth()) {
          return;
        }

        this.isBusy = true;

        try {
          if (!isAuth()) {
            this.isBusy = false;

            return;
          }

          await this.getCurrentUser();

          this.$router.push({ name: 'Dashboard' });
        } catch (error) {
          this.isBusy = false;
        }
      },
    },
  };
</script>
