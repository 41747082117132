import VueI18n from 'vue-i18n';
import Vue from 'vue';
import { getUserLocale } from 'get-user-locale';
import localeCode from 'locale-code';

import localStorage from 'src/services/localStorage';

import en from 'src/translations/en.json';
import ru from 'src/translations/ru.json';
import pt from 'src/translations/pt.json';
import es from 'src/translations/es.json';

Vue.use(VueI18n);

const availableLocales = ['ru', 'en', 'pt', 'es'];

const getCurrentLocale = () => {
  const userLocale = getUserLocale();

  if (availableLocales.includes(userLocale)) {
    return userLocale;
  }

  const langCode = localeCode.getLanguageCode(userLocale);

  return availableLocales.includes(langCode) ? langCode : 'en';
};

const messages = {
  en,
  ru,
  pt,
  es,
};

const i18Instance = new VueI18n({
  locale: localStorage.get('interfaceLocale') || getCurrentLocale(),
  fallbackLocale: 'en',
  messages,
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    ru(choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
});

export const i18n = i18Instance;
export const currentLocale = getCurrentLocale();
export const isRu = getCurrentLocale() === 'ru';
export const isEn = getCurrentLocale() === 'en';
