import invites from 'src/store/modules/invites';
import stats from 'src/store/modules/stats';
import auth from 'src/store/modules/auth';
import users from 'src/store/modules/users';
import organizations from 'src/store/modules/organizations';
import scenarios from 'src/store/modules/scenarios';
import tooltips from 'src/store/modules/tooltips';
import groups from 'src/store/modules/groups';
import elementStyle from 'src/store/modules/elementStyle';
import upload from 'src/store/modules/upload';
import legal from 'src/store/modules/legal';
import trace from 'src/store/modules/trace';
import versions from 'src/store/modules/versions';
import workspaces from 'src/store/modules/workspaces';
import settings from 'src/store/modules/settings';
import notifications from 'src/store/modules/notifications';

export default {
  auth,
  users,
  organizations,
  scenarios,
  tooltips,
  invites,
  stats,
  groups,
  elementStyle,
  upload,
  legal,
  trace,
  versions,
  workspaces,
  settings,
  notifications,
};
