<template>
  <v-snackbar v-model="showToast" :color="color" :timeout="timeout">
    <slot />

    <template v-if="closable" #action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="$emit('close')">
        {{ $t('ui.common.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  export default {
    name: 'Toast',

    props: {
      opened: {
        type: Boolean,
        required: true,
      },
      color: {
        type: String,
        required: false,
        default: 'success',
      },
      closable: {
        type: Boolean,
        required: false,
        default: true,
      },
      timeout: {
        type: Number,
        required: false,
        default: 3000,
      },
    },

    computed: {
      showToast: {
        get() {
          return this.opened;
        },
        set(value) {
          this.$emit('update:opened', value);
        },
      },
    },
  };
</script>
