<script>
  import { mapActions, mapState } from 'vuex';

  import api from 'src/api';

  import BaseAddingForm from './BaseAddingForm.vue';

  export default {
    name: 'WorkspaceUsersAddingForm',

    extends: BaseAddingForm,

    computed: {
      ...mapState('users', ['usersList', 'usersCount', 'currentUser']),

      title() {
        return this.$t('pages.groups.addingModal.title.workspaces', {
          title: this.entity.title,
        });
      },

      allListTitle() {
        return this.$t('pages.groups.addingModal.all.workspaces');
      },

      addedListTitle() {
        return this.$t('pages.groups.addingModal.inGroup.workspaces');
      },

      allItems() {
        return this.usersList;
      },

      allItemsCount() {
        return this.usersCount;
      },
    },

    methods: {
      ...mapActions('users', { getAllUsers: 'list' }),

      itemInEntity(item) {
        return item.isInWorkspace;
      },

      mapItem(item) {
        return {
          ...item,
          title: item.email,
          subtitle: this.$t(`common.roles.${item.role.toUpperCase()}`),
        };
      },

      removeFromEntityMethod(user) {
        return api.workspaces.removeUser({
          url_params: { id: this.entity.id },
          data: { userId: user.id },
        });
      },

      addToEntityMethod(user) {
        return api.workspaces.addUser({
          url_params: { id: this.entity.id },
          data: { userId: user.id },
        });
      },

      async getAddedListMethod() {
        const params = this.currentAddedListParams;

        const queryParams = {
          limit: params.limit,
          page: params.page,
          ...(params.pattern ? { pattern: params.pattern } : {}),
        };

        const {
          response: { payload, count },
        } = await api.workspaces.usersList({
          url_params: { id: this.entity.id },
          params: queryParams,
        });

        this.addedList = payload;
        this.addedCount = count;
      },

      async getAllListMethod() {
        const { limit, page, pattern } = this.currentAllListParams;
        const workspaceId = this.entity.id;
        const { organizationId } = this.currentUser;

        await this.getAllUsers({ limit, page, pattern, workspaceId, organizationId });
      },
    },
  };
</script>
