<template>
  <div>
    <editor :holder="holder" :config="config" list :initialized="onInit" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import Parser from 'editorjs-parser';
  import defaultConfig, {
    ScenarioLink,
    ScenarioStopLink,
    UploadVideo,
    UploadVideoFromLink,
    VideoLink,
    VideoManager,
  } from '@hinted/editorjs-custom-plugins';
  import isEqual from 'lodash.isequal';
  import omit from 'lodash.omit';

  import { randomString } from 'src/utils';

  export default {
    name: 'TextEditor',

    props: {
      html: {
        type: String,
        required: false,
        default: null,
      },

      json: {
        type: Object,
        required: false,
        default: () => ({}),
      },

      desktopMode: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        editor: null,
        isReady: false,
        holder: randomString(),

        parser: new Parser(undefined, {
          scenarioLink(data) {
            return ScenarioLink.getRenderedHtml(data);
          },
          scenarioStopLink(data) {
            return ScenarioStopLink.getRenderedHtml(data);
          },

          videoLink(data) {
            return VideoLink.getRenderedHtml(data);
          },
        }),
      };
    },

    computed: {
      config() {
        return {
          defaultConfig,
          tools: {
            ...(this.desktopMode
              ? omit(defaultConfig.tools, [
                  'scenarioStopLink',
                  'scenarioLink',
                  'scenarioInlineLink',
                ])
              : {
                  ...defaultConfig.tools,
                  scenarioLink: {
                    ...defaultConfig.tools.scenarioLink,
                    config: {
                      searchFunction: async searchString => {
                        const params = { pattern: searchString, limit: 5 };
                        const { payload } = await this.getList(params);
                        return payload;
                      },
                    },
                  },

                  scenarioInlineLink: {
                    ...defaultConfig.tools.scenarioInlineLink,
                    config: {
                      searchFunction: async searchString => {
                        const params = { pattern: searchString, limit: 5 };
                        const { payload } = await this.getList(params);
                        return payload;
                      },
                    },
                  },
                }),

            header: {
              ...defaultConfig.tools.header,
              config: {
                placeholder: `Header`,
                levels: [1, 2, 3, 4, 5, 6],
                defaultLevel: 2,
              },
            },

            image: {
              ...defaultConfig.tools.image,
              config: {
                uploader: {
                  uploadByFile: this.editorUploadFile,
                  uploadByUrl: this.editorUploadByUrl,
                },
              },
            },

            videoLink: VideoLink,

            uploadVideoFromLink: UploadVideoFromLink,

            videoManager: {
              class: VideoManager,
              config: {
                uploadVideo: this.editorUploadFile,
              },
            },

            video: {
              class: UploadVideo,
              config: {
                uploadVideo: url => this.editorUploadFile(url),
              },
            },
          },
          onChange: this.onChange,
          onReady: this.onReady,
        };
      },
    },

    async beforeDestroy() {
      await this.editor.isReady;
      this.editor.destroy();
    },

    methods: {
      ...mapActions('scenarios', ['getList']),
      ...mapActions('upload', ['uploadFile', 'uploadByUrl']),

      onReady() {
        this.isReady = true;
      },

      async onInit(editor) {
        this.editor = editor;
        await this.editor.isReady;
        this.fixElement();

        const hasJsonScheme = typeof this.json === 'object' && this.json?.blocks?.length;

        if (hasJsonScheme) {
          return this.editor.blocks.render(this.json);
        }

        if (!hasJsonScheme && typeof this.html === 'string' && this.html.trim().length) {
          const prefix = '<!-- There is deprecated text block. Please fix it with new editor -->';
          return this.editor.blocks.insert('raw', { html: `${prefix}\n${this.html}` });
        }

        this.$emit('on-ready');
        return false;
      },

      async onChange(editor) {
        this.fixElement();
        // Hack according to editor.js issue https://github.com/codex-team/editor.js/issues/1755#issuecomment-929550729
        await this.$nextTick();
        const data = await editor.saver.save();

        const html = this.parser.parse(data);
        // Hack here: Prevent emit update then changed only time
        if (isEqual(omit(data, ['time']), omit(this.json, ['time']))) {
          return;
        }

        this.$emit('update:json', data);
        await this.$nextTick();
        this.$emit('update:html', html);
      },

      fixElement() {
        if (!this.$el || !this.$el.querySelector) {
          return;
        }

        this.$el
          .querySelector('.codex-editor__redactor')
          .setAttribute('style', 'padding-bottom: 20px; margin-right: 15px; margin-left: 10px');
      },

      async editorUploadFile(file) {
        try {
          const fileUrl = await this.uploadFile(file);

          return {
            success: 1,
            file: {
              url: fileUrl,
            },
          };
        } catch (err) {
          console.error(err);

          return {
            success: 0,
          };
        }
      },

      async editorUploadByUrl(url) {
        try {
          const fileUrl = await this.uploadByUrl(url);

          return {
            success: 1,
            file: {
              url: fileUrl,
            },
          };
        } catch (err) {
          console.error(err);

          return {
            success: 0,
          };
        }
      },
    },
  };
</script>

<style lang="scss">
  @import '@hinted/editorjs-custom-plugins/libs/scenarioLink/scenarioLink.css';
  @import '@hinted/editorjs-custom-plugins/libs/scenarioStopLink/scenarioStopLink.css';
  @import '@hinted/editorjs-custom-plugins/libs/videoLink/videoLink.css';
  @import '@hinted/editorjs-custom-plugins/libs/uploadVideo/uploadVideo.css';
  @import '@hinted/editorjs-custom-plugins/alignBlocks/style.css';
  @import '@hinted/editorjs-custom-plugins/libs/videoManager/videoManager.css';
  @import '@hinted/editorjs-custom-plugins/libs/uploadVideoFromLink/uploadVideoFromLink.css';

  .codex-editor {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.38);
  }
  .codex-editor__loader {
    height: 30px !important;
  }

  .ce-toolbar__settings-btn {
    margin-right: 10px;
  }

  .ct {
    z-index: 2147483630;
  }

  .ct__content {
    font-family: Roboto, sans-serif;
  }

  .cdx-settings-button--active {
    color: #388ae5 !important;
  }

  .ce-inline-tool--active {
    color: #388ae5 !important;
  }
</style>
