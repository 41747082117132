<template>
  <div />
</template>

<script>
  import { apiUrl } from 'src/config';
  import localStorage from 'src/services/localStorage';
  import { currentLocale } from 'src/plugins/i18n';

  export default {
    name: 'SimulationView',

    async mounted() {
      await this.$loadScript('/hinted-simulation-player.js');

      const interfaceLocale = localStorage.get('interfaceLocale');

      window.hintedSimulationPlayer.init(apiUrl);
      window.hintedSimulationPlayer.setLocale(interfaceLocale || currentLocale);
      window.hintedSimulationPlayer.onClose(() => window.close());
      window.hintedSimulationPlayer.playSimulation(this.$route.params.id);
    },
  };
</script>

<style lang="scss"></style>
