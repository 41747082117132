<template>
  <v-btn
    class="billing-button elevation-0"
    :class="[`billing-button--${theme}`]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
  export default {
    name: 'BillingButton',

    props: {
      theme: {
        type: String,
        required: false,
        default: 'blue',
      },
    },
  };
</script>

<style lang="scss">
  .billing-button {
    text-transform: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.00892857em;
    background-color: transparent !important;

    &--blue {
      color: #7474bf !important;
      background: linear-gradient(
        86.41deg,
        rgba(116, 116, 191, 0.2) 2.91%,
        rgba(52, 138, 199, 0.2) 99.13%
      );
    }

    &--white {
      color: #ffffff !important;
      background: rgba(255, 255, 255, 0.3) !important;
    }

    &--green {
      color: #ffffff !important;
      background: linear-gradient(86.41deg, #43c6ac 2.91%, rgba(22, 73, 84, 0.66) 99.13%);
    }

    &--gray {
      color: #343349;
      background: #f5f5f5 !important;
    }
  }
</style>
