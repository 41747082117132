const getPortalUrl = {
  name: 'getPortalUrl',
  method: 'get',
  url: 'portal',
};

const getSubscription = {
  name: 'getSubscription',
  method: 'get',
  url: 'subscription',
};

const subscribe = {
  name: 'subscribe',
  method: 'post',
  url: 'subscribe',
};

export default {
  name: 'payments',
  url: '/api/v2/payments',
  children: [getPortalUrl, subscribe, getSubscription],
};
