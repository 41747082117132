<template>
  <div class="payment-period">
    <div class="payment-period__label">{{ $t('pages.billing.periodLabel') }}:</div>

    <v-btn-toggle
      v-model="localValue"
      borderless
      mandatory
      active-class="payment-period__item--active"
    >
      <billing-button x-large :value="$options.PERIOD_MONTHLY" class="payment-period__item">
        {{ $t('pages.billing.monthly') }}
      </billing-button>

      <billing-button x-large :value="$options.PERIOD_YEARLY" class="payment-period__item">
        {{ $t('pages.billing.yearly') }} ({{ $t('pages.billing.discount', { discount: 20 }) }})
      </billing-button>
    </v-btn-toggle>
  </div>
</template>

<script>
  import BillingButton from '../BillingButton/BillingButton.vue';

  import { PERIOD_MONTHLY, PERIOD_YEARLY } from '../../utils/subscriptionPeriods';

  export default {
    name: 'PaymentPeriod',

    components: {
      BillingButton,
    },

    model: {
      prop: 'modelValue',
      event: 'update:modelValue',
    },

    props: {
      modelValue: {
        type: String,
        required: false,
        default: PERIOD_YEARLY,
      },
    },

    PERIOD_MONTHLY,
    PERIOD_YEARLY,

    computed: {
      localValue: {
        get() {
          return this.modelValue;
        },

        set(value) {
          this.$emit('update:modelValue', value);
        },
      },
    },
  };
</script>

<style lang="scss">
  .payment-period {
    display: flex;
    align-items: center;

    &__label {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.005em;
      color: #343349;
      margin-right: 20px;
    }

    &__item {
      text-transform: uppercase;

      &--active {
        background: linear-gradient(86.41deg, #7474bf 2.91%, #348ac7 99.13%);
        color: #ffffff !important;
      }
    }
  }
</style>
