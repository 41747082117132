<template>
  <v-card width="100%" elevation="0" class="billing-info">
    <div class="billing-info__header">
      <div>{{ $t('pages.billing.currentPlan') }}: {{ planName }}</div>

      <billing-button x-large :loading="isLoading" @click="goToStripe()">
        {{ $t('pages.billing.billingInfoButton') }}

        <v-icon right>mdi-arrow-right</v-icon>
      </billing-button>
    </div>

    <div class="billing-info__content">
      <div class="billing-info__content-item content-item">
        <div class="content-item__title">{{ $t('pages.billing.price') }}:</div>

        <div class="content-item__value">
          {{ price }}
        </div>
      </div>

      <div class="billing-info__content-item content-item">
        <div class="content-item__title">{{ $t('pages.billing.nextPaymentDate') }}:</div>

        <div class="content-item__value">
          {{ nextPaymentDate }}
        </div>
      </div>

      <div class="billing-info__content-item content-item">
        <div class="content-item__title">{{ $t('pages.billing.daysLeft') }}:</div>

        <div class="content-item__value">
          <v-icon v-if="subscription.daysLeft === -1" size="34" color="#343349">
            mdi-infinity
          </v-icon>

          <span v-else>
            {{ subscription.daysLeft }}
          </span>
        </div>
      </div>

      <div v-if="subscription.balance" class="billing-info__content-item content-item">
        <div class="content-item__title">{{ $t('pages.billing.balance') }}:</div>

        <div class="content-item__value">${{ subscription.balance }}</div>
      </div>
    </div>
  </v-card>
</template>

<script>
  import moment from 'moment';

  import api from 'src/api';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  import {
    ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,
    ORGANIZATION_PLAN_SMART_MANUALS_PRO,
    ORGANIZATION_PLAN_SMART_MANUALS_START,
  } from 'src/constants';

  import { PERIOD_MONTHLY } from '../../utils/subscriptionPeriods';

  import BillingButton from '../BillingButton/BillingButton.vue';

  export default {
    name: 'BillingInfo',

    components: {
      BillingButton,
    },

    mixins: [formatTimestampMixin],

    props: {
      subscription: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        isLoading: false,
      };
    },

    computed: {
      price() {
        if (typeof this.subscription.price === 'string') {
          return this.subscription.price;
        }

        const period = this.$t(
          `pages.billing.${this.subscription.price.period === PERIOD_MONTHLY ? 'month' : 'year'}`
        );

        return `${this.subscription.price.cost}$/${period}`;
      },

      nextPaymentDate() {
        if (this.subscription.nextPaymentDate === -1) {
          return '-';
        }

        return this.formatDate(moment.unix(this.subscription.nextPaymentDate).toDate());
      },

      planName() {
        if (!this.subscription.plan) {
          return '-';
        }

        switch (this.subscription.plan) {
          case ORGANIZATION_PLAN_SMART_MANUALS_START:
            return 'Smart Manuals Start';
          case ORGANIZATION_PLAN_SMART_MANUALS_PRO:
            return 'Smart Manuals Pro';
          case ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE:
            return 'Smart Manuals Enterprise';
          default:
            throw new Error('Unknown plan');
        }
      },
    },

    methods: {
      async goToStripe() {
        const { response } = await api.payments.getPortalUrl();

        window.location.href = response.url;
      },
    },
  };
</script>

<style lang="scss">
  .billing-info {
    padding: 30px;

    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 34px;
      line-height: 40px;
      letter-spacing: 0.0025em;
      color: #343349;
      margin-bottom: 40px;
    }

    &__content {
      display: flex;
      justify-content: space-between;
    }

    .content-item {
      display: flex;
      align-items: flex-end;
      color: #343349;

      &__title {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.005em;
      }

      &__value {
        margin-left: 10px;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.0025em;
      }
    }
  }
</style>
