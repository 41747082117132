<template>
  <v-card class="integrator-balance-modal">
    <v-card-title class="pa-4 primary">
      <span class="ma-1 title white--text">
        {{ $t('pages.accounts.accountRemoveModal.title', { email: editingAccount.email }) }}
      </span>

      <v-spacer />

      <v-btn icon color="white" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pt-6">
      <span class="black--text subtitle-1">
        {{ $t('pages.accounts.accountRemoveModal.text') }}
      </span>

      <v-alert v-if="serverError" color="red" type="error" class="mt-5 mb-0">
        {{ serverError }}
      </v-alert>
    </v-card-text>

    <v-card-actions>
      <v-spacer />

      <v-btn color="grey" text @click="$emit('close')">
        {{ $t('ui.common.cancel') }}
      </v-btn>

      <v-btn :loading="isBusy" :disabled="isBusy" color="primary" @click="remove()">
        {{ $t('ui.common.remove') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import api from 'src/api';

  const USER_IS_OWNER_OF_ORGANIZATION_CODE = 'USER_IS_OWNER_OF_ORGANIZATION';

  export default {
    name: 'AccountRemoveModal',

    props: {
      editingAccount: {
        type: Object,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        isBusy: false,

        serverError: null,
      };
    },

    methods: {
      async remove() {
        if (this.isBusy) {
          return;
        }

        try {
          await api.accounts.remove({ url_params: { id: this.editingAccount.id } });

          this.$emit('removed');
          this.close();
        } catch (error) {
          switch (error.code) {
            case USER_IS_OWNER_OF_ORGANIZATION_CODE:
              this.serverError = this.$t('serverCode.USER_IS_OWNER_OF_ORGANIZATION', {
                organizationName: error.details.organizationName,
              });
              break;
            default:
              this.serverError = this.$te(`serverCode.${error.code}`)
                ? this.$t(`serverCode.${error.code}`)
                : this.$t('serverCode.serverError');
              break;
          }
        } finally {
          this.isBusy = false;
        }
      },

      close() {
        this.$emit('close');
      },
    },
  };
</script>
