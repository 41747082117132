<template>
  <v-container id="recover" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-col lg="11" sm="8" xl="7">
        <v-card class="elevation-4">
          <v-row>
            <v-col lg="7" class="info d-none d-md-flex align-center justify-center">
              <div class="d-none d-sm-block">
                <div class="d-flex align-center pa-10">
                  <div>
                    <h2 class="display-1 white--text font-weight-medium">
                      {{ $t('pages.login.aside.title', { companyName: $options.COMPANY_NAME }) }}
                    </h2>

                    <h6 class="subtitle-1 mt-4 white--text op-5 font-weight-regular">
                      {{ $t('pages.login.aside.message') }}
                    </h6>
                  </div>
                </div>
              </div>
            </v-col>

            <v-col lg="5">
              <div class="pa-7 pa-sm-12">
                <img :src="$options.LOGO_URL" :alt="$options.COMPANY_NAME" />

                <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
                  {{ $t('pages.recover.title') }}
                </h2>

                <h6 class="subtitle-1">
                  <router-link to="/login">{{ $t('pages.recover.login') }}</router-link>
                </h6>

                <v-form ref="form" v-model="isValid" lazy-validation @submit.prevent="onSubmit">
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    :error-messages="error ? [error] : []"
                    :label="$t('pages.recover.form.password')"
                    required
                    outlined
                    :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    @click:append="togglePasswordVisibility"
                    @input="error = null"
                  />

                  <v-text-field
                    v-model="passwordRepeat"
                    :rules="passwordRepeatRules"
                    :label="$t('pages.recover.form.passwordConfirm')"
                    outlined
                    type="password"
                    validate-on-blur
                  />

                  <v-btn :disabled="!isValid" color="info" block class="mr-4" type="submit">
                    {{ $t('pages.recover.action') }}
                  </v-btn>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex';

  import { buildSpecConfig } from 'src/config';

  export default {
    name: 'AcceptRecover',

    data() {
      return {
        error: null,
        isValid: true,
        isPasswordVisible: false,
        password: null,
        passwordRepeat: null,
      };
    },

    COMPANY_NAME: buildSpecConfig.companyName,
    LOGO_URL: buildSpecConfig.logoUrl,

    computed: {
      passwordRules() {
        return [
          v => !!v || this.$t('validation.passwordEmpty'),
          v => (v && v.length >= 6) || this.$t('validation.passwordLength'),
        ];
      },

      passwordRepeatRules() {
        return [
          ...this.passwordRules,
          v => v === this.password || this.$t('validation.passwordsDiff'),
        ];
      },

      code() {
        return this.$route.params.code;
      },
    },

    methods: {
      ...mapActions('users', ['recoverPassword']),

      validate() {
        this.isValid = this.$refs.form.validate();
      },

      togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible;
      },

      async onSubmit() {
        this.validate();

        if (this.isBusy) {
          return;
        }

        this.isBusy = true;

        const payload = {
          code: this.code,
          password: this.password,
          passwordRepeat: this.passwordRepeat,
        };

        try {
          await this.recoverPassword(payload);
          this.$router.push({ name: 'login', query: { status: 'recovered' } });
        } catch (err) {
          this.error = this.$te(`serverCode.${err.code}`)
            ? this.$t(`serverCode.${err.code}`)
            : this.$t('serverCode.serverError');
        } finally {
          this.isBusy = false;
        }
      },
    },
  };
</script>
