<template>
  <v-card-text class="pt-6">
    <v-combobox
      v-model="unbindTag"
      :items="filteredTags"
      :filter="filterTags"
      :label="$t('ui.manageTagsModal.unbindTagLabel')"
      type="button"
      item-text="title"
      item-value="id"
      item-disabled="isSystemTag"
      small-chips
      hide-selected
      persistent-hint
      outlined
    >
      <template #selection="{ attrs, item, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :color="item.color"
          :input-value="selected"
          small
        >
          <span>
            {{ item.title }}
          </span>

          <v-icon small class="ml-2" @click="unbindTag = null">
            $delete
          </v-icon>
        </v-chip>
      </template>

      <template #item="{ item }">
        <v-chip :color="item.color" small>
          {{ item.title }}
        </v-chip>
      </template>
    </v-combobox>

    <v-combobox
      v-model="bindTag"
      :items="filteredTags"
      :filter="filterTags"
      :type="isRealAdminOrHigher ? 'text' : 'button'"
      :label="$t('ui.manageTagsModal.bindTagLabel')"
      :hint="$t('ui.manageTagsModal.bindTagHint')"
      item-text="title"
      item-value="id"
      item-disabled="isSystemTag"
      small-chips
      hide-selected
      persistent-hint
      outlined
    >
      <template #selection="{ attrs, item, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :color="item.color"
          :input-value="selected"
          small
        >
          <span>
            {{ item.title }}
          </span>

          <v-icon small class="ml-2" @click="bindTag = null">
            $delete
          </v-icon>
        </v-chip>
      </template>

      <template #item="{ item }">
        <span v-if="item.id === deletingTagId" class="mr-1">
          {{ $t('pages.scenarios.editModal.tagsDeleteConfirm') }}
        </span>

        <v-chip :color="item.color" small>
          {{ item.title }}
        </v-chip>

        <span v-if="item.id === deletingTagId" class="ml-1">
          ?
        </span>

        <v-spacer />

        <v-list-item-action
          v-if="isRealAdminOrHigher"
          class="flex align-center flex-row  flex-grow-0 flex-nowrap"
          @click.stop
        >
          <template v-if="deletingTagId === item.id">
            <v-btn icon @click="removeTag()">
              <v-icon small color="gray">
                mdi-check
              </v-icon>
            </v-btn>

            <v-btn icon @click="deletingTagId = null">
              <v-icon small color="gray">
                mdi-close
              </v-icon>
            </v-btn>
          </template>

          <v-btn v-else icon color="red" @click="deletingTagId = item.id">
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </template>

      <template #no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="$t('pages.scenarios.editModal.tagsEnterCaption')" />
              <!-- eslint-enable vue/no-v-html -->
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </v-card-text>
</template>

<script>
  import { mapGetters } from 'vuex';
  import api from 'src/api';
  import { versionTagColors } from 'src/utils';

  export default {
    name: 'ReplaceTagsTab',

    data() {
      return {
        isBusy: false,

        tags: [],

        unbindTag: null,
        bindTag: null,

        deletingTagId: null,

        selectedMode: 0,
      };
    },

    computed: {
      ...mapGetters('users', ['isRealAdminOrHigher']),

      entitiesIdList() {
        return this.entities.map(entity => entity.id);
      },

      filteredTags() {
        return this.tags.filter(tag => !tag.isSystemTag);
      },
    },

    watch: {
      bindTag(value) {
        if (!value) {
          return;
        }

        if (typeof value === 'string') {
          const existTag = this.tags.find(
            tag => tag.title.trim().toLowerCase() === value.trim().toLowerCase()
          );

          if (!existTag) {
            this.createNewTag(value);

            return;
          }

          if (existTag.isSystemTag) {
            this.bindTag = null;
          } else {
            this.bindTag = existTag;
          }
        }

        this.$emit('updated', { oldTag: this.unbindTag, newTag: this.bindTag });
      },

      unbindTag: {
        immediate: true,
        async handler() {
          this.$emit('updated', { oldTag: this.unbindTag, newTag: this.bindTag });
        },
      },
    },

    mounted() {
      this.getTags();
    },

    methods: {
      async getTags() {
        const { response } = await api.tags.list();

        this.tags = response;
      },

      filterTags(item, queryText, itemText) {
        if (item.header) {
          return false;
        }

        const hasValue = val => (val != null ? val : '');

        const text = hasValue(itemText);
        const query = hasValue(queryText);

        return (
          text
            .toString()
            .toLowerCase()
            .trim()
            .indexOf(
              query
                .toString()
                .toLowerCase()
                .trim()
            ) > -1
        );
      },

      async removeTag() {
        await api.tags.remove({
          url_params: {
            id: this.deletingTagId,
          },
        });

        this.tags = this.tags.filter(tag => tag.id !== this.deletingTagId);

        if (this.unbindTag?.id === this.deletingTagId) {
          this.unbindTag = null;
        }

        if (this.bindTag?.id === this.deletingTagId) {
          this.bindTag = null;
        }

        this.deletingTagId = null;
      },

      async createNewTag(title) {
        const color = versionTagColors[Math.floor(Math.random() * versionTagColors.length)];
        const { response } = await api.tags.create({
          data: {
            title,
            color,
          },
        });

        this.tags.push(response);

        this.bindTag = response;
      },
    },
  };
</script>

<style scoped></style>
