import {
  SET_SHOWED_NOTIFICATIONS_PANEL,
  SET_SHOWED_NOTIFICATIONS_SETTINGS,
  SET_NOTIFICATIONS_LIST_TYPE,
  SET_NOTIFICATIONS_LIST,
  SET_NOTIFICATIONS_SERVER_COUNT,
  SET_NOTIFICATIONS_UNREAD_COUNT,
} from 'src/store/mutation-types';

import { NOTIFICATIONS_LIST_TYPE_UNREAD } from 'src/constants';

import api from 'src/api';

export default {
  namespaced: true,

  state: {
    showedNotificationsPanel: false,
    showedNotificationSettings: false,

    listType: NOTIFICATIONS_LIST_TYPE_UNREAD,
    list: [],
    serverCount: 0,
    unreadCount: 0,
  },

  getters: {},

  actions: {
    async getNotificationsList({ commit, state }, { page, perPage } = {}) {
      const { response } = await api.events.getList({
        params: { page, limit: perPage, type: state.listType },
      });

      commit(SET_NOTIFICATIONS_LIST, response.payload);
      commit(SET_NOTIFICATIONS_SERVER_COUNT, response.count);
    },

    async getUnreadCount({ commit }) {
      const { response } = await api.events.getUnreadCount();

      commit(SET_NOTIFICATIONS_UNREAD_COUNT, response.count);
    },

    readNotification({ state, commit }, id) {
      const notification = state.list.find(notificationItem => notificationItem.id === id);
      if (notification && !notification.isRead) {
        notification.isRead = true;
      }

      commit('SET_NOTIFICATIONS_UNREAD_COUNT', state.unreadCount - 1);
    },

    async removeNotification({ state, commit }, id) {
      await api.events.removeEvent({ url_params: { id } });

      const event = state.list.find(notification => notification.id === id);

      const list = state.list.filter(notification => notification.id !== id);
      commit(SET_NOTIFICATIONS_LIST, list);

      if (!event.isRead) {
        commit('SET_NOTIFICATIONS_UNREAD_COUNT', state.unreadCount - 1);
      }
    },

    addNotification({ state, commit }, notification) {
      const newList = [notification, ...state.list];

      commit(SET_NOTIFICATIONS_LIST, newList);
      commit(SET_NOTIFICATIONS_UNREAD_COUNT, state.unreadCount + 1);
    },
  },

  mutations: {
    [SET_SHOWED_NOTIFICATIONS_PANEL](state, status) {
      state.showedNotificationsPanel = status;
    },

    [SET_SHOWED_NOTIFICATIONS_SETTINGS](state, status) {
      state.showedNotificationSettings = status;
    },

    [SET_NOTIFICATIONS_LIST_TYPE](state, type) {
      state.listType = type;
    },

    [SET_NOTIFICATIONS_LIST](state, list) {
      state.list = list;
    },

    [SET_NOTIFICATIONS_SERVER_COUNT](state, serverCount) {
      state.serverCount = serverCount;
    },

    [SET_NOTIFICATIONS_UNREAD_COUNT](state, unreadCount) {
      if (unreadCount >= 0) {
        state.unreadCount = unreadCount;
      }
    },
  },
};
