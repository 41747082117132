<template>
  <v-form ref="form" @submit.prevent="onSubmit()">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t('pages.users.changeOwnerDialog.title') }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-col cols="6">
          <v-autocomplete
            v-model="ownerId"
            :items="orgUsers"
            :rules="getRequiredRule('owner')"
            :label="$t('pages.users.changeOwnerDialog.searchTitle')"
            item-text="text"
            item-value="value"
            prepend-inner-icon="mdi-magnify"
            required
            hide-details
          />
        </v-col>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="blue darken-1" text @click="$emit('close')">
          {{ $t('ui.common.cancel') }}
        </v-btn>

        <v-btn :loading="isBusy" color="blue darken-1" text type="submit">
          {{ $t('ui.common.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import { mapActions } from 'vuex';
  import api from 'src/api';
  import { busyFlow } from 'src/utils';

  export default {
    name: 'ChangeOrganizationOwnerDialog',

    props: {
      owner: { type: Object, required: true },
    },

    data() {
      return {
        isBusy: false,
        ownerId: null,
        orgUsers: [],
      };
    },

    watch: {
      owner: {
        deep: true,
        immediate: true,
        async handler() {
          await this.$nextTick();
          this.orgUsers = await this.getOrgUsers();
        },
      },
    },

    methods: {
      ...mapActions('organizations', ['updateOrgOwner']),

      close() {
        this.$emit('close');
      },

      getRequiredRule(name) {
        return [
          v =>
            !!v || this.$t('validation.notNullField', { field: this.$t(`common.fields.${name}`) }),
        ];
      },

      async onSubmit() {
        const { form } = this.$refs;

        if (!form.validate()) {
          return;
        }

        await busyFlow.call(this, async () => {
          try {
            await this.updateOrgOwner({
              id: this.owner.organizationId,
              payload: {
                creatorId: this.ownerId || this.owner.id,
              },
            });

            this.$emit('updated');
            this.$emit('close');
          } catch (error) {
            console.error(error);
          }
        });
      },

      async getOrgUsers() {
        const params = {
          organizationId: this.owner.organizationId,
        };
        const usersQuery = await api.users.list({ params });

        const users = usersQuery.response.users.map(user => {
          return {
            text: user.email,
            value: user.id,
          };
        });

        return users.filter(user => user.value !== this.owner.id);
      },
    },
  };
</script>

<style scoped></style>
