<template>
  <div ref="wrapper" class="hint-preview">
    <div class="hint-preview__widget-element">
      Button
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import debounce from 'lodash.debounce';

  import api from 'src/api';
  import { i18n } from 'src/plugins/i18n';
  import { apiUrl } from 'src/config';
  import { jsonStylesToCss, retry, timeout } from 'src/utils';

  import basePreview from '../../mixins/basePreview';
  import { THEME_TYPE_CLASSIC } from '../../utils';

  export default {
    name: 'HintPreview',

    extends: basePreview,

    data() {
      return {
        isLoaded: false,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        windowScrollPosition: window.scrollY,
      };
    },

    computed: {
      ...mapState('users', ['currentUser']),

      compiledCss() {
        const { type } = this.schema;
        const { position, copyrightHidden } = this.settings;
        this.clearHintPositionClasses();
        const styles = {};

        if (copyrightHidden) {
          styles['hinted-hint-bottom-link'] = 'display:none;';
        }

        if (type === 'border' && position !== 'element') {
          const { top, left, right, bottom } = this.$refs.wrapper.getBoundingClientRect();
          const { windowWidth, windowHeight, windowScrollPosition } = this;

          switch (position) {
            case 'topLeft':
              styles['hinted-hint-bubble'] += `position: absolute; top: ${top +
                windowScrollPosition +
                10}px; left: ${left + 10}px`;
              break;
            case 'bottomLeft':
              styles[
                'hinted-hint-bubble'
              ] += `position: absolute; top: unset; bottom: ${windowHeight -
                bottom -
                windowScrollPosition +
                10}px; left: ${left + 10}px`;
              break;
            case 'topRight':
              styles['hinted-hint-bubble'] += `position: absolute; top: ${top +
                windowScrollPosition +
                10}px; right: ${windowWidth - right - 10}px;left:unset;`;
              break;
            case 'bottomRight':
              styles[
                'hinted-hint-bubble'
              ] += `position: absolute;top: unset; left:unset; bottom: ${windowHeight -
                bottom -
                windowScrollPosition +
                10}px; right: ${windowWidth - right - 10}px;`;
              break;
            default:
              break;
          }
        }

        return jsonStylesToCss(styles).replace(/;|(([^;])(}))/g, '$2 !important; $3');
      },

      isStepCountEnabled() {
        return this.settings.isStepCountEnabled || false;
      },

      frameSize() {
        return this.schema.frameSize;
      },
    },

    watch: {
      type: {
        immediate: true,
        handler() {
          this.updateThemeClasses();
        },
      },

      isStepCountEnabled: {
        immediate: true,
        async handler(value) {
          await timeout(1000);
          await this.$nextTick();
          if (value) {
            const counter = document.createElement('span');
            counter.innerHTML = this.$t('pages.styles.settings.stepCountPreview');
            const div = document.querySelector('[data-step-counter]');
            div.innerHTML = '';
            div.append(counter);
          } else {
            const div = document.querySelector('[data-step-counter]');
            div.innerHTML = '';
          }
        },
      },

      frameSize() {
        window.HintedWidget.player.currentStep.renderer.view.update();
      },
    },

    async mounted() {
      window.addEventListener('resize', this.setWindowSize);
      window.addEventListener('scroll', this.setWindowSize);

      // hack here: Timeout for page animation time, since hinted-bubble position crashed
      await timeout(500);

      await this.$loadScript('/hinted-ondemand-widget.min.js');

      const scenario = {
        id: 'testScenario',
        title: '',
        content: '',
        origin: '*',
        steps: [
          {}, // hack for having the "prev" button and make it not clickable
          {
            id: 'testScenarioStep',
            title: '',
            description: '',
            elementDescription: {
              selectors: ['.hint-preview__widget-element'],
            },
            experimental: {},
            closeCondition: 'next',
            contentPosition: 'top',
            contentJson: {
              time: 1612782184802,
              blocks: [
                {
                  type: 'header',
                  data: {
                    text: this.$t('pages.styles.loremTitle'),
                    level: 3,
                  },
                },
                {
                  type: 'paragraph',
                  data: {
                    text: this.$t('pages.styles.loremText'),
                  },
                },
              ],
              version: '2.19.1',
            },
            redirect: false,
            uri: '*',
            onlyHighlight: false,
            selector: '.hint-preview__widget-element',
            buttons: ['next', 'close', 'prev'],
            zIndex: 4,
          },
        ],
      };

      const isLoaded = async () => {
        await this.$nextTick();
        return window.HintedWidget.player.loaded;
      };

      await retry.call(this, isLoaded);

      const host = new URL('/api', apiUrl).href;
      window.HintedWidget.configure({
        host,
      });

      const { response } = await api.organizations.getSettings({
        url_params: { id: this.currentUser.organizationId },
      });

      window.HintedWidget.updateSettings({ logoUrl: response.logoUrl, logo: response.logoUrl });
      window.HintedWidget.player.setLocale(i18n.locale);
      window.HintedWidget.player.load(scenario);
      window.HintedWidget.player.play(1);

      await timeout(500);

      this.updateThemeClasses();
      this.clearHintPositionClasses();
      this.isLoaded = true;
    },

    async beforeDestroy() {
      window.removeEventListener('resize', this.setWindowSize);
      window.removeEventListener('scroll', this.setWindowSize);

      window.HintedWidget.player.stop();
      window.HintedWidget.player.clear();
      window.HintedWidget.player.finish();
    },

    methods: {
      onWindowResize: debounce(function update() {
        window.HintedWidget.player.currentStep.renderer.view.update();
      }, 100),

      setWindowSize() {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
        this.windowScrollPosition = window.scrollY;

        this.onWindowResize();
      },

      clearHintPositionClasses() {
        const widgetHintScreenPositionClasses = [
          'hinted-hint-bubble--top-left',
          'hinted-hint-bubble--bottom-left',
          'hinted-hint-bubble--top-right',
          'hinted-hint-bubble--bottom-right',
        ];

        const [hintedBubbleElement] = document.getElementsByTagName('hinted-bubble');

        if (!hintedBubbleElement) {
          return;
        }

        hintedBubbleElement.classList.remove(...widgetHintScreenPositionClasses);
      },

      updateThemeClasses() {
        const element = document.querySelector('.hinted-hint-bubble');
        if (!element) {
          return;
        }

        if (this.type === THEME_TYPE_CLASSIC) {
          element.classList.add('style-classic');
        } else {
          element.classList.remove('style-classic');
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .hint-preview {
    height: 700px;
    position: relative;

    &__widget-element {
      position: absolute;
      padding: 6px 10px;
      color: black;
      border: 2px solid black;
      font-family: Roboto, sans-serif;
      text-transform: capitalize;
      top: 50%;
      left: 25%;
      transform: translateY(-50%);
    }
  }
</style>
