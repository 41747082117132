<template>
  <v-container>
    <v-row class-name="pb-0">
      <v-col v-if="showedExpireAlert" cols="12" class-name="py-0">
        <v-container fluid class-name="py-0">
          <expire-plan-alert @closed="showedExpireAlert = false" />
        </v-container>
      </v-col>

      <v-col v-if="!isSmartManualsPlansActive" cols="12" class="px-9">
        <v-tabs v-model="selectedMode" height="54">
          <v-tab class="text-none">
            <feather :type="'message-square'" class="mr-3" />

            {{ $t('pages.statistics.scenariosStats') }}
          </v-tab>

          <v-tab class="text-none">
            <feather :type="'message-circle'" class="mr-3" />

            {{ $t('pages.statistics.tooltipsStats') }}
          </v-tab>

          <v-tab class="text-none">
            <feather :type="'package'" class="mr-3" />

            {{ $t('pages.statistics.simulationsStats') }}
          </v-tab>

          <v-tab class="text-none">
            <feather :type="'alert-circle'" class="mr-3" />

            {{ $t('pages.statistics.helpCenterStatistics') }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <component :is="currentStatsComponent" />
  </v-container>
</template>

<script>
  import moment from 'moment';
  import { mapGetters, mapState } from 'vuex';

  import localStorage from 'src/services/localStorage';

  import ScenariosStats from 'src/pages/Dashboard/components/ScenariosStats.vue';
  import SimulationsStats from 'src/pages/Dashboard/components/SimulationsStats.vue';
  import TooltipsStats from 'src/pages/Dashboard/components/TooltipsStats.vue';
  import HelpCenterStats from 'src/pages/Dashboard/components/HelpCenterStats.vue';
  import ExpirePlanAlert from 'src/components/ExpirePlanAlert/ExpirePlanAlert.vue';

  export default {
    name: 'Dashboard',

    components: {
      ScenariosStats,
      SimulationsStats,
      ExpirePlanAlert,
      HelpCenterStats,
      TooltipsStats,
    },

    data() {
      return {
        mode: localStorage.get('dashboardMode') || 0,
        showedExpireAlert: false,
      };
    },

    computed: {
      ...mapState('users', ['currentUser']),
      ...mapGetters('users', ['isSmartManualsPlansActive']),

      selectedMode: {
        get() {
          return this.mode;
        },

        set(value) {
          localStorage.set('dashboardMode', value);
          this.mode = value;
        },
      },

      currentStatsComponent() {
        if (this.isSmartManualsPlansActive) {
          return SimulationsStats;
        }

        switch (this.mode) {
          case 1:
            return TooltipsStats;
          case 2:
            return SimulationsStats;
          case 3:
            return HelpCenterStats;
          case 0:
          default:
            return ScenariosStats;
        }
      },
    },

    async mounted() {
      if (!this.isSmartManualsPlansActive) {
        this.showExpiredAlert();
      }
    },

    methods: {
      showExpiredAlert() {
        const expireAlertClosingStatuses = localStorage.get('expireAlertClosingStatuses') || {};

        const activeUntilDate = moment.utc(this.currentUser.organizationActiveUntil);

        if (
          activeUntilDate.diff(moment.utc(), 'days', true) <= 30 &&
          activeUntilDate.diff(moment.utc(), 'days', true) > 20 &&
          !expireAlertClosingStatuses?.[30]
        ) {
          this.showedExpireAlert = true;

          return;
        }

        if (
          activeUntilDate.diff(moment.utc(), 'days', true) <= 20 &&
          activeUntilDate.diff(moment.utc(), 'days', true) > 10 &&
          !expireAlertClosingStatuses?.[20]
        ) {
          this.showedExpireAlert = true;

          return;
        }

        if (
          activeUntilDate.diff(moment.utc(), 'days', true) <= 10 &&
          activeUntilDate.diff(moment.utc(), 'days', true) > 7 &&
          !expireAlertClosingStatuses?.[20]
        ) {
          this.showedExpireAlert = true;

          return;
        }

        [7, 6, 5, 4, 3, 2, 1].forEach(daysLeft => {
          if (
            activeUntilDate.diff(moment.utc(), 'days', true) <= daysLeft &&
            activeUntilDate.diff(moment.utc(), 'days', true) > daysLeft - 1 &&
            !expireAlertClosingStatuses?.[daysLeft]
          ) {
            this.showedExpireAlert = true;
          }
        });
      },
    },
  };
</script>

<style lang="scss"></style>
