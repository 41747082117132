<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-col lg="11" sm="8" xl="7">
        <v-card class="elevation-4">
          <v-row>
            <v-col lg="5" class="pr-0">
              <div class="pa-7 pa-sm-12">
                <v-alert v-if="error" dense type="error">
                  {{ error }}
                </v-alert>

                <img :src="$options.LOGO_URL" :alt="$options.COMPANY_NAME" />

                <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
                  {{ $t('pages.signUp.title') }}
                </h2>

                <h6 class="subtitle-1">
                  {{ $t('pages.signUp.hasAccountMessage') }}
                  <router-link to="/login">{{ $t('pages.signUp.signIn') }}</router-link>
                </h6>

                <v-form ref="form" v-model="isValid" lazy-validation @submit.prevent="onSubmit">
                  <v-text-field
                    v-model="organization"
                    :rules="organizationRules"
                    :label="$t('pages.signUp.form.organization')"
                    class="mt-4"
                    required
                    outlined
                  />

                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    :label="$t('pages.signUp.form.email')"
                    required
                    outlined
                  />

                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    :label="$t('pages.signUp.form.password')"
                    required
                    outlined
                    :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    @click:append="togglePasswordVisibility"
                  />

                  <v-text-field
                    v-model="passwordRepeat"
                    :rules="passwordRepeatRules"
                    :label="$t('pages.signUp.form.passwordConfirm')"
                    outlined
                    type="password"
                    validate-on-blur
                    @input="onPasswordInput"
                  />

                  <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                    <v-checkbox
                      v-model="checkbox"
                      :rules="[v => !!v || $t('pages.signUp.agreementValidation')]"
                      required
                    >
                      <template #label>
                        <i18n path="pages.signUp.privacyAndUserAgreement">
                          <a :href="$options.PRIVACY_URL" target="_blank" @click.stop>
                            {{ $t('pages.signUp.privacyTitle') }}
                          </a>

                          <a :href="$options.USER_AGREEMENT_URL" target="_blank" @click.stop>
                            {{ $t('pages.signUp.userAgreementTitle') }}
                          </a>
                        </i18n>
                      </template>
                    </v-checkbox>
                  </div>

                  <v-btn :disabled="!isValid" color="info" block class="mr-4" type="submit">
                    {{ $t('pages.signUp.action') }}
                  </v-btn>
                </v-form>
              </div>
            </v-col>

            <v-col lg="7" class="info d-none d-md-flex align-center justify-center">
              <div class="d-none d-sm-block">
                <div class="d-flex align-center pa-10">
                  <div>
                    <h2 class="display-1 white--text font-weight-medium">
                      {{ $t('pages.signUp.aside.title', { companyName: $options.COMPANY_NAME }) }}
                    </h2>

                    <h6 class="subtitle-1 mt-4 white--text op-5 font-weight-regular">
                      {{ $t('pages.signUp.aside.message') }}
                    </h6>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex';

  import {
    ORGANIZATION_PLAN_FREE,
    ORGANIZATION_PLAN_SIMULATION,
    ORGANIZATION_PLAN_SMART_MANUALS_START,
  } from 'src/constants';
  import { buildSpecConfig } from 'src/config';
  import localStorage from 'src/services/localStorage';

  export default {
    name: 'SignUpForm',
    data() {
      return {
        error: null,
        isValid: true,
        password: null,
        isPasswordVisible: false,
        isPasswordsValid: true,
        passwordRepeat: null,
        email: null,
        checkbox: false,
        organization: null,
        isBusy: false,
      };
    },

    COMPANY_NAME: buildSpecConfig.companyName,
    LOGO_URL: buildSpecConfig.logoUrl,
    PRIVACY_URL: buildSpecConfig.privacyUrl,
    USER_AGREEMENT_URL: buildSpecConfig.userAgreementUrl,

    computed: {
      ...mapState('settings', ['hasLDAP']),

      passwordRules() {
        return [
          v => !!v || this.$t('validation.passwordEmpty'),
          v => (v && v.length >= 6) || this.$t('validation.passwordLength'),
        ];
      },

      emailRules() {
        return [
          v => !!v || this.$t('validation.emailEmpty'),
          v => /.+@.+\..+/.test(v) || this.$t('validation.emailInvalid'),
        ];
      },

      organizationRules() {
        return [
          v => !!v || this.$t('validation.organizationEmpty'),
          v => (v && v.length >= 3) || this.$t('validation.organizationEmpty'),
        ];
      },

      passwordRepeatRules() {
        return [
          ...this.passwordRules,
          v => v === this.password || this.$t('validation.passwordsDiff'),
        ];
      },
    },

    created() {
      if (this.hasLDAP) {
        this.$router.push({ name: 'login' });

        return;
      }

      const queryPlan = this.$route.query.plan;
      if (
        queryPlan &&
        [
          ORGANIZATION_PLAN_FREE,
          ORGANIZATION_PLAN_SIMULATION,
          ORGANIZATION_PLAN_SMART_MANUALS_START,
        ].includes(queryPlan)
      ) {
        localStorage.set('registerPlan', queryPlan);
      }
    },

    methods: {
      ...mapActions('auth', ['register']),

      validate() {
        this.isValid = this.$refs.form.validate();
      },

      onPasswordInput() {
        this.isPasswordsValid = true;
      },

      togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible;
      },

      async onSubmit() {
        this.validate();

        if (this.isBusy || !this.isPasswordsValid || !this.isValid) {
          return;
        }

        this.isBusy = true;

        try {
          await this.register({
            email: this.email,
            username: this.email,
            organization: this.organization,
            password: this.password,
            password_repeat: this.passwordRepeat,
            terms: 'on',
            ...(localStorage.has('registerPlan') ? { plan: localStorage.get('registerPlan') } : {}),
          });

          localStorage.remove('registerPlan');

          this.$router.push({ name: 'login', query: { status: 'registered' } });
        } catch (err) {
          this.error = this.$te(`serverCode.${err.code}`)
            ? this.$t(`serverCode.${err.code}`)
            : this.$t('serverCode.serverError');
        } finally {
          this.isBusy = false;
        }
      },
    },
  };
</script>
