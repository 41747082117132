<template>
  <div />
</template>

<script>
  import { apiUrl } from 'src/config';
  import localStorage from 'src/services/localStorage';
  import { currentLocale } from 'src/plugins/i18n';
  import api from '@/api';
  import {
    ORGANIZATION_PLAN_ENTERPRISE,
    ORGANIZATION_PLAN_FREE,
    ORGANIZATION_PLAN_SIMULATION,
    ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,
    ORGANIZATION_PLAN_STANDARD,
    ORGANIZATION_PLAN_STARTUP,
  } from '@/constants';

  export default {
    name: 'SimulationTesting',

    async mounted() {
      const { response } = await api.simulations.get({
        url_params: { id: this.$route.params.id },
      });

      if (
        ![
          ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,
          ORGANIZATION_PLAN_STANDARD,
          ORGANIZATION_PLAN_FREE,
          ORGANIZATION_PLAN_ENTERPRISE,
          ORGANIZATION_PLAN_SIMULATION,
          ORGANIZATION_PLAN_STARTUP,
        ].includes(response.organizationPlan)
      ) {
        this.router.push({ name: 'Simulations' });

        return;
      }

      await this.$loadScript('/hinted-simulation-player.js');

      const interfaceLocale = localStorage.get('interfaceLocale');

      window.hintedSimulationPlayer.init(apiUrl);
      window.hintedSimulationPlayer.setLocale(interfaceLocale || currentLocale);
      window.hintedSimulationPlayer.onClose(() => window.close());
      window.hintedSimulationPlayer.startSimulationTesting(this.$route.params.id);
    },
  };
</script>
