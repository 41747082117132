<template>
  <div class="font-select mb-3">
    <template v-if="!selectedCustomFontFamily">
      <div class="d-flex align-center">
        <v-select
          v-model="localValue"
          :items="$options.fontItems"
          outlined
          hide-details
          dense
          class="input-control"
        />

        <div class="ml-4">{{ inputLabel }}</div>
      </div>

      <div class="mt-1">
        {{ $t('common.or') }}

        <button class="font-select__button" @click="toggleCustomFontFamily()">
          {{ $t('pages.styles.settings.fontSelect.writeCustomFont') }}
        </button>
      </div>
    </template>

    <template v-else>
      <v-alert text type="info">
        {{ $t('pages.styles.settings.fontSelect.alert') }}
      </v-alert>

      <div class="d-flex align-center">
        <v-combobox
          ref="input"
          v-model="separatedLocalValue"
          :delimiters="[',']"
          :label="$t('pages.styles.settings.fontSelect.inputLabel')"
          :placeholder="$t('pages.styles.settings.fontSelect.inputPlaceholder')"
          multiple
          small-chips
          deletable-chips
          outlined
          persistent-placeholder
          hide-details
          class="input-control"
        />

        <div class="ml-4">{{ inputLabel }}</div>
      </div>

      <div class="mt-1">
        {{ $t('common.or') }}

        <button class="font-select__button" @click="toggleCustomFontFamily()">
          {{ $t('pages.styles.settings.fontSelect.selectLibraryFont') }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
  import { fontItems } from '../utils';

  export default {
    name: 'FontSelect',

    model: {
      prop: 'modelValue',
      event: 'update:modelValue',
    },

    props: {
      modelValue: {
        type: String,
        required: false,
        default: null,
      },

      label: {
        type: String,
        required: false,
        default: null,
      },
    },

    fontItems,

    computed: {
      separatedLocalValue: {
        get() {
          if (!this.localValue) {
            return [];
          }

          return this.localValue.replace(' ', '').split(',');
        },

        set(value) {
          this.localValue = value.join(', ');
        },
      },

      localValue: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
        },
      },

      inputLabel() {
        return this.label || this.$t('pages.styles.settings.fontFamily');
      },

      selectedCustomFontFamily() {
        return !this.localValue || !fontItems.find(fontItem => this.localValue === fontItem.value);
      },
    },

    methods: {
      async toggleCustomFontFamily() {
        if (this.selectedCustomFontFamily) {
          this.localValue = this.$options.fontItems[0].value;
        } else {
          this.localValue = '';
          await this.$nextTick();
          this.$refs.input.focus();
        }
      },
    },
  };
</script>

<style lang="scss">
  .font-select {
    &__button {
      font-size: 12px;
      line-height: 12px;
      text-decoration: underline;
      color: #da1e57;
      font-weight: 500;
    }
  }
</style>
