<template>
  <v-card data-test-confirm-remove-dialog>
    <v-card-title class="text-h5">
      {{ $t(`pages.${entityType}.editDomainModal.title`) }}</v-card-title
    >
    <v-card-text>
      <v-alert type="warning" prominent dense>
        <span class="body-2">{{ $t(`pages.${entityType}.editDomainModal.alertTitle`) }}</span>
        <br />
        <span class="body-2">
          {{ $t(`pages.scenarios.editDomainModal.alertText`) }}
        </span>
      </v-alert>

      <v-col
        v-for="(originItem, key) in originList"
        :key="`origin-${key}`"
        cols="12"
        class="d-flex"
      >
        <v-text-field
          v-model="originItem.value"
          :label="$t(`pages.scenarios.editDomainModal.domainLabel`)"
          outlined
          hide-details
        >
          <template #append>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <span class="mt-1" v-bind="attrs" v-on="on">
                  {{ originItem.items.length }} {{ $t(`pages.scenarios.editDomainModal.of`) }}
                  {{ tempEntities.length }}
                </span>
              </template>
              <div>
                <div v-for="originEntity in originItem.items" :key="`${originEntity.id}-${key}`">
                  {{ originEntity.title }}
                </div>
              </div>
            </v-tooltip>
          </template>
        </v-text-field>

        <v-btn icon class="ml-2 mt-2" @click="removeOrigin(key)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="12" class="d-flex">
        <v-text-field
          v-model="tempOrigin"
          :label="$t(`pages.${entityType}.editDomainModal.newDomainLabel`)"
          :placeholder="$t(`pages.scenarios.editDomainModal.newDomainPlaceholder`)"
          :error-messages="tempOriginError"
          outlined
          @keydown.enter="addOrigin()"
        />

        <v-btn color="primary" width="63" height="56" class="ml-2" @click="addOrigin()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-card-text>

    <v-card-actions>
      <v-spacer />

      <v-btn color="grey" text @click="$emit('close')">
        {{ $t('ui.common.cancel') }}
      </v-btn>

      <v-btn :loading="isBusy" color="primary" text @click="saveEntitiesOriginList()">
        {{ $t('ui.common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import urlParse from 'url-parse';

  import api from 'src/api';
  import { deepcopy } from 'src/utils';

  export default {
    name: 'EditOriginsModal',

    props: {
      entities: {
        type: Array,
        required: true,
      },

      entityType: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        isBusy: false,
        tempEntities: [],
        originList: [],

        tempOrigin: null,
        tempOriginError: null,
      };
    },

    mounted() {
      this.tempEntities = deepcopy(this.entities).map(entity => {
        const normalizedEntity = { ...entity };

        if (!normalizedEntity?.originList.length) {
          normalizedEntity.originList = [{ type: 'text', value: normalizedEntity.origin }];
        }

        return normalizedEntity;
      });

      this.calculateGeneralOrigins();
    },

    methods: {
      parseUrl(url) {
        if (!url) {
          return null;
        }

        try {
          return urlParse(url);
        } catch (err) {
          return null;
        }
      },

      calculateGeneralOrigins() {
        this.originList = this.tempEntities.reduce((acc, item) => {
          item.originList
            .filter(origin => origin.type === 'text')
            .forEach(origin => {
              const url = this.parseUrl(origin.value);

              const existingOriginItem =
                acc.find(originItem => originItem.initialOrigin.hostname === url.hostname) || false;

              if (!existingOriginItem) {
                acc.push({
                  initialOrigin: url,
                  value: url.hostname,
                  items: [
                    {
                      id: item.id,
                      title: item.title,
                    },
                  ],
                });
              } else if (
                !existingOriginItem.items.find(existingEntity => existingEntity.id === item.id)
              ) {
                existingOriginItem.items.push({
                  id: item.id,
                  title: item.title,
                });
              }
            });

          return acc;
        }, []);
      },

      async saveEntitiesOriginList() {
        this.isBusy = true;

        this.updateOrigins();

        const promises = this.tempEntities.map(entity => {
          const params = {
            data: {
              origin: entity.origin,
              originList: entity.originList,
              ...(this.entityType === 'tooltips' ? { id: entity.id } : {}),
            },
            ...(this.entityType === 'scenarios' ? { url_params: { id: entity.id } } : {}),
          };

          return api[this.entityType].update(params);
        });

        await Promise.all(promises);

        this.isBusy = false;
        this.$emit('updated');
      },

      updateOrigins() {
        const updatingOrigins = this.originList.filter(
          originItem =>
            this.parseUrl(`${originItem.initialOrigin.protocol}//${originItem.value}`).hostname !==
            originItem.initialOrigin.hostname
        );

        this.tempEntities.forEach(entity => {
          updatingOrigins.forEach(origin => {
            let currentOrigin = this.parseUrl(entity.origin);

            if (currentOrigin.hostname === origin.initialOrigin.hostname) {
              currentOrigin.set(
                'hostname',
                this.parseUrl(`${origin.initialOrigin.protocol}//${origin.value}`).hostname
              );

              // eslint-disable-next-line no-param-reassign
              entity.origin = currentOrigin.toString();
            }

            if (
              entity.originList?.length &&
              entity.originList.filter(originItem => originItem.type === 'text').length
            ) {
              const originList = [...entity.originList];

              originList.forEach((originItem, index) => {
                currentOrigin = this.parseUrl(originItem.value);

                if (currentOrigin.hostname === origin.initialOrigin.hostname) {
                  currentOrigin.set(
                    'hostname',
                    this.parseUrl(`${origin.initialOrigin.protocol}//${origin.value}`).hostname
                  );

                  // eslint-disable-next-line no-param-reassign
                  entity.originList[index].value = currentOrigin.toString();
                }
              });
            }
          });
        });
      },

      removeOrigin(index) {
        const host = this.originList[index].initialOrigin;

        this.tempEntities = this.tempEntities.map(entity => {
          const newEntity = deepcopy(entity);

          const anotherOriginsList = newEntity.originList.filter(
            origin => this.parseUrl(origin).hostname !== host.hostname
          );

          if (!anotherOriginsList.length) {
            return newEntity;
          }

          newEntity.originList = newEntity.originList.filter(
            origin =>
              origin.type !== 'text' || this.parseUrl(origin.value).hostname !== host.hostname
          );

          newEntity.origin = newEntity.originList.find(
            originItem => originItem.type === 'text'
          ).value;

          return newEntity;
        });

        this.originList.splice(index, 1);
      },

      addOrigin() {
        this.tempOriginError = null;

        if (!this.tempOrigin) {
          return;
        }

        if (
          !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/.test(
            this.tempOrigin
          )
        ) {
          this.tempOriginError = this.$t('pages.scenarios.editModal.newOriginError');
          return;
        }

        const host = this.parseUrl(this.tempOrigin);

        const entitiesWithTempOrigin = this.tempEntities.filter(entity => {
          const existsOrigin = entity.originList.find(
            origin =>
              origin.type === 'text' && this.parseUrl(origin.value).hostname === host.hostname
          );

          return Boolean(existsOrigin);
        });

        if (entitiesWithTempOrigin.length === this.tempEntities.length) {
          this.tempOriginError = this.$t(`pages.scenarios.editDomainModal.domainAlreadyAddedError`);
          return;
        }

        this.tempEntities.forEach(entity => {
          const existsOrigin = entity.originList.find(
            origin =>
              origin.type === 'text' && this.parseUrl(origin.value).hostname === host.hostname
          );

          if (existsOrigin) {
            return;
          }

          const firstEntityOriginHost = this.parseUrl(entity.origin);

          firstEntityOriginHost.set('hostname', host.hostname);

          entity.originList.push({ type: 'text', value: firstEntityOriginHost.toString() });
        });

        const existsOriginIndex = this.originList.findIndex(
          origin => origin.initialOrigin.hostname === host.hostname
        );
        if (existsOriginIndex !== -1) {
          this.originList[existsOriginIndex].items = this.tempEntities.map(entity => ({
            id: entity.id,
            title: entity.title,
          }));
        } else {
          this.originList.push({
            initialOrigin: host,
            value: host.hostname,
            items: this.tempEntities.map(entity => ({ id: entity.id, title: entity.title })),
          });
        }

        this.tempOrigin = null;
      },
    },
  };
</script>
