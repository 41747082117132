<template>
  <div class="simulation-preview">
    <FakeSimulation v-if="showed" :custom-logo-url="schema.systemSlideLogoUrl" />
  </div>
</template>

<script>
  import basePreview from 'src/pages/Styles/mixins/basePreview';

  import FakeSimulation from './FakeSimulation.vue';

  export default {
    name: 'SimulationPreview',

    components: {
      FakeSimulation,
    },

    extends: basePreview,

    computed: {
      compiledCss() {
        return window.hintedSimulationPlayer.generateStyles(this.schema, this.settings);
      },
    },
  };
</script>

<style lang="scss">
  .simulation-preview {
    padding: 28px;
  }
</style>
