var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"options":_vm.params,"server-items-length":_vm.serverItemsCount,"loading":_vm.isBusy},on:{"update:options":function($event){_vm.params=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('travolta-not-found')]},proxy:true},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-8",attrs:{"flat":""}},[_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":((_vm.$t('pages.links.search')) + "..."),"append-icon":"mdi-magnify","clearable":"","hide-details":""},model:{value:(_vm.debouncedPattern),callback:function ($$v) {_vm.debouncedPattern=$$v},expression:"debouncedPattern"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"auto"}},[(_vm.isModeratorOrHigher)?_c('v-checkbox',{staticClass:"mt-2 mr-2",attrs:{"label":_vm.$t('common.showTrashed')},model:{value:(_vm.trashedItems),callback:function ($$v) {_vm.trashedItems=$$v},expression:"trashedItems"}}):_vm._e(),_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openEditModal()}}},[_vm._v(" "+_vm._s(_vm.$t('pages.links.create'))+" ")])],1)],1)],1)],1)]},proxy:true},{key:"item.num",fn:function(ref){
var index = ref.index;
return [_c('TableRowNumber',{attrs:{"server-page":_vm.serverPage,"server-limit":_vm.serverLimit,"index":index}})]}},{key:"item.url",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"truncate"},'div',attrs,false),on),[_vm._v(_vm._s(value))])]}}],null,true)},[_c('div',[_vm._v(_vm._s(value))])])]}},{key:"item.creatorEmail",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(value))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(value))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.trashedItems)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.restore(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-restore ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.restore")))])]):[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"info"},on:{"click":function($event){return _vm.openEditModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.edit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":"","small":""},on:{"click":function($event){return _vm.openRemoveDialog(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.delete")))])]),_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.copyLinkURL(item)}}},[_c('v-list-item-icon',{staticClass:"mr-0"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-link-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('pages.links.copyLink'))+" ")])],1)],1),(_vm.isAdmin && _vm.isEnterprisePlanActive)?_c('v-list-item',{on:{"click":function($event){return _vm.openWorkspaceMigrationDialog([item])}}},[_c('v-list-item-icon',{staticClass:"mr-0"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-folder-swap-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('ui.workspaceEntityMigrateModal.buttonText'))+" ")])],1)],1):_vm._e()],1)],1)]]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"1039px"},model:{value:(_vm.editModalOpened),callback:function ($$v) {_vm.editModalOpened=$$v},expression:"editModalOpened"}},[(_vm.editModalOpened)?_c('link-edit-modal',{attrs:{"editing-link":_vm.editingItem},on:{"created":function($event){return _vm.onChangeChecklist(true)},"updated":function($event){return _vm.onChangeChecklist()},"close":function($event){return _vm.closeEditModal()}}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"450px"},model:{value:(_vm.workspaceMigratingDialogOpened),callback:function ($$v) {_vm.workspaceMigratingDialogOpened=$$v},expression:"workspaceMigratingDialogOpened"}},[(_vm.workspaceMigratingDialogOpened)?_c('workspace-entity-migrate-modal',{attrs:{"type":"links","list":_vm.workspaceMigratingIds},on:{"changed":function($event){return _vm.getList()},"close":function($event){return _vm.closeWorkspaceModal()}}}):_vm._e()],1),_c('v-snackbar',{attrs:{"color":_vm.createdLink ? 'success' : 'default',"timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.changeToastOpened = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('ui.common.close'))+" ")])]}}]),model:{value:(_vm.changeToastOpened),callback:function ($$v) {_vm.changeToastOpened=$$v},expression:"changeToastOpened"}},[_vm._v(" "+_vm._s(_vm.$t(("pages.links." + (_vm.createdLink ? 'createToast' : 'updateToast'))))+" ")]),_c('v-snackbar',{attrs:{"color":"red darken-2","timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.removeToastOpened = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('ui.common.close'))+" ")])]}}]),model:{value:(_vm.removeToastOpened),callback:function ($$v) {_vm.removeToastOpened=$$v},expression:"removeToastOpened"}},[_vm._v(" "+_vm._s(_vm.$t('pages.links.removeToast'))+" ")]),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.removeDialogOpened),callback:function ($$v) {_vm.removeDialogOpened=$$v},expression:"removeDialogOpened"}},[(_vm.removeDialogOpened)?_c('remove-item-modal',{attrs:{"title":_vm.$t('pages.links.removeModalTitle'),"text":_vm.$t('pages.links.removeModalText'),"remove-callback":_vm.removeCallback},on:{"close":function($event){_vm.removeDialogOpened = false},"removed":function($event){return _vm.onRemoved()}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }