<template>
  <div class="highlight-preview">
    <div class="highlight-preview__widget-element">
      Button
    </div>
  </div>
</template>

<script>
  import hexToRgba from 'hex-to-rgba';

  import { retry } from 'src/utils';

  import basePreview from '../../mixins/basePreview';

  export default {
    name: 'HighlightPreview',

    extends: basePreview,

    computed: {
      borderStyles() {
        const { borderColor, borderLineWidth } = this.schema;
        const styles = `border: ${borderLineWidth}px solid ${hexToRgba(borderColor)}`;

        return {
          'hinted-hint-selection-border': styles,
        };
      },

      widgetStyles() {
        return {
          ...this.borderStyles,
        };
      },
    },

    async mounted() {
      await this.$loadScript('/hinted-ondemand-widget.min.js');

      const scenario = {
        id: 'testScenario',
        title: '',
        content: '',
        origin: '*',
        steps: [
          {
            id: 'testScenarioStep',
            title: '',
            description: '',
            selectors: [],
            experimental: {},
            closeCondition: 'next',
            contentPosition: 'top',
            redirect: false,
            uri: '*',
            onlyHighlight: true,
            contentTitle: 'Test selector',
            elementDescription: {
              selectors: ['.highlight-preview__widget-element'],
            },
            selector: '.highlight-preview__widget-element',
            buttons: ['next', 'close'],
          },
        ],
      };

      const isLoaded = async () => {
        await this.$nextTick();
        return window.HintedWidget.player.loaded;
      };

      await retry.call(this, isLoaded);

      window.HintedWidget.player.load(scenario);
      window.HintedWidget.player.play();

      this.isLoaded = true;
    },

    async beforeDestroy() {
      window.HintedWidget.player.clear();
      window.HintedWidget.player.stop();
    },
  };
</script>

<style scoped lang="scss">
  .highlight-preview {
    height: 700px;
    position: relative;

    &__widget-element {
      position: absolute;
      padding: 6px 10px;
      color: black;
      border: 2px solid black;
      font-family: Roboto, sans-serif;
      text-transform: capitalize;
      top: 50%;
      left: 25%;
      transform: translateY(-50%);
    }
  }
</style>
