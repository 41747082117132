/* eslint-disable no-shadow */
import api from 'src/api';

import {
  GROUPS_COUNT,
  GROUPS_LIST,
  GROUP_USERS,
  GROUP_USERS_COUNT,
  GROUP_SCENARIOS,
  GROUP_SCENARIOS_COUNT,
  GROUP_TOOLTIPS_COUNT,
  GROUP_TOOLTIPS,
  GROUP_LINKS,
  GROUP_LINKS_COUNT,
} from 'src/store/mutation-types';
import { deepcopy } from 'src/utils';

const state = {
  groupsList: [],
  groupsCount: 0,
  groupUsers: [],
  groupUsersCount: 0,
  groupScenarios: [],
  groupScenariosCount: 0,
  groupTooltips: [],
  groupTooltipsCount: 0,
  groupLinks: [],
  groupLinksCount: 0,
};

const actions = {
  async create(context, payload) {
    const { response } = await api.groups.create({ data: payload });
    return response;
  },

  async getList(
    { commit },
    { page, limit, filterBy, filterDirection, pattern, organizationId } = {}
  ) {
    const params = deepcopy({
      page,
      limit,
      filter_by: filterBy,
      filter_direction: filterDirection,
      pattern,
      organizationId,
    });

    const {
      response: { payload, count },
    } = await api.groups.list({ params });

    commit(GROUPS_LIST, payload);
    commit(GROUPS_COUNT, count);
  },

  clearList({ commit }) {
    commit(GROUPS_COUNT, 0);
    commit(GROUPS_LIST, []);
  },

  clearUsers({ commit }) {
    commit(GROUP_USERS_COUNT, 0);
    commit(GROUP_USERS, []);
  },

  clearScenarios({ commit }) {
    commit(GROUP_SCENARIOS_COUNT, 0);
    commit(GROUP_SCENARIOS, []);
  },

  clearTooltips({ commit }) {
    commit(GROUP_TOOLTIPS_COUNT, 0);
    commit(GROUP_TOOLTIPS, []);
  },

  clearLinks({ commit }) {
    commit(GROUP_LINKS_COUNT, 0);
    commit(GROUP_LINKS, []);
  },

  async remove(context, id) {
    const { response } = await api.groups.remove({ url_params: { id } });
    return response;
  },

  async updateGroup(context, { groupId, data }) {
    const { response } = await api.groups.update({ url_params: { id: groupId }, data });
    return response;
  },

  async getUsers({ commit }, { groupId, page, limit, filterBy, filterDirection, pattern } = {}) {
    const params = deepcopy({ page, limit, filterBy, filterDirection });

    if (pattern) {
      params.pattern = pattern;
    }

    const { response } = await api.groups.getUsers({ url_params: { id: groupId }, params });
    const { payload, count } = response;
    commit(GROUP_USERS, payload);
    commit(GROUP_USERS_COUNT, count);
  },

  async addUsers(context, { users, groupId } = {}) {
    const { response } = await api.groups.addUsers({
      url_params: { id: groupId },
      data: { users },
    });

    return response;
  },

  async removeUsers(context, { users, groupId } = {}) {
    const { response } = await api.groups.removeUsers({
      url_params: { id: groupId },
      data: { users },
    });

    return response;
  },

  async getUserGroups(context, userId) {
    const { response } = await api.groups.getUserGroups({
      url_params: { id: userId },
    });

    return response;
  },

  async getScenarios(
    { commit },
    { groupId, page, limit, filterBy, filterDirection, pattern, type } = {}
  ) {
    const params = deepcopy({ page, limit, filterBy, filterDirection, type });

    if (pattern) {
      params.pattern = pattern;
    }

    const { response } = await api.groups.getScenarios({ url_params: { id: groupId }, params });
    const { payload, count } = response;
    commit(GROUP_SCENARIOS, payload);
    commit(GROUP_SCENARIOS_COUNT, count);
  },

  async addScenarios(context, { scenarios, groupId, type } = {}) {
    const { response } = await api.groups.addScenarios({
      url_params: { id: groupId },
      data: { scenarios, type },
    });

    return response;
  },

  async removeScenarios(context, { scenarios, groupId, type } = {}) {
    const { response } = await api.groups.removeScenarios({
      url_params: { id: groupId },
      data: { scenarios, type },
    });

    return response;
  },

  async getTooltips(
    { commit },
    { groupId, page, limit, filterBy, filterDirection, pattern, type } = {}
  ) {
    const params = deepcopy({ page, limit, filterBy, filterDirection, type });

    if (pattern) {
      params.pattern = pattern;
    }

    const { response } = await api.groups.getTooltips({ url_params: { id: groupId }, params });
    const { payload, count } = response;
    commit(GROUP_TOOLTIPS, payload);
    commit(GROUP_TOOLTIPS_COUNT, count);
  },

  async addTooltips(context, { tooltips, groupId, type } = {}) {
    const { response } = await api.groups.addTooltips({
      url_params: { id: groupId },
      data: { tooltips, type },
    });

    return response;
  },

  async removeTooltips(context, { tooltips, groupId, type } = {}) {
    const { response } = await api.groups.removeTooltips({
      url_params: { id: groupId },
      data: { tooltips, type },
    });

    return response;
  },

  async getLinks(
    { commit },
    { groupId, page, limit, filterBy, filterDirection, pattern, type } = {}
  ) {
    const params = deepcopy({ page, limit, filterBy, filterDirection, type });

    if (pattern) {
      params.pattern = pattern;
    }

    const { response } = await api.groups.getLinks({ url_params: { id: groupId }, params });
    const { payload, count } = response;
    commit(GROUP_LINKS, payload);
    commit(GROUP_LINKS_COUNT, count);
  },

  async addLinks(context, { links, groupId } = {}) {
    const { response } = await api.groups.addLinks({
      url_params: { id: groupId },
      data: { links },
    });

    return response;
  },

  async removeLinks(context, { links, groupId } = {}) {
    const { response } = await api.groups.removeLinks({
      url_params: { id: groupId },
      data: { links },
    });

    return response;
  },
};

const mutations = {
  [GROUPS_LIST](state, payload) {
    state.groupsList = payload;
  },

  [GROUPS_COUNT](state, payload) {
    state.groupsCount = payload;
  },

  [GROUP_USERS](state, payload) {
    state.groupUsers = payload;
  },

  [GROUP_USERS_COUNT](state, payload) {
    state.groupUsersCount = payload;
  },

  [GROUP_SCENARIOS](state, payload) {
    state.groupScenarios = payload;
  },

  [GROUP_SCENARIOS_COUNT](state, payload) {
    state.groupScenariosCount = payload;
  },

  [GROUP_TOOLTIPS](state, payload) {
    state.groupTooltips = payload;
  },

  [GROUP_TOOLTIPS_COUNT](state, payload) {
    state.groupTooltipsCount = payload;
  },

  [GROUP_LINKS](state, payload) {
    state.groupLinks = payload;
  },

  [GROUP_LINKS_COUNT](state, payload) {
    state.groupLinksCount = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
