/* eslint-disable no-shadow,no-param-reassign,no-unused-vars */
import api from 'src/api';
import { deepcopy } from 'src/utils';

const actions = {
  send(context, { emailList, organizationId }) {
    const payload = {
      data: { emailList },
      params: deepcopy({ organizationId }),
    };

    return api.invites.send(payload);
  },

  check(context, code) {
    const payload = {
      url_params: { code },
    };

    return api.invites.check(payload);
  },

  accept(context, data) {
    const payload = {
      data,
    };

    return api.invites.accept(payload);
  },
};

export default {
  namespaced: true,
  actions,
};
