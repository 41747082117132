<template>
  <v-container fluid>
    <v-row class="content">
      <v-col class="d-flex flex-column justify-center align-center">
        <h4 class="font-weight-regular">
          HintEd
        </h4>

        <p>{{ $t('pages.mobileWelcome.warningMessage') }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-column justify-center align-center">
        <p class="text-center">{{ $t('pages.mobileWelcome.followLinkMessage') }}</p>

        <v-btn
          text
          block
          class="button-link button-link--filled font-weight-medium"
          tag="a"
          :href="originUrl"
        >
          {{ originUrl }}
        </v-btn>

        <p class="my-6 text--secondary">{{ $t('pages.mobileWelcome.or') }}</p>

        <send-login-link />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import SendLoginLink from 'src/components/SendLoginLink/SendLoginLink.vue';

  export default {
    name: 'Welcome',

    components: {
      SendLoginLink,
    },

    data() {
      return {
        originUrl: window.origin,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    height: 40vh;
  }

  .button-link {
    text-transform: none;
    text-transform: none;
    text-decoration: underline;
    color: #009efb;

    &--filled {
      background-color: rgba(0, 158, 251, 0.08);
    }
  }
</style>
