<template>
  <v-app class="password-change-needed">
    <VerticalHeader read-only />

    <v-main>
      <v-container class="fill-height justify-center">
        <v-card width="630">
          <v-form ref="form" @submit.prevent="send()">
            <v-card-text class="grey--text text--darken-3 pa-10">
              <h4 class="font-weight-bold grey--text text--darken-4">
                {{ $t('pages.passwordChangeNeeded.title') }}
              </h4>

              <div class="body-1 mt-6 payment-required__text">
                {{ $t('pages.passwordChangeNeeded.subtitle') }}
              </div>

              <v-text-field
                v-model="oldPassword"
                :label="$t('pages.passwordChangeNeeded.oldPassword')"
                outlined
                hide-details="auto"
                :rules="[rules.getRequiredRule('oldPassword')]"
                dense
                type="password"
                class="mt-8"
              />

              <v-text-field
                v-model="newPassword"
                :label="$t('pages.passwordChangeNeeded.newPassword')"
                :error-messages="newPasswordError ? [newPasswordError] : []"
                outlined
                hide-details="auto"
                :rules="[rules.getRequiredRule('newPassword'), rules.passwordLength]"
                dense
                type="password"
                class="mt-4"
                @input="newPasswordError = null"
              />

              <v-text-field
                v-model="passwordRepeat"
                :label="$t('pages.passwordChangeNeeded.passwordRepeat')"
                outlined
                hide-details="auto"
                :rules="[rules.getRequiredRule('passwordRepeat'), rules.passwordRepeat]"
                dense
                type="password"
                class="mt-4"
              />

              <div class="d-flex align-center mt-4 ">
                <v-btn color="primary" type="submit" dark class="text-none">
                  {{ $t('pages.passwordChangeNeeded.saveButton') }}
                </v-btn>
              </div>

              <div class="caption mt-8">
                {{ $t('pages.passwordChangeNeeded.supportText') }}
                <a
                  :href="`mailto:${supportEmail}`"
                  target="_blank"
                  class="text-decoration-underline grey--text text--darken-4"
                >
                  {{ supportEmail }}
                </a>
              </div>
            </v-card-text>
          </v-form>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import { mapState } from 'vuex';

  import api from 'src/api';
  import { dropToken } from 'src/services/session';
  import store from 'src/store';

  import VerticalHeader from 'src/components/VerticalHeader/VerticalHeader.vue';

  export default {
    name: 'PasswordChangeNeeded',

    components: {
      VerticalHeader,
    },

    data() {
      return {
        oldPassword: null,
        newPassword: null,
        passwordRepeat: null,
        newPasswordError: null,
        rules: {
          getRequiredRule: name => v =>
            !!v ||
            this.$t('validation.notNullField', {
              field: this.$t(`pages.passwordChangeNeeded.${name}`),
            }),
          passwordLength: v => (v && v.length >= 6) || this.$t('validation.passwordLength'),
          passwordRepeat: v => v === this.newPassword || this.$t('validation.passwordsDiff'),
        },
      };
    },

    computed: {
      ...mapState('settings', ['supportEmail']),

      isDemo() {
        return this.$route.query.isDemo === 'true';
      },
    },

    created() {
      if (!this.$route.query.redirected) {
        this.$router.push({ name: 'Dashboard' });
      } else {
        this.$router.push({
          name: 'PasswordChangeNeeded',
        });
      }
    },

    methods: {
      async send() {
        if (!this.$refs.form.validate()) {
          return;
        }

        try {
          await api.users.changePassword({
            data: {
              oldPassword: this.oldPassword,
              newPassword: this.newPassword,
            },
          });

          this.oldPassword = null;
          this.newPassword = null;
          this.passwordRepeat = null;

          this.$refs.form.resetValidation();
        } catch (err) {
          if (err.code === 'PASSWORD_USED_BEFORE') {
            this.newPasswordError = this.$t(`serverCode.PASSWORD_USED_BEFORE`);
          }
        } finally {
          if (!this.newPasswordError) {
            await store.dispatch('users/getCurrentUser');
            await dropToken();
            await this.$router.push({ name: 'login' });
          }
        }
      },
    },
  };
</script>
