<template>
  <v-card class="mb-5">
    <v-card-text class="pa-5">
      <div class="d-sm-flex align-center">
        <div>
          <h3 class="title font-weight-regular">
            <span class="lstick" />
            {{ $t('pages.statistics.stepsErrors.errorsHistory') }}:
          </h3>
        </div>
      </div>

      <div class="mt-2">
        <v-data-table
          :headers="headers"
          :items="scenarioErrorsHistory"
          :loading="isBusy"
          :server-items-length="count"
          :options.sync="options"
        >
          <template #no-data>
            <travolta-not-found />
          </template>

          <template #item.stepIndex="{item}">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon small color="info" v-bind="attrs" v-on="on">mdi-link-variant</v-icon>
              </template>
              <span>{{ item.origin }}</span>
            </v-tooltip>

            {{ $t('pages.statistics.stepsErrors.step') }} {{ item.stepIndex + 1 }}:
            {{ item.scenarioTitle }}
          </template>

          <template #item.error="{item}">
            <span class="primary--text">{{ getErrorText(item.code) }}</span>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { busyFlow } from 'src/utils';

  import TravoltaNotFound from 'src/components/TravoltaNotFound.vue';

  export default {
    name: 'ScenarioStepErrorsTable',

    components: {
      TravoltaNotFound,
    },

    data() {
      return {
        headers: [
          {
            text: this.$t('pages.statistics.stepsErrors.step'),
            value: 'stepIndex',
            sortable: false,
          },
          {
            text: this.$t('pages.statistics.stepsErrors.error'),
            value: 'error',
            sortable: false,
          },
        ],
        isBusy: false,

        count: 0,
        options: { itemsPerPage: 10, page: 1 },
      };
    },

    computed: {
      ...mapState('stats', ['scenarioErrorsHistory', 'scenarioId', 'filter']),
    },

    watch: {
      scenarioId() {
        this.loadStatistics();
      },

      filter: {
        handler() {
          this.loadStatistics();
        },
        deep: true,
      },

      options: {
        handler() {
          this.loadStatistics();
        },
        deep: true,
      },
    },

    methods: {
      ...mapActions('stats', ['getScenarioErrorsHistory']),

      loadStatistics() {
        busyFlow.call(
          this,
          async () => {
            this.count = await this.getScenarioErrorsHistory({
              limit: this.options.itemsPerPage === -1 ? 'all' : this.options.itemsPerPage,
              page: this.options.page,
            });
          },
          true
        );
      },

      getErrorText(errorCode) {
        switch (errorCode) {
          case 'SELECTOR_NOT_FOUND':
            return this.$t(`pages.statistics.stepsErrors.codes.${errorCode}`);
          default:
            return 'Unknown error';
        }
      },
    },
  };
</script>

<style lang="scss"></style>
