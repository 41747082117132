<template>
  <v-card>
    <v-card-text>
      <v-dialog v-model="hasGroupDialog" max-width="500px">
        <group-dialog
          :is-removing="isGroupRemovingMode"
          @close="closeGroupDialog"
          @remove="removeEntitiesFromGroups"
          @add="addEntitiesToGroups"
        />
      </v-dialog>

      <v-dialog v-model="isDownloadTriggersDialogOpened" max-width="500px">
        <triggers-dialog
          @downloadWithoutTriggers="downloadWithoutTriggers()"
          @downloadWithTriggers="downloadWithTriggers()"
          @close="isDownloadTriggersDialogOpened = false"
        />
      </v-dialog>

      <v-data-table
        v-model="selectedRows"
        :headers="tableHeaders"
        :items="formattedEntitiesList"
        :server-items-length="itemsCount"
        :options.sync="options"
        :loading="isBusy"
        :page.sync="page"
        class="contact-listing-app"
        show-select
      >
        <template #no-data>
          <travolta-not-found />
        </template>

        <template #top>
          <v-toolbar height="auto" flat class="mb-8">
            <v-container fluid class="px-0">
              <v-row align="start">
                <v-col cols="4" lg="3">
                  <v-text-field
                    v-model="currentSearch"
                    :label="`${$t(`${localeRootPath}.search`)}...`"
                    append-icon="mdi-magnify"
                    clearable
                    hide-details
                    class="mt-2"
                  >
                    <template #append-outer>
                      <filters-menu v-model="filters" />
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="8" lg="9">
                  <v-row justify="end">
                    <v-col cols="12" class="d-flex justify-end align-center">
                      <v-checkbox
                        v-if="isModeratorOrHigher"
                        v-model="trashedItems"
                        hide-details
                        :label="$t('common.showTrashed')"
                        class="mt-2 mr-2"
                      />

                      <template v-if="isAdmin">
                        <v-btn
                          v-if="selectedRows.length"
                          :loading="isBusyDownloadEntities && downloadIndex === -1"
                          color="grey lighten-4"
                          data-test-download-button
                          class="mr-2 text-none"
                          @click="openTriggersDialog(selectedRows)"
                        >
                          {{ $t(`${localeRootPath}.tableButtons.download`) }}

                          <v-icon right>
                            mdi-cloud-download
                          </v-icon>
                        </v-btn>

                        <v-btn
                          color="grey lighten-4"
                          :loading="isBusyUploadEntities"
                          data-test-upload-button
                          class="text-none"
                          @click="$refs.uploadInput.click()"
                        >
                          {{ $t(`${localeRootPath}.tableButtons.upload`) }}

                          <v-icon right>
                            mdi-cloud-upload
                          </v-icon>

                          <input
                            ref="uploadInput"
                            type="file"
                            accept="application/JSON"
                            class="d-none"
                            @change="uploadEntities($event)"
                          />
                        </v-btn>

                        <v-menu v-if="selectedRows.length && isEnterprisePlanActive" offset-y left>
                          <template #activator="{ on, attrs }">
                            <v-btn icon small v-bind="attrs" class="ml-2" v-on="on">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list dense>
                            <v-list-item @click="openWorkspaceMigrationDialog(selectedRows)">
                              <v-list-item-icon class="mr-0">
                                <v-icon small>mdi-folder-swap-outline</v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ $t('ui.workspaceEntityMigrateModal.buttonText') }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="updateOriginsDialogOpened = true">
                              <v-list-item-icon class="mr-0">
                                <v-icon small>mdi-sitemap-outline</v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ $t('pages.scenarios.tableButtons.manageDomains') }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="manageTagsDialogOpened = true">
                              <v-list-item-icon class="mr-0">
                                <v-icon small>mdi-tag-outline</v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ $t('pages.scenarios.tableButtons.manageTags') }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-col>

                    <v-col v-if="selectedRows.length" cols="12" class="d-flex justify-end">
                      <v-btn
                        color="primary"
                        dark
                        data-test-add-group-button
                        class="mr-2 text-none"
                        @click="addToGroups"
                      >
                        {{ $t('pages.scenarios.tableButtons.addToGroups') }}
                      </v-btn>

                      <v-btn
                        color="primary"
                        dark
                        data-test-remove-group-button
                        class="text-none mr-2"
                        @click="removeFromGroups"
                      >
                        {{ $t('pages.scenarios.tableButtons.removeFromGroups') }}
                      </v-btn>

                      <v-btn
                        color="primary"
                        dark
                        class="mr-2 text-none"
                        @click="openRemoveDialog(selectedRows)"
                      >
                        {{ $t('ui.common.remove') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <widget-entities-filter-preview v-model="filters" />
            </v-container>
          </v-toolbar>
        </template>

        <template #item.num="{ index }">
          <TableRowNumber :server-limit="serverLimit" :server-page="serverPage" :index="index" />
        </template>

        <template #item.origin="{ item }">
          {{ getHostnameFromUrls(item.originList, item.origin) }}
        </template>

        <template #item.title="{ item }">
          {{ item.title }}

          <v-chip
            v-if="item.onModerating || item.isDraft"
            class="ma-2"
            color="pink"
            label
            text-color="white"
          >
            {{ $t('pages.scenarios.onModerating') }}
          </v-chip>
        </template>

        <template #item.tagsList="{value, item}">
          <v-chip
            v-for="tag in value"
            :key="`${item.id}-tag-${tag.title}`"
            :color="tag.color"
            small
            class="mr-2 mt-2"
          >
            {{ tag.title }}
          </v-chip>
        </template>

        <template #item.avgRate="{ value, item }">
          <div v-if="item.hasRate" class="d-flex align-center justify-center">
            <v-icon small color="#FFA630">
              mdi-star
            </v-icon>

            <span class="ml-2">{{ value }}</span>
          </div>

          <span v-else>
            &#8212;
          </span>
        </template>

        <template #item.action="{ item, index }">
          <v-tooltip v-if="trashedItems" bottom>
            <template #activator="{ on, attrs }">
              <v-btn icon small v-bind="attrs" @click="restoreItem(item.id)" v-on="on">
                <v-icon small>
                  mdi-delete-restore
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t(`common.actions.restore`) }}</span>
          </v-tooltip>

          <template v-else-if="item.isEditable">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="info"
                  small
                  icon
                  :data-test-edit-button="item.id"
                  v-bind="attrs"
                  @click="
                    () => {
                      openEditDialog(item);
                      sendMetrics('web_walkthroughs_edit_button', 'click');
                    }
                  "
                  v-on="on"
                >
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t(`common.actions.edit`) }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="error"
                  small
                  icon
                  data-test-remove-button
                  v-bind="attrs"
                  @click="
                    () => {
                      openRemoveDialog([item]);
                      sendMetrics('web_walkthroughs_delete_button', 'click');
                    }
                  "
                  v-on="on"
                >
                  <v-icon small>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t(`common.actions.delete`) }}</span>
            </v-tooltip>

            <v-menu offset-y left>
              <template #activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="sendMetrics('web_walkthroughs_extras_button', 'click')"
                >
                  <v-icon small>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-if="isEnterprisePlanActive && !tooltipsMode"
                  :to="{ name: 'Ratings', query: { scenarioId: item.id } }"
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon small>mdi-star-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('pages.scenarios.showRatings') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  data-test-download-scenario-button
                  @click="
                    () => {
                      openTriggersDialog([item], index);
                      sendMetrics('web_walkthroughs_download_button', 'click');
                    }
                  "
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon small>mdi-cloud-download</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('ui.common.download') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="isAdmin && isEnterprisePlanActive"
                  @click="openWorkspaceMigrationDialog([item])"
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon small>mdi-folder-swap-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('ui.workspaceEntityMigrateModal.buttonText') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </template>
      </v-data-table>
    </v-card-text>

    <entity-edit-modal
      :editing-entity="editingEntity"
      :tooltips-mode="tooltipsMode"
      @updated="onUpdateEntity()"
      @created="onUpdateEntity(true)"
      @close="clearEditingItem()"
    />

    <v-snackbar
      v-model="showedUpdatedToast"
      :color="isCreating ? 'success' : 'default'"
      timeout="3000"
    >
      {{ $t(`${localeRootPath}.${isCreating ? 'createTitle' : 'updateTitle'}`) }}
    </v-snackbar>

    <v-dialog v-model="uploadErrorDialogOpened" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('pages.scenarios.uploadErrorTitle') }}
        </v-card-title>

        <v-card-text>
          {{ $t('pages.scenarios.uploadErrorText') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="primary" text @click="uploadErrorDialogOpened = false">
            {{ $t('ui.common.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeDialogOpened" max-width="500">
      <remove-item-modal
        v-if="removeDialogOpened"
        :title="$t('ui.common.confirm')"
        :text="removeConfirmText"
        :remove-callback="removeCallback"
        data-test-confirm-remove-dialog
        @close="removeDialogOpened = false"
        @removed="onRemoved()"
      />
    </v-dialog>

    <v-dialog v-model="updateOriginsDialogOpened" max-width="694">
      <EditOriginsModal
        v-if="updateOriginsDialogOpened"
        :entities="selectedRows"
        :entity-type="tooltipsMode ? 'tooltips' : 'scenarios'"
        @close="updateOriginsDialogOpened = false"
        @updated="onUpdateOrigins()"
      />
    </v-dialog>

    <v-dialog v-model="manageTagsDialogOpened" max-width="694">
      <manage-tags-modal
        v-if="manageTagsDialogOpened"
        :entities="selectedRows"
        :entity-type="tooltipsMode ? 'tooltips' : 'scenarios'"
        @close="manageTagsDialogOpened = false"
      />
    </v-dialog>

    <v-dialog v-model="workspaceMigratingDialogOpened" max-width="450px">
      <workspace-entity-migrate-modal
        v-if="workspaceMigratingDialogOpened"
        :type="tooltipsMode ? 'tooltips' : 'scenarios'"
        :list="workspaceMigratingIds"
        @changed="update()"
        @close="closeWorkspaceModal()"
      />
    </v-dialog>
  </v-card>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import debounce from 'lodash.debounce';
  import urlParse from 'url-parse';
  import { saveAs } from 'file-saver';

  import eventsService from 'src/services/events';

  import GroupDialog from 'src/components/GroupDialog.vue';
  import TriggersDialog from 'src/components/TriggersDialog.vue';
  import EntityEditModal from 'src/components/WidgetEntities/EntityEditModal.vue';
  import EditOriginsModal from 'src/components/WidgetEntities/EditOriginsModal.vue';
  import WorkspaceEntityMigrateModal from 'src/components/WorkspaceEntityMigrateModal/WorkspaceEntityMigrateModal.vue';
  import RemoveItemModal from 'src/components/RemoveItemModal/RemoveItemModal.vue';
  import ManageTagsModal from 'src/components/ManageTagsModal/ManageTagsModal.vue';
  import WidgetEntitiesFilterPreview from 'src/components/WidgetEntitiesFilterPreview/WidgetEntitiesFilterPreview.vue';
  import FiltersMenu from 'src/components/WidgetEntities/components/FiltersMenu.vue';
  import TravoltaNotFound from 'src/components/TravoltaNotFound.vue';

  import TableRowNumber from 'src/components/commonComponents/TableRowNumber.vue';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  import { busyFlow, deepcopy } from 'src/utils';
  import { EVENT_TYPE_CHANGE_DRAFT_STATUS, EVENT_TYPE_CONTENT_MODERATION } from 'src/constants';

  import { migrateEntities } from '@hinted/entity-migrations';

  const components = {
    GroupDialog,
    EntityEditModal,
    EditOriginsModal,
    TriggersDialog,
    WorkspaceEntityMigrateModal,
    RemoveItemModal,
    ManageTagsModal,
    WidgetEntitiesFilterPreview,
    FiltersMenu,
    TableRowNumber,
    TravoltaNotFound,
  };

  const props = {
    tooltipsMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  };

  function data() {
    return {
      dialog: false,
      options: {
        itemsPerPage: 10,
        sortBy: ['createdAt'],
        sortDesc: [true],
      },
      isBusy: false,
      isWithTriggers: false,
      isDownloadTriggersDialogOpened: false,
      isBusyDownloadEntities: false,
      downloadIndex: -1,
      uploadErrorDialogOpened: false,
      isBusyUploadEntities: false,
      limit: 10,
      page: 1,
      pattern: undefined,
      filterBy: undefined,
      filterDirection: undefined,
      selectedRows: [],
      hasGroupDialog: false,
      isGroupRemovingMode: false,
      editingEntity: null,
      showedUpdatedToast: false,
      deletingItems: [],
      isCreating: false,
      updateOriginsDialogOpened: false,
      trashedItems: false,
      serverLimit: 1,
      serverPage: 1,

      workspaceMigratingDialogOpened: false,
      workspaceMigratingIds: [],

      removeDialogOpened: false,
      removeCallback: () => {},

      manageTagsDialogOpened: false,

      filters: {
        creatorsEmails: null,
        tags: null,
        publishStatus: null,
        creationDatesRange: null,
      },
    };
  }

  const computed = {
    ...mapState('scenarios', ['scenariosList', 'scenariosCount']),
    ...mapState('tooltips', ['tooltipsList', 'tooltipsCount']),
    ...mapState('groups', ['groupScenarios', 'groupScenariosCount']),
    ...mapGetters('users', [
      'isAdmin',
      'isAdminOrHigher',
      'isEnterprisePlanActive',
      'isModeratorOrHigher',
    ]),

    localeRootPath() {
      return this.tooltipsMode ? 'pages.tooltips' : 'pages.scenarios';
    },

    removeConfirmText() {
      return this.$t(
        `${this.localeRootPath}.${
          this.deletingItems.length === 1 ? 'removeConfirmText' : 'removeManyConfirmText'
        }`
      );
    },

    tableHeaders() {
      return [
        {
          value: 'num',
          text: '#',
          sortable: false,
          align: 'center',
          width: '40px',
        },
        {
          text: this.$t('pages.scenarios.tableHeaders.title'),
          value: 'title',
          class: ['text-truncate'],
        },
        ...(this.isEnterprisePlanActive
          ? [
              {
                text: this.$t('pages.scenarios.tableHeaders.tagsList'),
                value: 'tagsList',
                width: 250,
              },
            ]
          : []),
        {
          text: this.$t('pages.scenarios.tableHeaders.origin'),
          value: 'origin',
          class: ['text-truncate'],
        },
        { text: this.$t('pages.scenarios.tableHeaders.creator'), value: 'creatorEmail' },
        { text: this.$t('common.fields.created'), value: 'createdAt', width: '150px' },
        ...(this.tooltipsMode || !this.isEnterprisePlanActive
          ? []
          : [
              {
                text: this.$t('pages.scenarios.tableHeaders.avgRate'),
                value: 'avgRate',
                sortable: false,
                width: '80px',
                align: 'center',
              },
            ]),
        {
          text:
            (this.tooltipsMode && this.$t('pages.tooltips.tableHeaders.stepsCount')) ||
            this.$t('pages.scenarios.tableHeaders.stepsCount'),
          value: 'stepsCount',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('ui.common.actions'),
          value: 'action',
          sortable: false,
          align: 'end',
          width: '120px',
        },
      ];
    },

    currentSearch: {
      get() {
        return this.pattern;
      },

      set(value) {
        this.debouncedSearch(value);
      },
    },

    formattedEntitiesList() {
      let items = this.isGroupMode ? this.groupScenarios : this.scenariosList;
      if (this.tooltipsMode) {
        items = this.isGroupMode ? this.groupScenarios : this.tooltipsList;
      }

      return items.map(item => {
        const description = item.description === 'null' ? '' : item.description;
        const createdAt = this.formatDateTime(item.createdAt);
        const stepsCount = (item.steps || []).length;
        return {
          ...item,
          createdAt,
          description,
          stepsCount,
        };
      });
    },

    isGroupMode() {
      return Boolean(this.$route.query.groupId);
    },

    itemsCount() {
      let count = this.isGroupMode ? this.groupScenariosCount : this.scenariosCount;
      if (this.tooltipsMode) {
        count = this.isGroupMode ? this.groupScenariosCount : this.tooltipsCount;
      }

      return count;
    },
  };

  const watch = {
    tooltipsMode(value) {
      if (value) {
        this.clearTooltipsList();
      } else {
        this.clearList();
      }
    },
    options: {
      handler(value) {
        this.setValues(value);
      },

      deep: true,
    },

    '$route.query': {
      deep: true,

      handler() {
        this.resetValues();
        this.update();
      },
    },

    trashedItems() {
      this.resetValues();
      this.update();
    },

    uploadErrorDialogOpened(value) {
      if (!value) {
        this.isBusyUploadEntities = false;
      }
    },

    filters: {
      handler() {
        this.update();
      },
      deep: true,
    },
  };

  function created() {
    if (this.tooltipsMode) {
      this.clearTooltipsList();
    } else {
      this.clearList();
    }
  }

  function mounted() {
    this.update();

    eventsService.on(EVENT_TYPE_CONTENT_MODERATION, this.onCreateEntityDraft);
    eventsService.on(EVENT_TYPE_CHANGE_DRAFT_STATUS, this.changeDraftNotificationStatus);
  }

  function beforeDestroy() {
    eventsService.off(EVENT_TYPE_CONTENT_MODERATION, this.onCreateEntityDraft);
    eventsService.off(EVENT_TYPE_CHANGE_DRAFT_STATUS, this.changeDraftNotificationStatus);
  }

  const methods = {
    ...mapActions('scenarios', [
      'listWithParams',
      'clearList',
      'removeScenario',
      'restoreScenario',
      'getListByIds',
      'createManyScenarios',
    ]),
    ...mapActions('tooltips', {
      getTooltipsList: 'getList',
      clearTooltipsList: 'clearList',
      removeTooltip: 'removeTooltip',
      restoreTooltip: 'restoreTooltip',
      getTooltipsListByIds: 'getListByIds',
      createManyTooltips: 'createManyTooltips',
    }),
    ...mapActions('groups', [
      'addScenarios',
      'addTooltips',
      'removeTooltips',
      'removeScenarios',
      'getScenarios',
      'getTooltips',
    ]),

    debouncedSearch: debounce(function search(pattern) {
      this.pattern = pattern || undefined;
      this.resetValues();
      this.update();
    }, 300),

    openEditDialog(item) {
      this.editingEntity = deepcopy(item);
    },

    openRemoveDialog(items) {
      this.deletingItems = items;

      const method = this.tooltipsMode ? this.removeTooltip : this.removeScenario;

      this.removeCallback = () =>
        Promise.all(items.filter(item => item.isEditable).map(item => method(item.id)));

      this.removeDialogOpened = true;
    },

    onRemoved() {
      this.update();
    },

    async restoreItem(id) {
      const method = this.tooltipsMode ? this.restoreTooltip : this.restoreScenario;

      await method(id);

      this.update();
    },

    setValues(obj) {
      const {
        itemsPerPage,
        page,
        sortBy: [filterBy],
        sortDesc: [isDesc],
      } = obj;

      this.limit = itemsPerPage === -1 ? 'all' : itemsPerPage;
      this.page = page;
      this.filterBy = filterBy;
      this.filterDirection = (isDesc && 'desc') || 'asc';

      this.update();
    },

    resetValues() {
      this.limit = 10;
      this.page = 1;
      this.filterBy = undefined;
      this.filterDirection = undefined;
    },

    async update() {
      if (this.isBusy) {
        return;
      }

      this.selectedRows = [];
      this.isBusy = true;

      let method = this.isGroupMode ? this.getScenarios : this.listWithParams;
      if (this.tooltipsMode) {
        method = this.isGroupMode ? this.getTooltips : this.getTooltipsList;
      }

      try {
        await method({
          page: this.page,
          limit: this.limit,
          filterBy: this.filterBy,
          filterDirection: this.filterDirection,
          pattern: this.pattern,
          groupId: this.$route.query.groupId,
          trashedItems: this.trashedItems,
          ...this.filters,
        });

        this.serverLimit = this.options.itemsPerPage;
        this.serverPage = this.page;
      } catch (err) {
        console.error(err);
      } finally {
        this.isBusy = false;
      }
    },

    closeGroupDialog() {
      this.hasGroupDialog = false;
    },

    openGroupDialog() {
      this.hasGroupDialog = true;
    },

    openTriggersDialog(items, index = -1) {
      this.downloadItems = items;
      this.downloadIndex = index;

      if (this.tooltipsMode) {
        this.downloadEntities();
        return;
      }

      this.isDownloadTriggersDialogOpened = true;
    },

    downloadWithoutTriggers() {
      this.isDownloadTriggersDialogOpened = false;
      this.isWithTriggers = false;
      return this.downloadEntities();
    },

    downloadWithTriggers() {
      this.isDownloadTriggersDialogOpened = false;
      this.isWithTriggers = true;
      return this.downloadEntities();
    },

    addToGroups() {
      this.isGroupRemovingMode = false;
      this.openGroupDialog();
    },

    removeFromGroups() {
      this.isGroupRemovingMode = true;
      this.openGroupDialog();
    },

    async removeEntitiesFromGroups(groups) {
      this.closeGroupDialog();

      if (!groups.length || this.isBusy) {
        return;
      }

      const entities = this.selectedRows.map(item => item.id);

      try {
        const promises = groups.map(groupId =>
          this.tooltipsMode
            ? this.removeTooltips({ groupId, tooltips: entities })
            : this.removeScenarios({ groupId, scenarios: entities })
        );
        await Promise.all(promises);
      } catch (err) {
        console.error(err);
      } finally {
        this.isBusy = false;
      }

      this.update();
    },

    async addEntitiesToGroups(groups) {
      this.closeGroupDialog();

      if (!groups.length || this.isBusy) {
        return;
      }

      const entities = this.selectedRows.map(item => item.id);

      try {
        const promises = groups.map(groupId =>
          this.tooltipsMode
            ? this.addTooltips({ groupId, tooltips: entities })
            : this.addScenarios({ groupId, scenarios: entities })
        );
        await Promise.all(promises);
      } catch (err) {
        console.error(err);
      } finally {
        this.isBusy = false;
      }

      this.update();
    },

    getHostnameFromUrls(originList, origin) {
      const parseUrl = url => {
        try {
          return (url && urlParse(url).hostname) || '---';
        } catch (err) {
          return '---';
        }
      };

      if (!originList?.length) {
        return parseUrl(origin);
      }

      return (
        originList
          .filter(({ type }) => type === 'text')
          .map(({ value }) => parseUrl(value))
          .join(', ') || '---'
      );
    },

    onUpdateEntity(isCreating = false) {
      this.isCreating = isCreating;
      this.update();
      this.showedUpdatedToast = true;
    },

    onUpdateOrigins() {
      this.update();
      this.updateOriginsDialogOpened = false;
    },

    clearEditingItem() {
      this.editingEntity = null;
    },

    async downloadEntities() {
      if (!this.downloadItems.length) {
        return;
      }

      const ids = this.downloadItems.map(item => item.id);

      await busyFlow.call(
        this,
        async () => {
          const entities = this.tooltipsMode
            ? await this.getTooltipsListByIds(ids)
            : await this.getListByIds({ ids, isWithTriggers: this.isWithTriggers });

          const blob = new Blob([JSON.stringify(entities, null, 2)], {
            type: 'text/plain;charset=utf-8',
          });
          saveAs(blob, `${this.tooltipsMode ? 'tooltips' : 'scenarios'}.json`);
        },
        false,
        'isBusyDownloadEntities'
      );

      this.downloadIndex = -1;
    },

    async uploadEntities($event) {
      try {
        if (!$event.target.files.length) {
          return;
        }

        this.isBusyUploadEntities = true;

        const fileReader = new FileReader();
        fileReader.onload = async fileLoadedEvent => {
          try {
            const entities = JSON.parse(fileLoadedEvent.target.result);

            const items = migrateEntities(entities.items, entities.type);

            const method = this.tooltipsMode ? 'createManyTooltips' : 'createManyScenarios';

            await this[method]({ type: entities.type, items });
            await this.update();
          } catch (e) {
            console.log(e);
            this.uploadErrorDialogOpened = true;
          } finally {
            this.isBusyUploadEntities = false;
          }
        };

        fileReader.readAsText($event.target.files[0], 'UTF-8');

        this.$refs.uploadInput.value = '';
      } catch (e) {
        console.log(e);
        this.uploadErrorDialogOpened = true;
      }
    },

    onCreateEntityDraft(eventData) {
      const entity = this.formattedEntitiesList.find(
        entityItem => entityItem.id === eventData.entityId
      );
      if (entity) {
        entity.onModerating = true;
      }
    },

    changeDraftNotificationStatus({ data: { entityId } }) {
      const entity = this.formattedEntitiesList.find(entityItem => entityItem.id === entityId);
      if (entity) {
        entity.onModerating = false;
        entity.isDraft = false;
      }
    },

    openWorkspaceMigrationDialog(entities) {
      this.workspaceMigratingIds = entities.map(entity => entity.id);
      this.workspaceMigratingDialogOpened = true;
    },

    closeWorkspaceModal() {
      this.workspaceMigratingDialogOpened = false;
      this.workspaceMigratingIds = [];
    },
  };

  export default {
    name: 'Scenarios',
    components,
    mixins: [formatTimestampMixin],
    inject: ['sendMetrics'],
    props,
    data,
    computed,
    watch,
    created,
    mounted,
    beforeDestroy,
    methods,
  };
</script>
