<template>
  <v-container fluid class="billing">
    <billing-skeleton-loader v-if="isBusy" />

    <template v-else>
      <billing-info class="billing__info" :subscription="subscription" />

      <trial-card
        v-if="
          subscription.plan === $options.ORGANIZATION_PLAN_SMART_MANUALS_START &&
            !subscription.trialAlreadyUsed
        "
        class="billing__trial"
      />

      <h2 class="billing__title">
        {{ $t('pages.billing.plans') }}
      </h2>

      <payment-period v-model="subscribePeriod" />

      <div class="billing__plans-wrapper">
        <plan-card name="Start" theme="orange" :features="featuresStart">
          <template #price>
            {{ $t('pages.billing.free') }}
          </template>

          <template #priceShadow>
            {{ $t('pages.billing.free') }}
          </template>

          <template #footer>
            <billing-button
              v-if="subscription.plan === $options.ORGANIZATION_PLAN_SMART_MANUALS_START"
              block
              x-large
              disabled
              theme="white"
            >
              <v-icon left size="17">mdi-check</v-icon>

              {{ $t('pages.billing.yourPlan') }}
            </billing-button>
          </template>
        </plan-card>

        <plan-card name="Pro" theme="blue" :discount="planProDiscount" :features="featuresPro">
          <template #price>
            <div class="billing__plan-pro-price">
              <span>
                <sup>$</sup>{{ planProPrice
                }}<sub>/{{ $t('pages.billing.monthAbbreviation') }}</sub>
              </span>
            </div>
          </template>

          <template #priceShadow>
            <div class="billing__plan-pro-price">
              <span>
                {{ planProPrice }}<sub>/{{ $t('pages.billing.monthAbbreviation') }}</sub>
              </span>
            </div>
          </template>

          <template #footer>
            <billing-button
              v-if="subscription.plan === $options.ORGANIZATION_PLAN_SMART_MANUALS_PRO"
              block
              x-large
              disabled
              theme="white"
            >
              <v-icon left size="17">mdi-check</v-icon>

              {{ $t('pages.billing.yourPlan') }}
            </billing-button>

            <billing-button v-else block x-large theme="white" @click="subscribe()">
              {{ $t('pages.billing.upgradeToPro') }}

              <v-icon right size="17">mdi-arrow-right</v-icon>
            </billing-button>
          </template>
        </plan-card>

        <plan-card name="Enterprise" theme="green" :features="featuresEnterprise">
          <template #price>
            {{ $t('pages.billing.custom') }}
          </template>

          <template #priceShadow>
            {{ $t('pages.billing.custom') }}
          </template>

          <template #footer>
            <billing-button
              v-if="subscription.plan === $options.ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE"
              block
              x-large
              disabled
              theme="white"
            >
              <v-icon left size="17">mdi-check</v-icon>

              {{ $t('pages.billing.yourPlan') }}
            </billing-button>

            <billing-button v-else block x-large theme="white" @click="feedbackDialogOpened = true">
              {{ $t('pages.billing.enterpriseRequestSupport') }}

              <v-icon right size="17">mdi-arrow-right</v-icon>
            </billing-button>
          </template>
        </plan-card>
      </div>

      <questions-block class="billing__help" />

      <billing-feedback-modal
        v-if="feedbackDialogOpened"
        @close="feedbackDialogOpened = false"
        @sent="onSentFeedback()"
      />

      <billing-feedback-success-modal
        v-if="feedbackSuccessDialogOpened"
        @close="feedbackSuccessDialogOpened = false"
      />
    </template>
  </v-container>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';

  import api from 'src/api';

  import { buildSpec } from 'src/config';

  import { busyFlow } from 'src/utils';

  import {
    APP_BUILD_SPEC_INTERNATIONAL,
    ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,
    ORGANIZATION_PLAN_SMART_MANUALS_PRO,
    ORGANIZATION_PLAN_SMART_MANUALS_START,
  } from 'src/constants';

  import BillingButton from './components/BillingButton/BillingButton.vue';
  import BillingInfo from './components/BillingInfo/BillingInfo.vue';
  import PaymentPeriod from './components/PaymentPeriod/PaymentPeriod.vue';
  import PlanCard from './components/PlanCard/PlanCard.vue';
  import QuestionsBlock from './components/QuestionsBlock/QuestionsBlock.vue';
  import TrialCard from './components/TrialCard/TrialCard.vue';
  import BillingFeedbackModal from './components/BillingFeedbackModal/BillingFeedbackModal.vue';
  import BillingFeedbackSuccessModal from './components/BillingFeedbackSuccessModal/BillingFeedbackSuccessModal.vue';
  import BillingSkeletonLoader from './components/BillingSkeletonLoader/BillingSkeletonLoader.vue';

  import { PERIOD_YEARLY } from './utils/subscriptionPeriods';

  export default {
    name: 'Billing',

    components: {
      BillingButton,
      BillingInfo,
      PaymentPeriod,
      PlanCard,
      QuestionsBlock,
      TrialCard,
      BillingFeedbackModal,
      BillingFeedbackSuccessModal,
      BillingSkeletonLoader,
    },

    data() {
      return {
        isBusy: true,

        subscribePeriod: PERIOD_YEARLY,
        feedbackDialogOpened: false,
        feedbackSuccessDialogOpened: false,

        subscription: {
          plan: null,
          price: '-',
          nextPaymentDate: -1,
          daysLeft: -1,
          activeUntil: -1,
          trialAlreadyUsed: false,
        },

        featuresStart: [
          {
            text: this.$t('pages.billing.features.unlimitedNumbersOfSmartManuals'),
          },
          {
            text: this.$t('pages.billing.features.unlimitedViews'),
          },
          {
            text: this.$t('pages.billing.features.unlimitedStepsCount'),
          },
          {
            text: this.$t('pages.billing.features.unlimitedUsers'),
          },
          {
            text: this.$t('pages.billing.features.share'),
          },
          {
            text: this.$t('pages.billing.features.browserExtension'),
          },
        ],
        featuresPro: [
          {
            text: this.$t('pages.billing.features.allFeaturesOfStart'),
            bold: true,
          },
          {
            text: this.$t('pages.billing.features.branding'),
          },
          {
            text: this.$t('pages.billing.features.blur'),
          },
          {
            text: this.$t('pages.billing.features.exportPdfAndDocx'),
          },
          {
            text: this.$t('pages.billing.features.customSettingsForShare'),
            soon: true,
          },
          {
            text: this.$t('pages.billing.features.embedCode'),
          },
          {
            text: this.$t('pages.billing.features.desktopApp'),
          },
        ],
        featuresEnterprise: [
          {
            text: this.$t('pages.billing.features.allFeaturesOfPro'),
            bold: true,
          },
          {
            text: this.$t('pages.billing.features.testing'),
          },
          {
            text: this.$t('pages.billing.features.workspaces'),
          },
          {
            text: this.$t('pages.billing.features.scorm'),
          },
          {
            text: this.$t('pages.billing.features.advancedSecurity'),
          },
          {
            text: this.$t('pages.billing.features.onPremise'),
          },
          {
            text: this.$t('pages.billing.features.accountManager'),
          },
        ],
      };
    },

    ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,
    ORGANIZATION_PLAN_SMART_MANUALS_PRO,
    ORGANIZATION_PLAN_SMART_MANUALS_START,

    computed: {
      ...mapState('users', ['currentUser']),
      ...mapGetters('users', ['isRealAdminOrHigher', 'isSmartManualsPlansActive']),

      planProDiscount() {
        return this.subscribePeriod === PERIOD_YEARLY
          ? this.$t('pages.billing.discount', { discount: 20 })
          : null;
      },

      planProPrice() {
        return this.subscribePeriod === PERIOD_YEARLY ? '16.80' : '21';
      },
    },

    beforeMount() {
      if (
        buildSpec !== APP_BUILD_SPEC_INTERNATIONAL ||
        !this.isRealAdminOrHigher ||
        !this.isSmartManualsPlansActive
      ) {
        this.$router.push({ name: 'Dashboard' });
      }
    },

    mounted() {
      this.loadSubscription();
    },

    methods: {
      loadSubscription() {
        busyFlow.call(
          this,
          async () => {
            const { response } = await api.payments.getSubscription();

            this.subscription = response;
          },
          true
        );
      },

      onSentFeedback() {
        this.feedbackDialogOpened = false;
        this.feedbackSuccessDialogOpened = true;
      },

      async subscribe() {
        const { response } = await api.payments.subscribe({
          data: {
            period: this.subscribePeriod,
          },
        });

        window.location.href = response.url;
      },
    },
  };
</script>

<style lang="scss">
  .billing {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__info {
      max-width: 1213px;
    }

    &__trial {
      margin-top: 50px;
      max-width: 980px;
      width: 100%;
    }

    &__title {
      margin: 50px 0 40px;
      font-weight: 400;
      font-size: 34px;
      line-height: 40px;
      letter-spacing: 0.0025em;
      color: #343349;
    }

    &__plans-wrapper {
      margin-top: 50px;
      display: grid;
      grid-gap: 40px;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: minmax(0, auto);
    }

    &__plan-pro-price {
      sup {
        font-size: 0.6em;
        top: -0.6em;
      }

      sub {
        font-size: 0.6em;
        bottom: 0;
      }
    }

    &__help {
      margin-top: 50px;
    }
  }
</style>
