<template>
  <base-download-nested-document-dialog-vue
    :show="show"
    :is-busy="isBusy"
    format="Pdf"
    @close="$emit('close')"
    @download="downloadPdf"
  />
</template>
<script>
  import { PDFDocument } from 'pdf-lib';
  import { saveAs } from 'file-saver';

  import api from 'src/api';
  import BaseDownloadNestedDocumentDialogVue from 'src/components/BaseDownloadNestedDocumentDialog/BaseDownloadNestedDocumentDialog.vue';

  export default {
    name: 'DownloadPdf',

    components: {
      BaseDownloadNestedDocumentDialogVue,
    },

    props: {
      show: { type: Boolean, required: true, default: false },
      id: { type: String, required: true },
      title: { type: String, required: true },
    },

    data: () => ({
      isBusy: false,
    }),

    methods: {
      async downloadPdf(file) {
        this.isBusy = true;

        try {
          const { response } = await api.simulations.downloadPDF({
            url_params: { id: this.id },
          });
          if (file) {
            const mergedPdfDoc = await PDFDocument.create();

            const mergePdfPages = async (pdfFileSrc, pdfFile) => {
              const pdfBuffer = await pdfFileSrc.arrayBuffer();
              const pdfDoc = await PDFDocument.load(pdfBuffer);
              return pdfFile.copyPages(pdfDoc, pdfDoc.getPageIndices());
            };

            const userPages = await mergePdfPages(file, mergedPdfDoc);
            const simulationPages = await mergePdfPages(response, mergedPdfDoc);

            await Promise.all(
              [...userPages, ...simulationPages].map(async page => {
                await mergedPdfDoc.addPage(page);
              })
            );
            const pdfFileBuffer = (await mergedPdfDoc.save()).buffer;
            return saveAs(new Blob([pdfFileBuffer]), `${this.title}.pdf`);
          }

          return saveAs(response, `${this.title}.pdf`);
        } finally {
          this.isBusy = false;
          this.$emit('close');
        }
      },
    },
  };
</script>
