import api from 'src/api';

import { SET_ADMIN_PANEL_SETTINGS } from 'src/store/mutation-types';

import { buildSpecConfig } from 'src/config';

export default {
  namespaced: true,

  state: {
    supportEmail: buildSpecConfig.supportEmail,
    showInviteUserButton: true,
    isCloud: false,
    isDemo: false,
    hasLDAP: false,
    kerberosEnabled: false,
    simulationsEnabled: false,
    isSettingsLoaded: false,
    allowedUserInfoFields: 'all',
    isMobilePlatform: false,
  },

  actions: {
    async getSettings({ commit }) {
      try {
        const { response } = await api.adminPanel.getSettings();
        response.isSettingsLoaded = true;
        commit(SET_ADMIN_PANEL_SETTINGS, response);
      } catch (error) {
        console.log(error);
      }
    },

    setIsMobilePlatform({ commit }, isMobilePlatform) {
      commit(SET_ADMIN_PANEL_SETTINGS, { isMobilePlatform });
    },
  },

  mutations: {
    [SET_ADMIN_PANEL_SETTINGS](state, settings) {
      Object.entries(settings).forEach(([key, value]) => {
        state[key] = value;
      });
    },
  },
};
