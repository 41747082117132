// auth
export const AUTH_STATE = 'AUTH_STATE';

// user
export const USER_DATA = 'USER_DATA';

export const USERS_LIST = 'USERS_LIST';
export const USERS_ERROR = 'USERS_ERROR';
export const USERS_CLEAR_ERROR = 'USERS_CLEAR_ERROR';
export const USERS_ROLES = 'USERS_ROLES';
export const SET_USER_WORKSPACE = 'SET_USER_WORKSPACE';
export const USERS_CREATE_MANY = 'USERS_CREATE_MANY';
export const USERS_SET_EMAIL_RETRY_PERIOD = 'USERS_SET_EMAIL_RETRY_PERIOD';

export const ORGANIZATIONS_LIST = 'ORGANIZATIONS_LIST';
export const ORGANIZATIONS_QUERY_LIST = 'ORGANIZATIONS_QUERY_LIST';
export const ORGANIZATIONS_QUERY_COUNT = 'ORGANIZATIONS_QUERY_COUNT';

export const SCENARIOS_LIST = 'SCENARIOS_LIST';
export const SCENARIOS_UPDATE_ITEM = 'SCENARIOS_UPDATE_ITEM';
export const SCENARIOS_COUNT = 'SCENARIOS_COUNT';
export const SCENARIOS_ERROR = 'SCENARIOS_ERROR';

export const SET_TOOLTIPS_LIST = 'SET_TOOLTIPS_LIST';
export const SET_TOOLTIPS_COUNT = 'SET_TOOLTIPS_COUNT';

export const GROUPS_LIST = 'GROUPS_LIST';
export const GROUPS_COUNT = 'GROUPS_COUNT';
export const GROUP_USERS = 'GROUP_USERS';
export const GROUP_USERS_COUNT = 'GROUP_USERS_COUNT';
export const GROUP_SCENARIOS = 'GROUP_SCENARIOS';
export const GROUP_SCENARIOS_COUNT = 'GROUP_SCENARIOS_COUNT';
export const GROUP_TOOLTIPS = 'GROUP_TOOLTIPS';
export const GROUP_TOOLTIPS_COUNT = 'GROUP_TOOLTIPS_COUNT';
export const GROUP_LINKS = 'GROUP_LINKS';
export const GROUP_LINKS_COUNT = 'GROUP_LINKS_COUNT';

export const DURATION_TO_AMOUNT_DATA = 'DURATION_TO_AMOUNT_DATA';
export const STEP_DURATIONS_DATA = 'STEP_DURATIONS_DATA';
export const INTERRUPTED_AND_TOTAL_DATA = 'INTERRUPTED_AND_TOTAL_DATA';
export const SCENARIO_CARDS_DATA = 'SCENARIO_CARDS_DATA';
export const SCENARIO_STATISTICS_DATA = 'SCENARIO_STATISTICS_DATA';
export const SCENARIO_STATS_DATA = 'SCENARIO_STATS_DATA';
export const SCENARIO_STEPS_STATS_DATA = 'SCENARIO_STEPS_STATS_DATA';
export const SCENARIO_ERRORS_HISTORY = 'SCENARIO_ERRORS_HISTORY';
export const STATS_SET_SCENARIO_ID = 'STATS_SET_SCENARIO_ID';
export const STATS_SET_FILTER = 'STATS_SET_FILTER';
export const STATS_SET_HELP_CENTER_CHART_DATA = 'STATS_SET_HELP_CENTER_CHART_DATA';
export const STATS_SET_HELP_CENTER_CARDS_DATA = 'STATS_SET_HELP_CENTER_CARDS_DATA';
export const STATS_SET_HELP_CENTER_SCENARIO_VISIBILITY_CHART_DATA =
  'STATS_SET_HELP_CENTER_SCENARIO_VISIBILITY_CHART_DATA';
export const STATS_SET_HELP_CENTER_SCENARIO_VISIBILITY_CARDS_DATA =
  'STATS_SET_HELP_CENTER_SCENARIO_VISIBILITY_CARDS_DATA';

export const SET_ELEMENT_STYLES = 'SET_ELEMENT_STYLES';
export const SET_ACTIVE_ELEMENT_STYLE_ID = 'SET_ACTIVE_ELEMENT_STYLE_ID';

export const SET_ENABLED_CREATING_MODE = 'SET_ENABLED_CREATING_MODE';
export const UPDATE_VERSION_DATA = 'UPDATE_VERSION_DATA';
export const SET_VERSIONS_LIST = 'SET_VERSIONS_LIST';
export const SET_SELECTED_VERSION_ID = 'SET_SELECTED_VERSION_ID';
export const SET_VERSION_DATA = 'SET_VERSION_DATA';
export const SET_VERSION_TAGS = 'SET_VERSION_TAGS';
export const SET_TAGS_LIST = 'SET_TAGS_LIST';
export const SET_VERSIONS_ENTITY_TYPE = 'SET_VERSIONS_ENTITY_TYPE';
export const SET_VERSIONS_ENTITY = 'SET_VERSIONS_ENTITY';
export const CLEAR_VERSIONS_DATA = 'CLEAR_VERSIONS_DATA';
export const SET_BUSY_SELECTED_VERSION = 'SET_BUSY_SELECTED_VERSION';
export const SET_BUSY_VERSIONS_LIST = 'SET_BUSY_VERSIONS_LIST';
export const SET_BUSY_UPDATE_VERSION = 'SET_BUSY_UPDATE_VERSION';
export const SET_SYNCED_SIMULATION_ID = 'SET_SYNCED_SIMULATION_ID';
export const SET_SYNCED_SIMULATION_VERSIONS_LIST = 'SET_SYNCED_SIMULATION_VERSIONS_LIST';

export const SET_WORKSPACES_LIST = 'SET_WORKSPACES_LIST';

export const SET_ADMIN_PANEL_SETTINGS = 'SET_ADMIN_PANEL_SETTINGS';

export const SET_SHOWED_NOTIFICATIONS_PANEL = 'SET_SHOWED_NOTIFICATIONS_PANEL';
export const SET_SHOWED_NOTIFICATIONS_SETTINGS = 'SET_SHOWED_NOTIFICATIONS_SETTINGS';
export const SET_NOTIFICATIONS_LIST_TYPE = 'SET_NOTIFICATIONS_LIST_TYPE';
export const SET_NOTIFICATIONS_LIST = 'SET_NOTIFICATIONS_LIST';
export const SET_NOTIFICATIONS_SERVER_COUNT = 'SET_NOTIFICATIONS_SERVER_COUNT';
export const SET_NOTIFICATIONS_UNREAD_COUNT = 'SET_NOTIFICATIONS_UNREAD_COUNT';
