<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="isBusy"
          :headers="headers"
          :items="documents"
          :server-items-length="count"
          :options.sync="options"
          :page.sync="page"
        >
          <template #item.status="{ item }">
            <span :class="[getStatus(item.status).classlist]">
              {{ getStatus(item.status).text }}
            </span>
          </template>

          <template #item.action="{ item }">
            <v-btn small color="primary" @click="downloadInvoice(item.relativePath)">
              <v-icon left>
                mdi-cloud-download
              </v-icon>

              download
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row justify="end">
      <v-col cols="auto">
        <v-btn to="/legal-info">Edit company data</v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn color="primary" @click="requestInvoice">Request invoice</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex';
  import urlResolve from 'url-resolve';
  import { apiUrl } from 'src/config';

  export default {
    name: 'Payment',
    data() {
      return {
        isBusy: false,

        documents: [],
        count: 0,
        page: 1,
        options: {},

        headers: [
          {
            text: 'Document',
            value: 'title',
          },

          {
            text: 'Status',
            value: 'status',
          },

          {
            text: 'Action',
            value: 'action',
          },
        ],
      };
    },

    watch: {
      options: {
        handler(value) {
          this.updateList(value);
        },

        deep: true,
      },
    },

    mounted() {
      this.updateList();
    },

    methods: {
      ...mapActions('legal', ['getInvoice', 'getDocuments']),

      async requestInvoice() {
        if (this.isBusy) {
          return;
        }

        this.isBusy = true;

        try {
          const { relativePath } = await this.getInvoice();
          this.downloadInvoice(relativePath);
        } catch (err) {
          console.error('err >>>>', err);
        } finally {
          this.isBusy = false;
        }
      },

      downloadInvoice(url) {
        const element = document.createElement('a');
        element.setAttribute('href', urlResolve(apiUrl, url));
        element.setAttribute('download', true);
        element.setAttribute('target', '_blank');
        element.click();
      },

      async updateList(obj = {}) {
        const { itemsPerPage, page } = obj;

        const limit = itemsPerPage === -1 ? 'all' : itemsPerPage;

        const { payload, count } = await this.getDocuments({ limit, page });
        this.documents = payload;
        this.count = count;
      },

      getStatusColor(status) {
        const currentStatus = status.toLowerCase();

        switch (currentStatus) {
          case 'paid':
            return 'green--text';
          case 'pending':
            return 'amber--text';
          case 'rejected':
          default:
            return 'red--text';
        }
      },

      getStatus(status) {
        return {
          classlist: this.getStatusColor(status),
          text: status.toLowerCase(),
        };
      },
    },
  };
</script>
