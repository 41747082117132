<template>
  <v-card v-if="chartData.length" class="mb-5">
    <v-card-text class="pa-5">
      <div class="mt-2">
        <vue-apex-charts
          class="chart"
          height="390"
          :series="preparedData"
          :options="chartOptions"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';

  export default {
    name: 'TooltipPlayingChart',

    components: {
      VueApexCharts,
    },

    props: {
      chartData: {
        type: Array,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        chartOptions: Object.freeze({
          chart: {
            height: 370,
            type: 'bar',
            stacked: true,
            fontFamily: 'Montserrat,sans-serif',
            toolbar: {
              show: false,
            },
          },

          dataLabels: {
            enabled: false,
          },

          markers: {
            size: 3,
            strokeColors: 'transparent',
          },

          stroke: {
            curve: 'smooth',
            width: '2',
          },

          colors: ['#D81159', '#2F80ED'],

          legend: {
            show: false,
          },

          grid: {
            show: true,
            strokeDashArray: 0,
            borderColor: 'rgba(0,0,0,0.1)',
            yaxis: {
              show: false,
            },
          },

          yaxis: {
            labels: {
              formatter: value => {
                return String(value);
              },

              style: {
                colors: '#a1aab2',
              },
            },
          },

          tooltip: {
            theme: 'dark',
            fillColors: ['#D81159', '#2F80ED'],

            x: {
              formatter: val => `${this.$t(`pages.statistics.charts.tooltipNumber`)}: ${val}`,
            },

            y: {
              formatter: (val, { series, seriesIndex, dataPointIndex }) => {
                const currentSeries = series[seriesIndex];

                if (seriesIndex === 1) {
                  return currentSeries[dataPointIndex] + series[0][dataPointIndex];
                }

                return val;
              },

              title: {
                formatter: type => `${this.$t(`pages.statistics.charts.${type}`)}:`,
              },
            },
          },

          plotOptions: {
            bar: {
              columnWidth: 5,
            },
          },
        }),
      };
    },

    computed: {
      preparedData() {
        const [unique, oldTotal] = this.chartData;

        const total = {
          name: 'total',
          data: oldTotal.data.map((item, index) => {
            const newY = item.y - unique.data[index].y;

            return { ...item, y: newY };
          }),
        };

        return [unique, total].map(item => ({
          ...item,
          data: item.data.map(dataItem => ({ ...dataItem, x: `${dataItem.x}` })),
        }));
      },
    },
  };
</script>

<style type="scss">
  .chart {
    position: relative;
    width: 100%;
    height: 350px;
  }
</style>
