<template>
  <v-app-bar app clipped-left clipped-right color="white">
    <v-toolbar-title class="align-center d-flex logo-width">
      <span class="logo-text ml-2">
        <img :src="$options.LOGO_URL" :alt="$options.COMPANY_NAME" class="mt-2 logo" />
      </span>

      <v-chip v-if="isDemo" color="primary" label class="ml-4">DEMO</v-chip>
    </v-toolbar-title>

    <v-spacer />

    <v-btn
      v-if="!isSmartManualsPlansActive && !isAnalyst"
      icon
      dark
      class="mr-4"
      @click="setShowedNotificationsPanel(true)"
    >
      <v-badge color="primary" :content="unreadCount" :value="unreadCount" overlap>
        <v-icon size="26">mdi-bell-outline</v-icon>
      </v-badge>
    </v-btn>

    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template #activator="{ on }">
        <v-btn dark icon class="mr-1" v-on="on">
          <v-avatar size="40">
            <v-icon x-large>mdi-account-circle</v-icon>
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-if="!readOnly && isPersonalDataCardEnabled">
          <v-list-item-avatar>
            <v-icon large>mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-if="isFieldAllowed('username')" class="text-capitalize">
              {{ currentUser.username }}
            </v-list-item-title>

            <v-list-item-subtitle v-if="isFieldAllowed('email')">
              {{ currentUser.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <template v-if="!readOnly">
          <v-divider />

          <v-list-item :to="{ name: 'Profile' }" color="primary">
            <v-list-item-icon>
              <v-icon>mdi-account-supervisor-circle</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ $t('ui.navbar.profile') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="showedBillingLink" :to="{ name: 'Billing' }" color="primary">
            <v-list-item-icon>
              <v-icon>mdi-currency-usd</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ $t('ui.navbar.billing') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="!isAnalyst" :to="{ name: 'Install' }" color="primary">
            <v-list-item-icon>
              <v-icon>mdi-console-line</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ $t('ui.navbar.install') }}</v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item color="primary" @click="exit()">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>

          <v-list-item-title>{{ $t('ui.navbar.logout') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
  import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
  import { SET_SHOWED_NOTIFICATIONS_PANEL } from 'src/store/mutation-types';

  import eventsService from 'src/services/events';
  import { APP_BUILD_SPEC_INTERNATIONAL, EVENT_TYPE_READ } from 'src/constants';
  import { buildSpec, buildSpecConfig } from 'src/config';

  export default {
    name: 'VerticalHeader',

    props: {
      readOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    LOGO_URL: buildSpecConfig.logoUrl,
    COMPANY_NAME: buildSpecConfig.companyName,
    BUILD_SPEC: buildSpec,

    computed: {
      ...mapState('users', ['currentUser']),
      ...mapState('settings', ['allowedUserInfoFields', 'isDemo']),
      ...mapGetters('users', [
        'isSuperAdmin',
        'isRealAdminOrHigher',
        'isSmartManualsPlansActive',
        'isAnalyst',
      ]),
      ...mapState('notifications', ['unreadCount']),

      showedBillingLink() {
        return (
          buildSpec === APP_BUILD_SPEC_INTERNATIONAL &&
          this.isRealAdminOrHigher &&
          this.isSmartManualsPlansActive
        );
      },
    },

    async mounted() {
      await this.getUnreadCount();

      eventsService.on(EVENT_TYPE_READ, this.readEventAccept);
    },

    beforeDestroy() {
      eventsService.off(EVENT_TYPE_READ, this.readEventAccept);
    },

    methods: {
      ...mapActions('auth', ['logout']),
      ...mapActions('notifications', ['getUnreadCount', 'readNotification']),
      ...mapMutations('notifications', {
        setShowedNotificationsPanel: SET_SHOWED_NOTIFICATIONS_PANEL,
      }),

      async exit() {
        await this.logout();

        this.$router.push({ name: 'login' });
      },

      isFieldAllowed(fieldName) {
        if (
          this.allowedUserInfoFields === 'all' ||
          this.isSuperAdmin ||
          !this.allowedUserInfoFields
        ) {
          return true;
        }

        return this.allowedUserInfoFields.includes(fieldName);
      },

      isPersonalDataCardEnabled() {
        const fields = ['username', 'email'];
        return (
          this.allowedUserInfoFields === 'all' ||
          this.isSuperAdmin ||
          this.allowedUserInfoFields.some(r => fields.includes(r))
        );
      },

      readEventAccept({ data }) {
        this.readNotification(data.eventId);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .v-application .theme--dark.white .theme--dark.v-btn.v-btn--icon {
    color: $font-color !important;
  }
  .v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined),
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: $box-shadow;
  }
  .v-btn--icon.v-size--default .v-icon {
    width: 20px;
    font-size: 20px;
  }
  .logo {
    width: 100px;
  }
</style>
