<template>
  <v-list flat dense class="creator-filter">
    <div class="px-4 py-3 firstItem">
      <button class="font-select__button" @click="$emit('back')">
        {{ $t('pages.scenarios.filters.back') }}
      </button>

      <button class="font-select__button" @click="resetFilters()">
        {{ $t('pages.scenarios.filters.reset') }}
      </button>
    </div>

    <div class="px-5 py-3">
      <v-text-field
        v-model="debouncedPattern"
        dense
        hide-details
        append-icon="mdi-magnify"
        :label="$t('pages.scenarios.filters.search')"
      />
    </div>

    <v-list-item-group
      :value="selectedUsers"
      multiple
      class="creator-filter__list"
      @change="$emit('updated', $event)"
    >
      <v-list-item v-for="user in users" :key="user.id" :value="user.email">
        <template #default="{ active }">
          <v-list-item-action>
            <v-checkbox :input-value="active" color="primary" />
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ user.email }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
  import api from 'src/api';
  import debounce from 'lodash.debounce';

  export default {
    name: 'CreatorFilter',

    props: {
      currentFilter: {
        type: Array,
        default: null,
      },
    },

    data() {
      return {
        users: null,
        selectedUsers: [],
        pattern: null,
      };
    },

    computed: {
      debouncedPattern: {
        get() {
          return this.pattern;
        },

        set(value) {
          this.debouncedSetPattern(value);
        },
      },
    },

    watch: {
      pattern() {
        this.getUsersList();
      },

      currentFilter: {
        handler(value) {
          this.selectedUsers = value || [];
        },
        immediate: true,
      },
    },

    mounted() {
      this.getUsersList();
    },

    methods: {
      async getUsersList() {
        const { response } = await api.users.list({
          params: {
            page: 1,
            limit: 5,
            ...(this.pattern ? { pattern: this.pattern } : {}),
          },
        });

        this.users = response.users;
      },

      debouncedSetPattern: debounce(function setPattern(pattern) {
        this.pattern = pattern;
      }, 300),

      resetFilters() {
        this.$emit('updated', []);
      },
    },
  };
</script>

<style lang="scss">
  .creator-filter {
    &__list {
      max-height: 290px;
      overflow: auto;
    }
  }
</style>
