<template>
  <v-menu>
    <template #activator="{ props, on }">
      <v-btn color="primary" dark class="text-none" v-bind="props" v-on="on">
        <v-icon v-if="hasIcon" left dark class="mr-1">
          mdi-plus
        </v-icon>

        {{ $t('pages.simulations.create') }}
      </v-btn>
    </template>

    <v-list>
      <template v-for="item in items">
        <v-divider v-if="item.type === 'divider'" :key="item.value" />

        <template v-else>
          <v-list-item
            v-if="!isPayedItem(item)"
            :key="item.value"
            :value="item.value"
            @click="clickHandler(item)"
          >
            <v-list-item-icon class="mr-2">
              <feather :type="item.icon" />
            </v-list-item-icon>

            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>

          <plan-upgrade-menu
            v-if="isPayedItem(item)"
            :key="item.value"
            offset-x
            right
            nudge-right="20"
          >
            <template #activator="{on, attrs}">
              <v-list-item v-bind="attrs" class="disabled-item" v-on="on">
                <v-list-item-icon class="disabled-item__icon mr-2">
                  <feather :type="item.icon" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="disabled-item__title">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                  <plan-upgrade-chip />
                </v-list-item-icon>
              </v-list-item>
            </template>
          </plan-upgrade-menu>
        </template>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
  import { mapGetters } from 'vuex';

  import PlanUpgradeMenu from 'src/components/PlanUpgradeMenu/PlanUpgradeMenu.vue';
  import PlanUpgradeChip from 'src/components/PlanUpgradeChip/PlanUpgradeChip.vue';

  export default {
    name: 'CreateSimulationButton',
    components: { PlanUpgradeChip, PlanUpgradeMenu },

    props: {
      hasIcon: { type: Boolean, default: false },
      createFromScratch: { type: Function, default: () => {} },
      createFromExtension: { type: Function, default: () => {} },
      isPlayerLoaded: { type: Boolean, default: false },
    },

    computed: {
      ...mapGetters('users', ['isSmartManualsStartPlanActive']),

      items() {
        return [
          {
            title: this.$t('pages.simulations.createInBrowser'),
            value: 'browser',
            icon: 'chrome',
          },

          {
            title: this.$t('pages.simulations.createInDesktop'),
            value: 'desktop',
            icon: 'monitor',
          },

          {
            type: 'divider',
          },

          {
            title: this.$t('pages.simulations.createFromScratch'),
            value: 'fromScratch',
            icon: 'file-text',
          },
        ];
      },
    },

    methods: {
      clickHandler(item) {
        if (!this.isPlayerLoaded && item.value !== 'desktop') {
          return this.$router.push({ name: 'Simulations', query: { createFrom: item.value } });
        }

        switch (item.value) {
          case 'fromScratch':
            return this.createFromScratch();
          case 'browser':
            return this.createFromExtension();
          case 'desktop':
            return this.createFromDesktop();
          default:
            return false;
        }
      },

      isPayedItem(item) {
        if (!this.isSmartManualsStartPlanActive) {
          return false;
        }

        return item.value === 'desktop';
      },

      async createFromDesktop() {
        const routeData = this.$router.resolve({ name: 'CreateDesktopSimulation' });
        window.open(routeData.href, '_blank');
      },
    },
  };
</script>

<style lang="scss">
  .disabled-item {
    opacity: 1 !important;
    &:hover {
      opacity: 1 !important;
    }

    &__icon,
    &__title {
      opacity: 0.3 !important;
    }
  }
</style>
