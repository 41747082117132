<template>
  <v-container>
    <v-row>
      <template v-for="(value, name) in stats">
        <v-col v-if="cards[name]" :key="name" cols="auto" class="py-0 flex-grow-1">
          <v-card>
            <v-card-text class="pa-5">
              <div class="d-flex align-center">
                <span class="lstick" :class="`lstick--${cards[name].color}`" />
                <v-icon large :color="cards[name].color" class="img-fluid">
                  {{ cards[name].icon }}
                </v-icon>

                <div class="ml-4 mr-1">
                  <h5 class="subtitle-1 grey--text">
                    {{ cards[name].title }}
                  </h5>

                  <h2 class="font-weight-regular " :class="`${cards[name].color}--text`">
                    {{ value }}
                  </h2>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'SimulationStatsCards',

    props: {
      stats: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },

    data() {
      return {
        cards: Object.freeze({
          started: {
            title: this.$t('pages.statistics.cards.played'),
            icon: 'mdi-play',
            color: 'green',
          },

          unfinished: {
            title: this.$t('pages.statistics.cards.notFinished'),
            icon: 'mdi-stop',
            color: 'red',
          },

          completed: {
            title: this.$t('pages.statistics.cards.completed'),
            icon: 'mdi-check',
            color: 'info',
          },
        }),
      };
    },
  };
</script>
