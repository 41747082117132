<template>
  <v-container class="fill-height position-relative">
    <div class="fill-height desktop-simulation">
      <v-row class="flex-shrink-1 flex-grow-0">
        <v-col>
          <router-link to="Simulations">
            <img
              class="desktop-simulation__logo"
              :src="$options.LOGO_URL"
              :alt="$options.COMPANY_NAME"
            />
          </router-link>
        </v-col>
      </v-row>

      <div class="desktop-simulation__wrapper">
        <div class="desktop-simulation__body">
          <v-row class="d-flex" justify="center">
            <v-col cols="auto">
              <v-row justify="center">
                <!-- eslint-disable vue/no-v-html -->
                <v-col
                  cols="12"
                  class="text-center"
                  v-html="$t('pages.simulations.createFromDesktop.firstDescription')"
                />

                <v-col
                  cols="12"
                  class="text-center"
                  v-html="$t('pages.simulations.createFromDesktop.secondDescription')"
                />
                <!-- eslint-enable vue/no-v-html -->
              </v-row>

              <v-row justify="center">
                <v-col cols="auto">
                  <v-btn color="primary" dark class="text-none" @click="handleRecord">
                    {{ $t('pages.simulations.createFromDesktop.startSimulationRecord') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="auto">
              <div class="desktop-simulation__separator" />
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="auto">
              {{ $t('pages.simulations.createFromDesktop.notInstalledMessage') }}&nbsp;<a
                :href="appUrl"
                target="_blank"
                >{{ $t('pages.simulations.createFromDesktop.installNow') }}</a
              >
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
  import api from 'src/api';
  import { paramsSerializer } from 'src/utils';
  import { apiUrl, buildSpecConfig } from 'src/config';

  export default {
    name: 'CreateDesktopSimulation',

    LOGO_URL: buildSpecConfig.logoUrl,
    COMPANY_NAME: buildSpecConfig.companyName,

    data() {
      return {
        transitionToken: null,
      };
    },

    computed: {
      appUrl() {
        const { origin } = window.location;
        return `${origin}/plugins/desktop/hinted.exe`;
      },
    },

    mounted() {
      this.handleRecord();
    },

    methods: {
      async getTransitionToken() {
        if (this.transitionToken) {
          return;
        }

        const {
          response: { token },
        } = await api.auth.signTransitionToken();

        this.transitionToken = token;
      },

      openRecordLink() {
        if (!this.transitionToken) {
          return;
        }

        const query = paramsSerializer({
          Action: 'StartSimulationRecord',
          TransitionToken: this.transitionToken,
          Server: apiUrl,
        });

        const protocol = 'HintedAssistant:';
        const host = 'desktop.hinted';
        const href = `${protocol}//${host}?${query}`;

        window.open(href, '_self');
      },

      async handleRecord() {
        await this.getTransitionToken();
        this.openRecordLink();
      },
    },
  };
</script>

<style lang="scss">
  .desktop-simulation {
    display: flex;
    position: relative;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    width: 100%;

    &__wrapper {
      display: flex;
      flex-grow: 1;
      padding: 15px;
      align-content: center;
    }

    &__logo {
      max-width: 100px;
    }

    &__body {
      flex-grow: 1;
      align-self: center;
    }

    &__separator {
      margin: 40px 0 40px;
      width: 255px;
      height: 1px;
      background: #dae4e8;
    }
  }
</style>
