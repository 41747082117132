<template>
  <div>
    <div>
      <div class="block-header block-header--first">
        {{ $t('pages.styles.settings.themeColors') }}
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="borderColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.secondaryColor') }}</div>
      </div>

      <div class="input-control">
        <div>{{ $t('pages.styles.settings.borderWidth') }}</div>

        <h-slider v-model="borderLineWidth" max="30" :min="borderWidthMin" hide-details>
          <template #append>
            <v-text-field
              v-model="borderLineWidth"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>
    </div>
  </div>
</template>

<script>
  import InlineColorPicker from 'src/components/InlineColorPicker.vue';
  import HSlider from 'src/HSlider/HSlider.vue';

  import { generateDynamicFields } from '../../utils';
  import stylesMixin from '../../mixins/stylesMixin';

  export default {
    name: 'HighlightGeneralStyles',

    components: {
      InlineColorPicker,
      HSlider,
    },

    mixins: [stylesMixin],

    computed: {
      ...generateDynamicFields(['borderColor', 'borderLineWidth']),

      borderWidthMin() {
        return typeof this.borderLineWidth === 'number' && this.borderLineWidth < 2
          ? this.borderLineWidth
          : 2;
      },
    },
  };
</script>

<style lang="scss"></style>
