<template>
  <v-card>
    <v-container>
      <template v-if="isCloud">
        <v-card-text>
          <p class="text--darken-1 body-2 font-weight-bold">
            {{ $t('pages.install.browserExtension.cloud.stepOne') }}
          </p>
          <p>
            <a
              :href="chromeStoreExtensionUrl"
              class="blue--text text-decoration-underline"
              target="_blank"
            >
              {{ $t('pages.install.browserExtension.cloud.downloadText') }}
            </a>
          </p>

          <p class="text--darken-1 body-2 font-weight-bold">
            {{ $t('pages.install.browserExtension.cloud.stepTwo') }}
          </p>
          <p>
            {{ $t('pages.install.browserExtension.cloud.stepTwoDescOne') }}
            <br />
            {{ $t('pages.install.browserExtension.cloud.stepTwoDescTwo') }}
          </p>
          <p>
            <v-img :src="chromeStoreImage" max-width="678" max-height="129"></v-img>
          </p>

          <p class="text--darken-1 body-2 font-weight-bold">
            {{ $t('pages.install.browserExtension.cloud.stepThree') }}
          </p>
          <p>
            {{
              $t('pages.install.browserExtension.cloud.stepThreeDescOne', {
                extensionName,
              })
            }}
            <br />
            {{ $t('pages.install.browserExtension.cloud.stepThreeDescTwo') }}
          </p>
          <v-img :src="installedExtensionImage" max-width="212" max-height="98"></v-img>
        </v-card-text>
      </template>

      <template v-else>
        <v-card-text>
          <p class="text--darken-1 body-2 font-weight-bold">
            {{ $t('pages.install.browserExtension.contur.stepOne') }}
          </p>
          <p>
            <a
              :href="`${apiLink}/plugins/hinted-pluginv2.zip`"
              class="blue--text text-decoration-underline"
              target="_blank"
            >
              {{ $t('pages.install.browserExtension.contur.downloadText') }}
            </a>
          </p>

          <p class="text--darken-1 body-2 font-weight-bold">
            {{ $t('pages.install.browserExtension.contur.stepTwo') }}
          </p>
          <p>
            {{ $t('pages.install.browserExtension.contur.stepTwoDescOne') }}
            <a
              href="chrome://extensions/"
              class="blue--text text-decoration-underline"
              target="_blank"
            >
              chrome://extensions/
            </a>
            {{ $t('pages.install.browserExtension.contur.stepTwoDescTwo') }}
            <br />
            {{ $t('pages.install.browserExtension.contur.stepTwoDescThree') }}
          </p>

          <p class="text--darken-1 body-2 font-weight-bold">
            {{ $t('pages.install.browserExtension.contur.stepThree') }}
          </p>
          <p>
            {{
              $t('pages.install.browserExtension.contur.stepThreeDescOne', {
                extensionName,
              })
            }}
            <br />
            {{ $t('pages.install.browserExtension.contur.stepThreeDescTwo') }}
          </p>
          <v-img :src="installedExtensionImage" max-width="212" max-height="98"></v-img>
        </v-card-text>
      </template>
    </v-container>
  </v-card>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';

  import downloadExtensionRu from 'src/assets/downloadExtension-ru.jpg';
  import downloadExtensionEn from 'src/assets/downloadExtension-en.jpg';
  import downloadExtensionEs from 'src/assets/downloadExtension-es.jpg';

  import downloadSmartManualsExtensionEn from 'src/assets/downloadSmartManualsExtension-en.jpg';
  import downloadSmartManualsExtensionEs from 'src/assets/downloadSmartManualsExtension-es.png';
  import downloadSmartManualsExtensionPt from 'src/assets/downloadSmartManualsExtension-pt.png';
  import downloadSmartManualsExtensionRu from 'src/assets/downloadSmartManualsExtension-ru.png';

  import smartManualsInstalledExtensionImage from 'src/assets/smartManualsInstalledExtension.png';

  import { buildSpecConfig } from 'src/config';
  import { LOCALE_EN, LOCALE_ES, LOCALE_PT, APP_BUILD_SPEC_RU } from 'src/constants';

  export default {
    name: 'BrowserExtension',

    data() {
      return {
        apiLink: window.location.origin,
      };
    },

    COMPANY_NAME: buildSpecConfig.companyName,

    computed: {
      ...mapState('settings', ['isCloud']),
      ...mapGetters('users', ['isSmartManualsPlansActive']),

      chromeStoreExtensionUrl() {
        if (this.isSmartManualsPlansActive) {
          return 'https://chrome.google.com/webstore/detail/hinted-smart-manuals-edit/fndkgbgkmmoblbhlbdkacjcbngdlokpi';
        }

        return buildSpecConfig.editorExtensionUrl;
      },
      companyName() {
        return buildSpecConfig.companyName;
      },
      extensionName() {
        if (this.isSmartManualsPlansActive) {
          return 'HintEd Smart Manuals Editor';
        }

        return buildSpecConfig.companyName;
      },

      installedExtensionImage() {
        if (this.isSmartManualsPlansActive) {
          return smartManualsInstalledExtensionImage;
        }
        return buildSpecConfig.installedExtensionImage;
      },

      chromeStoreImage() {
        const smartManualExtensionImages = {
          [LOCALE_EN]: downloadSmartManualsExtensionEn,
          [LOCALE_ES]: downloadSmartManualsExtensionEs,
          [LOCALE_PT]: downloadSmartManualsExtensionPt,
        };

        const extensionImages = {
          [LOCALE_EN]: downloadExtensionEn,
          [LOCALE_ES]: downloadExtensionEs,
        };

        if (buildSpecConfig.localeType === APP_BUILD_SPEC_RU) {
          if (this.isSmartManualsPlansActive) {
            return downloadSmartManualsExtensionRu;
          }

          return downloadExtensionRu;
        }

        if (this.isSmartManualsPlansActive) {
          return smartManualExtensionImages[this.$i18n.locale] || downloadSmartManualsExtensionEn;
        }

        return extensionImages[this.$i18n.locale] || downloadExtensionEn;
      },
    },
  };
</script>
