<template>
  <div>
    <div>
      <font-select v-model="fontFamily" />

      <div class="block-header">
        {{ $t('pages.styles.settings.themeColors') }}
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="activeColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.primaryColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="backgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.backgroundColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="color" />
        <div class="ml-4">{{ $t('pages.styles.settings.fontColor') }}</div>
      </div>
    </div>

    <div class="mt-6">
      <div class="input-control mb-4">
        <div>{{ $t('pages.styles.settings.allSidePadding') }}</div>

        <h-slider v-model="padding" max="30" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="paddingLabel"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div v-if="themeType !== $options.THEME_TYPE_CLASSIC" class="input-control">
        <div>{{ $t('pages.styles.settings.borderRadius') }}</div>

        <h-slider v-model="borderRadius" max="10" min="0" hide-details>
          <template #append>
            <v-text-field
              v-model="borderRadius"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>
    </div>
  </div>
</template>

<script>
  import InlineColorPicker from 'src/components/InlineColorPicker.vue';
  import HSlider from 'src/HSlider/HSlider.vue';

  import { generateDynamicFields, MIX_VALUE, THEME_TYPE_CLASSIC } from '../../utils';

  import stylesMixin from '../../mixins/stylesMixin';
  import FontSelect from '../FontSelect.vue';

  export default {
    name: 'RatingGeneralStyles',

    components: {
      InlineColorPicker,
      HSlider,
      FontSelect,
    },

    mixins: [stylesMixin],

    THEME_TYPE_CLASSIC,

    computed: {
      ...generateDynamicFields([
        'activeColor',
        'backgroundColor',
        'borderRadius',
        'color',
        'fontFamily',
      ]),

      paddingLabel() {
        const { paddingLeft, paddingRight, paddingTop, paddingBottom } = this.value;

        if (
          paddingLeft !== paddingRight ||
          paddingLeft !== paddingTop ||
          paddingLeft !== paddingBottom
        ) {
          return MIX_VALUE;
        }

        return paddingLeft;
      },

      padding: {
        get() {
          return this.paddingLabel === MIX_VALUE ? 0 : this.paddingLabel;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingLeft: value,
            paddingRight: value,
            paddingTop: value,
            paddingBottom: value,
          });
        },
      },
    },
  };
</script>

<style lang="scss"></style>
