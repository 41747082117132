<template>
  <div>
    <v-container fluid class-name="down-top-padding">
      <v-row class-name="pb-0">
        <v-col cols="12">
          <statistic-filter :organization-id="currentOrganizationId" />
        </v-col>

        <v-col cols="12">
          <stat-cards :stats="scenarioCardsData" />
        </v-col>

        <v-col cols="12">
          <charts />
        </v-col>

        <v-col v-if="!scenarioId" cols="12">
          <scenario-steps-statistics-table />
        </v-col>

        <v-col cols="12">
          <scenario-steps-statistics-chart />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import moment from 'moment';
  import { mapGetters, mapActions, mapState } from 'vuex';

  import StatCards from 'src/components/Statistics/StatCards.vue';
  import Charts from 'src/components/Statistics/Charts.vue';
  import StatisticFilter from 'src/components/Statistics/StatisticFilter.vue';
  import ScenarioStepsStatisticsTable from 'src/components/Statistics/ScenarioStepsStatisticsTable.vue';
  import ScenarioStepsStatisticsChart from 'src/components/Statistics/ScenarioStepsStatisticsChart.vue';

  export default {
    name: 'ScenariosStats',

    components: {
      StatisticFilter,
      StatCards,
      Charts,
      ScenarioStepsStatisticsTable,
      ScenarioStepsStatisticsChart,
    },

    data() {
      return {
        organizationId: null,
      };
    },

    computed: {
      ...mapGetters('users', ['isSuperAdmin']),
      ...mapState('users', ['currentUser']),
      ...mapState('organizations', ['organizationsList']),
      ...mapState('stats', ['scenarioCardsData']),
      ...mapState('stats', ['scenarioId']),

      currentOrganizationId() {
        return this.isSuperAdmin && this.organizationId
          ? this.organizationId
          : this.currentUser.organizationId;
      },
    },

    created() {
      this.clearList();
    },

    async mounted() {
      this.endDate = moment().format('YYYY-MM-DD');
      this.maxDate = moment().format('YYYY-MM-DD');
      this.startDate = moment()
        .subtract(30, 'd')
        .format('YYYY-MM-DD');

      if (this.isSuperAdmin) {
        this.organizationId = this.currentUser.organizationId;

        this.getOrganizationsList();
      }
    },

    methods: {
      ...mapActions('scenarios', ['clearList']),
      ...mapActions('organizations', { getOrganizationsList: 'list' }),
    },
  };
</script>
