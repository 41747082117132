import api from 'src/api';
import { deepcopy } from 'src/utils';
import { SET_TOOLTIPS_LIST, SET_TOOLTIPS_COUNT } from 'src/store/mutation-types';

export default {
  namespaced: true,

  state: {
    tooltipsList: [],
    tooltipsCount: 0,
  },

  actions: {
    async checkAccess() {
      await api.tooltips.list({ params: { limit: 1 } });
    },

    async getList(
      { commit },
      {
        page = 1,
        limit = 10,
        filterBy,
        filterDirection,
        pattern,
        organizationId,
        groupId,
        trashedItems,
        creatorsEmails,
        tags,
        publishStatus,
        creationDatesRange,
      } = {}
    ) {
      const params = deepcopy({
        page,
        limit,
        filter_by: filterBy,
        filter_direction: filterDirection,
        organizationId,
        group_id: groupId,
        trashedItems,
        ...(creatorsEmails ? { creatorsEmails } : {}),
        ...(tags ? { tags } : {}),
        ...(typeof publishStatus === 'boolean' ? { publishStatus } : {}),
        ...(creationDatesRange ? { ...creationDatesRange } : {}),
      });

      if (pattern) {
        params.pattern = pattern;
      }

      const { response } = await api.tooltips.list({ params });

      commit(SET_TOOLTIPS_LIST, response.payload);
      commit(SET_TOOLTIPS_COUNT, response.count);
    },

    clearList({ commit }) {
      commit(SET_TOOLTIPS_LIST, []);
      commit(SET_TOOLTIPS_COUNT, 0);
    },

    async removeTooltip(context, id) {
      await api.tooltips.remove({ url_params: { id } });
    },

    async restoreTooltip(context, id) {
      await api.tooltips.restore({ url_params: { id } });
    },

    async getListByIds(context, ids) {
      const { response } = await api.tooltips.getListByIds({ data: { list: ids } });

      return response;
    },

    async createManyTooltips(context, data) {
      const { response } = await api.tooltips.createMany({ data });

      return response;
    },
  },

  mutations: {
    [SET_TOOLTIPS_LIST](state, list) {
      state.tooltipsList = list;
    },

    [SET_TOOLTIPS_COUNT](state, count) {
      state.tooltipsCount = count;
    },
  },
};
