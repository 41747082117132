<template>
  <v-dialog
    v-if="tempEntity"
    ref="dialog"
    v-model="dialog"
    max-width="1040"
    class="entity-edit-modal"
  >
    <v-card>
      <v-card-title class="pa-4 primary flex-nowrap">
        <span v-if="creatingModeEnabled" class="title white--text">
          {{ $t(`${localeRootPath}.editModal.createTitle`) }} {{ selectedVersion.version }}
        </span>

        <template v-else>
          <span class="title white--text text-truncate">
            {{ $t(`${localeRootPath}.editModal.title`) }} {{ tempEntity.title }}
          </span>

          <v-tooltip
            bottom
            max-width="500"
            content-class="white elevation-1 grey--text text--darken-1 entity-edit-modal__tooltip"
          >
            <template #activator="{ on, attrs }">
              <v-icon class="ml-3 white--text" small v-bind="attrs" v-on="on">
                mdi-message-outline
              </v-icon>
            </template>

            <div class="font-weight-medium mb-1">
              {{ $t('pages.scenarios.editModal.tooltip.title') }}
            </div>
            <div>ID: {{ tempEntity.id }}</div>
            <div class="text-truncate">
              {{ $t('pages.scenarios.editModal.tooltip.origin') }}: {{ tempEntity.origin }}
            </div>
            <div>
              {{ $t('pages.scenarios.editModal.tooltip.creator') }}: {{ tempEntity.creatorId }}
            </div>
            <div>
              {{ $t('pages.scenarios.editModal.tooltip.organization') }}:
              {{ tempEntity.organizationId }}
            </div>
            <div>{{ $t('common.fields.created') }}: {{ formatDateTime(tempEntity.createdAt) }}</div>
            <div>{{ $t('common.fields.updated') }}: {{ formatDateTime(tempEntity.updatedAt) }}</div>
          </v-tooltip>
        </template>

        <v-spacer />

        <v-btn icon color="white" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                ref="titleInput"
                v-model="tempEntity.title"
                :label="$t('pages.scenarios.editModal.titleLabel')"
                outlined
                hide-details
                maxlength="200"
              />
            </v-col>

            <v-col cols="12">
              <origin-list-edit v-model="originList" />
            </v-col>

            <v-col v-if="isModeratorOrHigher" cols="12" class="py-0">
              <v-switch
                v-model="tempEntity.published"
                :label="$t(`${localeRootPath}.editModal.publishLabel`)"
                hide-details
              />
            </v-col>

            <v-col v-if="!tooltipsMode && isEnterprisePlanActive" cols="12" class="py-0">
              <v-switch
                v-model="tempEntity.hasRate"
                :label="$t(`${localeRootPath}.editModal.hasRateLabel`)"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-list>
                <v-list-item-group v-model="selectedStepId" mandatory color="primary">
                  <draggable
                    v-model="tempEntity.steps"
                    handle="[data-drag-step]"
                    animation="200"
                    @change="changeOrderOfSyncedSimulationSteps($event)"
                  >
                    <v-list-item
                      v-for="(item, index) in tempEntity.steps"
                      :key="item.id"
                      :value="item.id"
                    >
                      <v-list-item-icon class="mr-2">
                        <v-icon data-drag-step class="entity-edit-modal__drag-handler">
                          mdi-drag
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon v-if="!tooltipsMode" small class="mr-2">
                            {{ item.stepType === 'modal' ? 'mdi-dock-top' : 'mdi-message-outline' }}
                          </v-icon>

                          <span :class="{ 'text--disabled': !item.published }">
                            <span v-if="!tooltipsMode"
                              >{{ $t('pages.scenarios.editModal.step') }} {{ index + 1 }}:</span
                            >
                            {{ getFirstInnerText(item.content) }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-icon small @click="deleteStep(index)">mdi-delete</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </draggable>
                </v-list-item-group>
              </v-list>
            </v-col>

            <v-col v-if="selectedStep" cols="6">
              <div class="text-h6 mb-6">
                <span v-if="!tooltipsMode">{{ $t('pages.scenarios.editModal.step') }}</span>
                {{ getFirstInnerText(selectedStep.content) }}
              </div>

              <v-text-field
                v-if="selectedStep.contentTitle"
                v-model="selectedStep.contentTitle"
                :label="$t('pages.scenarios.editModal.contentTitle')"
                outlined
                hide-details
                class="my-4"
              />

              <text-editor
                v-if="showedTextEditor"
                :html.sync="selectedStep.content"
                :json.sync="selectedStep.contentJson"
              />

              <v-switch
                v-model="selectedStep.published"
                :label="$t(`${localeRootPath}.editModal.stepPublishedTitle`)"
                hide-details
              />

              <v-switch
                v-if="!tooltipsMode && selectedStepIndex !== 0 && !selectedStep.onlyHighlight"
                v-model="showedPrevButton"
                :label="$t('pages.scenarios.editModal.prevButtonTitle')"
                hide-details
              />

              <v-select
                v-if="!tooltipsMode && !stepIsModal"
                v-model="closeConditionList"
                :items="filteredCloseConditionOptions"
                :label="$t('pages.scenarios.editModal.closeCondition.label')"
                outlined
                multiple
                hide-details
                item-value="value"
                item-text="text"
                :item-disabled="closeConditionIsDisabled"
                class="mt-4"
              >
                <template #selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>

                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ closeConditionList.length - 1 }})
                  </span>
                </template>
              </v-select>

              <template v-if="tooltipsMode">
                <v-select
                  v-model="selectedStep.contentPosition"
                  :items="contentPositionOptions"
                  :label="$t('pages.scenarios.editModal.position.label')"
                  outlined
                  hide-details
                  class="mt-4"
                />

                <v-select
                  v-if="tooltipsMode"
                  v-model="selectedStep.iconPosition"
                  :items="tooltipIconPositionOptions"
                  :label="$t('pages.tooltips.editModal.iconPosition.label')"
                  outlined
                  hide-details
                  class="mt-4"
                />
              </template>

              <template v-if="!tooltipsMode">
                <v-text-field
                  v-model="selectedStep.uri"
                  :label="$t('pages.scenarios.editModal.originLabel')"
                  outlined
                  hide-details
                  class="mt-4"
                />

                <!-- eslint-disable vue/no-v-html -->
                <div class="mb-3" v-html="$t('pages.scenarios.editModal.originHint')" />
                <!-- eslint-enable vue/no-v-html -->
              </template>

              <h-text-field
                v-if="stepIsModal"
                v-model.number="selectedStep.widthModal"
                :label="$t('pages.scenarios.editModal.modalWidth')"
                :rules="timeInputsRules"
                class="mt-4"
                outlined
              />

              <h-text-field
                v-if="stepIsModal"
                v-model.number="selectedStep.maxHeight"
                :label="$t('pages.scenarios.editModal.modalMaxHeight')"
                :rules="timeInputsRules"
                outlined
              />

              <v-expansion-panels v-if="!stepIsModal" class="mt-4">
                <v-expansion-panel>
                  <v-expansion-panel-header color="primary" class="white--text">
                    {{ $t('pages.scenarios.editModal.selectors') }}

                    <template #actions>
                      <v-icon color="white">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-container class="pt-6">
                      <draggable v-model="selectors" handle="[data-drag-selector]" animation="200">
                        <v-row v-for="(selector, index) in selectors" :key="index" align="center">
                          <v-icon data-drag-selector class="entity-edit-modal__drag-handler">
                            mdi-drag
                          </v-icon>

                          <v-col cols="10" class="mr-0">
                            <v-text-field
                              :value="selector"
                              :label="`${$t('pages.scenarios.editModal.selector')} ${index + 1}`"
                              outlined
                              hide-details
                              @input="onSelectorChange($event, index)"
                            />
                          </v-col>

                          <v-btn color="primary" icon @click="removeSelector(index)">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-row>
                      </draggable>
                    </v-container>

                    <v-row>
                      <v-col cols="12">
                        <v-btn block @click="addSelector">
                          <v-icon left>mdi-plus</v-icon>
                          {{ $t('pages.scenarios.editModal.addSelector') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <template v-if="!tooltipsMode">
                <v-switch
                  v-model="selectedStep.redirect"
                  :label="$t('pages.scenarios.editModal.redirectLabel')"
                  hide-details
                  class="mt-0"
                />

                <v-switch
                  v-if="!stepIsModal"
                  v-model="selectedStep.isOverlayed"
                  :label="$t('pages.scenarios.editModal.overlayLabel')"
                  hide-details
                />
              </template>

              <v-switch
                v-if="!stepIsModal"
                :input-value="experimental.useInnerText"
                :label="$t('pages.scenarios.editModal.useInnerTextLabel')"
                hide-details
                @change="experimental = { ...experimental, useInnerText: $event }"
              />

              <h-text-field
                v-if="tooltipsMode"
                v-model.number="zIndex"
                :label="$t('pages.styles.settings.zIndex')"
                :default-title="$t('common.defaultTitles.default')"
                :default-value="0"
                outlined
                class="mt-4"
              />

              <v-text-field
                v-if="experimental.useInnerText"
                v-model="selectedStep.experimental.innerText"
                :label="$t('pages.scenarios.editModal.innerTextLabel')"
                outlined
                hide-details
                class="mt-4"
              />

              <auto-fields-input
                v-if="selectedStep.inputType"
                v-model="selectedStep.autoFields"
                class="mt-6 mb-8"
              />

              <v-expansion-panels v-if="!tooltipsMode" class="mt-4">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    {{ $t('pages.scenarios.editModal.additionalOptions') }}
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <h-text-field
                      v-model.number="showDelay"
                      :label="$t('pages.scenarios.editModal.showDelay')"
                      :default-title="$t('common.defaultTitles.notSet')"
                      :default-value="0"
                      :rules="timeInputsRules"
                      outlined
                    />

                    <h-text-field
                      v-if="!stepIsModal"
                      v-model.number="searchStepTime"
                      :label="$t('pages.scenarios.editModal.searchStepTimeLabel')"
                      :default-title="$t('common.defaultTitles.notSet')"
                      :default-value="0"
                      :rules="timeInputsRules"
                      outlined
                    />

                    <h-text-field
                      v-if="!tooltipsMode"
                      v-model.number="zIndex"
                      :label="$t('pages.styles.settings.zIndex')"
                      :default-title="$t('common.defaultTitles.default')"
                      :default-value="0"
                      outlined
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <v-row v-if="!tempEntity.onModerating && !tempEntity.isDraft" class="mt-8 mb-2 pr-11">
            <v-select
              v-model="tempEntityTheme"
              :items="themesOptions"
              :label="$t('pages.scenarios.editModal.themeLabel')"
              outlined
              hide-details
            />
          </v-row>

          <template
            v-if="hasAccessToVersionControl && !tempEntity.onModerating && !tempEntity.isDraft"
          >
            <v-row class="flex-column">
              <span class="subtitle-1 mb-2">
                {{ $t(`${localeRootPath}.editModal.versionHistory`) }}
              </span>

              <div class="d-flex">
                <v-select
                  v-model="selectedVersionId"
                  :items="versionsList"
                  :hide-details="!Boolean(selectedVersion.creatorEmail)"
                  :persistent-hint="Boolean(selectedVersion.creatorEmail)"
                  :hint="selectedVersion.creatorEmail || null"
                  :disabled="creatingModeEnabled"
                  item-value="id"
                  outlined
                >
                  <template v-if="isBusySelectedVersion" #append>
                    <v-progress-circular size="20" width="3" indeterminate color="primary" />
                  </template>

                  <template #item="{item, attrs, on}">
                    <v-list-item v-bind="attrs" v-on="on">
                      {{ $t('pages.scenarios.editModal.version') }} {{ item.version }} ({{
                        formatDateTime(item.changedAt)
                      }})

                      <div v-if="item.isActual" class="ml-1">
                        &ndash;
                        <span class="primary--text">
                          {{ $t('pages.scenarios.editModal.lastVersion') }}
                        </span>
                      </div>

                      <template v-if="!tooltipsMode && tempEntity.hasRate">
                        <v-icon small color="#FFA630" class="ml-3">
                          mdi-star
                        </v-icon>

                        <span class="ml-1 body-2">{{ item.avgRate }}</span>
                      </template>
                    </v-list-item>
                  </template>

                  <template #selection="{item}">
                    {{ $t('pages.scenarios.editModal.version') }} {{ item.version }} ({{
                      formatDateTime(item.changedAt)
                    }})

                    <div v-if="item.isActual" class="ml-1">
                      &ndash;
                      <span :class="!creatingModeEnabled ? 'primary--text' : ''">
                        {{ $t('pages.scenarios.editModal.lastVersion') }}
                      </span>
                    </div>

                    <template v-if="!tooltipsMode && tempEntity.hasRate">
                      <v-icon small color="#FFA630" class="ml-3">
                        mdi-star
                      </v-icon>

                      <span class="ml-1 body-2">{{ item.avgRate }}</span>
                    </template>
                  </template>
                </v-select>

                <v-tooltip v-if="!creatingModeEnabled" top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="mt-2 ml-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="setCreatingModeEnabled(true)"
                    >
                      <v-icon>mdi-plus-box-multiple</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t(`${localeRootPath}.editModal.createFromVersion`) }}</span>
                </v-tooltip>
              </div>
            </v-row>

            <v-row
              v-if="
                !creatingModeEnabled &&
                  !tempEntity.onModerating &&
                  !tempEntity.isDraft &&
                  isModeratorOrHigher
              "
              class="mt-5 pr-11"
            >
              <v-combobox
                v-model="versionTags"
                :items="tagsOptions"
                :filter="filterTags"
                :disabled="!isModeratorOrHigher"
                :type="isRealAdminOrHigher ? 'text' : 'button'"
                hide-selected
                multiple
                small-chips
                item-text="title"
                item-value="id"
                item-disabled="isSystemTag"
                :label="$t('pages.scenarios.editModal.tagsLabel')"
                :placeholder="$t('pages.scenarios.editModal.tagsPlaceholder')"
                outlined
                @blur="deletingTagId = null"
              >
                <template #selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :color="item.color"
                    :input-value="selected"
                    small
                  >
                    <span>
                      {{ item.title }}
                    </span>

                    <v-icon
                      v-if="!item.isSystemTag && isModeratorOrHigher"
                      small
                      class="ml-2"
                      @click="parent.selectItem(item)"
                    >
                      $delete
                    </v-icon>
                  </v-chip>
                </template>

                <template #item="{ item }">
                  <span v-if="item.id === deletingTagId" class="mr-1">
                    {{ $t('pages.scenarios.editModal.tagsDeleteConfirm') }}
                  </span>

                  <v-chip :color="item.color" small>
                    {{ item.title }}
                  </v-chip>

                  <span v-if="item.id === deletingTagId" class="ml-1">
                    ?
                  </span>

                  <v-spacer />

                  <v-list-item-action
                    v-if="isRealAdminOrHigher"
                    class="flex align-center flex-row  flex-grow-0 flex-nowrap"
                    @click.stop
                  >
                    <template v-if="deletingTagId === item.id">
                      <v-btn icon @click="removeTag()">
                        <v-icon small color="gray">
                          mdi-check
                        </v-icon>
                      </v-btn>

                      <v-btn icon @click="deletingTagId = null">
                        <v-icon small color="gray">
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-btn v-else icon color="red" @click="deletingTagId = item.id">
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </template>

                <template #no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <!-- eslint-disable vue/no-v-html -->
                        <span v-html="$t('pages.scenarios.editModal.tagsEnterCaption')" />
                        <!-- eslint-enable vue/no-v-html -->
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-row>
          </template>

          <template v-if="tempEntity.onModerating || tempEntity.isDraft">
            <v-row class="mt-0">
              <v-alert
                type="success"
                color="primary"
                dark
                width="100%"
                class="mt-4 py-6 entity-edit-modal__draft-alert"
              >
                <v-row align="center">
                  <v-col class="grow">
                    {{ $t('pages.scenarios.editModal.draftStatus') }}
                  </v-col>
                  <v-col class="shrink">
                    <v-btn light @click="rejectDialogOpened = true">
                      {{ $t('pages.scenarios.editModal.deleteDraft') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-row>

            <v-row v-if="!tempEntity.isDraft">
              <v-switch
                v-model="createVersionOnSaving"
                :disabled="!isModeratorOrHigher"
                :label="$t('pages.scenarios.editModal.createVersionOnSaving')"
                hide-details
                class="mt-2"
              />
            </v-row>

            <v-row v-if="isModeratorOrHigher" class="mt-6">
              <v-btn
                :loading="isBusyUpdatingVersion && !isBusyWithClose"
                color="primary"
                dark
                class="mt-6"
                @click="approveEntityDraft()"
              >
                {{ $t('pages.scenarios.editModal.approveButton') }}
              </v-btn>

              <v-btn
                :loading="isBusyUpdatingVersion && isBusyWithClose"
                class="mt-6 ml-3"
                @click="submit(true)"
              >
                {{ $t('ui.common.save') }}
              </v-btn>
            </v-row>

            <v-row v-else class="mt-6">
              <v-btn
                :loading="isBusyUpdatingVersion && !isBusyWithClose"
                color="primary"
                dark
                class="mt-6"
                @click="submit()"
              >
                {{ $t('pages.scenarios.editModal.updateButton') }}
              </v-btn>

              <v-btn
                :loading="isBusyUpdatingVersion && isBusyWithClose"
                class="mt-6 ml-3"
                @click="submit(true)"
              >
                {{ $t('pages.scenarios.editModal.updateAndCloseButton') }}
              </v-btn>
            </v-row>
          </template>

          <template v-else-if="moderateContentEnabled && !isModeratorOrHigher">
            <v-row class="mt-6">
              <v-btn
                :loading="isBusyUpdatingVersion && !isBusyWithClose"
                color="primary"
                dark
                class="mt-6"
                @click="createEntityDraft()"
              >
                {{ $t('pages.scenarios.editModal.sendToModerating') }}
              </v-btn>

              <v-btn
                :loading="isBusyUpdatingVersion && isBusyWithClose"
                class="mt-6 ml-3"
                @click="createEntityDraft(true)"
              >
                {{ $t('pages.scenarios.editModal.sendToModeratingAndClose') }}
              </v-btn>
            </v-row>
          </template>

          <template v-else>
            <v-row v-if="creatingModeEnabled" class="mt-6">
              <v-btn
                :loading="isBusyUpdatingVersion && isBusyWithClose"
                color="primary"
                dark
                @click="submit(true)"
              >
                {{ $t('ui.common.create') }}
              </v-btn>

              <v-btn class="ml-3" @click="setCreatingModeEnabled(false)">
                {{ $t('ui.common.cancel') }}
              </v-btn>
            </v-row>

            <template v-else>
              <v-row v-if="isSelectedActualVersion && hasAccessToVersionControl" class="mt-0 pr-11">
                <v-switch
                  v-model="createVersionOnSaving"
                  :disabled="!isModeratorOrHigher"
                  :label="$t('pages.scenarios.editModal.createVersionOnSaving')"
                  hide-details
                  class="mt-2"
                />

                <v-alert
                  v-if="!isModeratorOrHigher && !tempEntity.onModerating && !tempEntity.isDraft"
                  color="primary"
                  dark
                  class="mt-4 py-6"
                  width="100%"
                >
                  <template #prepend>
                    <v-icon class="mr-4">
                      mdi-alert
                    </v-icon>
                  </template>

                  <!-- eslint-disable vue/no-v-html -->
                  <span v-html="$t('pages.scenarios.editModal.tagsEditorAlert')" />
                  <!-- eslint-enable vue/no-v-html -->
                </v-alert>
              </v-row>

              <v-row v-if="!isSelectedActualVersion" class="mt-6">
                <v-btn color="primary" :loading="isBusyUpdatingVersion" @click="submit()">
                  {{
                    $t(
                      `pages.scenarios.editModal.${
                        versionHasUnsavedChanges ? 'createNewVersion' : 'restoreVersion'
                      }`
                    )
                  }}
                </v-btn>
              </v-row>

              <v-row v-else class="mt-6">
                <v-btn
                  :loading="isBusyUpdatingVersion && !isBusyWithClose"
                  color="primary"
                  dark
                  class="mt-6"
                  @click="submit()"
                >
                  {{ $t('ui.common.save') }}
                </v-btn>

                <v-btn
                  :loading="isBusyUpdatingVersion && isBusyWithClose"
                  class="mt-6 ml-3"
                  @click="submit(true)"
                >
                  {{ $t('ui.common.saveAndClose') }}
                </v-btn>
              </v-row>
            </template>
          </template>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="rejectDialogOpened" max-width="290">
      <v-card data-test-confirm-remove-dialog>
        <v-card-title class="text-h5">
          {{ $t('ui.common.confirm') }}
        </v-card-title>

        <v-card-text>
          {{ $t('pages.scenarios.editModal.confirmDeleteDraft') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="grey" text data-test-confirm-cancel @click="rejectDialogOpened = false">
            {{ $t('ui.common.cancel') }}
          </v-btn>

          <v-btn color="primary" text data-test-confirm @click="deleteDraft()">
            {{ $t('ui.common.remove') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
  import draggable from 'vuedraggable';

  import { SET_SYNCED_SIMULATION_ID } from 'src/store/mutation-types';

  import api from 'src/api';

  import { strToHtml, moveArrayElement } from 'src/utils';

  import versionsMixin from 'src/mixins/versionsMixin';
  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  import HTextField from 'src/components/HTextField/HTextField.vue';
  import TextEditor from 'src/components/TextEditor.vue';
  import OriginListEdit from 'src/components/OriginListEdit/OriginListEdit.vue';

  import AutoFieldsInput from './AutoFieldsInput.vue';

  export default {
    name: 'EntityEditModal',

    components: {
      draggable,
      HTextField,
      TextEditor,
      AutoFieldsInput,
      OriginListEdit,
    },

    mixins: [versionsMixin, formatTimestampMixin],

    props: {
      editingEntity: {
        type: Object,
        required: false,
        default: null,
      },

      tooltipsMode: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        tempEntity: {},
        tempEntityTheme: null,
        originalEntityTitle: null,
        originalEntityTheme: null,
        dialog: false,
        showedTextEditor: true,
        selectedStepId: null,
        createVersionOnSaving: true,
        isBusyWithClose: false,
        deletingTagId: null,
        closeConditionOptions: Object.freeze(
          ['next', 'click', 'value_enter', 'value_enter_or_timeout', 'hover'].map(value => ({
            value,
            text: this.$t(`pages.scenarios.editModal.closeCondition.items.${value}`),
          }))
        ),
        contentPositionOptions: Object.freeze(
          ['top', 'left', 'right', 'bottom'].map(value => ({
            value,
            text: this.$t(`pages.scenarios.editModal.position.items.${value}`),
          }))
        ),

        tooltipIconPositionOptions: Object.freeze(
          [
            { value: 'top-right', text: 'rightTop' },
            { value: 'bottom-right', text: 'rightBottom' },
            { value: 'top-left', text: 'leftTop' },
            { value: 'bottom-left', text: 'leftBottom' },
          ].map(({ value, text }) => ({
            value,
            text: this.$t(`pages.tooltips.editModal.iconPosition.${text}`),
          }))
        ),

        moderateContentEnabled: false,
        rejectDialogOpened: false,
      };
    },

    computed: {
      ...mapGetters('users', [
        'isEnterprisePlanActive',
        'isModeratorOrHigher',
        'isRealAdminOrHigher',
      ]),
      ...mapState('users', ['currentUser']),
      ...mapState('elementStyle', ['elementStyles']),
      ...mapGetters('versions', ['isSyncedScenarioWithSimulation']),

      timeInputsRules() {
        return [
          v => !Number.isNaN(v) || this.$t('validation.notNull'),
          v => v >= 0 || this.$t('validation.notPositiveOrZero'),
        ];
      },

      filteredCloseConditionOptions() {
        return this.selectedStep.onlyHighlight
          ? this.closeConditionOptions.filter(condition => condition.value !== 'next')
          : this.closeConditionOptions;
      },

      tagsOptions() {
        return [
          {
            header: this.$t(
              `pages.scenarios.editModal.${
                this.isRealAdminOrHigher ? 'tagsHeader' : 'tagsHeaderModerator'
              }`
            ),
          },
          ...this.tagsList,
        ];
      },

      themesOptions() {
        return [
          {
            value: null,
            text: this.$t('pages.scenarios.editModal.defaultTheme'),
          },
          ...this.elementStyles
            .map(style => ({ value: style.id, text: style.title }))
            .filter(({ text }) => text !== this.$t('pages.scenarios.editModal.defaultTheme')),
        ];
      },

      localeRootPath() {
        return this.tooltipsMode ? 'pages.tooltips' : 'pages.scenarios';
      },

      selectedStep() {
        if (!this.selectedStepId) {
          return null;
        }

        return this.tempEntity.steps.find(step => step.id === this.selectedStepId);
      },

      stepIsModal() {
        if (!this.selectedStep) {
          return false;
        }

        return this.selectedStep.stepType === 'modal';
      },

      selectors: {
        get() {
          if (!this.selectedStep || this.stepIsModal) {
            return null;
          }

          const {
            selector,
            elementDescription: { selectors },
          } = this.selectedStep;

          if (!selectors.length) {
            return [selector];
          }

          const [, ...otherSelectors] = selectors;
          return [selector, ...otherSelectors];
        },

        set(value) {
          const [selector, ...selectors] = value;

          this.selectedStep.selector = selector;
          this.selectedStep.elementDescription = {
            selectors: [selector, ...selectors],
          };
        },
      },

      experimental: {
        get() {
          if (!this.selectedStep) {
            return null;
          }

          return this.selectedStep.experimental || {};
        },
        set(value) {
          this.selectedStep.experimental = value;
        },
      },

      searchStepTime: {
        get() {
          if (!this.selectedStep) {
            return null;
          }

          if (!this.selectedStep.searchStepTime) {
            return 0;
          }

          return this.selectedStep.searchStepTime / 1000;
        },

        set(value) {
          if (!value) {
            this.selectedStep.searchStepTime = 0;

            return;
          }

          const number = Number.parseFloat(value).toFixed(1);
          this.selectedStep.searchStepTime = number * 1000;
        },
      },

      showDelay: {
        get() {
          if (!this.selectedStep) {
            return null;
          }

          if (!this.selectedStep.showDelay) {
            return 0;
          }

          return this.selectedStep.showDelay / 1000;
        },

        set(value) {
          if (!value) {
            this.selectedStep.showDelay = 0;

            return;
          }

          const number = Number.parseFloat(value).toFixed(1);
          this.selectedStep.showDelay = number * 1000;
        },
      },

      zIndex: {
        get() {
          if (!this.selectedStep) {
            return null;
          }

          return this.selectedStep.zIndex || 0;
        },

        set(value) {
          if (!value) {
            this.selectedStep.zIndex = 0;

            return;
          }

          this.selectedStep.zIndex = Number.parseInt(value, 10);
        },
      },

      selectedStepIndex() {
        if (!this.selectedStep) {
          return -1;
        }

        return this.tempEntity.steps.findIndex(step => step.id === this.selectedStep.id);
      },

      showedPrevButton: {
        get() {
          if (!this.selectedStep) {
            return null;
          }

          return this.selectedStep?.buttons.includes('prev') || false;
        },

        set(value) {
          let buttons = [...(this.selectedStep?.buttons || [])];

          if (value) {
            buttons.push('prev');
          } else {
            buttons = buttons.filter(button => button !== 'prev');
          }

          this.selectedStep.buttons = buttons;
        },
      },

      closeConditionList: {
        get() {
          if (this.tooltipsMode || !this.selectedStep) {
            return null;
          }

          return this.selectedStep.closeConditionList || [this.selectedStep.closeCondition];
        },

        set(value) {
          this.$set(this.selectedStep, 'closeConditionList', value);
          [this.selectedStep.closeCondition] = value;
        },
      },

      originList: {
        get() {
          if (!this.tempEntity) {
            return [];
          }

          if (!this.tempEntity.originList?.length) {
            return [{ type: 'text', value: this.tempEntity.origin }];
          }

          return this.tempEntity.originList;
        },
        set(value) {
          this.$set(this.tempEntity, 'originList', value);
        },
      },
    },

    watch: {
      tooltipsMode(value) {
        this.setVersionEntityType(value ? 'tooltips' : 'scenarios');
      },

      editingEntity(value) {
        if (value) {
          this.open();
        } else {
          this.close();
        }
      },

      async creatingModeEnabled(value) {
        if (value) {
          await this.$nextTick();
          this.originalEntityTitle = this.tempEntity.title;
          this.tempEntity.title = null;
          this.$refs.titleInput.focus();
        } else {
          this.tempEntity.title = this.originalEntityTitle;
          this.originalEntityTitle = null;
        }
      },

      selectedVersionData() {
        this.tempEntity = this.selectedVersionData;

        if (this.tempEntity?.onModerating) {
          this.createVersionOnSaving = this.tempEntity.isNewVersion;
        }
      },

      dialog(value) {
        if (!value) {
          this.clearVersionsData();
          this.selectedStepId = null;
        }
      },

      selectedStepId() {
        this.reloadTextEditor();
      },

      originList: {
        handler(value) {
          if (!value?.length) {
            return;
          }

          const textOrigin = value.find(originItem => originItem.type === 'text');

          if (textOrigin) {
            this.tempEntity.origin = textOrigin.value;
          }
        },
        deep: true,
      },
    },

    async mounted() {
      this.setVersionEntityType(this.tooltipsMode ? 'tooltips' : 'scenarios');

      const { response } = await api.organizations.getSettings({
        url_params: { id: this.currentUser.organizationId },
      });
      this.moderateContentEnabled = response.moderateContent;
    },

    methods: {
      ...mapActions('elementStyle', [
        'getByOrganization',
        'enableElementTheme',
        'disableElementTheme',
        'getTooltipTheme',
        'getScenarioTheme',
      ]),
      ...mapMutations('versions', { setSyncedSimulationId: SET_SYNCED_SIMULATION_ID }),

      async loadThemes() {
        await this.getByOrganization();

        const themeMethod = this.tooltipsMode ? this.getTooltipTheme : this.getScenarioTheme;
        const theme = await themeMethod(this.editingEntity.id);

        this.originalEntityTheme = theme.isCustomTheme ? theme.id : null;
        this.tempEntityTheme = theme.isCustomTheme ? theme.id : null;
      },

      async open() {
        this.setVersionEntity(this.editingEntity);

        if (!this.tooltipsMode) {
          const { response } = await api.scenarios.getSyncedSimulation({
            url_params: { scenarioId: this.editingEntity.id },
          });

          this.setSyncedSimulationId(response.simulationId);
        }

        if (this.isModeratorOrHigher) {
          this.loadThemes();
        }
        this.dialog = true;
      },

      close() {
        this.dialog = false;
        this.$emit('close');
      },

      deleteStep(stepIndex) {
        if (!this.tempEntity) {
          return;
        }

        this.tempEntity.steps.splice(stepIndex, 1);

        if (this.isSyncedScenarioWithSimulation) {
          this.tempEntity.simulation.steps.splice(stepIndex, 1);
        }
      },

      addSelector() {
        this.selectors = [...this.selectors, null];
      },

      removeSelector(index) {
        const selectors = [...this.selectors];
        selectors.splice(index, 1);
        this.selectors = selectors;
      },

      onSelectorChange(data, index) {
        const selectors = [...this.selectors];
        selectors[index] = data;
        this.selectors = selectors;
      },

      getFirstInnerText(text) {
        const html = strToHtml(text) || {};
        if (html.innerText) {
          return html.innerText;
        }
        return '<-- Empty title -->';
      },

      async submit(closeAfterUpdating = false) {
        if (!this.tempEntity.title) {
          this.tempEntity.title = this.originalEntityTitle;
        }
        this.originalEntityTitle = null;

        this.isBusyWithClose = closeAfterUpdating;

        await this.update(this.createVersionOnSaving);

        if (this.tempEntityTheme !== this.originalEntityTheme) {
          const elementType = this.tooltipsMode ? 'tooltip' : 'scenario';
          const elementId = this.editingEntity.id;
          const styleId = this.tempEntityTheme;

          if (!styleId) {
            await this.disableElementTheme({ elementId, elementType });
          } else {
            await this.enableElementTheme({ elementId, elementType, styleId });
          }
        }

        this.$emit(this.creatingModeEnabled ? 'created' : 'updated');

        if (closeAfterUpdating) {
          this.isBusyWithClose = false;
          this.close();
        }
      },

      async reloadTextEditor() {
        this.showedTextEditor = false;
        await this.$nextTick();
        this.showedTextEditor = true;
      },

      filterTags(item, queryText, itemText) {
        if (item.header) {
          return false;
        }

        const hasValue = val => (val != null ? val : '');

        const text = hasValue(itemText);
        const query = hasValue(queryText);

        return (
          text
            .toString()
            .toLowerCase()
            .trim()
            .indexOf(
              query
                .toString()
                .toLowerCase()
                .trim()
            ) > -1
        );
      },

      async removeTag() {
        await this.deleteTag(this.deletingTagId);
        this.deletingTagId = null;
      },

      closeConditionIsDisabled(item) {
        if (this.closeConditionList.length > 1) {
          return false;
        }

        return this.closeConditionList[0] === item.value;
      },

      async createEntityDraft(closeAfterCreating = false) {
        await this.createDraft();

        this.tempEntity.onModerating = true;

        this.$emit('updated');

        if (closeAfterCreating) {
          this.close();
        }
      },

      async approveEntityDraft() {
        await this.approveDraft(this.createVersionOnSaving);

        this.$emit('updated');
        this.close();
      },

      async deleteDraft() {
        this.rejectDialogOpened = false;
        await this.rejectDraft();

        this.$emit('updated');
        this.close();
      },

      changeOrderOfSyncedSimulationSteps($event) {
        if (!this.isSyncedScenarioWithSimulation) {
          return;
        }

        this.tempEntity.simulation.steps = moveArrayElement(
          this.tempEntity.simulation.steps,
          $event.moved.oldIndex,
          $event.moved.newIndex
        );
      },
    },
  };
</script>

<style lang="scss">
  .entity-edit-modal {
    &__tooltip {
      opacity: 1 !important;
    }

    &__drag-handler {
      cursor: move !important;
    }

    &__show-full-text-button {
      cursor: pointer;
    }

    &__draft-alert {
      .v-alert__icon.v-icon {
        align-self: center;
      }
    }
  }
</style>
