import api from 'src/api';

export default {
  namespaced: true,

  actions: {
    async checkAvailability() {
      const { response } = await api.trace.availability();

      return response.isAvailable;
    },

    async getList() {
      const { response } = await api.trace.list();

      return response;
    },

    async download(context, { filenames, onDownloadProgress = () => {} }) {
      const payload = {
        data: { filenames },
        options: {
          onDownloadProgress,
        },
      };

      const { response } = await api.trace.download(payload);

      return response;
    },
  },
};
