const create = {
  name: 'create',
  method: 'post',
  url: '/api/groups/',
};

const getOne = {
  name: 'getOne',
  method: 'get',
  url: ':id',
};

const getRoot = {
  name: 'getRoot',
  method: 'get',
  url: 'get-root-group',
};

const update = {
  name: 'update',
  method: 'patch',
  url: ':id',
};

const list = {
  name: 'list',
  method: 'get',
};

const remove = {
  name: 'remove',
  method: 'delete',
  url: ':id',
};

const getUsers = {
  name: 'getUsers',
  method: 'get',
  url: ':id/users',
};

const addUsers = {
  name: 'addUsers',
  method: 'patch',
  url: ':id/add-users',
};

const removeUsers = {
  name: 'removeUsers',
  method: 'patch',
  url: ':id/remove-users',
};

const getUserGroups = {
  name: 'getUserGroups',
  method: 'get',
  url: 'user/:id/',
};

const removeScenarios = {
  name: 'removeScenarios',
  method: 'patch',
  url: ':id/remove-scenarios',
};

const addScenarios = {
  name: 'addScenarios',
  method: 'patch',
  url: ':id/add-scenarios',
};

const getScenarios = {
  name: 'getScenarios',
  method: 'get',
  url: ':id/scenarios',
};

const removeTooltips = {
  name: 'removeTooltips',
  method: 'patch',
  url: ':id/remove-tooltips',
};

const addTooltips = {
  name: 'addTooltips',
  method: 'patch',
  url: ':id/add-tooltips',
};

const getTooltips = {
  name: 'getTooltips',
  method: 'get',
  url: ':id/tooltips',
};

const removeChecklists = {
  name: 'removeChecklists',
  method: 'patch',
  url: ':id/remove-checklists',
};

const addChecklists = {
  name: 'addChecklists',
  method: 'patch',
  url: ':id/add-checklists',
};

const removeLinks = {
  name: 'removeLinks',
  method: 'patch',
  url: ':id/remove-links',
};

const addLinks = {
  name: 'addLinks',
  method: 'patch',
  url: ':id/add-links',
};

const getLinks = {
  name: 'getLinks',
  method: 'get',
  url: ':id/links',
};

export default {
  name: 'groups',
  url: '/api/groups',
  children: [
    getOne,
    getRoot,
    create,
    update,
    list,
    remove,
    getUsers,
    addUsers,
    removeUsers,
    getUserGroups,
    removeScenarios,
    addScenarios,
    getScenarios,
    removeTooltips,
    addTooltips,
    getTooltips,
    removeChecklists,
    addChecklists,
    removeLinks,
    addLinks,
    getLinks,
  ],
};
