<template>
  <base-download-nested-document-dialog-vue
    :show="show"
    :is-busy="isBusy"
    format="Docx"
    @close="$emit('close')"
    @download="downloadDocx"
  />
</template>
<script>
  import DocxMerger from 'docx-merger';
  import { saveAs } from 'file-saver';

  import api from 'src/api';
  import BaseDownloadNestedDocumentDialogVue from 'src/components/BaseDownloadNestedDocumentDialog/BaseDownloadNestedDocumentDialog.vue';

  export default {
    name: 'DownloadDocx',

    components: {
      BaseDownloadNestedDocumentDialogVue,
    },

    props: {
      show: { type: Boolean, required: true, default: false },
      id: { type: String, required: true },
      title: { type: String, required: true },
    },

    data: () => ({
      isBusy: false,
    }),

    methods: {
      async downloadDocx(file) {
        this.isBusy = true;

        try {
          const { response } = await api.simulations.downloadDocx({
            url_params: { id: this.id },
          });
          if (file) {
            const userFileBuffer = await file.arrayBuffer();
            const simulationFileBuffer = await response.arrayBuffer();
            const docx = new DocxMerger({}, [userFileBuffer, simulationFileBuffer]);
            return docx.save('blob', data => {
              saveAs(data, `${this.title}.docx`);
            });
          }

          return saveAs(response, `${this.title}.docx`);
        } finally {
          this.isBusy = false;
          this.$emit('close');
        }
      },
    },
  };
</script>
