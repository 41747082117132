<template>
  <base-notification-item :notification="notification" class="entity-moderate-notification-item">
    <template #header>
      <span>
        {{ $t(`ui.notificationsList.moderateContentNotification.${notification.data.type}From`) }}
      </span>

      <a
        :href="`mailto:${notification.data.creatorEmail}`"
        class="entity-moderate-notification-item__link"
      >
        {{ notification.data.creatorEmail }}
      </a>
    </template>

    <template #content>
      {{ notification.data.title }}

      <entity-edit-modal
        v-if="openedEditForm"
        :editing-entity="entity"
        :tooltips-mode="notification.data.type === 'tooltips'"
        @close="openedEditForm = false"
      />
    </template>

    <template #actions>
      <button
        v-if="!notification.data.draftStatus"
        class="entity-moderate-notification-item__action-button"
        @click="openDraftForm()"
      >
        {{ $t('ui.notificationsList.moderate') }}
      </button>

      <span v-else>
        {{
          $t(`ui.notificationsList.moderateContentNotification.${notification.data.draftStatus}`)
        }}
      </span>
    </template>
  </base-notification-item>
</template>

<script>
  import { mapMutations } from 'vuex';

  import { SET_SHOWED_NOTIFICATIONS_PANEL } from 'src/store/mutation-types';

  import EntityEditModal from 'src/components/WidgetEntities/EntityEditModal.vue';

  import BaseNotificationItem from './BaseNotificationItem.vue';
  import notificationItemMixin from './notificationItemMixin';

  export default {
    name: 'EntityModerateNotificationItem',

    components: {
      EntityEditModal,
      BaseNotificationItem,
    },

    mixins: [notificationItemMixin],

    data() {
      return {
        openedEditForm: false,
        entity: null,
      };
    },

    methods: {
      ...mapMutations('notifications', {
        setShowedNotificationsPanel: SET_SHOWED_NOTIFICATIONS_PANEL,
      }),

      async openDraftForm() {
        this.setShowedNotificationsPanel(false);

        this.read();

        this.openedEditForm = true;

        await this.$nextTick();

        this.entity = {
          id: this.notification.entityId,
          onModerating: true,
        };
      },
    },
  };
</script>

<style lang="scss">
  .entity-moderate-notification-item {
    &__link {
      color: #009efb !important;
      text-decoration: underline;
    }

    &__action-button {
      color: #d81159;
      text-decoration: underline;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
    }
  }
</style>
