<template>
  <v-list expand nav>
    <base-item-props v-if="showBrowserExtensionLink" v-bind="browserExtensionLink" />
    <base-item v-if="showDesktopAppLink" :item="desktopAppLink">
      <template #adornmentStart>
        <v-list-item-icon>
          <icon-base>
            <desktop-app-icon icon-color="#FFFF" />
          </icon-base>
        </v-list-item-icon>
      </template>
    </base-item>
  </v-list>
</template>

<script>
  import { mapState } from 'vuex';
  import { apiUrl } from 'src/config';
  import {
    ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,
    ORGANIZATION_PLAN_SMART_MANUALS_PRO,
    ORGANIZATION_PLAN_SMART_MANUALS_START,
  } from 'src/constants';
  import IconBase from 'src/components/Icons/IconBase.vue';
  import DesktopAppIcon from 'src/components/Icons/DesktopAppIcon.vue';

  export default {
    name: 'DownloadAppLinks',

    components: { IconBase, DesktopAppIcon },

    data() {
      return {
        browserExtensionLink: {
          title: this.$t('ui.sidebar.downloadExtension'),
          icon: 'chrome',
          href:
            'https://chrome.google.com/webstore/detail/hinted-smart-manuals-edit/fndkgbgkmmoblbhlbdkacjcbngdlokpi',
        },
      };
    },

    computed: {
      ...mapState('users', ['currentUser']),
      showDesktopAppLink() {
        return [
          ORGANIZATION_PLAN_SMART_MANUALS_PRO,
          ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,
        ].includes(this.currentUser?.organizationPlan);
      },
      showBrowserExtensionLink() {
        return [
          ORGANIZATION_PLAN_SMART_MANUALS_START,
          ORGANIZATION_PLAN_SMART_MANUALS_PRO,
          ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,
        ].includes(this.currentUser?.organizationPlan);
      },

      desktopAppLink() {
        const { href } = new URL('/plugins/desktop/hinted.exe', apiUrl);
        return {
          title: this.$t('ui.sidebar.downloadDesktopApp'),
          href,
        };
      },
    },
  };
</script>
