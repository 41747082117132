<template>
  <v-form ref="form" @submit.prevent="remove()">
    <v-card>
      <v-card-title>
        <span class="headline text-truncate">
          {{ $t('ui.workspaces.removeDialog.title') }} '{{ workspace.title }}'
        </span>
      </v-card-title>

      <v-card-text>
        <v-container class="mt-4">
          <v-row class="mb-4">
            <v-alert type="warning">
              {{ $t('ui.workspaces.removeDialog.alertText') }}
              <br />
              {{ $t('ui.workspaces.removeDialog.alertConfirm') }}
            </v-alert>
          </v-row>

          <v-row>
            <v-text-field
              ref="input"
              v-model="confirmTitle"
              :label="$t('ui.workspaces.editDialog.fields.title')"
              outlined
              required
            />
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="primary" text @click="$emit('close')">
          {{ $t('ui.common.cancel') }}
        </v-btn>

        <v-btn :loading="isBusy" :disabled="!titleConfirmed" color="primary" text type="submit">
          {{ $t('ui.common.remove') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import { mapActions } from 'vuex';

  import { busyFlow } from 'src/utils';

  export default {
    name: 'WorkspaceRemoveConfirm',

    props: {
      workspace: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        isBusy: false,
        confirmTitle: '',
      };
    },

    computed: {
      titleConfirmed() {
        return this.workspace.title.trim().toLowerCase() === this.confirmTitle.trim().toLowerCase();
      },
    },

    async mounted() {
      await this.$nextTick();

      this.$refs.input.focus();
    },

    methods: {
      ...mapActions('workspaces', ['removeWorkspace']),

      remove() {
        if (!this.titleConfirmed) {
          return;
        }

        busyFlow.call(this, async () => {
          await this.removeWorkspace({ id: this.workspace.id, title: this.confirmTitle });

          this.$emit('deleted');
        });
      },
    },
  };
</script>
