<template>
  <v-container fluid class-name="down-top-padding">
    <v-row class-name="pb-0">
      <v-col cols="12" class="pt-0">
        <v-container>
          <v-card flat>
            <v-card-text class="d-flex pa-5">
              <v-row align="center">
                <v-col cols="auto">
                  <h3 class="title font-weight-regular">
                    <span class="lstick" />
                    {{ $t('pages.statistics.statistics') }}:
                  </h3>
                </v-col>

                <v-col cols="3">
                  <v-autocomplete
                    v-model="tooltipId"
                    :items="tooltips"
                    :label="$t('pages.statistics.tooltip')"
                    item-text="title"
                    item-value="id"
                    prepend-inner-icon="mdi-magnify"
                    hide-details
                  />
                </v-col>

                <v-col cols="3" md="2" xl="3">
                  <v-menu
                    ref="startDate"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        :label="$t('pages.statistics.startDate')"
                        prepend-icon="mdi-calendar"
                        hide-details
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="startDate"
                      :min="minDate"
                      :max="maxDate"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn text color="primary" @click="startDateMenu = false">
                        {{ $t('ui.common.cancel') }}
                      </v-btn>

                      <v-btn text color="primary" @click="$refs.startDate.save(startDate)">
                        {{ $t('ui.common.ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="3" md="2" xl="3">
                  <v-menu
                    ref="endDate"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="endDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        :label="$t('pages.statistics.endDate')"
                        prepend-icon="mdi-calendar"
                        readonly
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="endDate"
                      :min="startDate || minDate"
                      :max="maxDate"
                      no-title
                      scrollable
                    >
                      <v-spacer />

                      <v-btn text color="primary" @click="endDateMenu = false">
                        {{ $t('ui.common.cancel') }}
                      </v-btn>

                      <v-btn text color="primary" @click="$refs.endDate.save(endDate)">
                        {{ $t('ui.common.ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-spacer />

                <v-col cols="auto">
                  <v-btn dark color="primary" small @click="openDownloadDialog()">
                    {{ $t('pages.statistics.downloadStatistics') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- TODO: refactor to reusable component DownloadStatistics like at scenarioStatistic tab -->
          <v-dialog v-model="downloadDialogOpened" max-width="700px">
            <v-card>
              <v-card-title>
                {{ $t('pages.statistics.downloadStatistics') }}
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col>
                    <v-progress-linear indeterminate :active="isBusy" />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="auto">
                    <v-menu
                      ref="startDateDialog"
                      v-model="dialogDates.startDateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="dialogDates.startDate"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="dialogDates.startDate"
                          :label="$t('pages.statistics.startDate')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>

                      <v-date-picker
                        v-model="dialogDates.startDate"
                        :min="minDate"
                        :max="maxDate"
                        no-title
                        scrollable
                      >
                        <v-spacer />

                        <v-btn text color="primary" @click="dialogDates.startDateMenu = false">
                          {{ $t('ui.common.cancel') }}
                        </v-btn>

                        <v-btn
                          text
                          color="primary"
                          @click="$refs.startDateDialog.save(dialogDates.startDate)"
                        >
                          {{ $t('ui.common.ok') }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-spacer />

                  <v-col cols="auto">
                    <v-menu
                      ref="endDateDialog"
                      v-model="dialogDates.endDateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="dialogDates.endDate"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="dialogDates.endDate"
                          :label="$t('pages.statistics.endDate')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>

                      <v-date-picker
                        v-model="dialogDates.endDate"
                        :min="dialogDates.startDate || minDate"
                        :max="maxDate"
                        no-title
                        scrollable
                      >
                        <v-spacer />

                        <v-btn text color="primary" @click="dialogDates.endDateMenu = false">
                          {{ $t('ui.common.cancel') }}
                        </v-btn>

                        <v-btn
                          text
                          color="primary"
                          @click="$refs.endDateDialog.save(dialogDates.endDate)"
                        >
                          {{ $t('ui.common.ok') }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-spacer />

                  <v-col cols="auto">
                    <v-btn dark color="primary" small @click="downloadStatistics()">
                      {{ $t('pages.statistics.downloadSimulations') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-container>
      </v-col>

      <v-col cols="12">
        <v-container>
          <tooltip-playing-chart :chart-data="playingChart" />
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { saveAs } from 'file-saver';
  import moment from 'moment';

  import { mapActions, mapState } from 'vuex';
  import api from 'src/api';

  import TooltipPlayingChart from 'src/components/Statistics/TooltipPlayingChart.vue';
  import { getLocationForQueries } from 'src/utils';

  export default {
    name: 'TooltipsStats',

    components: {
      TooltipPlayingChart,
    },

    data() {
      return {
        organizationId: null,
        playingCardsStats: {},

        tooltipId: null,
        tooltips: [],
        params: {},
        serverItemsCount: 0,
        isBusy: false,

        maxDate: null,
        minDate: '2000-01-01',
        startDate: null,
        startDateMenu: false,
        endDate: null,
        endDateMenu: false,
        datesFilter: {},

        dialogDates: {
          startDate: null,
          startDateMenu: false,
          endDate: null,
          endDateMenu: false,
        },

        downloadDialogOpened: false,

        playingChart: [],
      };
    },

    computed: {
      ...mapState('users', ['currentUser']),
    },

    watch: {
      organizationId() {
        this.getTooltips();
      },

      tooltipId() {
        if (this.tooltipId) {
          this.updateStatistics();
        }
      },

      startDate(value, prevValue) {
        const from = moment(value)
          .startOf('day')
          .valueOf();
        const to = moment(this.endDate)
          .endOf('day')
          .valueOf();

        this.datesFilter = { from, to };
        if (prevValue) {
          this.updateStatistics();
        }
      },

      endDate(value, prevValue) {
        const from = moment(this.startDate)
          .startOf('day')
          .valueOf();
        const to = moment(value)
          .endOf('day')
          .valueOf();

        this.datesFilter = { from, to };
        if (prevValue) {
          this.updateStatistics();
        }
      },
    },

    async mounted() {
      await this.getTooltips();

      this.endDate = moment().format('YYYY-MM-DD');
      this.maxDate = moment().format('YYYY-MM-DD');
      this.startDate = moment()
        .subtract(30, 'd')
        .format('YYYY-MM-DD');

      this.organizationId = this.currentUser.organizationId;
    },

    methods: {
      ...mapActions('stats', ['getTooltipStatsChart', 'downloadStepStats']),

      async getTooltips() {
        if (!this.organizationId) {
          return;
        }

        const params = { organizationId: this.organizationId, limit: 'all' };
        const { response } = await api.tooltips.list({ params });

        this.tooltips = response.payload;
        this.tooltipId = this.tooltips.length && this.tooltips[0].id;
      },

      async updateStatistics() {
        this.playingChart = await this.getTooltipStatsChart({
          tooltipId: this.tooltipId,
          ...this.datesFilter,
        });
      },

      openDownloadDialog() {
        Object.keys(this.dialogDates).forEach(field => {
          this.dialogDates[field] = this[field];
        });

        this.downloadDialogOpened = true;
      },

      async downloadStatistics() {
        if (this.isBusy) {
          return;
        }

        this.isBusy = true;

        try {
          const from = moment(this.dialogDates.startDate)
            .startOf('day')
            .valueOf();
          const to = moment(this.dialogDates.endDate)
            .endOf('day')
            .valueOf();

          const { response } = await api.stats.downloadTooltipStatistics({
            params: { from, to, lang: getLocationForQueries() },
            url_params: { tooltipId: this.tooltipId },
          });

          const tooltip = this.tooltips.find(item => item.id === this.tooltipId);

          saveAs(response, `${tooltip.title || 'tooltipStatistics'}.xlsx`);

          this.downloadDialogOpened = false;
        } finally {
          this.isBusy = false;
        }
      },
    },
  };
</script>

<style scoped></style>
