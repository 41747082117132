<template>
  <div class="tooltip-preview">
    <div class="tooltip-preview__widget-element">
      Button
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce';

  import { jsonStylesToCss, retry } from 'src/utils';

  import basePreview from '../../mixins/basePreview';
  import { THEME_TYPE_CLASSIC } from '../../utils';

  export default {
    name: 'TooltipPreview',

    extends: basePreview,

    data() {
      return {
        isLoaded: false,
        widgetElementClone: null,
      };
    },

    computed: {
      compiledCss() {
        const { copyrightHidden } = this.settings;
        const styles = {};

        if (copyrightHidden) {
          styles['hinted-hint-bottom-link'] = 'display:none;';
        }

        let css = jsonStylesToCss(styles).replace(/;|(([^;])(}))/g, '$2 !important; $3');
        css = css.replace(/hinted-hint-bottom-link/gi, 'hinted-hint-bottom-link > *');

        return css;
      },
    },

    watch: {
      schema: {
        handler() {
          if (!this.isLoaded) {
            return;
          }

          this.updateTooltipPosition();
        },

        deep: true,
      },

      type: {
        immediate: true,
        handler() {
          this.updateThemeClasses();
        },
      },
    },

    async mounted() {
      await this.$loadScript('/hinted-ondemand-widget.min.js');
      await this.showTooltip();
      window.addEventListener('resize', this.onWindowResize.bind(this));
      window.addEventListener('scroll', this.onWindowResize.bind(this));

      this.updateTooltipPosition();

      this.isLoaded = true;
    },

    async beforeDestroy() {
      await this.destroyTooltip();
      window.removeEventListener('resize', this.onWindowResize.bind(this));
      window.removeEventListener('scroll', this.onWindowResize.bind(this));
    },

    methods: {
      onWindowResize: debounce(function update() {
        this.updateTooltipPosition();
      }, 300),

      async showTooltip() {
        const tooltip = {
          api: 2,
          id: 'a6d89c55-8969-480a-b3b4-227ed6fc4574',
          creatorId: 'b2c05057-1e46-4b42-9ab7-33fe1a22327d',
          organizationId: '9d4b204b-162d-482b-bb21-ffd825f15052',
          title: 'Toooltip for moving',
          description: '',
          origin: '*',
          published: false,
          triggers: [],
          steps: [
            {
              title: '',
              description: '',
              selectors: [],
              experimental: {},
              position: 'top',
              onlyHighlight: false,
              isAlwaysVisible: true,
              showDelay: 0,
              selector: '.tooltip-preview__widget-element',
              elementDescription: {
                selectors: ['.tooltip-preview__widget-element'],
              },
              contentJson: {
                time: 1612782184802,
                blocks: [
                  {
                    type: 'header',
                    data: {
                      text: this.$t('pages.styles.loremTitle'),
                      level: 3,
                    },
                  },
                  {
                    type: 'paragraph',
                    data: {
                      text: this.$t('pages.styles.loremText'),
                    },
                  },
                ],
                version: '2.19.1',
              },
              id: '2183d81f-1c73-48b8-9c41-f10821a6fa71',
            },
          ],
          style: 'Default',
          isShowOnLoad: false,
          createdAt: '2021-02-08T11:03:08.223Z',
          updatedAt: '2021-02-08T11:04:33.539Z',
          deletedAt: null,
          useGetParams: false,
        };

        const isLoaded = async () => {
          await this.$nextTick();
          return window.HintedWidget.player.loaded;
        };

        await retry.call(this, isLoaded);
        await this.$nextTick();

        window.HintedWidget.player.showTooltip(tooltip);

        await this.$nextTick();

        this.updateThemeClasses();
      },

      async destroyTooltip() {
        window.HintedWidget.player.removeTooltip();
        window.HintedWidget.player.stop();
      },

      async updateTooltipPosition() {
        await this.$nextTick();

        setTimeout(() => {
          window.HintedWidget.player.tooltips.elements?.forEach(element => {
            element.tooltipDescription.updatePosition();
            element.tooltipIcon.updatePosition();
          });
        }, 300);
      },

      updateThemeClasses() {
        const element = document.querySelector('.hinted-tooltip');
        if (!element) {
          return;
        }

        if (this.type === THEME_TYPE_CLASSIC) {
          element.classList.add('style-classic');
        } else {
          element.classList.remove('style-classic');
        }

        this.updateTooltipPosition();
      },
    },
  };
</script>

<style scoped lang="scss">
  .tooltip-preview {
    height: 700px;
    position: relative;

    &__widget-element {
      position: absolute;
      padding: 6px 10px;
      color: black;
      border: 2px solid black;
      font-family: Roboto, sans-serif;
      text-transform: capitalize;
      top: 50%;
      left: 25%;
      transform: translateY(-50%);
    }
  }
</style>
