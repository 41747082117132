<template>
  <v-card class="mb-5 mx-2">
    <v-card-text class="pa-5">
      <base-table-title>
        {{ $t('pages.statistics.scenarioStepStatistics.sectionTitle') }}:
      </base-table-title>

      <v-data-table
        :headers="headers"
        :items="scenarioStatisticsTableData"
        :loading="isBusy"
        :items-per-page="itemsPerPage"
        class="statistic-table"
        @click:row="handleSelectScenario"
      >
        <template #no-data>
          <travolta-not-found />
        </template>

        <template #item.played="{ item }">
          <base-conversion-bar
            :value="item.played.value"
            :total="item.played.value"
            :percent="item.played.percent"
          />
        </template>

        <template #item.interrupt="{ item }">
          <base-conversion-bar
            :value="item.interrupt.value"
            :total="item.played.value"
            :percent="item.interrupt.percent"
            show-percent
          />
        </template>

        <template #item.stop="{ item }">
          <base-conversion-bar
            :value="item.stop.value"
            :total="item.played.value"
            :percent="item.stop.percent"
            show-percent
          />
        </template>

        <template #item.complete="{ item }">
          <base-conversion-bar
            :value="item.complete.value"
            :total="item.played.value"
            :percent="item.complete.percent"
            show-percent
          />
        </template>

        <template #item.rate="{ item }">
          <v-row v-if="item.rate.hasRate" align="center">
            <v-rating v-model="item.rate.value" size="small" half-increments />

            <span>{{ item.rate.value }}</span>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
  import { mapGetters, mapMutations, mapState } from 'vuex';

  import { STATS_SET_SCENARIO_ID } from 'src/store/mutation-types';

  import BaseTableTitle from 'src/components/BaseTableTitle/BaseTableTitle.vue';
  import BaseConversionBar from 'src/components/BaseConversionBar/BaseConversionBar.vue';
  import TravoltaNotFound from 'src/components/TravoltaNotFound.vue';

  const sortByValue = ({ value }, { value: nextValue }) => value - nextValue;
  const sortByPercent = ({ percent }, { percent: nextPercent }) => percent - nextPercent;

  export default {
    name: 'ScenarioStepsStatisticsTable',

    components: {
      BaseTableTitle,
      BaseConversionBar,
      TravoltaNotFound,
    },

    data() {
      return {
        headers: [
          {
            text: this.$t('pages.statistics.scenarioStepStatistics.scenarioTitle'),
            value: 'title',
            sortable: false,
            width: '30%',
          },
          {
            text: this.$t('pages.statistics.scenarioStepStatistics.played'),
            value: 'played',
            sortable: true,
            sort: sortByValue,
          },
          {
            text: `${this.$t('pages.statistics.scenarioStepStatistics.interrupt')} (%) `,
            value: 'interrupt',
            sortable: true,
            sort: sortByPercent,
          },
          {
            text: `${this.$t('pages.statistics.scenarioStepStatistics.stop')} (%) `,
            value: 'stop',
            sortable: true,
            sort: sortByPercent,
          },
          {
            text: `${this.$t('pages.statistics.scenarioStepStatistics.complete')} (%) `,
            value: 'complete',
            sortable: true,
            sort: sortByPercent,
          },
          {
            text: this.$t('pages.statistics.scenarioStepStatistics.rate'),
            value: 'rate',
            sortable: true,
            sort: sortByValue,
            width: 190,
          },
        ],
        isBusy: false,
        itemsPerPage: 5,
      };
    },
    computed: {
      ...mapGetters('stats', ['scenarioStatisticsPercentTableData']),
      ...mapState('stats', ['scenarioId']),
      ...mapState('scenarios', ['scenariosList']),

      scenarioStatisticsTableData() {
        return this.scenarioStatisticsPercentTableData.map(({ scenarioId, ...restProps }) => {
          const { hasRate = false, avgRate = 0 } =
            this.scenariosList.find(({ id }) => id === scenarioId) || {};
          return { scenarioId, rate: { hasRate, value: avgRate }, ...restProps };
        });
      },
    },

    methods: {
      ...mapMutations('stats', {
        setScenarioId: STATS_SET_SCENARIO_ID,
      }),

      handleSelectScenario({ scenarioId }) {
        this.setScenarioId(scenarioId);
      },
    },
  };
</script>

<style lang="scss">
  .statistic-table {
    tr {
      cursor: pointer;
    }
  }
</style>
