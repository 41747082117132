import moment from 'moment';

moment.updateLocale('ru', {
  monthsShort: [
    'Янв',
    'Февр',
    'Март',
    'Апр',
    'Май',
    'Июнь',
    'Июль',
    'Авг',
    'Сент',
    'Окт',
    'Нояб',
    'Дек',
  ],
});

export default {
  methods: {
    formatDateTime(value) {
      let format = null;

      switch (this.$i18n.locale) {
        case 'ru':
          format = 'D MMM YYYY HH:mm';
          break;
        default:
          format = 'MMM D, YYYY hh:mm A';
          break;
      }

      return moment(value)
        .locale(this.$i18n.locale)
        .format(format)
        .replace(/ /g, '\xa0');
    },

    formatDate(value) {
      let format = null;

      switch (this.$i18n.locale) {
        case 'ru':
          format = 'D MMM YYYY';
          break;
        default:
          format = 'MMM D, YYYY';
          break;
      }

      return moment(value)
        .locale(this.$i18n.locale)
        .format(format)
        .replace(/ /g, '\xa0');
    },
  },
};
