const availability = {
  name: 'availability',
  method: 'get',
  url: 'availability',
};

const list = {
  name: 'list',
  method: 'get',
  url: 'list',
};

const download = {
  name: 'download',
  method: 'post',
  options: {
    responseType: 'blob',
  },
  url: 'download',
};

export default {
  name: 'trace',
  url: '/api/trace/',
  children: [availability, list, download],
};
