<template>
  <div>
    <div>
      <div class="block-header block-header--first">
        {{ $t('pages.styles.settings.modalStyles') }}
      </div>

      <font-select v-model="fontFamily" :label="$t('pages.styles.settings.fontFamily')" />

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="backgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.backgroundColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="color" />
        <div class="ml-4">{{ $t('pages.styles.settings.fontColor') }}</div>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.allSidePaddingModal') }}</div>

        <h-slider v-model="padding" max="30" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="paddingLabel"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.horizontalPadding') }}</div>

        <h-slider v-model="horizontalPadding" max="30" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="horizontalPaddingLabel"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.verticalPadding') }}</div>

        <h-slider v-model="verticalPadding" max="30" min="0" hide-details>
          <template #append>
            <v-text-field
              :value="verticalPaddingLabel"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>

      <template v-if="themeType !== $options.THEME_TYPE_CLASSIC">
        <div class="mb-2">
          <v-switch
            v-model="isBorderEnabled"
            :label="$t('pages.styles.settings.border')"
            hide-details
          />
        </div>

        <template v-if="isBorderEnabled">
          <div class="d-flex align-center mb-3">
            <InlineColorPicker :color.sync="borderColor" />
            <div class="ml-4">{{ $t('pages.styles.settings.borderColor') }}</div>
          </div>

          <div class="input-control mb-3">
            <div>{{ $t('pages.styles.settings.borderWidth') }}</div>

            <h-slider v-model="borderLineWidth" max="10" min="2" hide-details>
              <template #append>
                <v-text-field
                  v-model="borderLineWidth"
                  hide-details
                  dense
                  disabled
                  class="mt-0 pt-0 slider-input"
                />
              </template>
            </h-slider>
          </div>
        </template>
      </template>

      <div class="mb-2">
        <v-switch
          v-model="isShadowEnabled"
          :label="$t('pages.styles.settings.shadow')"
          hide-details
        />
      </div>

      <template v-if="isShadowEnabled">
        <div class="d-flex align-center mb-3">
          <InlineColorPicker :color.sync="shadowColor" />
          <div class="ml-4">{{ $t('pages.styles.settings.shadowColor') }}</div>
        </div>

        <div class="input-control mb-3">
          <div>{{ $t('pages.styles.settings.shadowXOffset') }}</div>

          <h-slider v-model="shadowXOffset" max="50" min="-50" hide-details>
            <template #append>
              <v-text-field
                v-model="shadowXOffset"
                hide-details
                dense
                disabled
                class="mt-0 pt-0 slider-input"
              />
            </template>
          </h-slider>
        </div>

        <div class="input-control mb-3">
          <div>{{ $t('pages.styles.settings.shadowYOffset') }}</div>

          <h-slider v-model="shadowYOffset" max="50" min="-50" hide-details>
            <template #append>
              <v-text-field
                v-model="shadowYOffset"
                hide-details
                dense
                disabled
                class="mt-0 pt-0 slider-input"
              />
            </template>
          </h-slider>
        </div>

        <div class="input-control mb-3">
          <div>{{ $t('pages.styles.settings.shadowBlur') }}</div>

          <h-slider v-model="shadowBlurRadius" max="50" min="0" hide-details>
            <template #append>
              <v-text-field
                v-model="shadowBlurRadius"
                hide-details
                dense
                disabled
                class="mt-0 pt-0 slider-input"
              />
            </template>
          </h-slider>
        </div>

        <div class="input-control mb-3">
          <div>{{ $t('pages.styles.settings.shadowSpreadRadius') }}</div>

          <h-slider v-model="shadowSpreadRadius" max="50" min="0" hide-details>
            <template #append>
              <v-text-field
                v-model="shadowSpreadRadius"
                hide-details
                dense
                disabled
                class="mt-0 pt-0 slider-input"
              />
            </template>
          </h-slider>
        </div>
      </template>

      <div v-if="isEnterprisePlanActive" class="mb-2">
        <v-switch
          v-model="copyrightHidden"
          :label="$t('pages.styles.settings.copyrightHidden')"
          hide-details
        />
      </div>
    </div>

    <div>
      <div class="block-header">
        {{ $t('pages.styles.settings.buttonStyles') }}
      </div>

      <font-select
        v-model="buttonFontFamily"
        :label="$t('pages.styles.settings.buttonFontFamily')"
      />

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="buttonBackgroundColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.buttonBackgroundColor') }}</div>
      </div>

      <div class="d-flex align-center mb-3">
        <InlineColorPicker :color.sync="buttonColor" />
        <div class="ml-4">{{ $t('pages.styles.settings.buttonFontColor') }}</div>
      </div>

      <div class="input-control mb-3">
        <div>{{ $t('pages.styles.settings.buttonBorderRadius') }}</div>

        <h-slider v-model="buttonBorderRadius" max="10" min="0" hide-details>
          <template #append>
            <v-text-field
              v-model="buttonBorderRadius"
              hide-details
              dense
              disabled
              class="mt-0 pt-0 slider-input"
            />
          </template>
        </h-slider>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import InlineColorPicker from 'src/components/InlineColorPicker.vue';
  import HSlider from 'src/HSlider/HSlider.vue';

  import {
    generateDynamicFields,
    hintPositionOptions,
    MIX_VALUE,
    THEME_TYPE_CLASSIC,
  } from 'src/pages/Styles/utils';
  import stylesMixin from 'src/pages/Styles/mixins/stylesMixin';

  import FontSelect from '../FontSelect.vue';

  export default {
    name: 'ModalAdvancedStyles',

    components: {
      InlineColorPicker,
      HSlider,
      FontSelect,
    },

    mixins: [stylesMixin],

    hintPositionOptions,
    THEME_TYPE_CLASSIC,

    computed: {
      ...mapGetters('users', ['isEnterprisePlanActive']),

      ...generateDynamicFields([
        'backgroundColor',
        'borderColor',
        'borderLineWidth',
        'buttonBackgroundColor',
        'buttonBorderRadius',
        'buttonColor',
        'buttonFontFamily',
        'color',
        'fontFamily',
        'isBorderEnabled',
        'isShadowEnabled',
        'shadowBlurRadius',
        'shadowColor',
        'shadowSpreadRadius',
        'shadowXOffset',
        'shadowYOffset',
        'type',
        'frameColor',
        'frameSize',
      ]),

      ...generateDynamicFields(['position', 'copyrightHidden'], 'updateSettings', 'settings'),

      paddingLabel() {
        const { paddingLeft, paddingRight, paddingTop, paddingBottom } = this.value;

        if (
          paddingLeft !== paddingRight ||
          paddingLeft !== paddingTop ||
          paddingLeft !== paddingBottom
        ) {
          return MIX_VALUE;
        }

        return paddingLeft;
      },

      padding: {
        get() {
          return this.paddingLabel === MIX_VALUE ? 0 : this.paddingLabel;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingLeft: value,
            paddingRight: value,
            paddingTop: value,
            paddingBottom: value,
          });
        },
      },

      horizontalPaddingLabel() {
        const { paddingLeft, paddingRight } = this.value;

        if (paddingLeft !== paddingRight) {
          return MIX_VALUE;
        }

        return paddingLeft;
      },

      horizontalPadding: {
        get() {
          return this.horizontalPaddingLabel === MIX_VALUE ? 0 : this.horizontalPaddingLabel;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingLeft: value,
            paddingRight: value,
          });
        },
      },

      verticalPaddingLabel() {
        const { paddingTop, paddingBottom } = this.value;

        if (paddingTop !== paddingBottom) {
          return MIX_VALUE;
        }

        return paddingTop;
      },

      verticalPadding: {
        get() {
          return this.verticalPaddingLabel === MIX_VALUE ? 0 : this.verticalPaddingLabel;
        },

        set(value) {
          this.$emit('update:value', {
            ...this.value,
            paddingTop: value,
            paddingBottom: value,
          });
        },
      },
    },

    watch: {
      type(value) {
        if (value === 'default') {
          this.position = undefined;
        } else {
          this.position = 'element';
        }
      },
    },
  };
</script>
