const list = {
  name: 'list',
  method: 'get',
  url: 'list',
};

const changeBalance = {
  name: 'changeBalance',
  method: 'post',
  url: ':id/change-balance',
};

const getCurrent = {
  name: 'getCurrent',
  method: 'get',
  url: '/api/integrators',
};

const getById = {
  name: 'getById',
  method: 'get',
  url: ':id',
};

const getBalanceHistory = {
  name: 'getBalanceHistory',
  method: 'get',
  url: ':id/balance/history',
};

const getBalanceHistoryUsers = {
  name: 'getBalanceHistoryUsers',
  method: 'get',
  url: ':id/balance/history/users',
};

const getClientsById = {
  name: 'getClientsById',
  method: 'get',
  url: ':id/clients',
};

const getClientById = {
  name: 'getClientById',
  method: 'get',
  url: 'clients/:id',
};

const createClient = {
  name: 'createClient',
  method: 'post',
  url: 'clients',
};

const updateClient = {
  name: 'updateClient',
  method: 'patch',
  url: 'clients/:id',
};

const getClients = {
  name: 'getClients',
  method: 'get',
  url: 'clients',
};

const createClientLicense = {
  name: 'createClientLicense',
  method: 'post',
  url: 'clients/:id/license',
};

const clientLicenseAddMonths = {
  name: 'clientLicenseAddMonths',
  method: 'patch',
  url: 'clients/:id/license/add-months',
};

const clientLicenseAddUsers = {
  name: 'clientLicenseAddUsers',
  method: 'patch',
  url: 'clients/:id/license/add-users',
};

const getClientLicense = {
  name: 'getClientLicense',
  method: 'get',
  url: 'clients/:id/license',
};

const blockClient = {
  name: 'blockClient',
  method: 'patch',
  url: 'clients/:id/block',
};

const unblockClient = {
  name: 'unblockClient',
  method: 'patch',
  url: 'clients/:id/unblock',
};

const getClientUsers = {
  name: 'getClientUsers',
  method: 'get',
  url: 'clients/:id/users',
};

const getCurrentClientLicense = {
  name: 'getCurrentClientLicense',
  method: 'get',
  url: 'clients/license',
};

const getClientSystems = {
  name: 'getClientSystems',
  method: 'get',
  url: 'systems/list',
};

const getClientSystemsStats = {
  name: 'getClientSystemsStats',
  method: 'get',
  url: 'systems/stats',
};

const blockClientSystem = {
  name: 'blockClientSystem',
  method: 'patch',
  url: 'systems/:id/block',
};

const unblockClientSystem = {
  name: 'unblockClientSystem',
  method: 'patch',
  url: 'systems/:id/unblock',
};

const updateClientSystem = {
  name: 'updateClientSystem',
  method: 'patch',
  url: 'systems/:id',
};

const removeClientSystem = {
  name: 'removeClientSystem',
  method: 'delete',
  url: 'systems/:id',
};

export default {
  name: 'integrators',
  url: '/api/integrators',
  children: [
    list,
    changeBalance,
    getCurrent,
    getById,
    getBalanceHistory,
    getBalanceHistoryUsers,
    getClientsById,
    getClientById,
    createClient,
    updateClient,
    getClients,
    createClientLicense,
    getClientLicense,
    getCurrentClientLicense,
    clientLicenseAddMonths,
    clientLicenseAddUsers,
    blockClient,
    unblockClient,
    getClientUsers,
    getClientSystems,
    getClientSystemsStats,
    blockClientSystem,
    unblockClientSystem,
    removeClientSystem,
    updateClientSystem,
  ],
};
