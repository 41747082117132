const getSettings = {
  name: 'getSettings',
  method: 'get',
  url: 'admin-settings',
};

const getPermissions = {
  name: 'getPermissions',
  method: 'get',
  url: 'features-access',
};

export default {
  name: 'adminPanel',
  url: '/api/',
  children: [getSettings, getPermissions],
};
