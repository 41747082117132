<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-dialog v-model="isEditDialogOpened" max-width="709px">
          <organization-edit
            v-if="isEditDialogOpened"
            v-model="editableItemId"
            @close="cancelModal"
            @created="onCreated()"
            @updated="onCreated()"
          />
        </v-dialog>

        <v-dialog
          v-model="isIntegratorDialogOpened"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <integrator-balance-modal
            v-if="isIntegratorDialogOpened"
            :integrator-id="editableItemId"
            @close="isIntegratorDialogOpened = false"
          />
        </v-dialog>

        <v-data-table
          :headers="headers"
          :items="formattedOrganizationsQueryList"
          :options.sync="options"
          :server-items-length="organizationsQueryCount"
          :loading="isBusy"
          :page.sync="page"
        >
          <template #top>
            <v-toolbar flat class="mb-8">
              <v-container fluid class="px-0">
                <v-row align="center">
                  <v-col cols="4">
                    <v-text-field
                      v-model="patternModel"
                      :label="`${$t('pages.organizations.search')}...`"
                      append-icon="mdi-magnify"
                      clearable
                      hide-details
                    />
                  </v-col>

                  <v-spacer />

                  <v-col cols="auto" class="d-flex justify-end">
                    <v-checkbox
                      v-model="trashedItems"
                      :label="$t('common.showTrashed')"
                      class="mt-2 mr-2"
                    />

                    <v-btn color="primary" dark class="text-none" @click="createItem">
                      {{ $t('pages.organizations.create') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-toolbar>
          </template>

          <template #item.name="{item}">
            {{ item.name }}

            <v-chip v-if="item.isIntegrator" color="gray" small label class="ml-2">
              {{ $t('pages.organizations.tableHeaders.integrator') }}
            </v-chip>
          </template>

          <template #item.integratorName="{value}">
            {{ value || '—' }}
          </template>

          <template #item.action="{ item }">
            <v-tooltip v-if="trashedItems" bottom>
              <template #activator="{ on, attrs }">
                <v-icon small v-bind="attrs" @click="restore(item.id)" v-on="on">
                  mdi-delete-restore
                </v-icon>
              </template>
              <span>{{ $t(`common.actions.restore`) }}</span>
            </v-tooltip>

            <template v-else>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn icon small color="info" v-bind="attrs" v-on="on" @click="editItem(item)">
                    <v-icon small>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`common.actions.edit`) }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="openRemoveDialog(item.id)"
                  >
                    <v-icon small>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`common.actions.delete`) }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on" @click="goToOrgUsers(item)">
                    <v-icon small>
                      mdi-account-multiple
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`common.actions.goToOrgUsers`) }}</span>
              </v-tooltip>

              <v-btn v-if="item.isIntegrator" icon small @click="openIntegratorBalanceModal(item)">
                <v-icon small>
                  mdi-license
                </v-icon>
              </v-btn>
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="removeDialogOpened" max-width="500">
      <remove-item-modal
        v-if="removeDialogOpened"
        :title="$t('pages.organizations.removeModalTitle')"
        :text="$t('pages.organizations.removeModalText')"
        :remove-callback="removeCallback"
        @close="removeDialogOpened = false"
        @removed="onRemoved()"
      />
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import debounce from 'lodash.debounce';

  import api from 'src/api';

  import OrganizationEdit from 'src/components/OrganizationEdit.vue';
  import IntegratorBalanceModal from 'src/components/IntegratorBalanceModal/IntegratorBalanceModal.vue';
  import RemoveItemModal from 'src/components/RemoveItemModal/RemoveItemModal.vue';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  export default {
    name: 'Organizations',

    components: {
      OrganizationEdit,
      IntegratorBalanceModal,
      RemoveItemModal,
    },

    mixins: [formatTimestampMixin],

    data() {
      return {
        options: {},
        isBusy: false,
        limit: 10,
        page: 1,
        pattern: undefined,
        filterBy: undefined,
        filterDirection: undefined,
        editableItemId: null,
        isEditDialogOpened: false,

        isIntegratorDialogOpened: false,

        removeDialogOpened: false,
        removeCallback: () => {},

        trashedItems: false,
      };
    },

    computed: {
      ...mapState('organizations', ['organizationsQueryList', 'organizationsQueryCount']),
      ...mapState('settings', ['isCloud']),

      headers() {
        return [
          { text: this.$t('pages.organizations.tableHeaders.title'), value: 'name' },
          { text: this.$t('pages.organizations.tableHeaders.site'), value: 'site' },
          { text: this.$t('pages.organizations.tableHeaders.creator'), value: 'creatorEmail' },
          { text: this.$t('common.fields.created'), value: 'createdAt' },
          ...(this.isCloud
            ? [
                {
                  text: this.$t('pages.organizations.tableHeaders.integrator'),
                  value: 'integratorName',
                },
              ]
            : []),
          {
            text: this.$t('ui.common.actions'),
            value: 'action',
            width: '150px',
            sortable: false,
            align: 'end',
          },
        ];
      },

      patternModel: {
        get() {
          return this.pattern;
        },

        set(value) {
          this.debouncedSearch(value);
        },
      },

      formattedOrganizationsQueryList() {
        return this.organizationsQueryList.map(organization => {
          const createdAt = this.formatDateTime(organization.createdAt);
          return {
            ...organization,
            createdAt,
          };
        });
      },
    },

    watch: {
      trashedItems() {
        this.page = 1;
        this.update();
      },

      options: {
        handler(value) {
          this.setValues(value);
        },

        deep: true,
      },
    },

    methods: {
      ...mapActions('organizations', {
        getListWithParams: 'getListWithParams',
      }),

      debouncedSearch: debounce(function search(pattern) {
        this.pattern = pattern || undefined;
        this.resetValues();
        this.update();
      }, 300),

      async update() {
        if (this.isBusy) {
          return;
        }

        this.isBusy = true;

        try {
          await this.getListWithParams({
            page: this.page,
            limit: this.limit,
            filterBy: this.filterBy,
            filterDirection: this.filterDirection,
            pattern: this.pattern,
            trashedItems: this.trashedItems,
          });
        } catch (err) {
          console.error(err);
        } finally {
          this.isBusy = false;
        }
      },

      setValues(obj) {
        const {
          itemsPerPage,
          page,
          sortBy: [filterBy],
          sortDesc: [isDesc],
        } = obj;

        this.limit = itemsPerPage === -1 ? 'all' : itemsPerPage;
        this.page = page;
        this.filterBy = filterBy;
        this.filterDirection = (isDesc && 'desc') || 'asc';

        this.update();
      },

      resetValues() {
        this.limit = 10;
        this.page = 1;
        this.filterBy = undefined;
        this.filterDirection = undefined;
      },

      openModal() {
        this.isEditDialogOpened = true;
      },

      closeModal() {
        this.isEditDialogOpened = false;
      },

      cancelModal() {
        this.editableItemId = null;
        this.closeModal();
      },

      onCreated() {
        this.cancelModal();

        this.update();
      },

      editItem(item) {
        this.editableItemId = item.id;
        this.openModal();
      },

      createItem() {
        this.editableItemId = null;
        this.openModal();
      },

      goToOrgUsers(data) {
        this.$router.push({ name: 'users', query: { orgId: data.id } });
      },

      openIntegratorBalanceModal(item) {
        this.editableItemId = item.id;
        this.isIntegratorDialogOpened = true;
      },

      openRemoveDialog(id) {
        this.removeCallback = () => api.organizations.remove({ url_params: { id } });
        this.removeDialogOpened = true;
      },

      onRemoved() {
        if (this.organizationsQueryList.length === 1 && this.page !== 1) {
          this.page -= 1;
        }

        this.update();
      },

      async restore(id) {
        await api.organizations.restore({ url_params: { id } });

        if (this.organizationsQueryList.length === 1 && this.page !== 1) {
          this.page -= 1;
        }

        this.update();
      },
    },
  };
</script>
