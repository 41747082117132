<template>
  <v-card class="integrator-balance-modal">
    <v-card-title class="pa-4 primary">
      <span class="ma-1 title white--text">{{ $t('ui.blockSystemModal.title') }}?</span>

      <v-spacer />

      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pt-6">
      <span class="black--text subtitle-1"> {{ $t('ui.blockSystemModal.text') }}. </span>
    </v-card-text>

    <v-card-actions>
      <v-spacer />

      <v-btn color="grey" text @click="$emit('close')">
        {{ $t('ui.common.cancel') }}
      </v-btn>

      <v-btn :loading="isBusy" color="primary" @click="block()">
        {{ $t('ui.blockSystemModal.blockButton') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import api from 'src/api';

  import { busyFlow } from 'src/utils';

  export default {
    name: 'BlockSystemModal',

    props: {
      systemId: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        isBusy: false,
      };
    },

    methods: {
      block() {
        busyFlow.call(this, async () => {
          await api.integrators.blockClientSystem({ url_params: { id: this.systemId } });

          this.$emit('updated');
          this.$emit('close');
        });
      },
    },
  };
</script>
