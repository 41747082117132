<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-for="(card, index) in statCardsData"
        :key="index"
        cols="12"
        lg="3"
        sm="3"
        class="py-0"
      >
        <v-card>
          <v-card-text class="pa-5">
            <div class="d-flex align-center">
              <span class="lstick" :class="`lstick--${card.stickColor || card.color}`" />
              <v-icon size="30" :color="card.color">
                {{ card.icon }}
              </v-icon>

              <div class="ml-4 mr-1">
                <h5 class="subtitle-1 grey--text">
                  {{ card.title }}
                </h5>

                <h2
                  class="font-weight-regular"
                  :class="[card.textClasses ? card.textClasses : `${card.color}--text`]"
                >
                  {{ card.value }}
                </h2>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12">
        <v-card flat>
          <v-card-text class="d-flex align-center pa-5 pt-3">
            <span class="lstick" />

            <v-text-field
              v-model="debouncedPattern"
              hide-details
              append-icon="mdi-magnify"
              :label="$t('pages.integratorClientSystems.search')"
              class="flex-grow-0"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="system in systems" :key="system.id" cols="6" md="4" xl="3">
        <system-card :system="system" @updated="onUpdateSystem()" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import debounce from 'lodash.debounce';

  import api from 'src/api';

  import SystemCard from './components/SystemCard/SystemCard.vue';

  export default {
    name: 'IntegratorClientSystems',

    components: {
      SystemCard,
    },

    data() {
      return {
        stats: {
          activeSystemsCount: '-',
          blockedSystemCount: '-',
          systemsCount: '-',
          workplacesCount: '-',
        },

        licenseUsersCount: '-',

        systems: [],

        pattern: '',
      };
    },

    computed: {
      statCardsData() {
        return [
          {
            color: 'info',
            icon: 'mdi-table',
            title: this.$t('pages.integratorClientSystems.allSystemsCount'),
            value: this.stats.systemsCount,
          },
          {
            color: 'green',
            icon: 'mdi-play-outline',
            title: this.$t('pages.integratorClientSystems.activeSystemsCount'),
            value: this.stats.activeSystemsCount,
          },
          {
            color: 'red',
            icon: 'mdi-lock-outline',
            title: this.$t('pages.integratorClientSystems.blockedSystemsCount'),
            value: this.stats.blockedSystemCount,
          },
          {
            color: 'grey darken-4',
            textClasses: 'grey--text text--darken-4',
            stickColor: 'black',
            icon: 'mdi-account-multiple-outline',
            title: this.$t('pages.integratorClientSystems.workplacesCount'),
            value: `${this.stats.workplacesCount} / ${this.licenseUsersCount}`,
          },
        ];
      },

      debouncedPattern: {
        get() {
          return this.pattern;
        },

        set(value) {
          this.debouncedSetPattern(value);
        },
      },
    },

    watch: {
      pattern() {
        this.getSystems();
      },
    },

    mounted() {
      this.getStats();
      this.getLicense();
      this.getSystems();
    },

    methods: {
      onUpdateSystem() {
        this.getStats();
        this.getSystems();
      },

      async getStats() {
        const { response } = await api.integrators.getClientSystemsStats();

        this.stats = response;
      },

      async getLicense() {
        const { response } = await api.integrators.getCurrentClientLicense();

        this.licenseUsersCount = response.usersCount;
      },

      async getSystems() {
        const { response } = await api.integrators.getClientSystems({
          params: {
            ...(this.pattern ? { pattern: this.pattern } : {}),
          },
        });

        this.systems = Object.freeze(response);
      },

      debouncedSetPattern: debounce(function setPattern(pattern) {
        this.pattern = pattern;
      }, 300),
    },
  };
</script>

<style lang="scss"></style>
