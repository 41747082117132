<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="params"
          :server-items-length="serverItemsCount"
          :loading="isBusy"
        >
          <template #no-data>
            <travolta-not-found />
          </template>

          <template #top>
            <v-toolbar flat class="mb-8">
              <v-container fluid class="px-0">
                <v-row align="center">
                  <v-col cols="4">
                    <v-text-field
                      v-model="debouncedPattern"
                      :label="`${$t('pages.links.search')}...`"
                      append-icon="mdi-magnify"
                      clearable
                      hide-details
                    />
                  </v-col>

                  <v-spacer />

                  <v-col cols="auto" class="d-flex">
                    <v-checkbox
                      v-if="isModeratorOrHigher"
                      v-model="trashedItems"
                      :label="$t('common.showTrashed')"
                      class="mt-2 mr-2"
                    />

                    <v-btn color="primary" dark class="text-none" @click="openEditModal()">
                      {{ $t('pages.links.create') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-toolbar>
          </template>

          <template #item.num="{ index }">
            <TableRowNumber :server-page="serverPage" :server-limit="serverLimit" :index="index" />
          </template>

          <template #item.url="{value}">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <div class="truncate" v-bind="attrs" v-on="on">{{ value }}</div>
              </template>
              <div>{{ value }}</div>
            </v-tooltip>
          </template>

          <template #item.creatorEmail="{value}">
            {{ value }}
          </template>

          <template #item.createdAt="{value}">
            {{ formatDateTime(value) }}
          </template>

          <template #item.updatedAt="{value}">
            {{ formatDateTime(value) }}
          </template>

          <template #item.action="{ item }">
            <v-tooltip v-if="trashedItems" bottom>
              <template #activator="{ on, attrs }">
                <v-icon small v-bind="attrs" @click="restore(item.id)" v-on="on">
                  mdi-delete-restore
                </v-icon>
              </template>
              <span>{{ $t(`common.actions.restore`) }}</span>
            </v-tooltip>

            <template v-else>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="info"
                    v-bind="attrs"
                    @click="openEditModal(item)"
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`common.actions.edit`) }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="openRemoveDialog(item.id)"
                  >
                    <v-icon small>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t(`common.actions.delete`) }}</span>
              </v-tooltip>

              <v-menu offset-y left>
                <template #activator="{ on, attrs }">
                  <v-btn small icon v-bind="attrs" v-on="on">
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item @click="copyLinkURL(item)">
                    <v-list-item-icon class="mr-0">
                      <v-icon small>mdi-link-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('pages.links.copyLink') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="isAdmin && isEnterprisePlanActive"
                    @click="openWorkspaceMigrationDialog([item])"
                  >
                    <v-list-item-icon class="mr-0">
                      <v-icon small>mdi-folder-swap-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('ui.workspaceEntityMigrateModal.buttonText') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="editModalOpened" max-width="1039px">
      <link-edit-modal
        v-if="editModalOpened"
        :editing-link="editingItem"
        @created="onChangeChecklist(true)"
        @updated="onChangeChecklist()"
        @close="closeEditModal()"
      />
    </v-dialog>

    <v-dialog v-model="workspaceMigratingDialogOpened" max-width="450px">
      <workspace-entity-migrate-modal
        v-if="workspaceMigratingDialogOpened"
        type="links"
        :list="workspaceMigratingIds"
        @changed="getList()"
        @close="closeWorkspaceModal()"
      />
    </v-dialog>

    <v-snackbar
      v-model="changeToastOpened"
      :color="createdLink ? 'success' : 'default'"
      timeout="3000"
    >
      {{ $t(`pages.links.${createdLink ? 'createToast' : 'updateToast'}`) }}

      <template #action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="changeToastOpened = false">
          {{ $t('ui.common.close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="removeToastOpened" color="red darken-2" timeout="3000">
      {{ $t('pages.links.removeToast') }}

      <template #action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="removeToastOpened = false">
          {{ $t('ui.common.close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="removeDialogOpened" max-width="500">
      <remove-item-modal
        v-if="removeDialogOpened"
        :title="$t('pages.links.removeModalTitle')"
        :text="$t('pages.links.removeModalText')"
        :remove-callback="removeCallback"
        @close="removeDialogOpened = false"
        @removed="onRemoved()"
      />
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import debounce from 'lodash.debounce';

  import api from 'src/api';

  import { busyFlow, copyText } from 'src/utils';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  import WorkspaceEntityMigrateModal from 'src/components/WorkspaceEntityMigrateModal/WorkspaceEntityMigrateModal.vue';
  import RemoveItemModal from 'src/components/RemoveItemModal/RemoveItemModal.vue';

  import TableRowNumber from 'src/components/commonComponents/TableRowNumber.vue';
  import TravoltaNotFound from 'src/components/TravoltaNotFound.vue';
  import LinkEditModal from './components/LinkEditModal.vue';

  const defaultParams = {
    itemsPerPage: 10,
    page: 1,
    pattern: null,
    sortBy: ['createdAt'],
    sortDesc: [true],
  };

  export default {
    name: 'Links',

    components: {
      WorkspaceEntityMigrateModal,
      RemoveItemModal,
      LinkEditModal,
      TableRowNumber,
      TravoltaNotFound,
    },

    mixins: [formatTimestampMixin],

    data() {
      return {
        isBusy: false,
        editModalOpened: false,
        activeScenariosManagingStep: false,
        changeToastOpened: false,
        removeToastOpened: false,
        createdLink: false,
        editingItem: null,
        items: [],
        serverItemsCount: 0,
        serverPage: 1,
        serverLimit: 1,

        params: { ...defaultParams },
        trashedItems: false,

        workspaceMigratingDialogOpened: false,
        workspaceMigratingIds: [],

        removeDialogOpened: false,
        removeCallback: () => {},
      };
    },

    computed: {
      ...mapGetters('users', ['isModeratorOrHigher', 'isAdmin', 'isEnterprisePlanActive']),

      tableHeaders() {
        return [
          {
            value: 'num',
            text: '#',
            sortable: false,
            align: 'center',
            width: '40px',
          },
          {
            text: this.$t('pages.links.tableHeaders.title'),
            value: 'title',
            sortable: false,
          },
          {
            text: this.$t('pages.links.tableHeaders.url'),
            value: 'url',
            sortable: false,
            class: 'truncate',
          },
          {
            text: this.$t('pages.links.tableHeaders.creatorEmail'),
            value: 'creatorEmail',
            sortable: false,
          },
          {
            text: this.$t('common.fields.created'),
            value: 'createdAt',
            width: '150px',
          },
          {
            text: this.$t('ui.common.actions'),
            value: 'action',
            width: '120px',
            align: 'center',
            sortable: false,
          },
        ];
      },

      debouncedPattern: {
        get() {
          return this.params.pattern;
        },

        set(value) {
          this.debouncedSetPattern(value);
        },
      },

      mappedParams() {
        const {
          itemsPerPage,
          page = 1,
          pattern,
          sortBy: [filterBy],
          sortDesc: [isDesc],
        } = this.params;

        const limit = itemsPerPage === -1 ? 'all' : itemsPerPage;
        const filterDirection = isDesc ? 'desc' : 'asc';

        return {
          page,
          limit,
          trashedItems: this.trashedItems,
          ...(pattern ? { pattern } : {}),
          ...(filterBy ? { filter_by: filterBy, filter_direction: filterDirection } : {}),
        };
      },
    },

    watch: {
      mappedParams: {
        handler() {
          this.getList();
        },
        immediate: true,
      },

      editModalOpened(value) {
        if (!value) {
          this.editingItem = null;
          this.getList();
        }
      },
    },

    methods: {
      debouncedSetPattern: debounce(function setPattern(pattern) {
        this.params = {
          ...defaultParams,
          sortBy: this.params.sortBy,
          itemsPerPage: this.params.itemsPerPage,
          sortDesc: this.params.sortDesc,
          pattern,
        };
      }, 300),

      getList() {
        busyFlow.call(this, async () => {
          const { response } = await api.links.list({
            params: { ...this.mappedParams },
          });

          this.serverItemsCount = response.count;
          this.serverLimit = this.mappedParams.limit;
          this.serverPage = this.mappedParams.page;
          this.items = response.payload;
        });
      },

      openEditModal(editingLink = null) {
        this.editingItem = editingLink ? { ...editingLink } : editingLink;
        this.editModalOpened = true;
      },

      closeEditModal() {
        this.editModalOpened = false;
      },

      onChangeChecklist(isCreated = false) {
        this.createdLink = isCreated;
        this.changeToastOpened = true;
        this.getList();
      },

      openRemoveDialog(id) {
        this.removeCallback = () => api.links.remove({ url_params: { id } });
        this.removeDialogOpened = true;
      },

      async onRemoved() {
        this.removeToastOpened = true;

        if (this.items.length === 1 && this.params.page !== 1) {
          this.params.page -= 1;
        } else {
          this.getList();
        }
      },

      async restore(id) {
        await api.links.restore({ url_params: { id } });

        if (this.items.length === 1 && this.params.page !== 1) {
          this.params.page -= 1;
        } else {
          this.getList();
        }
      },

      copyLinkURL(item) {
        copyText(item.url);
      },

      openWorkspaceMigrationDialog(links) {
        this.workspaceMigratingIds = links.map(link => link.id);
        this.workspaceMigratingDialogOpened = true;
      },

      closeWorkspaceModal() {
        this.workspaceMigratingDialogOpened = false;
        this.workspaceMigratingIds = [];
      },
    },
  };
</script>

<style lang="scss">
  .truncate {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
