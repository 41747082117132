const update = {
  name: 'update',
  method: 'post',
  url: 'update',
};

const uploadByUrl = {
  name: 'uploadByUrl',
  method: 'post',
  url: 'by-url',
};

const uploadFile = {
  name: 'uploadFile',
  method: 'post',
  url: '/api/upload',
};

export default {
  name: 'upload',
  url: '/api/upload',
  children: [update, uploadByUrl, uploadFile],
};
