<template>
  <div>
    <create-many-button @open="createManyDialogOpened = true" />
    <v-dialog v-model="createManyDialogOpened" max-width="800">
      <create-many-dialog
        v-if="createManyDialogOpened"
        @close="createManyDialogOpened = false"
        @created="onCreatedUsers()"
      />
    </v-dialog>
    <toast
      :opened="showedCreatedUsersToast"
      :color="toastColor"
      @close="showedCreatedUsersToast = false"
    >
      {{ toastMessage }}
    </toast>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { LOAD_SUCCESS } from 'src/constants';
  import Toast from 'src/components/Toast/Toast.vue';
  import CreateManyDialog from 'src/pages/Users/components/CreateManyUsers/CreateManyDialog.vue';
  import CreateManyButton from 'src/pages/Users/components/CreateManyUsers/CreateManyButton.vue';

  export default {
    name: 'CreateManyUsers',

    components: {
      CreateManyButton,
      CreateManyDialog,
      Toast,
    },

    data() {
      return {
        createManyDialogOpened: false,
        showedCreatedUsersToast: false,
      };
    },

    computed: mapState({
      toastColor: state =>
        state.users.createManyUsersStatus === LOAD_SUCCESS ? 'success' : 'error',

      toastMessage(state) {
        return state.users.createManyUsersStatus === LOAD_SUCCESS
          ? this.$t('pages.users.createManyModal.successToast')
          : this.$t('pages.users.createManyModal.failedToast');
      },
    }),

    methods: {
      onCreatedUsers() {
        this.createManyDialogOpened = false;
        this.showedCreatedUsersToast = true;

        this.$emit('update');
      },
    },
  };
</script>
