import Apipie from 'apipie';
import axios from 'axios';

import * as config from 'src/config';
import invites from 'src/api/invites';
import stats from 'src/api/stats';
import groups from 'src/api/groups';
import elementStyle from 'src/api/elementStyle';
import upload from 'src/api/upload';
import legal from 'src/api/legal';
import trace from 'src/api/trace';
import payments from 'src/api/payments';

import store from 'src/store';
import router from 'src/router';

import {
  getTokenForAxios,
  handleResponseError,
  headerAuthorization,
  isAuth,
} from 'src/services/session';

import { paramsSerializer } from 'src/utils';
import localStorage from 'src/services/localStorage';

import adminPanel from './adminPanel';
import auth from './auth';
import users from './users';
import accounts from './accounts';
import organizations from './organizations';
import scenarios from './scenarios';
import tooltips from './tooltips';
import simulations from './simulations';
import checklists from './checklists';
import workspaces from './workspaces';
import desktopScenarios from './desktopScenarios';
import desktopTooltips from './desktopTooltips';
import tags from './tags';
import events from './events';
import links from './links';
import integrators from './integrators';
import statistics from './statistics';
import v2statistics from './v2statistics';
import clientSessions from './clientSessions';

let isRefreshing = false;
let subscribers = [];

const onRefreshingComplete = (rejected = false) => {
  subscribers.forEach(callback => callback(getTokenForAxios(), rejected));
  subscribers = [];
};

const addSubscriber = callback => {
  subscribers.push(callback);
};

const routes = [
  adminPanel,
  auth,
  users,
  accounts,
  organizations,
  scenarios,
  tooltips,
  checklists,
  invites,
  stats,
  groups,
  elementStyle,
  upload,
  legal,
  trace,
  simulations,
  workspaces,
  desktopScenarios,
  desktopTooltips,
  tags,
  events,
  links,
  integrators,
  payments,
  statistics,
  v2statistics,
  clientSessions,
];

axios.defaults.baseURL = config.apiUrl;
axios.defaults.validateStatus = () => true;
axios.interceptors.response.use(async response => {
  const originalRequest = response.config;

  if ([402, 426].includes(response.status) && window.location.pathname !== '/expiration') {
    localStorage.set('supportEmail', response.data.details?.supportEmail || null);
    window.location = `/expiration?redirected=true&isDemo=${response.status === 426}`;
  }

  if (originalRequest.url === '/api/oauth/refresh' && response.status !== 200) {
    isRefreshing = false;

    await store.dispatch('auth/clearData');
    await store.dispatch('auth/setAuthState');

    if (router.currentRoute.name !== 'login') {
      router.push({ name: 'login' });
    }

    onRefreshingComplete(true);

    return Promise.reject();
  }

  if (
    response.status === 401 &&
    !['/api/oauth/authorize', '/api/oauth/integrated', 'api/oauth/transition/authorize'].includes(
      originalRequest.url
    ) &&
    router.currentRoute.name !== 'login'
  ) {
    const promise = new Promise((resolve, reject) => {
      addSubscriber((token, rejected) => {
        originalRequest.headers[headerAuthorization] = token;

        if (!rejected) {
          resolve(axios(originalRequest));
        } else {
          reject(new Error('Unknown refresh tokens error'));
        }
      });
    });

    if (!isRefreshing) {
      isRefreshing = true;

      await store.dispatch('auth/refresh');

      isRefreshing = false;

      onRefreshingComplete();
    }

    return promise;
  }

  if (response.data.status && response.data.status === 'error') {
    throw response.data;
  }

  if (response.data) {
    return response.data;
  }

  return response;
}, handleResponseError);

const apipie = new Apipie(routes, { axios });

const checkAuth = async (ctx, next) => {
  const context = ctx;

  if (isAuth()) {
    context.options.headers = {
      ...context.options.headers,
      [headerAuthorization]: getTokenForAxios(),
    };
  }

  await next();
};

apipie.globalHook(checkAuth);
apipie.options = { paramsSerializer };
const api = apipie.create();

export default api;
