<template>
  <v-navigation-drawer v-model="showedPanel" right app temporary width="508">
    <notifications-settings v-if="showedNotificationSettings" />

    <notifications-list v-else />
  </v-navigation-drawer>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';

  import {
    SET_SHOWED_NOTIFICATIONS_SETTINGS,
    SET_SHOWED_NOTIFICATIONS_PANEL,
  } from 'src/store/mutation-types';

  import NotificationsList from './NotificationsList.vue';
  import NotificationsSettings from './NotificationsSettings.vue';

  export default {
    name: 'NotificationsPanel',

    components: {
      NotificationsList,
      NotificationsSettings,
    },

    computed: {
      ...mapState('notifications', ['showedNotificationSettings', 'showedNotificationsPanel']),

      showedPanel: {
        get() {
          return this.showedNotificationsPanel;
        },
        set(value) {
          this.setShowedPanel(value);
        },
      },
    },

    watch: {
      showedPanel(value) {
        if (!value) {
          this.setShowedSettings(false);
        }
      },
    },

    methods: {
      ...mapMutations('notifications', {
        setShowedSettings: SET_SHOWED_NOTIFICATIONS_SETTINGS,
        setShowedPanel: SET_SHOWED_NOTIFICATIONS_PANEL,
      }),
    },
  };
</script>
