var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"options":_vm.params,"server-items-length":_vm.serverItemsCount,"loading":_vm.isBusy},on:{"update:options":function($event){_vm.params=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-8",attrs:{"flat":"","height":"60"}},[_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"4","lg":"3"}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"label":((_vm.$t((_vm.localeRootPath + ".search"))) + "..."),"append-icon":"mdi-magnify","clearable":"","hide-details":""},model:{value:(_vm.debouncedPattern),callback:function ($$v) {_vm.debouncedPattern=$$v},expression:"debouncedPattern"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"4"}},[(_vm.isModeratorOrHigher)?_c('v-checkbox',{staticClass:"mt-2 mr-2",attrs:{"label":_vm.$t('common.showTrashed')},model:{value:(_vm.trashedItems),callback:function ($$v) {_vm.trashedItems=$$v},expression:"trashedItems"}}):_vm._e()],1)],1)],1)],1)]},proxy:true},{key:"item.num",fn:function(ref){
var index = ref.index;
return [_c('TableRowNumber',{attrs:{"server-limit":_vm.serverLimit,"server-page":_vm.serverPage,"index":index}})]}},{key:"item.steps",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item[_vm.tooltipsMode ? 'items' : 'steps'] ? item[_vm.tooltipsMode ? 'items' : 'steps'].length : 0)+" ")]}},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(value))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.trashedItems)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.restoreItem(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete-restore ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.restore")))])]):(item.isEditable)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":"","small":""},on:{"click":function () {
                    _vm.openEditDialog(item);
                    _vm.sendMetrics('desktop_walkthroughs_edit_button', 'click');
                  }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.edit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":"","small":""},on:{"click":function () {
                    _vm.openRemoveDialog(item.id);
                    _vm.sendMetrics('desktop_walkthroughs_delete_button', 'click');
                  }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.delete")))])]),(_vm.isAdmin && _vm.isEnterprisePlanActive)?_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.openWorkspaceMigrationDialog([item])}}},[_c('v-list-item-icon',{staticClass:"mr-0"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-folder-swap-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('ui.workspaceEntityMigrateModal.buttonText'))+" ")])],1)],1)],1)],1):_vm._e()]:_vm._e()]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.removeDialogOpened),callback:function ($$v) {_vm.removeDialogOpened=$$v},expression:"removeDialogOpened"}},[(_vm.removeDialogOpened)?_c('remove-item-modal',{attrs:{"title":_vm.$t((_vm.localeRootPath + ".removeModalTitle")),"text":_vm.$t((_vm.localeRootPath + ".removeModalText")),"remove-callback":_vm.removeCallback},on:{"close":function($event){_vm.removeDialogOpened = false},"removed":function($event){return _vm.onRemoved()}}}):_vm._e()],1),_c('desktop-entity-edit-form',{attrs:{"editing-entity":_vm.editingEntity,"tooltips-mode":_vm.tooltipsMode},on:{"close":function($event){return _vm.clearEditingItem()},"updated":function($event){return _vm.onUpdated()}}}),_c('v-dialog',{attrs:{"max-width":"450px"},model:{value:(_vm.workspaceMigratingDialogOpened),callback:function ($$v) {_vm.workspaceMigratingDialogOpened=$$v},expression:"workspaceMigratingDialogOpened"}},[(_vm.workspaceMigratingDialogOpened)?_c('workspace-entity-migrate-modal',{attrs:{"type":_vm.tooltipsMode ? 'desktopTooltips' : 'desktopScenarios',"list":_vm.workspaceMigratingIds},on:{"changed":function($event){return _vm.getList()},"close":function($event){return _vm.closeWorkspaceModal()}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }