const list = {
  name: 'list',
  method: 'get',
  url: 'me',
};

const listByOrgId = {
  name: 'listByOrgId',
  method: 'get',
  url: 'organization/:orgId',
};

const create = {
  name: 'create',
  method: 'post',
  url: '/api/workspaces',
};

const createByOrgId = {
  name: 'createByOrgId',
  method: 'post',
  url: 'organization/:orgId',
};

const update = {
  name: 'update',
  method: 'patch',
  url: ':id',
};

const remove = {
  name: 'remove',
  method: 'patch',
  url: ':id/delete',
};

const addUser = {
  name: 'addUser',
  method: 'post',
  url: ':id/add-user',
};

const removeUser = {
  name: 'removeUser',
  method: 'post',
  url: ':id/remove-user',
};

const select = {
  name: 'select',
  method: 'post',
  url: ':id/select',
};

const usersList = {
  name: 'usersList',
  method: 'get',
  url: ':id/users',
};

const adminsList = {
  name: 'adminsList',
  method: 'get',
  url: ':id/admins',
};

export default {
  name: 'workspaces',
  url: '/api/workspaces/',
  children: [
    list,
    listByOrgId,
    create,
    createByOrgId,
    update,
    remove,
    addUser,
    removeUser,
    select,
    usersList,
    adminsList,
  ],
};
