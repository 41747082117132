<template>
  <v-app>
    <v-container class="fill-height justify-center">
      <v-progress-linear indeterminate active />
    </v-container>
  </v-app>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    name: 'AuthRedirect',

    mounted() {
      this.auth();
    },

    methods: {
      ...mapActions('auth', ['authTransition']),

      async auth() {
        await this.authTransition({ token: this.$route.query.token, app: 'ADMIN_PANEL' });

        this.$router.push(
          this.$route.query.next
            ? { path: decodeURIComponent(String(this.$route.query.next)) }
            : { name: 'Dashboard' }
        );
      },
    },
  };
</script>
