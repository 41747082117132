<template>
  <v-card class="link-edit-modal">
    <v-form ref="form" @submit.prevent="submit()">
      <v-card-title class="pb-0">
        <span class="headline">
          {{
            $t('pages.accounts.accountChangePasswordModal.title', { email: editingAccount.email })
          }}
        </span>

        <v-spacer />

        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-6">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="password"
                :rules="[validation.getRequiredRule, validation.getPasswordLengthRule]"
                :append-icon="showedPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showedPassword ? 'text' : 'password'"
                :label="$t('pages.accounts.accountChangePasswordModal.password')"
                hide-details="auto"
                outlined
                @click:append="showedPassword = !showedPassword"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="repeatPassword"
                :rules="[
                  validation.getRequiredRule,
                  validation.getPasswordLengthRule,
                  validation.getRepeatPasswordRule,
                ]"
                :append-icon="showedPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showedPassword ? 'text' : 'password'"
                :label="$t('pages.accounts.accountChangePasswordModal.repeatPassword')"
                hide-details="auto"
                outlined
                @click:append="showedPassword = !showedPassword"
              />
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="isPasswordChangeNeeded"
                :label="$t('pages.accounts.accountChangePasswordModal.firstLoginChangePassword')"
                hide-details
                class="mt-3 mb-0 pt-0"
              />
            </v-col>
          </v-row>

          <v-alert v-if="serverError" color="red" type="error" class="mt-5 mb-0">
            {{ serverError }}
          </v-alert>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="primary" text @click="close()">
          {{ $t('ui.common.cancel') }}
        </v-btn>

        <v-btn :disabled="isBusy" :loading="isBusy" color="primary" type="submit">
          {{ $t('pages.accounts.accountChangePasswordModal.updateButton') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import api from 'src/api';

  export default {
    name: 'AccountChangePasswordModal',

    props: {
      editingAccount: {
        type: Object,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        showedPassword: false,

        password: '',
        repeatPassword: '',
        isPasswordChangeNeeded: true,

        isBusy: false,

        serverError: null,
        validation: {
          getRequiredRule: v => (!!v && !!v.trim()) || this.$t('validation.notNull'),
          getPasswordLengthRule: v => v.length >= 6 || this.$t('validation.passwordLength'),
          getRepeatPasswordRule: v => v === this.password || this.$t('validation.passwordsDiff'),
        },
      };
    },

    methods: {
      async submit() {
        if (this.isBusy || !this.$refs.form.validate()) {
          return;
        }

        this.serverError = null;

        const payload = {
          password: this.password,
          isPasswordChangeNeeded: this.isPasswordChangeNeeded,
        };

        try {
          await api.accounts.update({ url_params: { id: this.editingAccount.id }, data: payload });

          this.$emit('update');
          this.close();
        } catch (error) {
          this.serverError = this.$te(`serverCode.${error.code}`)
            ? this.$t(`serverCode.${error.code}`)
            : this.$t('serverCode.serverError');
        } finally {
          this.isBusy = false;
        }
      },

      close() {
        this.$emit('close');
      },
    },
  };
</script>
