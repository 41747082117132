import Vue from 'vue';
import Router from 'vue-router';
import { isAuth, isAuthing, waitInitialization } from 'src/services/session';
import MainLayout from 'src/pages/layouts/Main.vue';
import Login from 'src/pages/Login.vue';
import Users from 'src/pages/Users/Users.vue';
import Scenarios from 'src/pages/Scenarios/Scenarios.vue';
import Tooltips from 'src/pages/Tooltips/Tooltips.vue';
import AcceptInvite from 'src/pages/AcceptInvite.vue';
import Dashboard from 'src/pages/Dashboard/Dashboard.vue';
import OrganizationsPage from 'src/pages/Organizations.vue';
import Accounts from 'src/pages/Accounts/Accounts.vue';
import GroupsPage from 'src/pages/Groups/Groups.vue';
import Signup from 'src/pages/Signup.vue';
import Styles from 'src/pages/Styles/Styles.vue';
import Recover from 'src/pages/Recover.vue';
import AcceptRecover from 'src/pages/AcceptRecover.vue';
import LegalInfo from 'src/pages/LegalInfoRu.vue';
import Payment from 'src/pages/Payment.vue';
import Logs from 'src/pages/Logs.vue';
import PaymentRequired from 'src/pages/errors/PaymentRequired.vue';
import PasswordChangeNeeded from 'src/pages/errors/PasswordChangeNeeded.vue';
import Simulations from 'src/pages/Simulations/Simulations.vue';
import SimulationView from 'src/pages/SimulationView.vue';
import SimulationTesting from 'src/pages/SimulationTesting.vue';
import Ratings from 'src/pages/Ratings/Ratings.vue';
import Checklists from 'src/pages/Checkists/Checklists.vue';
import Profile from 'src/pages/Profile.vue';
import Install from 'src/pages/Install/Install.vue';
import Links from 'src/pages/Links/Links.vue';
import IntegratorClients from 'src/pages/IntegratorClients/IntegratorClients.vue';
import IntegratorClientSystems from 'src/pages/IntegratorClientSystems/IntegratorClientSystems.vue';
import Billing from 'src/pages/Billing/Billing.vue';
import OrganizationSelect from 'src/pages/OrganizationSelect/OrganizationSelect.vue';
import MobileWelcome from 'src/pages/Mobile/Welcome.vue';
import MobileEmailLoginLink from 'src/pages/Mobile/EmailLoginLink.vue';
import MobileLayout from 'src/pages/layouts/MobileLayout.vue';
import AuthRedirect from 'src/pages/AuthRedirect/AuthRedirect.vue';
import CreateDesktopSimulation from 'src/pages/Simulations/CreateDesktopSimulation.vue';

import store from 'src/store';
import {
  ORGANIZATION_PLAN_SIMULATION,
  ORGANIZATION_PLAN_STARTUP,
  SUPERADMIN_ROLE,
} from 'src/constants';

Vue.use(Router);

const isDevelopment = process.env.NODE_ENV === 'development';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '',
      component: MainLayout,
      children: [
        {
          path: '/users',
          name: 'users',
          component: Users,
          meta: { auth: true },
        },

        {
          path: '/walkthroughs',
          name: 'scenarios',
          component: Scenarios,
          meta: { auth: true, workspaceAccess: true },
        },

        {
          path: '/tooltips',
          name: 'Tooltips',
          component: Tooltips,
          meta: { auth: true, workspaceAccess: true },
        },

        {
          path: '/ratings',
          name: 'Ratings',
          component: Ratings,
          meta: { auth: true, workspaceAccess: true },
        },

        {
          path: '/checklists',
          name: 'Checklists',
          component: Checklists,
          meta: { auth: true, workspaceAccess: true },
        },

        {
          path: '/links',
          name: 'Links',
          component: Links,
          meta: { auth: true, workspaceAccess: true },
        },

        {
          path: '/simulations',
          name: 'Simulations',
          component: Simulations,
          meta: { auth: true, workspaceAccess: true },
        },

        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: { auth: true, workspaceAccess: true },
        },

        {
          path: '/teams',
          name: 'teams',
          component: OrganizationsPage,
          meta: { auth: true },
        },

        {
          path: '/accounts',
          name: 'accounts',
          component: Accounts,
          meta: { auth: true },
        },

        {
          path: '/integrator-clients',
          name: 'IntegratorClients',
          component: IntegratorClients,
          meta: { auth: true },
        },

        {
          path: '/systems',
          name: 'IntegratorClientSystems',
          component: IntegratorClientSystems,
          meta: { auth: true },
        },

        {
          path: '/groups',
          name: 'groups',
          component: GroupsPage,
          meta: { auth: true },
        },

        {
          path: '/styles',
          name: 'styles',
          component: Styles,
          meta: { auth: true },
        },

        {
          path: '/legal-info',
          name: 'legalInfo',
          component: LegalInfo,
          meta: { auth: true },
        },

        {
          path: '/payment',
          name: 'Payment',
          component: Payment,
          meta: { auth: true },
        },

        {
          path: '/logs',
          name: 'Logs',
          component: Logs,
          meta: { auth: true },
        },

        {
          path: '/profile',
          name: 'Profile',
          component: Profile,
          meta: { auth: true },
        },

        {
          path: '/install',
          name: 'Install',
          component: Install,
          meta: { auth: true },
        },

        {
          path: '/billing',
          name: 'Billing',
          component: Billing,
          meta: { auth: true },
        },
      ],
    },

    {
      path: '/signup',
      name: 'signup',
      component: Signup,
    },

    {
      path: '/login',
      name: 'login',
      component: Login,
    },

    {
      path: '/recover',
      name: 'recover',
      component: Recover,
    },

    {
      path: '/accept/recover/:code',
      name: 'acceptRecover',
      component: AcceptRecover,
    },

    {
      path: '/accept/invite',
      name: 'acceptInvite',
      component: AcceptInvite,
    },

    {
      path: '/expiration',
      name: 'PaymentRequired',
      component: PaymentRequired,
      meta: { auth: true },
    },

    {
      path: '/password-change',
      name: 'PasswordChangeNeeded',
      component: PasswordChangeNeeded,
      meta: { auth: true },
    },

    {
      path: '/organization-select',
      name: 'OrganizationSelect',
      component: OrganizationSelect,
      meta: { auth: true },
    },

    {
      path: '/simulations/:id/view',
      name: 'SimulationView',
      component: SimulationView,
    },

    {
      path: '/simulations/:id/testing',
      name: 'SimulationTesting',
      component: SimulationTesting,
    },

    {
      path: '/auth-transition',
      name: 'AuthRedirect',
      component: AuthRedirect,
    },

    {
      path: '/simulations/create/desktop',
      name: 'CreateDesktopSimulation',
      component: CreateDesktopSimulation,
      meta: { auth: true },
    },

    {
      path: '*',
      component: Login,
    },

    {
      path: '/mobile',
      component: MobileLayout,
      children: [
        {
          path: 'welcome',
          name: 'MobileWelcome',
          component: MobileWelcome,
          meta: { auth: true, mobileOnly: true },
        },
        {
          path: 'login-link',
          name: 'MobileEmailLoginLink',
          component: MobileEmailLoginLink,
          meta: { auth: true, mobileOnly: true },
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.settings.isSettingsLoaded) {
    await store.dispatch('settings/getSettings');
  }

  if (to.name === 'PaymentRequired') {
    next();

    return;
  }

  const { hasLDAP } = store.state.settings;

  const allowedForStartup = ['dashboard', 'styles', 'scenarios', 'tooltips', 'install', 'profile'];
  const allowedForSimulation = [
    'Simulations',
    'SimulationView',
    'SimulationTesting',
    'Profile',
    'Install',
  ];

  if (isAuth() && !store.state.users.currentUser.role) {
    await store.dispatch('users/getCurrentUser');
  }

  const { currentUser } = store.state.users;

  const handler = async () => {
    if (to.meta.requireInviteId && !to.query.inviteId && !isAuth) {
      return next({ name: 'login' });
    }

    if (!isAuth() && to.meta.auth) {
      let query = {};

      if (to.fullPath !== '/') {
        query = { ...query, next: encodeURIComponent(to.fullPath) };
      }

      return next({ name: 'login', query });
    }

    if (isAuth() && currentUser.isPasswordChangeNeeded && to.name !== 'PasswordChangeNeeded') {
      return next({ name: 'PasswordChangeNeeded', query: { redirected: true } });
    }

    if (!isDevelopment) {
      if (
        ((isAuth() && !to.meta.auth) || to.path === '/') &&
        store.state.settings.isMobilePlatform
      ) {
        return next({ name: 'MobileWelcome' });
      }

      if (isAuth() && to.meta.mobileOnly && !store.state.settings.isMobilePlatform) {
        return next({ name: '' });
      }

      if (isAuth() && !to.meta.mobileOnly && store.state.settings.isMobilePlatform) {
        return next({ name: 'MobileWelcome' });
      }

      if (
        isAuth() &&
        store.state.settings.isMobilePlatform &&
        from.name === 'MobileWelcome' &&
        to.name === 'MobileEmailLoginLink'
      ) {
        await store.dispatch('users/sendLoginLinkEmail');
        return next();
      }
    }

    if (isAuth() && !currentUser.organizationId && to.name !== 'OrganizationSelect') {
      return next({ name: 'OrganizationSelect', query: { redirected: true } });
    }

    if (
      ((isAuth() && !to.meta.auth) || to.path === '/') &&
      !['SimulationView', 'SimulationTesting'].includes(to.name)
    ) {
      return next({ name: 'Dashboard' });
    }

    if (isAuth() && hasLDAP) {
      const ldapAccesses = {
        MODERATOR: ['scenarios', 'tooltips', 'simulations', 'simulationview', 'simulationtesting'],
        EDITOR: ['scenarios', 'tooltips', 'simulations', 'simulationview', 'simulationtesting'],
        DESIGNER: ['styles'],
      };

      if (
        Object.keys(ldapAccesses).includes(currentUser.role) &&
        !ldapAccesses[currentUser.role].includes(to.name?.toLowerCase())
      ) {
        return next({ name: ldapAccesses[currentUser.role][0] });
      }

      return next();
    }

    if (
      isAuth() &&
      currentUser.role !== SUPERADMIN_ROLE &&
      currentUser.organizationPlan === ORGANIZATION_PLAN_STARTUP &&
      !allowedForStartup.includes(to.name.toLowerCase())
    ) {
      return next({ name: 'Dashboard' });
    }

    if (
      isAuth() &&
      currentUser.role !== SUPERADMIN_ROLE &&
      currentUser.organizationPlan === ORGANIZATION_PLAN_SIMULATION &&
      !allowedForSimulation.includes(to.name)
    ) {
      return next({ name: 'Simulations' });
    }

    return next();
  };

  if (isAuthing()) {
    await waitInitialization;
  }

  await handler();
});

export default router;
