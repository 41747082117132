<template>
  <div class="trial-card">
    <div class="trial-card__content">
      <div class="trial-card__icon-wrapper">
        <div class="trial-card__icon">
          <v-icon color="#C2D0E7">mdi-license</v-icon>
        </div>
      </div>

      <span>
        {{ $t('pages.billing.trialText') }}
      </span>
    </div>

    <billing-button x-large class="trial-card__button" @click="subscribeTrial()">
      {{ $t('pages.billing.activateTrial') }}
    </billing-button>
  </div>
</template>

<script>
  import api from 'src/api';

  import BillingButton from '../BillingButton/BillingButton.vue';

  import { PERIOD_YEARLY } from '../../utils/subscriptionPeriods';

  export default {
    name: 'TrialCard',

    components: {
      BillingButton,
    },

    methods: {
      async subscribeTrial() {
        const { response } = await api.payments.subscribe({
          data: {
            period: PERIOD_YEARLY,
            trial: true,
          },
        });

        window.location.href = response.url;
      },
    },
  };
</script>

<style lang="scss">
  .trial-card {
    background: linear-gradient(
      86.41deg,
      rgba(116, 116, 191, 0.18) 2.91%,
      rgba(52, 138, 199, 0.18) 99.13%
    );
    border-radius: 4px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 21px;
    line-height: 40px;
    letter-spacing: 0.0025em;
    color: #7474bf;

    &__content {
      display: flex;
      align-items: center;
    }

    &__icon-wrapper {
      width: 94px;
      min-width: 94px;
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: linear-gradient(
        86.41deg,
        rgba(116, 116, 191, 0.16) 2.91%,
        rgba(52, 138, 199, 0.16) 99.13%
      );
      margin-right: 25px;
    }

    &__icon {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: linear-gradient(86.41deg, #7474bf 2.91%, #348ac7 99.13%);
    }

    &__button {
      margin-left: 10px;
      background: linear-gradient(86.41deg, #7474bf 2.91%, #348ac7 99.13%);
      color: #ffffff !important;
    }
  }
</style>
