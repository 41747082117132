<template>
  <v-container fluid class="integrator-clients">
    <v-card flat>
      <v-card-text class="pa-5">
        <div class="d-flex align-center">
          <span class="lstick lstick--info" />
          <v-icon large color="info" class="img-fluid">
            mdi-license
          </v-icon>

          <div class="ml-4 mr-1">
            <h5 class="subtitle-1 ">
              {{ $t('pages.integratorClients.credits') }}
            </h5>

            <h2 class="font-weight-regular">
              {{ formatCost(balance) }}
            </h2>
          </div>

          <v-spacer />

          <v-btn color="primary" dark class="text-none" @click="openManageForm()">
            {{ $t('pages.integratorClients.createButton') }}

            <v-icon right dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <v-card flat class="mt-5">
      <v-card-text class="pa-5">
        <div class="d-flex align-center">
          <span class="lstick lstick--info" />

          <div class="text-h6 mr-4">
            {{ $t('pages.integratorClients.filter') }}
          </div>

          <v-text-field
            v-model.trim="debouncedPattern"
            :label="$t('pages.integratorClients.search')"
            append-icon="mdi-magnify"
            hide-details
            outlined
            dense
            class="shrink integrator-clients__filter-control"
          />

          <v-select
            v-model="params.status"
            :items="statusesOptions"
            :label="$t('pages.integratorClients.status')"
            hide-details
            outlined
            dense
            class="shrink ml-3 integrator-clients__filter-control"
          />
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mt-5">
      <v-card-text>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="params"
          :server-items-length="serverItemsCount"
          :loading="isBusy"
        >
          <template #item.createdAt="{value}">
            {{ formatDateTime(value) }}
          </template>

          <template #item.usersCount="{value}">
            {{ value || '—' }}
          </template>

          <template #item.startDate="{value}">
            {{ value ? formatDate(value) : '—' }}
          </template>

          <template #item.endDate="{value}">
            {{ value ? formatDate(value) : '—' }}
          </template>

          <template #item.status="{item}">
            <client-status-chip :client="item" />
          </template>

          <template #item.actions="{item}">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="info"
                  icon
                  small
                  v-bind="attrs"
                  @click="openManageForm(item.id)"
                  v-on="on"
                >
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>

              <span> {{ $t('pages.integratorClients.editTooltip') }}</span>
            </v-tooltip>

            <v-tooltip v-if="!item.startDate" top>
              <template #activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" @click="openCreateLicenseForm(item.id)" v-on="on">
                  <v-icon small>
                    mdi-license
                  </v-icon>
                </v-btn>
              </template>

              <span>{{ $t('pages.integratorClients.grantAccessTooltip') }}</span>
            </v-tooltip>

            <v-tooltip v-else-if="item.blocked" top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  @click="openBlockForm(item.id, 'UnblockClientModal')"
                  v-on="on"
                >
                  <v-icon small>
                    mdi-lock-open-outline
                  </v-icon>
                </v-btn>
              </template>

              <span>{{ $t('pages.integratorClients.unblockClientTooltip') }}</span>
            </v-tooltip>

            <template v-else>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    @click="openExtendForm(item.id, 'LicenseTermExtensionModal')"
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-calendar-blank-outline
                    </v-icon>
                  </v-btn>
                </template>

                <span>{{ $t('pages.integratorClients.extendTermTooltip') }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    @click="openExtendForm(item.id, 'LicenseUsersExtensionModal')"
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-account-plus-outline
                    </v-icon>
                  </v-btn>
                </template>

                <span>{{ $t('pages.integratorClients.extendUsersTooltip') }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    @click="openBlockForm(item.id, 'BlockClientModal')"
                    v-on="on"
                  >
                    <v-icon small>
                      mdi-lock-outline
                    </v-icon>
                  </v-btn>
                </template>

                <span>{{ $t('pages.integratorClients.blockClientTooltip') }}</span>
              </v-tooltip>
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="isManageDialogOpened" max-width="570px">
      <manage-client-modal
        v-if="isManageDialogOpened"
        :client-id="editingClientId"
        @created="getList()"
        @updated="getList()"
        @close="isManageDialogOpened = false"
      />
    </v-dialog>

    <v-dialog v-model="isCreateLicenseDialogOpened" max-width="708px">
      <create-license-modal
        v-if="isCreateLicenseDialogOpened"
        :client-id="editingClientId"
        @created="getList()"
        @updateBalance="balance = $event"
        @close="isCreateLicenseDialogOpened = false"
      />
    </v-dialog>

    <v-dialog v-model="isExtendLicenseDialogOpened" max-width="708px">
      <component
        :is="extendLicenseComponent"
        v-if="isExtendLicenseDialogOpened"
        :client-id="editingClientId"
        @updated="getList()"
        @updateBalance="balance = $event"
        @close="isExtendLicenseDialogOpened = false"
      />
    </v-dialog>

    <v-dialog v-model="isBlockModalOpened" max-width="648px">
      <component
        :is="blockClientComponent"
        v-if="isBlockModalOpened"
        :client-id="editingClientId"
        @updated="getList()"
        @close="isBlockModalOpened = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
  import debounce from 'lodash.debounce';

  import api from 'src/api';

  import { busyFlow } from 'src/utils';

  import formatCostMixin from 'src/mixins/formatCostMixin';
  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  import ManageClientModal from './components/ManageClientModal/ManageClientModal.vue';
  import CreateLicenseModal from './components/CreateLicenseModal/CreateLicenseModal.vue';
  import LicenseTermExtensionModal from './components/LicenseTermExtensionModal/LicenseTermExtensionModal.vue';
  import LicenseUsersExtensionModal from './components/LicenseUsersExtensionModal/LicenseUsersExtensionModal.vue';
  import ClientStatusChip from './components/ClientStatusChip/ClientStatusChip.vue';
  import BlockClientModal from './components/BlockClientModal/BlockClientModal.vue';
  import UnblockClientModal from './components/UnblockClientModal/UnblockClientModal.vue';

  const defaultParams = {
    itemsPerPage: 10,
    page: 1,
    pattern: '',
    status: null,
    sortBy: ['createdAt'],
    sortDesc: [true],
  };

  export default {
    name: 'IntegratorClients',

    components: {
      ManageClientModal,
      CreateLicenseModal,
      LicenseTermExtensionModal,
      LicenseUsersExtensionModal,
      ClientStatusChip,
      BlockClientModal,
      UnblockClientModal,
    },

    mixins: [formatCostMixin, formatTimestampMixin],

    data() {
      return {
        balance: 0,

        isBusy: false,
        items: [],
        serverItemsCount: 0,
        params: { ...defaultParams },

        isManageDialogOpened: false,
        isCreateLicenseDialogOpened: false,
        isExtendLicenseDialogOpened: false,
        isBlockModalOpened: false,
        blockClientComponent: null,
        extendLicenseComponent: null,
        editingClientId: null,

        statusesOptions: Object.freeze([
          {
            value: null,
            text: this.$t('pages.integratorClients.statuses.any'),
          },
          ...['waiting', 'active', 'ends', 'ended', 'blocked'].map(status => ({
            value: status,
            text: this.$t(`pages.integratorClients.statuses.${status}`),
          })),
        ]),
      };
    },

    computed: {
      tableHeaders() {
        return [
          {
            text: this.$t('pages.integratorClients.tableHeaders.createdAt'),
            value: 'createdAt',
            width: '150px',
          },
          { text: this.$t('pages.integratorClients.tableHeaders.client'), value: 'name' },
          { text: this.$t('pages.integratorClients.tableHeaders.usersCount'), value: 'usersCount' },
          { text: this.$t('pages.integratorClients.tableHeaders.startDate'), value: 'startDate' },
          { text: this.$t('pages.integratorClients.tableHeaders.endDate'), value: 'endDate' },
          {
            text: this.$t('pages.integratorClients.tableHeaders.status'),
            value: 'status',
            sortable: false,
          },
          {
            text: this.$t('ui.common.actions'),
            value: 'actions',
            width: '150px',
            align: 'center',
            sortable: false,
          },
        ];
      },

      debouncedPattern: {
        get() {
          return this.params.pattern;
        },

        set(value) {
          this.debouncedSetPattern(value);
        },
      },

      mappedParams() {
        const {
          itemsPerPage,
          page = 1,
          pattern,
          status,
          sortBy: [filterBy],
          sortDesc: [isDesc],
        } = this.params;

        const limit = itemsPerPage === -1 ? 'all' : itemsPerPage;
        const filterDirection = isDesc ? 'desc' : 'asc';

        return {
          page,
          limit,
          ...(pattern ? { pattern } : {}),
          ...(status ? { status } : {}),
          ...(filterBy ? { filter_by: filterBy, filter_direction: filterDirection } : {}),
        };
      },
    },

    watch: {
      mappedParams: {
        handler() {
          this.getList();
        },
      },
    },

    mounted() {
      this.getBalance();
      this.getList();
    },

    methods: {
      debouncedSetPattern: debounce(function setPattern(pattern) {
        this.params = {
          ...defaultParams,
          sortBy: this.params.sortBy,
          itemsPerPage: this.params.itemsPerPage,
          sortDesc: this.params.sortDesc,
          status: this.params.status,
          pattern,
        };
      }, 300),

      getList() {
        busyFlow.call(this, async () => {
          const { response } = await api.integrators.getClients({
            params: { ...this.mappedParams },
          });

          this.serverItemsCount = response.count;
          this.items = response.payload;
        });
      },

      async getBalance() {
        const { response } = await api.integrators.getCurrent();

        this.balance = response.balance;
      },

      openManageForm(clientId = null) {
        this.editingClientId = clientId;
        this.isManageDialogOpened = true;
      },

      openCreateLicenseForm(clientId) {
        this.editingClientId = clientId;
        this.isCreateLicenseDialogOpened = true;
      },

      openExtendForm(clientId, component) {
        this.editingClientId = clientId;
        this.extendLicenseComponent = component;
        this.isExtendLicenseDialogOpened = true;
      },

      openBlockForm(clientId, component) {
        this.editingClientId = clientId;
        this.blockClientComponent = component;
        this.isBlockModalOpened = true;
      },
    },
  };
</script>

<style lang="scss">
  .integrator-clients {
    &__filter-control {
      max-width: 250px;
    }
  }
</style>
