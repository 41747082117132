<template>
  <v-btn :loading="loading" color="blue darken-1" text @click="$emit('open')">
    {{ $t('pages.users.createFromFile') }}
  </v-btn>
</template>

<script>
  import { mapState } from 'vuex';
  import { LOAD_PENDING } from 'src/constants';

  export default {
    name: 'CreateManyButton',

    computed: mapState({
      loading: state => state.users.createManyUsersStatus === LOAD_PENDING,
    }),
  };
</script>
