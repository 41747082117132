const getOne = {
  name: 'getOne',
  method: 'get',
  url: ':id',
};

const me = {
  name: 'me',
  method: 'get',
  url: 'me',
};

const getAvailableList = {
  name: 'getAvailableList',
  method: 'get',
  url: 'me/list',
};

const getSettings = {
  name: 'getSettings',
  method: 'get',
  url: 'settings/:id',
};

const list = {
  name: 'list',
  method: 'get',
};

const listWithParams = {
  name: 'listWithParams',
  method: 'get',
  url: 'list',
};

const create = {
  name: 'create',
  method: 'post',
  url: 'create',
};

const update = {
  name: 'update',
  method: 'post',
  url: ':id/update',
};

const updateInfo = {
  name: 'updateInfo',
  method: 'patch',
  url: 'edit-info/:id',
};

const updateOwner = {
  name: 'updateOwner',
  method: 'patch',
  url: 'update-owner/:id',
};

const updateSettings = {
  name: 'updateSettings',
  method: 'patch',
  url: 'settings',
};

const sendFeedback = {
  name: 'sendFeedback',
  method: 'post',
  url: 'feedback',
};

const select = {
  name: 'select',
  method: 'post',
  url: ':id/select',
};

const remove = {
  name: 'remove',
  method: 'delete',
  url: ':id',
};

const restore = {
  name: 'restore',
  method: 'post',
  url: ':id/restore',
};

export default {
  name: 'organizations',
  url: '/api/organizations',
  children: [
    getOne,
    me,
    getAvailableList,
    getSettings,
    list,
    listWithParams,
    create,
    update,
    updateInfo,
    updateOwner,
    updateSettings,
    sendFeedback,
    select,
    remove,
    restore,
  ],
};
