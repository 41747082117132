<template>
  <v-container>
    <v-row class="mb-4">
      <v-col cols="12" class="pa-0">
        <v-container fluid class="pb-0">
          <v-card flat>
            <v-card-text class="px-5 py-4">
              <v-row align="center">
                <v-col class="col-xl-1 col-md-auto">
                  <h3 class="title font-weight-regular">
                    <span class="lstick" />
                    {{ $t('pages.ratings.filter') }}:
                  </h3>
                </v-col>

                <v-col class="col-md-12 col-xl-11">
                  <v-row>
                    <v-col cols="3">
                      <v-autocomplete
                        :value="params.scenarioId"
                        :loading="isBusyScenariosSearch"
                        :items="scenariosOptions"
                        :search-input.sync="debouncedScenariosPattern"
                        :label="$t('pages.ratings.scenarios')"
                        item-text="title"
                        item-value="id"
                        prepend-inner-icon="mdi-magnify"
                        light
                        outlined
                        dense
                        cache-items
                        hide-details
                        @input="updateScenarioId($event)"
                      />
                    </v-col>

                    <v-col cols="3">
                      <v-select
                        v-model="params.versionId"
                        :items="scenarioVersionsOptions"
                        :disabled="!params.scenarioId"
                        :label="$t('pages.ratings.scenarioVersions')"
                        light
                        outlined
                        dense
                        prepend-inner-icon="mdi-magnify"
                        hide-details
                      />
                    </v-col>

                    <v-col cols="3">
                      <v-menu
                        ref="menu"
                        v-model="openedDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="filterDates"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            :value="formattedDatesFilter"
                            :label="$t('pages.ratings.period')"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            dense
                            outlined
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="datePickerModel"
                          range
                          no-title
                          scrollable
                          first-day-of-week="1"
                          :max="maxDate"
                        >
                          <v-spacer />

                          <v-btn text color="primary" @click="openedDateMenu = false">
                            {{ $t('ui.common.cancel') }}
                          </v-btn>

                          <v-btn text color="primary" @click="$refs.menu.save(datePickerModel)">
                            {{ $t('ui.common.ok') }}
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="3">
                      <v-btn block dark color="primary" :loading="isDownloadBusy" @click="download">
                        {{ $t('pages.ratings.download') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
    </v-row>

    <v-card>
      <v-card-text>
        <v-data-table
          :headers="tableHeaders"
          :items="mappedItems"
          :options.sync="params"
          :server-items-length="serverItemsCount"
          :loading="isBusy"
        >
          <template #no-data>
            <travolta-not-found />
          </template>

          <template #top>
            <v-toolbar flat class="mb-8">
              <v-container fluid class="px-0">
                <v-row align="center">
                  <v-col cols="4">
                    <v-text-field
                      v-model="debouncedPattern"
                      :label="`${$t('pages.ratings.search')}...`"
                      append-icon="mdi-magnify"
                      clearable
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-toolbar>
          </template>

          <template #item.createdAt="{value}">
            {{ formatDateTime(value) }}
          </template>

          <template #item.scenarioTitle="{value, item}">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on">
                  {{ $options.mappedCollectionIcons[item.collection] }}
                </v-icon>
              </template>

              <span>{{ $t(`pages.ratings.collections.${item.collection}`) }}</span>
            </v-tooltip>

            <span class="ml-2">{{ value }}</span>
          </template>

          <template #item.value="{value}">
            <div class="mr-4">
              <v-icon small color="#FFA630">
                mdi-star
              </v-icon>

              <span class="ml-3">{{ value }}</span>
            </div>
          </template>

          <template #item.user="{item}">
            <div>{{ item.userInfo }}</div>
            <div v-if="item.userEmail">{{ item.userEmail }}</div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import debounce from 'lodash.debounce';
  import { saveAs } from 'file-saver';

  import api from 'src/api';

  import { busyFlow, deepcopy, getLocationForQueries, prepareCsv } from 'src/utils';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  import TravoltaNotFound from 'src/components/TravoltaNotFound.vue';

  const defaultParams = {
    itemsPerPage: 10,
    page: 1,
    pattern: null,
    sortBy: ['createdAt'],
    sortDesc: [true],
    scenarioId: null,
    versionId: null,
    from: moment()
      .subtract(1, 'years')
      .startOf('day')
      .valueOf(),
    to: moment()
      .endOf('day')
      .valueOf(),
  };

  export default {
    name: 'Ratings',

    components: {
      TravoltaNotFound,
    },

    mixins: [formatTimestampMixin],

    data() {
      return {
        isBusy: false,
        items: [],
        serverItemsCount: 0,
        params: { ...defaultParams },

        scenariosSearchPattern: null,
        isBusyScenariosSearch: false,
        scenarios: [],
        scenarioId: null,
        versionId: null,
        versions: [],
        isBusyScenarioVersions: false,

        filterDates: [
          moment()
            .subtract(1, 'years')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        openedDateMenu: false,
        maxDate: new Date().toISOString().slice(0, 10),
        datePickerModel: [
          moment()
            .subtract(1, 'years')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],

        isDownloadBusy: false,
      };
    },

    mappedCollectionIcons: {
      scenarios: 'mdi-message-outline',
    },

    computed: {
      ...mapGetters('versions', ['hasAccessToVersionControl']),

      tableHeaders() {
        return [
          {
            text: this.$t('pages.ratings.tableHeaders.createdAt'),
            value: 'createdAt',
            width: '150px',
          },
          { text: this.$t('pages.ratings.tableHeaders.text'), value: 'text', width: '400px' },
          { text: this.$t('pages.ratings.tableHeaders.title'), value: 'scenarioTitle' },
          ...(this.hasAccessToVersionControl
            ? [
                {
                  text: this.$t('pages.ratings.tableHeaders.version'),
                  value: 'scenarioVersion',
                  align: 'center',
                },
              ]
            : []),
          {
            text: this.$t('pages.ratings.tableHeaders.rating'),
            value: 'value',
            align: 'center',
            width: '250px',
          },
          { text: this.$t('pages.ratings.tableHeaders.user'), value: 'user', sortable: false },
        ];
      },

      debouncedPattern: {
        get() {
          return this.params.pattern;
        },

        set(value) {
          this.debouncedSetPattern(value);
        },
      },

      mappedParams() {
        const {
          itemsPerPage,
          page = 1,
          pattern,
          sortBy: [filterBy],
          sortDesc: [isDesc],
          scenarioId,
          versionId,
          from,
          to,
        } = this.params;

        const limit = itemsPerPage === -1 ? 'all' : itemsPerPage;
        const filterDirection = isDesc ? 'desc' : 'asc';

        return {
          page,
          limit,
          from,
          to,
          ...(scenarioId ? { scenarioId } : {}),
          ...(versionId ? { versionId } : {}),
          ...(pattern ? { pattern } : {}),
          ...(filterBy ? { filter_by: filterBy, filter_direction: filterDirection } : {}),
        };
      },

      scenariosOptions() {
        return [
          {
            id: null,
            title: this.$t('pages.ratings.allScenarios'),
          },
          ...this.scenarios,
        ];
      },

      scenarioVersionsOptions() {
        return [
          {
            value: null,
            text: this.$t('pages.ratings.allScenarioVersions'),
          },
          ...[...this.versions].reverse().map(versionData => ({
            value: versionData.id,
            text: `Версия ${versionData.version} (${this.formatDateTime(versionData.createdAt)})`,
          })),
        ];
      },

      debouncedScenariosPattern: {
        get() {
          return this.scenariosSearchPattern;
        },

        set(value) {
          this.debouncedSetScenariosPattern(value);
        },
      },

      formattedDatesFilter() {
        const dates = [...this.filterDates];

        if (dates.length === 1) {
          dates.push(dates[0]);
        }

        if (Date.parse(dates[1]) < Date.parse(dates[0])) {
          const temp = dates[1];
          [dates[1]] = dates;
          dates[0] = temp;
        }

        return `${this.formatDate(dates[0])} ~ ${this.formatDate(dates[1])}`;
      },

      mappedItems() {
        return this.items.map(item => {
          const { firstname, lastname, username, email } = item;

          let userInfo = this.$t('pages.ratings.unknownUser');
          let userEmail = null;

          if (firstname && lastname && username) {
            userInfo = `${firstname} ${lastname}`;
            userEmail = email;

            return { ...item, userInfo, userEmail };
          }

          if (username) {
            userInfo = username;
          }

          return { ...item, userInfo, userEmail };
        });
      },
    },

    watch: {
      mappedParams: {
        handler() {
          this.getList();
        },
      },

      scenariosSearchPattern() {
        this.searchScenarios();
      },

      'params.scenarioId': {
        handler(value) {
          if (value) {
            this.searchScenarioVersions();
          }
        },
      },

      filterDates(value) {
        const dates = [...value];

        if (dates.length === 1) {
          dates.push(dates[0]);
        }

        if (Date.parse(dates[1]) < Date.parse(dates[0])) {
          const temp = dates[1];
          [dates[1]] = dates;
          dates[0] = temp;
        }

        this.params = {
          ...defaultParams,
          sortBy: this.params.sortBy,
          itemsPerPage: this.params.itemsPerPage,
          sortDesc: this.params.sortDesc,
          scenarioId: this.params.scenarioId,
          versionId: this.params.versionId,
          from: moment(dates[0]).valueOf(),
          to: moment(dates[1]).valueOf(),
        };
      },
    },

    async created() {
      if (this.$route.query.scenarioId) {
        this.params.scenarioId = this.$route.query.scenarioId;
      }
    },

    async mounted() {
      this.getList();

      if (this.params.scenarioId) {
        const { response } = await api.scenarios.listWithParams({
          params: {
            limit: 1,
            pattern: this.params.scenarioId,
          },
        });

        this.scenarios = response.payload;

        this.searchScenarioVersions();
      }

      this.searchScenarios(true);
    },

    methods: {
      debouncedSetPattern: debounce(function setPattern(pattern) {
        this.params = {
          ...defaultParams,
          sortBy: this.params.sortBy,
          itemsPerPage: this.params.itemsPerPage,
          sortDesc: this.params.sortDesc,
          pattern,
        };
      }, 300),

      debouncedSetScenariosPattern: debounce(function setPattern(pattern) {
        this.scenariosSearchPattern = pattern;
      }, 300),

      getList() {
        busyFlow.call(this, async () => {
          const { response } = await api.scenarios.getRatingList({
            params: { ...this.mappedParams },
          });

          this.serverItemsCount = response.count;
          this.items = response.payload;
        });
      },

      updateScenarioId(scenarioId) {
        this.params = {
          ...defaultParams,
          sortBy: this.params.sortBy,
          itemsPerPage: this.params.itemsPerPage,
          sortDesc: this.params.sortDesc,
          scenarioId,
          versionId: null,
        };

        this.$router.replace({ name: 'Ratings', query: { scenarioId: scenarioId || undefined } });
      },

      searchScenarios(emptyPattern = false) {
        busyFlow.call(
          this,
          async () => {
            const { response } = await api.scenarios.listWithParams({
              params: {
                limit: 20,
                pattern: emptyPattern ? '' : this.scenariosSearchPattern,
              },
            });

            this.scenarios = response.payload;
          },
          false,
          'isBusyScenariosSearch'
        );
      },

      searchScenarioVersions() {
        busyFlow.call(
          this,
          async () => {
            const { response } = await api.scenarios.getVersions({
              url_params: { id: this.params.scenarioId },
            });

            this.versions = response;
          },
          false,
          'isBusyScenarioVersions'
        );
      },

      download() {
        busyFlow.call(
          this,
          async () => {
            const { response } = await api.scenarios.downloadRatings({
              params: deepcopy({
                ...this.mappedParams,
                limit: undefined,
                page: undefined,
                lang: getLocationForQueries(),
              }),
            });

            const blob = await prepareCsv(response);
            saveAs(blob, `ratings.csv`);
          },
          false,
          'isDownloadBusy'
        );
      },
    },
  };
</script>
