<template>
  <div>
    <div class="subtitle-1 mb-4">{{ $t('ui.autoFieldsInput.title') }}</div>

    <div v-for="(item, index) in localValue" :key="index" class="d-flex align-center mb-4">
      <v-text-field
        :value="item"
        :label="$t('ui.autoFieldsInput.label')"
        outlined
        hide-details
        @input="changeItem(index, $event)"
      />

      <v-btn v-if="index" icon class="ml-2" @click="removeItem(index)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>

    <v-btn block color="primary" @click="addItem()">
      {{ $t('ui.autoFieldsInput.addButton') }}
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'AutoFieldsInput',

    model: {
      prop: 'modelValue',
      event: 'update:modelValue',
    },

    props: {
      modelValue: {
        type: Array,
        required: false,
        default: () => [],
      },
    },

    data() {
      return {
        localValue: [],
      };
    },

    watch: {
      modelValue: {
        handler() {
          this.init();
        },
        immediate: true,
      },
    },

    methods: {
      init() {
        if (!Array.isArray(this.modelValue) || !this.modelValue.length) {
          this.localValue = [''];
        } else {
          this.localValue = this.modelValue;
        }
      },

      changeItem(index, value) {
        const localValue = [...this.localValue];
        localValue[index] = value;

        this.updateValue(localValue);
      },

      removeItem(index) {
        const localValue = [...this.localValue];
        localValue.splice(index, 1);

        this.updateValue(localValue);
      },

      addItem() {
        const localValue = [...this.localValue];
        localValue.push('');

        this.updateValue(localValue);
      },

      updateValue(value) {
        this.localValue = value;
        this.$emit('update:modelValue', value);
      },
    },
  };
</script>
