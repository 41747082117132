const list = {
  name: 'list',
  method: 'get',
  url: 'list',
};

const update = {
  name: 'update',
  method: 'post',
  url: 'update/:id',
};

const remove = {
  name: 'remove',
  method: 'post',
  url: 'remove/:id',
};

const restore = {
  name: 'restore',
  method: 'post',
  url: ':id/restore',
};

const changeWorkspace = {
  name: 'changeWorkspace',
  method: 'post',
  url: 'change-workspace',
};

export default {
  name: 'desktopScenarios',
  url: '/api/desktop/scenarios',
  children: [list, update, remove, restore, changeWorkspace],
};
