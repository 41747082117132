<template>
  <v-container>
    <v-card class="accept-form">
      <v-form ref="form" class="form" @submit.prevent="submit">
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field v-model="username" :rules="notNull" label="Username" required />
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field v-model="firstname" label="Firstname" required />
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field v-model="lastname" label="Lastname" required />
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-text-field v-model="phone" label="Phone" :rules="notNull" type="phone" required />
          </v-col>

          <v-col cols="6" sm="12" md="6">
            <v-text-field
              v-model="password"
              :rules="passwordRules"
              label="Password"
              type="password"
              required
            />
          </v-col>

          <v-col cols="6" sm="12" md="6">
            <v-text-field
              v-model="passwordConfirm"
              :rules="passwordRepeatRules"
              label="Password confirmation"
              type="password"
              required
            />
          </v-col>

          <v-col cols="12">
            <v-btn color="blue darken-1" type="submit" :loading="isBusy">
              Accept
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex';

  function data() {
    return {
      username: null,
      firstname: null,
      lastname: null,
      password: null,
      passwordConfirm: null,
      phone: null,
      isBusy: false,
      isValid: true,
    };
  }

  const computed = {
    notNull() {
      return [v => !!v || this.$t('validation.notNull')];
    },

    passwordRules() {
      return [
        v => !!v || this.$t('validation.passwordEmpty'),
        v => (v && v.length >= 6) || this.$t('validation.passwordLength'),
      ];
    },

    passwordRepeatRules() {
      return [
        ...this.passwordRules,
        v => v === this.password || this.$t('validation.passwordsDiff'),
      ];
    },
  };

  const methods = {
    ...mapActions('invites', ['check', 'accept']),

    async submit() {
      this.validate();

      if (this.isBusy || !this.isValid) {
        return;
      }

      this.isBusy = true;

      const { username, firstname, lastname, password, passwordConfirm, phone } = this;
      const payload = {
        username,
        firstname: firstname || null,
        lastname: lastname || null,
        password,
        confirmPassword: passwordConfirm,
        phone,
        code: this.$route.query.inviteId,
      };

      try {
        await this.accept(payload);
        this.$router.push({ name: 'login', query: { status: 'invited' } });
      } catch (err) {
        console.error(err);
      } finally {
        this.isBusy = false;
      }
    },

    validate() {
      this.isValid = this.$refs.form.validate();
    },
  };

  async function created() {
    const {
      query: { inviteId },
    } = this.$route;
    const {
      response: { isActive },
    } = await this.check(inviteId);

    if (!isActive) {
      this.$router.push({ name: 'login' });
    }
  }

  export default {
    name: 'AcceptInvite',
    data,
    computed,
    created,
    methods,
  };
</script>

<style scoped>
  .accept-form {
    max-width: 600px;
    margin: 0 auto;
  }

  .form {
    padding: 15px;
  }
</style>
