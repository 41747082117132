<template>
  <v-container>
    <v-card flat>
      <v-card-text class="d-flex pa-5">
        <v-row align="center">
          <v-col cols="auto">
            <h3 class="title font-weight-regular">
              <span class="lstick" />
              {{ $t('pages.statistics.statistics') }}:
            </h3>
          </v-col>

          <v-col cols="3">
            <v-autocomplete
              v-model="scenarioId"
              :items="dropDownItems"
              :label="$t('pages.statistics.scenario')"
              item-text="title"
              item-value="id"
              prepend-inner-icon="mdi-magnify"
              hide-details
            />
          </v-col>

          <v-col cols="3" md="2" xl="3">
            <v-menu
              ref="startDate"
              v-model="startDateMenu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  :label="$t('pages.statistics.startDate')"
                  prepend-icon="mdi-calendar"
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>

              <v-date-picker v-model="startDate" :min="minDate" :max="maxDate" no-title scrollable>
                <v-spacer />
                <v-btn text color="primary" @click="startDateMenu = false">
                  {{ $t('ui.common.cancel') }}
                </v-btn>

                <v-btn
                  text
                  color="primary"
                  @click="
                    () => {
                      $refs.startDate.save(startDate);
                      sendMetrics('statistics_start_date_selected', 'select');
                    }
                  "
                >
                  {{ $t('ui.common.ok') }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="3" md="2" xl="3">
            <v-menu
              ref="endDate"
              v-model="endDateMenu"
              :close-on-content-click="false"
              :return-value.sync="endDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  :label="$t('pages.statistics.endDate')"
                  prepend-icon="mdi-calendar"
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                />
              </template>

              <v-date-picker
                v-model="endDate"
                :min="startDate || minDate"
                :max="maxDate"
                no-title
                scrollable
              >
                <v-spacer />

                <v-btn text color="primary" @click="endDateMenu = false">
                  {{ $t('ui.common.cancel') }}
                </v-btn>

                <v-btn
                  text
                  color="primary"
                  @click="
                    () => {
                      $refs.endDate.save(endDate);
                      sendMetrics('statistics_end_date_selected', 'select');
                    }
                  "
                >
                  {{ $t('ui.common.ok') }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-spacer />

          <v-col>
            <v-menu>
              <template #activator="{ props, on }">
                <v-btn dark color="primary" small v-bind="props" v-on="on">
                  {{ $t('pages.statistics.downloadStatistics') }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="item in downloadItems"
                  :key="item.value"
                  @click="downloadStats(item.value)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <download-statistics v-model="isDownloadDialogOpened" :load="loadStepStatistics" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  import moment from 'moment';
  import { saveAs } from 'file-saver';

  import DownloadStatistics from 'src/components/Statistics/DownloadStatistics.vue';

  import { STATS_SET_FILTER, STATS_SET_SCENARIO_ID } from 'src/store/mutation-types';
  import { prepareCsv } from 'src/utils';

  export default {
    name: 'StatisticFilter',

    components: {
      DownloadStatistics,
    },

    inject: ['sendMetrics'],

    props: {
      organizationId: {
        type: String,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        scenarioId: null,

        maxDate: null,
        minDate: '2000-01-01',
        startDate: null,
        startDateMenu: false,
        endDate: null,
        endDateMenu: false,

        isBusy: false,
        isDownloadDialogOpened: false,
      };
    },

    computed: {
      ...mapState('scenarios', ['scenariosList']),

      ...mapState('stats', {
        scenarioIdState: state => state.scenarioId,
      }),

      downloadItems() {
        return [
          { value: 'downloadStatistics', title: this.$t('pages.statistics.downloadStatistics') },
          {
            value: 'downloadHistoricalStatistics',
            title: this.$t('pages.statistics.downloadHistoricalStatistics'),
          },
        ];
      },

      dropDownItems() {
        return [
          { id: null, title: this.$t('pages.statistics.testingFilter.allWalkthroughs') },
          ...this.scenariosList,
        ];
      },

      datesSelected() {
        return this.startDate && this.endDate;
      },
    },

    watch: {
      organizationId() {
        this.getScenarios();
      },

      scenarioIdState(value) {
        this.scenarioId = value;
      },

      scenarioId(value, oldValue) {
        if (oldValue) {
          this.sendMetrics('statistics_walkthrough_selected', 'select');
        }

        this.setScenarioId(value);
        this.updateStatistics();
      },

      startDate(value, prevValue) {
        const from = moment(value)
          .startOf('day')
          .valueOf();
        const to = moment(this.endDate)
          .endOf('day')
          .valueOf();

        this.setFilter({ to, from });

        if (prevValue) {
          this.updateStatistics();
        }
      },

      endDate(value, prevValue) {
        const from = moment(this.startDate)
          .startOf('day')
          .valueOf();
        const to = moment(value)
          .endOf('day')
          .valueOf();

        this.setFilter({ to, from });

        if (prevValue) {
          this.updateStatistics();
        }
      },

      datesSelected(currentDatesSelected, prevDateSelected) {
        if (!prevDateSelected && currentDatesSelected) {
          this.updateStatistics();
        }
      },
    },

    async mounted() {
      await this.getScenarios();

      this.endDate = moment().format('YYYY-MM-DD');
      this.maxDate = moment().format('YYYY-MM-DD');
      this.startDate = moment()
        .subtract(2, 'w')
        .format('YYYY-MM-DD');
    },

    methods: {
      ...mapMutations('stats', {
        setScenarioId: STATS_SET_SCENARIO_ID,
        setFilter: STATS_SET_FILTER,
      }),
      ...mapActions('scenarios', ['listWithParams']),
      ...mapActions('stats', [
        'getScenarioStatsChart',
        'getScenarioStatistics',
        'getInterruptedAndTotalChart',
        'clearScenarioStatsChart',
        'downloadStepStats',
        'getScenarioCards',
        'downloadHistoricalStepStats',
      ]),

      async getScenarios() {
        if (!this.organizationId) {
          return;
        }

        const params = { organizationId: this.organizationId, limit: 'all' };
        await this.listWithParams(params);
      },

      updateStatistics() {
        this.getScenarioCards();
        this.getScenarioStatsChart();
        this.getInterruptedAndTotalChart();
        this.getScenarioStatistics();
      },

      loadStepStatistics({ from, to }) {
        return this.downloadStepStats({ scenarioId: this.scenarioId || null, from, to });
      },

      async downloadHistoricalData() {
        const data = await this.downloadHistoricalStepStats();
        const blob = await prepareCsv(data);
        saveAs(blob, 'historicalStepStats.csv');
      },

      downloadStats(key) {
        if (key === 'downloadStatistics') {
          this.isDownloadDialogOpened = true;
          return;
        }

        this.downloadHistoricalData();
      },
    },
  };
</script>
