<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t('pages.users.inviteModal.title') }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-combobox
          v-model="emailList"
          :items="emailList"
          :label="$t('pages.users.inviteModal.emailAddresses')"
          :hint="$t('pages.users.inviteModal.inputHint')"
          multiple
          outlined
          dense
          small-chips
          deletable-chips
          @change="onChange"
        />
      </v-container>

      <div v-if="error" class="red--text">{{ error }}</div>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="blue darken-1" text @click="close">
        {{ $t('ui.common.cancel') }}
      </v-btn>
      <v-btn color="blue darken-1" text :loading="isBusy" @click="save">
        {{ $t('pages.users.inviteModal.invite') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex';

  import { emailRegex } from 'src/utils';

  const props = {
    organizationId: String,
  };

  function data() {
    return {
      emailList: [],
      error: null,
      isBusy: false,
    };
  }

  const methods = {
    ...mapActions('invites', ['send']),

    close() {
      this.clearList();
      this.$emit('close');
    },

    clearList() {
      this.emailList = [];
    },

    async save() {
      if (this.isBusy || !this.emailList.length) {
        return;
      }

      this.isBusy = true;

      try {
        this.error = null;
        await this.send({ emailList: this.emailList, organizationId: this.organizationId });
        this.close();
      } catch (err) {
        this.error = this.$te(`serverCode.${err.code}`)
          ? this.$t(`serverCode.${err.code}`)
          : this.$t('serverCode.serverError');
      } finally {
        this.isBusy = false;
      }
    },

    isEmail(value) {
      return emailRegex.test(String(value).toLowerCase());
    },

    onChange() {
      this.emailList = this.emailList.filter(current => this.isEmail(current));
    },
  };

  export default {
    name: 'EmailInvite',
    props,
    data,
    methods,
  };
</script>

<style scoped>
  .textarea {
    width: 100%;
  }
</style>
