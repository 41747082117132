<template>
  <div class="h-text-field">
    <v-text-field
      v-bind="{ ...$attrs, dense }"
      v-on="$listeners"
      @focusin="onFocus()"
      @focusout="onBlur()"
      @keydown="onKeydown($event)"
    />

    <div
      v-if="showedPreview"
      class="h-text-field__preview"
      :class="{ 'h-text-field__preview--dense': dense }"
    >
      {{ defaultTitle }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HTextField',

    inheritAttrs: false,

    props: {
      defaultValue: {
        type: [String, Boolean, Number],
        required: false,
        default: null,
      },

      defaultTitle: {
        type: [String, Boolean, Number],
        required: false,
        default: null,
      },

      numberInput: {
        type: Boolean,
        required: false,
        default: false,
      },

      positiveNumber: {
        type: Boolean,
        required: false,
        default: false,
      },

      dense: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        focused: false,
      };
    },

    computed: {
      showedPreview() {
        return (
          this.defaultValue != null && this.defaultValue === this.$attrs.value && !this.focused
        );
      },
    },

    methods: {
      onFocus() {
        this.focused = true;
      },

      onKeydown($event) {
        if (!this.numberInput) {
          return;
        }

        if (
          this.positiveNumber &&
          ($event.key === 'ю' || // ю and dot have equal keyKode
            !(
              (
                ($event.keyCode > 95 && $event.keyCode < 106) || // numpad numbers
                ($event.keyCode > 47 && $event.keyCode < 58) || // numbers
                [8, 9, 35, 36, 37, 39, 190].indexOf($event.keyCode) >= 0 || // backspace, tab, home, end, left arrow, right arrow, dot
                ($event.keyCode === 65 && ($event.ctrlKey === true || $event.metaKey === true)) || // Ctrl/Cmd + A
                ($event.keyCode === 67 && ($event.ctrlKey === true || $event.metaKey === true)) || // Ctrl/Cmd + C
                ($event.keyCode === 88 && ($event.ctrlKey === true || $event.metaKey === true)) || // Ctrl/Cmd + X
                ($event.keyCode === 86 && ($event.ctrlKey === true || $event.metaKey === true))
              ) // Ctrl/Cmd + V
            ))
        ) {
          $event.preventDefault();
        }
      },

      onBlur() {
        this.focused = false;
      },
    },
  };
</script>

<style lang="scss">
  .h-text-field {
    position: relative;

    &__preview {
      position: absolute;
      top: 18px;
      left: 12px;
      background: white;
      color: rgba(0, 0, 0, 0.87);
      font-size: 16px;
      pointer-events: none;

      &--dense {
        top: 12px;
      }
    }
  }
</style>
