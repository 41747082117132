<template>
  <v-form ref="form" @submit.prevent="submit()">
    <v-card>
      <v-card-title>
        <span class="headline text-truncate">
          {{ $t(`ui.manageClientModal.title.${clientId ? 'edit' : 'create'}`) }}
        </span>

        <v-spacer />

        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              ref="nameInput"
              v-model="client.name"
              :label="$t('ui.manageClientModal.fields.name')"
              :rules="[rules.getRequiredRule('name')]"
              :error-messages="nameError"
              hide-details="auto"
              outlined
              @input="nameError = null"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="client.site"
              :label="$t('ui.manageClientModal.fields.site')"
              hide-details="auto"
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="client.plan"
              :items="$options.planItems"
              :label="$t('ui.manageClientModal.fields.plan')"
              hide-details="auto"
              outlined
            />
          </v-col>

          <v-col>
            <v-text-field
              v-if="!clientId"
              v-model="client.email"
              :label="$t('ui.manageClientModal.fields.owner')"
              :rules="[rules.getRequiredRule('owner'), rules.getEmailRule]"
              :error-messages="ownerError"
              hide-details="auto"
              outlined
              @input="ownerError = null"
            />

            <v-autocomplete
              v-else
              v-model="client.creatorId"
              :label="$t('ui.manageClientModal.fields.owner')"
              :items="clientUsers"
              item-value="id"
              item-text="email"
              prepend-inner-icon="mdi-magnify"
              hide-details
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text color="primary" @click="close()">
          {{ $t('ui.common.cancel') }}
        </v-btn>

        <v-btn :loading="isBusySubmit" text color="primary" type="submit">
          {{ $t(`ui.common.${clientId ? 'save' : 'create'}`) }}
        </v-btn>
      </v-card-actions>

      <v-overlay :value="isBusyClient" absolute>
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-card>
  </v-form>
</template>

<script>
  import pick from 'lodash.pick';

  import {
    ORGANIZATION_PLAN_ENTERPRISE,
    ORGANIZATION_PLAN_FREE,
    ORGANIZATION_PLAN_SIMULATION,
    ORGANIZATION_PLAN_STANDARD,
    ORGANIZATION_PLAN_STARTUP,
  } from 'src/constants';

  import api from 'src/api';

  import { busyFlow } from 'src/utils';

  const defaultClientData = {
    name: '',
    plan: ORGANIZATION_PLAN_STANDARD,
    site: '',
    email: '',
    creatorId: null,
  };

  export default {
    name: 'ManageClientModal',

    props: {
      clientId: {
        type: String,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        isBusySubmit: false,

        client: { ...defaultClientData },
        clientUsers: [],
        isBusyClient: false,

        nameError: null,
        ownerError: null,

        rules: {
          getRequiredRule: name => v =>
            !!v ||
            this.$t('validation.notNullField', {
              field: this.$t(`ui.manageClientModal.fields.${name}`),
            }),
          getEmailRule: v =>
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((([^<>()[\].,;:\s@"]{2,})+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
              v
            ) || this.$t('validation.emailInvalid'),
        },
      };
    },

    planItems: [
      {
        text: 'Startup',
        value: ORGANIZATION_PLAN_STARTUP,
      },
      {
        text: 'Standard',
        value: ORGANIZATION_PLAN_STANDARD,
      },
      {
        text: 'Enterprise',
        value: ORGANIZATION_PLAN_ENTERPRISE,
      },
      {
        text: 'Simulation',
        value: ORGANIZATION_PLAN_SIMULATION,
      },
      {
        text: 'Free',
        value: ORGANIZATION_PLAN_FREE,
      },
    ],

    async mounted() {
      if (this.clientId) {
        await this.getClient();
      }

      this.$nextTick();
      this.$refs.nameInput.focus();
    },

    methods: {
      getClient() {
        busyFlow.call(
          this,
          async () => {
            let { response } = await api.integrators.getClientById({
              url_params: { id: this.clientId },
            });

            this.client = response;

            ({ response } = await api.integrators.getClientUsers({
              url_params: { id: this.clientId },
              params: {
                limit: 'all',
              },
            }));

            this.clientUsers = Object.freeze(response.payload);
          },
          false,
          'isBusyClient'
        );
      },

      submit() {
        const { form } = this.$refs;
        if (!form.validate()) {
          return;
        }

        const data = pick(
          this.client,
          this.clientId ? ['name', 'plan', 'site', 'creatorId'] : ['name', 'plan', 'site', 'email']
        );

        if (data.site === '') {
          delete data.site;
        }

        busyFlow.call(
          this,
          async () => {
            try {
              if (this.clientId) {
                await api.integrators.updateClient({
                  url_params: { id: this.clientId },
                  data,
                });

                this.$emit('updated');
              } else {
                await api.integrators.createClient({
                  data,
                });

                this.$emit('created');
              }

              this.close();
            } catch (error) {
              switch (error.code) {
                case 'ORGANIZATION_EXIST':
                  this.nameError = this.$t('ui.manageClientModal.errors.nameExistsError');
                  break;
                case 'USER_EXISTS':
                  this.ownerError = this.$t('ui.manageClientModal.errors.ownerExistsError');
                  break;
                default:
                  break;
              }
            }
          },
          false,
          'isBusySubmit'
        );
      },

      close() {
        this.$emit('close');
      },
    },
  };
</script>
