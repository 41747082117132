import api from 'src/api';

const actions = {
  async create(context, payload) {
    const { response } = await api.legal.create({ data: payload });
    return response;
  },

  async update(context, payload) {
    const { response } = await api.legal.update({ data: payload });
    return response;
  },

  async get() {
    const { response } = await api.legal.get();
    return response;
  },

  async getInfo(context, param) {
    const { response } = await api.legal.getInfo({ params: { param } });
    return response;
  },

  async getInvoice() {
    const { response } = await api.legal.getInvoice({ data: { amount: 1 } });
    return response;
  },

  async getDocuments(context, { page, limit } = {}) {
    const params = { page, limit };
    const { response } = await api.legal.getDocuments({ params });
    return response;
  },
};

export default {
  namespaced: true,
  actions,
};
