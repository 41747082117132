<template>
  <v-form ref="form" @submit.prevent="onSubmit()">
    <v-card>
      <v-card-title>
        <span class="headline">
          <template v-if="isEdit">
            {{ $t('pages.organizations.editModal.title.edit') }}
          </template>

          <template v-else>
            {{ $t('pages.organizations.editModal.title.create') }}
          </template>
        </span>
      </v-card-title>

      <v-card-subtitle v-if="isEdit">
        {{ currentItem.id }}
      </v-card-subtitle>

      <v-card-text>
        <v-dialog v-model="isWorkspaceDialogOpened" max-width="750px">
          <edit-workspace
            :workspace="currentWorkspace"
            :is-edit="isWorkspaceEdit"
            @close="closeWorkspaceDialog()"
            @remove="openWorkspaceRemoveDialog()"
            @updated="closeWorkspaceDialogWithListUpdate()"
          />
        </v-dialog>

        <v-dialog v-model="isWorkspaceDeleteModalOpened" max-width="852">
          <workspace-remove-confirm
            :workspace="currentWorkspace"
            @close="isWorkspaceDeleteModalOpened = false"
            @deleted="closeWorkspaceRemoveDialog()"
          />
        </v-dialog>
        <v-container>
          <v-row>
            <template v-if="isCloud">
              <v-col cols="6">
                <v-switch
                  v-model="currentItem.isIntegrator"
                  :label="$t('pages.organizations.editModal.fields.isIntegrator')"
                  hide-details
                  required
                  :disabled="Boolean(currentItem.integratorId)"
                />
              </v-col>

              <v-col cols="6">
                <v-autocomplete
                  v-model="currentItem.integratorId"
                  :search-input.sync="integratorsPattern"
                  :items="integratorsList"
                  :loading="isBusyIntegrators"
                  :disabled="currentItem.isIntegrator"
                  :label="$t('pages.organizations.editModal.fields.integratorId')"
                  :error-messages="integratorError"
                  item-value="id"
                  item-text="name"
                  outlined
                  hide-details
                  cache-items
                  clearable
                  @input="integratorError = null"
                />
              </v-col>
            </template>

            <v-col cols="6">
              <v-text-field
                ref="titleInput"
                v-model="currentItem.name"
                :label="$t('pages.organizations.editModal.fields.title')"
                required
                outlined
                hide-details="auto"
                :disabled="isOrgNameNotEditable()"
                :rules="[rules.getRequiredRule('title')]"
                :error-messages="organizationExistsError"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="currentItem.site"
                :label="$t('pages.organizations.editModal.fields.site')"
                required
                outlined
                hide-details="auto"
              />
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="currentItem.plan"
                :items="planItems"
                :label="$t('pages.organizations.editModal.fields.plan')"
                :disabled="isSmartManualsPlansSelected"
                required
                outlined
                hide-details
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="currentItem.shortname"
                :label="$t('pages.organizations.editModal.fields.shortname')"
                :rules="[rules.getShortNameRule]"
                :error-messages="shortnameExistsError"
                required
                outlined
                :disabled="isOrgNameNotEditable()"
                hide-details="auto"
                @input="shortnameExistsError = null"
              />
            </v-col>

            <template v-if="isEdit">
              <v-col :cols="!currentItem.integratorId ? 6 : 12">
                <v-autocomplete
                  v-model="currentItem.creatorId"
                  :items="orgUsersWithOwner"
                  :label="$t('pages.organizations.editModal.fields.owner')"
                  item-text="text"
                  item-value="value"
                  prepend-inner-icon="mdi-magnify"
                  required
                  hide-details
                  outlined
                />
              </v-col>

              <v-col v-if="!currentItem.integratorId" cols="6">
                <v-menu
                  ref="activeUntil"
                  v-model="activeUntilMenu"
                  :close-on-content-click="false"
                  :return-value.sync="activeUntil"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      ref="activeUntilInput"
                      v-model="activeUntil"
                      :label="$t('pages.organizations.editModal.fields.activeUntil')"
                      :disabled="isSmartManualsPlansSelected"
                      prepend-inner-icon="mdi-calendar"
                      hide-details
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      @click:prepend-inner="on.click($event)"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker v-model="activeUntil" no-title scrollable>
                    <v-spacer />

                    <v-btn text color="primary" @click="activeUntilMenu = false">
                      {{ $t('ui.common.cancel') }}
                    </v-btn>

                    <v-btn text color="primary" @click="$refs.activeUntil.save(activeUntil)">
                      {{ $t('ui.common.ok') }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </template>

            <template v-if="isEdit && isSuperAdmin && hasLDAP">
              <v-col cols="10">
                <v-combobox
                  ref="combobox"
                  :items="orgWorkspaces"
                  :type="isSuperAdmin ? 'text' : 'button'"
                  hide-selected
                  multiple
                  item-text="title"
                  item-value="id"
                  :label="$t('pages.organizations.editModal.fields.workspaces')"
                  :placeholder="$t('pages.organizations.editModal.fields.workspaces')"
                  outlined
                >
                  <template #item="{ item }">
                    {{ item.title }}

                    <v-spacer />

                    <v-list-item-action
                      class="flex align-center flex-row  flex-grow-0 flex-nowrap"
                      @click.stop
                    >
                      <v-btn icon color="red" @click="openWorkspaceDialog(item, true)">
                        <v-icon small>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </template>
                </v-combobox>
              </v-col>

              <v-col class="pl-0" cols="2">
                <v-btn
                  large
                  color="primary"
                  min-width="62"
                  width="62"
                  height="54"
                  @click="
                    openWorkspaceDialog(
                      { title: null, shortname: null, organizationId: currentItem.id },
                      false
                    )
                  "
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-col cols="6">
          <organization-statistic-remove
            v-if="currentItem.id && currentItem.name"
            :id="currentItem.id"
            :company-name="currentItem.name"
          />
        </v-col>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="close()">
          {{ $t('ui.common.cancel') }}
        </v-btn>

        <v-btn :loading="isBusy" color="blue darken-1" text type="submit">
          {{ $t(`ui.common.${isEdit ? 'save' : 'create'}`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import moment from 'moment';

  import { busyFlow } from 'src/utils';
  import {
    ORGANIZATION_PLAN_ENTERPRISE,
    ORGANIZATION_PLAN_STANDARD,
    ORGANIZATION_PLAN_STARTUP,
    ORGANIZATION_PLAN_SIMULATION,
    ORGANIZATION_PLAN_FREE,
    ORGANIZATION_PLAN_SMART_MANUALS_PRO,
    ORGANIZATION_PLAN_SMART_MANUALS_START,
    ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,
  } from 'src/constants';
  import api from 'src/api';
  import EditWorkspace from './EditWorkspace.vue';
  import WorkspaceRemoveConfirm from './WorkspaceRemoveConfirm/WorkspaceRemoveConfirm.vue';
  import OrganizationStatisticRemove from './OrganizationStatisticRemove.vue';

  const defaultOrganizationInfo = {
    plan: ORGANIZATION_PLAN_STANDARD,
    isIntegrator: false,
    integratorId: null,
  };

  export default {
    name: 'OrganizationEdit',

    components: {
      EditWorkspace,
      WorkspaceRemoveConfirm,
      OrganizationStatisticRemove,
    },

    model: {
      prop: 'value',
      event: 'change',
    },

    props: {
      value: {
        type: String,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        isBusy: false,
        activeUntilMenu: false,
        currentItem: {},
        currentItemId: null,
        rules: {
          getRequiredRule: name => v =>
            !!v ||
            this.$t('validation.notNullField', {
              field: this.$t(`pages.organizations.editModal.fields.${name}`),
            }),
          getShortNameRule: v =>
            !v ||
            /^[a-z0-9]+$/i.test(v) ||
            this.$t('pages.organizations.editModal.validation.shortname'),
        },
        shortnameExistsError: null,
        organizationExistsError: null,
        orgUsers: [],
        orgUsersWithOwner: [],
        orgWorkspaces: [],
        workspaceUsers: [],
        isWorkspaceDialogOpened: false,
        currentWorkspace: {},
        isWorkspaceEdit: false,
        isWorkspaceDeleteModalOpened: false,

        isBusyIntegrators: false,
        integratorsPattern: '',
        integratorsList: [],
        integratorError: null,
      };
    },

    computed: {
      ...mapState('settings', ['hasLDAP', 'isCloud']),

      planItems() {
        return [
          {
            text: 'Startup',
            value: ORGANIZATION_PLAN_STARTUP,
          },
          {
            text: 'Standard',
            value: ORGANIZATION_PLAN_STANDARD,
          },
          {
            text: 'Enterprise',
            value: ORGANIZATION_PLAN_ENTERPRISE,
          },
          {
            text: 'Simulation',
            value: ORGANIZATION_PLAN_SIMULATION,
          },
          {
            text: 'Free',
            value: ORGANIZATION_PLAN_FREE,
          },

          ...(this.isEdit &&
          [
            ORGANIZATION_PLAN_SMART_MANUALS_PRO,
            ORGANIZATION_PLAN_SMART_MANUALS_START,
            ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,
          ].includes(this.currentItem.plan)
            ? [
                {
                  text: 'Smart Manuals Start',
                  value: ORGANIZATION_PLAN_SMART_MANUALS_START,
                },
                {
                  text: 'Smart Manuals Pro',
                  value: ORGANIZATION_PLAN_SMART_MANUALS_PRO,
                },
                {
                  text: 'Smart Manuals Enterprise',
                  value: ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,
                },
              ]
            : []),
        ];
      },

      isSmartManualsPlansSelected() {
        if (!this.isEdit) {
          return false;
        }

        return [
          ORGANIZATION_PLAN_SMART_MANUALS_START,
          ORGANIZATION_PLAN_SMART_MANUALS_PRO,
          ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE,
        ].includes(this.currentItem.plan);
      },

      isEdit() {
        return Boolean(this.currentItemId);
      },

      activeUntil: {
        get() {
          if (!this.isEdit) {
            return null;
          }

          return moment(this.currentItem.activeUntil).format('YYYY-MM-DD');
        },
        set(value) {
          this.currentItem.activeUntil = value;
        },
      },
    },

    watch: {
      value: {
        deep: true,
        immediate: true,
        async handler(value) {
          await this.$nextTick();
          this.$refs.form.resetValidation();
          this.currentItemId = value;

          if (this.isEdit) {
            this.currentItem = await this.getOrganization(this.currentItemId);

            const params = { organizationId: this.currentItemId };
            const usersQuery = await api.users.list({ params });

            const organizationUsers = usersQuery.response.users.map(user => {
              return {
                text: user.email,
                value: user.id,
              };
            });

            this.orgUsers = organizationUsers;

            if (!organizationUsers.find(x => x.value === this.currentItem.creatorId)) {
              organizationUsers.push({
                text: this.currentItem.creatorEmail,
                value: this.currentItem.creatorId,
              });
            }

            this.orgUsersWithOwner = organizationUsers;

            await this.updateWorkspacesList();

            await this.getCurrentIntegrator();
          } else {
            this.currentItem = { ...defaultOrganizationInfo };
          }

          await this.$nextTick();
          this.$refs.titleInput.focus();
        },
      },

      isWorkspaceDialogOpened: {
        handler() {
          this.$refs.combobox.isMenuActive = false;
        },
      },

      integratorsPattern: {
        handler() {
          this.getIntegratorsList();
        },
        immediate: true,
      },
    },

    methods: {
      ...mapActions('organizations', {
        organizationCreate: 'create',
        organizationUpdate: 'update',
        updateInfo: 'updateInfo',
        updateOrgOwner: 'updateOrgOwner',
        getOrganization: 'getOrganization',
      }),
      ...mapActions('users', ['getUsersWithOrgOwner']),
      ...mapGetters('users', ['isSuperAdmin']),

      close() {
        this.$emit('close');
      },

      openWorkspaceDialog(item, isEdit) {
        this.currentWorkspace = item;
        this.isWorkspaceEdit = isEdit;
        this.isWorkspaceDialogOpened = true;
      },

      closeWorkspaceDialog() {
        this.isWorkspaceDialogOpened = false;
      },

      closeWorkspaceDialogWithListUpdate() {
        this.updateWorkspacesList();
        this.isWorkspaceDialogOpened = false;
      },

      openWorkspaceRemoveDialog() {
        this.isWorkspaceDialogOpened = false;
        this.isWorkspaceDeleteModalOpened = true;
      },

      closeWorkspaceRemoveDialog() {
        this.updateWorkspacesList();
        this.isWorkspaceDeleteModalOpened = false;
      },

      async updateWorkspacesList() {
        if (!this.isSuperAdmin || !this.hasLDAP) {
          return;
        }

        const orgWorkspacesQuery = await api.workspaces.listByOrgId({
          url_params: { orgId: this.currentItem.id },
        });

        this.orgWorkspaces = orgWorkspacesQuery.response.filter(workspace => !workspace.isRoot);
      },

      async onSubmit() {
        const { form } = this.$refs;

        if (!form.validate()) {
          return;
        }

        await busyFlow.call(this, async () => {
          const payload = {
            name: this.currentItem.name,
            site: this.currentItem.site || null,
            isIntegrator: this.currentItem.isIntegrator || false,
            integratorId: this.currentItem.integratorId || null,
            ...(!this.isEdit ? { plan: this.currentItem.plan } : {}),
            ...(this.isEdit ? { id: this.currentItem.id } : {}),
            ...(this.currentItem.shortname ? { shortname: this.currentItem.shortname } : {}),
          };

          try {
            if (this.isEdit) {
              await this.organizationUpdate(payload);

              const currentDate = moment();

              const activeUntil = moment(this.currentItem.activeUntil)
                .set({ hours: currentDate.get('hours'), minutes: currentDate.get('minutes') })
                .utc()
                .valueOf();

              await this.updateInfo({
                id: payload.id,
                payload: { plan: this.currentItem.plan, activeUntil },
              });

              await this.updateOrgOwner({
                id: payload.id,
                payload: {
                  creatorId: this.currentItem.creatorId,
                },
              });

              this.$emit('updated');
            } else {
              await this.organizationCreate(payload);
              this.$emit('created');
            }
          } catch (error) {
            switch (error.code) {
              case 'SHORTNAME_ALREADY_TAKEN':
                this.shortnameExistsError = this.$t(
                  'pages.organizations.editModal.validation.shortnameExists'
                );
                break;
              case 'ORGANIZATION_EXIST':
                this.organizationExistsError = this.$t(
                  'pages.organizations.editModal.validation.organizationExists'
                );
                break;
              case 'ORGANIZATION_INTEGRATOR_TYPES_CONFLICT':
                this.integratorError = this.$t(
                  'pages.organizations.editModal.validation.integratorTypesConflict'
                );
                break;
              case 'INTEGRATOR_NOT_FOUND':
                this.integratorError = this.$t(
                  'pages.organizations.editModal.validation.integratorNotFound'
                );
                break;
              default:
                break;
            }
          }
        });
      },

      isOrgNameNotEditable() {
        if (!this.hasLDAP) {
          return false;
        }
        return this.orgUsers.length >= 1;
      },

      async getCurrentIntegrator() {
        if (!this.isEdit || !this.currentItem.integratorId) {
          return;
        }

        const { response } = await api.organizations.getOne({
          url_params: { id: this.currentItem.integratorId },
        });

        this.integratorsList.push(Object.freeze(response));
      },

      async getIntegratorsList() {
        const { response } = await api.integrators.list({
          params: {
            limit: 5,
            ...(this.integratorsPattern ? { pattern: this.integratorsPattern } : {}),
          },
        });

        this.integratorsList.push(...Object.freeze(response.payload));
      },
    },
  };
</script>
