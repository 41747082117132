var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-5 mx-2"},[_c('v-card-text',{staticClass:"pa-5"},[_c('base-table-title',[_vm._v(" "+_vm._s(_vm.$t('pages.statistics.scenarioStepStatistics.sectionTitle'))+": ")]),_c('v-data-table',{staticClass:"statistic-table",attrs:{"headers":_vm.headers,"items":_vm.scenarioStatisticsTableData,"loading":_vm.isBusy,"items-per-page":_vm.itemsPerPage},on:{"click:row":_vm.handleSelectScenario},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('travolta-not-found')]},proxy:true},{key:"item.played",fn:function(ref){
var item = ref.item;
return [_c('base-conversion-bar',{attrs:{"value":item.played.value,"total":item.played.value,"percent":item.played.percent}})]}},{key:"item.interrupt",fn:function(ref){
var item = ref.item;
return [_c('base-conversion-bar',{attrs:{"value":item.interrupt.value,"total":item.played.value,"percent":item.interrupt.percent,"show-percent":""}})]}},{key:"item.stop",fn:function(ref){
var item = ref.item;
return [_c('base-conversion-bar',{attrs:{"value":item.stop.value,"total":item.played.value,"percent":item.stop.percent,"show-percent":""}})]}},{key:"item.complete",fn:function(ref){
var item = ref.item;
return [_c('base-conversion-bar',{attrs:{"value":item.complete.value,"total":item.played.value,"percent":item.complete.percent,"show-percent":""}})]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [(item.rate.hasRate)?_c('v-row',{attrs:{"align":"center"}},[_c('v-rating',{attrs:{"size":"small","half-increments":""},model:{value:(item.rate.value),callback:function ($$v) {_vm.$set(item.rate, "value", $$v)},expression:"item.rate.value"}}),_c('span',[_vm._v(_vm._s(item.rate.value))])],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }