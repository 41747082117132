import api from 'src/api';

import { SET_USER_WORKSPACE, SET_WORKSPACES_LIST } from 'src/store/mutation-types';

export default {
  namespaced: true,

  state: {
    workspacesList: [],
  },

  getters: {
    hasAccessToWorkspaces: (state, getters, rootState, rootGetters) =>
      rootGetters['users/isEnterprisePlanActive'] ||
      rootGetters['users/isSmartManualsEnterprisePlanActive'],

    selectedWorkspace: (state, getters, rootState) => {
      if (!getters.hasAccessToWorkspaces) {
        return null;
      }

      return state.workspacesList.find(
        workspace => workspace.id === rootState.users.currentUser.workspaceId
      );
    },

    selectedWorkspaceIsRoot: (state, getters) => {
      if (!getters.selectedWorkspace) {
        return true;
      }

      return getters.selectedWorkspace.isRoot;
    },
  },

  actions: {
    async getWorkspacesList({ commit }) {
      const { response } = await api.workspaces.list();

      commit(SET_WORKSPACES_LIST, response);
    },

    async selectWorkspace({ commit, getters }, id) {
      if (id === getters.selectedWorkspace.id) {
        return;
      }

      await api.workspaces.select({ url_params: { id } });

      commit(`users/${SET_USER_WORKSPACE}`, id, { root: true });
    },

    async createWorkspace({ dispatch }, data) {
      const { response } = await api.workspaces.create({ data });

      dispatch('getWorkspacesList');

      return response;
    },

    async createWorkspaceByOrgId({ dispatch }, { orgId, data }) {
      const { response } = await api.workspaces.createByOrgId({ url_params: { orgId }, data });

      dispatch('getWorkspacesList');

      return response;
    },

    async updateWorkspace({ dispatch }, { id, data }) {
      const { response } = await api.workspaces.update({ url_params: { id }, data });

      dispatch('getWorkspacesList');

      return response;
    },

    async removeWorkspace({ state, getters, dispatch }, { id, title }) {
      if (getters.selectedWorkspace && id === getters.selectedWorkspace.id) {
        const rootWorkspace = state.workspacesList.find(workspace => workspace.isRoot);

        await dispatch('selectWorkspace', rootWorkspace.id);
      }

      const { response } = await api.workspaces.remove({ url_params: { id }, data: { title } });
      dispatch('getWorkspacesList');

      return response;
    },
  },

  mutations: {
    [SET_WORKSPACES_LIST](state, list) {
      state.workspacesList = list;
    },
  },
};
