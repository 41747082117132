<script>
  import { mapActions, mapState } from 'vuex';
  import urlParse from 'url-parse';

  import api from 'src/api';

  import BaseAddingForm from './BaseAddingForm.vue';

  const TYPE_WEB = 'WEB';

  export default {
    name: 'GroupTooltipsAddingForm',

    extends: BaseAddingForm,

    props: {
      mode: {
        type: String,
        required: false,
        default: 'WEB',
      },
    },

    computed: {
      ...mapState('groups', ['groupTooltips', 'groupTooltipsCount']),
      ...mapState('tooltips', ['tooltipsList', 'tooltipsCount']),

      title() {
        return this.$t('pages.groups.addingModal.title.tooltips');
      },

      allListTitle() {
        return this.$t('pages.groups.addingModal.all.tooltips');
      },

      addedListTitle() {
        return this.$t('pages.groups.addingModal.inGroup.tooltips');
      },

      allItems() {
        if (this.mode === TYPE_WEB) {
          return this.tooltipsList;
        }

        return this.allList;
      },

      allItemsCount() {
        if (this.mode === TYPE_WEB) {
          return this.tooltipsCount;
        }

        return this.allCount;
      },

      addedItems() {
        return this.groupTooltips;
      },

      addedItemsCount() {
        return this.groupTooltipsCount;
      },
    },

    methods: {
      ...mapActions('groups', ['getTooltips', 'clearTooltips', 'addTooltips', 'removeTooltips']),
      ...mapActions('tooltips', { getAllWebTooltips: 'getList' }),

      itemInEntity(item) {
        return item.isInGroup;
      },

      mapItem(item) {
        if (this.mode === TYPE_WEB) {
          const url = item.origin && urlParse(item.origin);

          return {
            ...item,
            title: item.title?.trim() || '---',
            subtitle: url?.hostname || '---',
          };
        }

        return {
          ...item,
          title: item.title?.trim() || '---',
          subtitle: item.description || '',
        };
      },

      getAddedListMethod() {
        const params = this.currentAddedListParams;

        const queryParams = {
          type: this.mode,
          limit: params.limit,
          page: params.page,
          groupId: this.entity.id,
          ...(params.pattern ? { pattern: params.pattern } : {}),
        };

        return this.getTooltips(queryParams);
      },

      async getAllListMethod() {
        const { limit, page, pattern } = this.currentAllListParams;
        const groupId = this.entity.id;

        if (this.mode === TYPE_WEB) {
          await this.getAllWebTooltips({ limit, page, pattern, groupId });

          return;
        }

        const { response } = await api.desktopTooltips.list({
          params: { limit, page, group_id: groupId, ...(pattern ? { pattern } : {}) },
        });

        this.allList = response.payload;
        this.allCount = response.count;
      },

      removeFromEntityMethod(tooltip) {
        return this.removeTooltips({
          groupId: this.entity.id,
          tooltips: [tooltip.id],
          type: this.mode,
        });
      },

      addToEntityMethod(tooltip) {
        return this.addTooltips({
          groupId: this.entity.id,
          tooltips: [tooltip.id],
          type: this.mode,
        });
      },

      clearListsMethod() {
        return this.clearTooltips();
      },
    },
  };
</script>
