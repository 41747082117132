const list = {
  name: 'list',
  method: 'get',
  url: 'list',
};

const create = {
  name: 'create',
  method: 'post',
  url: '/api/checklists',
};

const update = {
  name: 'update',
  method: 'patch',
  url: ':id',
};

const remove = {
  name: 'remove',
  method: 'delete',
  url: ':id',
};

const restore = {
  name: 'restore',
  method: 'post',
  url: ':id/restore',
};

const getGroups = {
  name: 'getGroups',
  method: 'get',
  url: ':id/groups',
};

const getScenarios = {
  name: 'getScenarios',
  method: 'get',
  url: ':id/scenarios',
};

const addScenario = {
  name: 'addScenario',
  method: 'post',
  url: ':id/add-scenario',
};

const removeScenario = {
  name: 'removeScenario',
  method: 'post',
  url: ':id/remove-scenario',
};

const changeWorkspace = {
  name: 'changeWorkspace',
  method: 'post',
  url: 'change-workspace',
};

export default {
  name: 'checklists',
  url: '/api/checklists/',
  children: [
    list,
    create,
    update,
    remove,
    restore,
    getGroups,
    getScenarios,
    addScenario,
    removeScenario,
    changeWorkspace,
  ],
};
