const list = {
  name: 'list',
  method: 'get',
  url: '/api/users/global',
};

const getOrganizations = {
  name: 'getOrganizations',
  method: 'get',
  url: ':id/organizations',
};

const updateOrganizations = {
  name: 'updateOrganizations',
  method: 'patch',
  url: ':id/organizations',
};

const create = {
  name: 'create',
  method: 'post',
  url: '/api/users/global',
};

const update = {
  name: 'update',
  method: 'patch',
  url: ':id',
};

const remove = {
  name: 'remove',
  method: 'delete',
  url: ':id',
};

export default {
  name: 'accounts',
  url: '/api/users/global',
  children: [list, getOrganizations, updateOrganizations, create, update, remove],
};
