export const ORGANIZATION_PLAN_STANDARD = 'standard';
export const ORGANIZATION_PLAN_ENTERPRISE = 'enterprise';
export const ORGANIZATION_PLAN_STARTUP = 'startup';
export const ORGANIZATION_PLAN_SIMULATION = 'simulation';
export const ORGANIZATION_PLAN_FREE = 'free';
export const ORGANIZATION_PLAN_SMART_MANUALS_START = 'smartManualsStart';
export const ORGANIZATION_PLAN_SMART_MANUALS_PRO = 'smartManualsPro';
export const ORGANIZATION_PLAN_SMART_MANUALS_ENTERPRISE = 'smartManualsEnterprise';

export const SUPERADMIN_ROLE = 'SUPERADMIN';
export const ADMIN_ROLE = 'ADMIN';
export const ANALYST_ROLE = 'ANALYST';

export const NOTIFICATIONS_LIST_TYPE_ALL = 'all';
export const NOTIFICATIONS_LIST_TYPE_UNREAD = 'unread';

export const NOTIFICATION_TYPE_ENTITY_MODERATION = 'moderateContent';

export const EVENT_TYPE_READ = 'read';
export const EVENT_TYPE_CONTENT_MODERATION = 'moderateContent';
export const EVENT_TYPE_CHANGE_DRAFT_STATUS = 'changeDraftStatus';

export const APP_BUILD_SPEC_INTERNATIONAL = 'INTERNATIONAL';
export const APP_BUILD_SPEC_RU = 'RU';

export const LOAD_IDLE = 'IDLE';
export const LOAD_PENDING = 'PENDING';
export const LOAD_SUCCESS = 'SUCCESS';
export const LOAD_FAILED = 'FAILED';

export const LOCALE_RU = 'ru';
export const LOCALE_EN = 'en';
export const LOCALE_ES = 'es';
export const LOCALE_PT = 'pt';
