<template>
  <v-container fluid>
    <v-row class="content">
      <v-col class="d-flex flex-column justify-center align-center mb-4">
        <div class="pa-6 rounded d-flex flex-column justify-center align-center info-message">
          <v-avatar color="rgba(50,161,246, 0.1)" size="75">
            <v-icon color="#32A1F6" size="35">$mail</v-icon>
          </v-avatar>

          <p class="py-4 text-center">
            {{ $t('pages.mobileSendLink.infoSendingMessage', { email: userEmail }) }}
          </p>

          <p class="mb-0">
            {{ $t('pages.mobileSendLink.followLinkMessage') }}
          </p>
        </div>

        <send-login-link />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';
  import SendLoginLink from 'src/components/SendLoginLink/SendLoginLink.vue';

  export default {
    name: 'EmailLoginLink',

    components: {
      SendLoginLink,
    },

    computed: mapState({
      userEmail: state => state.users.currentUser.email,
    }),
  };
</script>

<style lang="scss" scoped>
  .content {
    height: 100%;
  }

  .info-message {
    background-color: rgba(50, 161, 246, 0.05);
    width: 100%;
    color: #32a1f6;
  }
</style>
