<template>
  <v-card v-if="chartData.length" class-name="mb-5">
    <v-card-text class-name="pa-5">
      <div className="mt-2">
        <vue-apex-charts
          class-name="chart"
          height="390"
          :series="preparedData"
          :options="chartOptions"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  export default {
    name: 'SimulationPlayingChart',

    components: {
      VueApexCharts,
    },

    mixins: [formatTimestampMixin],

    props: {
      chartData: {
        type: Array,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        chartOptions: Object.freeze({
          chart: {
            height: 370,
            type: 'area',
            stacked: false,
            fontFamily: 'Montserrat,sans-serif',
            toolbar: {
              show: false,
            },
          },

          dataLabels: {
            enabled: false,
          },

          markers: {
            size: 3,
            strokeColors: 'transparent',
          },

          stroke: {
            curve: 'smooth',
            width: '2',
          },

          colors: ['#abceff', '#398bf7', '#7460ee', '#9c94ee'],

          legend: {
            show: false,
          },

          grid: {
            show: true,
            strokeDashArray: 0,
            borderColor: 'rgba(0,0,0,0.1)',
            yaxis: {
              show: false,
            },
            padding: {
              left: 30,
              right: 30,
            },
          },

          yaxis: {
            labels: {
              formatter: value => {
                return String(value);
              },

              style: {
                colors: '#a1aab2',
              },
            },
          },

          tooltip: {
            theme: 'dark',
            fillColors: ['#abceff', '#398bf7', '#7460ee', '#9c94ee'],
            y: {
              title: {
                formatter: type => `${this.$t(`pages.statistics.charts.${type}`)}:`,
              },
            },
          },
        }),
      };
    },

    computed: {
      preparedData() {
        return this.chartData.map(item => ({
          ...item,
          data: item.data.map(dataItem => ({ ...dataItem, x: this.formatDate(dataItem.x) })),
        }));
      },
    },
  };
</script>

<style type="scss">
  .chart {
    position: relative;
    width: 100%;
    height: 350px;
  }
</style>
