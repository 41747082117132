<template>
  <v-card>
    <v-container>
      <template v-if="isAdminOrHigher">
        <v-card-text>
          <p class="text--darken-1 body-2 font-weight-bold">
            {{ $t('pages.install.siteCode.cloud.stepOne') }}
          </p>
          <p>
            <v-switch
              v-model="isFramework"
              :label="$t('pages.install.siteCode.cloud.stepOneSwitch')"
            />

            <v-text-field
              v-if="isFramework"
              v-model="debouncedSpa"
              :label="$t('pages.install.siteCode.cloud.stepOneTextField')"
              hide-details
              dense
              outlined
              style="width: 320px"
              required
            />
          </p>

          <p class="text--darken-1 body-2 font-weight-bold">
            {{ $t('pages.install.siteCode.cloud.stepTwo') }}
          </p>
          <p>
            {{ $t('pages.install.siteCode.cloud.stepTwoDesc') }}
          </p>
          <p>
            <v-toolbar class="rounded-lg white--text subtitle-1" color="#343349">
              {{ widgetScript }}
              <v-spacer />
              <v-btn icon color="#DAE4E8" @click="copyCode()">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </v-toolbar>

            <v-switch
              v-model="debouncedStepStats"
              :label="$t('pages.install.siteCode.saveStepStats')"
            />
          </p>

          <p class="text--darken-1 body-2 font-weight-bold">
            {{ $t('pages.install.siteCode.cloud.stepThree') }}
          </p>
          <p>
            {{
              $t('pages.install.siteCode.cloud.stepThreeDesc', {
                companyName: $options.COMPANY_NAME,
              })
            }}
          </p>
        </v-card-text>
      </template>
    </v-container>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import debounce from 'lodash.debounce';

  import { copyText } from 'src/utils';
  import { buildSpecConfig } from 'src/config';

  export default {
    name: 'SiteCode',

    data() {
      return {
        isFramework: false,
        spaSelector: '#app',
        widgetScript: null,
        orgSettings: {},
        saveStepStats: false,
      };
    },

    COMPANY_NAME: buildSpecConfig.companyName,

    computed: {
      ...mapState('users', ['currentUser']),
      ...mapState('settings', ['isCloud']),
      ...mapGetters('users', ['isAdminOrHigher']),

      debouncedSpa: {
        get() {
          return this.spaSelector;
        },

        set(value) {
          this.debouncedSetSpa(value);
        },
      },

      debouncedStepStats: {
        get() {
          return this.saveStepStats;
        },

        set(value) {
          this.debouncedUpdateStepStats(value);
        },
      },
    },

    watch: {
      isFramework: {
        async handler() {
          this.widgetScript = this.getScript();
          await this.updateSpaSettings();
        },
      },

      spaSelector: {
        async handler() {
          this.widgetScript = this.getScript();
          await this.updateSpaSettings();
        },
      },

      saveStepStats: {
        async handler() {
          await this.updateStepStatsSettings();
        },
      },
    },

    async mounted() {
      this.orgSettings = await this.getOrgSettings({ id: this.currentUser.organizationId });
      this.isFramework = this.orgSettings.adminPanel.pages?.install.isSpa || false;
      this.spaSelector = this.orgSettings.adminPanel.pages?.install.spaSelector || '#app';
      this.saveStepStats = this.orgSettings.saveStepStats || true;
      this.widgetScript = this.getScript();
    },

    methods: {
      ...mapActions('organizations', ['updateOrgSettings', 'getOrgSettings']),

      debouncedSetSpa: debounce(function setSpa(value) {
        this.spaSelector = value;
      }, 300),

      debouncedUpdateStepStats: debounce(function updateStats(value) {
        this.saveStepStats = value;
      }, 300),

      getScript() {
        const { origin } = window.location;

        const cloudUrl = `https://${buildSpecConfig.companyDomain}/script.js`;

        const widgetLink = this.isCloud
          ? cloudUrl
          : `${origin}/plugins/hinted-ondemand-widget.min.js`;

        let script = `<script src="${widgetLink}" organizationId="${this.currentUser.organizationId}" host="${origin}/api"`;
        const stringEnd = 'script>';
        if (this.isFramework) {
          script = `${script} isSpa="true" spaSelector="${this.spaSelector}"`;
        }

        return `${script}></${stringEnd}`;
      },

      async updateSpaSettings() {
        await this.updateOrgSettings({
          payload: {
            adminPanel: {
              pages: {
                install: {
                  isSpa: this.isFramework,
                  spaSelector: this.spaSelector,
                },
              },
            },
          },
        });
      },

      async updateStepStatsSettings() {
        await this.updateOrgSettings({
          payload: {
            saveStepStats: this.saveStepStats,
          },
        });
      },

      copyCode() {
        copyText(this.widgetScript);
      },
    },
  };
</script>
