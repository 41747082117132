<template>
  <v-chip
    v-if="showedChip"
    :color="chipData.color"
    :text-color="chipData.textColor"
    label
    class="text-uppercase font-weight-bold client-status-chip"
  >
    <span class="client-status-chip__text"> {{ chipData.text }}</span>
  </v-chip>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'ClientStatusChip',

    props: {
      client: {
        type: Object,
        required: true,
      },
    },

    computed: {
      showedChip() {
        return Boolean(this.client.startDate);
      },

      chipData() {
        if (!this.showedChip) {
          return null;
        }

        if (this.client.blocked) {
          return {
            text: this.$t('pages.integratorClients.statuses.blocked'),
            color: '#D6F3FF',
            textColor: '#32A1F6',
          };
        }

        const startDate = moment.utc(this.client.startDate);
        const endDate = moment.utc(this.client.endDate);

        if (startDate.isAfter(moment())) {
          return {
            text: this.$t('pages.integratorClients.statuses.waiting'),
            color: '#FFF2E1',
            textColor: '#FFA630',
          };
        }

        if (endDate.isBefore(moment())) {
          return {
            text: this.$t('pages.integratorClients.statuses.ended'),
            color: '#D6D5DB',
            textColor: '#343349',
          };
        }

        if (endDate.diff(moment(), 'days', true) <= 7) {
          return {
            text: this.$t('pages.integratorClients.statuses.ends'),
            color: '#FFD4E4',
            textColor: '#D81159',
          };
        }

        return {
          text: this.$t('pages.integratorClients.statuses.active'),
          color: '#D2FFEB',
          textColor: '#02AC5E',
        };
      },
    },
  };
</script>

<style lang="scss">
  .client-status-chip {
    &__text {
      font-size: 12px;
      line-height: 14px;
    }
  }
</style>
