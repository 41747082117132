<script>
  import { mapActions, mapState } from 'vuex';

  import BaseAddingForm from './BaseAddingForm.vue';

  export default {
    name: 'GroupUsersAddingForm',

    extends: BaseAddingForm,

    computed: {
      ...mapState('groups', ['groupUsers', 'groupUsersCount']),
      ...mapState('users', ['usersList', 'usersCount']),

      title() {
        return this.$t('pages.groups.addingModal.title.users');
      },

      allListTitle() {
        return this.$t('pages.groups.addingModal.all.users');
      },

      addedListTitle() {
        return this.$t('pages.groups.addingModal.inGroup.users');
      },

      allItems() {
        return this.usersList;
      },

      allItemsCount() {
        return this.usersCount;
      },

      addedItems() {
        return this.groupUsers;
      },

      addedItemsCount() {
        return this.groupUsersCount;
      },
    },

    methods: {
      ...mapActions('groups', ['getUsers', 'clearUsers', 'addUsers', 'removeUsers']),
      ...mapActions('users', { getAllUsers: 'list' }),

      itemInEntity(item) {
        return item.isInGroup;
      },

      mapItem(item) {
        return {
          ...item,
          title: item.email,
          subtitle: this.$t(`common.roles.${item.role.toUpperCase()}`),
        };
      },

      getAddedListMethod() {
        const params = this.currentAddedListParams;

        const queryParams = {
          limit: params.limit,
          page: params.page,
          groupId: this.entity.id,
          ...(params.pattern ? { pattern: params.pattern } : {}),
        };

        return this.getUsers(queryParams);
      },

      async getAllListMethod() {
        const { limit, page, pattern } = this.currentAllListParams;
        const groupId = this.entity.id;

        await this.getAllUsers({ limit, page, pattern, groupId });
      },

      removeFromEntityMethod(user) {
        return this.removeUsers({ groupId: this.entity.id, users: [user.id] });
      },

      addToEntityMethod(user) {
        return this.addUsers({ groupId: this.entity.id, users: [user.id] });
      },

      clearListsMethod() {
        return this.clearUsers();
      },
    },
  };
</script>
