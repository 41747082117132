<template>
  <v-menu
    v-if="showedMenu"
    v-model="popupOpened"
    bottom
    offset-y
    :close-on-content-click="false"
    max-width="256"
    content-class="menu-offset-class"
    rounded="0"
  >
    <template #activator="{ on, attrs }">
      <v-list-item class="profile-bg" v-bind="attrs" v-on="on">
        <v-list-item-content class="py-4">
          <v-list-item-title>
            {{ $t('common.fields.organization') }}
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ selectedOrganization.name }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-icon>{{ menuIcon }}</v-icon>
      </v-list-item>
    </template>

    <v-card tile>
      <v-list nav>
        <v-text-field
          v-model="debouncedPattern"
          dense
          hide-details
          append-icon="mdi-magnify"
          :label="$t('ui.organizationsMenu.searchLabel')"
          class="my-4"
        />
        <v-list-item-group v-model="selectedOrganizationId">
          <v-list-item-subtitle class="mb-2">Команды</v-list-item-subtitle>

          <v-list-item
            v-for="organization in organizationsList"
            :key="organization.id"
            :value="organization.id"
            :disabled="!organization.isApproved"
          >
            <v-list-item-content>
              <v-list-item-title>{{ organization.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <template v-if="accessToIntegratorClients">
            <v-list-item-subtitle class="mb-2 mt-4">Клиенты</v-list-item-subtitle>

            <v-list-item
              v-for="client in integratorClients"
              :key="`client-${client.id}`"
              :value="client.id"
            >
              <v-list-item-content>
                <v-list-item-title>{{ client.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>

    <v-dialog v-model="editingDialogOpened" max-width="500px">
      <organization-edit
        v-if="editingDialogOpened"
        v-model="editingOrganizationId"
        @close="closeEditingModal()"
        @created="onChanged()"
        @updated="onChanged()"
      />
    </v-dialog>
  </v-menu>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import debounce from 'lodash.debounce';

  import OrganizationEdit from 'src/components/OrganizationEdit.vue';

  import api from 'src/api';

  export default {
    name: 'OrganizationsMenu',

    components: {
      OrganizationEdit,
    },

    data() {
      return {
        popupOpened: false,

        selectedOrganization: null,

        organizations: [],
        integratorClients: [],

        pattern: '',

        editingOrganizationId: null,
        editingDialogOpened: false,

        allOrganizationCount: 0,
        allIntegratorClientsCount: 0,
      };
    },

    computed: {
      ...mapState('users', ['currentUser']),
      ...mapGetters('users', ['isAdminOrHigher', 'isRealAdminOrHigher']),

      showedMenu() {
        return Boolean(
          this.currentUser.id &&
            this.selectedOrganization &&
            this.allOrganizationCount + this.allIntegratorClientsCount > 1
        );
      },

      menuIcon() {
        return `mdi-menu-down ${this.popupOpened ? 'mdi-rotate-180' : ''}`;
      },

      debouncedPattern: {
        get() {
          return this.pattern;
        },

        set(value) {
          this.debouncedSetPattern(value);
        },
      },

      organizationsList() {
        const list = [...this.organizations];

        if (!list.find(organization => organization.id === this.selectedOrganization.id)) {
          list.unshift(this.selectedOrganization);
        }

        return list;
      },

      selectedOrganizationId: {
        get() {
          return this.selectedOrganization?.id || null;
        },
        set(value) {
          this.selectOrganization(value);
        },
      },

      accessToIntegratorClients() {
        return Boolean(this.currentUser.organizationIsIntegrator && this.isRealAdminOrHigher);
      },
    },

    watch: {
      editingDialogOpened(value) {
        if (!value) {
          this.editingWorkspace = null;
        }
      },

      usersDialogOpened(value) {
        if (!value) {
          this.editingWorkspace = null;
        }
      },

      pattern() {
        this.getOrganizations();
      },
    },

    async mounted() {
      const [allOrganizationsCount, allIntegratorClientsCount] = await Promise.all([
        this.getOrganizations(),
        this.getIntegratorClients(),
        this.getSelectedOrganization(),
      ]);

      this.allOrganizationCount = allOrganizationsCount;
      this.allIntegratorClientsCount = allIntegratorClientsCount;
    },

    methods: {
      async getSelectedOrganization() {
        const { response } = await api.organizations.me();

        this.selectedOrganization = Object.freeze(response);
      },

      async getOrganizations() {
        const { response } = await api.organizations.getAvailableList({
          params: {
            page: 1,
            limit: 5,
            pattern: this.pattern,
          },
        });

        this.organizations = Object.freeze(response.payload);

        return response.count;
      },

      async getIntegratorClients() {
        if (!this.accessToIntegratorClients) {
          return 0;
        }

        const { response } = await api.integrators.getClients({
          params: {
            page: 1,
            limit: 5,
            pattern: this.pattern,
            filterDeniedAccess: true,
          },
        });

        this.integratorClients = Object.freeze(response.payload);

        return response.count;
      },

      debouncedSetPattern: debounce(function setPattern(pattern) {
        this.pattern = pattern;
      }, 300),

      openEditDialog(id = null) {
        this.editingOrganizationId = id;
        this.editingDialogOpened = true;
      },

      async selectOrganization(id) {
        await api.organizations.select({
          url_params: {
            id,
          },
        });

        window.location.reload();
      },

      closeEditingModal() {
        this.editingDialogOpened = false;
      },

      onChanged() {
        this.closeEditingModal();

        this.getList();
      },
    },
  };
</script>

<style lang="scss">
  .menu-offset-class {
    margin: 0 0 0 -12px;
  }
</style>
