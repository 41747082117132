<template>
  <div>
    <v-btn text color="blue darken-1" @click="openRemoveDialog()">
      {{ $t('pages.organizations.editModal.statistics.removeButtonTitle') }}
    </v-btn>

    <v-dialog v-model="removeDialogOpened" max-width="500">
      <remove-item-modal
        v-if="removeDialogOpened"
        :disabled="!removeMessageConfirmed"
        :title="$t('pages.organizations.editModal.statistics.removeModalTitle')"
        :remove-callback="removeCallback"
        @close="removeDialogOpened = false"
      >
        <span class="black--text subtitle-1">
          {{ $t('pages.organizations.editModal.statistics.removeModalText', { companyName }) }}
        </span>

        <v-text-field v-model="confirmMessage" />
      </remove-item-modal>
    </v-dialog>

    <v-snackbar v-model="showedSuccessSnackbar" color="success" timeout="3000">
      {{ $t('pages.organizations.editModal.statistics.removedSuccess') }}
      <template #action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showedSuccessSnackbar = false">
          {{ $t('ui.common.close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="showedFailSnackbar" color="error" timeout="3000">
      {{ $t('pages.organizations.editModal.statistics.removedFailed') }}
      <template #action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showedFailSnackbar = false">
          {{ $t('ui.common.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import RemoveItemModal from 'src/components/RemoveItemModal/RemoveItemModal.vue';
  import api from 'src/api';

  export default {
    name: 'OrganizationStatisticRemove',

    components: {
      RemoveItemModal,
    },

    props: {
      id: {
        type: String,
        required: true,
      },
      companyName: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        removeDialogOpened: false,
        confirmMessage: '',
        showedSuccessSnackbar: false,
        showedFailSnackbar: false,
      };
    },

    computed: {
      removeMessageConfirmed() {
        return (
          this.confirmMessage.toLowerCase().trim() ===
          this.$t('pages.organizations.editModal.statistics.removeConfirmMessage')
            .toLowerCase()
            .trim()
        );
      },
    },

    methods: {
      openRemoveDialog() {
        this.removeCallback = async () => {
          try {
            await api.statistics.removeOrganizationStatistics({ url_params: { id: this.id } });
            this.showedSuccessSnackbar = true;
          } catch (error) {
            this.showedFailSnackbar = true;
          }
        };
        this.removeDialogOpened = true;
      },
    },
  };
</script>
