<template>
  <v-dialog v-if="tempEntity" v-model="dialog" max-width="1040" class="desktop-entity-edit-form">
    <v-card>
      <v-card-title class="pa-4 primary">
        <span class="title white--text">
          {{ $t('pages.scenarios.editModal.title') }} {{ tempEntity.title }}
        </span>

        <v-tooltip
          bottom
          max-width="500"
          content-class="white elevation-1 grey--text text--darken-1 desktop-entity-edit-form__tooltip"
        >
          <template #activator="{ on, attrs }">
            <v-icon class="ml-3 white--text" small v-bind="attrs" v-on="on">
              mdi-message-outline
            </v-icon>
          </template>

          <div class="font-weight-medium mb-1">
            {{ $t('pages.scenarios.editModal.tooltip.title') }}
          </div>
          <div>ID: {{ tempEntity.id }}</div>
          <div>
            {{ $t('pages.scenarios.editModal.tooltip.creator') }}: {{ tempEntity.creatorId }}
          </div>
          <div>
            {{ $t('pages.scenarios.editModal.tooltip.organization') }}:
            {{ tempEntity.organizationId }}
          </div>
          <div>{{ $t('common.fields.created') }}: {{ formatDateTime(tempEntity.createdAt) }}</div>
          <div>{{ $t('common.fields.updated') }}: {{ formatDateTime(tempEntity.updatedAt) }}</div>
        </v-tooltip>

        <v-spacer />

        <v-btn icon color="white" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="tempEntity.title"
                :label="$t('pages.scenarios.editModal.titleLabel')"
                outlined
                hide-details
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="isModeratorOrHigher" cols="12" class="py-0">
              <v-switch
                v-model="tempEntity.published"
                :label="$t(`${localeRootPath}.editModal.publishLabel`)"
                hide-details
              />
            </v-col>

            <v-col v-if="isModeratorOrHigher && !tooltipsMode" cols="12" class="py-0">
              <v-switch
                v-model="tempEntity.info.lockWindow"
                :label="$t(`ui.desktopScenarios.editModal.lockWindowLabel`)"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-list>
                <v-list-item-group v-model="selectedStepId" mandatory color="primary">
                  <draggable v-model="tempEntity.steps" handle="[data-drag-step]" animation="200">
                    <v-list-item
                      v-for="(item, index) in tempEntity[tooltipsMode ? 'items' : 'steps']"
                      :key="item.id"
                      :value="item.id"
                    >
                      <v-list-item-icon>
                        <v-icon data-drag-step class="desktop-entity-edit-form__drag-handler">
                          mdi-drag
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon v-if="!tooltipsMode" small class="mr-2">
                            {{ item.isModalWindow ? 'mdi-dock-top' : 'mdi-message-outline' }}
                          </v-icon>

                          {{ $t('pages.scenarios.editModal.step') }} {{ index + 1 }}:
                          {{ item.stepProperty.title }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-icon small @click="deleteStep(index)">mdi-delete</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </draggable>
                </v-list-item-group>
              </v-list>
            </v-col>

            <v-col v-if="selectedStep" cols="6">
              <div class="text-h6 mb-6">
                {{ $t('pages.scenarios.editModal.step') }}
                {{ selectedStep.stepProperty.title }}
              </div>

              <v-text-field
                v-model="selectedStep.stepProperty.title"
                :label="$t('ui.desktopScenarios.editModal.stepTitle')"
                outlined
                hide-details
                class="mt-4"
              />

              <v-select
                v-model="isRemoteContent"
                :items="contentOptions"
                :label="$t('ui.desktopScenarios.editModal.hintContentType')"
                outlined
                hide-details
                class="mt-6"
              />

              <v-text-field
                v-if="isRemoteContent"
                v-model="remoteContent"
                :label="$t('ui.desktopScenarios.editModal.remoteContentLink')"
                outlined
                hide-details
                class="mt-4"
              />

              <div v-else class="mt-4">
                <text-editor
                  v-if="showedEditor"
                  :html.sync="selectedStep.stepProperty.htmlContent"
                  :json.sync="jsonContent"
                  desktop-mode
                />
              </div>

              <v-select
                v-if="!tooltipsMode"
                v-model="selectedStep.stepProperty.action"
                :items="actionOptions"
                :label="$t('ui.desktopScenarios.editModal.action')"
                outlined
                hide-details
                class="mt-6"
              />

              <v-select
                v-model="selectedStep.stepProperty.position"
                :items="positionOptions"
                :label="$t('ui.desktopScenarios.editModal.position')"
                outlined
                hide-details
                class="mt-6"
              />
            </v-col>
          </v-row>

          <v-row class="mt-6">
            <v-btn :loading="isBusy" color="primary" dark class="mt-6" @click="submit()">
              {{ $t('ui.common.save') }}
            </v-btn>

            <v-btn :loading="isBusyWithClose" class="mt-6 ml-3" @click="submit(true)">
              {{ $t('ui.common.saveAndClose') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex';
  import pick from 'lodash.pick';
  import draggable from 'vuedraggable';
  import { parseHtmlToJsonForEditorJs } from 'parseHTMLtoJSON';

  import TextEditor from 'src/components/TextEditor.vue';

  import { deepcopy } from 'src/utils';
  import api from 'src/api';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  export default {
    name: 'DesktopEntityEditFOrm',

    components: {
      draggable,
      TextEditor,
    },

    mixins: [formatTimestampMixin],

    props: {
      editingEntity: {
        type: Object,
        required: false,
        default: null,
      },

      tooltipsMode: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        showedEditor: false,
        tempEntity: null,
        dialog: false,
        selectedStepId: null,
        createVersionOnSaving: true,
        isBusy: false,
        isBusyWithClose: false,
        actionOptions: Object.freeze(
          ['leftClick', 'rightClick', 'mouseMove', 'nextButton', 'doubleClick', 'textInput'].map(
            value => ({
              value,
              text: this.$t(`ui.desktopScenarios.editModal.actionOptions.${value}`),
            })
          )
        ),
        positionOptions: Object.freeze(
          ['auto', 'cornerRightTop', 'cornerRightBot', 'cornerLeftTop', 'cornerLeftBot'].map(
            value => ({
              value,
              text: this.$t(`ui.desktopScenarios.editModal.positionOptions.${value}`),
            })
          )
        ),
        contentOptions: Object.freeze(
          [false, true].map(value => ({
            value,
            text: this.$t(
              `ui.desktopScenarios.editModal.hintContentTypeOptions.${
                value ? 'remoteContent' : 'description'
              }`
            ),
          }))
        ),
      };
    },

    computed: {
      ...mapGetters('users', ['isModeratorOrHigher']),

      localeRootPath() {
        return this.tooltipsMode ? 'ui.desktopTooltips' : 'ui.desktopScenarios';
      },

      selectedStep() {
        if (!this.selectedStepId) {
          return null;
        }

        return this.tempEntity[this.tooltipsMode ? 'items' : 'steps'].find(
          step => step.id === this.selectedStepId
        );
      },

      isRemoteContent: {
        get() {
          if (!this.selectedStep) {
            return false;
          }

          return this.selectedStep.isRemoteContent || false;
        },

        set(value) {
          this.$set(this.selectedStep, 'isRemoteContent', value);
        },
      },

      remoteContent: {
        get() {
          if (!this.selectedStep || !this.selectedStep.isRemoteContent) {
            return false;
          }

          return this.selectedStep.remoteContent;
        },

        set(value) {
          this.$set(this.selectedStep, 'remoteContent', value);
        },
      },

      jsonContent: {
        get() {
          if (!this.selectedStep) {
            return {};
          }

          if (!this.selectedStep.stepProperty.jsonContent) {
            this.convertOldContent();

            return {};
          }

          return JSON.parse(this.selectedStep.stepProperty.jsonContent);
        },
        set(value) {
          this.selectedStep.stepProperty.jsonContent = JSON.stringify(value);
        },
      },
    },

    watch: {
      editingEntity(value) {
        if (value) {
          this.open();
        }
      },

      selectedVersionData() {
        this.tempEntity = this.selectedVersionData;
      },

      async dialog(value) {
        if (!value) {
          this.selectedStepId = null;
        }
      },

      selectedStepId(value) {
        if (value) {
          this.showedEditor = false;
          this.$nextTick(() => {
            this.showedEditor = true;
          });
        }
      },
    },

    methods: {
      async convertOldContent() {
        const content = await parseHtmlToJsonForEditorJs(
          this.selectedStep.stepProperty.htmlContent
        );

        this.$set(this.selectedStep.stepProperty, 'jsonContent', JSON.stringify(content));
      },

      async open() {
        this.dialog = true;
        this.tempEntity = deepcopy(this.editingEntity);
      },

      close() {
        this.dialog = false;
        this.$emit('close');
      },

      deleteStep(stepIndex) {
        if (!this.tempEntity) {
          return;
        }

        this.tempEntity.steps.splice(stepIndex, 1);
      },

      async submit(closeAfterUpdating = false) {
        if (!this.tempEntity.title) {
          this.tempEntity.title = this.editingEntity.title;
        }

        if (closeAfterUpdating) {
          this.isBusyWithClose = true;
        } else {
          this.isBusy = true;
        }

        const payload = pick(this.tempEntity, [
          'title',
          'published',
          'info',
          this.tooltipsMode ? 'items' : 'steps',
        ]);

        await api[this.tooltipsMode ? 'desktopTooltips' : 'desktopScenarios'].update({
          url_params: { id: this.tempEntity.id },
          data: payload,
        });

        this.$emit('updated');

        this.isBusy = false;
        this.isBusyWithClose = false;

        if (closeAfterUpdating) {
          this.close();
        }
      },
    },
  };
</script>

<style lang="scss">
  .desktop-entity-edit-form {
    &__tooltip {
      opacity: 1 !important;
    }

    &__drag-handler {
      cursor: move !important;
    }
  }
</style>
