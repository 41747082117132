<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          <v-btn icon @click="setShowedSettings(false)">
            <v-icon color="grey darken-4">
              mdi-chevron-left-circle-outline
            </v-icon>
          </v-btn>

          {{ $t('ui.notificationsList.settings') }}
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <v-btn icon>
          <v-icon color="grey darken-4" @click="setShowedPanel(false)">mdi-close</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <v-divider />

    <v-container>
      <v-switch
        v-if="isModeratorOrHigher"
        v-model="debouncedReceiveDraftEvents"
        :label="$t('ui.notificationsList.contentModerateEnable')"
        hide-details
      />
    </v-container>
  </div>
</template>

<script>
  import { mapMutations, mapGetters } from 'vuex';
  import debounce from 'lodash.debounce';

  import eventsService from 'src/services/events';

  import {
    SET_SHOWED_NOTIFICATIONS_PANEL,
    SET_SHOWED_NOTIFICATIONS_SETTINGS,
  } from 'src/store/mutation-types';

  import api from 'src/api';

  export default {
    name: 'NotificationsSettings',

    data() {
      return {
        receiveDraftEvents: false,
      };
    },

    computed: {
      ...mapGetters('users', ['isModeratorOrHigher']),

      debouncedReceiveDraftEvents: {
        get() {
          return this.receiveDraftEvents;
        },

        set(value) {
          this.debouncedUpdateReceiveDraftEvents(value);
        },
      },
    },

    mounted() {
      this.getUserSettings();
    },

    methods: {
      ...mapMutations('notifications', {
        setShowedPanel: SET_SHOWED_NOTIFICATIONS_PANEL,
        setShowedSettings: SET_SHOWED_NOTIFICATIONS_SETTINGS,
      }),

      async getUserSettings() {
        const { response } = await api.users.me();

        this.receiveDraftEvents = response.receiveDraftEvents;
      },

      debouncedUpdateReceiveDraftEvents: debounce(async function updateReceiveDraftEvents(value) {
        this.receiveDraftEvents = value;

        await api.users.updateCurrent({
          data: { receiveDraftEvents: value },
        });

        eventsService.connect();
      }, 300),
    },
  };
</script>
