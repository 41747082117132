<template>
  <div class="hinted-simulation fake-simulation">
    <div data-system-slide class="fake-simulation__slide">
      <div data-logo-wrapper class="fake-simulation__logo-wrapper">
        <img :src="customLogoUrl || $options.DEFAULT_LOGO_URL" class="fake-simulation__logo" />
      </div>

      <div class="fake-simulation__slide-content">
        <div data-highlight class="fake-simulation__highlight" />
        <div data-system-slide-text>
          <div class="fake-simulation__header">
            {{ $t('pages.styles.settings.fakeSimulation.title') }}
          </div>
          <div class="fake-simulation__subtitle">
            {{ $t('pages.styles.settings.fakeSimulation.description') }}
          </div>
        </div>

        <div class="step-popup fake-simulation__popup">
          <div data-popup-content class="popover-inner fake-simulation__popup-body">
            <h4>{{ $t('pages.styles.loremTitle') }}</h4>

            <div>{{ $t('pages.styles.loremText') }}</div>

            <button data-popup-next-button class="fake-simulation__popup-button">
              {{ $t('pages.styles.settings.fakeSimulation.next') }}
            </button>
          </div>
          <div class="popover-arrow fake-simulation__popup-arrow" />
        </div>
      </div>
    </div>

    <div data-steps-pagination class="fake-simulation__nav">
      <div class="fake-simulation__nav-content">
        <div class="fake-simulation__steps-container">
          <div data-pagination-text class="fake-simulation__steps-title">
            {{ $t('pages.styles.settings.fakeSimulation.step') }}:
            <span data-steps-counter class="fake-simulation__steps-counter">
              {{ $t('pages.styles.settings.fakeSimulation.stepOf') }}
            </span>
          </div>

          <div class="fake-simulation__steps-wrapper">
            <div
              data-pagination-step-complete
              class="fake-simulation__step fake-simulation__step--active"
            />
            <div data-pagination-step class="fake-simulation__step" />
          </div>
        </div>

        <button data-secondary-button class="fake-simulation__steps-back-button">
          {{ $t('pages.styles.settings.fakeSimulation.back') }}
        </button>

        <button data-primary-button class="fake-simulation__steps-button">
          {{ $t('pages.styles.settings.fakeSimulation.start') }}
        </button>
      </div>
      <div data-logo-wrapper class="fake-simulation__nav-logo">
        <img v-if="customLogo" :src="customLogo" class="fake-simulation__custom-logo" />

        <a
          v-else
          :href="`https://${$options.COMPANY_DOMAIN}/`"
          target="_blank"
          class="fake-simulation__logo-text"
          data-pagination-text
        >
          Powered by
          <span class="fake-simulation__hinted-text">
            {{ $options.HINT_TEXT }}<span data-ed-logo-text>{{ $options.ED_TEXT }}</span>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import { buildSpecConfig } from 'src/config';

  export default {
    name: 'FakeSimulation',

    inject: ['orgSettings'],

    props: {
      customLogoUrl: {
        type: String,
        required: false,
        default: null,
      },
    },

    DEFAULT_LOGO_URL: buildSpecConfig.logoUrl,
    COMPANY_DOMAIN: buildSpecConfig.companyDomain,
    HINT_TEXT: buildSpecConfig.hintText,
    ED_TEXT: buildSpecConfig.edText,

    computed: {
      customLogo() {
        return this.orgSettings.logoUrl;
      },
    },
  };
</script>

<style lang="scss">
  .fake-simulation {
    height: 579px;
    width: 100%;
    outline: 1px solid #dae4e8;
    border-radius: 5px;
    background-color: #ffffff;
    font-family: 'Roboto', sans-serif;

    &__slide {
      display: flex;
      position: relative;
      width: 100%;
      height: 505px;
      background-color: #f4f6f9;
      padding: 50px;
      align-items: center;
      justify-content: center;
    }

    &__logo-wrapper {
      padding: inherit;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__logo {
      max-width: 120px;
      object-fit: contain;
    }

    &__slide-content {
      position: relative;
      color: #343349;
      text-align: center;
      padding: 10px;
    }

    &__header {
      font-weight: 700;
      font-size: 34px;
      line-height: 40px;
    }

    &__subtitle {
      margin-top: 10px;
      font-size: 13px;
      line-height: 15px;
    }

    &__highlight {
      position: absolute;
      top: -5px;
      left: -5px;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      background-color: transparent;
      outline: 5px solid #d81159;
    }

    &__popup {
      position: absolute;
      top: calc(100% + 23px);
      left: 50%;
      transform: translateX(-50%);
      background: #d81159;
      border-radius: 5px;
    }

    .step-popup .fake-simulation__popup-arrow.popover-arrow {
      border-width: 0 17px 17px 17px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -16px;
      left: calc(50% - 22px);
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      z-index: 1;
    }

    &__popup-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: 100%;
      text-align: left;
      padding: 20px;
      color: #ffffff;
      font-size: 16px;
      line-height: 24px;
      overflow: hidden;
      border-radius: 5px;
      width: 320px;
    }

    &__popup-button {
      background-color: #ffffff;
      color: #d81159;
      border: none;
      outline: none;
      border-radius: 5px;
      padding: 7px 12px;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      align-self: flex-end;
      margin-top: 20px;
      margin-left: 125px;
    }

    &__nav {
      width: 100%;
      height: 74px;
      padding: 4px 20px 0;
    }

    &__nav-content {
      width: 100%;
      height: 52px;
      display: flex;
      align-items: center;
    }

    &__nav-logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__logo-text {
      font-size: 8px;
      line-height: 9px;
      color: #343349 !important;
      text-decoration: none;
    }

    &__hinted-text {
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;
    }

    &__steps-container {
      flex: 1 0 auto;
    }

    &__steps-button {
      height: 36px;
      display: inline-flex;
      justify-content: center;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.00892857em;
      font-weight: bold;
      background-color: #d81159;
      color: #fff;
      border-radius: 4px;
      padding: 10px 16px;
      border: none;
      outline: none;
      font-family: Roboto, sans-serif;
      cursor: pointer;
    }

    &__steps-back-button {
      background-color: transparent;
      color: #d81159;
      height: 36px;
      display: inline-flex;
      justify-content: center;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.00892857em;
      font-weight: bold;
      border-radius: 4px;
      padding: 10px 16px;
      border: none;
      outline: none;
      font-family: Roboto, sans-serif;
      cursor: pointer;
      margin-right: 5px;

      &:hover {
        background-color: rgba(216, 17, 89, 0.1);
      }
    }

    &__steps-title {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: #343349;
      margin-bottom: 10px;
      font-weight: 500;
    }

    &__steps-counter {
      color: #d81159;
    }

    &__steps-wrapper {
      display: flex;
      align-items: center;
      margin: 0 9px 0 -1px;
    }

    &__step {
      flex: 1 0 auto;
      height: 3px;
      background-color: #c0c3cb;
      border-radius: 50px;
      margin: 0 1px;

      &--active {
        background-color: #d81159;
      }
    }

    &__custom-logo {
      max-height: 16px;
    }
  }
</style>
