<template>
  <v-card-text class="pt-6">
    <v-combobox
      v-model="unbindTags"
      :items="filteredTags"
      :filter="filterTags"
      :label="$t('ui.manageTagsModal.userTags')"
      :hint="$t('ui.manageTagsModal.removeTagHint')"
      type="button"
      item-text="title"
      item-value="id"
      item-disabled="isSystemTag"
      small-chips
      hide-selected
      persistent-hint
      outlined
      multiple
    >
      <template #selection="{ attrs, item, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :color="item.color"
          :input-value="selected"
          small
        >
          <span>
            {{ item.title }}
          </span>

          <v-icon small class="ml-2" @click="unbindTags = []">
            $delete
          </v-icon>
        </v-chip>
      </template>

      <template #item="{ item }">
        <v-chip :color="item.color" small>
          {{ item.title }}
        </v-chip>
      </template>
    </v-combobox>
  </v-card-text>
</template>

<script>
  import api from 'src/api';

  export default {
    name: 'RemoveTagsTab',

    data() {
      return {
        isBusy: false,

        tags: [],

        unbindTags: [],
      };
    },

    computed: {
      entitiesIdList() {
        return this.entities.map(entity => entity.id);
      },

      filteredTags() {
        return this.tags.filter(tag => !tag.isSystemTag);
      },
    },

    watch: {
      unbindTags: {
        immediate: true,
        async handler() {
          this.$emit(
            'updated',
            this.unbindTags.map(tag => tag.id)
          );
        },
      },
    },

    mounted() {
      this.getTags();
    },

    methods: {
      async getTags() {
        const { response } = await api.tags.list();

        this.tags = response;
      },

      filterTags(item, queryText, itemText) {
        if (item.header) {
          return false;
        }

        const hasValue = val => (val != null ? val : '');

        const text = hasValue(itemText);
        const query = hasValue(queryText);

        return (
          text
            .toString()
            .toLowerCase()
            .trim()
            .indexOf(
              query
                .toString()
                .toLowerCase()
                .trim()
            ) > -1
        );
      },
    },
  };
</script>
