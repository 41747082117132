<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 18 18"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <title :id="iconName" :v-if="iconName" lang="en">{{ iconName }}</title>
    <g :fill="iconColor">
      <slot />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'IconBase',
    props: {
      iconName: {
        type: String,
        default: '',
      },
      width: {
        type: [Number, String],
        default: 18,
      },
      height: {
        type: [Number, String],
        default: 18,
      },
      iconColor: {
        type: String,
        default: 'currentColor',
      },
    },
  };
</script>

<style scoped>
  svg {
    display: inline-block;
    vertical-align: baseline;
    margin-bottom: -2px;
  }
</style>
