<template>
  <div class="hint-preview">
    <FakeModal v-if="showed" />
  </div>
</template>

<script>
  import basePreview from 'src/pages/Styles/mixins/basePreview';

  import { jsonStylesToCss } from 'src/utils';
  import { THEME_TYPE_CLASSIC } from '../../utils';

  import FakeModal from './FakeModal.vue';

  export default {
    name: 'ModalPreview',

    components: {
      FakeModal,
    },

    extends: basePreview,

    computed: {
      compiledCss() {
        const { copyrightHidden } = this.settings;
        const styles = {};

        if (copyrightHidden) {
          styles['hinted-hint-bottom-link'] = 'display:none;';
        }

        let css = jsonStylesToCss(styles).replace(/;|(([^;])(}))/g, '$2 !important; $3');
        css = css.replace(/hinted-hint-bottom-link/gi, 'hinted-hint-bottom-link > *');

        return css;
      },
    },

    watch: {
      type: {
        immediate: true,
        async handler() {
          await this.$nextTick();
          this.updateThemeClasses();
        },
      },

      async showed(value) {
        await this.$nextTick();

        if (value) {
          this.updateThemeClasses();
        }
      },
    },

    methods: {
      updateThemeClasses() {
        const element = document.querySelector('hinted-modal');

        if (!element) {
          return;
        }

        if (this.type === THEME_TYPE_CLASSIC) {
          element.classList.add('style-classic');
        } else {
          element.classList.remove('style-classic');
        }
      },
    },
  };
</script>

<style lang="scss">
  .hint-preview {
    height: 700px;
    position: relative;
  }
</style>
