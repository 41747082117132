<template>
  <v-menu
    open-on-hover
    close-delay="1000"
    open-delay="300"
    content-class="plan-upgrade-menu-content"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :top="top"
    :right="right"
    :nudge-right="nudgeRight"
    :nudge-top="nudgeTop"
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>

    <i18n :path="typeI18nKey" tag="p" class="plan-upgrade-menu">
      <template #link>
        &nbsp;

        <router-link :to="{ name: 'Billing' }" class="plan-upgrade-menu__link">
          {{ $t('components.planUpgradeMenu.upgradeLink') }}
        </router-link>
      </template>
    </i18n>
  </v-menu>
</template>

<script>
  export default {
    name: 'PlanUpgradeMenu',

    props: {
      offsetX: {
        type: Boolean,
        required: false,
        default: false,
      },

      offsetY: {
        type: Boolean,
        required: false,
        default: false,
      },

      right: {
        type: Boolean,
        required: false,
        default: false,
      },

      top: {
        type: Boolean,
        required: false,
        default: false,
      },

      nudgeTop: {
        type: [String, Number],
        required: false,
        default: null,
      },

      nudgeRight: {
        type: [String, Number],
        required: false,
        default: null,
      },

      type: {
        type: String,
        required: false,
        default: 'pro',
      },
    },

    computed: {
      typeI18nKey() {
        switch (this.type) {
          case 'pro':
            return 'components.planUpgradeMenu.upgradeProTooltip';
          case 'enterprise':
            return 'components.planUpgradeMenu.upgradeEnterpriseTooltip';
          default:
            throw new Error(`Unknown type ${this.type}}`);
        }
      },
    },
  };
</script>

<style lang="scss">
  .plan-upgrade-menu {
    display: flex;
    padding: 10px 15px;
    background-color: rgba(52, 51, 73, 0.95);
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;

    &__link {
      color: #ffffff !important;
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .plan-upgrade-menu-content {
    box-shadow: none !important;
  }
</style>
