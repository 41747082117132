<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
  import { mapActions } from 'vuex';
  import { getIsMobilePlatform } from 'src/utils';

  const CLOUD_PRODUCTION_HOST = 'app.hinted.me';

  export default {
    name: 'App',

    provide: {
      sendMetrics(key, type, url) {
        if (window.location.host === CLOUD_PRODUCTION_HOST) {
          window.umami?.trackEvent(key, type, url);
        }
      },
    },

    metaInfo() {
      if (window.location.host === CLOUD_PRODUCTION_HOST) {
        return {
          script: [
            {
              src: 'https://hinted.me/services/analytics/umami.js',
              async: true,
              defer: true,
              'data-website-id': '9f97f790-a73e-4f7c-a010-2ee18a91e60c',
              'data-host-url': 'https://hinted.me/services/analytics',
            },
            {
              src: 'https://hinted.me/script.js',
              organizationId: '3d0f2550-59e6-4615-ac4c-0e4286a4bfb4',
              host: 'https://app.hinted.me/api',
              spaSelector: '#app',
              isSpa: 'true',
            },
          ],
        };
      }

      return {};
    },

    mounted() {
      const isMobilePlatform = getIsMobilePlatform();
      this.getSettings();
      this.setIsMobilePlatform(isMobilePlatform);
    },

    methods: {
      ...mapActions('settings', ['getSettings', 'setIsMobilePlatform']),
    },
  };
</script>
