import api from 'src/api';
import {
  setAccessToken,
  dropToken,
  isAuth,
  setRefreshToken,
  getRefreshToken,
  headerAuthorization,
} from 'src/services/session';
import { currentLocale } from 'src/plugins/i18n';

import { AUTH_STATE } from '../mutation-types';

const setAuth = (response, dispatch) => {
  const { access_token: accessToken } = response;
  const { refresh_token: refreshToken } = response;

  if (response && accessToken && refreshToken) {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);

    return dispatch('setAuthState', true);
  }

  return Promise.reject(response);
};

const actions = {
  async auth({ dispatch }, params) {
    const { response } = await api.auth.auth({ data: { ...params, app: 'ADMIN_PANEL' } });

    setAuth(response, dispatch);

    await dispatch('users/getCurrentUser', {}, { root: true });

    return response;
  },

  async authTransition({ dispatch }, { token, app }) {
    const { response } = await api.auth.authTransition({
      options: { headers: { [headerAuthorization]: `Bearer ${token}` } },
      data: { app },
    });

    setAuth(response, dispatch);

    await dispatch('users/getCurrentUser', {}, { root: true });

    return response;
  },

  async integrated({ dispatch }) {
    const { response } = await api.auth.integrated({ data: { app: 'ADMIN_PANEL' } });

    await setAuth(response, dispatch);

    return response;
  },

  async refresh({ dispatch }) {
    const {
      response: { access_token: accessToken, refresh_token: refreshToken, data },
    } = await api.auth.refresh({
      data: { refreshToken: getRefreshToken(), app: 'ADMIN_PANEL' },
    });

    setAccessToken(accessToken);
    setRefreshToken(refreshToken);

    if (data.updateUserInfo) {
      await dispatch('users/getCurrentUser', null, { root: true });
    }

    if (data.updateWorkspaces) {
      await dispatch('workspaces/getWorkspacesList', null, { root: true });
    }
  },

  async register(context, params) {
    const { response } = await api.auth.register({
      data: { ...params, browserLocale: currentLocale },
    });

    return response;
  },

  async logout({ dispatch }) {
    const { response } = await api.auth.logout();

    if (response) {
      dispatch('clearData');
      return dispatch('setAuthState', false);
    }

    return false;
  },

  async recovery(context, { email }) {
    await api.auth.recovery({ data: { email } });
  },

  async confirmEmail({ state, dispatch }, { email, code, token = '' }) {
    const { response } = await api.auth.confirmEmail({ params: { email, code, token } });

    if (!state.isAuth) {
      if (!response || !response.access_token || response.refresh_token) {
        throw Error;
      }

      setAccessToken(response.access_token);
      setRefreshToken(response.refresh_token);
      dispatch('setAuthState');
    }

    return response;
  },

  async checkAuth({ dispatch }) {
    await dispatch('user/getCurrentUser', null, { root: true });
    dispatch('setAuthState');
  },

  async clearData() {
    dropToken();
  },

  setAuthState({ commit }) {
    commit(AUTH_STATE, isAuth());
  },
};

const mutations = {
  [AUTH_STATE](state, payload) {
    state.isAuth = payload;
  },
};

export default {
  namespaced: true,
  state: {
    isAuth: false,
  },
  actions,
  mutations,
};
