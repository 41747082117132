const getOrganizationHelpCenterChart = {
  name: 'getOrganizationHelpCenterChart',
  method: 'get',
  url: 'help-center/:organizationId/chart',
};

const getOrganizationHelpCenterFile = {
  name: 'getOrganizationHelpCenterFile',
  method: 'get',
  url: 'help-center/:organizationId/download',
};

const getOrganizationScenarioVisibilityChart = {
  name: 'getOrganizationScenarioVisibilityChart',
  method: 'get',
  url: '/api/v2/statistics/help-center/scenarios-visibility/:organizationId/chart',
};

const getOrganizationScenarioVisibilityFile = {
  name: 'getOrganizationScenarioVisibilityFile',
  method: 'get',
  url: '/api/v2/statistics/help-center/scenarios-visibility/:organizationId/download',
};

export default {
  name: 'v2statistics',
  url: '/api/v2/statistics',
  children: [
    getOrganizationHelpCenterChart,
    getOrganizationHelpCenterFile,
    getOrganizationScenarioVisibilityChart,
    getOrganizationScenarioVisibilityFile,
  ],
};
