<template>
  <v-row v-if="preparedFilter.length" justify="space-between" class="flex-nowrap">
    <div>
      <v-chip
        v-for="(item, key) in preparedFilter"
        :key="key"
        close
        small
        class="mr-2 mb-2"
        @click:close="removeFilter(item.type, item.value)"
      >
        {{ item.text }}
      </v-chip>
    </div>

    <v-btn text small @click="resetAllFilters()">
      <v-icon left>
        mdi-close
      </v-icon>
      {{ $t('pages.scenarios.filters.reset') }}
    </v-btn>
  </v-row>
</template>

<script>
  import pick from 'lodash.pick';
  import moment from 'moment';

  import api from 'src/api';
  import { deepcopy } from 'src/utils';

  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  export default {
    name: 'WidgetEntitiesFilterPreview',

    mixins: [formatTimestampMixin],

    model: {
      prop: 'modelValue',
      event: 'update:modelValue',
    },

    props: {
      modelValue: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        tagsList: {},
      };
    },

    FILTER_TYPE_TAGS: 'tags',
    FILTER_TYPE_CREATOR: 'creatorsEmails',
    FILTER_TYPE_CREATION_DATE: 'creationDatesRange',
    FILTER_TYPE_PUBLISH_STATUS: 'publishStatus',

    computed: {
      preparedFilter() {
        if (!this.modelValue || !Object.keys(this.modelValue).length) {
          return [];
        }

        return Object.entries(this.modelValue).reduce((acc, [key, value]) => {
          if (key === this.$options.FILTER_TYPE_PUBLISH_STATUS && value !== null) {
            acc.push({
              type: key,
              text: this.$t(`pages.scenarios.filters.${value ? 'published' : 'unpublished'}`),
              value,
            });

            return acc;
          }

          if (!value) {
            return acc;
          }

          switch (key) {
            case this.$options.FILTER_TYPE_TAGS:
              Object.values(value).forEach(selectedTag => {
                acc.push({ type: key, text: this.tagsList[selectedTag].title, value: selectedTag });
              });
              break;
            case this.$options.FILTER_TYPE_CREATOR:
              value.forEach(creatorEmail => {
                acc.push({ type: key, text: creatorEmail, value: creatorEmail });
              });
              break;
            case this.$options.FILTER_TYPE_CREATION_DATE:
              acc.push({
                type: key,
                text: `${this.formatDate(moment.utc(value.from).toDate())} – ${this.formatDate(
                  moment.utc(value.to).toDate()
                )}`,
              });
              break;
            default:
              console.error(`Unknown filter type ${value}`);
              break;
          }
          return acc;
        }, []);
      },
    },

    created() {
      this.getTags();
    },

    methods: {
      async getTags() {
        const { response } = await api.tags.list();

        const mappedTagsList = response.reduce((acc, item) => {
          acc[item.id] = pick(item, ['id', 'title']);

          return acc;
        }, {});

        this.tagsList = Object.freeze(mappedTagsList);
      },

      removeFilter(type, value) {
        const filter = deepcopy(this.modelValue);

        switch (type) {
          case this.$options.FILTER_TYPE_PUBLISH_STATUS:
          case this.$options.FILTER_TYPE_CREATION_DATE:
            filter[type] = null;
            break;
          case this.$options.FILTER_TYPE_TAGS:
          case this.$options.FILTER_TYPE_CREATOR:
            filter[type] = filter[type].filter(item => item !== value);

            if (!filter[type].length) {
              filter[type] = null;
            }
            break;
          default:
            console.error(`Unknown filter type ${value}`);
            break;
        }

        this.$emit('update:modelValue', filter);
      },

      resetAllFilters() {
        this.$emit('update:modelValue', {
          creatorsEmails: null,
          tags: null,
          publishStatus: null,
          creationDatesRange: null,
        });
      },
    },
  };
</script>
