import { APP_BUILD_SPEC_INTERNATIONAL, APP_BUILD_SPEC_RU } from 'src/constants';

import logo from 'src/assets/logo.svg';
import ruLogo from 'src/assets/logo_ru.svg';
import installedExtensionImage from 'src/assets/installedExtension.jpg';
import installedExtensionRuImage from 'src/assets/installedExtensionRu.jpg';
import desktopLogo from 'src/assets/desktopLogo.svg';
import desktopLogoRu from 'src/assets/desktopLogoRu.svg';

const getBuildSpec = () => {
  switch (process.env.VUE_APP_BUILD_SPEC) {
    case APP_BUILD_SPEC_RU:
      return APP_BUILD_SPEC_RU;
    default:
    case APP_BUILD_SPEC_INTERNATIONAL:
      return APP_BUILD_SPEC_INTERNATIONAL;
  }
};

const getBuildSpecConfig = () => {
  switch (getBuildSpec()) {
    case APP_BUILD_SPEC_RU:
      return {
        localeType: APP_BUILD_SPEC_RU,
        hintText: 'Хинт',
        edText: 'Ед',
        companyName: 'ХинтEд',
        companyDomain: 'hintedme.ru',
        desktopName: 'ХинтЕд Ассистент',
        logoUrl: ruLogo,
        desktopLogoUrl: desktopLogoRu,
        privacyUrl: 'https://hintedme.ru/PrivacyPolicy',
        userAgreementUrl: 'https://hintedme.ru/useragreement',
        supportEmail: 'support@hintedme.ru',
        smartManualExtensionUrl:
          'https://chrome.google.com/webstore/detail/hinted-smart-manuals-edit/fndkgbgkmmoblbhlbdkacjcbngdlokpi',
        editorExtensionUrl:
          'https://chrome.google.com/webstore/detail/хинтед-редактор/mjjabhcjfdalfhicajajhmpoephhkbhj',
        installedExtensionImage: installedExtensionRuImage,
      };
    case APP_BUILD_SPEC_INTERNATIONAL:
    default:
      return {
        localeType: APP_BUILD_SPEC_INTERNATIONAL,
        hintText: 'Hint',
        edText: 'Ed',
        companyName: 'HintEd',
        companyDomain: 'hinted.me',
        desktopName: 'HintEd Assistant',
        logoUrl: logo,
        desktopLogoUrl: desktopLogo,
        privacyUrl: 'https://hinted.me/privacy-policy',
        userAgreementUrl: 'https://smartmanuals.hinted.me/terms.html',
        supportEmail: 'support@hinted.me',
        smartManualExtensionUrl:
          'https://chrome.google.com/webstore/detail/hinted-smart-manuals-edit/fndkgbgkmmoblbhlbdkacjcbngdlokpi',
        editorExtensionUrl:
          'https://chrome.google.com/webstore/detail/hinted-editor/pomgnikapieeibhibecdjghdjnnianik',
        installedExtensionImage,
      };
  }
};

export const apiUrl = window.location.origin;
export const version = process.env.VUE_APP_VERSION;
export const buildSpec = getBuildSpec();
export const buildSpecConfig = getBuildSpecConfig();
