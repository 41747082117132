<template>
  <v-card>
    <v-card-title>
      Legal info
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-form @submit.prevent="onSubmit">
          <v-row>
            <v-col>
              <v-combobox
                v-model="inn"
                label="ИНН"
                :items="companies"
                :filter="filter"
                item-value="inn"
                item-text="inn"
                @change="onPick"
                @update:search-input="debouncedOnChange"
              >
                <template #item="{ item }">
                  <template v-if="item">
                    ИНН {{ item.inn }}

                    <template v-if="item.kpp"> КПП {{ item.kpp }} </template>

                    {{ item.companyName }}
                  </template>
                </template>
              </v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field v-model="companyName" label="Название компании" outlined />
            </v-col>

            <v-col cols="6">
              <v-text-field v-model="kpp" label="КПП" outlined />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field v-model="address" label="Адрес" outlined />
            </v-col>
          </v-row>

          <v-row justify="end">
            <v-col cols="auto">
              <v-btn type="submit" :loading="isBusy" color="primary">Save</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex';
  import debounce from 'lodash.debounce';

  export default {
    name: 'LegalInfo',

    data() {
      return {
        inn: null,
        kpp: null,
        address: null,
        companyName: null,
        companies: [],
        hasData: false,
        isSearching: false,
        isBusy: false,
      };
    },

    async mounted() {
      this.isBusy = true;

      try {
        const result = await this.get();
        const { data } = result;
        this.hasData = true;
        this.inn = data.inn;
        this.companyName = data.companyName;
        this.kpp = data.kpp;
        this.address = data.address;
        // eslint-disable-next-line no-empty
      } catch (err) {
      } finally {
        this.isBusy = false;
      }
    },

    methods: {
      ...mapActions('legal', ['getInfo', 'get', 'create', 'update']),

      async onInnChange(value) {
        if (this.isSearching) {
          return;
        }

        this.isSearching = true;

        try {
          const param = this.clearInn(value);

          if (!param || !param.length) {
            this.isSearching = false;
            return;
          }

          const results = await this.getInfo(param);

          if (!Array.isArray(results)) {
            this.isSearching = false;
            return;
          }

          this.companies = results;
        } catch (err) {
          console.error('searching error', err);
        } finally {
          this.isSearching = false;
        }
      },

      debouncedOnChange: debounce(async function request(value) {
        await this.onInnChange(value);
      }, 300),

      onPick(value) {
        this.inn = value.inn;

        if (!this.address) {
          this.address = value.address;
        }

        if (!this.kpp) {
          this.kpp = value.kpp;
        }

        if (!this.companyName) {
          this.companyName = value.companyName;
        }
      },

      async onSubmit() {
        if (this.isBusy) {
          return;
        }

        this.isBusy = true;

        const payload = {
          data: {
            companyName: this.companyName,
            kpp: this.kpp,
            inn: this.inn,
            address: this.address,
          },

          countryCode: 'RU',
        };

        try {
          if (this.hasData) {
            await this.update(payload);
            return;
          }

          await this.create(payload);
        } catch (err) {
          console.error('err >>>>', err);
        } finally {
          this.isBusy = false;
        }
      },

      filter(item, query, itemText) {
        if (!query || !query.length) {
          return false;
        }

        const currentQuery = this.clearInn(query);
        return currentQuery === itemText;
      },

      clearInn(value) {
        if (!value) {
          return '';
        }

        return value.replace(/[^0-9]/g, '').trim();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card {
    max-width: 1000px;
  }
</style>
