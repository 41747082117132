<template>
  <v-card>
    <v-card-title class="pa-4 primary">
      <span class="ma-1 title white--text">
        {{ $t('pages.users.ownerDeletionDialog.title') }}
      </span>

      <v-spacer />

      <v-btn icon color="white" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pt-6">
      <v-container>
        <span class="black--text subtitle-1">
          {{ $t('pages.users.ownerDeletionDialog.warningText') }}
        </span>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn :disabled="!isSuperAdmin" color="primary" text @click="$emit('changeOwner')">
        {{ $t('pages.users.ownerDeletionDialog.changeOwnerButton') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'OwnerDeletionDialog',

    computed: {
      ...mapGetters('users', ['isSuperAdmin']),
    },
  };
</script>
