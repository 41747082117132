const list = {
  name: 'list',
  method: 'get',
};

const getSimpleList = {
  name: 'getSimpleList',
  method: 'get',
  url: 'list',
};

const add = {
  name: 'add',
  method: 'post',
  url: '/api/users/',
};

const createMany = {
  name: 'createMany',
  method: 'post',
  url: 'create-many',
};

const update = {
  name: 'update',
  method: 'patch',
  url: ':userId',
};

const me = {
  name: 'me',
  method: 'get',
  url: 'me',
};

const remove = {
  name: 'remove',
  method: 'post',
  url: ':id/remove',
};

const getRoles = {
  name: 'getRoles',
  method: 'get',
  url: 'roles',
};

const sendRecoveryCode = {
  name: 'sendRecoveryCode',
  method: 'post',
  url: '/api/users/password-recover/send-code',
};

const recoverPassword = {
  name: 'recoverPassword',
  method: 'post',
  url: '/api/users/password-recover',
};

const approveUsers = {
  name: 'approveUsers',
  method: 'post',
  url: 'approve',
};

const setLocale = {
  name: 'setLocale',
  method: 'post',
  url: 'set-locale',
};

const changePassword = {
  name: 'changePassword',
  method: 'post',
  url: 'changePassword',
};

const updateCurrent = {
  name: 'updateCurrent',
  method: 'patch',
  url: 'edit',
};

const block = {
  name: 'block',
  method: 'post',
  url: '/api/users/block/:id',
};

const unblock = {
  name: 'unblock',
  method: 'get',
  url: '/api/users/unblock/:id',
};

const sendLoginLink = {
  name: 'sendLoginLink',
  method: 'post',
  url: '/api/users/send-login-link',
};

const getLoginLinkEmailRetryPeriod = {
  name: 'getLoginLinkEmailRetryPeriod',
  method: 'get',
  url: '/api/users/login-link/period',
};

export default {
  name: 'users',
  url: '/api/users/',
  children: [
    list,
    getSimpleList,
    add,
    createMany,
    update,
    me,
    remove,
    getRoles,
    sendRecoveryCode,
    recoverPassword,
    approveUsers,
    setLocale,
    changePassword,
    updateCurrent,
    block,
    unblock,
    sendLoginLink,
    getLoginLinkEmailRetryPeriod,
  ],
};
