var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',{staticClass:"pt-6"},[_c('v-combobox',{attrs:{"items":_vm.filteredTags,"filter":_vm.filterTags,"label":_vm.$t('ui.manageTagsModal.unbindTagLabel'),"type":"button","item-text":"title","item-value":"id","item-disabled":"isSystemTag","small-chips":"","hide-selected":"","persistent-hint":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":item.color,"input-value":selected,"small":""}},'v-chip',attrs,false),[_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){_vm.unbindTag = null}}},[_vm._v(" $delete ")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.color,"small":""}},[_vm._v(" "+_vm._s(item.title)+" ")])]}}]),model:{value:(_vm.unbindTag),callback:function ($$v) {_vm.unbindTag=$$v},expression:"unbindTag"}}),_c('v-combobox',{attrs:{"items":_vm.filteredTags,"filter":_vm.filterTags,"type":_vm.isRealAdminOrHigher ? 'text' : 'button',"label":_vm.$t('ui.manageTagsModal.bindTagLabel'),"hint":_vm.$t('ui.manageTagsModal.bindTagHint'),"item-text":"title","item-value":"id","item-disabled":"isSystemTag","small-chips":"","hide-selected":"","persistent-hint":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":item.color,"input-value":selected,"small":""}},'v-chip',attrs,false),[_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){_vm.bindTag = null}}},[_vm._v(" $delete ")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.deletingTagId)?_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.$t('pages.scenarios.editModal.tagsDeleteConfirm'))+" ")]):_vm._e(),_c('v-chip',{attrs:{"color":item.color,"small":""}},[_vm._v(" "+_vm._s(item.title)+" ")]),(item.id === _vm.deletingTagId)?_c('span',{staticClass:"ml-1"},[_vm._v(" ? ")]):_vm._e(),_c('v-spacer'),(_vm.isRealAdminOrHigher)?_c('v-list-item-action',{staticClass:"flex align-center flex-row  flex-grow-0 flex-nowrap",on:{"click":function($event){$event.stopPropagation();}}},[(_vm.deletingTagId === item.id)?[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeTag()}}},[_c('v-icon',{attrs:{"small":"","color":"gray"}},[_vm._v(" mdi-check ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.deletingTagId = null}}},[_c('v-icon',{attrs:{"small":"","color":"gray"}},[_vm._v(" mdi-close ")])],1)]:_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){_vm.deletingTagId = item.id}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],2):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('pages.scenarios.editModal.tagsEnterCaption'))}})])],1)],1)]},proxy:true}]),model:{value:(_vm.bindTag),callback:function ($$v) {_vm.bindTag=$$v},expression:"bindTag"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }