<template>
  <span>
    {{ currentNumber }}
  </span>
</template>

<script>
  export default {
    name: 'TableRowNumber',

    props: {
      serverLimit: { type: Number, default: 1 },
      serverPage: { type: Number, default: 1 },
      index: { type: Number, default: 0 },
    },

    computed: {
      currentNumber() {
        let currentLimit = this.serverLimit;
        let currentPage = this.serverPage;

        if (currentLimit < 0 || typeof currentLimit !== 'number') {
          currentLimit = 1;
          currentPage = 1;
        }

        return (currentPage - 1) * currentLimit + this.index + 1;
      },
    },
  };
</script>
