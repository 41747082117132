<template>
  <h3 class="title font-weight-regular">
    <span class="lstick" />

    <slot />
  </h3>
</template>

<script>
  export default {
    name: 'BaseTableTitle',
  };
</script>
