<template>
  <v-form ref="form" @submit.prevent="create()">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t('pages.users.createManyModal.title') }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col class="d-flex">
            <v-file-input
              :error-count="usersErrors.length"
              :error-messages="usersErrors"
              :label="$t('pages.users.createManyModal.fileLabel')"
              prepend-icon=""
              accept=".txt"
              outlined
              dense
              @change="loadUsers($event)"
            />
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col class="py-0">
            <v-alert color="info" text>
              <template #prepend>
                <v-icon color="info" x-large class="mr-5">mdi-information</v-icon>
              </template>

              <template #default>
                <h4 class="mb-3">{{ $t('pages.users.createManyModal.alertHeader') }}</h4>

                <!-- eslint-disable vue/no-v-html -->
                <div v-html="$t('pages.users.createManyModal.alertText')" />
                <!-- eslint-enable vue/no-v-html -->

                <div class="mt-3">
                  {{ $t('pages.users.createManyModal.alertUsernameValidation') }}
                </div>

                <div>
                  {{ $t('pages.users.createManyModal.alertPasswordValidation') }}
                </div>
              </template>
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="prefix"
              :label="$t('pages.users.createManyModal.prefixLabel')"
              :rules="prefixRules"
              required
            />
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="postfix"
              :label="$t('pages.users.createManyModal.postfixLabel')"
              :rules="prefixRules"
              required
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="payload.domain"
              :rules="domainRules"
              :label="
                $t('pages.users.createManyModal.domainLabel', {
                  companyDomain: $options.COMPANY_DOMAIN,
                })
              "
              required
            />
          </v-col>

          <v-col cols="6">
            <v-select
              v-model="payload.role"
              :label="$t('common.fields.role')"
              :items="rolesItems"
              :disabled="!isSuperAdmin && isStartupPlanActive"
            />
          </v-col>
        </v-row>

        <v-row v-if="!isSmartManualsPlansActive">
          <v-col>
            <v-combobox
              v-model="payload.groups"
              :items="groupsList"
              :rules="groupsRules"
              :label="$t('pages.users.createManyModal.groupsLabel')"
              :hint="$t('pages.users.groupsModal.inputHint')"
              item-text="title"
              multiple
              small-chips
              deletable-chips
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="$emit('close')">
          {{ $t('ui.common.cancel') }}
        </v-btn>

        <v-btn :loading="isBusy" color="blue darken-1" type="submit" text>
          {{ $t('ui.common.create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';

  import api from 'src/api';

  import { buildSpecConfig } from 'src/config';
  import { busyFlow, deepcopy } from 'src/utils';

  export default {
    name: 'CreateManyDialog',

    data() {
      return {
        groupsList: [],
        payload: {
          groups: [],
          role: 'USER',
          usersList: [],
          domain: null,
        },
        prefix: null,
        postfix: null,
        usersErrors: [],
        isBusy: false,
      };
    },

    COMPANY_DOMAIN: buildSpecConfig.companyDomain,

    computed: {
      ...mapState('users', ['usersRoles', 'currentUser']),
      ...mapGetters('users', ['isSuperAdmin', 'isStartupPlanActive', 'isSmartManualsPlansActive']),

      domainRules() {
        return [
          v =>
            !!v || this.$t('validation.notNullField', { field: this.$t('common.fields.domain') }),
          v =>
            /^@[a-z0-9-]+(\.[a-z0-9]+)+$/gi.test(v) ||
            this.$t('pages.users.createManyModal.domainError'),
        ];
      },

      prefixRules() {
        return [
          v => !v || /^[a-z0-9]+$/gi.test(v) || this.$t('pages.users.createManyModal.prefixError'),
        ];
      },

      groupsRules() {
        return [v => !!v.length || this.$t('pages.users.createManyModal.groupsError')];
      },

      rolesItems() {
        return this.usersRoles.map(role => ({
          value: role,
          text: this.$t(`common.roles.${role}`),
        }));
      },
    },

    mounted() {
      if (!this.isSmartManualsPlansActive) {
        this.getGroupsList();
      } else {
        this.getRootGroup();
      }

      this.getList({ organizationId: this.currentUser.organizationId, limit: 'all' });
      this.getUsersRoles();
    },

    methods: {
      ...mapActions('users', ['getUsersRoles', 'createManyUsers']),

      async getGroupsList() {
        const { response } = await api.groups.list({ params: { limit: 'all' } });

        this.groupsList = Object.freeze(response.payload);
      },

      async getRootGroup() {
        const { response } = await api.groups.getRoot();

        this.payload.groups = [response];
      },

      async loadUsers(file) {
        this.usersErrors = [];
        this.payload.usersList = [];

        if (!file) {
          return;
        }

        const text = await file.text();

        let usersList = text.split(/\r?\n/).filter(userString => Boolean(userString));
        usersList = usersList.map(userString => {
          const [username, ...password] = userString.split(':');

          return {
            username,
            ...(password?.length ? { password: password.join(':') } : {}),
          };
        });

        this.payload.usersList = usersList;

        usersList.forEach((user, index) => {
          if (!user.username.match(/^[a-z0-9]+$/gi)) {
            this.$t('pages.users.createManyModal.fileContentUsernameError', {
              line: index + 1,
              username: user.username,
            });
          }

          if (user.password != null && user.password.length < 6) {
            this.usersErrors.push(
              this.$t('pages.users.createManyModal.fileContentPasswordError', {
                line: index + 1,
                password: user.password,
                username: user.username,
              })
            );
          }
        });
      },

      preparePayload() {
        const data = deepcopy(this.payload);
        data.groups = data.groups.length ? data.groups.map(({ id }) => id) : undefined;
        data.domain = data.domain.substring(1);
        data.usersList = data.usersList.map(user => {
          const { username } = user;
          let fullUsername = '';

          if (this.prefix) {
            fullUsername += `${this.prefix}_`;
          }

          fullUsername += username;

          if (this.postfix) {
            fullUsername += `-${this.postfix}`;
          }

          return {
            ...user,
            username: fullUsername,
          };
        });

        return data;
      },

      create() {
        if (this.usersErrors.length) {
          return;
        }

        if (!this.payload.usersList.length) {
          this.usersErrors = [this.$t('pages.users.createManyModal.fileError')];

          return;
        }

        if (!this.$refs.form.validate()) {
          return;
        }

        const payload = this.preparePayload();
        busyFlow.call(this, async () => {
          await this.createManyUsers(payload);
          this.$emit('created');
        });
      },
    },
  };
</script>

<style lang="scss"></style>
