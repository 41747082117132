<template>
  <v-menu ref="menu" offset-y right :close-on-content-click="false" :min-width="340">
    <template #activator="{ on, attrs }">
      <v-btn small icon v-bind="attrs" v-on="on">
        <v-badge color="primary" :content="filtersCount" :value="filtersCount" overlap>
          <v-icon>mdi-filter-outline</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-list v-if="mode === $options.FILTER_TYPE_DEFAULT" dense>
      <div class="px-4 py-3 firstItem">
        <span>{{ $t('pages.scenarios.filters.filterBy') }}:</span>

        <button class="font-select__button" @click="resetFilters()">
          {{ $t('pages.scenarios.filters.reset') }}
        </button>
      </div>

      <v-list-item v-if="isEnterprisePlanActive" @click="setMode($options.FILTER_TYPE_TAGS)">
        <v-list-item-icon class="mr-0">
          <v-icon small>mdi-tag-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ $t('pages.scenarios.filters.byTags') }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-badge color="primary" :content="tagsLength" :value="tagsLength" inline />
        </v-list-item-icon>
      </v-list-item>

      <v-list-item @click="setMode($options.FILTER_TYPE_CREATOR)">
        <v-list-item-icon class="mr-0">
          <v-icon small>mdi-account-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ $t('pages.scenarios.filters.byCreator') }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-badge color="primary" :content="usersLength" :value="usersLength" inline />
        </v-list-item-icon>
      </v-list-item>

      <v-list-item @click="setMode($options.FILTER_TYPE_CREATION_DATE)">
        <v-list-item-icon class="mr-0">
          <v-icon small>mdi-calendar-blank-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ $t('pages.scenarios.filters.byCreationDate') }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-badge
            color="primary"
            :content="filters.creationDatesRange ? 1 : 0"
            :value="filters.creationDatesRange ? 1 : 0"
            inline
          />
        </v-list-item-icon>
      </v-list-item>

      <v-list-item @click="setMode($options.FILTER_TYPE_PUBLISH_STATUS)">
        <v-list-item-icon class="mr-0">
          <v-icon small>mdi-eye-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ $t('pages.scenarios.filters.byPublish') }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-badge
            color="primary"
            :content="typeof filters.publishStatus === 'boolean' ? 1 : 0"
            :value="typeof filters.publishStatus === 'boolean' ? 1 : 0"
            inline
          />
        </v-list-item-icon>
      </v-list-item>
    </v-list>

    <tags-filter
      v-if="mode === $options.FILTER_TYPE_TAGS"
      :current-filter="filters.tags"
      @updated="updateFilters($options.FILTER_TYPE_TAGS, $event)"
      @back="setMode($options.FILTER_TYPE_DEFAULT)"
    />

    <creator-filter
      v-if="mode === $options.FILTER_TYPE_CREATOR"
      :current-filter="filters.creatorsEmails"
      @updated="updateFilters($options.FILTER_TYPE_CREATOR, $event)"
      @back="setMode($options.FILTER_TYPE_DEFAULT)"
    />

    <creation-dates-filter
      v-if="mode === $options.FILTER_TYPE_CREATION_DATE"
      :current-filter="filters.creationDatesRange"
      @updated="updateFilters($options.FILTER_TYPE_CREATION_DATE, $event)"
      @back="setMode($options.FILTER_TYPE_DEFAULT)"
    />

    <publish-status-filter
      v-if="mode === $options.FILTER_TYPE_PUBLISH_STATUS"
      :current-filter="filters.publishStatus"
      @updated="updateFilters($options.FILTER_TYPE_PUBLISH_STATUS, $event)"
      @back="setMode($options.FILTER_TYPE_DEFAULT)"
    />
  </v-menu>
</template>

<script>
  import moment from 'moment';
  import { mapGetters } from 'vuex';

  import TagsFilter from 'src/components/WidgetEntities/components/TagsFilter.vue';
  import CreatorFilter from 'src/components/WidgetEntities/components/CreatorFilter.vue';
  import PublishStatusFilter from 'src/components/WidgetEntities/components/PublishStatusFilter.vue';
  import CreationDatesFilter from 'src/components/WidgetEntities/components/CreationDatesFilter.vue';

  import { deepcopy } from 'src/utils';

  const DEFAULT_FILTER = {
    creatorsEmails: null,
    tags: null,
    publishStatus: null,
    creationDatesRange: null,
  };

  export default {
    name: 'FiltersMenu',

    components: { CreatorFilter, TagsFilter, PublishStatusFilter, CreationDatesFilter },

    model: {
      prop: 'modelValue',
      event: 'update:modelValue',
    },

    props: {
      modelValue: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        mode: 0,
        filters: {
          ...DEFAULT_FILTER,
        },
      };
    },

    FILTER_TYPE_DEFAULT: 0,
    FILTER_TYPE_TAGS: 1,
    FILTER_TYPE_CREATOR: 2,
    FILTER_TYPE_CREATION_DATE: 3,
    FILTER_TYPE_PUBLISH_STATUS: 4,

    computed: {
      ...mapGetters('users', ['isEnterprisePlanActive']),

      filtersCount: {
        get() {
          let count = 0;

          if (this.filters) {
            Object.keys(this.filters).forEach(key => {
              if (this.filters[key] || typeof this.filters[key] === 'boolean') {
                count += 1;
              }
            });
          }

          return count;
        },
      },

      tagsLength: {
        get() {
          let count = 0;

          if (this.filters?.tags) {
            count = this.filters.tags.length;
          }

          return count;
        },
      },

      usersLength: {
        get() {
          let count = 0;

          if (this.filters?.creatorsEmails) {
            count = this.filters.creatorsEmails.length;
          }

          return count;
        },
      },
    },

    watch: {
      modelValue: {
        handler(value) {
          this.filters = deepcopy(value);
        },

        deep: true,
      },
    },

    methods: {
      updateFilters(type, data) {
        const filters = deepcopy(this.filters);

        switch (type) {
          case this.$options.FILTER_TYPE_TAGS:
            filters.tags = data.length > 0 ? data : null;
            break;
          case this.$options.FILTER_TYPE_CREATOR:
            filters.creatorsEmails = data.length > 0 ? data : null;
            break;
          case this.$options.FILTER_TYPE_PUBLISH_STATUS:
            filters.publishStatus = data !== 'all' ? data : null;
            break;
          case this.$options.FILTER_TYPE_CREATION_DATE:
            filters.creationDatesRange =
              data.length > 0
                ? {
                    from: moment(data[0])
                      .startOf('day')
                      .utc()
                      .valueOf(),
                    to: moment(data[1])
                      .endOf('day')
                      .utc()
                      .valueOf(),
                  }
                : null;
            break;
          default:
            console.error(`Unknown filter type ${type}`);
            break;
        }

        this.$emit('update:modelValue', filters);
      },

      resetFilters() {
        this.$emit('update:modelValue', { ...DEFAULT_FILTER });
      },

      async setMode(mode) {
        this.mode = mode;

        await this.$nextTick();

        this.$refs.menu.updateDimensions();
      },
    },
  };
</script>

<style>
  .firstItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    font-weight: 400;
    background-color: #ffffff;
  }
</style>
