<template>
  <v-card class="justify-center position-relative">
    <div class="close-button" @click="$emit('close')">
      <v-icon color="black">mdi-close</v-icon>
    </div>

    <v-card-title class="justify-center pt-7">
      <span class="ma-1 title black--text">
        {{ $t('pages.simulations.createFromExtension.create') }}
      </span>
    </v-card-title>

    <v-card-text class="text-center black--text">
      {{ $t('pages.simulations.createFromExtension.description') }}:
    </v-card-text>

    <div class="list-container">
      <div class="list">
        <div v-for="item in tabs" :key="item.id" class="list__item" @click="onItemClick(item)">
          <div class="list-icon">
            <img class="list-icon__img" :src="item.favicon || noFavicon" @error="onLoadingError" />
          </div>

          <div class="list-text">{{ item.title }}</div>
        </div>
      </div>
    </div>

    <v-card-text class="text-center black--text pa-0 mt-2">
      {{ $t('pages.simulations.createFromExtension.or') }}
    </v-card-text>

    <v-card-text class="text-center black--text">
      {{ $t('pages.simulations.createFromExtension.goToExtension') }}
      <a :href="extensionUrl" target="_blank">{{ extensionTitle }}</a>
    </v-card-text>
  </v-card>
</template>

<script>
  import noFavicon from 'src/assets/no-favicon.svg';
  import * as config from 'src/config';
  import api from 'src/api';

  export default {
    name: 'CreateSimulationFromExtensionModal',

    props: {
      extensionUrl: { type: String, default: '' },
      extensionTitle: { type: String, default: '' },
    },

    data() {
      return {
        noFavicon,
        tabs: [],
      };
    },

    async mounted() {
      this.tabs = await this.getTabs();
    },

    methods: {
      async signTransitionToken() {
        const {
          response: { token },
        } = await api.auth.signTransitionToken();

        return token;
      },

      getTabs() {
        return window.hintedExtension.getTabs();
      },

      onLoadingError(event) {
        const currentEvent = event;
        currentEvent.target.src = noFavicon;
      },

      async onItemClick(item) {
        const token = await this.signTransitionToken();

        if (!token) {
          return;
        }

        window.hintedExtension.startSimulationRecord(item, token, config.apiUrl);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .list-container {
    display: flex;
    justify-content: center;
  }

  .list {
    display: block;
    overflow-y: auto;
    max-height: 400px;
    padding: 0 7px 0 15px;
    margin-right: 8px;

    &__item {
      display: flex;
      align-items: center;
      padding: 15px;
      border: 1px solid #dae4e8;
      min-height: 10px;
      border-radius: 5px;
      transition: all 0.5s;
    }

    &__item:hover {
      border-color: transparent;
      background-color: rgba(218, 228, 232, 0.5);
      cursor: pointer;
    }

    &__item + &__item {
      margin-top: 15px;
    }
  }

  .list-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;

    &__img {
      display: inline-block;
      width: 16px;
    }
  }

  .list-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .list-icon + .list-text {
    margin-left: 15px;
  }

  .close-button {
    display: flex;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
</style>
