/* eslint-disable no-shadow */
import api from 'src/api';
import { deepcopy, getLocationForQueries, getPercent, strToHtml } from 'src/utils';

import {
  DURATION_TO_AMOUNT_DATA,
  INTERRUPTED_AND_TOTAL_DATA,
  SCENARIO_CARDS_DATA,
  SCENARIO_STATISTICS_DATA,
  SCENARIO_ERRORS_HISTORY,
  SCENARIO_STATS_DATA,
  SCENARIO_STEPS_STATS_DATA,
  STATS_SET_FILTER,
  STATS_SET_SCENARIO_ID,
  STEP_DURATIONS_DATA,
  STATS_SET_HELP_CENTER_CHART_DATA,
  STATS_SET_HELP_CENTER_CARDS_DATA,
  STATS_SET_HELP_CENTER_SCENARIO_VISIBILITY_CHART_DATA,
  STATS_SET_HELP_CENTER_SCENARIO_VISIBILITY_CARDS_DATA,
} from 'src/store/mutation-types';
import { i18n } from 'src/plugins/i18n';

const state = {
  interruptedAndTotalChartData: {},
  durationToAmountChartData: {},
  stepDurationChartData: {},
  scenarioStatsChartData: [],
  scenarioErrorsHistory: [],
  scenarioStepsStatsChartData: [],
  helpCenterChartData: [],
  helpCenterCardsData: { total: 0, uniq: 0 },
  helpCenterScenarioVisibilityChartData: [],
  helpCenterScenarioVisibilityCardData: 0,
  scenarioId: null,
  filter: {
    from: undefined,
    to: undefined,
  },
  scenarioCardsData: {},
  scenarioStatisticsData: [],
};

const actions = {
  async getScenarioCards({ state, commit }, id) {
    if (!state.filter.from || !state.filter.to) {
      return;
    }

    const scenarioParamObj = state.scenarioId ? { scenarioId: state.scenarioId } : {};

    const { response: playStats } = await api.stats.scenarioCards({
      url_params: { id },
      params: { from: state.filter.from, to: state.filter.to, ...scenarioParamObj },
    });

    const { played, stop, interrupt, complete } = playStats;

    const data = {
      played,
      stop,
      interrupt,
      complete,
    };

    commit(SCENARIO_CARDS_DATA, data);
  },

  async getScenarioStatistics({ state, commit }, id) {
    if (!state.filter.from || !state.filter.to) {
      return;
    }

    const { response: scenarioStatistics } = await api.stats.getScenarioStatistics({
      url_params: { id },
      params: { from: state.filter.from, to: state.filter.to },
    });

    commit(SCENARIO_STATISTICS_DATA, scenarioStatistics);
  },

  async getByScenario(context, id) {
    const { response } = await api.stats.byScenario({ url_params: { id } });
    return response;
  },

  async downloadStepStats(context, { from, to, scenarioId } = {}) {
    const params = scenarioId ? { from, to, scenarioId } : { from, to };
    params.lang = getLocationForQueries();

    const { response } = await api.stats.downloadStepStatistics({
      params,
    });

    return response;
  },

  async downloadHistoricalStepStats() {
    const params = { lang: getLocationForQueries() };

    const { response } = await api.stats.downloadHistoricalStepStats({ params });

    return response;
  },

  async getDurationToAmountChart({ commit }, { scenarioId, precision }) {
    if (!scenarioId) {
      return;
    }

    const { response } = await api.stats.getDurationToAmountChart({
      url_params: { scenarioId },
      params: { precision },
    });

    const { durations, amounts } = response.reduce(
      (acc, current) => {
        acc.amounts.push(current.amount);
        acc.durations.push(current.duration);
        return acc;
      },
      { amounts: [], durations: [] }
    );

    const data = {
      labels: durations,
      datasets: [
        {
          label: 'Average walkthrough playing time',
          backgroundColor: '#1976d2',
          data: amounts,
        },
      ],
    };

    commit(DURATION_TO_AMOUNT_DATA, data);
  },

  async getStepDurationsChart({ commit }, { scenarioId }) {
    if (!scenarioId) {
      return;
    }

    const { response } = await api.stats.getStepDurationsChart({ url_params: { scenarioId } });

    const stats = response.reduce(
      (acc, current) => {
        acc.min.push(current.min);
        acc.max.push(current.max);
        acc.avg.push(current.avg);
        return acc;
      },
      { min: [], avg: [], max: [] }
    );

    const getColor = key => {
      switch (key) {
        case 'avg':
          return '#5bc0eb';
        case 'max':
          return '#ffa630';
        case 'min':
        default:
          return '#f5d547';
      }
    };

    const data = {
      labels: response.map(current => `Step ${current.stepIndex + 1}`),
      datasets: Object.entries(stats).map(([key, values]) => {
        return {
          label: key,
          backgroundColor: getColor(key),
          data: values.map((value, index) => {
            return { x: `Step ${index + 1}`, y: value };
          }),
        };
      }),
    };

    commit(STEP_DURATIONS_DATA, data);
  },

  async getInterruptedAndTotalChart({ state, commit }) {
    if (!state.scenarioId) {
      return;
    }

    const { response } = await api.stats.getInterruptedToTotalChart({
      url_params: { scenarioId: state.scenarioId },
      params: deepcopy({ from: state.filter.from, to: state.filter.to }),
    });

    const stats = response.reduce(
      (acc, current) => {
        acc.interrupted.push(current.interrupted);
        acc.total.push(current.total);
        return acc;
      },
      { interrupted: [], total: [] }
    );

    const getColor = key => {
      switch (key) {
        case 'interrupted':
          return '#5bc0eb';
        case 'total':
        default:
          return '#ffa630';
      }
    };

    const data = {
      labels: response.map(current => `Step ${current.stepIndex + 1}`),
      datasets: Object.entries(stats).map(([key, values]) => {
        return {
          label: key,
          backgroundColor: getColor(key),
          data: values.map((value, index) => {
            return { x: `Step ${index + 1}`, y: value };
          }),
        };
      }),
    };

    commit(INTERRUPTED_AND_TOTAL_DATA, data);
  },

  async getScenarioStatsChart({ state, commit }) {
    if (!state.filter.from || !state.filter.to) {
      return;
    }

    const scenarioParamObj = state.scenarioId ? { scenario_id: state.scenarioId } : {};

    const { response } = await api.stats.getScenarioStatsChart({
      params: { from: state.filter.from, to: state.filter.to, ...scenarioParamObj },
    });

    const completed = {
      color: '#5bc0eb',
      items: response.completed,
    };

    const interrupted = {
      color: '#ffa630',
      items: response.interrupted,
    };

    const unfinished = {
      color: '#188896',
      items: response.unfinished,
    };

    const items = { interrupted, unfinished, completed };

    const data = Object.entries(items).map(([key, value]) => {
      return {
        name: key,
        data: value.items.map(current => {
          return {
            x: current.date,
            y: current.value,
          };
        }),
      };
    });

    commit(SCENARIO_STATS_DATA, data);
  },

  async getScenarioStepsStatsChart({ state, commit }) {
    if (!state.filter.from || !state.filter.to || !state.scenarioId) {
      return;
    }

    const { response } = await api.stats.getScenarioStepsStatsChart({
      url_params: { scenarioId: state.scenarioId },
      params: { from: state.filter.from, to: state.filter.to },
    });

    commit(SCENARIO_STEPS_STATS_DATA, response);
  },

  async getSimulationTestingStats(context, { simulationId, datesRange, additionalParams }) {
    const { response } = await api.stats.getSimulationTestingStats({
      params: { ...datesRange, ...additionalParams, ...(simulationId ? { simulationId } : {}) },
    });

    return {
      ...response,
      payload: response.payload.map(item => {
        const getFormattedTime = timestamp => {
          const minutes = Math.floor(timestamp / 60);
          const seconds = timestamp - minutes * 60;

          return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        };

        return {
          ...item,
          time: {
            expected: getFormattedTime(item.data.expectedTime),
            ...(item.data.timeSpent ? { spent: getFormattedTime(item.data.timeSpent) } : {}),
          },
          mistakes: {
            total: item.data.errorsCount || 0,
            steps: item.data.errorsSteps || '-',
          },
          result: {
            expected: item.data.expectedResult,
            actual: item.data.resultInPercent || 0,
          },
          status: item.statuses.includes('SUCCESS') ? 'SUCCESS' : 'FAILED',
        };
      }),
    };
  },

  async getSimulationStatsChart(context, { simulationId, from, to }) {
    const { response } = await api.stats.getSimulationStatsChart({
      params: {
        from,
        to,
        ...(simulationId ? { simulationId } : {}),
      },
    });

    const result = Object.entries(response).reduce((accObj, [key, value]) => {
      const store = accObj;
      store[key] = value.reduce(
        (acc, current) => {
          acc.completed.push({ value: current.completed, date: current.eventDate });
          acc.started.push({ value: current.started, date: current.eventDate });
          acc.unfinished.push({ value: current.unfinished, date: current.eventDate });

          return acc;
        },
        { started: [], unfinished: [], completed: [] }
      );

      return store;
    }, {});

    const testingResults = {
      completed: {
        color: '#5bc0eb',
        items: result.testing.completed,
      },

      unfinished: {
        color: '#188896',
        items: result.testing.unfinished,
      },

      testingStarted: {
        color: '#5bc0eb',
        items: result.testing.started,
      },
    };

    const playingResults = {
      completed: {
        color: '#5bc0eb',
        items: result.playing.completed,
      },

      unfinished: {
        color: '#188896',
        items: result.playing.unfinished,
      },

      started: {
        color: '#5bc0eb',
        items: result.playing.started,
      },
    };

    const formatResults = object =>
      Object.entries(object).map(([key, value]) => {
        return {
          name: key,
          data: value.items.map(current => {
            return {
              x: current.date,
              y: current.value,
            };
          }),
        };
      });

    const getCardsResults = object => {
      const entries = Object.entries(object).map(([key, entry]) => {
        const valuesArr = Object.values(entry).map(value => value.value);

        return { [key]: valuesArr.reduce((partialSum, a) => partialSum + a, 0) };
      });

      return Object.assign(...entries);
    };

    return {
      playing: formatResults(playingResults),
      testing: formatResults(testingResults),

      cards: {
        testing: getCardsResults(result.testing),
        playing: getCardsResults(result.playing),
      },
    };
  },

  async getTooltipStatsChart(context, { tooltipId, from, to }) {
    const { response } = await api.stats.getTooltipStatsChart({
      url_params: { tooltipId },
      params: { from, to },
    });

    const total = {
      fillColor: '#ffa630',
      items: response.total,
    };

    const unique = {
      fillColor: '#5bc0eb',
      items: response.unique,
    };

    const items = { unique, total };

    return Object.entries(items).map(([key, value]) => {
      return {
        name: key,
        data: value.items.map(current => {
          return {
            x: current.stepNumber,
            y: current.value,
          };
        }),
      };
    });
  },

  async getHelpCenterStatsChart({ commit }, { organizationId, from, to }) {
    const { response } = await api.v2statistics.getOrganizationHelpCenterChart({
      url_params: { organizationId },
      params: { from, to },
    });

    const { total: totalItems, uniq: uniqItems, totalEvents } = response.reduce(
      (acc, { date, uniqCount, totalCount }) => {
        const totalItem = { date, value: totalCount };
        const uniqItem = { date, value: uniqCount };
        acc.totalEvents.uniq += uniqCount;
        acc.totalEvents.total += totalCount;

        acc.total.unshift(totalItem);
        acc.uniq.unshift(uniqItem);

        return acc;
      },
      { total: [], uniq: [], totalEvents: { uniq: 0, total: 0 } }
    );

    const total = {
      fillColor: '#7c54ea',
      items: totalItems,
    };

    const uniq = {
      fillColor: '#2c82e2',
      items: uniqItems,
    };

    const items = { totalOpened: total, uniqOpened: uniq };

    const data = Object.entries(items).map(([key, value]) => {
      return {
        name: key,
        data: value.items.map(current => {
          return {
            x: current.date,
            y: current.value,
          };
        }),
      };
    });

    commit(STATS_SET_HELP_CENTER_CHART_DATA, data);
    commit(STATS_SET_HELP_CENTER_CARDS_DATA, totalEvents);
  },

  async getHelpCenterScenarioVisibilityChart({ commit }, { organizationId, from, to }) {
    const { response } = await api.v2statistics.getOrganizationScenarioVisibilityChart({
      url_params: { organizationId },
      params: { from, to },
    });

    const hiddenCount = {
      fillColor: '#2c82e2',
      items: response,
    };

    const items = { hiddenScenarios: hiddenCount };

    const data = Object.entries(items).map(([key, value]) => {
      return {
        name: key,
        data: value.items.map(current => {
          return {
            x: current.eventDate,
            y: current.count,
          };
        }),
      };
    });

    const cardValue = (response.length && response[response.length - 1].count) || 0;

    commit(STATS_SET_HELP_CENTER_SCENARIO_VISIBILITY_CHART_DATA, data);
    commit(STATS_SET_HELP_CENTER_SCENARIO_VISIBILITY_CARDS_DATA, cardValue);
  },

  async getScenarioErrorsHistory({ state, commit }, { limit = 10, page = 1 } = {}) {
    if (!state.scenarioId || !state.filter.from || !state.filter.to) {
      return null;
    }

    const { response } = await api.stats.scenarioStepsErrors({
      url_params: { id: state.scenarioId },
      params: { from: state.filter.from, to: state.filter.to, limit, page },
    });

    commit(SCENARIO_ERRORS_HISTORY, response.payload);

    return response.count;
  },

  clearScenarioStatsChart({ commit }) {
    commit(SCENARIO_STATS_DATA, []);
  },
};

const getters = {
  scenarioStatisticsPercentTableData: state => {
    return state.scenarioStatisticsData.map(
      ({ scenarioId, title, played, stop, complete, interrupt }) => ({
        scenarioId,
        title,
        played: {
          value: played,
          percent: getPercent(played, played),
        },
        stop: {
          value: stop,
          percent: getPercent(stop, played),
        },
        complete: {
          value: complete,
          percent: getPercent(complete, played),
        },
        interrupt: {
          value: interrupt,
          percent: getPercent(interrupt, played),
        },
      })
    );
  },

  scenarioStepsStatisticsBarChartCategories: state =>
    state.scenarioStepsStatsChartData.map(({ content }) => {
      const { innerText: stepTitle = '' } = strToHtml(content) || {};
      return stepTitle;
    }),

  scenarioStepsStatisticsBarChartData: state =>
    state.scenarioStepsStatsChartData.reduce(
      (resultData, { completed, interrupted, unfinished }) => {
        resultData[0].data.push(completed);
        resultData[1].data.push(interrupted);
        resultData[2].data.push(unfinished);
        resultData[3].data.push(Number(completed) + Number(interrupted) + Number(unfinished));
        return resultData;
      },
      [
        { name: i18n.t('pages.statistics.scenarioStepStatisticsChart.completed'), data: [] },
        { name: i18n.t('pages.statistics.scenarioStepStatisticsChart.interrupted'), data: [] },
        { name: i18n.t('pages.statistics.scenarioStepStatisticsChart.unfinished'), data: [] },
        { name: i18n.t('pages.statistics.scenarioStepStatisticsChart.played'), data: [] },
      ]
    ),
};

const mutations = {
  [DURATION_TO_AMOUNT_DATA](state, payload) {
    state.durationToAmountChartData = payload;
  },

  [STEP_DURATIONS_DATA](state, payload) {
    state.stepDurationChartData = payload;
  },

  [INTERRUPTED_AND_TOTAL_DATA](state, payload) {
    state.interruptedAndTotalChartData = payload;
  },

  [SCENARIO_CARDS_DATA](state, payload) {
    state.scenarioCardsData = payload;
  },

  [SCENARIO_STATISTICS_DATA](state, payload) {
    state.scenarioStatisticsData = payload;
  },

  [SCENARIO_STATS_DATA](state, payload) {
    state.scenarioStatsChartData = payload;
  },

  [SCENARIO_STEPS_STATS_DATA](state, payload) {
    state.scenarioStepsStatsChartData = payload;
  },

  [SCENARIO_ERRORS_HISTORY](state, payload) {
    state.scenarioErrorsHistory = payload;
  },

  [STATS_SET_SCENARIO_ID](state, id) {
    state.scenarioId = id;
  },

  [STATS_SET_FILTER](state, { to, from }) {
    state.filter = {
      to,
      from,
    };
  },

  [STATS_SET_HELP_CENTER_CHART_DATA](state, payload) {
    state.helpCenterChartData = payload;
  },

  [STATS_SET_HELP_CENTER_CARDS_DATA](state, payload) {
    state.helpCenterCardsData = payload;
  },

  [STATS_SET_HELP_CENTER_SCENARIO_VISIBILITY_CHART_DATA](state, payload) {
    state.helpCenterScenarioVisibilityChartData = payload;
  },

  [STATS_SET_HELP_CENTER_SCENARIO_VISIBILITY_CARDS_DATA](state, payload) {
    state.helpCenterScenarioVisibilityCardData = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
