export default {
  props: {
    schema: { type: Object, required: false, default: () => ({}) },
    settings: { type: Object, required: false, default: () => ({}) },
    type: { type: String, required: true },
  },

  metaInfo() {
    return {
      style: [
        {
          vmid: `${this.$options.name.toLowerCase()}-styles`,
          cssText: this.compiledCss,
          type: 'text/css',
        },
      ],
    };
  },

  data() {
    return {
      showed: false,
    };
  },

  computed: {
    compiledCss() {
      return '';
    },
  },

  async mounted() {
    await this.$loadScript('/hinted-ondemand-widget.min.js');
    this.showed = true;

    await this.$nextTick();
  },
};
