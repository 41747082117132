const send = {
  name: 'send',
  method: 'post',
  url: 'send',
};

const check = {
  name: 'check',
  method: 'get',
  url: 'code/:code/check',
};

const accept = {
  name: 'accept',
  method: 'post',
  url: 'accept',
};

export default {
  name: 'invites',
  url: '/api/invites',
  children: [send, check, accept],
};
