<template>
  <v-dialog v-model="dialog" width="630">
    <v-card>
      <v-card-title>
        <span class="title">
          {{ $t('ui.nestedDocsModal.title') }}
        </span>

        <v-spacer />

        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-radio-group v-model="downloadFile">
          <v-radio
            :label="$t('ui.nestedDocsModal.downloadOptionLabel', { format })"
            value="1"
          ></v-radio>

          <v-radio :label="$t('ui.nestedDocsModal.downloadMergedOptionLabel')" value="2"></v-radio>
        </v-radio-group>

        <v-file-input
          v-if="isNestedFile"
          v-model="file"
          :accept="`.${format.toLowerCase()}`"
          variant="outlined"
          :placeholder="$t('ui.nestedDocsModal.inputFilePlaceholder')"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          :loading="loading"
          :disabled="downloadDisabled"
          color="primary"
          @click="handleDownload"
          >{{ $t('ui.nestedDocsModal.downloadAction') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'BaseDownloadNestedDocumentDialog',

    props: {
      show: { type: Boolean, required: true, default: false },
      format: { type: String, required: true },
      isBusy: { type: Boolean, default: false },
    },

    data: () => ({
      dialog: false,
      file: null,
      downloadFile: '1',
      loading: false,
    }),

    computed: {
      isNestedFile() {
        return this.downloadFile === '2';
      },

      downloadDisabled() {
        return (!this.file && this.isNestedFile) || this.loading;
      },
    },

    watch: {
      show() {
        this.dialog = this.show;
      },

      dialog(currentDialog, prevDialog) {
        if (!currentDialog && prevDialog) {
          this.file = null;
          this.downloadFile = '1';
        }
      },

      isBusy() {
        this.loading = this.isBusy;
      },
    },

    mounted() {
      this.dialog = this.show;
    },

    methods: {
      handleDownload() {
        if (this.isNestedFile) {
          return this.$emit('download', this.file);
        }
        return this.$emit('download');
      },
    },
  };
</script>
