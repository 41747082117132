<template>
  <v-dialog :value="true" persistent width="766" close-delay="billing-feedback-modal">
    <v-card>
      <v-card-title>
        <span class="billing-feedback-modal__title">
          {{ $t('pages.billing.feedbackModal.title') }}
        </span>

        <v-spacer />

        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="name"
              outlined
              :label="$t('pages.billing.feedbackModal.name')"
              hide-details="auto"
              color="#2c8877"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="lastName"
              outlined
              :label="$t('pages.billing.feedbackModal.lastName')"
              hide-details="auto"
              color="#2c8877"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="email"
              :label="$t('pages.billing.feedbackModal.workEmail')"
              hide-details="auto"
              color="#2c8877"
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="company"
              outlined
              :label="$t('pages.billing.feedbackModal.company')"
              hide-details="auto"
              color="#2c8877"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="jobTitle"
              outlined
              :label="$t('pages.billing.feedbackModal.jobTitle')"
              hide-details="auto"
              color="#2c8877"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn-toggle
              v-model="feedbackType"
              borderless
              mandatory
              active-class="billing-feedback-modal__button-group-item--active"
            >
              <billing-button
                height="36"
                theme="gray"
                :value="$options.FEEDBACK_TYPE_REQUEST_DEMO"
                class="billing-feedback-modal__button-group-item"
              >
                {{ $t('pages.billing.feedbackModal.requestDemo') }}
              </billing-button>

              <billing-button
                height="36"
                theme="gray"
                :value="$options.FEEDBACK_TYPE_REQUEST_PRICING"
                class="billing-feedback-modal__button-group-item"
              >
                {{ $t('pages.billing.feedbackModal.requestPricing') }}
              </billing-button>

              <billing-button
                height="36"
                theme="gray"
                :value="$options.FEEDBACK_TYPE_OTHER"
                class="billing-feedback-modal__button-group-item"
              >
                {{ $t('pages.billing.feedbackModal.other') }}
              </billing-button>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <template v-if="feedbackType === $options.FEEDBACK_TYPE_REQUEST_PRICING">
          <v-row>
            <v-col>
              <v-btn-toggle
                v-model="installationType"
                borderless
                mandatory
                active-class="billing-feedback-modal__button-group-item--active"
              >
                <billing-button
                  height="36"
                  theme="gray"
                  :value="$options.INSTALLATION_TYPE_CLOUD"
                  class="billing-feedback-modal__button-group-item"
                >
                  {{ $t('pages.billing.feedbackModal.cloudService') }}
                </billing-button>

                <billing-button
                  height="36"
                  theme="gray"
                  :value="$options.INSTALLATION_TYPE_ON_PREMISE"
                  class="billing-feedback-modal__button-group-item"
                >
                  {{ $t('pages.billing.feedbackModal.onPremise') }}
                </billing-button>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model.number="applicationsCount"
                outlined
                :label="$t('pages.billing.feedbackModal.appsCount')"
                type="number"
                hide-details="auto"
                color="#2c8877"
              />
            </v-col>

            <v-col>
              <v-text-field
                v-model.number="companySize"
                outlined
                :label="$t('pages.billing.feedbackModal.companySize')"
                type="number"
                hide-details="auto"
                color="#2c8877"
              />
            </v-col>
          </v-row>
        </template>

        <v-row
          v-if="
            [$options.FEEDBACK_TYPE_REQUEST_PRICING, $options.FEEDBACK_TYPE_OTHER].includes(
              feedbackType
            )
          "
        >
          <v-col>
            <v-textarea
              v-model="additionalComment"
              outlined
              :label="$t('pages.billing.feedbackModal.comment')"
              hide-details="auto"
              color="#2c8877"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          large
          text
          color="#2c8877"
          class="billing-feedback-modal__return-button"
          @click="$emit('close')"
        >
          {{ $t('pages.billing.feedbackModal.return') }}
        </v-btn>

        <billing-button
          right
          large
          theme="green"
          :disabled="isLoading"
          :loading="isLoading"
          @click="send()"
        >
          {{ $t('pages.billing.feedbackModal.send') }}
        </billing-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex';

  import api from 'src/api';

  import BillingButton from '../BillingButton/BillingButton.vue';

  export default {
    name: 'BillingFeedbackModal',

    components: {
      BillingButton,
    },

    data() {
      return {
        isLoading: false,

        feedbackType: this.$options.FEEDBACK_TYPE_REQUEST_DEMO,
        installationType: this.$options.INSTALLATION_TYPE_CLOUD,
        name: '',
        lastName: '',
        email: '',
        company: '',
        jobTitle: '',
        companySize: 5,
        applicationsCount: 3,
        additionalComment: '',
      };
    },

    FEEDBACK_TYPE_REQUEST_DEMO: 'requestDemo',
    FEEDBACK_TYPE_REQUEST_PRICING: 'requestPricing',
    FEEDBACK_TYPE_OTHER: 'other',

    INSTALLATION_TYPE_ON_PREMISE: 'onPremise',
    INSTALLATION_TYPE_CLOUD: 'cloud',

    computed: {
      ...mapState('users', ['currentUser']),
    },

    beforeMount() {
      this.name = this.currentUser.firstname;
      this.lastName = this.currentUser.lastName;
      this.email = this.currentUser.email;
      this.company = this.currentUser.organizationName;
    },

    methods: {
      getFeedbackTypeTitle() {
        switch (this.feedbackType) {
          case this.$options.FEEDBACK_TYPE_REQUEST_DEMO:
            return 'Request demo of smart manuals enterprise plan';
          case this.$options.FEEDBACK_TYPE_REQUEST_PRICING:
            return 'Request pricing of smart manuals enterprise plan';
          case this.$options.FEEDBACK_TYPE_OTHER:
          default:
            return 'Request other feedback of smart manuals enterprise plan';
        }
      },

      getFeedbackInstallationType() {
        switch (this.installationType) {
          case this.$options.INSTALLATION_TYPE_ON_PREMISE:
            return 'On premise';
          case this.$options.INSTALLATION_TYPE_CLOUD:
          default:
            return 'Cloud';
        }
      },

      getFeedbackMessageFields() {
        const fields = [
          {
            title: 'Subject',
            value: this.getFeedbackTypeTitle(),
          },
          ...(this.name ? [{ title: 'Name', value: this.name }] : []),
          ...(this.lastName ? [{ title: 'Last name', value: this.lastName }] : []),
          ...(this.email ? [{ title: 'Email', value: this.email }] : []),
          ...(this.company ? [{ title: 'Company', value: this.company }] : []),
          ...(this.jobTitle ? [{ title: 'Job title', value: this.jobTitle }] : []),
        ];

        switch (this.feedbackType) {
          case this.$options.FEEDBACK_TYPE_REQUEST_PRICING:
            fields.push(
              {
                title: 'Installation type',
                value: this.getFeedbackInstallationType(),
              },
              {
                title: 'Numbers of applications',
                value: this.applicationsCount,
              },
              {
                title: 'Company size',
                value: this.companySize,
              },
              {
                title: 'Additional comment',
                value: this.additionalComment,
              }
            );
            break;
          case this.$options.FEEDBACK_TYPE_OTHER:
            fields.push({
              title: 'Additional comment',
              value: this.additionalComment,
            });
            break;
          case this.$options.FEEDBACK_TYPE_REQUEST_DEMO:
          default:
            break;
        }

        return fields;
      },

      async send() {
        this.isLoading = true;

        try {
          await api.organizations.sendFeedback({
            data: {
              locale: this.$i18n.locale,
              fields: this.getFeedbackMessageFields(),
            },
          });

          this.$emit('sent');
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
</script>

<style lang="scss">
  .billing-feedback-modal {
    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }

    &__button-group-item {
      font-weight: 500;
      font-family: Roboto, sans-serif;
      letter-spacing: 0.00892857em;

      &--active {
        color: #ffffff !important;
        background: linear-gradient(
          86.41deg,
          #43c6ac 2.91%,
          rgba(22, 73, 84, 0.66) 99.13%
        ) !important;
      }
    }

    &__return-button {
      font-family: Roboto, sans-serif;
      letter-spacing: 0.00892857em;
      font-weight: 700;
      text-transform: none;
    }
  }
</style>
