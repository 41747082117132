var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"styles"},[(!_vm.activeTheme)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):[_c('v-row',{staticClass:"white ma-0 pa-4 mb-4"},[_c('v-col',{staticClass:" d-flex pa-0"},[(!_vm.openedTitleEditForm)?_c('v-select',{staticClass:"mr-3",attrs:{"items":_vm.elementStyles,"item-text":"title","item-value":"id","hide-details":"","dense":"","outlined":"","label":_vm.$t('pages.styles.selectTheme')},model:{value:(_vm.activeTheme),callback:function ($$v) {_vm.activeTheme=$$v},expression:"activeTheme"}}):_c('v-text-field',{ref:"titleInput",staticClass:"mr-3",attrs:{"label":_vm.$t('pages.styles.themeTitle'),"hide-details":"","dense":"","outlined":""},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveTitle()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeEditTitleForm()}]},model:{value:(_vm.tempThemeTitle),callback:function ($$v) {_vm.tempThemeTitle=$$v},expression:"tempThemeTitle"}}),(!_vm.openedTitleEditForm)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openEditTitleForm(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-plus")])],1)]}}],null,false,1385606068)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.addTheme")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isSuperAdmin)?_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.activeTheme.isPublic ? 'info' : undefined,"icon":""},on:{"click":function($event){return _vm.publishTheme()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-cloud-upload")])],1):_vm._e()]}}],null,false,3245490184)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.publishTheme")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.copyTheme()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-content-copy")])],1)]}}],null,false,4195691443)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.createThemeCopy")))])]),(
              !_vm.activeTheme.isRoot &&
                ((_vm.activeTheme.isPublic && _vm.isSuperAdmin) ||
                  (!_vm.activeTheme.isPublic && _vm.isDesignerOrHigher))
            )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openEditTitleForm()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-pencil")])],1)]}}],null,false,55250149)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.edit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(!_vm.activeTheme.isPublic)?_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.isBusyDelete,"icon":""},on:{"click":function($event){return _vm.deleteTheme()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}}],null,false,1783804815)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.actions.delete")))])])]:_vm._e()]:[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.saveTitle()}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-check")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeEditTitleForm()}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-close")])],1)]],2),(!_vm.openedTitleEditForm && !_vm.isSmartManualsPlansActive)?_c('v-col',{staticClass:"pa-0 ml-2",attrs:{"cols":"3"}},[_c('v-select',{staticClass:"mr-3",attrs:{"items":_vm.themeTypeOptions,"hide-details":"","dense":"","outlined":"","label":_vm.$t('pages.styles.themeType')},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1):_vm._e(),_c('v-col',{staticClass:"pa-0 text-right align-center justify-end d-flex"},[_c('v-btn',{attrs:{"loading":_vm.isBusyActiveTheme,"color":"primary","small":""},on:{"click":function($event){return _vm.applyTheme()}}},[_vm._v(" "+_vm._s(_vm.$t('pages.styles.applyTheme'))+" ")])],1)],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"white ma-0 px-4"},[_c('v-col',{staticClass:"d-flex align-center pa-0"},[_c('v-tabs',{staticClass:"styles__types-tabs mr-4",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(ref){
            var title = ref.title;
return _c('v-tab',{key:title},[_vm._v(" "+_vm._s(title)+" ")])}),1),_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"grey lighten-4","small":""},on:{"click":function($event){return _vm.applyCurrentStyles()}}},[_vm._v(" "+_vm._s(_vm.$t('pages.styles.resetTheme'))+" ")]),_c('v-btn',{attrs:{"loading":_vm.isBusy,"color":"primary","small":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t('pages.styles.saveTheme'))+" ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"styles__widget-column pb-0",attrs:{"cols":"6"}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col'),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}})],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(ref){
            var title = ref.title;
            var component = ref.component;
return _c('v-tab-item',{key:title,attrs:{"transition":false}},[(component === _vm.currentKey)?_c(component,{tag:"component",attrs:{"schema":_vm.schema[component],"settings":_vm.settings[_vm.getWidgetName(component)],"type":_vm.type}}):_vm._e()],1)}),1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('styles-controls',{attrs:{"type":_vm.currentElement,"settings":_vm.currentSettings,"theme-type":_vm.type},on:{"update:settings":function($event){_vm.currentSettings = $event}},model:{value:(_vm.currentSchema),callback:function ($$v) {_vm.currentSchema=$$v},expression:"currentSchema"}})],1)],1),_c('v-snackbar',{attrs:{"color":"info","timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.showedActiveThemeToast = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('ui.common.close'))+" ")])]}}]),model:{value:(_vm.showedActiveThemeToast),callback:function ($$v) {_vm.showedActiveThemeToast=$$v},expression:"showedActiveThemeToast"}},[_vm._v(" "+_vm._s(_vm.$t('pages.styles.themeSelected'))+" ")]),_c('v-snackbar',{attrs:{"color":"success","timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.showThemeSavedToast = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('ui.common.close'))+" ")])]}}]),model:{value:(_vm.showThemeSavedToast),callback:function ($$v) {_vm.showThemeSavedToast=$$v},expression:"showThemeSavedToast"}},[_vm._v(" "+_vm._s(_vm.$t('pages.styles.themeUpdated'))+" ")]),_c('v-dialog',{staticStyle:{"z-index":"100000000"},attrs:{"max-width":"380"},model:{value:(_vm.confirmOpened),callback:function ($$v) {_vm.confirmOpened=$$v},expression:"confirmOpened"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('pages.styles.confirmModal.title'))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('pages.styles.confirmModal.text'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){return _vm.$options.cancelCallback()}}},[_vm._v(" "+_vm._s(_vm.$t('ui.common.dontSave'))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$options.confirmCallback()}}},[_vm._v(" "+_vm._s(_vm.$t('ui.common.save'))+" ")])],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }