import Vue from 'vue';
import VueMeta from 'vue-meta';
import LoadScript from 'vue-plugin-load-script';
import Editor from 'vue-editor-js/src';
import 'src/plugins/base';
import VueFeather from 'vue-feather';
import '@mdi/font/css/materialdesignicons.css';

import App from 'src/App.vue';
import vuetify from 'src/plugins/vuetify';
import router from 'src/router';
import store from 'src/store';
import { i18n } from 'src/plugins/i18n';

Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(LoadScript);
Vue.use(Editor);
Vue.use(VueFeather);
Vue.config.ignoredElements = ['hinted-modal'];

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
