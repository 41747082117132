<template>
  <v-container fluid class-name="down-top-padding">
    <v-container v-if="!hasSimulations" fluid>
      <v-row class="pb-0" justify="center">
        <v-col cols="auto">
          <v-img src="~src/assets/emptySimulationStatistic.svg" max-width="350" />
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-0">
        <v-col cols="auto" class="text-h5 font-weight-medium">{{
          $t('pages.simulations.noSimulationsTitle')
        }}</v-col>
      </v-row>

      <v-row class="mt-0" justify="center">
        <v-col cols="auto">{{ $t('pages.simulations.noSimulationsMessageStats') }}</v-col>
      </v-row>

      <v-row v-if="!isAnalyst" class="mt-0" justify="center">
        <v-col cols="auto">
          <create-simulation-button has-icon />
        </v-col>
      </v-row>
    </v-container>

    <v-row v-else class-name="pb-0">
      <v-col cols="12" class="pt-0">
        <v-container>
          <v-card flat>
            <v-card-text class="d-flex pa-5">
              <v-row align="center">
                <v-col cols="auto">
                  <h3 class="title font-weight-regular">
                    <span class="lstick" />
                    {{ $t('pages.statistics.statistics') }}:
                  </h3>
                </v-col>

                <v-col cols="3">
                  <v-autocomplete
                    v-model="simulationId"
                    :items="simulations"
                    :label="$t('pages.statistics.simulation')"
                    item-text="title"
                    item-value="id"
                    prepend-inner-icon="mdi-magnify"
                    hide-details
                  />
                </v-col>

                <v-col cols="3" md="2" xl="3">
                  <v-menu
                    ref="startDate"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        :label="$t('pages.statistics.startDate')"
                        prepend-icon="mdi-calendar"
                        hide-details
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="startDate"
                      :min="minDate"
                      :max="maxDate"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn text color="primary" @click="startDateMenu = false">
                        {{ $t('ui.common.cancel') }}
                      </v-btn>

                      <v-btn text color="primary" @click="$refs.startDate.save(startDate)">
                        {{ $t('ui.common.ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="3" md="2" xl="3">
                  <v-menu
                    ref="endDate"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="endDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        :label="$t('pages.statistics.endDate')"
                        prepend-icon="mdi-calendar"
                        readonly
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="endDate"
                      :min="startDate || minDate"
                      :max="maxDate"
                      no-title
                      scrollable
                    >
                      <v-spacer />

                      <v-btn text color="primary" @click="endDateMenu = false">
                        {{ $t('ui.common.cancel') }}
                      </v-btn>

                      <v-btn text color="primary" @click="$refs.endDate.save(endDate)">
                        {{ $t('ui.common.ok') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-spacer />

                <v-col cols="auto">
                  <v-menu>
                    <template #activator="{ props, on }">
                      <v-btn dark color="primary" small v-bind="props" v-on="on">
                        {{ $t('pages.statistics.downloadStatistics') }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="item in downloadItems"
                        :key="item.value"
                        :value="item.value"
                        @click="downloadFile(item.value)"
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>

      <v-col cols="12">
        <v-container>
          <h2>{{ $t('pages.statistics.playing') }}</h2>
        </v-container>
      </v-col>

      <v-col cols="12">
        <simulation-stats-cards :stats="playingCardsStats" />
      </v-col>

      <v-col cols="12">
        <v-container>
          <simulation-playing-chart :chart-data="playingChart" />
        </v-container>
      </v-col>

      <template v-if="isHintedPlansActive || isSmartManualsEnterprisePlanActive">
        <v-col cols="12">
          <v-container>
            <h2>{{ $t('pages.statistics.testing') }}</h2>
          </v-container>
        </v-col>

        <v-col cols="12">
          <simulation-stats-cards :stats="testingCardsStats" />
        </v-col>

        <v-col cols="12">
          <v-container>
            <simulation-testing-chart :chart-data="testingChart" />
          </v-container>
        </v-col>

        <v-col cols="12">
          <v-container>
            <v-card flat>
              <v-card-text class="d-flex pa-5">
                <v-row align="center">
                  <v-col cols="12" class="d-flex align-center">
                    <h3 class="title font-weight-regular">
                      <span class="lstick" />
                      {{ $t('pages.statistics.testingTable.testingFilter') }}:
                    </h3>

                    <v-autocomplete
                      v-model="testingUser"
                      :items="users"
                      :label="$t('pages.statistics.testingFilter.user')"
                      item-text="email"
                      item-value="id"
                      prepend-inner-icon="mdi-magnify"
                      hide-details
                      class="mx-4"
                    />

                    <v-autocomplete
                      v-model="status"
                      :items="testingStatuses"
                      :label="$t('pages.statistics.testingFilter.status')"
                      item-text="title"
                      item-value="status"
                      prepend-inner-icon="mdi-magnify"
                      hide-details
                      class="mx-4"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-col>

        <v-col cols="12">
          <v-container>
            <v-card flat>
              <v-card-text class="d-flex pa-5">
                <v-row align="center">
                  <v-col cols="12" class="d-flex align-center">
                    <h3 class="title font-weight-regular">
                      <span class="lstick" />
                      {{ $t('pages.statistics.testingTable.testingHistory') }}:
                    </h3>
                  </v-col>

                  <v-data-table
                    :headers="tableHeaders"
                    :items="testingStats"
                    :options.sync="params"
                    :server-items-length="serverItemsCount"
                    :loading="isBusy"
                    style="width: 100%;"
                  >
                    <template #no-data>
                      <travolta-not-found />
                    </template>

                    <template #item.data.startTime="{value}">
                      {{ formatDateTime(value * 1000) }}
                    </template>

                    <template #item.userId="{ item }">
                      {{ getUserInfo(item).email }}
                      <br />
                      {{ getUserInfo(item).name }}
                    </template>

                    <template #item.simulationTitle="{value}">
                      {{ value }}
                    </template>

                    <template #item.time="{value}">
                      {{
                        $t('pages.statistics.testingTable.timeExpectedPlural', {
                          expected: value.expected,
                        })
                      }}
                      <br />
                      {{
                        $t('pages.statistics.testingTable.timeSpentPlural', { spent: value.spent })
                      }}
                    </template>

                    <template #item.mistakes="{value}">
                      {{ $t('pages.statistics.testingTable.totalPlural', { total: value.total }) }}
                      <br />
                      {{ $t('pages.statistics.testingTable.stepsPlural', { steps: value.steps }) }}
                    </template>

                    <template #item.result="{value}">
                      {{
                        $t('pages.statistics.testingTable.expectedResultPlural', {
                          expected: value.expected,
                        })
                      }}
                      <br />
                      {{
                        $t('pages.statistics.testingTable.actualResultPlural', {
                          actual: value.actual,
                        })
                      }}
                    </template>

                    <template #item.status="{value}">
                      <v-chip
                        v-if="value === 'SUCCESS'"
                        color="green"
                        text-color="white"
                        label
                        small
                      >
                        {{ $t('pages.statistics.testingTable.success') }}
                      </v-chip>

                      <v-chip v-else color="red" text-color="white" label small>
                        {{ $t('pages.statistics.testingTable.failed') }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment';
  import { mapActions, mapGetters, mapState } from 'vuex';
  import { saveAs } from 'file-saver';

  import api from 'src/api';
  import formatTimestampMixin from 'src/mixins/formatTimestampMixin';

  import SimulationStatsCards from 'src/components/Statistics/SimulationStatsCards.vue';
  import SimulationPlayingChart from 'src/components/Statistics/SimulationPlayingChart.vue';
  import SimulationTestingChart from 'src/components/Statistics/SimulationTestingChart.vue';

  import TravoltaNotFound from 'src/components/TravoltaNotFound.vue';
  import CreateSimulationButton from '@/pages/Simulations/components/CreateSimulationButton.vue';

  import { deepcopy, getLocationForQueries, prepareCsv } from '@/utils';

  const defaultParams = {
    itemsPerPage: 10,
    page: 1,
  };

  export default {
    name: 'SimulationsStats',

    components: {
      CreateSimulationButton,
      SimulationStatsCards,
      SimulationPlayingChart,
      SimulationTestingChart,
      TravoltaNotFound,
    },

    mixins: [formatTimestampMixin],

    data() {
      return {
        organizationId: null,
        playingCardsStats: {},
        testingCardsStats: {},

        simulationId: null,
        simulations: [
          { id: null, title: this.$t('pages.statistics.testingFilter.allSimulations') },
        ],
        params: {},
        serverItemsCount: 0,
        isBusy: false,

        maxDate: null,
        minDate: '2000-01-01',
        startDate: null,
        startDateMenu: false,
        endDate: null,
        endDateMenu: false,
        filter: { ...defaultParams },
        datesFilter: {},

        dialogDates: {
          startDate: null,
          startDateMenu: false,
          endDate: null,
          endDateMenu: false,
        },

        downloadItems: [
          {
            title: this.$t('pages.statistics.playingStatistics'),
            value: 'playing',
          },

          {
            title: this.$t('pages.statistics.testingStatistics'),
            value: 'testing',
          },

          {
            title: this.$t('pages.statistics.testingResults'),
            value: 'testingResults',
          },
        ],

        playingChart: [],
        testingChart: [],

        testingDatesMenu: false,
        testingDatesRange: [],
        testingUser: null,
        rawUsers: [],
        users: [{ id: null, email: this.$t('pages.statistics.testingTable.allUsers') }],
        status: null,
        testingStats: [],

        testingStatuses: [
          {
            title: this.$t('pages.statistics.testingTable.allStatuses'),
            status: null,
          },
          {
            title: this.$t('pages.statistics.testingTable.success'),
            status: 'SUCCESS',
          },
          {
            title: this.$t('pages.statistics.testingTable.failed'),
            status: 'FAILED',
          },
        ],

        hasSimulations: false,
      };
    },

    computed: {
      ...mapGetters('users', [
        'isSuperAdmin',
        'isSmartManualsEnterprisePlanActive',
        'isHintedPlansActive',
        'isAnalyst',
      ]),
      ...mapState('users', ['currentUser']),

      tableHeaders() {
        return [
          {
            text: this.$t('pages.statistics.testingTable.date'),
            value: 'data.startTime',
            sortable: false,
          },
          {
            text: this.$t('pages.statistics.testingTable.user'),
            value: 'userId',
            sortable: false,
          },
          {
            text: this.$t('pages.statistics.testingTable.title'),
            value: 'simulationTitle',
            sortable: false,
          },
          {
            text: this.$t('pages.statistics.testingTable.time'),
            value: 'time',
            sortable: false,
          },
          {
            text: this.$t('pages.statistics.testingTable.mistakes'),
            value: 'mistakes',
            sortable: false,
          },
          {
            text: this.$t('pages.statistics.testingTable.result'),
            value: 'result',
            sortable: false,
          },
          {
            text: this.$t('pages.statistics.testingTable.status'),
            value: 'status',
            sortable: false,
          },
        ];
      },

      datesSelected() {
        return this.startDate && this.endDate;
      },

      mappedParams() {
        const { itemsPerPage, page = 1 } = this.params;

        return {
          page,
          limit: itemsPerPage === -1 ? 'all' : itemsPerPage,
          ...(this.testingUser ? { userId: this.testingUser } : {}),
          ...(this.status ? { status: this.status } : {}),
        };
      },
    },

    watch: {
      organizationId() {
        this.getSimulations();
        this.updateUsersList();
      },

      simulationId() {
        this.updateStatistics();
        this.updateTestingStatistics();
      },

      startDate(value, prevValue) {
        const from = moment(value)
          .startOf('day')
          .valueOf();
        const to = moment(this.endDate)
          .endOf('day')
          .valueOf();

        this.datesFilter = { from, to };
        this.updateStatistics();

        if (prevValue) {
          this.updateTestingStatistics();
        }
      },

      endDate(value, prevValue) {
        const from = moment(this.startDate)
          .startOf('day')
          .valueOf();
        const to = moment(value)
          .endOf('day')
          .valueOf();

        this.datesFilter = { from, to };
        this.updateStatistics();

        if (prevValue) {
          this.updateTestingStatistics();
        }
      },

      datesSelected(currentDatesSelected, prevDateSelected) {
        if (!prevDateSelected && currentDatesSelected) {
          this.updateTestingStatistics();
        }
      },

      mappedParams: {
        handler() {
          if (this.datesSelected) {
            this.updateTestingStatistics();
          }
        },
        immediate: true,
      },

      rawUsers(value) {
        const defaultUser = [
          { id: null, email: this.$t('pages.statistics.testingTable.allUsers') },
        ];

        this.users = [...defaultUser, ...value];
      },

      status: {
        handler() {
          this.params.page = 1;
        },
        immediate: true,
      },

      testingUser: {
        handler() {
          this.params.page = 1;
        },
        immediate: true,
      },
    },

    async mounted() {
      await this.getSimulations();

      this.endDate = moment().format('YYYY-MM-DD');
      this.maxDate = moment().format('YYYY-MM-DD');
      this.startDate = moment()
        .subtract(30, 'd')
        .format('YYYY-MM-DD');

      this.organizationId = this.currentUser.organizationId;

      this.updateStatistics();
    },

    methods: {
      ...mapActions('stats', ['getSimulationStatsChart', 'getSimulationTestingStats']),

      async getSimulations() {
        if (!this.organizationId) {
          return;
        }

        const params = { organizationId: this.organizationId, limit: 'all' };
        const { response } = await api.simulations.list({ params });

        this.hasSimulations = Boolean(response.payload.length);

        this.simulations = [
          { id: null, title: this.$t('pages.statistics.testingFilter.allSimulations') },
          ...response.payload,
        ];
      },

      async updateStatistics() {
        const results = await this.getSimulationStatsChart({
          simulationId: this.simulationId,
          ...this.datesFilter,
        });

        this.playingChart = results.playing;
        this.testingChart = results.testing;

        this.playingCardsStats = results.cards.playing;
        this.testingCardsStats = results.cards.testing;
      },

      async updateTestingStatistics() {
        const result = await this.getSimulationTestingStats({
          simulationId: this.simulationId,
          datesRange: this.datesFilter,
          additionalParams: this.mappedParams,
        });

        this.testingStats = result.payload;
        this.serverItemsCount = result.count;
      },

      async updateUsersList() {
        const { response } = await api.users.getSimpleList();
        this.rawUsers = response;
      },

      getUserInfo(item) {
        const { userEmail, firstname, lastname } = item;
        const email = userEmail || this.$t('pages.statistics.testingFilter.unknownEmail');
        const name =
          (firstname && lastname && `${firstname} ${lastname}`) ||
          this.$t('pages.statistics.testingFilter.unknownUser');

        return {
          email,
          name,
        };
      },

      async downloadFile(type) {
        if (this.isBusy) {
          return;
        }

        this.isBusy = true;

        try {
          const from = moment(this.startDate)
            .startOf('day')
            .valueOf();
          const to = moment(this.endDate)
            .endOf('day')
            .valueOf();

          if (['playing', 'testing'].includes(type)) {
            await this.saveTypedStatistics(from, to, type);
          } else {
            await this.saveTestingResults(from, to);
          }
        } finally {
          this.isBusy = false;
        }
      },

      async saveTypedStatistics(from, to, type) {
        const params = deepcopy({
          from,
          to,
          type,
          simulationId: this.simulationId || undefined,
          lang: getLocationForQueries(),
        });

        const { response } = await api.stats.downloadSimulationsStatistics({
          params,
        });

        const blob = await prepareCsv(response);
        saveAs(blob, 'simulationStatistics.csv');
      },

      async saveTestingResults(from, to) {
        const params = deepcopy({
          from,
          to,
          simulationId: this.simulationId || undefined,
          lang: getLocationForQueries(),
        });
        const { response } = await api.stats.downloadTestingResults({
          params,
        });

        const blob = await prepareCsv(response);
        saveAs(blob, 'testingStatistics.csv');
      },
    },
  };
</script>
