import api from 'src/api';
import { SET_ACTIVE_ELEMENT_STYLE_ID, SET_ELEMENT_STYLES } from 'src/store/mutation-types';

export default {
  namespaced: true,
  state: {
    elementStyles: [],
    activeElementStyleId: null,
  },

  getters: {
    activeElementStyle: state =>
      state.elementStyles.find(elementStyle => elementStyle.id === state.activeElementStyleId),
  },

  actions: {
    async create(context, payload) {
      const { response } = await api.elementStyle.create({ data: payload });
      return response;
    },

    async getByOrganization({ commit }) {
      const { response } = await api.elementStyle.getByOrganization();
      commit(SET_ELEMENT_STYLES, response);
    },

    async update(context, { id, payload }) {
      const { response } = await api.elementStyle.update({ url_params: { id }, data: payload });
      return response;
    },

    async enableTheme(context, id) {
      const { response } = await api.elementStyle.enableTheme({ url_params: { id } });
      return response;
    },

    async remove(context, id) {
      const { response } = await api.elementStyle.remove({ url_params: { id } });
      return response;
    },

    async enableElementTheme(context, { elementId, elementType, styleId }) {
      const { response } = await api.elementStyle.enableElementTheme({
        data: { elementId, elementType, styleId },
      });

      return response;
    },

    async disableElementTheme(context, { elementId, elementType }) {
      const { response } = await api.elementStyle.disableElementTheme({
        data: { elementId, elementType },
      });

      return response;
    },

    async getTooltipTheme(context, id) {
      const { response } = await api.elementStyle.getTooltipTheme({
        url_params: { tooltipId: id },
      });

      return response;
    },

    async getScenarioTheme(context, id) {
      const { response } = await api.elementStyle.getScenarioTheme({
        url_params: { scenarioId: id },
      });

      return response;
    },
  },

  mutations: {
    [SET_ELEMENT_STYLES](state, elementStyles) {
      state.elementStyles = elementStyles;
    },

    [SET_ACTIVE_ELEMENT_STYLE_ID](state, id) {
      state.activeElementStyleId = id;
    },
  },
};
